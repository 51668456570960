import ContentWrapper from "../../Components/Global/ContentWrapper";
import PageDescription from "../../Components/Global/PageDescription";
import Layout from "../../Layout/Layout";
import { Button, Modal, ModalBody, Table } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import React, { useCallback, useEffect, useState } from 'react'
import db from "../../firebase";
import { doc, getDoc, collection, getDocs, query, orderBy, startAfter, limit, getCountFromServer, endBefore, limitToLast, startAt, endAt, where, and, updateDoc } from "firebase/firestore";
import SecondaryButton from "../../Components/Global/Buttons/SecondaryButton";
import Ripple from "../../Components/Global/Buttons/Ripple";
import Skeleton from "react-loading-skeleton";
import { useRef } from "react";
import moment from "moment";
import FilterModalMembershipsRequest from "../../Components/Global/Popup/FilterModalMembershipsRequest";
import PrimaryButton from "../../Components/Global/Buttons/PrimaryButton";
import Toaster from "../../Components/Global/Toast";
const sortIcon = "/assets/icons/icon-sort.svg";
const sortIconAsc = "/assets/icons/icon-sort-asc.svg";
const sortIconDesc = "/assets/icons/icon-sort-desc.svg";

const ImagePreview = () => {
  return (
    <div
      style={{
        height: "50px",
        width: "50px",
        backgroundColor: "#DEDEDE",
      }}
      className="bg-gray rounded-circle overflow-hidden d-flex align-items-center justify-content-center"
    >
      <i className="bi bi-person-fill text-4xl"></i>
    </div>
  );
};

const MembershipRequests = () => {
  const [showEditMembershipModal, setShowEditMembershipModal] = useState(false);
  const [showToaster, setShowToaster] = useState(false);
  const [userId, setUserId] = useState("")
  const [membershipFormEdit, setMembershipFormEdit] = useState({
    startDate: moment(new Date()).format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
    membershipRequestId: "",
    membershipCreatedDate: "",
    errors: {
      startDate: "",
      endDate: ""
    }
  }
  )
  const [toasterDetails, setToasterDetails] = useState({
    title: "",
    bodyText: "",
    bg: "",
    statusIcon: "",
  });
  const filterComponent = React.createRef();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageNo = searchParams.get('pageNo');
  const membershipsRequestPerPage = useRef(20);
  const count = useRef(0);
  const changeOrder = useRef(0);
  const nextPage = useRef(1);
  const currentPageId = useRef("");
  const sortBy = useRef({
    orderByKey: "updatedAt",
    order: "desc"
  })
  const filterKeysRef = useRef({
    selectedMemberships: [],
    selectedStatus: []
  });
  const localIdBasedSortIcon = useRef("");
  const targetElementForSort = useRef("");
  const searchQueryInputRef = useRef("");
  const filterIcon = useRef(null);
  const [loader, setLoader] = useState(true);
  const [totalMembershipRequests, setTotalMembershipRequests] = useState(null);
  const [pageCount, setPageCount] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterKeys, setFilterKeys] = useState({
    selectedMemberships: [],
    selectedStatus: []
  })
  const [membershipRequests, setMembershipRequests] = useState([]);
  const handleClickOutside = useCallback((event) => {
    if (filterComponent.current && !filterComponent.current.contains(event.target) && event.target !== filterIcon.current) {
      setShowModal(false);
    }
  }, [filterComponent])

  // useEffect goes here ----------------------------------------------------------------------------------------

  useEffect(() => { // this useEffect is used when user will click other area than filter popup
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handleClickOutside]);

  useEffect(() => { //on the very first render of component and on page refresh
    if (Boolean(searchParams.get('pageNo'))) count.current = searchParams.get('pageNo') - 1;
    if (Boolean(searchParams.get('sortBy'))) {
      sortBy.current = JSON.parse(searchParams.get('sortBy'));
      changeOrder.current = +searchParams.get('changeOrder');
      targetElementForSort.current = JSON.parse(searchParams.get('sortBy')).orderByKey;
      localIdBasedSortIcon.current = targetElementForSort.current;
    }
    if (Boolean(searchParams.get('itemsPerPage'))) membershipsRequestPerPage.current = +searchParams.get('itemsPerPage');
    if (Boolean(searchParams.get('prevNext')) || Boolean(searchParams.get('searchQuery')) || Boolean(searchParams.get('filterQuery'))) {
      if (Boolean(searchParams.get('prevNext'))) {
        if (searchParams.get('n')) nextPage.current = +searchParams.get("n");
        currentPageId.current = searchParams.get('currentPageId');
        if (Boolean(searchParams.get('n'))) nextPage.current = +searchParams.get('n');
        pageReloadFunc();
        return;
      }
      if (Boolean(searchParams.get('searchQuery'))) {
        searchQueryInputRef.current = searchParams.get('searchQuery');
        setSearchQuery(searchQueryInputRef.current);
        fetchSearchedMembershipRequests(searchQueryInputRef.current);
        return;
      } else {
        handleFilterMembershipsRequest(JSON.parse(searchParams.get('filterQuery')));
        return;
      }
    }
    if (!Boolean(pageNo)) {
      fetchData();
    }
    else pageReloadFunc();
  }, [])

  const fetchData = async () => { // this function will execute on first render only when count value will be zero or clubPageNo will be 1 and if items per page will change
    setLoader(true);
    if (searchQueryInputRef.current) return;
    count.current = 0;
    //memberships request count -----------------------------------------------------------
    const clubsCount = await getCountFromServer(collection(db, "membership_requests"));
    setTotalMembershipRequests(clubsCount.data().count);
    setPageCount(Math.ceil(clubsCount.data().count / membershipsRequestPerPage.current));
    setFilterKeys({
      selectedMemberships: [],
      selectedStatus: []
    })
    filterKeysRef.current = {
      selectedMemberships: [],
      selectedStatus: []
    }
    if (Boolean(searchParams.get('filterQuery'))) {
      membershipsRequestPerPage.current = 20;
      localIdBasedSortIcon.current = "";
    }
    try {
      setMembershipRequests([]);
      const membershipRequestDocs = await getDocs(query(collection(db, "membership_requests"),
        orderBy(sortBy.current.orderByKey, sortBy.current.order),
        limit(membershipsRequestPerPage.current)));
      await Promise.all(membershipRequestDocs.docs.map(async (membershipRequestDoc) => {
        setMembershipRequests(prev => [...prev, membershipRequestDoc.data()])
      }))
      setLoader(false)
    } catch (error) {
      console.error("Error fetching membership requests:", error);
    }
  }
  const pageReloadFunc = useCallback(async () => {
    // membershipsRequestPerPage.current = +searchParams.get('itemsPerPage');
    setLoader(true);
    //memberships request count -----------------------------------------------------------
    const coll = collection(db, "membership_requests");
    const clubsCount = await getCountFromServer(coll);
    setTotalMembershipRequests(clubsCount.data().count);
    setPageCount(Math.ceil(clubsCount.data().count / membershipsRequestPerPage.current));
    const docSnap = count.current && await getDoc(doc(collection(db, "membership_requests"), currentPageId.current));
    if (nextPage.current === 1) {
      getDocs(query(collection(db, "membership_requests"), orderBy(sortBy.current.orderByKey, sortBy.current.order), startAfter(docSnap), limit(membershipsRequestPerPage.current)))
        .then((docs) => {
          setMembershipRequests([]);
          docs.forEach((doc) => {
            setMembershipRequests((prev) => [...prev, doc.data()]);
          });
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
        });

    } else {
      const docSnap = count.current && await getDoc(doc(collection(db, "membership_requests"), currentPageId.current));
      getDocs(query(collection(db, "membership_requests"), orderBy(sortBy.current.orderByKey, sortBy.current.order), endBefore(docSnap), limitToLast(+membershipsRequestPerPage.current)))
        .then((docs) => {
          setMembershipRequests([]);
          docs.forEach((doc) => {
            setMembershipRequests((prev) => [...prev, doc.data()]);
          });
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  }, [nextPage])

  // Items Per Page Change **************************************************

  const handlerItemsPerPage = (e) => { // I am recalling the fetchData func when items per page whenever change but not calling fetchMembershipsRequestCount because it won't change if items per page will change
    setLoader(true);
    membershipsRequestPerPage.current = +e.target.value;
    const currentParams = Object.fromEntries([...searchParams]);
    setSearchParams({ ...currentParams, itemsPerPage: membershipsRequestPerPage.current });
    count.current = 0;
    if (!Boolean(searchParams.get('searchQuery')) && !Boolean(searchParams.get('filterQuery'))) {
      // fetchMembershipsRequestCount(); // this function is called because on items per page change no of page will change
      fetchData();
    }
    else if (Boolean(searchParams.get("searchQuery"))) fetchSearchedMembershipRequests(searchParams.get("searchQuery"));
    else if (Boolean(searchParams.get("filterQuery"))) handleFilterMembershipsRequest(JSON.parse(searchParams.get("filterQuery")))
    else console.log('endingg');
  }

  // Sorting Goes Here ***************************************************************************

  const handleSorting = (e, localIdParam) => {
    if (count.current) { // coming back to first page when you will be on other page
      count.current = 0;
      changeOrder.current = 0;
    }
    if (targetElementForSort.current !== localIdParam) {
      changeOrder.current = 0;
      targetElementForSort.current = localIdParam;
    }
    if (searchParams.get('searchQuery') || searchParams.get('filterQuery')) {
      localIdBasedSortIcon.current = localIdParam;
      if (Boolean(searchParams.get('searchQuery'))) {
        if (!changeOrder.current) {
          changeOrder.current = changeOrder.current + 1;
          sortBy.current = { orderByKey: targetElementForSort.current, order: "asc" }
          setMembershipRequests([...membershipRequests].sort((a, b) => a[targetElementForSort.current] > b[targetElementForSort.current] ? 1 : a[targetElementForSort.current] < b[targetElementForSort.current] ? -1 : 0).splice(0, membershipsRequestPerPage.current));
        }
        else if (changeOrder.current === 1) {
          changeOrder.current = changeOrder.current + 1;
          sortBy.current = { orderByKey: targetElementForSort.current, order: "desc" }
          setMembershipRequests([...membershipRequests].sort((a, b) => a[targetElementForSort.current] < b[targetElementForSort.current] ? 1 : a[targetElementForSort.current] > b[targetElementForSort.current] ? -1 : 0).splice(0, membershipsRequestPerPage.current));
        }
        else {
          changeOrder.current = 0;
          setLoader(true);
          sortBy.current = { orderByKey: "createdAt", order: "desc" }
          fetchSearchedMembershipRequests(searchParams.get('searchQuery'));
        }
      }
      else {
        if (!changeOrder.current) {
          changeOrder.current = changeOrder.current + 1;
          sortBy.current = { orderByKey: targetElementForSort.current, order: "asc" }
          setMembershipRequests([...membershipRequests].sort((a, b) => a[targetElementForSort.current] > b[targetElementForSort.current] ? 1 : a[targetElementForSort.current] < b[targetElementForSort.current] ? -1 : 0).splice(0, membershipsRequestPerPage.current));
        }
        else if (changeOrder.current === 1) {
          changeOrder.current = changeOrder.current + 1;
          sortBy.current = { orderByKey: targetElementForSort.current, order: "desc" }
          setMembershipRequests([...membershipRequests].sort((a, b) => a[targetElementForSort.current] < b[targetElementForSort.current] ? 1 : a[targetElementForSort.current] > b[targetElementForSort.current] ? -1 : 0).splice(0, membershipsRequestPerPage.current));
        }
        else {
          changeOrder.current = 0;
          setLoader(true);
          sortBy.current = { orderByKey: "createdAt", order: "desc" }
          handleFilterMembershipsRequest(JSON.parse(searchParams.get('filterQuery')));
        }
      }
    }
    else {
      if (!changeOrder.current) {
        changeOrder.current = changeOrder.current + 1;
        localIdBasedSortIcon.current = localIdParam;
        sortBy.current = { orderByKey: targetElementForSort.current, order: "asc" }
        fetchData();
      }
      else if (changeOrder.current === 1) {
        changeOrder.current = changeOrder.current + 1;
        sortBy.current = { orderByKey: targetElementForSort.current, order: "desc" }
        fetchData();
      }
      else {
        changeOrder.current = 0;
        sortBy.current = { orderByKey: "updatedAt", order: "desc" }
        fetchData();
      }
    }
    const currentParams = Object.fromEntries([...searchParams]);
    setSearchParams({ ...currentParams, sortBy: JSON.stringify(sortBy.current), changeOrder: changeOrder.current });
  }
  // Sorting Finished Here -----------------------------------------------------------------------

  // Searching ***********************************************************************************!
  const handleSearchQuery = (e) => {
    let inputValue = (e.target.value).trim();
    if (inputValue.trim().length > 0) {
      setLoader(true);
      count.current = 0;
      setSearchQuery(e.target.value);
      searchParams.delete('prevNext');
      searchParams.delete('currentPageId');
      searchParams.delete('filterQuery');
      searchParams.delete('n');
      setSearchParams({ ...Object.fromEntries([...searchParams]), pageNo: count.current + 1, searchQuery: e.target.value })
      if (Boolean(searchParams.get('itemsPerPage'))) membershipsRequestPerPage.current = +searchParams.get('itemsPerPage');
      setFilterKeys({
        selectedMemberships: [],
        selectedStatus: []
      })
      filterKeysRef.current = {
        selectedMemberships: [],
        selectedStatus: []
      }
      searchQueryInputRef.current = e.target.value;
      if (e.target.value) {
        fetchSearchedMembershipRequests(e.target.value);
      }
      else {
        membershipsRequestPerPage.current = 20;
        setSearchParams({});
        setTimeout(() => {
          setMembershipRequests([]);
          fetchData();
        }, 0)
      }
    }
    else {
      setSearchQuery("");
      console.log(searchParams, "searchParams")
      searchParams.delete('prevNext');
      searchParams.delete('currentPageId');
      searchParams.delete('filterQuery');
      searchParams.delete('n');
      membershipsRequestPerPage.current = 20;
      setSearchParams({});
      setTimeout(() => {
        setMembershipRequests([]);
        fetchData();
      }, 0)
      searchQueryInputRef.current = "";
      setSearchQuery(searchQueryInputRef.current);
    }
  }
  const fetchSearchedMembershipRequests = useCallback(
    debounce((val) => {
      if (searchQueryInputRef.current) {
        setLoader(true);
        const searchedMembershipRequestByName = query(collection(db, "membership_requests"), orderBy("user_name_lower_case"), startAt(val.replace(/\s+/g, ' ').trim().toLowerCase()), endAt(`${val.toLowerCase()}\uf8ff`));
        let localSearchedArray = [];
        getDocs(searchedMembershipRequestByName).then((docSnapshots) => {
          docSnapshots.forEach((doc) => {
            localSearchedArray = [...localSearchedArray, doc.data()]
          });
          if (!changeOrder.current) {
            setMembershipRequests([...localSearchedArray].splice(0, membershipsRequestPerPage.current));
          }
          else if (changeOrder.current === 1) {
            setMembershipRequests([...localSearchedArray].sort((a, b) => a[targetElementForSort.current] > b[targetElementForSort.current] ? 1 : a[targetElementForSort.current] < b[targetElementForSort.current] ? -1 : 0).splice(0, membershipsRequestPerPage.current));
          }
          else {
            setMembershipRequests([...localSearchedArray].sort((a, b) => a[targetElementForSort.current] < b[targetElementForSort.current] ? 1 : a[targetElementForSort.current] > b[targetElementForSort.current] ? -1 : 0).splice(0, membershipsRequestPerPage.current));
          }
          setTotalMembershipRequests([...localSearchedArray].length);
          setLoader(false);
        });
      }
    }, 1000),
    []);


  function debounce(fn, delay) {
    let timer;
    return function () {
      let context = this,
        args = arguments;
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(context, args);
      }, delay);
    };
  }

  // Searching Finished Here ---------------------------------------------------------------------------------
  const previousPageDataFunc = async () => { // this function will be called when previous button clicked
    setLoader(true);
    count.current = count.current - 1;
    if (!count.current) {
      fetchData();
      setSearchParams({});
      return;
    }
    const docSnap = count.current && await getDoc(doc(collection(db, "membership_requests"), membershipRequests[0].id));
    getDocs(query(collection(db, "membership_requests"), orderBy(sortBy.current.orderByKey, sortBy.current.order), endBefore(docSnap), limitToLast(+membershipsRequestPerPage.current)))
      .then((docs) => {
        setMembershipRequests([]);
        docs.forEach((doc) => {
          setMembershipRequests((prev) => [...prev, doc.data()]);
        });
        setSearchParams({ ...Object.fromEntries([...searchParams]), pageNo: count.current + 1, prevNext: JSON.stringify([docs.docs[0].id, docs.docs[docs.docs.length - 1].id]), currentPageId: docSnap.id, itemsPerPage: membershipsRequestPerPage.current, n: 0 })
        // setSearchParams({ pageNo: count.current + 1, prevNext: JSON.stringify([docs.docs[0].id, docs.docs[docs.docs.length - 1].membershipRequestId]), currentPageId: docSnap.id, itemsPerPage: membershipsRequestPerPage.current, n: 0, sortBy: JSON.stringify(sortBy.current), changeOrder: changeOrder.current })
        // if count value will be greater than 0 then will set data in the url otherwise nothing needs to set in the url
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  }

  const nextPageDataFunc = async () => { // this will called when next button will called
    setLoader(true);
    count.current = count.current + 1;
    const docSnap = count.current && await getDoc(doc(collection(db, "membership_requests"), membershipRequests[membershipRequests.length - 1].id));
    getDocs(query(collection(db, "membership_requests"), orderBy(sortBy.current.orderByKey, sortBy.current.order), startAfter(docSnap), limit(membershipsRequestPerPage.current)))
      .then((docs) => {
        setMembershipRequests([]);
        docs.forEach((doc) => {
          setMembershipRequests((prev) => [...prev, doc.data()]);
        });
        setSearchParams({ ...Object.fromEntries([...searchParams]), pageNo: count.current + 1, prevNext: JSON.stringify([docs.docs[0].id, docs.docs[docs.docs.length - 1].id]), currentPageId: docSnap.id, n: 1 })
        /* setSearchParams => clubPageno will help to track the page no, prevNext is storing id of first and last
          item which will help to go prev and next page.
          currentPageId will help me to be on the page same page when page reload
        */
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  }

  const handleShowAll = () => {
    setLoader(true);
    let tempItemsPerPage = membershipsRequestPerPage.current;
    membershipsRequestPerPage.current = +totalMembershipRequests;
    if (Boolean(searchParams.get('searchQuery'))) fetchSearchedMembershipRequests(searchQuery)
    else handleFilterMembershipsRequest(JSON.parse(searchParams.get('filterQuery')))
    setSearchParams({ ...Object.fromEntries([...searchParams]), itemsPerPage: tempItemsPerPage });
  }

  // Filter **************************************************************************************************!
  const handleFilterMembershipsRequest = async (filterState) => {
    setLoader(true);
    count.current = 0;
    if (searchQueryInputRef.current) {
      if (Boolean(searchParams.get('itemsPerPage'))) membershipsRequestPerPage.current = +searchParams.get('itemsPerPage');
    }
    let arr = [];
    setFilterKeys(filterState);
    filterKeysRef.current = filterState;
    // reseting search input field in order to remove search and apply filter
    searchQueryInputRef.current = "";
    setSearchQuery(searchQueryInputRef.current);
    // ----------------------------------------
    let filteredMemberships = filterKeysRef.current.selectedMemberships.length;
    let filteredStatus = filterKeysRef.current.selectedStatus.length;
    if (filteredMemberships && filteredStatus) {
      arr = [where("membership_name_lower_case", "in", filterKeysRef.current.selectedMemberships), where("status", "in", filterKeysRef.current.selectedStatus)]
    } else if (filteredMemberships) {
      arr = [where("membership_name_lower_case", "in", filterKeysRef.current.selectedMemberships)]
    } else if (filteredStatus) {
      arr = [where("status", "in", filterKeysRef.current.selectedStatus)]
    } else console.log("not applicable");
    let membership_requests_data = (filteredMemberships || filteredStatus) && await getDocs(query(collection(db, "membership_requests"), and(...arr), orderBy(sortBy.current.orderByKey, sortBy.current.order)))
    if (membership_requests_data) {
      let localFilteredMembershipRequests = []
      membership_requests_data.size && membership_requests_data.forEach(membershipRequest => {
        localFilteredMembershipRequests = [...localFilteredMembershipRequests, membershipRequest.data()];
        // setMembershipRequests(prev => [...prev, membershipRequest.data()])
      })
      setMembershipRequests([...localFilteredMembershipRequests].splice(0, membershipsRequestPerPage.current));
      setTotalMembershipRequests(membership_requests_data.size);
      setLoader(false);
    }
  }
  const handleModifyFilter = (e, item, filterType) => {
    if (filterType === "membershipRequest") {
      setFilterKeys({
        ...filterKeys,
        selectedMemberships: filterKeys.selectedMemberships.filter(membershipRequest => membershipRequest !== item)
      })

      filterKeysRef.current = {
        ...filterKeysRef.current,
        selectedMemberships: filterKeysRef.current.selectedMemberships.filter(membershipRequest => membershipRequest !== item)
      }
    }
    if (filterType === "status") {
      setFilterKeys({
        ...filterKeys,
        selectedStatus: filterKeys.selectedStatus.filter(status => status !== item)
      })
      filterKeysRef.current = {
        ...filterKeysRef.current,
        selectedStatus: filterKeysRef.current.selectedStatus.filter(status => status !== item)
      }
    }
    const currentParams = Object.fromEntries([...searchParams]);
    setSearchParams({ ...currentParams, filterQuery: JSON.stringify(filterKeysRef.current) });
    if (filterKeysRef.current.selectedMemberships.length || filterKeysRef.current.selectedStatus.length) {
      handleFilterMembershipsRequest(filterKeysRef.current);
    } else {
      sortBy.current = {
        orderByKey: "createdAt",
        order: "desc"
      }
      changeOrder.current = 0;
      membershipsRequestPerPage.current = 20;
      setSearchParams({ itemsPerPage: membershipsRequestPerPage.current, sortBy: JSON.stringify(sortBy.current), changeOrder: changeOrder.current });
      fetchData()
    }
  }

  // Membership Updation
  const handleMembershipEdit = () => setShowEditMembershipModal(false);
  const onSubmitMembershipEdit = async (e) => {
    e.preventDefault()
    if (editFormValidation()) {
      return;
    }
    else {
      setShowEditMembershipModal(false)
      setShowToaster(true)
      setToasterDetails((prev) => ({
        ...prev,
        title: "Updating...",
        bodyText: "Updating membership...",
        bg: "warning",
        statusIcon: (
          <div className="spinner-border text-warning me-2" role="status"></div>
        ),
      }));
      const { startDate, endDate, membershipRequestId } = membershipFormEdit;
      const docRef = doc(db, "membership_requests", membershipRequestId)
      try {
        // First we are updating membership requests
        let membershipRequestResponse = await updateDoc(docRef, {
          starts_at: new Date(startDate),
          ends_at: new Date(endDate)
        })
        // Retrieve the user ID from the membership request document
        const membershipRequestSnapshot = await getDoc(docRef);
        const userId = membershipRequestSnapshot.data().user_id;
        // Second we are updating user collections
        let userResponse = await updateDoc(doc(db, "users", userId), {
          mebership_starts_at: new Date(startDate),
          mebership_ends_at: new Date(endDate)
        })

        setShowModal(false);
        setToasterDetails((prev) => ({
          ...prev,
          title: "Success",
          bodyText: "Updated successfully",
          bg: "success",
          statusIcon: (
            <i className="bi bi-check text-success text-3xl me-2"></i>
          ),
        }));
        getAllMembershipDetails();
        setTimeout(() => {
          setShowToaster(false);
        }, 2000);
      } catch (error) {
        setToasterDetails((prev) => ({
          ...prev,
          title: "Error",
          bodyText: "Something went wrong",
          bg: "danger",
          statusIcon: (
            <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
          ),
        }));
        setTimeout(() => {
          setShowToaster(false);
        }, 2000);
      }
    }
  }
  const editFormValidation = () => {
    let errors = {};
    const { startDate, endDate } = membershipFormEdit;
    if (startDate === "") {
      errors.startDate = "Please select a start date"
    }
    if (endDate === "") {
      errors.endDate = "Please select a end date"
    }
    setMembershipFormEdit({
      ...membershipFormEdit,
      errors: errors
    })

    return Object.keys(errors).length > 0
  }

  const getAllMembershipDetails = async () => {
    let temp = [];
    let collectionRef = collection(db, "membership_requests");
    let queryforDataFetching = await getDocs(collectionRef);
    for (let index = 0; index < queryforDataFetching.docs.length; index++) {
      const element = queryforDataFetching.docs[index];
      if (element.data().user_id === userId) {
        temp.push(element.data());
      }
    }
  };

  const MembershipRequestsEntries = ({ membershipRequests }) => {
    return (
      <div className="tableFixHead pb-5 font-body rounded bg-white" >
        <Table hover>
          <thead>
            <tr className="background-gray10">
              <th className="text-nowrap">
                <p className="text-nowrap text-xs text-xl-sm text-uppercase ps-3 color-black2 mb-0 py-2">
                  S. No
                </p>
              </th>
              <th valign="middle">
                <span
                  onClick={(e) => handleSorting(e, "user_name_lower_case")}
                  className={`cursor-pointer text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2 d-inline-flex align-items-center gap-1 ${localIdBasedSortIcon.current !== "user_name_lower_case" || !changeOrder.current ? "" : "color-yellow1"}`} id="user_name_lower_case">
                  NAME
                  <img src={(localIdBasedSortIcon.current !== "user_name_lower_case" || !changeOrder.current) ? sortIcon : changeOrder.current === 1 ? sortIconAsc : sortIconDesc} alt="sort-icon" height={12} />
                </span>
              </th>
              <th className="text-nowrap">
                <p className="text-nowrap text-xs text-xl-sm text-uppercase  color-black2 mb-0 py-2">
                  MEMBERSHIP
                </p>
              </th>
              <th valign="middle">
                <span onClick={(e) => handleSorting(e, "createdAt")} className={`cursor-pointer text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2 d-inline-flex align-items-center gap-1 ${localIdBasedSortIcon.current !== "createdAt" || !changeOrder.current ? "" : "color-yellow1"}`} id="createdAt">
                  REQUESTED ON
                  <img src={(localIdBasedSortIcon.current !== "createdAt" || !changeOrder.current) ? sortIcon : changeOrder.current === 1 ? sortIconAsc : sortIconDesc} alt="sort-icon" height={12} />
                </span>
              </th>
              <th className="text-nowrap">
                <p className="text-nowrap text-xs text-xl-sm text-uppercase  color-black2 mb-0 py-2">
                  STATUS
                </p>
              </th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {!loader ?
              (
                <>
                  {(membershipRequests && membershipRequests.length) ? membershipRequests.map((element, index) => {
                    return (
                      <tr key={index}>
                        <td className="align-middle">
                          <p className="text-sm mb-0 text-nowrap color-black ps-3">
                            {count.current * membershipsRequestPerPage.current + index + 1}.
                          </p>
                        </td>
                        <td className="align-middle">
                          {/* <div className="d-flex align-items-center cursor-pointer">
                            <div className="me-3">
                              {element.user && element.user.profile_image ? (
                                <div
                                  style={{
                                    height: "50px",
                                    width: "50px",
                                    backgroundImage: `url(${element.user.profile_image})`,
                                  }}
                                  className="background-position-center-center background-repeat-norepeat background-size-cover rounded-circle overflow-hidden "
                                ></div>
                              ) : (
                                <ImagePreview />
                              )}
                            </div>
                            <div>
                              <p className="text-sm mb-0 text-nowrap color-black">
                                <a href={`/memberships/membership-request/${element.id}`} className="text-dark">{element.user.name}</a>
                              </p>
                            </div>
                          </div> */}
                          <Link className="d-inline-flex align-items-center cursor-pointer text-black" to={`/memberships/membership-request/${element.id}`} target="_blank">
                            <div className="me-3">
                              {element.user && element.user.profile_image ? (
                                <div
                                  style={{
                                    height: "50px",
                                    width: "50px",
                                    backgroundImage: `url(${element.user.profile_image})`,
                                  }}
                                  className="background-position-center-center background-repeat-norepeat background-size-cover rounded-circle overflow-hidden "
                                ></div>
                              ) : (
                                <ImagePreview />
                              )}
                            </div>
                            <div>
                              <span className="text-sm mb-0 text-nowrap color-black">{element.user.name}</span>
                            </div>
                          </Link>
                        </td>
                        <td className="align-middle">
                          <p
                            className="text-sm mb-0 text-nowrap color-black"
                            style={{ minWidth: "200px" }}
                          >
                            {element.old_membership_name === null ? "Non Member" : element.old_membership_name} to {element.membership_name}
                          </p>
                        </td>
                        <td className="align-middle">
                          <p className="text-sm mb-0 text-nowrap color-black">
                            {moment(new Date(element.createdAt.seconds * 1000).toISOString()).format("DD MMM YYYY")}
                          </p>
                        </td>

                        <td className="align-middle">

                          <div className="d-flex align-items-center justify-content-start">
                            {element.status === "Pending" ? <Ripple color="#FFAE1F" /> : <div
                              style={{
                                height: "10px",
                                width: "10px",
                                backgroundColor: `${element.status === "Approved"
                                  ? "#13DEB9"
                                  : element.status === "Pending"
                                    ? "#FFAE1F"
                                    : element.status === "Cancelled"
                                      ? "#FA896B" :
                                      element.status === "Expired"
                                        ? "#FA896B"
                                        : element.status === "waiting for payment confirmation"
                                          ? "#FFAE1F"
                                          : null
                                  }`,
                              }}
                              className="rounded-circle flex-shrink-0"
                            ></div>
                            }

                            <div className="ms-2 text-sm color-black">
                              {element && element.status}
                            </div>
                          </div>
                        </td>
                        <td className="align-middle">
                          <SecondaryButton
                            name="View"
                            link={`/memberships/membership-request/${element.id}`}
                            target="_blank"
                            additionalClass="text-sm py-2 px-2 text-black"
                          />
                        </td>
                        <td>
                          {element.status === "Approved" ? <SecondaryButton name="Edit"
                            onClick={(e) => {
                              setUserId(element.user.id);
                              setShowEditMembershipModal(true)
                              setMembershipFormEdit({
                                ...membershipFormEdit,
                                membershipRequestId: element.id,
                                membershipCreatedDate: moment(element.createdAt.seconds * 1000).format('YYYY-MM-DD'),
                                startDate: moment(element.starts_at.seconds * 1000).format('YYYY-MM-DD'),
                                endDate: moment(element.ends_at.seconds * 1000).format('YYYY-MM-DD')
                              })
                            }}
                            additionalClass="text-sm py-0 px-0" />
                            : <button
                              style={{ cursor: "not-allowed" }}
                              className={`color-gray text-md border-1 border-secondary border font-body font-semibold cursor-pointer py-3 px-3 radius-105 d-inline-block bg-transparent`}
                            >
                              Edit
                            </button>}
                        </td>
                      </tr>
                    );
                  }) : <tr><td colSpan={6} className="text-center text-2xl font-bold">No Data Found</td></tr>
                  }
                </>
              ) : (
                <tr>
                  <td colSpan={7}>
                    <Skeleton inline={true} count={5} height={80} style={{ marginBottom: "1rem" }} baseColor='#dedede' highlightColor='#cccccc' duration={2} />
                  </td>
                </tr>
              )
            }
          </tbody>
        </Table>
      </div>
    )
  }
  return (
    <Layout path="/memberships" additionalclass="d-flex flex-column">
      <div className='po-table-content'>
        <div className="d-flex justify-content-between align-items-center gap-3 gap-lg-5 font-body">
          <PageDescription title="Membership Requests" caption="" />
          <PrimaryButton link="/create-membership" name="Add membership request" icon="bi-plus-circle" />
        </div>

        <div className='row d-flex justify-content-between g-3'>
          <div className='col-6 col-xl-5 col-xxl-4'>
            <input
              id="search"
              type="search"
              className="po-edit-input mb-1"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchQuery}
            />
            {(Boolean(searchParams.get('searchQuery')) || Boolean(searchParams.get('filterQuery'))) && !loader ? <div className={"mt-2 text-sm ps-2"} >No. of membership requests displaying: <span className="font-bold text-sm ms-3">{totalMembershipRequests}</span></div> : ""}
          </div>
          <div className='col-6 col-xxl-5'>
            <div className='text-end position-relative'>
              <button className='border-0 bg-transparent' type='button' onClick={() => { setShowModal(prev => !prev) }}><i className={`bi bi-funnel-fill text-xl ${showModal ? "color-yellow1" : ""}`} ref={filterIcon}></i></button>
              <FilterModalMembershipsRequest
                ref={filterComponent}
                showModal={showModal}
                setShowModal={setShowModal}
                collectionRecall={fetchData}
                handleFilterMembershipsRequest={handleFilterMembershipsRequest}
              />
            </div>
          </div>
        </div>
        <div className={`mt-3 ${!filterKeys.selectedMemberships.length && !filterKeys.selectedStatus.length ? "d-none" : ""}`}>
          {filterKeys && filterKeys.selectedMemberships && filterKeys.selectedMemberships.length ? <div className='d-flex align-items-start my-3 gap-3 text-sm font-medium'>
            <span className='text-nowrap'>Selected Membership</span> :
            <div className='d-flex align-items-start flex-wrap gap-3'>
              {filterKeys && filterKeys.selectedMemberships && filterKeys.selectedMemberships.map((item, index) => <div
                className='text-xxs font-medium pl-3 pe-0 py-1 border-1 background-gradient rounded-pill text-capitalize d-flex align-items-center justify-content-between'
                key={index}
              >
                {item}
                <button className='bg-transparent border-0 d-flex align-items-center' onClick={(e) => handleModifyFilter(e, item, 'membershipRequest')}>
                  <i className="bi bi-x-circle-fill text-md lineheight-0"></i>
                </button>
              </div>
              )}
            </div>
          </div> : ""}
          {filterKeys && filterKeys.selectedStatus && filterKeys.selectedStatus.length ? <div className='d-flex align-items-start my-3 gap-3 text-sm font-medium'>
            <span className='text-nowrap'>Selected Status</span> :
            <div className='d-flex align-items-start flex-wrap gap-3'>
              {filterKeys && filterKeys.selectedStatus && filterKeys.selectedStatus.map((item, index) => <div
                className='text-xxs font-medium pl-3 pe-0 py-1 border-1 background-gradient rounded-pill text-capitalize d-flex align-items-center justify-content-between'
                key={index}
              >
                {item}
                <button className='bg-transparent border-0 d-flex align-items-center' onClick={(e) => handleModifyFilter(e, item, 'status')}>
                  <i className="bi bi-x-circle-fill text-md lineheight-0"></i>
                </button>
              </div>
              )}
            </div>
          </div> : ""}
        </div>
      </div>
      <ContentWrapper additionalClass="font-body">
        <MembershipRequestsEntries membershipRequests={membershipRequests} />
        {membershipRequests.length ? <div className={`d-flex gap-2 justify-content-end align-items-center py-4 text-xs ${(Boolean(searchParams.get('searchQuery')) || Boolean(searchParams.get('filterQuery'))) ? "" : "me-4"}`}>
          {(membershipRequests.length !== totalMembershipRequests) ? <div>
            Rows per page:
            <div className="d-inline-block ms-1 ms-xl-3">
              <select name="item-per-page" id="item-per-page" value={membershipsRequestPerPage.current} className="border-0 shadow-none font-medium" onChange={handlerItemsPerPage}>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
          </div> : ""}
          {(Boolean(searchParams.get('searchQuery')) || Boolean(searchParams.get('filterQuery'))) && !loader && totalMembershipRequests > membershipRequests.length ?
            <button
              className="bg-transparent border-1 rounded-3 ml-12"
              type="button"
              onClick={handleShowAll}
            >Show All</button> : ""}
          {!Boolean(searchParams.get('searchQuery')) && !Boolean(searchParams.get('filterQuery')) ? <div className="d-flex align-items-center gap-3">
            {!loader && membershipRequests.length ? <div className="mx-6 mx-xl-12 font-medium">
              {count.current * membershipsRequestPerPage.current + 1}-{(count.current + 1) * membershipsRequestPerPage.current < totalMembershipRequests ? (count.current + 1) * membershipsRequestPerPage.current : totalMembershipRequests} of {totalMembershipRequests}
            </div> : <div className="mx-6 mx-xl-12 font-medium">loading...</div>}
            <button className={`font-body font-bold rounded-circle po-paginationBtn d-inline-block bg-transparent ${(!count.current || loader) ? "po-border border-secondary" : "color-black1 po-border po-border-yellow1"}`} type="button"
              onClick={previousPageDataFunc}
              disabled={!count.current || loader}
            ><i className="bi bi-chevron-left text-black font-black text-lg"></i></button>
            <button className={`font-body font-bold rounded-circle po-paginationBtn po-paginationBtn--next d-inline-block bg-transparent ${(pageCount - 1 === count.current) || loader ? "po-border border-secondary" : "color-black1 po-border po-border-yellow1"}`} type="button"
              onClick={nextPageDataFunc}
              disabled={(pageCount - 1 === count.current) || loader}><i className="bi bi-chevron-right text-black font-black text-lg"></i></button>
          </div> : <div className="px-2"></div>}
        </div> : ""}
      </ContentWrapper>
      {/* membership edit  -- start */}
      <Modal
        show={showEditMembershipModal}
        onHide={handleMembershipEdit}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header className="d-flex justify-content-between align-items-center">
          <Modal.Title className="font-bold px-5">Membership Request Edit</Modal.Title>
          <i
            className="bi bi-x-circle-fill text-4xl color-ternary cursor-pointer"
            onClick={() => {
              setShowEditMembershipModal(false)
              setMembershipFormEdit({
                ...membershipFormEdit,
                startDate: moment(new Date()).format("YYYY-MM-DD"),
                endDate: moment(new Date()).format("YYYY-MM-DD"),
                errors: {
                  startDate: " ",
                  endDate: ""
                }
              })
            }}
          ></i>
        </Modal.Header>
        <div className=" p-5 rounded font-body">
          <ModalBody>
            <form onSubmit={onSubmitMembershipEdit}>
              <div>
                <label className="font-semibold">Start Date :</label>
                <input
                  min={membershipFormEdit.membershipCreatedDate}
                  type="date"
                  value={membershipFormEdit.startDate}
                  className="po-edit-input"
                  name="startDate"
                  onChange={(e) => {
                    setMembershipFormEdit({
                      ...membershipFormEdit,
                      startDate: e.target.value,
                      errors: {
                        ...membershipFormEdit.errors,
                        startDate: ""
                      }
                    })
                  }}
                />
                {membershipFormEdit.errors.startDate &&
                  <p className="mt-2 text-danger">{membershipFormEdit.errors.startDate}</p>

                }
              </div>
              <div>
                {console.log(membershipFormEdit.membershipCreatedDate,"membershipFormEdit.membershipCreatedDatemembershipFormEdit.membershipCreatedDate")}
                <label className="font-semibold">End Date :</label>
                <input
                  min={membershipFormEdit.startDate}
                  type="date"
                  value={membershipFormEdit.endDate}
                  className="po-edit-input"
                  name="endDate"
                  onChange={(e) => {
                    setMembershipFormEdit({
                      ...membershipFormEdit,
                      endDate: e.target.value,
                      errors: {
                        ...membershipFormEdit.errors,
                        endDate: ""
                      }
                    })
                  }}
                />
                {membershipFormEdit.errors.endDate &&
                  <p className="mt-2 text-danger">{membershipFormEdit.errors.endDate}</p>

                }
              </div>
              <div className="d-flex justify-content-end mt-12">
                <Button
                  variant="secondary"
                  type="button"
                  disabled={showToaster}
                  className="me-2 px-6"
                  onClick={() => setShowEditMembershipModal(false)}>Cancel</Button>
                <Button
                  type="submit"
                  id="submit"
                  variant="success"
                  disabled={showToaster}
                  className="px-6"
                >Update</Button>
              </div>
            </form>
          </ModalBody>
        </div>
      </Modal>
      <Toaster
        showToaster={showToaster}
        setShowToaster={setShowToaster}
        bg={toasterDetails.bg}
        title={toasterDetails.title}
        bodyText={toasterDetails.bodyText}
        statusIcon={toasterDetails.statusIcon}
      />
    </Layout>
  )
}
export default MembershipRequests;