const PrimaryInput = ({ id, label, type = "text", placeholder, value, disabled, onChange, min, mandatory }) => {
  return (
    <>
      <label htmlFor={id} className="text-lg font-medium color-gray mb-3">{label} {mandatory ? <span className="text-danger">*</span> : ""}</label>
      {!min && <input
        id={id}
        name={id}
        type={type}
        className="po-edit-input mb-1"
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onChange={onChange}
      />}
      {min &&
        <input
          id={id}
          name={id}
          type={type}
          className="po-edit-input mb-1"
          placeholder={placeholder}
          value={value}
          min={min}
          disabled={disabled}
          onChange={onChange}
        />
      }
    </>
  )
}

export default PrimaryInput
