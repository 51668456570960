import React, { useEffect, useState } from "react";
import ContentWrapper from "../../Components/Global/ContentWrapper";
import Layout from "../../Layout/Layout";
import PageDescription from "../../Components/Global/PageDescription";
import { Table, Modal } from "react-bootstrap";
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  getDoc,
  query,
  orderBy,
} from "firebase/firestore";
import db from "../../firebase";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PrimaryButton from "../../Components/Global/Buttons/PrimaryButton";
import SecondaryButton from "../../Components/Global/Buttons/SecondaryButton";
import Dropdown from "react-bootstrap/Dropdown";
import Toaster from "../../Components/Global/Toast";
import ReactPaginate from "react-paginate";
import Skeleton from "react-loading-skeleton";
const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [showToaster, setShowToaster] = useState(false);
  const [isLoading, setIsLoading] = useState([]);
  const [toasterDetails, setToasterDetails] = useState({
    title: "",
    bodyText: "",
    bg: "",
    statusIcon: "",
  });
  //  const []
  // modal states
  const [show, setShow] = useState({ show: false, data: null });
  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    console.log(id);
    setShow({ show: true, data: notifications[id] });
  };

  // fetching entries
  const fetchRequests = async () => {
    const q = query(
      collection(db, "notifications_campaign"),
      orderBy("createdAt", "desc")
    );
    const querySnapshot = await getDocs(q);
    const newData = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    setNotifications(newData);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchRequests();
  }, []);

  // deleting entries
  const hadnleDelete = async (data) => {
    try {
      await deleteDoc(doc(db, "notifications_campaign", data.id));
      fetchRequests();
      handleClose();
    } catch (err) {
      console.log(err);
    }
  };

  // Editing method
  const history = useNavigate();
  const handleEdit = (id) => {
    history(`/notifications/edit-notifications/${id}`);
  };

  //  Resend Notification
  const resendNotification = async (id) => {
    setShowToaster(true);
    setToasterDetails((prev) => ({
      ...prev,
      title: "Sending...",
      bodyText: "Sending push notification",
      bg: "warning",
      statusIcon: (
        <div className="spinner-border text-warning me-2" role="status"></div>
      ),
    }));
    const token = localStorage.getItem("authenticate");
    await getDoc(doc(db, "notifications_campaign", id)).then((res) => {
      console.log(res.data(), "res");
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/notification/v2/notifications`,
          {
            body: res.data().body,
            title: res.data().title,
            url: res.data().url,
            internal_redirect: false,
            cities: res.data().cities
          },
          {
            headers: {
              developement: true,
              token: token,
            },
          }
        )
        .then((response) => {
          setToasterDetails((prev) => ({
            ...prev,
            title: "Success",
            bodyText: "Push notification sent successfully",
            bg: "success",
            statusIcon: (
              <i className="bi bi-check text-success text-3xl me-2"></i>
            ),
          }));
          setTimeout(() => {
            setShowToaster(false);
          }, 1000)
          console.log(response);
        })
        .catch((err) => {
          setToasterDetails((prev) => ({
            ...prev,
            title: "Error",
            bodyText: "Something went wrong",
            bg: "danger",
            statusIcon: (
              <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
            ),
          }));
          console.log(err);
        });
    });
    // console.log("Resending notification");
  };
  // paginations
  const NotificationEntries = ({ currentItems, itemOffset }) => {
    return (
      <div className="table-responsive po-table tableFixHead">
        <Table hover>
          <thead>
            <tr className="background-gray10">
              <th>
                <p className="text-nowrap text-xs text-xl-sm text-uppercase ps-3 color-black2 mb-0 py-2">
                  s. no
                </p>
              </th>
              <th>
                {" "}
                <p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">
                  Title
                </p>
              </th>
              <th>
                {" "}
                <p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">
                  Message Body
                </p>
              </th>
              <th className="text-nowrap text-sm text-uppercase"></th>
              <th className="text-nowrap text-sm text-uppercase"></th>
            </tr>
          </thead>
          <tbody>
            {!isLoading ? (
              <>
                {currentItems && currentItems.length
                  ? currentItems.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td className="align-middle ps-4">
                          <p className="text-sm mb-0 text-nowrap color-black ps-3">
                            {itemOffset + index + 1}.
                          </p>
                        </td>
                        <td className="align-middle">
                          <p
                            className="text-sm mb-0 color-black"
                            style={{ maxWidth: "300px" }}
                          >
                            {data.title && data.title}
                          </p>
                        </td>
                        <td className="align-middle">
                          <div style={{ maxWidth: "400px" }}>
                            <p className="text-sm mb-0 color-black">
                              {data.body && data.body}
                            </p>
                          </div>
                        </td>
                        <td className="align-middle">
                          <SecondaryButton
                            onClick={() => resendNotification(data.id)}
                            name="Resend"
                            additionalClass="text-xs"
                          />
                        </td>
                        <td className="align-middle">
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="link"
                              id="dropdown-basic"
                              className="text-black"
                            >
                              <i className="bi bi-three-dots-vertical text-4xl d-bloxk"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="border-0 shadow px-2">
                              <div
                                className="border-bottom font-bold text-sm py-2 text-black d-block ps-2 cursor-pointer"
                                onClick={() => handleEdit(data.id)}
                              >
                                Edit
                              </div>
                              <div
                                className="font-bold text-sm py-2 text-black d-block ps-2 cursor-pointer"
                                onClick={() => handleShow(index)}
                              >
                                Delete
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })
                  : ""}
              </>
            ) : (
              <tr>
                <td colSpan={5}>
                  {" "}
                  <Skeleton
                    inline={true}
                    count={5}
                    height={80}
                    style={{ marginBottom: "1rem" }}
                    baseColor="#dedede"
                    highlightColor="#cccccc"
                    duration={2}
                  />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    );
  };

  function PaginatedItems({ itemsPerPage }) {
    // We start with an empty list of items.
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
      // Fetch items from another resources.
      const endOffset = itemOffset + itemsPerPage;
      console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      setCurrentItems(notifications.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(notifications.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % notifications.length;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      setItemOffset(newOffset);
    };

    return (
      <>
        <NotificationEntries
          currentItems={currentItems}
          itemOffset={itemOffset}
        />
        <ReactPaginate
          nextLabel={<i className="bi bi-chevron-right"></i>}
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel={<i className="bi bi-chevron-left"></i>}
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination pe-4 pt-4 d-flex justify-content-end"
          // activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </>
    );
  }
  return (
    <Layout
      path="/notifications"
      additionalclass="d-flex flex-column"
    >
      <div className="d-flex justify-content-between align-items-center gap-3 gap-lg-5 font-body">
        <PageDescription title="All Push Notifications" caption="" />
        <PrimaryButton
          link="/notifications/create-notifications"
          name=" Add Notification"
          icon="bi-plus-circle"
        />
      </div>
      <ContentWrapper additionalClass="font-body pb-5">
        <PaginatedItems itemsPerPage={20} />
        {/* <Table hover responsive>
          <thead>
            <tr className="background-gray10">
              <th>
                <p className="text-nowrap text-xs text-xl-sm text-uppercase ps-3 color-black2 mb-0 py-2">
                  ID
                </p>
              </th>
              <th>
                {" "}
                <p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">
                  Title
                </p>
              </th>
              <th>
                {" "}
                <p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">
                  Message Body
                </p>
              </th>
              <th className="text-nowrap text-sm text-uppercase"></th>
              <th className="text-nowrap text-sm text-uppercase"></th>
            </tr>
          </thead>
          <tbody>
            {notifications &&
              notifications.map((data, index) => {
                return (
                  <tr key={index}>
                    <td className="align-middle ps-4">
                      <p className="text-sm mb-0 text-nowrap color-black font-semibold ps-3">
                        {index + 1}.
                      </p>
                    </td>
                    <td className="align-middle">
                      <p
                        className="text-sm mb-0 text-nowrap color-black font-semibold"
                        style={{ maxWidth: "200px" }}
                      >
                        {data.title && data.title}
                      </p>
                    </td>
                    <td className="align-middle">
                      <div style={{ maxWidth: "400px" }}>
                        <p className="text-sm mb-0 color-black font-semibold">
                          {data.body && data.body}
                        </p>
                      </div>
                    </td>
                    <td className="align-middle">
                      <SecondaryButton
                        onClick={() => resendNotification(data.id)}
                        name="Resend"
                      />
                    </td>
                    <td className="align-middle">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="link"
                          id="dropdown-basic"
                          className="text-black"
                        >
                          <i className="bi bi-three-dots-vertical text-4xl d-bloxk"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="border-0 shadow px-2">
                          <div
                            className="border-bottom font-bold text-sm py-2 text-dark d-block ps-2 cursor-pointer"
                            onClick={() => handleEdit(data.id)}
                          >
                            Edit
                          </div>
                          <div
                            className="font-bold text-sm py-2 text-dark d-block ps-2 cursor-pointer"
                            onClick={() => handleShow(index)}
                          >
                            Delete
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table> */}
      </ContentWrapper>
      <Modal show={show.show} onHide={handleClose}>
        <div className="background-appColor p-8 rounded font-body">
          <h5 className="text-2xl  color-ternary font-semibold ">
            Are you sure?
          </h5>
          <p className="background-appColor text-light border-bottom-0 py-1 ">
            You want to delete this Notification
          </p>
          <div className="d-flex justify-content-end mt-12">
            <SecondaryButton
              onClick={handleClose}
              name="Cancel"
              additionalClass="me-2 px-6 text-light"
            />
            <PrimaryButton
              onClick={() => hadnleDelete(show.data)}
              name="Delete"
            />
          </div>
        </div>
      </Modal>
      <Toaster
        showToaster={showToaster}
        setShowToaster={setShowToaster}
        bg={toasterDetails.bg}
        title={toasterDetails.title}
        bodyText={toasterDetails.bodyText}
        statusIcon={toasterDetails.statusIcon}
      />
    </Layout>
  );
};
export default Notifications;
