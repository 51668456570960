import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from 'react-hook-form';
// import { Button } from "react-bootstrap";
import { doc, getDoc, collection, getDocs, updateDoc } from "firebase/firestore";
import db from '../../../../firebase';
import Layout from '../../../../Layout/Layout';
import PageDescription from '../../../../Components/Global/PageDescription';
import ContentWrapper from '../../../../Components/Global/ContentWrapper';
import Toaster from '../../../../Components/Global/Toast';
import SuccessButton from '../../../../Components/Global/Buttons/SuccessButton';
import OutlinedButton from '../../../../Components/Global/Buttons/OutlinedButton';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useRef } from 'react';

const AdminUpdateCloseBooking = () => {
  const navigate = useNavigate();
  const { close_booking_id, club_id } = useParams();
  const [closeBookingDetails, setCloseBookingDetails] = useState({});
  const [showToaster, setShowToaster] = useState(false);
  const [nonOperationalDays, setNonOperationalDays] = useState([]);
  const [toasterDetails, setToasterDetails] = useState({
    title: "",
    bodyText: "",
    bg: "",
    statusIcon: ""
  })
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  
  const dateRef = useRef(null);
  useEffect(()=> {
    const parentRef = doc(collection(db, "clubs"), club_id );
    const subCollectionClosedBookings = collection(parentRef, "closed_bookings");
    getDocs(subCollectionClosedBookings)
    .then(response=> {
      setNonOperationalDays([...response.docs.map(item=> item.data().date)])
    })
    if (dateRef && dateRef.current) {
      dateRef.current.setFocus(); // Call the focus function on the input element
    }
  }, [])

  const onSubmit = async (data) => {
    const updateData = {
      id: close_booking_id,
      date: !data.date ? closeBookingDetails.date.toDate() : data.date,
      reason: data.message.trimStart().replace(/\s+/g, ' ').trimEnd(),
      updatedAt: new Date()
    };
    setShowToaster(true);
    setToasterDetails(prev => ({
      ...prev,
      title: "Updating...",
      bodyText: "Updating non-operational day",
      bg: "warning",
      statusIcon: <div className="spinner-border text-warning me-2" role="status">
      </div>
    }))
    const clubResponse = await updateDoc(doc(db, "clubs", club_id, "closed_bookings", close_booking_id), updateData)
      .then(() => {
        setToasterDetails(prev => ({
          ...prev,
          title: "Success",
          bodyText: "non-operational day successfully updated",
          bg: "success",
          statusIcon: <i className="bi bi-check text-success text-3xl me-2"></i>
        }))
        setTimeout(() => {
          navigate(`/clubs/close-booking/${club_id}`, { replace: true })
        }, 2000)
      })
      .catch(err => {
        setToasterDetails(prev => ({
          ...prev,
          title: "Error",
          bodyText: "Something went wrong",
          bg: "danger",
          statusIcon: <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
        }))
        setTimeout(() => {
          setShowToaster(false);
        }, 2000)
      })
  };
  const getClosedBookingData = async () => {
    const subColRef = (await getDoc(doc(db, "clubs", club_id, "closed_bookings", close_booking_id))).data();
    console.log(subColRef, 'subColRef');
    setCloseBookingDetails(subColRef);
  };
  useEffect(() => {
    getClosedBookingData();
  }, []);
  const validateField = (value) => {
    if (value.trim() === '') { return "This field should not be empty."; }
    if (/^\s+$/.test(value)) { return "This field should not contain only whitespace characters."; }
    return true;
  };
  const breadCrumbData = {
    title: "Non Operational Days",
    caption: "/ Edit"
  }
  return (
    <Layout link={`/clubs/close-booking/${club_id}`} breadCrumbData={breadCrumbData}>
      <div className="d-flex justify-content-between align-items-center gap-3 gap-lg-5 font-body">
        <PageDescription title="Update Non-Operational Days" caption="" />
      </div>
      <ContentWrapper additionalClass="p-6 font-body">
        {closeBookingDetails.hasOwnProperty('reason') && closeBookingDetails.hasOwnProperty('date') && <form onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="date" className="text-md">
            Date:
          </label>
          {/* <input
            className="po-edit-input"
            type="date"
            id="date"
            defaultValue={closeBookingDetails.date}
            {...register("date", { required: true })}
          /> */}
          
          <Controller
            control={control}
            name="date"
            rules={{ required: 'Date is required' }} // Add the required rule
            defaultValue={closeBookingDetails.date.toDate()}
            render={({ field }) => (
              <DatePicker
                {...field}
                selected={!field.value ? closeBookingDetails.date.toDate() : field.value }
                // defaultValue={closeBookingDetails.date.toDate()}
                value={!field.value ? closeBookingDetails.date.toDate() : field.value }
                onChange={(date) => field.onChange(date)}
                dateFormat="dd/MM/yyy"
                placeholderText="dd/mm/yyyy"
                className='po-edit-input'
                minDate={new Date()}
                excludeDates={nonOperationalDays.map(item => item.toDate())}
                ref={dateRef}
              />
            )}
            />
            {errors.date && (
              <p className="text-sm text-danger">{errors.date.message}</p>
            )}

          <label htmlFor="message" className="text-md mt-3">
            Reason:
          </label>
          <textarea
            className="po-edit-input"
            id="message"
            defaultValue={closeBookingDetails.reason}
            {...register("message", { validate: validateField })}
          />
          {errors.message && (
            <p className="text-sm text-danger">Please enter a message.</p>
          )}

          <div className="text-end mt-4">
            {showToaster && <img
              src="/assets/icons/loading_icon.gif"
              alt="processing" width={100} />}
            <OutlinedButton
              link={`/clubs/close-booking/${club_id}`}
              name="Cancel"
              style={{pointerEvents: !showToaster ? '' : 'none'}}
            />
            <SuccessButton
              type="submit"
              name="Update Day"
              icon="bi-check-circle"
              additionalClass="ms-3"
              disabled={showToaster}
            />
            {/* <Button variant="success" type="submit">
              Submit
            </Button> */}
          </div>
        </form>}
      </ContentWrapper>
      <Toaster
        showToaster={showToaster}
        setShowToaster={setShowToaster}
        bg={toasterDetails.bg}
        title={toasterDetails.title}
        bodyText={toasterDetails.bodyText}
        statusIcon={toasterDetails.statusIcon}
      />
    </Layout>
  );
};
export default AdminUpdateCloseBooking;
