// I am storing id of last item of previous page so that after page reload pagination should persist
import React, { useState, useEffect, useCallback, useRef } from "react";
import { Table } from "react-bootstrap";
import moment from "moment";
import SecondaryButton from "../Global/Buttons/SecondaryButton";
import { and, collection, doc, endAt, endBefore, getCountFromServer, getDoc, getDocs, limit, limitToLast, orderBy, query, startAfter, startAt, where } from "firebase/firestore";
import db from "../../firebase";
import Ripple from "../Global/Buttons/Ripple";
import Skeleton from "react-loading-skeleton";
import { Link, useSearchParams } from "react-router-dom";

const sortIcon = "/assets/icons/icon-sort.svg";
const sortIconAsc = "/assets/icons/icon-sort-asc.svg";
const sortIconDesc = "/assets/icons/icon-sort-desc.svg";

const EventBookings = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const count = useRef(0);
  const changeOrder = useRef(0);
  const nextPage = useRef(1);
  const currentPageId = useRef("");
  const sortBy = useRef({
      orderByKey: "createdAt",
      order: "desc"
    })
  const bookingsPerPage = useRef(20);
  const filterKeysRef = useRef({
    selectedLocations: [],
    selectedEvents: [],
    selectedStatus: []
  });
  const targetElementForSort = useRef("");
  const searchQueryInputRef = useRef("");
  const [loader, setLoader] = useState(true);
  const [eventBookingData, setEventBookingData] = useState([]);
  const [totalBookings, setTotalBookings] = useState(null);
  const [pageCount, setPageCount] = useState(null);
  const pageNo = searchParams.get('pageNo');

    const fetchBookingsCount = async () => { // It is giving me total no. of event_entry_bookings and no. of pages
      const bookingsCount = await getCountFromServer(query(collection(db, "event_entry_bookings")));
      setTotalBookings(bookingsCount.data().count);
      setPageCount(Math.ceil(bookingsCount.data().count/bookingsPerPage.current));
    }

    const pageReloadFunc = useCallback(async() => {
      bookingsPerPage.current = +searchParams.get('bookingsPerPage');
      setLoader(true);
      if(nextPage.current===1) {
        const docSnap = count.current && await getDoc(doc(collection(db, "event_entry_bookings"), currentPageId.current));
        getDocs(query(collection(db, "event_entry_bookings"), orderBy(sortBy.current.orderByKey, sortBy.current.order), startAfter(docSnap), limit(bookingsPerPage.current)))
          .then((docs) => {
            setEventBookingData([]);
            docs.forEach((doc) => {
              setEventBookingData((prev) => [...prev, doc.data()]);
            });
            setLoader(false);
          })
          .catch((err) => {
            setLoader(false);
          });
      } else {
        const docSnap = count.current && await getDoc(doc(collection(db, "event_entry_bookings"), currentPageId.current));
        getDocs(query(collection(db, "event_entry_bookings"), orderBy(sortBy.current.orderByKey, sortBy.current.order), endBefore(docSnap), limitToLast(+bookingsPerPage.current)))
        .then((docs) => {
          setEventBookingData([]);
          docs.forEach((doc) => {
            setEventBookingData((prev) => [...prev, doc.data()]);
          });        
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
        });
      }
    }, [nextPage])

  const fetchData = () => { // this function will execute on first render only when count value will be zero or eventPageNo will be 1 and if items per page will change
    fetchBookingsCount();
    setLoader(true);
    getDocs(query(collection(db, "event_entry_bookings"), orderBy(sortBy.current.orderByKey, sortBy.current.order), limit(+bookingsPerPage.current)))
      .then((docs) => {
        setEventBookingData([]);
        docs.forEach((doc) => {
          setEventBookingData((prev) => [...prev, doc.data()]);
        });
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  }
  
  const previousPageDataFunc = async () => { // this function will be called when previous button clicked
    setLoader(true);
    count.current = count.current-1;
    if(!count.current) {
      fetchData();
      setSearchParams({});
      return;
    }
    const docSnap = count.current && await getDoc(doc(collection(db, "event_entry_bookings"), eventBookingData[0].id));
    getDocs(query(collection(db, "event_entry_bookings"), orderBy(sortBy.current.orderByKey, sortBy.current.order), endBefore(docSnap), limitToLast(+bookingsPerPage.current)))
      .then((docs) => {
        setEventBookingData([]);
        docs.forEach((doc) => {
          setEventBookingData((prev) => [...prev, doc.data()]);
        });
        if(Boolean(searchParams.get('tab'))) setSearchParams({tab: searchParams.get('tab'), pageNo: count.current+1, prevNext: JSON.stringify([docs.docs[0].id, docs.docs[docs.docs.length-1].id]), currentPageId: docSnap.id, bookingsPerPage: bookingsPerPage.current , n: 0, sortBy: JSON.stringify(sortBy.current), changeOrder: changeOrder.current })
        else setSearchParams({pageNo: count.current+1, prevNext: JSON.stringify([docs.docs[0].id, docs.docs[docs.docs.length-1].id]), currentPageId: docSnap.id, bookingsPerPage: bookingsPerPage.current , n: 0, sortBy: JSON.stringify(sortBy.current), changeOrder: changeOrder.current })
        // if count value will be greater than 0 then will set data in the url otherwise nothing needs to set in the url
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  }

  const nextPageDataFunc = async () => { // this will called when next button will called
    setLoader(true);
    count.current = count.current+1;
    const docSnap = count.current && await getDoc(doc(collection(db, "event_entry_bookings"), eventBookingData[eventBookingData.length-1].id));
    getDocs(query(collection(db, "event_entry_bookings"), orderBy(sortBy.current.orderByKey, sortBy.current.order), startAfter(docSnap), limit(bookingsPerPage.current)))
      .then((docs) => {
        setEventBookingData([]);
        docs.forEach((doc) => {
          setEventBookingData((prev) => [...prev, doc.data()]);
        });
        if(Boolean(searchParams.get('tab'))) setSearchParams({tab: searchParams.get('tab'), pageNo: count.current+1, prevNext: JSON.stringify([docs.docs[0].id, docs.docs[docs.docs.length-1].id]), currentPageId: docSnap.id, bookingsPerPage: bookingsPerPage.current, n: 1, sortBy: JSON.stringify(sortBy.current), changeOrder: changeOrder.current})
        else setSearchParams({pageNo: count.current+1, prevNext: JSON.stringify([docs.docs[0].id, docs.docs[docs.docs.length-1].id]), currentPageId: docSnap.id, bookingsPerPage: bookingsPerPage.current, n: 1, sortBy: JSON.stringify(sortBy.current), changeOrder: changeOrder.current})
        /* setSearchParams => eventPageno will help to track the page no, prevNext is storing id of first and last
          item which will help to go prev and next page.
          currentPageId will help me to be on the page same page when page reload
        */
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  }

  // Items Per Page Change **************************************************

  const handleItemsPerPage = (e) => { // I am recalling the fetchData func when items per page whenever change but not calling fetchBookingsCount because it won't change if items per page will change
    count.current = 0;
    setLoader(true);
    bookingsPerPage.current = +e.target.value;
    if(Boolean(searchParams.get('prevNext'))) {
      setSearchParams({bookingsPerPage: bookingsPerPage.current})
    }
    if(searchParams.get('searchQuery') || searchParams.get('filterQuery')) {
      setSearchParams({...Object.fromEntries([...searchParams]), bookingsPerPage: bookingsPerPage.current})
    }
    if(Boolean(searchParams.get('sortBy')) && !Boolean(searchParams.get('filterQuery')) && !Boolean(searchParams.get('searchQuery'))) {
      setSearchParams({sortBy: JSON.stringify(sortBy.current), changeOrder: changeOrder.current, bookingsPerPage: bookingsPerPage.current})
    }
    // const currentParams = Object.fromEntries([...searchParams]);
    // setSearchParams({...currentParams, bookingsPerPage: bookingsPerPage.current});
    count.current = 0;
    // fetchBookingsCount();
    // fetchData();
    if(!Boolean(searchParams.get('searchQuery')) && !Boolean(searchParams.get('filterQuery'))) {
      // fetchBookingsCount(); // this function is called because on items per page change no of page will change
      fetchData();
    }
    else if(Boolean(searchParams.get("searchQuery"))) fetchSearchedBookings(searchParams.get("searchQuery"));
    else if(Boolean(searchParams.get("filterQuery"))) {
      handleFilterBookings(JSON.parse(searchParams.get("filterQuery")))
    }
    else console.log('endingg');
  }

  // searching goes here ----------------------------------------------------------------------------------------

  const fetchSearchedBookings = useCallback(
    debounce((val) => {
      count.current=0;
      // setEvents([]);
      if(val && searchQueryInputRef.current) {
        setLoader(true);
        const searchedBookingsByBookingId = query(collection(db, "event_entry_bookings"), orderBy("booking_id"), where("booking_id", "==", +val));
        const searchedBookingsByUserName = query(collection(db, "event_entry_bookings"), orderBy("user.name_lower_case"), startAt(val.replace(/\s+/g, ' ').trim().toLowerCase()),endAt(`${val.toLowerCase()}\uf8ff`));
        const searchedBookingsByEventName = query(collection(db, "event_entry_bookings"), orderBy("event_name_lower_case"), startAt(val.replace(/\s+/g, ' ').trim().toLowerCase()),endAt(`${val.toLowerCase()}\uf8ff`));
        const searchedBookingsByUserEmail = query(collection(db, "event_entry_bookings"), orderBy("user.email_lower_case"), startAt(val.replace(/\s+/g, ' ').trim().toLowerCase()),endAt(`${val.toLowerCase()}\uf8ff`));
        let localSearchedArray = [];
        getDocs(searchedBookingsByBookingId).then((docSnapshots) => {
          docSnapshots.forEach((docs) => {
            localSearchedArray = [...localSearchedArray, docs.data()]
          });
        });
        getDocs(searchedBookingsByUserName).then((docSnapshots) => {
          docSnapshots.forEach((docs) => {
            localSearchedArray = [...localSearchedArray, docs.data()]
          });
        });
        getDocs(searchedBookingsByEventName).then((docSnapshots) => {
          docSnapshots.forEach((docs) => {
            localSearchedArray = [...localSearchedArray, docs.data()]
          });
        });
        getDocs(searchedBookingsByUserEmail).then((docSnapshots) => {
          docSnapshots.forEach((docs) => {
            localSearchedArray = [...localSearchedArray, docs.data()]
          });
          uniqueSearchedBookings(localSearchedArray, val);
          setLoader(false);
        });
        searchParams.delete('filterQuery');
      }
    }, 1000),
    []);

    const uniqueSearchedBookings = (localSearchedArray, val) => {
      switch(+changeOrder.current) {
        case 0:
        setEventBookingData([...new Map(localSearchedArray.map(item =>
          [item['id'], item])).values()].splice(0, bookingsPerPage.current));
          break;
        case 1: 
        setEventBookingData([...new Map(localSearchedArray.map(item =>
          [item['id'], item])).values()].sort((a, b) => (targetElementForSort.current === "user.name_lower_case" ? a.user.name_lower_case : a[targetElementForSort.current]) > (targetElementForSort.current === "user.name_lower_case" ? b.user.name_lower_case : b[targetElementForSort.current]) ? 1 : (targetElementForSort.current === "user.name_lower_case" ? a.user.name_lower_case : a[targetElementForSort.current]) < (targetElementForSort.current === "user.name_lower_case" ? b.user.name_lower_case : b[targetElementForSort.current]) ? -1 : 0 ).splice(0, bookingsPerPage.current));
          break;
        default:
        setEventBookingData([...new Map(localSearchedArray.map(item =>
          [item['id'], item])).values()].sort((a, b) => (targetElementForSort.current === "user.name_lower_case" ? a.user.name_lower_case : a[targetElementForSort.current]) < (targetElementForSort.current === "user.name_lower_case" ? b.user.name_lower_case : b[targetElementForSort.current]) ? 1 : (targetElementForSort.current === "user.name_lower_case" ? a.user.name_lower_case : a[targetElementForSort.current]) > (targetElementForSort.current === "user.name_lower_case" ? b.user.name_lower_case : b[targetElementForSort.current]) ? -1 : 0 ).splice(0, bookingsPerPage.current));
          
      }
      let totalBookingsCount = [...new Map(localSearchedArray.map(item =>
        [item['id'], item])).values()].length;
      setTotalBookings(totalBookingsCount);
    }
    function debounce(fn, delay) {
      let timer;
      return function () {
        let context = this,
          args = arguments;
        clearTimeout(timer);
        timer = setTimeout(() => {
          fn.apply(context, args);
        }, delay);
      };
    }

  // filter goes here *****************************************************************************************

  const handleFilterBookings = async (filterState) => {
    count.current = 0
    setLoader(true);
    let arr=[];
    filterKeysRef.current = filterState;
    // reseting search input field in order to remove search and apply filter  
    searchQueryInputRef.current="";
    // ----------------------------------------
    let selectedLocations = filterKeysRef.current.selectedLocations.length;
    let selectedEvents = filterKeysRef.current.selectedEvents.length;
    let filteredStatus = filterKeysRef.current.selectedStatus.length;
    if(selectedLocations && selectedEvents && filteredStatus) {
      arr = [where("club_location", "in", filterKeysRef.current.selectedLocations), where("event_id", "in", filterKeysRef.current.selectedEvents.map(item=>item.id)), where("status", "in", filterKeysRef.current.selectedStatus)]
    } else if(selectedLocations && selectedEvents) {
      arr = [where("event_id", "in", filterKeysRef.current.selectedEvents.map(item=>item.id)), where("club_location", "in", filterKeysRef.current.selectedLocations)]
    } else if(selectedEvents && filteredStatus) {
      arr = [where("event_id", "in", filterKeysRef.current.selectedEvents.map(item=>item.id)), where("status", "in", filterKeysRef.current.selectedStatus)]
    } else if(selectedLocations && filteredStatus) {
      arr = [where("club_location", "in", filterKeysRef.current.selectedLocations), where("status", "in", filterKeysRef.current.selectedStatus)]
    } else if(selectedEvents) {
      arr = [where("event_id", "in", filterKeysRef.current.selectedEvents.map(item=>item.id))]
    } else if(filteredStatus) {
      arr = [where("status", "in", filterKeysRef.current.selectedStatus)]
    } else if(selectedLocations) {
      arr = [where("club_location", "in", filterKeysRef.current.selectedLocations)]
    } else console.log("not applicable");
    let bookings_data = (selectedLocations || selectedEvents || filteredStatus) && await getDocs(query(collection(db, "event_entry_bookings"), and(...arr), orderBy(sortBy.current.orderByKey, sortBy.current.order)))
    if(bookings_data) {
      let localFilteredBookings = []
      bookings_data.size && bookings_data.forEach(event => {
        localFilteredBookings = [...localFilteredBookings, event.data()];
      })
      setEventBookingData([...localFilteredBookings].splice(0, bookingsPerPage.current));
      setTotalBookings(bookings_data.size);
      setLoader(false);
    }
  }

  // show all searched booking or filtered bookings

  const handleShowAll = () => {
    setLoader(true);
    bookingsPerPage.current = +totalBookings;
    setSearchParams({ ...Object.fromEntries([...searchParams]), bookingsPerPage: bookingsPerPage.current, showAllEventbookings: true })
  }

  // sorting *******************************************************************************************************

  const handleSorting = (e, localId) => {
    setLoader(true);
    if(targetElementForSort.current !== localId) {
      changeOrder.current = 0;
      sortBy.current = {orderByKey: localId, order: "asc"}
      targetElementForSort.current = localId;
    }
    if(count.current) { // coming back to first page when you will be on other page
      count.current=0;
      sortBy.current = {orderByKey: localId, order: "asc"}
      // fetchBookingsCount();
      fetchData();
        setSearchParams({bookingsPerPage: bookingsPerPage.current , sortBy: JSON.stringify(sortBy.current), changeOrder: 1 })
      return;
    }
    if(searchParams.get('searchQuery') || searchParams.get('filterQuery')) {
      if(Boolean(searchParams.get('searchQuery'))) {
        if(!changeOrder.current) {
          changeOrder.current = changeOrder.current + 1;
          sortBy.current = {orderByKey: targetElementForSort.current, order: "asc"}
          setEventBookingData([...eventBookingData].sort((a, b) => a[targetElementForSort.current] > b[targetElementForSort.current] ? 1 : a[targetElementForSort.current] < b[targetElementForSort.current] ? -1 : 0 ).splice(0,bookingsPerPage.current));
        }
        else if(changeOrder.current===1) {
          changeOrder.current = changeOrder.current + 1;
          sortBy.current = {orderByKey: targetElementForSort.current, order: "desc"} 
          setEventBookingData([...eventBookingData].sort((a, b) => a[targetElementForSort.current] < b[targetElementForSort.current] ? 1 : a[targetElementForSort.current] > b[targetElementForSort.current] ? -1 : 0 ).splice(0,bookingsPerPage.current));
        }
        else {
          changeOrder.current = 0;
          setLoader(true);
          sortBy.current = {orderByKey: "createdAt", order: "desc"} 
          fetchSearchedBookings(searchParams.get('searchQuery'));
        }
      }
      else {
        if(!changeOrder.current) {
          changeOrder.current = changeOrder.current + 1;
          sortBy.current = {orderByKey: targetElementForSort.current, order: "asc"}
          setEventBookingData([...eventBookingData].sort((a, b) => a[targetElementForSort.current] > b[targetElementForSort.current] ? 1 : a[targetElementForSort.current] < b[targetElementForSort.current] ? -1 : 0 ).splice(0,bookingsPerPage.current));
        }
        else if(changeOrder.current===1) {
          changeOrder.current = changeOrder.current + 1;
          sortBy.current = {orderByKey: targetElementForSort.current, order: "desc"} 
          setEventBookingData([...eventBookingData].sort((a, b) => a[targetElementForSort.current] < b[targetElementForSort.current] ? 1 : a[targetElementForSort.current] > b[targetElementForSort.current] ? -1 : 0 ).splice(0,bookingsPerPage.current));
        }
        else {
          changeOrder.current = 0;
          setLoader(true);
          sortBy.current = {orderByKey: "createdAt", order: "desc"} 
          handleFilterBookings(JSON.parse(searchParams.get('filterQuery')));
        }
      }
    }
    else {
      if(!changeOrder.current) {
        changeOrder.current = changeOrder.current + 1;
        sortBy.current = {orderByKey: targetElementForSort.current, order: "asc"}
      }
      else if(changeOrder.current===1) {
        changeOrder.current = changeOrder.current + 1;
        sortBy.current = {orderByKey: targetElementForSort.current, order: "desc"}
      }
      else {
        changeOrder.current = 0;
        sortBy.current = {orderByKey: "createdAt", order: "desc"}
      }
    }
    const currentParams = Object.fromEntries([...searchParams]);
    setSearchParams({...currentParams, sortBy: JSON.stringify(sortBy.current), changeOrder: changeOrder.current});
  }

  // useEffect goes here ----------------------------------------------------------------------------------------

  useEffect(() => {
    if(Boolean(searchParams.get('bookingsPerPage'))) bookingsPerPage.current = +searchParams.get('bookingsPerPage'); // this will be used when searching or filter will be removed and show all will be applied
    if(Boolean(searchParams.get('showAllEventbookings'))) {
      if(Boolean(searchParams.get('searchQuery'))) fetchSearchedBookings(searchParams.get('searchQuery'))
      if(Boolean(searchParams.get('filterQuery'))) handleFilterBookings(JSON.parse(searchParams.get('filterQuery')))
      return;
    }

    if(Boolean(JSON.parse(searchParams.get('sortBy')))) {
      sortBy.current = JSON.parse(searchParams.get('sortBy'));
      targetElementForSort.current = sortBy.current.orderByKey;
      changeOrder.current = +searchParams.get('changeOrder');
      if(Boolean(searchParams.get('searchQuery')) && !Boolean(searchParams.get('prevNext'))) {
        searchQueryInputRef.current = searchParams.get('searchQuery')
        filterKeysRef.current = {
          selectedLocations: [],
          selectedEvents: [],
          selectedStatus: []
        };
        fetchSearchedBookings(searchParams.get('searchQuery'));
        return
      } 
      if(Boolean(searchParams.get('filterQuery')) && !Boolean(searchParams.get('prevNext'))) {
        searchQueryInputRef.current = "";
        filterKeysRef.current = JSON.parse(searchParams.get('filterQuery'));
        handleFilterBookings(JSON.parse(searchParams.get('filterQuery')));
        return
      }
      if(!Boolean(searchParams.get('filterQuery')) && !Boolean(searchParams.get('prevNext')) && !Boolean(searchParams.get('searchQuery'))) {
        searchQueryInputRef.current = "";
        filterKeysRef.current = {
          selectedLocations: [],
          selectedEvents: [],
          selectedStatus: []
        };
        count.current = 0;
        fetchData();
      }
    }
    if(Boolean(searchParams.get('bookingsPerPage')) && searchParams.size===1 && !eventBookingData.length) { // if page will be refreshed...
      bookingsPerPage.current = +searchParams.get('bookingsPerPage');
      count.current=0
      fetchData();
    }
    if(Boolean(searchParams.get('searchQuery')) || Boolean(searchParams.get('filterQuery'))) {
      if(Boolean(searchParams.get('sortBy'))) sortBy.current = JSON.parse(searchParams.get('sortBy'));
      if(Boolean(searchParams.get('searchQuery'))) {
        searchQueryInputRef.current = searchParams.get('searchQuery')
        filterKeysRef.current = {
          selectedLocations: [],
          selectedEvents: [],
          selectedStatus: []
        };
        fetchSearchedBookings(searchParams.get('searchQuery'));
      } if(Boolean(searchParams.get('filterQuery'))) {
        searchQueryInputRef.current = "";
        filterKeysRef.current = JSON.parse(searchParams.get('filterQuery'));
        handleFilterBookings(JSON.parse(searchParams.get('filterQuery')));
      }
    }
    if(!searchParams.size || (searchParams.get('tab') && searchParams.size===1) || (!Boolean(searchParams.get('searchQuery')) && !Boolean(searchParams.get('filterQuery')) && !Boolean(searchParams.get("prevNext")) && !Boolean(searchParams.get("bookingsPerPage")))) {
      count.current = 0;
      bookingsPerPage.current = 20;
      searchQueryInputRef.current="";
      fetchData();
      return;
    }
    if(Boolean(searchParams.get('prevNext')) && (!eventBookingData.length)) {
      nextPage.current = +searchParams.get("n"); // for tracking which button is clicked next or prev. it will help when page reload to identify which query should be used in order to reload the page(startAfter or lastToEnd)
      count.current = Boolean(pageNo) ? pageNo - 1 : count.current;
      currentPageId.current = searchParams.get('currentPageId');
      fetchBookingsCount();
      pageReloadFunc();
      return;
    }
    if(!Boolean(searchParams.get('filterQuery'))) {
      filterKeysRef.current = {
        selectedLocations: [],
        selectedEvents: [],
        selectedStatus: []
      }
    }
    if(filterKeysRef.current && filterKeysRef.current.selectedEvents && filterKeysRef.current.selectedStatus && !filterKeysRef.current.selectedEvents.length && !filterKeysRef.current.selectedStatus.length && !Boolean(searchParams.get('searchQuery')) && !Boolean(searchParams.get('filterQuery')) && !Boolean(searchParams.get('prevNext'))) {
      fetchData();
    }
    // if(Boolean(searchParams.get('prevNext')) && Boolean(searchParams.get('sortBy'))) {
    //   nextPage.current = +searchParams.get("n")
    // }
  }, [searchParams]);
  // useEffect ends here***********************************************************
  const TableBookingEntries = ({ eventBookingData }) => {
    return (
      <div className="table-responsive po-table tableFixHead-tabs">
        <Table hover>
          <thead>
            <tr className="background-gray10 position-sticky top-0">
              <th>
                <p className="text-nowrap text-xs text-xl-sm text-uppercase ps-3 color-black2 mb-0 py-2">
                  S. No
                </p>
              </th>
              <th valign="middle">
                <span
                  onClick={(e)=>handleSorting(e, "booking_id")} 
                  className={`cursor-pointer text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2 d-inline-flex align-items-center gap-1 ${targetElementForSort.current!=="booking_id" || !changeOrder.current ? "" : "color-yellow1" }`}>
                  Booking ID
                  <img src={(targetElementForSort.current!=="booking_id" || !changeOrder.current) ? sortIcon : changeOrder.current===1 ? sortIconAsc : sortIconDesc } alt="sort-icon" height={12} />
                </span>
              </th>
              <th valign="middle">
                <span
                  onClick={(e)=>handleSorting(e, "user.name_lower_case")} 
                  className={`cursor-pointer text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2 d-inline-flex align-items-center gap-1 ${targetElementForSort.current!=="user.name_lower_case" || !changeOrder.current ? "" : "color-yellow1" }`}>
                  Name
                  <img src={(targetElementForSort.current!=="user.name_lower_case" || !changeOrder.current) ? sortIcon : changeOrder.current===1 ? sortIconAsc : sortIconDesc } alt="sort-icon" height={12} />
                </span>
              </th>
              <th>
                <p className="text-nowrap text-xs text-xl-sm text-uppercase  color-black2 mb-0 py-2">
                  Phone no
                </p>
              </th>
              <th>
                <p className="text-nowrap text-xs text-xl-sm text-uppercase  color-black2 mb-0 py-2">
                  email ID
                </p>
              </th>
              <th valign="middle">
                <span 
                  onClick={(e)=>handleSorting(e, "event_name_lower_case")}
                  className={`cursor-pointer text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2 d-inline-flex align-items-center gap-1 ${targetElementForSort.current!=="event_name_lower_case" || !changeOrder.current ? "" : "color-yellow1" }`}>
                  EVENT NAME
                  <img src={(targetElementForSort.current!=="event_name_lower_case" || !changeOrder.current) ? sortIcon : changeOrder.current===1 ? sortIconAsc : sortIconDesc } alt="sort-icon" height={12} />
                </span>
              </th>
              <th>
                <p className="text-nowrap text-xs text-xl-sm text-uppercase  color-black2 mb-0 py-2">
                  Event Banner
                </p>
              </th>
              <th>
                <p className="text-nowrap text-xs text-xl-sm text-uppercase  color-black2 mb-0 py-2">
                  guest COUNT
                </p>
              </th>
              <th>
                <p className="text-nowrap text-xs text-xl-sm text-uppercase  color-black2 mb-0 py-2">
                  Current Membership
                </p>
              </th>
              <th valign="middle">
                <div 
                  onClick={(e)=>handleSorting(e, "createdAt")}
                  className={`cursor-pointer text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2 d-inline-flex align-items-center gap-1 ${targetElementForSort.current!=="createdAt" || !changeOrder.current ? "" : "color-yellow1" }`}>
                  Booked On
                  <img src={(targetElementForSort.current!=="createdAt" || !changeOrder.current) ? sortIcon : changeOrder.current===1 ? sortIconAsc : sortIconDesc } alt="sort-icon" height={12} />
                </div>
              </th>
              <th valign="middle">
                <div 
                  onClick={(e)=>handleSorting(e, "booking_date")}
                  className={`cursor-pointer text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2 d-inline-flex align-items-center gap-1 ${targetElementForSort.current!=="booking_date" || !changeOrder.current ? "" : "color-yellow1" }`}>
                  BOOKED FOR
                  <img src={(targetElementForSort.current!=="booking_date" || !changeOrder.current) ? sortIcon : changeOrder.current===1 ? sortIconAsc : sortIconDesc } alt="sort-icon" height={12} />
                </div>
              </th>
              <th valign="middle">
                <span
                  onClick={(e)=>handleSorting(e, "status")} 
                  className={`cursor-pointer text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2 d-inline-flex align-items-center gap-1 ${targetElementForSort.current!=="status" || !changeOrder.current ? "" : "color-yellow1" }`}>
                  STATUS
                  <img src={(targetElementForSort.current!=="status" || !changeOrder.current) ? sortIcon : changeOrder.current===1 ? sortIconAsc : sortIconDesc } alt="sort-icon" height={12} />
                </span>
              </th>
              <th>
                <p className="text-nowrap text-xs text-xl-sm text-uppercase  color-black2 mb-0 py-2"></p>
              </th>
            </tr>
          </thead>
          <tbody>
            {!loader ? (
              <>
                {eventBookingData && eventBookingData.length
                  ? eventBookingData.map((event, index) => {
                    const date = moment(
                      new Date(
                        event.booking_date.seconds * 1000
                      ).toISOString()
                    ).format("DD MMM YYYY");
                    const booking_date = moment(
                      new Date(
                        event.createdAt.seconds * 1000
                      ).toISOString()
                    ).format("DD MMM YYYY");
                    return (
                        <tr key={index}>
                          <td className="align-middle">
                            <p className="ps-3 text-sm mb-0 text-nowrap text-black">
                              {count.current*bookingsPerPage.current + index + 1}
                            </p>
                          </td>
                          <td className="align-middle">
                            {" "}
                            <p className="text-sm mb-0 text-nowrap text-black">
                              {event && event.booking_id}
                            </p>
                          </td>
                          <td className="align-middle">
                            {" "}
                            <Link 
                              to={`/bookings/event-details/${event.id}`} 
                              target="_blank" className="text-sm mb-0 text-nowrap text-dark text-capitalize">
                              {event && event.user.name}
                            </Link>
                          </td>
                          <td className="align-middle">
                            {" "}
                            <p className="text-sm mb-0 text-nowrap text-black">
                              {event.user && event.user.phone_no
                                ? event.user.phone_no
                                : ""}
                            </p>
                          </td>
                          <td className="align-middle">
                            {" "}
                            <p className="text-sm mb-0 text-nowrap text-black">
                              {event.user && event.user.email_lower_case
                                ? event.user.email_lower_case
                                : ""}
                            </p>
                          </td>
                          <td className="align-middle">
                            {" "}
                            <p
                              className="text-sm mb-0 text-wrap text-black"
                              style={{ maxWidth: "250px", minWidth: "150px", lineHeight: "20px" }}
                            >
                            {event && event.event_name.length > 45 ? event.event_name.substring(0,45) + "..." : event.event_name}
                            </p>
                          </td>
                          <td className="align-middle" align="center">
                            <div
                              className="d-flex justify-content-center align-items-center border rounded overflow-hidden"
                              style={{ height: "60px", width: "60px" }}
                            >
                              {event.event_display_image ? (
                                <img
                                  src={event.event_display_image}
                                  className="rounded po-objectFit--contain po-objectPosition"
                                  width="100%"
                                  height="100%"
                                  alt=""
                                />
                              ) : (
                                <i className="bi bi-image text-5xl"></i>
                              )}
                            </div>
                          </td>
                          <td className="align-middle">
                            {" "}
                            <p className="text-sm mb-0 text-nowrap text-center text-black">
                              {event && event.attendee_count + 1}
                            </p>
                          </td>
                          <td className="align-middle">
                            {" "}
                            <p className="text-sm mb-0 text-nowrap text-black">
                              {event && event.user.membership_name}
                            </p>
                          </td>
                          <td className="align-middle">
                            {" "}
                            <p className="text-sm mb-0 text-nowrap text-black">
                              {booking_date}
                            </p>
                          </td>
                          <td className="align-middle">
                            {" "}
                            <p className="text-sm mb-0 text-nowrap text-black">
                              {date}
                            </p>
                          </td>
                          <td className="align-middle">
                            <div className="d-flex align-items-center justify-content-start">
                              {event.status === "Pending" ? (
                                <Ripple color="#FFAE1F" />
                              ) : (
                                <div
                                  style={{
                                    height: "10px",
                                    width: "10px",
                                    backgroundColor: `${event.status === "Approved"
                                      ? "#13DEB9"
                                      : event.status ===
                                        "Pending"
                                        ? "#FFAE1F"
                                        : event.status === "Rejected"
                                          ? "#FA896B"
                                          : event.status === "Cancelled"
                                            ? "#FA896B"
                                            : event.status === "Completed"
                                              ? "#13DEB9" :
                                              event.status === "Waiting for payment verification"
                                                ? "#FFAE1F" : 
                                                event.status === "Waiting for approval"
                                                  ? "#c4bbbb" 
                                                : null

                                      }`,
                                  }}
                                  className="rounded-circle flex-shrink-0"
                                ></div>
                              )}
                              <div className="ms-2 text-black text-sm text-nowrap">
                                {event.status === "Approved"
                                  ? "Approved"
                                  : event.status === "Pending"
                                    ? "Pending"
                                    : event.status === "Rejected"
                                      ? "Rejected"
                                      : event.status === "Cancelled"
                                        ? "Cancelled"
                                        : event.status === "Completed"
                                          ? "Completed"
                                          : event.status === "Waiting for payment verification"
                                                ? "Waiting for payment verification" : 
                                                event.status === "Waiting for approval"
                                                ? "Waiting for approval"
                                          : null}
                              </div>
                            </div>
                          </td>
                          <td className="align-middle">
                            <SecondaryButton
                              link={`/bookings/event-details/${event.id}`}
                              name="View"
                              target="_blank"
                            />
                          </td>
                        </tr>
                      );
                  })
                  : <tr><td colSpan={10} className="text-center text-2xl font-bold">No Data Found</td></tr>}
              </>
            ) : (
              <tr>
                <td colSpan={14}>
                  <Skeleton
                    inline={true}
                    count={5}
                    height={80}
                    style={{ marginBottom: "1rem" }}
                    baseColor="#dedede"
                    highlightColor="#cccccc"
                    duration={2}
                  />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    );
  };
  return (
    <>
      <TableBookingEntries 
        eventBookingData={eventBookingData}
      />
      {eventBookingData.length ? <div className="d-flex gap-2 justify-content-end align-items-center py-4 me-4 text-xs">
          {(Boolean(searchParams.get('searchQuery')) || Boolean(searchParams.get('filterQuery'))) && totalBookings && !loader ? <div className={`my-3 text-md ${eventBookingData.length !== totalBookings ? "mx-12" : "" }`} >No. of bookings displaying: <span className="font-bold ms-3">{totalBookings}</span></div>: ""}
          {(eventBookingData.length !== totalBookings) ? <div>
            Rows per page: 
            <div className="d-inline-block ms-1 ms-xl-3">
              <select 
                name="item-per-page" 
                id="item-per-page" 
                value={bookingsPerPage.current} 
                className="border-0 shadow-none font-medium" 
                onChange={handleItemsPerPage}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
          </div> : ""}
          {(Boolean(searchParams.get('searchQuery')) || Boolean(searchParams.get('filterQuery'))) && !loader && totalBookings > eventBookingData.length ? 
            <button 
              className="bg-transparent border-1 rounded-3 ml-12" 
              type="button"
              onClick={handleShowAll}
            >Show All</button>: ""}
            {!Boolean(searchParams.get('searchQuery')) && !Boolean(searchParams.get('filterQuery')) ? <div className="d-flex align-items-center gap-3">
            {!loader && eventBookingData.length ? <div className="mx-6 mx-xl-12 font-medium">
              {count.current*bookingsPerPage.current+1}-{(count.current+1)*bookingsPerPage.current < totalBookings ? (count.current+1)*bookingsPerPage.current: totalBookings} of {totalBookings}
            </div>: <div className="mx-6 mx-xl-12 font-medium">loading...</div>}
            <button className={`font-body font-bold rounded-circle po-paginationBtn d-inline-block bg-transparent ${(!count.current || loader) ? "po-border border-secondary" : "color-black1 po-border po-border-yellow1"}`} type="button" 
              onClick={previousPageDataFunc} 
              disabled={!count.current || loader}
            ><i className="bi bi-chevron-left text-black font-black text-lg"></i></button>
            <button className={`font-body font-bold rounded-circle po-paginationBtn po-paginationBtn--next d-inline-block bg-transparent ${(pageCount - 1 === count.current) || loader ? "po-border border-secondary" : "color-black1 po-border po-border-yellow1"}`} type="button" 
              onClick={nextPageDataFunc} 
              disabled={(pageCount - 1 === count.current) || loader}><i className="bi bi-chevron-right text-black font-black text-lg"></i></button>
          </div>: <div className="px-2"></div>}
        </div>: ""}
    </>
  );
};
export default React.memo(EventBookings);