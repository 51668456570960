import React, { useState, useEffect } from 'react';
import { Row, Col } from "react-bootstrap";
import ContentWrapper from "../ContentWrapper";
import DateRange from "../DateRange";
import BarChart from "./BarCharts";
import LineChart from "./LineChart";

const Members = ({ membershipStartDate, membership_members, setSelectedDateRange, membershipEndDate, membership_upgrade_analytycs }) => {
  const getDateRange = (start, end) => {
    setSelectedDateRange(start, end, "membership");
  }
  const [total, setTotal] = useState(0);
  useEffect(() => {
    if (membership_members.Gold) {
      setTotal(membership_members.Gold + membership_members.Amethyst + membership_members.Platinum + membership_members.Solitaire)
    }

  }, [membership_members]);

  return (
    <ContentWrapper additionalClass="p-5 rounded font-body">
      <div className="d-flex justify-content-between  mb-3">
        <div>
          <h2 className="text-md text-xl-lg font-bold color-blue font-body mb-0">
            Membership Overview
          </h2>
          <p className="text-xs text-xl-sm">Total Upgrades: {total}</p>
        </div>
        <div>
          <DateRange
            getDateRange={getDateRange}
            startDate={membershipStartDate}
            endDate={membershipEndDate}
          />
        </div>
      </div>
      <Row>
        <Col lg={6}>
          <BarChart
            membership_upgrade_analytycs={membership_upgrade_analytycs}
          />
        </Col>
        <Col lg={6}>
          <LineChart
            membership_members={membership_members}
            setTotal={setTotal}
          />
        </Col>
      </Row>
    </ContentWrapper>
  );
};
export default Members;
