import React, { useState, useEffect } from 'react';
import Chart from "react-apexcharts";
import ContentWrapper from "../ContentWrapper";
import DateRange from "../DateRange";

const AgeDemographics = ({ setSelectedDateRange, ageMatrixStartDate, ageMatrixEndDate, ageMatrix }) => {
  const [state, setState] = useState({
    people: [],
    categories: []
  })

  useEffect(() => {
    setState((prevProps) => ({
      ...prevProps,
      people: ageMatrix ? Object.values(ageMatrix) : "",
      categories: ageMatrix ? Object.keys(ageMatrix) : ""
    }))
  }, [ageMatrix]);
  const options = {
    chart: {
      type: "bar",
      fontFamily: 'Montserrat, sans-serif',
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: 'left',
    },
    series: [
      {
        name: "Members",
        data: state.people
      },
    ],
    xaxis: {
      categories: state.categories
    },
    colors: ["#003f5c",
      "#444e86",
      "#955196",
      "#dd5182",
      "#ff6e54",
      "#ffa600"],
    plotOptions: {
      bar: {
        columnWidth: "30%",
        distributed: true,
      },
    },
  };
  const getDateRange = (start, end) => {
    setSelectedDateRange(start, end, "age_matrix");
  }
  return (
    <ContentWrapper additionalClass="p-5">
      <div className="d-flex justify-content-between font-body">
        <div>
          <h2 className="text-lg font-bold color-blue font-body mb-0">Age Demographics</h2>
          {/* <p className="text-sm">Total Metrics: 200</p> */}
        </div>
        <div>
          <DateRange
            getDateRange={getDateRange}
            startDate={ageMatrixStartDate}
            endDate={ageMatrixEndDate}
          />
        </div>
      </div>
      <Chart
        options={options}
        series={options.series}
        type="bar"
        height={450}
      />
    </ContentWrapper>
  );
};
export default AgeDemographics;
