import { useState } from "react";
import { motion, useCycle } from "framer-motion";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Logout from "../../Components/Global/Popup/ConfirmModal";


const contents = {
  title: "Confirm Logout",
  permission: "Are you sure you want to log out?", 
}


const dashboardLight = "/assets/icons/sidebar/light/home.svg";
const dashboardDark = "/assets/icons/sidebar/dark/home.svg";
const locationLight = "/assets/icons/sidebar/light/location.svg";
const locationDark = "/assets/icons/sidebar/dark/location.svg";
const logout = "/assets/icons/sidebar/logout.svg";

const Dropdown = ({ title, items, iconDark, iconLight }) => {
  const { pathname } = useLocation();
  const [isOpen, toggleOpen] = useCycle(false, true);

  return (
    <div
      className={`font-body po-dropdown position-relative p-2  ${
        isOpen ? "po-dropdown--solid mb-2" : ""
      }`}
    >
      <div
        className="d-flex justify-content-between align-items-center cursor-pointer"
        onClick={() => toggleOpen()}
      >
        <div className="po-w-10">
          <img
            src={`${isOpen ? locationDark : locationLight}`}
            alt="dashboard-icon"
            width="15"
            height="15"
            className="d-block"
          />
        </div>

        <div className="po-w-90 d-flex align-items-center justify-content-between">
          <div
            className={`font-medium text-md ${
              isOpen ? " text-black" : " text-white"
            }`}
          >
            {title}
          </div>
          <div>
            <i
              className={`bi  ${
                isOpen
                  ? "bi-chevron-up text-black"
                  : "bi-chevron-down text-white"
              }`}
            ></i>
          </div>
        </div>
      </div>
      <motion.ul
        animate={isOpen ? "open" : "closed"}
        variants={{
          open: { opacity: 1, height: "auto" },
          closed: { opacity: 0, height: 0 },
        }}
        transition={{ duration: 0.7 }}
        className="ps-4"
      >
        {items.map((item, index) => (
          <li key={index}>
            <Link
              className={`mt-1 color-app text-sm hover-ternary  ${
                isOpen ? "d-block" : "d-none"
              } ${
                pathname === item.itemLink ? "color-ternary font-medium" : null
              }`}
              to={item.itemLink}
            >
              {item.itemName}
            </Link>
          </li>
        ))}
      </motion.ul>
    </div>
  );
};

const NormalItems = ({ itemName, itemLink, iconDark, iconLight }) => {
  const { pathname } = useLocation();
  return (
    <>
      <Link to={itemLink}>
        <div
          className={`d-flex align-items-center px-2 py-1 font-body ${
            pathname === itemLink ? "po-dropdown--solid rounded" : null
          }`}
        >
          <div className="po-w-10 me-1">
            <img
              src={`${pathname === itemLink ? iconDark : iconLight}`}
              alt="dashboard-icon"
              width="15"
              height="15"
              className="d-block"
            />
          </div>
          <div
            className={`po-w-90 font-medium text-sm ${
              pathname === itemLink ? " text-black" : "text-white"
            }`}
          >
            {itemName}
          </div>
        </div>
      </Link>
    </>
  );
};

const Sidebar = () => {
  const [showModal, setShowModal] = useState(false);
  const handleButtonClick = () => setShowModal(true);

  return (
    <>
      <motion.aside
        className="po-sidebar px-3 py-9 position-relative"
      >
        <div className="po-sidebar__logo m-auto pb-9">
          <Link to="/dashboard">
            <img
              src="/assets/logos/party-one-new.svg"
              alt="dashboard logo"
              className="img-fluid"
            />
          </Link>
        </div>
        <ul>
          <li className="mb-2">
            <NormalItems
              itemName="Profile"
              itemLink="/influencer/profile"
              iconDark={dashboardDark}
              iconLight={dashboardLight}
            />
          </li>
          <li className="mb-2">
            <NormalItems
              itemName="My Referrals"
              itemLink="/influencer/referrals"
              iconDark={dashboardDark}
              iconLight={dashboardLight}
            />
          </li>
          <li className="mb-2">
            <NormalItems
              itemName="Codes"
              itemLink="/influencer/codes"
              iconDark={dashboardDark}
              iconLight={dashboardLight}
            />
          </li>
        </ul>

        <div className="po-sidebar__bottom position-absolute font-body">
          <div
            className="bg-transparent border-0 text-white font-body font-medium d-flex align-items-center gap-2 shadow-none mb-4 cursor-pointer"
            onClick={handleButtonClick}
          >
            <img
              src={logout}
              alt="logout"
              width="15"
              height="15"
              className="d-block"
            />
            <span>Logout</span>
          </div>
          <h4 className="text-uppercase font-semibold text-sm mb-0">
            Party One
          </h4>
          <h5 className="font-semibold text-lg text-lg-xl">Influencer</h5>
        </div>
      </motion.aside>
      <Logout 
        showModal={showModal} 
        setShowModal={setShowModal} 
        contents={contents} 
      />
    </>
  );
};

export default Sidebar;
