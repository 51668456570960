import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import ContentWrapper from "../../Components/Global/ContentWrapper";
import Layout from "../../Layout/Layout";
import ActionIcon from "../../Components/Global/Buttons/ActionIcons";
import {
  collection,
  getDocs,
  orderBy,
  query,
  addDoc,
  serverTimestamp,
  setDoc,
  doc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import db from "../../firebase";
import SuccessButton from "../../Components/Global/Buttons/SuccessButton";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import Toaster from "../../Components/Global/Toast";
import SecondaryButton from "../../Components/Global/Buttons/SecondaryButton";
import OutlinedButton from "../../Components/Global/Buttons/OutlinedButton";
const CreateExploreFuther = () => {
  const [showToaster, setShowToaster] = useState(false);
  const [toasterDetails, setToasterDetails] = useState({
    title: "",
    bodyText: "",
    bg: "",
    statusIcon: "",
  });
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();
  const selectedOption = watch("redirectionType"); // Watch the selected redirection Type
  const [selectedImage, setSelectedImage] = useState(null);
  const [ufile, setUfile] = useState({});

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(URL.createObjectURL(file));
    setUfile(file);
  };
  const handleDeselectImage = () => {
    setSelectedImage(null);
    const inputElement = document.getElementById("imageInput");
    if (inputElement) {
      inputElement.value = null;
    }
  };

  // upload images
  const uploadImage = async () => {
    const formData = new FormData();
    formData.append("images", ufile);
    // console.log(ufile, "hhhhh");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_MULTIPLEIMAGES_POSTING_URL}/uploads/uploadImages`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            method: "POST",
          },
        }
      );

      return response;
    } catch (error) {
      console.log(error);
    }
  };

  // events fetch
  const [events, setEvents] = useState([]);
  const fetchEvents = async () => {
    const q = query(collection(db, "events"), orderBy("name"));
    const querySnapshot = await getDocs(q);
    const eventsData = querySnapshot.docs.map((doc) => ({
      value: doc.id,
      label: doc.data().name,

      // ...doc.data(),
    }));
    setEvents(eventsData);
  };

  // clubs fetch
  const [clubs, setClubs] = useState([]);
  const fetchClubs = async () => {
    const q = query(collection(db, "clubs"), orderBy("name"));
    const querySnapshot = await getDocs(q);
    const clubsData = querySnapshot.docs.map((doc) => ({
      value: doc.id,
      label: doc.data().name,
    }));
    setClubs(clubsData);
  };

  // Cities fetch
  const [cities, setCities] = useState([]);
  const fetchCities = async () => {
    const q = query(collection(db, "cities"), orderBy("name"));
    const querySnapshot = await getDocs(q);
    const citiesData = querySnapshot.docs.map((doc) => ({
      value: doc.id,
      label: doc.data().name,
    }));
    setCities(citiesData);
  };

  useEffect(() => {
    fetchEvents();
    fetchClubs();
    fetchCities();
  }, []);

  const onSubmit = async (data) => {
    setShowToaster(true);
    setToasterDetails((prev) => ({
      ...prev,
      title: "Creating...",
      bodyText: "Creating pop up",
      bg: "warning",
      statusIcon: (
        <div className="spinner-border text-warning me-2" role="status"></div>
      ),
    }));
    const payloadId = uuidv4();
    const resultantImage = await uploadImage(ufile);
    const payload = {
      id: payloadId,
      city: data.city,
      image: resultantImage.data.url[0],
      redirectionType: data.redirectionType,
      clubId: data.club === "" ? null : data.club,
      eventId: data.event === "" ? null : data.event,
      createdAt: serverTimestamp(),
    };
    console.log(payload, "payload");
    try {
      const docRef = await setDoc(doc(db, "popups", payloadId), payload);
      reset();
      setSelectedImage(null);
      setToasterDetails((prev) => ({
        ...prev,
        title: "Success",
        bodyText: "Created pop up successfully",
        bg: "success",
        statusIcon: <i className="bi bi-check text-success text-3xl me-2"></i>,
      }));
      setTimeout(() => {
        navigate("/popups");
      }, 2000);
    } catch (e) {
      console.error("Error adding document: ", e);
      setToasterDetails((prev) => ({
        ...prev,
        title: "Error",
        bodyText: "Something went wrong",
        bg: "danger",
        statusIcon: (
          <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
        ),
      }));
    }
  };
  return (
    <Layout>
      <ContentWrapper additionalClass="p-5">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row className="g-4">
            <Col xs={6}>
              <label className="text-md mb-1">Image</label>
              <div
                id="displayImage"
                style={{
                  height: "240px",
                  width: "100%",
                  backgroundImage: `url(${selectedImage ? selectedImage : null
                    })`,
                  border: `${selectedImage ? "0px" : "1px"} dashed #D2D6DA`,
                }}
                className="d-flex align-items-center justify-content-center background-position-center-top background-repeat-norepeat background-size-cover rounded overflow-hidden position-relative"
              >
                {selectedImage ? (
                  <div className="position-absolute end-0 top-0">
                    <ActionIcon
                      onClick={() => {
                        setSelectedImage(null);
                      }}
                      additionalClass="bi bi-x-circle-fill text-3xl color-red me-2"
                    />
                  </div>
                ) : null}

                {selectedImage ? null : (
                  <div>
                    <input
                      type="file"
                      id="imageInput"
                      {...register("image", { required: "Image is required" })}
                      onChange={handleImageChange}
                      accept="image/*"
                      className="opacity-0 position-absolute start-0 end-0 top-0 bottom-0 w-100 h-100"
                    />

                    <div className="text-center">
                      <i className="bi bi-plus-circle-fill text-5xl color-lightgreen d-block"></i>
                      <p className="text-md font-body text-center  ">
                        Upload your Image here.
                      </p>
                    </div>
                  </div>
                )}
              </div>
              {errors.image && (
                <p className="text-sm text-danger mt-1 font-body">
                  {errors.image.message}
                </p>
              )}
            </Col>

            <Col xs={6}>
              <label className="text-md mb-1">Title</label>
              <input type="text"
                className="po-edit-input"
                {...register("title", {
                  required: "title is required",
                })}
              />


              {errors.title && (
                <p className="text-sm text-danger mt-1 font-body">
                  {errors.title.message}
                </p>
              )}
            </Col>
            <Col xs={6}>
              {selectedOption === "events" ? (
                <div>
                  <label className="text-md mb-1">Redirecting url</label>
                  <input
                    className="po-edit-input"
                    {...register("redirectingurl", { required: "url is required" })}
                  />


                  {errors.redirectingurl && (
                    <p className="text-sm text-danger mt-1 font-body">
                      {errors.redirectingurl.message}
                    </p>
                  )}
                </div>
              ) : (
                <div>
                  <label className="text-md mb-1">Club</label>
                  <select
                    className="po-edit-input"
                    {...register("club", { required: "Club is required" })}
                  >
                    <option value="">Select Club</option>
                    {clubs.map((data, index) => {
                      return (
                        <option key={index} value={data.value}>
                          {data.label}
                        </option>
                      );
                    })}
                  </select>
                  {errors.club && (
                    <p className="text-sm text-danger mt-1 font-body">
                      {errors.club.message}
                    </p>
                  )}
                </div>
              )}
            </Col>
            <Col className="text-end">
              <OutlinedButton
                link="/popups"
                name="Cancel"
                additionalClass="me-3"
              />
              <SuccessButton type="submit" name="Save" icon="bi-check-circle" />
            </Col>
          </Row>
        </form>
      </ContentWrapper>
      <Toaster
        showToaster={showToaster}
        setShowToaster={setShowToaster}
        bg={toasterDetails.bg}
        title={toasterDetails.title}
        bodyText={toasterDetails.bodyText}
        statusIcon={toasterDetails.statusIcon}
      />
    </Layout >
  );
};
export default CreateExploreFuther;
