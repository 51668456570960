import React from 'react';
import Layout from '../Layout/Layout';
import ContentWrapper from '../Components/Global/ContentWrapper';
import {Row, Col} from 'react-bootstrap';



const RequestDetails = () =>{
    const userDetailsList = [
        {
            query:"Booking ID",
            userResponse:"AQ1346"
        },
        {
            query:"Club Name",
            userResponse:"Out and loud",
        },
        {
            query:"Requested on",
            userResponse:"03/05/2021 04:00PM"
        },
        {
            query:"Status",
            status:"Pending"
        },
        {
            query:"Location",
            userResponse:"London"
        },
        {
            query:"Email",
            userResponse:"demouser@gmail.com",
        },
        {
            query:"Phone",
            userResponse:"+1 - 9876543276",
            icon:"./assets/icons/america-flag-icon.svg"
        }
    ]
    return(
        <Layout>
            <ContentWrapper>
                <Row className="py-7 px-8">
                    <Col xs={12}>
                        <h2 className="color-heading font-body font-semibold text-lg text-lg-2xl pb-6 mb-0">User Details</h2>
                        <div className="d-flex align-items-center">
                            <img className="po-userDetails__image" src="./assets/icons/user-icon.svg" alt="Party One Member" />
                            <div className="pl-3 font-body">
                                <p className="color-lightblue font-semibold text-md text-lg-2xl mb-0">Dua Lipa</p>
                                <p className="color-lightestblue font-normal text-xs mb-0">@demouser</p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} lg={6}>
                        {
                            userDetailsList && userDetailsList.map((item, index) => {
                                return(
                                    <div className="pt-4 font-body">
                                        <p className="color-lightestblue font-normal text-xs mb-0 font-body" key={index}>{item.query}</p>
                                        <div className="d-flex align-items-center">
                                            {item.icon && <img className="po-userDetails__icon mr-2" src={item.icon} alt="User Response Icons"/>}  
                                            <p className="color-lightblue font-semibold text-md text-lg-lg mb-0 font-body" key={index}>{item.userResponse}</p>
                                            { item.status && <button className="po-requestDetails__statusBtn font-body mt-1">{item.status}</button> }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Col>
                    <Col xs={12} lg={6} className="pt-4 d-flex flex-column justify-content-between">
                        <div>
                            <p className="color-lightestblue font-semibold text-md mb-0 font-body">Price Breakdown</p>
                            <div className="d-flex justify-content-between align-items-center pt-4">
                                <div>
                                    <p className="color-lightestblue font-normal text-xs mb-0 font-body">Table</p>
                                    <p className="text-dark text-md text-lg-lg font-semibold font-body mb-0">Table for 4</p>
                                </div>
                                <div>
                                    <p className="color-lightestblue font-normal text-xs mb-0 font-body">Requested for</p>
                                    <p className="color-lightblue font-semibold text-md text-lg-lg mb-0 font-body">03/05/2021 04:00PM</p>
                                </div>
                            </div>
                            <p className="color-lightestblue font-normal text-xs mb-0 font-body mt-6 mb-1">Starters</p>
                            <div className="po-requestDetails-table d-flex align-items-center justify-content-between pb-5">
                                <div>
                                    <p className="text-dark text-md text-lg-lg font-semibold font-body mb-0 pb-2">4 Mojitos</p>
                                    <p className="text-dark text-md text-lg-lg font-semibold font-body mb-0 pb-2">2 Extra Large Mushroom Pizzas</p>
                                    <p className="text-dark text-md text-lg-lg font-semibold font-body mb-0 pb-2">2 Sheesha</p>
                                </div>
                                <div>
                                    <p className="text-dark text-md text-lg-lg font-regular font-body mb-0 pb-2">$20</p>
                                    <p className="text-dark text-md text-lg-lg font-regular font-body mb-0 pb-2">$80</p>
                                    <p className="text-dark text-md text-lg-lg font-regular font-body mb-0 pb-2">$90</p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center pt-4">
                                <p className="color-lightblue font-semibold text-lg text-lg-xl mb-0 font-body">Total</p>
                                <p className="color-lightblue font-semibold text-lg text-lg-xl mb-0 font-body">$190</p>
                            </div>
                        </div>
                        {
                            <div className="d-flex justify-content-lg-end mt-12">
                                
                               
                            </div>
                        }   
                    </Col>
                </Row>
            </ContentWrapper>
        </Layout>
    );
}

export default RequestDetails