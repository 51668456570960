import React, { useEffect, useState } from 'react';
import { collection, query, startAfter, endBefore, limit, where, getDocs,limitToLast} from 'firebase/firestore';
// import firebase from 'firebase/compat/app';
// import 'firebase/compat/firestore';
import db from '../firebase';


const YourComponent = () => {
  const [documents, setDocuments] = useState([]);
  const [lastVisibleDocument, setLastVisibleDocument] = useState(null);
  const [startAfterDocument, setStartAfterDocument] = useState(null);
  const [endBeforeDocument, setEndBeforeDocument] = useState(null);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    performSearchPagination();

    // Cleanup function
    return () => {
      // Clean up any resources if needed
    };
  }, []);

  // Function to perform search pagination
  const performSearchPagination = async () => {
    const collectionRef = collection(db, 'clubs');
    const searchQuery = query(collectionRef, where('field', '==', searchValue));
    const pageSize = 10;

    try {
      let querySnapshot;

      if (startAfterDocument) {
        querySnapshot = await getDocs(
          query(searchQuery, startAfter(startAfterDocument), limit(pageSize))
        );
      } else if (endBeforeDocument) {
        querySnapshot = await getDocs(
          query(searchQuery, endBefore(endBeforeDocument), limitToLast(pageSize))
        );
      } else {
        querySnapshot = await getDocs(query(searchQuery, limit(pageSize)));
      }

      const newDocuments = querySnapshot.docs.map((doc) => doc.data());

      setDocuments(newDocuments);

      // Set the last visible document for pagination
      const lastDocument = querySnapshot.docs[querySnapshot.docs.length - 1];
      setLastVisibleDocument(lastDocument);

      // Set the startAfterDocument for seeking pagination
      const firstDocument = querySnapshot.docs[0];
      setStartAfterDocument(firstDocument);

      // Clear the endBeforeDocument for seeking pagination
      setEndBeforeDocument(null);
    } catch (error) {
      console.log('Error fetching documents:', error);
    }
  };

  // Function to handle search input change
  const handleSearchInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  // Function to fetch next page using seeking pagination
  const fetchNextPage = async () => {
    if (lastVisibleDocument) {
      setStartAfterDocument(lastVisibleDocument);
      setEndBeforeDocument(null);
      performSearchPagination();
    }
  };

  // Function to fetch previous page using seeking pagination
  const fetchPreviousPage = async () => {
    if (startAfterDocument) {
      setEndBeforeDocument(startAfterDocument);
      setStartAfterDocument(null);
      performSearchPagination();
    }
  };
 console.log(documents,'documents')
  return (
    <div>
      {/* Search input field */}
      <input type="text" value={searchValue} onChange={handleSearchInputChange} />

      {/* Render your documents */}
      {documents.map((doc,index) => (
        <div key={index}>{index}</div>
      ))}

      <button onClick={fetchPreviousPage}>Fetch Previous Page</button>
      <button onClick={fetchNextPage}>Fetch Next Page</button>
    </div>
  );
};

export default YourComponent
