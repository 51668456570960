import { Row, Col, Modal, ModalBody } from "react-bootstrap";
import ContentWrapper from "../../Components/Global/ContentWrapper";
// import axios from "axios";

import { useCallback, useRef, useState } from "react";
import ActionIcon from "../Global/Buttons/ActionIcons";
import { useForm } from "react-hook-form";
import PrimaryInput from "../UI/PrimaryInput";
import {
  Timestamp,
  collection,
  doc,
  endAt,
  getDoc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  startAt,
  updateDoc,
  where,
} from "firebase/firestore";
import db from "../../firebase";
import { v4 as uuidv4 } from "uuid";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import SuccessButton from "../Global/Buttons/SuccessButton";
import OutlinedButton from "../Global/Buttons/OutlinedButton";
import Toaster from "../Global/Toast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SecondaryButton from "../Global/Buttons/SecondaryButton";

const UpdateEvent = ({ newClubData, eventDetails }) => {
  const navigate = useNavigate();
  const [previewImage, setPreviewImage] = useState(null);
  const [eventImageDeleteModal, setEventImageDeleteModal] = useState(null);
  const [displayImage, setDisplayImage] = useState();
  const [eventPhotosUrl, setEventPhotosUrl] = useState([]);
  const [eventPhotos, setEventPhotos] = useState([]);
  const [photoDimensionError, setPhotoDimensionError] = useState({
    bannerError: false,
    photosError: false
  })
  const [clubs, setClubs] = useState([]);
  const [eventTypes, setEventTypes] = useState([]);
  const [nonOperationalDays, setNonOperationalDays] = useState([]);
  const [uploadImagesError, setUploadImagesError] = useState("");
  const { handleSubmit, reset } = useForm({
    // resolver: yupResolver(schema)
  });
  const uploadImagesRef = useRef(null);
  const [showToaster, setShowToaster] = useState(false);
  const [photoIndex, setPhotoIndex] = useState({
    type: "",
    indexNumber: 0
  })
  const [photoDeleteModal, setPhotoDeleteModal] = useState(null);
  const [toasterDetails, setToasterDetails] = useState({
    title: "",
    bodyText: "",
    bg: "",
    statusIcon: "",
  });
  const [state, setState] = useState({
    id: "",
    event_name: "",
    description: "",
    event_type: "",
    event_type_show: false,
    event_date: "",
    event_timing: "",
    entry_per_head: "",
    display_image: "",
    event_photos: [],
    status: false,
    club_logo: null,
    club: {
      club_name: "",
      club_id: "",
      club_address: "",
      city_name: "",
      city_id: "",
      longitude: null,
      latitude: null,
    },
    error: {
      event_name: "",
      description: "",
      event_type: "",
      club_name: "",
      event_date: "",
      event_timing: "",
      entry_per_head: "",
    },
    showList: true,
  });

  // filling the inputs for update event********************************
  useEffect(() => {
    fetchEventsTypes();
    if (eventDetails && JSON.stringify(eventDetails) !== "{}") {
      const parentRef = doc(collection(db, "clubs"), eventDetails.club.id);
      const non_operational_days = collection(parentRef, "closed_bookings");
      getDocs(non_operational_days)
        .then(response => {
          setNonOperationalDays([...response.docs.map(item => item.data().date)])
        });
      setState((prev) => ({
        ...prev,
        event_name: eventDetails && eventDetails.name,
        description: eventDetails && eventDetails.description,
        event_type: eventDetails && eventDetails.event_type,
        // event_type_show: false,
        status: eventDetails && eventDetails.active,
        event_date: eventDetails && nonOperationalDays.includes(moment(eventDetails.date_and_time.toDate()).format("YYYY-MM-DD")) ? "" : eventDetails.date_and_time.toDate(),
        event_timing: moment(eventDetails && eventDetails.date_and_time.toDate()).format(
          "HH:mm:ss"
        ),
        entry_per_head: eventDetails && eventDetails.entry_per_head,
        club: {
          club_name: eventDetails && eventDetails.club ? eventDetails.club.name : "",
          club_id: eventDetails.club ? eventDetails.club.id : "",
          club_address: eventDetails.club ? eventDetails.club.address : "",
          city_name: eventDetails.club ? eventDetails.club.city_name : "",
          city_id: eventDetails.club ? eventDetails.club.city_name : "",
          longitude: eventDetails.club ? eventDetails.club.longitude : "",
          latitude: eventDetails.club ? eventDetails.club.latitude : "",
        },
        club_logo: eventDetails.club ? eventDetails.club_logo : null,
      }));
      setPreviewImage(eventDetails && eventDetails.display_image);
      // setEventPhotos(eventDetails.photos);
      setEventPhotosUrl(eventDetails && eventDetails.photos);
    }
  }, [eventDetails]);
  // postImages ****************************************************
  const isValidImageDimensions = (file, maxWidth, maxHeight) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = function () {
        const width = this.width;
        const height = this.height;

        if (width <= maxWidth && height <= maxHeight) {
          resolve(true); // Image dimensions are valid
        } else {
          resolve(false); // Image dimensions are not valid
        }
      };

      img.onerror = function () {
        reject("Failed to load the image");
      };
    });
  };
  const postImages = async (files) => {
    const formdata = new FormData();
    for (const file of files) {
      formdata.append("images", file);
    }
    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    const resultPhotos = await fetch(
      `${process.env.REACT_APP_MULTIPLEIMAGES_POSTING_URL}/uploads/uploadImages`,
      requestOptions
    );
    const response = await resultPhotos.json();
    if (resultPhotos.status === 200) {
      return response.url;
    } else {
      console.log("something went wrong");
      return "Something went wrong!";
    }
  };
  // onChangeDisplayImage
  const handleDisplayImage = (e) => {
    const file = e.target.files[0];
    isValidImageDimensions(file, 800, 800)
    .then((isValidDimensions) => {
      console.log(isValidDimensions, 'isValidDimensions');
      if(isValidDimensions) {
        setPhotoDimensionError(prev=> ({
          ...prev,
          bannerError: false
        }))
        setDisplayImage(e.target.files);
        if (file) {
          const reader = new FileReader();
          reader.onload = () => {
            setPreviewImage(reader.result);
          };
          reader.readAsDataURL(file);
        }
      } else {
        setPhotoDimensionError(prev=> ({
          ...prev,
          bannerError: true
        }))
        setTimeout(()=> {
          setPhotoDimensionError(prev=> ({
            ...prev,
            bannerError: false
          }))
        }, 2000)
      }
    })
    .catch(err=>{
      console.log(err, 'image upload error!')
    })
    
  };
  // handleChangePhotos ****************************
  const handleChangePhotos = (e) => {
    for (const file of e.target.files) {
      if ((e.target.files.length + eventPhotos.length > 6) || (eventPhotosUrl.length + e.target.files.length > 6)) {
        setUploadImagesError("You can select only up to six photos");
        setTimeout(() => {
          setUploadImagesError("");
        }, 2000)
        uploadImagesRef.current.value = "";
        return;
      }
      if (eventPhotos.some(item => item.name === file.name)) {
        setUploadImagesError("Please upload different image");
        setTimeout(() => {
          setUploadImagesError("");
        }, 2000)
      } else {
        isValidImageDimensions(file, 800, 800)
        .then((isValidDimensions)=> {
          if(isValidDimensions) {        
            setEventPhotos((prev) => [...prev, file]);
          } else {
            setPhotoDimensionError(prev=> ({
              ...prev,
              photosError: true
            }))
            setTimeout(()=> {
              setPhotoDimensionError(prev=> ({
                ...prev,
                photosError: false
              }))
            }, 2000)
          }
        })
        .catch(()=> {

        })
        setUploadImagesError("");
      }

    }
    uploadImagesRef.current.value = "";
  };

  const formValidate = () => {
    let isValid = true;
    let errors = state.error;
    if (state.event_name.trim() === "") {
      errors.event_name = "Please enter Event name...";
      isValid = false;
    }
    if (state.description.trim() === "") {
      errors.description = "Please enter description...";
      isValid = false;
    }
    if (state.event_type === "") {
      errors.event_type = "Please Select an event type...";
      isValid = false;
    }
    if (state.event_date === "") {
      errors.event_date = "Please Select an event date...";
      isValid = false;
    }
    if (state.event_timing === "") {
      errors.event_timing = "Please Select an event timing...";
      isValid = false;
    } else {
      if (moment(state.event_date).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") &&
        state.event_timing < moment(new Date()).format("HH:mm")) {
        errors.event_timing = "You entered invalid time...";
        isValid = false;
      }
    }
    if (state.entry_per_head === "") {
      errors.entry_per_head = "Please enter the amount...";
      isValid = false;
    }
    if (state.club.club_name === "") {
      errors.club_name = "Please enter the club name...";
      isValid = false;
    } else {
      if (state.club.club_address === "") {
        errors.club_name = "Please Select the club name from list...";
        isValid = false;
      }
    }
    // if(!state.club.city_name) {
    //   document.getElementById('city_name').focus();
    // }
    setState((prev) => ({
      ...prev,
      error: errors,
    }));
    return isValid;
  };

  const onSubmit = async (data) => {
    let validation = formValidate();
    if (validation && !state.error.club_name) {
      setShowToaster(true);
      if (newClubData) {
        setToasterDetails((prev) => ({
          ...prev,
          title: "Creating...",
          bodyText: "Creating new events",
          bg: "warning",
          statusIcon: (
            <div className="spinner-border text-warning me-2" role="status"></div>
          ),
        }));
      } else {
        setToasterDetails((prev) => ({
          ...prev,
          title: "Updating...",
          bodyText: "Updating event details",
          bg: "warning",
          statusIcon: (
            <div className="spinner-border text-warning me-2" role="status"></div>
          ),
        }));
      }
      let display_image_url = displayImage
        ? await postImages(displayImage)
        : null;
      let event_photos_url = eventPhotos.length
        ? await postImages(eventPhotos)
        : [];
      if (eventPhotosUrl && eventPhotosUrl.length) {
        event_photos_url =
          eventPhotosUrl.length > 0 && event_photos_url
            ? event_photos_url.concat(eventPhotosUrl)
            : eventPhotosUrl;
      }
      let eventStatus = "";
      if (new Date() < new Date(moment(state.event_date).format("DD MMM YYYY"))) {
        eventStatus = "Upcoming";
      }
      else if (moment(state.event_date).format("DD MMM YYYY") === moment().format("DD MMM YYYY")) {
        eventStatus = "Today";
      }
      else {
        eventStatus = "Past";
      }
      let obj = {
        createdAt: new Date(),
        id: uuidv4(),
        name: state.event_name.trim(),
        name_lowercase: state.event_name.trim().toLowerCase(),
        description: state.description.trim(),
        event_type: state.event_type,
        date_and_time: Timestamp.fromDate(
          moment(`${moment(state.event_date).format("YYYY-MM-DD")} ${state.event_timing}`, moment.defaultFormat).toDate(), "date_and_time"),
        entry_per_head: +state.entry_per_head,
        no_of_bookings: 0,
        display_image:
          display_image_url &&
          display_image_url.length > 0 &&
          display_image_url[0],
        photos: event_photos_url,
        club: {
          name: state.club.club_name,
          id: state.club.club_id,
          address: state.club.club_address,
          city_name: state.club.city_name,
          city_id: state.club.city_id,
          longitude: state.club.longitude ?? null,
          latitude: state.club.latitude ?? null,
        },
        club_logo: state.club_logo ? state.club_logo : null,
        club_name_lower_case: state.club.club_name.toLowerCase(),
        city_name_lower_case: state.club.city_name.toLowerCase(),
        active: state.status,
        status: state.status ? eventStatus : "Inactive",
        updateAt: serverTimestamp(),
      };
      // create event ***********************************************
      if (newClubData) {
        let success = await newClubData(obj);
        if (success) {
          reset();
          setPreviewImage(null);
          setToasterDetails((prev) => ({
            ...prev,
            title: "Success",
            bodyText: "Event create successfully",
            bg: "success",
            statusIcon: (
              <i className="bi bi-check text-success text-3xl me-2"></i>
            ),
          }));
          setTimeout(() => {
            setShowToaster(false);
            navigate("/events", { replace: true });
          }, 2000);
        } else {
          setToasterDetails((prev) => ({
            ...prev,
            title: "Error",
            bodyText: "Something went wrong, Try again",
            bg: "danger",
            statusIcon: (
              <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
            ),
          }));
          setTimeout(() => {
            setShowToaster(false);
          }, 2000);
        }
      } else {
        // updating the event ****************************
        if (event_photos_url.length > 6) {
          setUploadImagesError("You can select only up to six photos");
          setTimeout(() => {
            setUploadImagesError("");
          }, 2000)
          uploadImagesRef.current.value = "";
          setToasterDetails((prev) => ({
            ...prev,
            title: "Error",
            bodyText: "You can select only up to six photos",
            bg: "danger",
            statusIcon: (
              <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
            ),
          }));
          setTimeout(() => {
            setShowToaster(false);
          }, 2000);
          return;
        }
        const updateEvent = doc(db, "events", eventDetails.id);
        let eventStatus = "";
        if (new Date() < new Date(moment(state.event_date).format("DD MMM YYYY"))) {
          eventStatus = "Upcoming";
        }
        else if (moment(state.event_date).format("DD MMM YYYY") === moment().format("DD MMM YYYY")) {
          eventStatus = "Today";
        }
        else {
          eventStatus = "Past";
        }
        try {
          await updateDoc(updateEvent, {
            name: state.event_name.trim(),
            name_lowercase: state.event_name.trim().toLowerCase(),
            description: state.description.trim(),
            event_type: state.event_type,
            date_and_time: Timestamp.fromDate(
              moment(
                `${moment(state.event_date).format("YYYY-MM-DD")} ${state.event_timing}`,
                moment.defaultFormat
              ).toDate(),
              "date_and_time"
            ),
            entry_per_head: +state.entry_per_head,
            no_of_bookings: eventDetails.no_of_bookings,
            display_image: display_image_url
              ? display_image_url.length > 0 && display_image_url[0]
              : previewImage,
            photos: event_photos_url ?? eventPhotosUrl,
            club: {
              name: state.club.club_name,
              id: state.club.club_id,
              address: state.club.club_address,
              city_name: state.club.city_name,
              city_id: state.club.city_id,
              longitude: state.club.longitude,
              latitude: state.club.latitude,
            },
            club_logo: state.club_logo ? state.club_logo : null,
            club_name_lower_case: state.club.club_name.toLowerCase(),
            city_name_lower_case: state.club.city_name.toLowerCase(),
            active: state.status,
            status: state.status ? eventStatus : "Inactive",
            updateAt: serverTimestamp(),
          });
          setToasterDetails((prev) => ({
            ...prev,
            title: "Success",
            bodyText: "Event details are updated successfully",
            bg: "success",
            statusIcon: (
              <i className="bi bi-check text-success text-3xl me-2"></i>
            ),
          }));
          setTimeout(() => {
            setShowToaster(false);
            navigate("/events", { replace: true });
          }, 2000);
          reset();
        } catch (err) {
          console.log(err, "err");
          setToasterDetails((prev) => ({
            ...prev,
            title: "Error",
            bodyText: err,
            bg: "danger",
            statusIcon: (
              <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
            ),
          }));
          setTimeout(() => {
            setShowToaster(false);
          }, 2000);
        }
      }
    } else {
      setShowToaster(false);
      if (!state.event_name.trim()) {
        document.getElementById("event_name").focus();
      } else if (!state.description.trim()) {
        document.getElementById("description").focus();
      } else if (!state.event_type) {
        document.getElementById("event_type").focus();
      } else if (!state.event_date) {
        document.getElementById("event_date").focus();
      } else if (!state.event_timing || state.error.event_timing) {
        document.getElementById("event_timing").focus();
      } else if (!state.entry_per_head) {
        document.getElementById("entry_per_head").focus();
      } else if (!state.club.club_name.trim()) {
        document.getElementById("club_name").focus();
      } else if (!state.club.club_address.trim()) {
        document.getElementById("club_name").focus();
      }
    }
  };

  const fetchClubData = useCallback(
    debounce((val) => {
      const searchedData =
        val.length !== 0 &&
        query(
          collection(db, "clubs"), where("status", "==", "Active"),
          orderBy("name_lower_case"),
          startAt(val.replace(/\s+/g, ' ').trim().toLowerCase()),
          endAt(`${val.replace(/\s+/g, ' ').trim().toLowerCase()}\uf8ff`)
        );
      val.length !== 0 &&
        getDocs(searchedData).then((docSnapshots) => {
          setClubs([]);
          docSnapshots.forEach((docs) => {
            console.log(docs.data(), 'docs.data()');
            if (docs.data().latitude && docs.data().longitude) {
              setClubs((prev) => [...prev, docs.data()]);
            }
          });
        });
    }, 1000),
    []
  );

  function debounce(fn, delay) {
    let timer;
    return function () {
      let context = this,
        args = arguments;
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(context, args);
      }, delay);
    };
  }

  const handleDateChange = (date) => {
    setState(prev => ({
      ...prev,
      event_date: date,
      error: {
        ...state.error,
        event_timing: "",
        event_date: ""
      }
    }))
  };

  const onChangeHandler = (event) => {
    const { value, name } = event.target;
    let errors = state.error;
    if (name === "status") {
      setState((prevProps) => ({
        ...prevProps,
        [name]: !state.status,
        error: {
          ...errors,
          [name]: "",
        },
      }));
    } else if (name === "event_name") {
      if (value.length <= 100) {
        setState((prevProps) => ({
          ...prevProps,
          [name]: value,
          error: {
            ...errors,
            [name]: "",
          },
        }));
      } else {
        setState((prevProps) => ({
          ...prevProps,
          // [name]: value,
          error: {
            ...errors,
            [name]: "Maximum character limit exceeded. Enter an event name within 100 characters.",
          },
        }));
        setTimeout(() => {
          setState((prevProps) => ({
            ...prevProps,
            error: {
              ...errors,
              [name]: "",
            },
          }));
        }, 5000)
      }
    }
    else {
      setState((prevProps) => ({
        ...prevProps,
        [name]: value,
        error: {
          ...errors,
          [name]: "",
        },
      }));
    }
  };

  const onChangeClubName = (e) => {
    setState((prev) => ({
      ...prev,
      club: {
        ...state.club,
        club_name: e.target.value,
        club_address: "",
        city_name: "",
      },
      error: {
        ...state.error,
        club_name: "",
      },
      showList: true,
    }));
    fetchClubData(e.target.value);
  };

  const fetchEventsTypes = async () => {
    setState((prev) => ({
      ...prev,
      event_type_show: true,
    }));
    if (eventTypes.length < 1) {
      const docRef = doc(db, "picklist", "event_type");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setEventTypes(Object.values(docSnap.data()));
      } else {
        console.log("No such document!");
      }
    }
  };
  // deleting image from the list of multiple images selected
  const onDeleteImagesList = (item) => {
    let newArrayEventPhotos = [...eventPhotos];
    newArrayEventPhotos.splice(item, 1);
    setEventPhotos(newArrayEventPhotos);
    uploadImagesRef.current.value = "";
    setPhotoDeleteModal(false)
  };
  const onUpdateImagesList = (imageIndex) => {
    let remainImages = eventPhotosUrl.filter(
      (item, index) => index !== imageIndex
    );
    setEventPhotosUrl(remainImages);
    setPhotoDeleteModal(false);
  };
  const onClubBlurHandler = () => {
    setTimeout(() => {
      setState((prev) => ({
        ...prev,
        showList: false,
      }));
    }, 300);
  };

  const onSelectClub = (club) => {
    const parentRef = doc(collection(db, "clubs"), club.id);
    const non_operational_days = collection(parentRef, "closed_bookings");
    getDocs(non_operational_days)
      .then(response => {
        setNonOperationalDays([...response.docs.map(item => item.data().date)])
      });
    setState((prev) => ({
      ...prev,
      club: {
        club_name: club.name,
        club_id: club.id,
        club_address: club.address,
        city_name: club.city_name,
        city_id: club.city_id,
        longitude: club.longitude,
        latitude: club.latitude,
      },
      club_logo: club.logo,
      error: {
        ...state.error,
        club_name: "",
      },
      showList: false,
    }));
  }
  return (
    <>
      <form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
        <ContentWrapper additionalClass="p-5 mb-4">
          <Row className="g-4">
            <Col
              xs="12"
              className="d-flex justify-content-between align-content-center"
            >
              <h5 className="text-2xl font-body font-bold">
                Club details
              </h5>
            </Col>
            <Col xs={6} className="font-body mb-7">
              <label
                htmlFor="club_name"
                className="text-lg font-medium color-gray mb-3"
              >
                Club Name <span className="text-danger">*</span>
              </label>
              <div className="position-relative">
                <input
                  id="club_name"
                  type="search"
                  className="po-edit-input mb-1"
                  placeholder="Enter Club Name"
                  value={state.club.club_name}
                  onChange={onChangeClubName}
                  onBlur={onClubBlurHandler}
                />
                {!state.club.club_name && (
                  <ActionIcon additionalClass="bi bi-chevron-down po-input__dropdownIcon position-absolute" />
                )}
              </div>
              {clubs && clubs.length > 0 && state.showList && (
                <ul className="po-updateEvent__clubLists">
                  {clubs.map((club, item) => (
                    <li
                      key={item}
                      className="p-2 font-medium d-flex justify-content-between gap-4"
                      onClick={() => onSelectClub(club)}
                    >
                      <span className='text-nowrap'>{club.name}</span>
                      <span className='text-xs font-light text-end'>{club.address}</span>
                    </li>
                  ))}
                </ul>
              )}
              {(!state.club.club_name || state.error.club_name) && (
                <span className="text-danger font-medium">
                  {state.error.club_name}
                </span>
              )}
            </Col>
            <Col xs={6} className="font-body">
              <PrimaryInput
                id="club_address"
                label="Club Address"
                placeholder="Enter Club Address"
                value={state.club && state.club.club_address}
                disabled
              />
            </Col>
            <Col xs={6} className="font-body">
              <PrimaryInput
                id="city_name"
                label="City Name"
                placeholder="Enter City Name"
                value={state.club && state.club.city_name}
                disabled
              />
            </Col>
          </Row>
        </ContentWrapper>
        <ContentWrapper additionalClass="p-5 mb-5">
          <Row className="g-4">
            <Col xs={12}><h5 className="text-2xl font-body font-bold">Event details</h5></Col>
            <Col xs={6} className="font-body mb-7">
              <PrimaryInput
                id="event_name"
                label="Event name"
                mandatory
                placeholder="Enter event name"
                onChange={onChangeHandler}
                value={state.event_name}
              />
              {state.error.event_name && <span className="text-danger font-medium">{state.error.event_name}</span>}
            </Col>
            <Col xs={6} className="font-body mb-7">
              <PrimaryInput
                id="description"
                label="Description"
                mandatory
                placeholder="Enter description"
                onChange={onChangeHandler}
                value={state.description}
              />
              {state.error.description && <span className="text-danger font-medium">{state.error.description}</span>}
            </Col>
            <Col xs={6} className="font-body mb-7">
              <label
                htmlFor="event_type"
                className="text-lg font-medium color-gray mb-3"
              >
                Event Type <span className="text-danger">*</span>
              </label>
              <select
                id="event_type"
                name="event_type"
                type="text"
                value={state.event_type}
                className="po-edit-input position-relative zIndex__1 bg-transparent text-black mb-1"
                style={{ caretColor: "transparent" }}
                placeholder="Select Event Type"
                onChange={(e) => {
                  setState((prev) => ({
                    ...prev,
                    event_type: e.target.options[e.target.selectedIndex].value,
                    error: {
                      ...state.error,
                      event_type: e.target.options[e.target.selectedIndex].value.trim() ? "" : "Please select an event type"
                    }
                  }));
                }}
              >
                <option key="event_type" value="">Select event type</option>
                {eventTypes && eventTypes.length > 0 &&
                  eventTypes.map((eventType, index) => {
                    return <option value={eventType} key={index} className="text-md">{eventType}</option>;
                  })}
              </select>
              {state.error.event_type && <span className="text-danger font-medium">{state.error.event_type}</span>}
            </Col>
            <Col xs={6} className="font-body mb-7">
              <label htmlFor="event_date" className="text-lg font-medium color-gray mb-3">
                Event Date <span className="text-danger">*</span>
              </label>
              <DatePicker
                selected={state.event_date}
                className="po-edit-input mb-1 w-100"
                dateFormat="dd/MM/YYY"
                placeholderText="dd/mm/yyyy"
                minDate={new Date()}
                excludeDates={nonOperationalDays && nonOperationalDays.map(item => item.toDate())}
                onChange={handleDateChange}
              />
              {state.error.event_date && <span className="text-danger font-medium">{state.error.event_date}</span>}
            </Col>
            <Col xs={6} className="font-body mb-7">
              <label htmlFor="event_timing" className="text-lg  font-medium color-gray mb-3">
                Event Timing <span className="text-danger">*</span>
              </label>
              <input
                name="event_timing"
                id="event_timing"
                value={state.event_timing}
                type="time"
                className="po-edit-input mb-1"
                step="2"
                onChange={onChangeHandler}
              />
              {(state.error.event_timing || state.error.event_timing) && <span className="text-danger font-medium">{state.error.event_timing}</span>}
            </Col>
            <Col xs={6} className="font-body">
              <PrimaryInput
                type="number"
                id="entry_per_head"
                label="Entry Per Head (In Dollars)"
                mandatory
                value={state.entry_per_head}
                min="0"
                placeholder="500"
                onChange={onChangeHandler}
              />
              {state.error.entry_per_head && <span className="text-danger font-medium">{state.error.entry_per_head}</span>}
            </Col>
            <Col xs={6} className="font-body">
              <label className="text-lg  font-medium color-gray mb-2">
                Event Banner
              </label>
              <div
                style={{
                  backgroundImage: `url(${previewImage ? previewImage.replaceAll(" ", "%20") : null})`,
                  border: `${previewImage ? "0px" : "1px"} dashed #D2D6DA`,
                }}
                className="po-updateEvent__inputPhoto mb-1 d-flex align-items-center justify-content-center background-position-center-top background-repeat-norepeat background-size-cover rounded overflow-hidden position-relative"
              >
                {previewImage ? (
                  <div className="position-absolute end-0 top-0">
                    <ActionIcon
                      onClick={() => {
                        setEventImageDeleteModal(true)
                      }}
                      additionalClass="bi bi-x-circle-fill text-3xl color-red me-2"
                    />
                  </div>
                ) : null}
                {previewImage ? null : (
                  <div>
                    <input
                      type="file"
                      id="image"
                      accept="image/*"
                      // value={state.display_image}
                      // {...register("display_image", { required: "Image is required" })}
                      onChange={handleDisplayImage}
                      className="opacity-0 position-absolute start-0 end-0 top-0 bottom-0"
                    />
                    <div className="text-center">
                      <i className="bi bi-plus-circle-fill text-5xl color-lightgreen d-block"></i>
                      <p className="text-xs font-semibold font-body text-center color-gray2 ">
                        Upload image here
                      </p>
                    </div>
                  </div>
                )}
              </div>
              {photoDimensionError.bannerError && <p className="text-danger font-medium mb-2">{photoDimensionError.bannerError ? "Please select an image according to mentioned formats" : ""}</p>}
              <i className="bi bi-info-circle-fill text-md color-yellow1 me-2">
                <span className=" text-sm ml-1 text-dark">
                  Upload image of size 800x800 pixels in one of the valid formats (JPEG, PNG or BMP)
                </span>
              </i>
            </Col>
            <Col xs={6} className="font-body mb-7">
              <label
                htmlFor="event_photos"
                className="text-lg font-medium color-gray mb-2"
              >
                Event Photos
              </label>
              <div
                className=" rounded mb-1 position-relative po-updateEvent__inputPhoto d-flex align-items-center justify-content-center"
                style={{ border: "1px dashed #D2D6DA" }}
              >
                <input
                  type="file"
                  accept="image/*"
                  ref={uploadImagesRef}
                  multiple
                  onChange={handleChangePhotos}
                  className=" position-absolute top-0 bottom-0 start-0 end-0 opacity-0"
                />
                <div className="text-center">
                  <i className="bi bi-plus-circle-fill text-5xl color-lightgreen d-block"></i>
                  <p className="text-xs font-semibold font-body text-center color-gray2 ">
                    Upload images here
                  </p>
                </div>
              </div>
              {!uploadImagesError ? photoDimensionError.photosError && <p className="text-danger font-medium mb-2">{photoDimensionError.photosError && !uploadImagesError ? "Please select image/images according to mentioned formats" : uploadImagesError}</p> : <p className="text-danger font-medium mb-2">{uploadImagesError}</p>}
              <i className="bi bi-info-circle-fill text-md color-yellow1 me-2">
                <span className=" text-sm ml-1 text-dark">
                  Upload image of size 800x800 pixels in one of the valid formats (JPEG, PNG or BMP)
                </span>
              </i>
              {/* start */}
              {/* {uploadImagesError && <span className="text-danger font-medium">{uploadImagesError}</span>} */}
              {
                eventPhotos && eventPhotos.length > 0 && (
                  <ul className="d-flex flex-wrap mt-2">
                    {eventPhotos.map((item, index) => (
                      <li
                        key={index}
                        className="text-xxs po-updateEvent__inputList-item my-1 d-flex justify-content-between"
                      >
                        {" "}
                        <div
                          key={index}
                          style={{
                            height: "75px",
                            width: "75px",
                            backgroundImage: `url(${URL.createObjectURL(item)})`,
                          }}
                          className="position-relative mt-2 text-end me-2 background-position-center-center background-repeat-norepeat background-size-cover rounded overflow-hidden p-1"
                        >

                          <div
                            onClick={() => {
                              setPhotoDeleteModal(true);
                              setPhotoIndex({ ...photoIndex, indexNumber: index, type: "newlyAdded" })
                            }}
                            className="cursor-pointer d-inline-block rounded-circle d-flex align-items-center justify-content-center position-absolute top-0 end-0 z-2"
                            style={{
                              height: "20px",
                              width: "20px",
                            }}
                          >
                            <i className="bi d-inline-block cursor-pointer bi bi-x-circle-fill color-red me-1 mt-1 text-lg"></i>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                )
              }
              {/* end */}
              {
                eventPhotosUrl && <ul className="d-flex flex-wrap mt-2">
                  {eventPhotosUrl.map((item, index) => (
                    <li
                      key={index}
                      className="text-xxs po-updateEvent__inputList-item my-1 d-flex justify-content-between"
                    >
                      <div
                        style={{
                          height: "75px",
                          width: "75px",
                        }}
                        className="position-relative mt-2 text-end me-2 background-position-center-center background-repeat-norepeat background-size-cover rounded overflow-hidden"
                      >
                        <img src={item} alt="xddd" className="rounded" style={{ height: "100%", width: "100%", objectFit: "cover", objectPosition: "center" }} />
                        <div
                          onClick={() => {
                            setPhotoDeleteModal(true)
                            setPhotoIndex({ ...photoIndex, indexNumber: index, type: "notNewlyAdded" })
                          }}
                          className="cursor-pointer d-inline-block rounded-circle  d-flex align-items-center justify-content-center position-absolute top-0 end-0 z-2"
                          style={{
                            height: "20px",
                            width: "20px",
                          }}
                        >
                          <i className="bi d-inline-block cursor-pointer bi bi-x-circle-fill  color-red me-1 mt-1 text-lg"></i>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              }
            </Col>
            <Col xs={12}>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="status"
                  name="status"
                  checked={state.status}
                  onChange={onChangeHandler}
                />
                <label className="form-check-label text-medium" htmlFor="status">{state.status ? 'Active' : 'Inactive'}</label>
              </div>
            </Col>
            <Col
              xs={12}
              className="d-flex justify-content-end align-items-center mb-6"
            >
              {showToaster && (
                <img
                  src="/assets/icons/loading_icon.gif"
                  alt="processing"
                  width={100}
                />
              )}
              <OutlinedButton link="/events" name="Cancel" />
              <SuccessButton
                type="submit"
                name={eventDetails ? "Update" : "Create"}
                icon="bi-check-circle"
                disabled={showToaster ? true : false}
                additionalClass="ms-4"
              />
            </Col>
          </Row>
        </ContentWrapper>
      </form>
      <Modal size="xs" show={photoDeleteModal} onHide={() => setPhotoDeleteModal(false)}  >

        <div className="background-appColor p-5 rounded font-body">
          <h5 className="text-2xl  color-ternary  mb-3">
            Are you sure?
          </h5>
          <ModalBody>
            <p className="text-light font-weight-bold">You want to delete this photo?</p>
          </ModalBody>
          <div className="d-flex justify-content-end">
            <SecondaryButton
              onClick={() => setPhotoDeleteModal(false)}
              name="No"
              additionalClass="text-light mt-3 me-4 px-5"
            />
            <SecondaryButton
              onClick={() => {
                photoIndex.type === "newlyAdded" ?
                  onDeleteImagesList(photoIndex.indexNumber) : onUpdateImagesList(photoIndex.indexNumber)
              }}
              name="Yes"
              additionalClass="text-light mt-3 me-4 px-5"
            />

          </div>
        </div>
      </Modal>
      {/* Club Logo Image */}
      <Modal size="xs" show={eventImageDeleteModal} onHide={() => setEventImageDeleteModal(false)}>
        <div className="background-appColor p-5 rounded font-body">
          <h5 className="text-2xl  color-ternary  mb-3">
            Are you sure?
          </h5>
          <ModalBody>
            <p className="text-light font-weight-bold">You want to delete this banner image?</p>
          </ModalBody>
          <div className="d-flex justify-content-end">
            <SecondaryButton
              onClick={() => setEventImageDeleteModal(false)}
              name="No"
              additionalClass="text-light mt-3 me-4 px-5"
            />
            <SecondaryButton
              onClick={() => {
                setPreviewImage(null);
                setDisplayImage(null);
                setEventImageDeleteModal(false)
              }}
              name="Yes"
              additionalClass="text-light mt-3 me-4 px-5"
            />
          </div>
        </div>
      </Modal>
      <Toaster
        showToaster={showToaster}
        setShowToaster={setShowToaster}
        bg={toasterDetails.bg}
        title={toasterDetails.title}
        bodyText={toasterDetails.bodyText}
        statusIcon={toasterDetails.statusIcon}
      />
    </>
  );
};

export default UpdateEvent;
