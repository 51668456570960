import React, { useState, useEffect } from 'react';
import { collection, deleteDoc, doc, getDocs, query, orderBy } from "firebase/firestore";
import { Button, Table, Modal } from "react-bootstrap";
import { Link } from 'react-router-dom';
import Layout from "../../../Layout/LayoutManager/Layout";
import ContentWrapper from '../../../Components/Global/ContentWrapper';
import PageDescription from '../../../Components/Global/PageDescription';
import ActionIcon from "../../../Components/Global/Buttons/ActionIcons";
import db from "../../../firebase";
import Dropdown from "react-bootstrap/Dropdown";
import PrimaryButton from '../../../Components/Global/Buttons/PrimaryButton';
import SecondaryButton from '../../../Components/Global/Buttons/SecondaryButton';
import moment from "moment";
import Skeleton from 'react-loading-skeleton';


const ManagerCloseBooking = () => {
  const clubId = localStorage.getItem('club_id');
  const [show, setShow] = useState(false);
  const [closedBookings, setClosedBookings] = useState([]);
  const handleClose = () => setShow(false);
  const [deletingId, setDeletingId] = useState("");
  const [isLoading, setIsLoading] = useState([]);

  const getClosedBookingData = async () => {
    const subColRef = query(collection(db, "clubs", clubId, "closed_bookings"), orderBy('updatedAt', 'desc'));
    const qSnap = await getDocs(subColRef);
    let closedBookingsArray = [];
    qSnap.forEach(data => {
      closedBookingsArray.push(data.data());
    });
    setClosedBookings(closedBookingsArray);
    setIsLoading(false);
  }

  useEffect(() => {
    getClosedBookingData();
  }, []);
  const confirmDelete = (deleteId) => {
    setShow(true);
    setDeletingId(deleteId);
  }
  const deleteCloseBooking = async (e, id) => {
    const detetion = await deleteDoc(doc(db, "clubs", clubId, "closed_bookings", id))
      .then(() => {
        getClosedBookingData();
        setShow(false);
        setDeletingId("");
      })
      .catch((err) => {
        console.log(err);
        // alert("Not Deleted");
      });
  }
  return (
    <Layout path="/manager/close-booking">
      <div className="d-flex justify-content-between align-items-center gap-3 gap-lg-5 font-body">
        <PageDescription title="All Non-Operational Days" caption="" />
        {/* <Link to="/manager/create-close-booking">
          <Button variant="light" className="text-nowrap text-md px-5 py-2">
            Add close booking
          </Button>
        </Link> */}
        <PrimaryButton link="/manager/create-close-booking" name="Add Non-Operational Days" />
      </div>
      <ContentWrapper additionalClass="pb-5 font-body" >
        {/* <h2 className="text-lg font-semibold ps-4 py-3 color-lightblue">Showing Closed bookings</h2> */}
        <div className="table-responsive po-table tableFixHead">
          <Table hover>
            <thead>
              <tr className="background-gray10">
                <th className="text-nowrap text-sm text-uppercase"><p className="text-nowrap text-xs text-xl-sm text-uppercase ps-3 color-black2 mb-0 py-2">S. NO</p></th>
                <th><p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">Reason</p></th>
                <th><p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">Date</p></th>
                <th><p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2"></p></th>
              </tr>
            </thead>
            <tbody>
              {!isLoading ?
                (
                  <>
                    {(closedBookings && closedBookings.length) ? closedBookings.map((data, index) => {
                      // console.log(data.date && moment(data.date, "DD/MM/YYYY"), "The date in the li");
                      return (
                        <tr key={index}>
                          <td className="align-middle"><p className='text-sm mb-0 text-nowrap color-lightblue ps-3'>{index + 1}</p></td>
                          <td className="align-middle"><p className="text-sm mb-0 text-nowrap color-lightblue">{data.reason}</p></td>
                          <td className="align-middle"><p className="text-sm mb-0 text-nowrap color-lightblue">{data && data.date && moment(data.date).format("DD MMM YYYY")}</p></td>
                          <td className="align-middle">
                            {/* <div className="d-flex">
                              <Link to={`/manager/update-close-booking/${data.id}`}>
                                <ActionIcon additionalClass="bi-pencil-square text-xl me-3" />
                              </Link>
                              <ActionIcon
                                additionalClass="bi-trash-fill  text-xl text-danger"
                                // onClick={() => hadnleDelete(data)}
                                // onClick={handleShow}
                                onClick={(e) => confirmDelete(data.id)}
                              />
                            </div> */}
                            <Dropdown>
                              <Dropdown.Toggle variant="link" id="dropdown-basic" className="text-black">
                                <i className="bi bi-three-dots-vertical text-4xl d-bloxk"></i>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="border-0 shadow px-2">
                                <Link className="text-black border-bottom font-bold text-sm py-2 d-block ps-1 " to={`/manager/update-close-booking/${data.id}`}>Edit </Link>
                                <div className="text-black  font-bold text-sm py-2 d-block ps-1 cursor-pointer" onClick={(e) => confirmDelete(data.id)}>Delete</div>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      )
                    }) : ""
                    }
                  </>
                ) : (
                  <tr>
                    <td colSpan={4}> <Skeleton inline={true} count={5} height={80} style={{ marginBottom: "1rem" }} baseColor='#dedede' highlightColor='#cccccc' duration={2} />
                    </td>
                  </tr>
                )
              }
            </tbody>
          </Table>
        </div>
      </ContentWrapper>
      <Modal show={show} onHide={handleClose}>
        <div className="background-appColor p-8 rounded font-body">
          <h5 className="text-2xl  color-ternary font-semibold ">
            Are you sure?
          </h5>
          <p className="background-appColor text-light border-bottom-0 py-1 ">
            Do you want to delete this date from the Non-Operational Days list.
          </p>
          <div className="d-flex justify-content-end mt-12">
            {/* <Button variant="secondary" className="me-2 px-6" onClick={(e) => deleteCloseBooking(e, deletingId)}>Yes</Button>
            <Button variant="light" className=" px-6" onClick={handleClose}>No keep it</Button> */}
            <SecondaryButton onClick={(e) => deleteCloseBooking(e, deletingId)} additionalClass="me-2 px-6 text-white" name="Delete" />
            <PrimaryButton onClick={handleClose} name="Cancel" additionalClass="px-6" />
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

export default ManagerCloseBooking;