import React, { useState, useEffect } from 'react';
import { Button, Table, Modal } from "react-bootstrap";
import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import { Link, useParams, useSearchParams } from "react-router-dom";
import db from '../../../../firebase';
import Layout from '../../../../Layout/Layout';
import PageDescription from '../../../../Components/Global/PageDescription';
import ContentWrapper from '../../../../Components/Global/ContentWrapper';
import ActionIcon from '../../../../Components/Global/Buttons/ActionIcons';
import Skeleton from 'react-loading-skeleton';
import PrimaryButton from '../../../../Components/Global/Buttons/PrimaryButton';
import Dropdown from "react-bootstrap/Dropdown";
const AdminSmokeManagement = () => {
  const { club_id } = useParams();
  const [loader, setLoader] = useState(true);
  const [show, setShow] = useState(false);
  const [deletingId, setDeletingId] = useState("");
  const [searchParams] = useSearchParams();
  const clubName = searchParams.get("name");
  console.log(clubName,'c')
  const handleClose = () => {
    setShow(false);
    setDeletingId("");
  }
  const confirmDelete = (deleteId) => {
    setShow(true);
    setDeletingId(deleteId);
  }
  const [smokes, setSmokes] = useState([]);

  const getClubData = async () => {
    console.log("coming inside the get all smoke");
    const subColRef = collection(db, "clubs", club_id, "smoke_category");
    const qSnap = await getDocs(subColRef);
    let drinksArray = [];
    qSnap.forEach(data => {
      drinksArray.push(data.data());
    });
    setSmokes(drinksArray);
    setLoader(false);
  }
  useEffect(() => {
    getClubData();
  }, []);
  const deleteCategory = async (e, id) => {
    const detetion = await deleteDoc(doc(db, "clubs", club_id, "smoke_category", id))
      .then(() => {
        // alert("Successfully Deleted");
        getClubData();
        setShow(false);
      })
      .catch((err) => {
        console.log(err);
        // alert("Not Deleted");
      });
  }
  const showFirstThree = (brandInList) => {
    let rtn = [];
    brandInList.forEach((brand, index) => {
      if (index < 3) {
        rtn.push(brand.name);
      }
    })
    return rtn.join(", ");
  }
  return (
    <Layout path="/clubs/smoke-management">
      <div className="d-flex justify-content-between align-items-center gap-3 gap-lg-5 font-body">
        <PageDescription title="All smoke categories" caption="" />
        <PrimaryButton link={`/clubs/smoke-management/create-category/${club_id}`} name="Add Category" icon="bi-plus-circle" />
      </div>
      <ContentWrapper additionalClass="py-5 rounded font-body">
        <h2 className="text-lg font-semibold ps-4 py-3 color-lightblue">Showing smoke categories for <span className='text-uppercase font-bold text-dark'>{clubName}</span></h2>
        <div className="table-responsive po-table">
          <Table hover>
            <thead>
              <tr className="background-gray10">
                <th>
                  <p className="text-nowrap text-xs text-xl-md text-uppercase ps-3 color-black2 mb-0 py-2">S. No.</p>
                </th>
                <th>
                  <p className="text-nowrap text-xs text-xl-md text-uppercase  color-black2 mb-0 py-2">Smoke Types</p>
                </th>
                <th>
                  <p className="text-nowrap text-xs text-xl-md text-uppercase  color-black2 mb-0 py-2">Brands</p>
                </th>

                <th>
                  <p className="text-nowrap text-xs text-xl-md text-uppercase  color-black2 mb-0 py-2"></p>
                </th>
              </tr>
            </thead>
            <tbody>
              {!loader ? (smokes && smokes.length > 0 && smokes.map((smoke, index) => {
                return (
                  <tr key={index}>
                    <td className="align-middle">
                      <p className="text-md mb-0 text-nowrap color-lightblue font-semibold ps-3">{index + 1}.</p>
                    </td>
                    <td className="align-middle">
                      <p className="text-md mb-0 text-nowrap color-lightblue font-semibold"> {smoke.name}</p>
                    </td>

                    <td className="align-middle">
                      <p className="text-md mb-0 text-nowrap color-lightblue font-semibold">
                        {showFirstThree(smoke.brands)}
                        {smoke.brands.length > 3 && <span className='ms-3 text-sm font-bold text-primary'>{smoke.brands.length - 3} more</span>}

                      </p>
                      {/* <Link to="/manager/smokes-management/brands"><Button variant="outline-dark">View</Button></Link> */}
                    </td>
                    <td className="align-middle">
                      {/* <div className="d-flex">
                        <Link to={`/clubs/smoke-management/update-category/${smoke.id}/${club_id}`}>
                          <ActionIcon
                            onClick={() => { }}
                            additionalClass="bi-pencil-square text-xl me-3"
                          />
                        </Link>
                        <ActionIcon
                          additionalClass="bi-trash-fill  text-xl text-danger"
                          onClick={(e) => confirmDelete(smoke.id)}
                        />
                      </div> */}
                      <Dropdown>
                        <Dropdown.Toggle variant="link" id="dropdown-basic" className="text-black">
                          <i className="bi bi-three-dots-vertical text-4xl d-bloxk"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="border-0 shadow px-2">
                          <Link className="border-bottom font-bold text-sm py-2 d-block text-black ps-2" to={`/clubs/smoke-management/update-category/${smoke.id}/${club_id}`}>
                            Edit
                          </Link>
                          <div className="cursor-pointer font-bold text-sm py-2 d-block text-black ps-2" onClick={(e) => confirmDelete(smoke.id)}>
                            Delete
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                )
              })) : (
                <tr> 
                  <td colSpan={4}> 
                    <Skeleton inline={true} count={5} height={80} style={{marginBottom:"1rem"}} baseColor='#dedede' highlightColor='#cccccc' duration={2} /> 
                  </td> 
                </tr>
              )
              }
            </tbody>
          </Table>
        </div>
      </ContentWrapper>
      <Modal show={show} onHide={handleClose}>
        <div className="background-appColor p-8 rounded font-body">
          <h5 className="text-2xl  color-ternary font-semibold ">
            Are you sure?
          </h5>
          <p className="background-appColor text-light border-bottom-0 py-1 ">
            You want to delete this Smoke category?
          </p>
          <div className="d-flex justify-content-end mt-12">
            <Button variant="secondary" className="me-2 px-6" onClick={(e) => deleteCategory(e, deletingId)}>Yes</Button>
            <Button variant="light" className=" px-6" onClick={handleClose}>No keep it</Button>

          </div>
        </div>
      </Modal>
    </Layout>
  );
};
export default AdminSmokeManagement;
