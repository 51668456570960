// import SubmitButtonPrimary from "../../Components/Global/Buttons/SubmitPrimaryButton";
import ContentWrapper from "../../Components/Global/ContentWrapper";
import Layout from "../../Layout/Layout";
import { Controller, useForm } from "react-hook-form";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  collection,
  getDocs,
  addDoc,
  serverTimestamp,
  query,
  orderBy,
  where,
} from "firebase/firestore";
import db from "../../firebase";
import ActionIcon from "../../Components/Global/Buttons/ActionIcons";

import { Col, Row } from "react-bootstrap";
import SuccessButton from "../../Components/Global/Buttons/SuccessButton";
import OutlinedButton from "../../Components/Global/Buttons/OutlinedButton";
import { getImageSize } from 'react-image-size';
import Toaster from "../../Components/Global/Toast";
import PrimaryButton from "../../Components/Global/Buttons/PrimaryButton";
import AdsModal from "./ads-modal";

const CreateAdvertisement = () => {
  const [showToaster, setShowToaster] = useState(false);
  const [showDateValidationModal, setShowDateValidationModal] = useState(false);
  const [toasterDetails, setToasterDetails] = useState({
    title: "",
    bodyText: "",
    bg: "",
    statusIcon: "",
  });
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    setError,
    setValue,
    control,
    getValues,
    formState: { errors },
  } = useForm();
  const [clubData, setClubData] = useState([]);
  const [eventsData, setEventsData] = useState([]);
  const [categoryValues, setCategoryValues] = useState([]);
  const [categoryValue, setCategoryValue] = useState("");
  const [isGlobal, setIsGlobal] = useState(true)
  const [previewImage, setPreviewImage] = useState(null);
  const [ufile, setUfile] = useState({});
  const [advertisementsCategories, setAdvertisementsCategories] = useState([]);
  const [selectCategory, setSelectCategory] = useState(null);
  const [selectedCategoryType, setSelectedCategoryType] = useState("");
  const [redirectingType, setRedirectingType] = useState("");
  const [cities, setCities] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCities, setSelectedCIties] = useState([]);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState("");
  const [selectedCategoryName, setSelectedCategoryName] = useState("");
  const categoryRef = useRef();


  const [fetchDimensions, setFetchDimensions] = useState(null);
  // const [fetchDimensions, setFetchDimensions] = useState(null);

  const [errorDisplayImage, setErrorDisplayImage] = useState("");

  const fetchCategories = async () => {
    await getDocs(collection(db, "advertisement_categories")).then(
      (QuerySnapshot) => {
        const newData = QuerySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setAdvertisementsCategories(newData);
        // console.log(advertisementsCategories, newData, "bb");
      }
    );
  };
  const getAllData = async () => {
    try {
      const clubsResponse = await getDocs(query(collection(db, "clubs"), orderBy("name", "asc"), where("status", "==", "Active")));
      clubsResponse && setClubData(clubsResponse.docs);
      const eventResponse = await getDocs(query(collection(db, "events"), orderBy("name", "asc"), where("active", "==", true)));
      eventResponse && setEventsData(eventResponse.docs);
      const membershipshipResponse = await getDocs(collection(db, "membership_requests"));
      eventResponse && setEventsData(eventResponse.docs);
    }
    catch (err) {
      console.log(err);
    }
  }

  const getAllCities = async () => {
    await getDocs(collection(db, "cities")).then((response) => setCities(response.docs)).catch(err => console.log(err));
  }
  useEffect(() => {
    getAllData();
    fetchCategories();
    getAllCities();
  }, []);


  const uploadImage = async () => {
    const formData = new FormData();
    formData.append("images", ufile);
    // console.log(ufile, "hhhhh");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_MULTIPLEIMAGES_POSTING_URL}/uploads/uploadImages`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            method: "POST",
          },
        }
      );

      return response;
    } catch (error) {
      console.log(error);
    }
  };


  // / Suggestion box handler

  const handleSearchBox = (e) => {
    const { value } = e.target;
    setSearchItem(value); // Update searchTerm state immediately

    if (value === "") {
      setSuggestions([]);
    }
    else if (value.trim().length === 0) {
      setError("city", {
        type: "custom",
        message: "Only whitespace not allowed"
      })
    }
    else {
      const results = getSearchResults(value);
      setSuggestions(results);
      setError("city", {
        type: "custom",
        message: null
      })
      setValue("city", value)
    }
  };
  const getSearchResults = (input) => {
    if (typeof input !== "string") {
      return [];
    }


    return cities.filter(
      (city) =>
        city &&
        city.data().name &&
        city.data().name.toLowerCase().includes(input.toLowerCase())
    );
  };
  // User suggesstion on click

  const handleUserOnClick = (e) => {
    let cityName = e.target.getAttribute("value");
    let duplicateValidation = selectedCities.find(city => city === cityName)
    if (!duplicateValidation) {
      setSuggestions([]);
      setSelectedCIties([
        ...selectedCities,
        cityName
      ])
      setSearchItem("");
      setError("city", {
        custom: true,
        message: null
      })
    }
    else {
      setError("city", {
        custom: true,
        message: "This city is already selected"
      })
      setSearchItem("");
      setSuggestions([]);
    }

  };

  const onSubmit = async (data) => {
    setShowToaster(true);
    setToasterDetails((prev) => ({
      ...prev,
      title: "Creating...",
      bodyText: "Creating advertisement",
      bg: "warning",
      statusIcon: (
        <div className="spinner-border text-warning me-2" role="status"></div>
      ),
    }));
    let redirectingLink = "";
    if (data.category === "Club") {
      redirectingLink = `clubs/${data.category_value}`
    }
    else if (data.category === "Event") {
      redirectingLink = `events/${data.category_value}`

    }
    // setIsLoading(true);
    console.log(data, "redirecting_url")
    let resultantImage = await uploadImage(ufile);
    if (!errorDisplayImage) {
      // setIsLoading(true);
      setErrorDisplayImage("");
      const resultantImage = await uploadImage(ufile);
      const payload = {
        name: data.name,
        display_image: resultantImage.data.url[0],
        ad_category_name: selectCategory && selectCategory.name,
        ad_category_id: selectCategory && selectCategory.id,
        redirecting_url: redirectingLink ? redirectingLink : data.redirecting_url,
        redirect_type: data.redirect_type,
        createdAt: serverTimestamp(),
        status: data && data.status,
        advertisement_trigger_category: data.category ? data.category : null,
        cities: selectedCities,
        global: isGlobal,
        end_date: new Date(data.end_date),
        start_date: new Date(data.start_date),
        category_selected_name: data.redirect_type === "internal" ? selectedCategoryName : null
      };
      console.log(payload, "payload");
      console.log(payload);
      try {
        const docRef = await addDoc(collection(db, "advertisements"), payload);
        console.log("Document written with ID: ", docRef.id);
        // setIsLoading(false);
        // setIsSuccess(true);
        reset();
        setPreviewImage(null);
        setToasterDetails((prev) => ({
          ...prev,
          title: "Success",
          bodyText: "Created advertisement successfully",
          bg: "success",
          statusIcon: <i className="bi bi-check text-success text-3xl me-2"></i>,
        }));
        setTimeout(() => {
          navigate("/advertisement-manager");
        }, 2000)
      } catch (e) {
        // setIsLoading(false);
        // setIsError(true);
        // console.error("Error adding document: ", e);
        // setTimeout(() => {
        //   setIsError(false);
        // }, 2000)
        setToasterDetails((prev) => ({
          ...prev,
          title: "Error",
          bodyText: "Something went wrong",
          bg: "danger",
          statusIcon: (
            <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
          ),
        }));
        console.error("Error adding document: ", e);
      }
    }
    else {
      console.log("Error...");
    }
  };
  const imgg = new Image();
  imgg.src = ufile;

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    console.log(file, "file");
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        // console.log(reader.result,'rr')
        fetchImageSize(reader.result, file)
      };
      reader.readAsDataURL(file);
    }

  };
  async function fetchImageSize(url, file) {
    // console.log(file, 'testing');
    try {
      const dimensions = await getImageSize(url);
      // console.log(dimensions.width, 'dimensions');
      // 640w*240h
      if (dimensions.width === 320 && dimensions.height === 180) {
        setErrorDisplayImage("");
        setPreviewImage(url);
        setUfile(file);
        setErrorDisplayImage("");
      }
      else {
        console.log("Please upload the image of mentioned dimensions");
        setErrorDisplayImage("Please upload the image of mentioned dimensions");
        // setTimeout(() => {
        //   setErrorDisplayImage("");
        // }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  }

  // // detecting image dimensions
  // const [dimensions] = useImageSize(previewImage);
  // console.log(dimensions, "dim");

  // console.log(imageDimensions,'imageDimensions')
  const categoryHandler = (e) => {
    const selectCategories = {
      name: e.target.options[e.target.selectedIndex].value,
      id: e.target.options[e.target.selectedIndex].id,
    };
    setSelectCategory(selectCategories);
  };
  const validateField = (value) => {
    if (value.trim() === '') {
      return "Ad name is required";
    }
    if (/^\s+$/.test(value)) {
      return "This field should not contain only whitespace characters.";
    }
    return true;
  };
  const handleSuggestionKeyDown = (e, index) => {
    if (e.key === 'ArrowDown') {
      // Handle down arrow key press
      e.preventDefault();
      if (index < suggestions.length - 1) {
        setSelectedSuggestionIndex(index + 1);
      }
    } else if (e.key === 'ArrowUp') {
      // Handle up arrow key press
      e.preventDefault();
      if (index > 0) {
        setSelectedSuggestionIndex(index - 1);
      }
    } else if (e.key === 'Enter') {
      // Handle Enter key press to select the suggestion
      handleSuggestionClick(suggestions[index].data().name);
    }
  };
  const handleSuggestionClick = () => {

  }

  // End date should not exceed start date
  const isEndDateValid = (startDate, endDate) => {
    // You can parse the dates if they are in string format
    const parsedStartDate = new Date(startDate);
    const parsedEndDate = new Date(endDate);

    // Compare the start and end dates
    return parsedEndDate < parsedStartDate;
  };






  return (
    <>
      <Layout path="/advertisement-manager/create-advertisement">
        <ContentWrapper additionalClass="p-5">
          <form onSubmit={handleSubmit(onSubmit)} className="row g-3">
            <div className="col-12 col-sm-12">
              <label
                className="text-md font-body d-block font-bold mb-1"
                htmlFor="image"
              >
                Ad image <span className="text-danger">*</span>
              </label>
              <div
                id="displayImage"
                style={{
                  height: "240px",
                  width: "640px",
                  backgroundImage: `url(${previewImage ? previewImage : null})`,
                  border: `${previewImage ? "0px" : "1px"} dashed #D2D6DA`,
                }}
                className="d-flex align-items-center justify-content-center background-position-center-top background-repeat-norepeat background-size-cover rounded overflow-hidden position-relative"
              >
                {previewImage ? (
                  <div className="position-absolute end-0 top-0">
                    <ActionIcon
                      onClick={() => {
                        setPreviewImage(null);
                      }}
                      additionalClass="bi bi-x-circle-fill text-3xl color-red me-2"
                    />
                  </div>
                ) : null}

                {previewImage ? null : (
                  <div>
                    <input
                      type="file"
                      id="display_image"
                      {...register("display_image", {
                        required: "Image is required",
                      })}
                      accept="image/*"
                      onChange={handleImageChange}
                      className="opacity-0 position-absolute start-0 end-0 top-0 bottom-0 w-100 h-100"
                    />

                    <div className="text-center">
                      <i className="bi bi-plus-circle-fill text-5xl color-lightgreen d-block"></i>
                      <p className="text-md font-body text-center  ">
                        Upload your Image here.
                      </p>
                    </div>
                  </div>

                )}
              </div>
              <i className="bi bi-info-circle-fill text-md color-yellow1 me-2 mt-2 d-inline-block">
                <span className=" text-sm ml-1 text-dark">
                  Please upload the image with the dimensions 320w X 180h px
                </span>
              </i>
              {errors.display_image && !errorDisplayImage && !previewImage && (
                <p className="text-sm text-danger mt-1 font-body">
                  {errors.display_image.message}
                </p>
              )}
              {errorDisplayImage && (
                <p className="text-sm text-danger mt-1 font-body">
                  {errorDisplayImage}
                </p>
              )}
            </div>

            <div className="col-12 col-sm-6">
              <label
                className="text-md font-body d-block font-bold mb-1"
                htmlFor="name"
              >
                Ad name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="name"
                className="po-edit-input"
                {...register('name', { validate: validateField })}

              />
              {errors.name && (
                <p className="text-sm text-danger mt-1 font-body">
                  {errors.name.message}
                </p>
              )}
            </div>


            <div className="col-12 col-sm-6">
              <label
                className="text-md font-body d-block font-bold mb-1"
                htmlFor="url"
              >
                Destination Type <span className="text-danger">*</span>
              </label>
              <select
                {...register("redirect_type", {
                  required: "Redirect type is required",
                })}
                className="po-edit-input font-body"
                onChange={(e) => {
                  setRedirectingType(e.target.value);
                  if (e.target.value === "external") {
                    setValue("category", "");
                    setSelectedCategoryType("");
                    setValue("category_value", "");
                  }
                  // setError("redirect_type", null)
                }}
              >
                <option value="">Destination Type</option>
                <option value="internal">Internal</option>
                <option value="external">External</option>
              </select>
              {/* <input
                type="text"
                id="url"
                className="po-edit-input"
                {...register("redirecting_url", {
                  required: "URL is required",
                })}
              /> */}
              {errors.redirect_type && (
                <p className="text-sm text-danger mt-1 font-body">
                  {errors.redirect_type.message}
                </p>
              )}
            </div>

            <div className="col-12 col-md-6">
              <label
                className="text-md font-body d-block font-bold mb-1"
                htmlFor="url"
              >
                Status <span className="text-danger">*</span>
              </label>
              <select
                {...register("status", {
                  required: "Status is required",
                })}
                className="po-edit-input font-body"
              >
                <option value="">Select status</option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>

              {errors.status && (
                <p className="text-sm text-danger mt-1 font-body">
                  {errors.status.message}
                </p>
              )}
            </div>
            {redirectingType === "internal" &&
              <>
                <Col md={6}>
                  <label className="text-md mb-1 bold" htmlFor="internalRedirect">
                    Select Category <span className="text-danger">*</span>
                  </label>

                  <select
                    id="categoryid"
                    className="po-edit-input"
                    {...register("category", {
                      required: "Category is required",
                    })}
                    onChange={(e) => {
                      setSelectedCategoryType(e.target.value)
                      switch (e.target.value) {
                        case "Club":
                          setCategoryValues(clubData);
                          setSelectedCategoryName(clubData.name)
                          break;
                        case "Event":
                          setCategoryValues(eventsData);
                          setSelectedCategoryName(eventsData.name)
                          break;
                        case "":
                          setCategoryValues([])
                          document.getElementById("categoryid").value = ''
                          document.getElementById("categoryvalueid").value = ""
                          break;
                        default:
                          break;
                      }
                    }}
                  >
                    {selectedCategoryType == "" && <option value="" selected="selected" disabled>Select category</option>}
                    <option value="Club">Club</option>
                    <option value="Event">Event</option>
                  </select>
                  {errors.internalRedirect && (
                    <p className="text-sm text-danger mt-1 font-body">
                      {errors.category.message}
                    </p>
                  )}
                </Col>
                <Col md={6}>
                  {selectedCategoryType === "Club" ? <label className="text-md mb-1 bold" htmlFor="internalRedirect" >{`Select ${selectedCategoryType}`}
                  </label> : selectedCategoryType === "Event" ? <label className="text-md mb-1 bold" htmlFor="internalRedirect" >{`Select ${selectedCategoryType}`}
                  </label> : selectedCategoryType === "" ? <label className="text-md mb-1" htmlFor="internalRedirect" >
                    <p className="m-0 bold">Select Value <span className="text-danger">*</span> </p>
                  </label> : null}

                  <select
                    id="categoryvalueid"
                    className="po-edit-input"
                    {...register("category_value", {
                      required: "Value is required",
                    })}
                    onChange={(e) => {
                      setCategoryValue(e.target.value);
                      setSelectedCategoryName(e.target.options[e.target.selectedIndex].text.split(" -")[0])
                    }}
                  >
                    <option value="" selected="selected" disabled>Select Value</option>
                    {categoryValues && categoryValues.length > 0 && categoryValues.map((club) => {
                      const id = club.data().id;
                      const name = club.data().name;
                      const address = club.data().address;
                      const clubAddress = club.data().club?.address;
                      return (

                        <option key={id} value={id} title={address}>
                          <span className="text-success">{name}</span> -{" "}
                          {selectedCategoryType === "Club" ? address : clubAddress || "N/A"}
                        </option>
                      )
                    })
                    }
                  </select>
                  {errors.internalRedirect && (
                    <p className="text-sm text-danger mt-1 font-body">
                      {errors.category_value.message}
                    </p>
                  )}
                </Col>
              </>
            }
            {redirectingType === "external" &&
              <div className="col-12 col-sm-6">
                <label
                  className="text-md font-body d-block font-bold mb-1"
                  htmlFor="url"
                >
                  Redirecting URL <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="url"
                  className="po-edit-input"
                  {...register("redirecting_url", {
                    required: "URL is required",
                  })}
                />
                {errors.redirecting_url && (
                  <p className="text-sm text-danger mt-1 font-body">
                    {errors.redirecting_url.message}
                  </p>
                )}
              </div>
            }

            <div className="col-12 col-sm-6">
              <label
                className="text-md font-body d-block font-bold mb-1"
                htmlFor="url"
              >
                Start Date <span className="text-danger">*</span>
              </label>
              <input
                type="datetime-local"
                min={`${new Date().toISOString().slice(0, 16)}`}
                className="po-edit-input"
                {...register("start_date", {
                  required: "Start date is required",
                })}
              />
              {errors.start_date && (
                <p className="text-sm text-danger mt-1 font-body">
                  {errors.start_date.message}
                </p>
              )}
            </div>
            <div className="col-12 col-sm-6">
              <label
                className="text-md font-body d-block font-bold mb-1"
                htmlFor="url"
              >
                End Date <span className="text-danger">*</span>
              </label>
              <input
                type="datetime-local"
                min={`${new Date().toISOString().slice(0, 16)}`}
                className="po-edit-input"
                {...register('end_date', {
                  required: 'End date is required',
                  validate: (value) => {
                    const startDate = new Date(getValues('start_date'));
                    const endDate = new Date(value);
                    if (startDate > endDate) {
                      setShowDateValidationModal(true);
                      setTimeout(() => {
                        setShowDateValidationModal(false)
                      }, 1000)
                      return false;
                    }

                    return true;
                  },
                })}
              />
              {errors.end_date && (
                <p className="text-sm text-danger mt-1 font-body">
                  {errors.end_date.message}
                </p>
              )}
            </div>
            {/* Global section  */}
            <Col xs={6}>
              <label
                className="text-md font-body d-block font-bold mb-1"
                htmlFor="url"
              >
                Global <span className="text-danger">*</span>
              </label>
              <div style={{
                width: "75px",
                height: "90px"
              }}>
                <input type="checkbox"
                  className=""
                  style={{ height: "20px", width: "20px" }}
                  value={isGlobal}
                  checked={isGlobal}
                  onChange={() => {
                    setIsGlobal(!isGlobal);
                    setSelectedCIties([]);

                  }}
                />
              </div>
            </Col>

            {/* If it is not Global, it will show city */}
            {isGlobal === false &&
              <>
                <div className="col-12 col-md-6">
                  <label
                    className="text-md font-body d-block font-bold mb-1"
                    htmlFor="url"
                  >
                    City <span className="text-danger">*</span>
                  </label>
                  {/* <ContentWrapper additionalClass="p-5"> */}
                  <form>
                    <Row>
                      <Col xs={12}>
                        <div>
                          <Controller
                            control={control}
                            name="city"
                            rules={{ required: "This is required field" }}
                            render={({ fields }) => {
                              return (
                                <input
                                  {...fields}
                                  placeholder="Please enter some text..."
                                  value={searchItem}
                                  className="po-edit-input"
                                  type="search"
                                  onChange={handleSearchBox}

                                />
                              )
                            }}

                          />
                          {/* <input
                              placeholder="Please enter some text..."
                              value={searchItem}
                              className="po-edit-input"
                              type="search"
                              onChange={handleSearchBox}

                        /> */}

                          <ul className={`mt-2 ${suggestions.length > 0 && `card`} py-2`}>
                            {suggestions &&
                              suggestions.map((city, index) => (
                                <li
                                  onKeyDown={(e) => console.log()}
                                  key={index}
                                  value={city.data().name}
                                  className={`px-3 on-hover ${index === selectedSuggestionIndex ? 'selected' : ''}`}
                                  onClick={handleUserOnClick}
                                  tabIndex={0}
                                >
                                  {city.data().name}
                                </li>
                              ))}
                          </ul>
                        </div>

                        <Row className="g-2">
                          {selectedCities && selectedCities.length > 0 &&
                            selectedCities.map((ele, index) => {
                              return (
                                <Col sm={6} md={4} key={index}>
                                  <div className="d-flex justify-content-between flex-row card p-2 position-relative">
                                    <p className="text-sm font-semibold mb-0 mt-1">{ele}</p>
                                    <ActionIcon
                                      onClick={() => {
                                        let tempCities = selectedCities;
                                        tempCities.splice(index, 1);
                                        setSelectedCIties([...tempCities])
                                      }}
                                      additionalClass="bi bi-x-circle-fill text-lg color-red ms-1 position-absolute top-1 end-0 me-1"
                                    />
                                  </div>

                                </Col>
                              )
                            })

                          }
                        </Row>
                        {errors.city && (
                          <p className="mb-0 mt-0 text-sm text-danger mt-2">
                            {errors.city.message}
                          </p>
                        )}

                      </Col>

                      <div className="text-end">
                        <PrimaryButton
                          disabled={showToaster}
                          name="Apply"
                        // onClick={handleApplyButton}
                        />
                      </div>
                    </Row>
                  </form>
                  {/* </ContentWrapper> */}


                </div>

              </>
            }

            <div className="col-12 text-end">
              <OutlinedButton link="/advertisement-manager" name="Cancel" additionalClass="px-10" />
              <SuccessButton type="submit" name="Create" icon="bi-check-circle" additionalClass={`ms-4 px-10 ${showToaster ? "opacity-06 cursor-default" : null}  `} disabled={showToaster ? true : false} />
            </div>
          </form>
        </ContentWrapper>
        <AdsModal
          icon={true}
          showModal={showDateValidationModal}
          setShowModal={() => setShowDateValidationModal(true)}
          warningText="Warning"
          bodyText="Please ensure that the selected end date is lesser than the start date"
          bodyParaClass="text-light font-weight-bold"
          successButtonClass="text-light mt-3 me-4 px-5"
          cancelButtonClass="text-light mt-3 me-4 px-5"
        />



        <Toaster
          showToaster={showToaster}
          setShowToaster={setShowToaster}
          bg={toasterDetails.bg}
          title={toasterDetails.title}
          bodyText={toasterDetails.bodyText}
          statusIcon={toasterDetails.statusIcon}

        />
      </Layout>
    </>
  );
};
export default CreateAdvertisement;
