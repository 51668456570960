import React from 'react';
import { useState } from 'react';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../firebase';

const Login = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [error, setError] = useState("");
  const [type, setType] = useState(true);
  const [showIcon, setShowIcon] = useState(true);

  const showPassword =() => {
    setType(!type);
    setShowIcon(!showIcon);
 }
  console.log("Env ====>", process.env.REACT_APP_FIREBASE_APIKEY)
  const onSubmit = (data) => {
    let email = data && data.email;
    let password = data && data.password;
    signInWithEmailAndPassword(auth, email, password).then(async (userCredential) => {
      localStorage.setItem("authenticate", auth.currentUser.accessToken);
      const idTokenResult = await auth.currentUser.getIdTokenResult();
      localStorage.setItem("roles", JSON.stringify(idTokenResult.claims.role));
      if (idTokenResult.claims.role.includes("admin")) {
        localStorage.setItem("userType", "admin");
        window.location.replace("/dashboard");

      } else if (idTokenResult.claims.role.includes("manager")) {
        localStorage.setItem("club_id", idTokenResult.claims.club_id);
        localStorage.setItem("userType", "manager");
        window.location.replace("/manager");
      }
      localStorage.setItem("user_name", auth.currentUser.providerData[0].displayName);
      localStorage.setItem("email", idTokenResult.claims.email);
      localStorage.setItem("user_id", idTokenResult.claims.user_id);
    }).catch((error) => {
      const errorCode = error.code;
      if (errorCode === "auth/user-not-found")
        setError("Please verify email id");
      else if (errorCode === "auth/wrong-password")
        setError("Please verify password")
      else
        setError(errorCode)
    })
  }
  return (
    <Container fluid className="po-login__wrapper d-flex align-items-center">
      <Container>
        <Row className="d-flex justify-content-center">
          <Col lg={5} >
            <form onSubmit={handleSubmit(onSubmit)} className="font-body">
              <div>
                <div className='d-flex justify-content-center'>
                  <div className="d-flex justify-content-center" style={{ width: "40%", marginBottom: "45px" }}>
                    <img src="/partyone-new-logo.svg" className='img-fluid' alt="Party One Logo" />
                  </div>
                </div>
                <h1 className="text-white font-body font-medium text-3xl text-lg-4xl mb-4 text-center">Sign In</h1>
                {error && <Alert variant='danger'>{error}</Alert>}
                <input className="po-input w-100 mb-4" type="email" placeholder='Email/Username' required {...register('email', { required: "Username is required", pattern: { value: /^\S+@\S+$/i, message: "This is not a valid email" } })} />
                <div className="d-flex align-items-center">
                  <input className="po-input w-100 mb-2" type={type ? "password" : "text"} placeholder='Password' required {...register('password', { required: "Password is required", minLength: { value: 4, message: "Password must be more than 4 characters" } })} />
                  <span style={{marginLeft:"-30px"}}><i className={`bi ${showIcon ? "bi-eye-fill": "bi-eye-slash-fill"} color-gray`} onClick={showPassword}></i></span>
                </div>
                <div className="d-flex align-items-center">
                  <input className="po-checkbox" id="rememberMe" type="checkbox" />
                  <label htmlFor="rememberMe" className="text-white font-semibold font-body ps-1">Remember me</label>
                </div>
                <div className="d-flex justify-content-center mb-2 px-3">
                  <button className="po-login__button text-xl font-body font-semibold w-100 py-2 mt-10 text-uppercase" type="submit">login</button>
                </div>
                <a href='mailto:techadmin@htp.world' className=" font-light text-white"><p className="font-body text-center text-capitalize text-white text-md mt-5 ">Trouble signing in? <span className='fst-italic'>Contact support</span></p></a>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default Login