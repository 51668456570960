import Chart from "react-apexcharts";
const BarChart = ({ membership_upgrade_analytycs }) => {
  const options = {
    chart: {
      type: "bar",
      fontFamily: 'Montserrat, sans-serif',
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: 'left',
    },
    series: [
      {
        name: "Members",
        data: Object.values(membership_upgrade_analytycs),
      },
    ],
    xaxis: {
      categories: ["Solitaire", "Platinum", "Gold", "Amethyst"],
    },
    colors: ["#003f5c",
      "#444e86",
      "#955196",
      "#dd5182",
      "#ff6e54",
      "#ffa600"],
    plotOptions: {
      bar: {
        columnWidth: "45%",
        distributed: true,
      },
    },
  };

  return (
    // <ContentWrapper additionalClass="p-5">
    //   <div className="d-flex justify-content-between font-body">
    //     <div>
    //     <h2 className="text-lg font-bold color-blue font-body mb-0">Members</h2>
    //     <p className="text-sm">Total members: 200</p>
    //     </div>
    //     <div>
    //       <DateRange/>
    //     </div>
    //   </div>
    <Chart
      options={options}
      series={options.series}
      type="bar"
      height={350}
    />
    // </ContentWrapper>
  );
};
export default BarChart;
