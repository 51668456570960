import React, { useState, useEffect } from 'react';
import ContentWrapper from "../../../Components/Global/ContentWrapper";
import Layout from "../../../Layout/LayoutManager/Layout";
import { useForm } from "react-hook-form";
import { Button } from "react-bootstrap";
import PageDescription from '../../../Components/Global/PageDescription';
import { doc, setDoc, collection, query, where, getDocs } from "firebase/firestore";
import { v4 as uuid } from 'uuid';
import db from "../../../firebase";
import PrimaryButton from "../../../Components/Global/Buttons/PrimaryButton";
import OutlinedButton from "../../../Components/Global/Buttons/OutlinedButton";
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import Toaster from '../../../Components/Global/Toast';

const ManagerCreateCloseBooking = () => {
  const clubId = localStorage.getItem('club_id');
  const navigate = useNavigate();
  const validateField = (value) => { if (value.trim() === '') { return "This field should not be empty."; } if (/^\s+$/.test(value)) { return "This field should not contain only whitespace characters."; } return true; };
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const [showToaster, setShowToaster] = useState(false);
  const [toasterDetails, setToasterDetails] = useState({
    title: "",
    bodyText: "",
    bg: "",
    statusIcon: "",
  });

  const onSubmit = async (data) => {
    setShowToaster(true);
    setToasterDetails((prev) => ({
      ...prev,
      title: "Creating...",
      bodyText: "Adding non operational day details",
      bg: "warning",
      statusIcon: (
        <div className="spinner-border text-warning me-2" role="status"></div>
      ),
    }));
    let id = uuid();
    const updateData = {
      id: id,
      date: data.date,
      reason: data.message.trimStart().replace(/\s+/g, ' ').trimEnd(),
      createdAt: new Date(),
      updatedAt: new Date()
    };
    const duplicateQuery = query(
      collection(db, "clubs", clubId, "closed_bookings"),
      where("date", "==", data.date)
    );
    const duplicateDocs = await getDocs(duplicateQuery);
    if (!duplicateDocs.empty) {
      setError("date", {
        type: "error",
        message: "This date is already a non-operational day",
      });
      return;
    } else {
      const clubResponse = await setDoc(doc(db, "clubs", clubId, "closed_bookings", id), updateData)
        .then(() => {
          setToasterDetails((prev) => ({
            ...prev,
            title: "Success",
            bodyText: "non operational days are added successfully",
            bg: "success",
            statusIcon: (
              <i className="bi bi-check text-success text-3xl me-2"></i>
            ),
          }));
          setTimeout(()=> {
            navigate(`/manager/close-booking`, { replace: true })
          }, 2000)
        })
        .catch(err=> {
          console.log(err, 'err');
          setToasterDetails((prev) => ({
            ...prev,
            title: "Error",
            bodyText: "Something went wrong, Please try again",
            bg: "danger",
            statusIcon: (
              <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
            ),
          }));
          setTimeout(() => {
            setShowToaster(false);
          }, 2000);
        })
    }


  };
  const resetErrors = () => {
    console.log("comes inside ");
  }
  return (
    <Layout path="/manager/create-close-booking">
      <div className="d-flex justify-content-between align-items-center gap-3 gap-lg-5 font-body">
        <PageDescription title="Add Non-Operational Days" caption="" />
      </div>
      <ContentWrapper additionalClass="p-6 font-body">
        <form onSubmit={handleSubmit(onSubmit)} className="col-12">
          <label htmlFor="date" className="text-md">
            Date<span className="text-danger">*</span>
          </label>
          <input
            className="po-edit-input"
            type="date"
            id="date"
            name="date"
            onChange={() => resetErrors()}
            min={moment().format('YYYY-MM-DD')}
            {...register("date", { required: true })}
          />
          {errors.date && (
            <>{
              errors.date.message ?
                <p className="text-sm text-danger">{errors.date.message}</p> :
                <p className="text-sm text-danger">Please select a date.</p>
            }
            </>
          )}
          <label htmlFor="message" className="text-md mt-3">
            Reason<span className="text-danger">*</span>
          </label>
          <textarea
            className="po-edit-input"
            id="message"
            {...register("message", { validate: validateField })}
          />
          {errors.message && (
            <p className="text-sm text-danger">Please enter a message.</p>
          )}

          <div className="text-end mt-4">
            {/* <Button variant="success" type="submit" >
              Submit
            </Button> */}
            {showToaster && (
                <img
                  src="/assets/icons/loading_icon.gif"
                  alt="processing"
                  width={100}
                />
              )}
            <OutlinedButton link="/manager/close-booking" name="Cancel" additionalClass="me-3" />
            <PrimaryButton type="Submit" name="Create Non-Operational Days" />
          </div>
        </form>
      </ContentWrapper>
      <Toaster
        showToaster={showToaster}
        setShowToaster={setShowToaster}
        bg={toasterDetails.bg}
        title={toasterDetails.title}
        bodyText={toasterDetails.bodyText}
        statusIcon={toasterDetails.statusIcon}
      />
    </Layout>
  );
};
export default ManagerCreateCloseBooking;
