import { motion } from "framer-motion";
const ActionIcon = ({ additionalClass, onClick }) => {
  return (
    <motion.span
      whileHover={{
        scale: 1.2,
        transition: { duration: 0.1 },
      }}
      whileTap={{ scale: 0.9 }}
      className={`bi d-inline-block cursor-pointer ${additionalClass}`}
      onClick={onClick}
    ></motion.span>
  );
};

export default ActionIcon;
