import React, { useCallback, useState, useEffect, createRef, useRef } from "react";
import ContentWrapper from "../Global/ContentWrapper";
import { Col, Row, Modal, ModalBody } from "react-bootstrap";
import PrimaryInput from "../UI/PrimaryInput";
import ActionIcon from "../Global/Buttons/ActionIcons";
import { useNavigate, useParams } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import {
  collection,
  doc,
  endAt,
  getDoc,
  getDocs,
  orderBy,
  query,
  startAt,
  where,
} from "firebase/firestore";
import db from "../../firebase";
import { useForm } from "react-hook-form";
import UpdatePassword from "../Global/Popup/UpdatePassword";
import OutlinedButton from "../Global/Buttons/OutlinedButton";
import SuccessButton from "../Global/Buttons/SuccessButton";
import Toaster from "../Global/Toast";
import axios from "axios";
import moment from "moment";
import SecondaryButton from "../Global/Buttons/SecondaryButton";

const UpdateUser = ({ createNewUser, userDetails, type, club_id, clubName }) => {
  const navigate = useNavigate();
  const { handleSubmit } = useForm({});
  const clubListRef = createRef();
  const searchInputRef = useRef("");
  const [clubInputLoader, setClubInputLoader] = useState(false);
  const [showClubList, setShowClubList] = useState(false);
  const [active, setActive] = useState(0);
  const searchClubIdRef = useRef("");
  const [checkedWhatsapp, setCheckedWhatsapp] = useState(false);
  const { user_id } = useParams();
  const [userImageDeleteModal, setUserImageDeleteModal] = useState(null)
  const [showToaster, setShowToaster] = useState(false);
  const [countries, setCountries] = useState(null);
  const [cities, setCities] = useState(null);
  const [states, setStates] = useState(null);
  const [country, setCountry] = useState({
    countryName: "",
    countryId: "",
  });
  const [userState, setUserState] = useState({
    stateName: "",
    stateId: "",
  })
  const [city, setCity] = useState({
    cityName: "",
    cityId: "",
  });
  const [gender, setGender] = useState("");
  const [genderError, setGenderError] = useState("");
  const [toasterDetails, setToasterDetails] = useState({
    title: "",
    bodyText: "",
    bg: "",
    statusIcon: "",
  });
  const [whatsappNumber, setWhatsapp] = useState(null);
  const [displayImage, setDisplayImage] = useState(null);
  const [userRoles, setUserRoles] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [clubs, setClubs] = useState([]);
  const [phone, setPhone] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const handleButtonClick = () => setShowModal(true);
  const [favoriteClubState, setFavoriteClubState] = useState({
    favClub1: "",
    favClub2: "",
    favClub3: ""
  })
  const [state, setState] = useState({
    email: "",
    password: "",
    name: "",
    role: [],
    club_id: "",
    club_name: "",
    showRoles: false,
    dob: "",
    errorMessage: {
      email: "",
      password: "",
      name: "",
      role: "",
      club_name: "",
      phone: "",
      whatsapp: "",
      dob: "",
      countryError: "",
      stateError: "",
      cityError: "",
      noClubFound: ""
    },
  });

  useEffect(() => {
    if (createNewUser) {
      gettingAllCountries();
    }
    // fetching user roles ******************************************
    const docRef = doc(db, "picklist", "user_role");
    getDoc(docRef)
      .then((userRole) => {
        let obj = {};
        for (const key in userRole.data()) {
          obj[key] = "";
        }
        setUserRoles(Object.keys(obj).sort((a, b) => a > b ? 1 : -1));
      })
      .catch((err) => console.log(err, "err"));
    if (JSON.stringify(userDetails) !== "{}" && !createNewUser) {
      if (userDetails && userDetails.data && userDetails.data.contacts[0] && userDetails.data.contacts[1] && userDetails.data.contacts[0].contact_no && userDetails.data.contacts[1].contact_no && (userDetails.data.contacts[0].contact_no === userDetails.data.contacts[1].contact_no)) {
        setCheckedWhatsapp(true);
      }
      if (userDetails && userDetails.data && userDetails.data.customClaims && userDetails.data.customClaims.club_id) {
        getDoc(doc(db, "clubs", userDetails.data.customClaims.club_id))
          .then((result) => {
            gettingAllCountries();
            gettingAllStates(userDetails.data.home_country);
            gettingAllCities(userDetails.data.home_state, userDetails.data.home_country);

            setCountry({
              ...country,
              countryId: userDetails.data.home_country,
              countryName: userDetails.data.home_country_name,
            });

            setUserState({
              ...userState,
              stateId: userDetails.data.home_state,
              stateName: userDetails.data.home_state_name
            })
            setCity({
              ...city,
              cityId: userDetails.data.home_city,
              cityName: userDetails.data.home_city_name,
            });
            setState((prev) => ({
              ...prev,
              name: userDetails && userDetails.data && userDetails.data.name,
              email: userDetails.data && userDetails.data.email,
              role: userDetails.data.customClaims.role.map(item => item.toLowerCase()),
              club_id: result.data() && result.data().id,
              club_name: result.data() && result.data().name,
              dob: userDetails.data && userDetails.data.dob,
              errorMessage: {
                ...state.errorMessage,
              },
            }));
            userDetails &&
              userDetails.data &&
              userDetails.data.contacts &&
              userDetails.data.contacts[0] &&
              userDetails.data.contacts[0].is_whatsapp
              ? setCheckedWhatsapp(userDetails.data.contacts[0].is_whatsapp)
              : userDetails.data.contacts[1] && setWhatsapp(userDetails.data.contacts[1].contact_no);
            setPhone(
              userDetails.data &&
              userDetails.data.contacts &&
              userDetails.data.contacts[0] &&
              userDetails.data.contacts[0].contact_no
            );
            setGender(userDetails.data.gender);
          })
          .catch((error) => console.log(error, "error"));
      } else if (userDetails && userDetails.data && userDetails.data.customClaims &&
        !userDetails.data.customClaims.club_id) {

        setState((prev) => ({
          ...prev,
          name: userDetails.data.name,
          email: userDetails.data.email,
          role: userDetails && userDetails.data && userDetails.data.customClaims && userDetails.data.customClaims.role && userDetails.data.customClaims.role.map(item => item.toLowerCase()),
          dob: userDetails.data && userDetails.data.dob,
          errorMessage: {
            ...state.errorMessage,
          },
        }));
        gettingAllCountries();
        gettingAllStates(userDetails.data.home_country)
        gettingAllCities(userDetails.data.home_state, userDetails.data.home_country);
        setCountry({
          ...country,
          countryId: userDetails.data.home_country,
          countryName: userDetails.data.home_country_name,
        });
        setUserState({
          ...userState,
          stateId: userDetails.data.home_state,
          stateName: userDetails.data.home_state_name
        })
        setCity({
          ...city,
          cityId: userDetails.data.home_city,
          cityName: userDetails.data.home_city_name,
        });
        setCountry({
          ...country,
          countryId: userDetails.data.home_country,
          countryName: userDetails.data.home_country_name,
        });
        userDetails &&
          userDetails.data &&
          userDetails.data.contacts &&
          userDetails.data.contacts[0] &&
          userDetails.data.contacts[0].is_whatsapp === true
          ? setCheckedWhatsapp(userDetails.data.contacts[0].is_whatsapp)
          : userDetails.data.contacts[1] && setWhatsapp(userDetails.data.contacts[1].contact_no);
        gettingAllCities(userDetails.data.home_state, userDetails.data.home_country);
        setCity({
          ...city,
          cityId: userDetails.data.home_city,
          cityName: userDetails.data.home_city_name,
        });
        setPhone(
          userDetails.data &&
          userDetails.data.contacts &&
          userDetails.data.contacts[0] &&
          userDetails.data.contacts[0].contact_no
        );
        setGender(userDetails.data.gender)
      } else {
        setState((prev) => ({
          ...prev,
          name: userDetails.data.name ?? "",
          email: userDetails.data.email,
          dob: userDetails.data && userDetails.data.dob,
          favorite_clubs: userDetails.data && userDetails.data.favorite_clubs,
          role: userDetails.data.role,
          errorMessage: {
            ...state.errorMessage,
          },
        }));
        gettingAllCountries();
        gettingAllStates(userDetails && userDetails.data && userDetails.data.home_country)
        gettingAllCities(userDetails && userDetails.data && userDetails.data.home_state, userDetails && userDetails.data && userDetails.data.home_country);
        setFavoriteClubState({
          favClub1: userDetails && userDetails.data && userDetails.data.favorite_clubs && userDetails.data.favorite_clubs[0] ? userDetails.data.favorite_clubs[0] : "",
          favClub2: userDetails && userDetails.data && userDetails.data.favorite_clubs && userDetails.data.favorite_clubs[1] ? userDetails.data.favorite_clubs[1] : "",
          favClub3: userDetails && userDetails.data && userDetails.data.favorite_clubs && userDetails.data.favorite_clubs[2] ? userDetails.data.favorite_clubs[2] : "",
        })
        // setting state for contact , dob , whatsapp number
        userDetails &&
          userDetails.data &&
          userDetails.data.contacts.length &&
          userDetails.data.contacts[0] &&
          userDetails.data.contacts[0].is_whatsapp === true
          ? setCheckedWhatsapp(userDetails.data.contacts[0].is_whatsapp)
          : userDetails.data.contacts[1] && setWhatsapp(userDetails.data.contacts[1].contact_no);
        userDetails &&
          userDetails.data &&
          userDetails.data.contacts &&
          userDetails.data.contacts[0] &&
          setPhone(userDetails.data.contacts[0].contact_no);
        // userDetails && userDetails.data && setDob(userDetails.data.dob);
        setCountry({
          ...country,
          countryId: userDetails.data.home_country,
          countryName: userDetails.data.home_country_name,
        });
        setUserState({
          ...userState,
          stateId: userDetails.data.home_state,
          stateName: userDetails.data.home_state_name
        })
        setCity({
          ...city,
          cityId: userDetails.data.home_city,
          cityName: userDetails.data.home_city_name,
        });
        userDetails.data.gender && setGender(userDetails.data.gender);

        // setIsLoading(false);
      }
      if (userDetails.data.profile_image) setPreviewImage(userDetails.data.profile_image);
      else setPreviewImage(null);
      // const dummyImg =
      //   "https://storage.googleapis.com/party_images/1683799221634-person-fill.svg";
      // userDetails.data.profile_image !== dummyImg
      //   ? setPreviewImage(userDetails.data.profile_image)
      //   : setPreviewImage(null);
    }
  }, [userDetails]);


  useEffect(() => {
    if (club_id !== null && type === "concierge") {
      setState((prev) => ({
        ...prev,
        role: [...state.role, "concierge"],
      }));
      setState((prev) => ({
        ...prev,
        club_name: clubName,
        club_id: club_id,
        errorMessage: {
          ...state.errorMessage,
          club_name: "",
        },
      }));
    }
    else if (club_id !== null && type === "manager") {
      setState((prev) => ({
        ...prev,
        role: [...state.role, "manager"],
      }));
      setState((prev) => ({
        ...prev,
        club_name: clubName,
        club_id: club_id,
        errorMessage: {
          ...state.errorMessage,
          club_name: "",
        },
      }));
    }
  }, [])

  const keyDownHandler = event => {
    if (showClubList) {
      if (event.keyCode === 38 && active > 0) {
        let tempActive = active - 1;
        setActive(tempActive);
        clubListRef.current.scrollTop -= document.getElementById(`club-${active}`).offsetHeight;
      }
      if (event.keyCode === 40 && active < clubs.length - 1) {
        let nextTemp = active + 1;
        setActive(nextTemp);
        clubListRef.current.scrollTop += document.getElementById(`club-${active}`).offsetHeight;
      }
    } else {
      if (event.keyCode === 38 && active > 0) {
        let tempActive = active - 1;
        setActive(tempActive);
      }
    }
  };

  const searchInputHandler = (e) => {
    const { name, value } = e.target;
    searchInputRef.current = value;
    if (searchInputRef.current !== "") {
      searchClubIdRef.current = "";
      setState(prev => ({
        ...prev,
        [name]: searchInputRef.current,
        club_id: "",
        errors: {
          ...state.errors,
          [name]: ""
        }
      }))
      setClubInputLoader(true);
      fetchSearchedData(searchInputRef.current, "club_name");
    }
    else {
      setClubs([]);
      setState(prev => ({
        ...prev,
        [name]: searchInputRef.current,
        errors: {
          ...state.errors,
          [name]: ""
        }
      }))
      setClubInputLoader(false);
    }
  }
  const fetchSearchedData = useCallback(
    debounce((val, searchField) => {
      if (!searchInputRef.current) return;
      val.length !== 0 &&
        getDocs(query(collection(db, "clubs"), where("status", "==", "Active"),
          orderBy("name_lower_case"),
          startAt(val.replace(/\s+/g, ' ').trim().toLowerCase()),
          endAt(`${val.replace(/\s+/g, ' ').trim().toLowerCase()}\uf8ff`)))
          .then((docSnapshots) => {
            setClubs([]);
            if (docSnapshots.size) {
              setClubInputLoader(false);
              setShowClubList(true);
            }
            else {
              setState(prev => ({
                ...prev,
                errors: {
                  ...state.errors,
                  club_name: "No clubs have been found. Please choose a different one."
                }
              }))
              return;
            }
            docSnapshots.forEach((docs) => {
              if (docs.data().latitude && docs.data().longitude) {
                setClubs((prev) => [...prev, docs.data()]);
              }
            });
          })
    }, 1000), []);
  const onClubInputBlurHandler = () => {
    setTimeout(() => {
      setShowClubList(false);
      setActive(0);
      if (!searchClubIdRef.current) {
        setState(prev => ({
          ...prev,
          errors: {
            ...state.errors,
            club_name: "Please select club name"
          }
        }))
      } else {
        setState(prev => ({
          ...prev,
          errors: {
            ...state.errors,
            club_name: ""
          }
        }))
      }
    }, 300);
  };

  function debounce(fn, delay) {
    let timer;
    return function () {
      let context = this,
        args = arguments;
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(context, args);
      }, delay);
    };
  }
  const onSelectSearchedInput = (id) => {
    let selectedClubDetailsFound = clubs.find(item => item.id === id)
    setState(prev => ({
      ...prev,
      club_name: selectedClubDetailsFound.name,
      club_id: selectedClubDetailsFound.id,
      errors: {
        ...state.errors,
        club_name: ""
      }
    }))
    searchClubIdRef.current = selectedClubDetailsFound.id;
  };

  //Getting All Countries
  const gettingAllCountries = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/location/v2/countries`)
      .then((countries) => {
        const { data } = countries;
        setCountries(data);

      })
      .catch((err) => console.log(err));
  };
  //Getting All Cities
  const gettingAllCities = async (stateId, countryId) => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/location/v3/userCities?country=${countryId}&state=${stateId}`)
      .then((cities) => {
        const { data } = cities;
        setCities(data);
      })
      .catch((err) => console.log(err));
  };

  //Getting All Cities
  const gettingAllStates = async (countryId) => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/location/v1/userState?country=${countryId}`)
      .then((states) => {
        const { data } = states;
        setStates(data);
      })
      .catch((err) => console.log(err));
  };

  // postImages ****************************************************
  const postImages = async (files) => {
    const formdata = new FormData();
    for (const file of files) {
      formdata.append("images", file);
    }
    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    const resultPhotos = await fetch(
      `${process.env.REACT_APP_MULTIPLEIMAGES_POSTING_URL}/uploads/uploadImages`,
      requestOptions
    );
    const response = await resultPhotos.json();
    if (resultPhotos.status === 200) {
      return response.url;
    } else {
      return "Something went wrong!";
    }
  };

  const handleDisplayImage = (e) => {
    const file = e.target.files[0];
    setDisplayImage(e.target.files);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const onSubmit = async () => {
    if (document.getElementById('club_name') === document.activeElement) {
      onSelectSearchedInput(clubs[active].id);
      onClubInputBlurHandler();
      return;
    }
    // if whatsapp and phone number same
    const contacts = [{
      contact_no: phone,
      is_verified: false,
      is_active: true,
      mode: "phone"
    },
    {
      contact_no: checkedWhatsapp ? phone : whatsappNumber,
      is_verified: false,
      is_active: true,
      mode: "whatsapp"
    },
    ];
    let formValidated = formValidate();
    if (formValidated) {
      setShowToaster(true);
      if (createNewUser) {
        setToasterDetails((prev) => ({
          ...prev,
          title: "Creating...",
          bodyText: "Creating new user",
          bg: "warning",
          statusIcon: (
            <div
              className="spinner-border text-warning me-2"
              role="status"
            ></div>
          ),
        }));
      } else {
        setToasterDetails((prev) => ({
          ...prev,
          title: "Updating...",
          bodyText: "Updating existing user",
          bg: "warning",
          statusIcon: (
            <div
              className="spinner-border text-warning me-2"
              role="status"
            ></div>
          ),
        }));
      }
      let display_image_url = displayImage && (await postImages(displayImage));
      let userPayload = {
        name: state.name.charAt(0).toUpperCase() + state.name.slice(1),
        email: state.email,
        contacts: contacts,
        password: state.password,
        profile_image: display_image_url ? display_image_url[0] : null,
        role: [...state.role],
        club_id: state.club_id ? state.club_id : "null", // it is neither accepting empty string nor null
        gender: gender,
        home_city: Number(city.cityId),
        home_city_name: city.cityName.toLowerCase(),
        home_country: Number(country.countryId),
        home_country_name: country.countryName.toLowerCase(),
        dob: moment(state.dob).format('DD-MM-YYYY'),
        home_state: userState.stateId,
        home_state_name: userState.stateName.toLowerCase(),
      };
      console.log(userPayload, "userPayloaduserPayloaduserPayload")
      // create and update user ************************************ !
      if (createNewUser) {
        // creating new user ********************!
        let userCreated = await createNewUser(userPayload);
        if (userCreated === true) {
          setState((prev) => ({
            ...prev,
            email: "",
            password: "",
            name: "",
            role: [],
            club_id: "",
            club_name: "",
          }));
          setPhone(null);
          setWhatsapp(null);
          setDisplayImage(null);
          setPreviewImage(null);
          setToasterDetails((prev) => ({
            ...prev,
            title: "Success...",
            bodyText: "New user created successfully",
            bg: "success",
            statusIcon: (
              <i className="bi bi-check text-success text-3xl me-2"></i>
            ),
          }));
          if (type === "concierge") {
            setTimeout(() => {
              setShowToaster(false);
              navigate(`/conceigers/${state.club_id}`, { state: clubName });
            }, 2000);

          }
          else if (type === "manager") {
            setTimeout(() => {
              setShowToaster(false);
              navigate(`/managers/${state.club_id}`, { state: clubName });
            }, 2000);

          }
          else {
            setTimeout(() => {
              setShowToaster(false);
              navigate("/users", { replace: true });
            }, 2000);
          }
        } else if (userCreated !== "") {
          setToasterDetails((prev) => ({
            ...prev,
            title: "Error...",
            bodyText: userCreated,
            bg: "danger",
            statusIcon: (
              <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
            ),
          }));
          setTimeout(() => {
            setShowToaster(false);
          }, 3000);
        } else {
          setToasterDetails((prev) => ({
            ...prev,
            title: "Error...",
            bodyText: "something went wrong!",
            bg: "danger",
            statusIcon: (
              <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
            ),
          }));
          setTimeout(() => {
            setShowToaster(false);
          }, 3000);
        }
      } else {
        // updating user's details ************************!
        // const dummyImg =
        //   "https://storage.googleapis.com/party_images/1683799221634-person-fill.svg";
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const userPayloadUpdate = {
          uid: user_id,
          email: state.email,
          contacts: contacts,
          password: state.password === "" ? null : state.password,
          name: state.name.charAt(0).toUpperCase() + state.name.slice(1),
          home_state: userState.stateId,
          home_state_name: userState.stateName.toLowerCase(),
          home_city: Number(city.cityId),
          home_city_name: city.cityName.toLowerCase(),
          home_country: Number(country.countryId),
          home_country_name: country.countryName.toLowerCase(),
          favorite_clubs: Object.values(favoriteClubState).filter(item => item !== ""),
          dob: state.dob,
          profile_image: display_image_url
            ? display_image_url[0]
            : previewImage ?? null,
          role: [...Array.from(new Set(state.role))],
          club_id:
            state.club_id && state.role &&
              (state.role.includes("manager") || state.role.includes("concierge"))
              ? state.club_id
              : null,
        };
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify(userPayloadUpdate),
          redirect: "follow",
        };
        fetch(`${process.env.REACT_APP_API_URL}/user/v2/update?uid=${user_id}`, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            if (result.success === true) {
              setState((prev) => ({
                ...prev,
                email: "",
                password: "",
                name: "",
                role: [],
                club_id: "",
                club_name: "",
                dob: ""
              }));
              setPhone(null);
              setWhatsapp(null);
              setDisplayImage(null);
              setPreviewImage(null);
              setToasterDetails((prev) => ({
                ...prev,
                title: "Success...",
                bodyText: "user's details updated successfully",
                bg: "success",
                statusIcon: (
                  <i className="bi bi-check text-success text-3xl me-2"></i>
                ),
              }));
              if (type === "concierge") {
                setTimeout(() => {
                  setShowToaster(false);
                  navigate(`/conceigers/${state.club_id}`);
                }, 2000);

              }
              else if (type === "manager") {
                setTimeout(() => {
                  setShowToaster(false);
                  navigate(`/managers/${state.club_id}`);
                }, 2000);

              }
              else {
                setTimeout(() => {
                  setShowToaster(false);
                  navigate("/users", { replace: true });
                }, 2000);
              }
            } else {
              // alert(result.message);
              setToasterDetails((prev) => ({
                ...prev,
                title: "Error...",
                bodyText: result.message,
                bg: "danger",
                statusIcon: (
                  <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
                ),
              }));
              setTimeout(() => {
                setShowToaster(false);
              }, 3000);
            }
          })
          .catch((error) => {
            setToasterDetails((prev) => ({
              ...prev,
              title: "Error...",
              bodyText: "Something went wrong",
              bg: "danger",
              statusIcon: (
                <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
              ),
            }));
            setTimeout(() => {
              setShowToaster(false);
            }, 3000);
          });
      }
    } else {
      if (state.errorMessage.name) document.getElementById("name").focus();
      else if (state.errorMessage.email)
        document.getElementById("email").focus();
      else if (state.errorMessage.phone)
        document.getElementById("phone").focus();
      else if (!isValidPhoneNumber(phone))
        document.getElementById("phone").focus();
      else if (state.errorMessage.password)
        document.getElementById("password").focus();
      else if (!checkedWhatsapp) {
        if (state.errorMessage.whatsapp) document.getElementById("whatsappNo").focus();
        if (whatsappNumber) {
          if (!isValidPhoneNumber(whatsappNumber)) document.getElementById("whatsappNo").focus();
        }
      }
      else if (state.errorMessage.dob)
        document.getElementById("dob").focus();
      // checkedWhatsapp
      else if (state.errorMessage.countryError)
        document.getElementById('home_country').focus();
      else if (state.errorMessage.stateError)
        document.getElementById('home_state').focus();
      else if (state.errorMessage.cityError)
        document.getElementById('home_city').focus();
      else if (genderError)
        document.getElementById("gender").focus();
      else if (((state.role.includes("manager") || state.role.includes("concierge"))) && state.errorMessage.club_name)
        document.getElementById("club_name").focus();
      else console.log("validation failed!");
    }
  };

  // Form validation ************************************************
  const formValidate = () => {
    let isValid = true;
    let errors = state.errorMessage;
    if (state.name.trim() === "") {
      errors.name = "Please enter user name...";
      isValid = false;
    }
    if (state.email.trim() === "") {
      errors.email = "Please enter email address...";
      isValid = false;
    }
    if (state.email.trim() !== "") {
      let mailFormat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (mailFormat.test(state.email)) {
        errors.email = "";
        isValid = true;
      } else {
        errors.email = "Please enter valid email address...";
        isValid = false;
      }
    }
    if (state.dob === "") {
      errors.dob = "Please choose a date";
      isValid = false;
    }
    if (createNewUser && state.password.trim() === "") {
      errors.password = "Please enter password...";
      isValid = false;
    }
    if (
      createNewUser &&
      state.password.trim() !== "" &&
      state.password.trim().length < 8
    ) {
      errors.password = "Password length must be of 8 characters...";
      isValid = false;
    }
    if (!checkedWhatsapp && !whatsappNumber) {
      errors.whatsapp = "Please enter a whatsapp number";
      isValid = false;
    }
    if (country.countryId === "") {
      // setCountry(prev => ({
      //   ...prev,
      //   countryError: "Please select your home country"
      // }))
      errors.countryError = "Please select your home country";
      isValid = false;
    }
    if (userState.stateId === "") {
      // setUserState(prev => ({
      //   ...prev,
      //   stateError: "Please select your home state"
      // }))
      errors.stateError = "Please select your home state";
      isValid = false;
    }
    if (city.cityId === "") {
      // setCity(prev => ({
      //   ...prev,
      //   cityError: "Please select your home city"
      // }))
      errors.cityError = "Please select your home city";
      isValid = false;
    }
    if (gender === "") {
      setGenderError("Please select your gender");
      isValid = false;
    }
    if (
      createNewUser &&
      state.password.trim() !== "" &&
      state.password.trim().length > 7
    ) {
      errors.password = "";
      isValid = true;
    }
    if (!createNewUser) {
      errors.password = "";
      isValid = true;
    }
    if (state.dob !== "") {
      errors.dob = "";
      isValid = true;
    }
    if (checkedWhatsapp && whatsappNumber !== null) {
      errors.whatsapp = "";
      isValid = true;
    }
    if (!phone) {
      errors.phone = "Please enter phone number...";
      isValid = false;
    }
    if (phone) {
      errors.phone = "";
      if (isValidPhoneNumber(phone)) isValid = true;
      else isValid = false;
    }
    if (!checkedWhatsapp) {
      if (whatsappNumber) {
        errors.whatsapp = "";
        if (isValidPhoneNumber(whatsappNumber)) isValid = true;
        else isValid = false;
      }
    }
    if (state.role.length === 0) {
      errors.role = "Please select user role...";
      isValid = false;
    }
    if (state.role.length !== 0) errors.role = "";
    if (
      state.club_name === "" &&
      (state.role.includes("manager") || state.role.includes("concierge"))
    ) {
      errors.club_name = "Please enter club name...";
      isValid = false;
    }
    if (
      state.club_id === "" &&
      state.club_name !== "" &&
      (state.role.includes("manager") || state.role.includes("concierge"))
    ) {
      errors.club_name = "Please Select club name...";
      isValid = false;
    }
    if (
      state.club_id !== "" &&
      (state.role.includes("manager") || state.role.includes("concierge"))
    )
      errors.club_name = "";
    if (
      state.errorMessage.email ||
      state.errorMessage.password ||
      state.errorMessage.name ||
      state.errorMessage.role ||
      state.errorMessage.whatsapp ||
      state.errorMessage.phone ||
      state.errorMessage.dob ||
      state.errorMessage.countryError ||
      state.errorMessage.stateError ||
      state.errorMessage.cityError
    )
      isValid = false;

    setState((prev) => ({
      ...prev,
      errorMessage: errors,
    }));
    return isValid;
  };

  const fetchClubData = useCallback(
    debounce((val) => {
      // let titlePattern = val.toUpperCase();
      let capitalize = val.charAt(0).toUpperCase() + val.slice(1);
      // let lowerCase = val.toLowerCase();
      const searchedData =
        val.length !== 0 &&
        query(
          collection(db, "clubs"), where("status", "==", "Active"),
          orderBy("name"),
          startAt(capitalize),
          endAt(`${capitalize}\uf8ff`)
        );
      val.length !== 0 &&
        getDocs(searchedData).then((docSnapshots) => {
          setClubs([]);
          docSnapshots.forEach((docs) => {
            if (docs.data().latitude && docs.data().longitude) {
              setClubs((prev) => [...prev, docs.data()]);
            }
          });
        });
    }, 1000),
    []
  );

  function debounce(fn, delay) {
    let timer;
    return function () {
      let context = this,
        args = arguments;
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(context, args);
      }, delay);
    };
  }
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "dob") {
      setState((prev) => ({
        ...prev,
        [name]: value,
        errorMessage: {
          ...state.errorMessage,
          [name]: "",
        },
      }));
    }
    else {
      setState((prev) => ({
        ...prev,
        [name]: value,
        errorMessage: {
          ...state.errorMessage,
          [name]: "",
        },
      }));
    }
  };
  const onChangeCheckbox = (e) => {
    if (e.target.checked) {
      setState((prev) => ({
        ...prev,
        role: [...state.role, e.target.value],
      }));
    } else {
      let roles = state.role;
      setState((prev) => ({
        ...prev,
        role: [...roles.filter((item) => item !== e.target.value)],
      }));
    }
  };
  const currentDate = new Date();
  const minAgeDate = moment(currentDate).subtract(21, 'years');

  const handleSelectCountry = (e) => {
    if (!e.target.value) {
      setCountry({
        ...country,
        countryId: e.target.value,
        countryName: "",
      });
      setState((prev) => ({
        ...prev,
        errorMessage: {
          ...state.errorMessage,
          countryError: "Please select your country",
        },
      }));
    }
    else {
      setCountry({
        ...country,
        countryId: e.target.value,
        countryName: e.target.options[e.target.selectedIndex].text,
        // countryError: ""
      });
      setState((prev) => ({
        ...prev,
        errorMessage: {
          ...state.errorMessage,
          countryError: "",
        },
      }));
      gettingAllStates(e.target.value);
    }
    setCity(prev => ({
      ...prev,
      cityName: "",
      cityId: "",
    }))
    setUserState(prev => ({
      ...prev,
      stateId: "",
      stateName: "",
    }))
  }

  const handleStateSelection = (e) => {
    if (e.target.value) {
      setUserState({
        ...userState,
        stateId: e.target.value,
        stateName: e.target.options[e.target.selectedIndex].text,
      })
      setState((prev) => ({
        ...prev,
        errorMessage: {
          ...state.errorMessage,
          stateError: "",
        },
      }));
      gettingAllCities(e.target.value, country.countryId);
    } else {
      setUserState({
        ...userState,
        stateId: e.target.value,
        stateName: e.target.options[e.target.selectedIndex].text,
        // stateError: "Please select your home state"
      })
      setState((prev) => ({
        ...prev,
        errorMessage: {
          ...state.errorMessage,
          stateError: "Please select your home state",
        },
      }));
    }
    setCity(prev => ({
      ...prev,
      cityName: "",
      cityId: "",
    }))
  }


  return (
    <ContentWrapper additionalClass="p-5">
      <h5 className="text-2xl font-body font-medium">
        User details
      </h5>
      <form className="mt-6" onSubmit={handleSubmit(onSubmit)} id="userform">
        <Row className="g-4">
          <Col xs={6} className="font-body mb-7">
            <PrimaryInput
              id="name"
              label="Name"
              mandatory
              placeholder="Enter user's name"
              onChange={onChangeHandler}
              value={state.name}
            />
            {state.errorMessage.name && (
              <span className="text-danger font-medium">
                {state.errorMessage.name}
              </span>
            )}
          </Col>
          <Col xs={6} className="font-body mb-7">
            <PrimaryInput
              id="email"
              label="Email"
              mandatory
              placeholder="Enter user's email address"
              onChange={onChangeHandler}
              value={state.email}
            />
            {state.errorMessage.email && (
              <span className="text-danger font-medium">
                {state.errorMessage.email}
              </span>
            )}
          </Col>
          <Col>
            <label
              htmlFor="Phone"
              className="text-lg font-medium color-gray mb-2"
            >
              Phone <span className="text-danger">*</span>
            </label>
            <PhoneInput
              name="phone"
              id="phone"
              defaultCountry="IN"
              placeholder="Enter phone number"
              value={phone}
              onChange={setPhone}
              className="mb-1"
            />
            {phone && (
              <span className="text-danger font-medium">
                {phone
                  ? isValidPhoneNumber(phone)
                    ? undefined
                    : "Invalid phone number"
                  : "Phone number required"}
              </span>
            )}
            {!phone && (
              <span className="text-danger font-medium">
                {state.errorMessage.phone}
              </span>
            )}
            <div className="m-2">
              <input
                type="checkbox"
                name="whatsapp_no"
                id="whatsapp_no"
                value={checkedWhatsapp}
                onChange={() => {
                  setCheckedWhatsapp(!checkedWhatsapp);
                  setState((prev) => ({
                    ...prev,
                    errorMessage: {
                      ...state.errorMessage,
                      whatsapp: "",
                    },
                  }));
                }}
                checked={checkedWhatsapp}
              />
              <label className="bold ms-3" htmlFor="whatsapp_no">
                This is also my Whatsapp Number
              </label>
            </div>
          </Col>

          {createNewUser && (
            <Col xs={6} className="font-body mb-7">
              <PrimaryInput
                id="password"
                label="Password"
                mandatory
                placeholder="Enter password"
                type="password"
                onChange={onChangeHandler}
                value={state.password}
              />
              {state.errorMessage.password && (
                <span className="text-danger font-medium">
                  {state.errorMessage.password}
                </span>
              )}
            </Col>
          )}
          {!checkedWhatsapp && (
            <Col xs={6}>
              <label
                htmlFor="whatsappNo"
                className="text-lg font-medium color-gray mb-2"
              >
                Whatsapp number <span className="text-danger">*</span>
              </label>
              <PhoneInput
                name="whatsappNo"
                id="whatsappNo"
                defaultCountry="IN"
                placeholder="Enter whatsapp number"
                value={whatsappNumber}
                onChange={setWhatsapp}
                className="mb-1"
              />
              {whatsappNumber && (
                <span className="text-danger font-medium">
                  {whatsappNumber
                    ? isValidPhoneNumber(whatsappNumber)
                      ? undefined
                      : "Invalid phone number"
                    : "Phone number required"}
                </span>
              )}
              {!whatsappNumber && (
                <span className="text-danger font-medium">
                  {state.errorMessage.whatsapp}
                </span>
              )}
            </Col>
          )}
          <Col xs={6} className="font-body mb-7">
            <label htmlFor="dob" className="text-lg font-medium color-gray mb-2">Date of Birth <span className="text-danger">*</span></label>
            <input
              id="dob"
              name="dob"
              type="date"
              className="po-edit-input mb-1"
              value={!createNewUser ? state.dob.split("-").reverse().join("-") : state.dob}
              max={minAgeDate.format('YYYY-MM-DD')}
              disabled={!createNewUser}
              onChange={onChangeHandler}
            />
            {state.errorMessage.dob && (
              <span className="text-danger font-medium">
                {state.errorMessage.dob}
              </span>
            )}
          </Col>
          <Col xs={6}>
            <label
              htmlFor="home_country"
              className="text-lg font-medium color-gray mb-2"
            >
              Home Country <span className="text-danger">*</span>
            </label>
            <select
              id="home_country"
              name="home_country"
              className="po-edit-input mb-1"
              value={country.countryId}
              onChange={(e) => handleSelectCountry(e)}
            >
              <option value="" key="home-country">Select Your Home Country</option>
              {countries &&
                countries.data &&
                countries.data.length > 0 &&
                countries.data.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0).map((country, index) => {
                  const { name, id } = country;
                  return (
                    <option value={id} key={index}>
                      {name}
                    </option>
                  );
                })}
            </select>
            {state.errorMessage.countryError && (
              <span className="text-danger font-medium">
                {state.errorMessage.countryError}
              </span>
            )}
          </Col>
          <Col xs={6}>
            <label
              htmlFor="home_state"
              className="text-lg font-medium color-gray mb-2"
            >
              Home State <span className="text-danger">*</span>
            </label>
            <select
              className="po-edit-input mb-1"
              id="home_state"
              name="home_state"
              value={userState.stateId}
              disabled={!country.countryId}
              onChange={handleStateSelection}
            >
              <option value="" key="home-state">Select Your Home State</option>
              {states &&
                states.data &&
                states.data.length > 0 &&
                states.data.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0).map((state, index) => {
                  const { id, name } = state;
                  return (
                    <option key={index} value={id}>
                      {name}
                    </option>
                  );
                })}
            </select>
            {state.errorMessage.stateError && (
              <span className="text-danger font-medium">
                {state.errorMessage.stateError}
              </span>
            )}
          </Col>
          <Col xs={6}>
            <label
              htmlFor="home_city"
              className="text-lg font-medium color-gray mb-2"
            >
              Home City <span className="text-danger">*</span>
            </label>
            <select
              className="po-edit-input mb-1"
              id="home_city"
              name="home_city"
              value={city.cityId}
              disabled={!userState.stateId}
              onChange={(e) => {
                setCity({
                  ...city,
                  cityId: e.target.value,
                  cityName: e.target.options[e.target.selectedIndex].text,
                  // cityError: ""
                });
                setState((prev) => ({
                  ...prev,
                  errorMessage: {
                    ...state.errorMessage,
                    cityError: "",
                  },
                }));
              }}
            >
              <option value="" key="home-city">Select Your Home City</option>
              {cities &&
                cities.data &&
                cities.data.length > 0 &&
                cities.data.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0).map((city, index) => {
                  const { id, name } = city;
                  return (
                    <option key={index} value={id}>
                      {name}
                    </option>
                  );
                })}
            </select>
            {state.errorMessage.cityError && (
              <span className="text-danger font-medium">
                {state.errorMessage.cityError}
              </span>
            )}
          </Col>
          <Col xs={6}>
            <label
              htmlFor="gender"
              className="text-lg font-medium color-gray mb-2"
            >
              Gender <span className="text-danger">*</span>
            </label>
            <div className="w-100 mb-1">
              <select
                id="gender"
                name="gender"
                className="po-edit-input"
                onChange={(e) => { setGender(e.target.value); e.target.value === "" ? setGenderError("Please select your gender") : setGenderError("") }}
                value={gender}
                disabled={!createNewUser}
              >
                <option value="">Select Gender</option>
                <option>
                  Male
                </option>
                <option value="Female">Female</option>
                <option value="Others">Others</option>
              </select>
            </div>
            {genderError && (
              <span className="text-danger font-medium">
                {genderError}
              </span>
            )}
          </Col>
          <Col xs={6} className="font-body">
            <label className="text-lg  font-medium color-gray mb-2">
              User photo
            </label>
            <div
              style={{
                backgroundImage: `url(${previewImage ? previewImage : null})`,
                border: `${previewImage ? "0px" : "1px"} dashed #D2D6DA`,
                backgroundSize: "contain",
                backgroundColor: `${previewImage && "#0a1017"}`,
              }}
              className="po-updateEvent__inputPhoto d-flex align-items-center justify-content-center background-position-center-top background-repeat-norepeat background-size-cover rounded overflow-hidden position-relative"
            >
              {previewImage ? (
                <div className="position-absolute end-0 top-0">
                  <ActionIcon
                    onClick={() => {
                      setUserImageDeleteModal(true)
                    }}
                    additionalClass="bi bi-x-circle-fill text-3xl color-red me-2"
                  />
                </div>
              ) : null}
              {previewImage ? null : (
                <div>
                  <input
                    type="file"
                    id="image"
                    accept="image/*"
                    // value={state.display_image}
                    // {...register("display_image", { required: "Image is required" })}
                    onChange={handleDisplayImage}
                    className="opacity-0 position-absolute start-0 end-0 top-0 bottom-0"
                  />
                  <div className="text-center">
                    <i className="bi bi-plus-circle-fill text-5xl color-lightgreen d-block"></i>
                    <p className="text-xs font-semibold font-body text-center color-gray2 ">
                      Upload image here
                    </p>
                  </div>
                </div>
              )}
            </div>
          </Col>
          {/* if type is not equal to undefined means it will execute this code */}
          {
            type &&
            <Col xs={6} className="font-body mb-7">
              <div className="text-lg font-medium color-gray mb-2">
                User role <span className="text-danger">*</span>
              </div>
              {userRoles &&
                userRoles.map((item, index) => {
                  // Optimized code
                  let isMatched = item === type.trim();
                  return (
                    <div hidden={!isMatched} key={index}>
                      <label
                        className="text-lg font-medium color-gray mb-2 d-flex align-items-center"
                      >
                        <input
                          type="checkbox"
                          className="me-2 po-checkbox"
                          onChange={onChangeCheckbox}
                          value={item}
                          checked={state.role.includes(item)}
                          disabled={isMatched}
                        />
                        {item.charAt(0).toUpperCase() + item.slice(1)}
                      </label>
                    </div>
                  )

                })}
              {state.role && !state.role.length && (
                <span className="text-danger font-medium">
                  {state.errorMessage.role}
                </span>
              )}
            </Col>
          }
          {/* this is for type is undefined means it will execute this code */}
          {
            !type &&
            <Col xs={6} className="font-body mb-7">
              <div className="text-lg font-medium color-gray mb-2">
                User role <span className="text-danger">*</span>
              </div>
              {userRoles &&
                userRoles.map((item, index) => {
                  // if (type === 'manager') {
                  return (
                    <div key={index} className="">
                      <label
                        className="text-lg font-medium color-gray mb-2 d-inline-flex align-items-center"
                      >
                        <input
                          type="checkbox"
                          className="me-2 po-checkbox"
                          onChange={onChangeCheckbox}
                          value={item}
                          checked={state.role && state.role.includes(item)}
                        />
                        {item.charAt(0).toUpperCase() + item.slice(1)}
                      </label>
                    </div>
                  );
                  // }
                })}
              {state.role && !state.role.length && (
                <span className="text-danger font-medium">
                  {state.errorMessage.role}
                </span>
              )}
            </Col>
          }
          {!type ?
            (state.role && (state.role.includes("manager") ||
              state.role.includes("concierge"))) && (
              <Col xs={6} className="font-body">
                <label
                  htmlFor="club_name"
                  className="text-lg font-medium color-gray mb-3"
                >
                  Club Name <span className="text-danger">*</span>
                </label>
                <div className="position-relative">
                  <input
                    id="club_name"
                    name="club_name"
                    type="search"
                    className="po-edit-input mb-1"
                    placeholder="Enter Club Name"
                    value={state.club_name}
                    onChange={searchInputHandler}
                    onBlur={onClubInputBlurHandler}
                    onKeyDown={keyDownHandler}
                  />
                  {!state.club_name && !clubInputLoader && (
                    <ActionIcon additionalClass="bi bi-chevron-down po-input__dropdownIcon position-absolute" />
                  )}
                  {clubInputLoader && <div
                    className="spinner-border po-input__dropdownIcon position-absolute bg-white"
                    style={{ width: "1.4rem", height: "1.4rem" }}
                    role="status">
                  </div>}
                </div>
                {clubs && clubs.length > 0 && showClubList && (
                  <ul className="po-updateEvent__clubLists" ref={clubListRef}>
                    {clubs.map((club, index) => (
                      <li
                        key={index}
                        tabIndex={index}
                        id={`club-${index}`}
                        className={`p-2 font-medium d-flex justify-content-between gap-4 ${active === index ? 'background-skyBlue text-dark' : ''}`}
                        onClick={(e) => onSelectSearchedInput(club && club.id)}
                      >
                        <span className='text-nowrap'>{club && club.name}</span>
                        <span className='text-xs text-end'>{club && club.address}</span>
                      </li>
                    ))}
                  </ul>)}
                {state && state.errors && state.errors.club_name &&
                  <p className="text-danger font-body text-sm">{state.errors.club_name}</p>
                }
              </Col>
            )
            : (state.role.includes("manager") ||
              state.role.includes("concierge")) && (
              <Col xs={6} className="font-body">
                <label
                  htmlFor="club_name"
                  className="text-lg font-medium color-gray mb-3"
                >
                  Club Name <span className="text-danger">*</span>
                </label>
                <div className="position-relative">
                  <input
                    id="club_name"
                    name="club_name"
                    type="search"
                    className="po-edit-input mb-1"
                    placeholder="Enter Club Name"
                    value={state.club_name}
                    onChange={searchInputHandler}
                    onBlur={onClubInputBlurHandler}
                    onKeyDown={keyDownHandler}
                  />
                  {!state.club_name && !clubInputLoader && (
                    <ActionIcon additionalClass="bi bi-chevron-down po-input__dropdownIcon position-absolute" />
                  )}
                  {clubInputLoader && <div
                    className="spinner-border po-input__dropdownIcon position-absolute bg-white"
                    style={{ width: "1.4rem", height: "1.4rem" }}
                    role="status">
                  </div>}
                </div>
                {clubs && clubs.length > 0 && showClubList && (
                  <ul className="po-updateEvent__clubLists" ref={clubListRef}>
                    {clubs.map((club, index) => (
                      <li
                        key={index}
                        tabIndex={index}
                        id={`club-${index}`}
                        className={`p-2 font-medium d-flex justify-content-between gap-4 ${active === index ? 'background-skyBlue text-dark' : ''}`}
                        onClick={(e) => onSelectSearchedInput(club && club.id)}
                      >
                        <span className='text-nowrap'>{club && club.name}</span>
                        <span className='text-xs text-end'>{club && club.address}</span>
                      </li>
                    ))}
                  </ul>)}
                {state && state.errors && state.errors.club_name &&
                  <p className="text-danger font-body text-sm">{state.errors.club_name}</p>
                }
              </Col>)}
          <Col xs={12} className="d-flex">
            {!createNewUser && (
              <Col xs={6} className="d-flex align-items-center mb-6">
                <button
                  type="button"
                  className="bg-transparent border-0 text-danger font-medium text-decoration-underline"
                  onClick={handleButtonClick}
                >
                  Update password
                </button>
              </Col>
            )}
            <Col
              xs={createNewUser ? 12 : 6}
              className="d-flex justify-content-end align-items-center mb-6"
            >
              {showToaster && (
                <img
                  src="/assets/icons/loading_icon.gif"
                  alt="processing"
                  width={100}
                />
              )}
              <OutlinedButton link="/users" name="Cancel" />
              <SuccessButton
                type="submit"
                disabled={showToaster ? true : false}
                name={createNewUser ? "Create" : "Update"}
                icon="bi-check-circle"
                additionalClass="ms-3"
              />
            </Col>
          </Col>
        </Row>
      </form>
      {/* Club Logo Image */}
      <Modal size="xs" show={userImageDeleteModal} onHide={() => setUserImageDeleteModal(false)}  >

        <div className="background-appColor p-5 rounded font-body">
          <h5 className="text-2xl  color-ternary  mb-3">
            Are you sure?
          </h5>
          <ModalBody>
            <p className="text-light font-weight-bold">Do you want to delete user image?</p>
          </ModalBody>
          <div className="d-flex justify-content-end">
            <SecondaryButton
              onClick={() => setUserImageDeleteModal(false)}
              name="No"
              additionalClass="text-light mt-3 me-4 px-5"
            />
            <SecondaryButton
              onClick={() => {
                setPreviewImage(null);
                setDisplayImage(null);
                setUserImageDeleteModal(false)
              }}
              name="Yes"
              additionalClass="text-light mt-3 me-4 px-5"
            />

          </div>
        </div>
      </Modal>
      <Toaster
        showToaster={showToaster}
        setShowToaster={setShowToaster}
        bg={toasterDetails.bg}
        title={toasterDetails.title}
        bodyText={toasterDetails.bodyText}
        statusIcon={toasterDetails.statusIcon}
      />
      {
        userDetails && (
          <UpdatePassword
            showModal={showModal}
            setShowModal={setShowModal}
            userId={user_id}
            userDetails={userDetails.data}
          />
        )
      }
    </ContentWrapper>
  );
};

export default UpdateUser;
