import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import ContentWrapper from '../ContentWrapper';
import DateRange from '../DateRange';

const AreaChart = ({ revenue, setSelectedDateRange, revenueStartDate, revenueEndDate }) => {
  const [state, setState] = useState({
    yAxis: [],
    xAxis: [],
    total: 0,
  })
  useEffect(() => {
    let yAxis = [];
    let xAxis = [];
    let total = 0;
    revenue && revenue.length > 0 && revenue.forEach(data => {
      xAxis.push(data.date);
      yAxis.push(data.amount);
      total = total + data.amount;
    })
    setState((prevProps) => ({
      ...prevProps,
      yAxis: yAxis,
      xAxis: xAxis,
      total: total
    }))
  }, [revenue]);
  const chartData = {
    series: [{
      name: 'Revenue',
      data: state.yAxis
    },
    ],

    options: {
      colors: ["#444e86"],
      chart: {
        height: 350,
        type: 'area',
        fontFamily: 'Montserrat, sans-serif',
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        type: 'datetime',
        categories: state.xAxis
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm'
        },
      },
    },


  };
  const getDateRange = (start, end) => {
    setSelectedDateRange(start, end, "revenue");
  }
  return (
    <ContentWrapper additionalClass="p-5 rounded font-body h-100">
      <div className="d-flex justify-content-between font-body">
        <div>
          <h2 className="text-md text-xl-lg font-bold color-blue font-body mb-0">Revenue</h2>
          <p className="text-xs text-xl-sm">Total Revenue: {state.total}</p>
        </div>
        <div>
          <DateRange
            getDateRange={getDateRange}
            startDate={revenueStartDate}
            endDate={revenueEndDate}
          />
        </div>
      </div>
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="area"
        height={450}
      />
    </ContentWrapper>
  )
}
export default AreaChart;