import React, { useState, useEffect } from 'react';
import Layout from "../../../Layout/LayoutManager/Layout";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import ManagerStats from '../../../Components/Dashboard/ManagerDashboard/ManagerStats';
import StackedColumn from "../../../Components/Global/Manager/Analytics/StackedColumn";
// import PolarChart from "../Components/Global/Analytics/PolarChart";
import ColumnChart from "../../../Components/Global/Manager/Analytics/ColumnChart";
import AreaChart from "../../../Components/Global/Manager/Analytics/AreaChart";
import Bookings from "../../../Components/Global/Manager/Analytics/Bookings";
import Members from "../../../Components/Global/Analytics/Members";
import moment from "moment";
import CityWiseBookings from '../../../Components/Global/Analytics/CityWiseBookings';
import GuestEntries from '../../../Components/Global/Manager/Analytics/GuestEntries';
import MembershipRevenue from '../../../Components/Global/Analytics/MembershipRevenue';
import BookingRevenue from '../../../Components/Global/Manager/Analytics/BookingRevenue';
import TodaysStat from '../../../Components/Global/Manager/Analytics/TodaysStats';
import RevenueCities from '../../../Components/Global/Analytics/RevenueCities';
import AgeDemographics from '../../../Components/Global/Manager/Analytics/AgeDemographics';
import UsersGender from '../../../Components/Global/Analytics/UsersGender';


const ManagerDashboard = () => {
  const clubId = localStorage.getItem('club_id');
  const [dataState, setDataState] = useState({
    active_booking: [],
    booking_analytycs: {},
    club_stats: {},
    membership_members: {},
    membership_stats: {},
    membership_upgrade_analytycs: {},
    menu_preference: [],
    profit_stats: {},
    revenue: [],
    top_10_city: [],
    guest: [],
    revenueFromSubscription: [],
    revenueFromBookings: [],
    ageMatrix: [],
    todayAnalytycs: {},
    userGender: {},
    monthlyBooking: []
  });
  const [state, setState] = useState({
    bookingsStartDate: moment().startOf('month').format('YYYY-MM-DD'),
    bookingsEndDate: moment().endOf('month').format('YYYY-MM-DD'),
    revenueStartDate: moment().startOf('month').format('YYYY-MM-DD'),
    revenueEndDate: moment().endOf('month').format('YYYY-MM-DD'),
    activeBookingStartDate: moment().startOf('month').format('YYYY-MM-DD'),
    activeBookingEndDate: moment().endOf('month').format('YYYY-MM-DD'),
    membershipStartDate: moment().startOf('month').format('YYYY-MM-DD'),
    membershipEndDate: moment().endOf('month').format('YYYY-MM-DD'),
    menuPreferenceStartDate: moment().startOf('month').format('YYYY-MM-DD'),
    menuPreferenceEndDate: moment().endOf('month').format('YYYY-MM-DD'),
    cityStartDate: moment().startOf('month').format('YYYY-MM-DD'),
    cityEndDate: moment().endOf('month').format('YYYY-MM-DD'),
    guestStartDate: moment().startOf('month').format('YYYY-MM-DD'),
    guestEndDate: moment().endOf('month').format('YYYY-MM-DD'),
    membershipRevenueStartDate: moment().startOf('month').format('YYYY-MM-DD'),
    membershipRevenueEndDate: moment().endOf('month').format('YYYY-MM-DD'),
    bookingRevenueStartDate: moment().startOf('month').format('YYYY-MM-DD'),
    bookingRevenueEndDate: moment().endOf('month').format('YYYY-MM-DD'),
    ageMatrixStartDate: moment().startOf('month').format('YYYY-MM-DD'),
    ageMatrixEndDate: moment().endOf('month').format('YYYY-MM-DD'),
    genderStartDate: moment().startOf('month').format('YYYY-MM-DD'),
    genderEndDate: moment().endOf('month').format('YYYY-MM-DD'),
  })


  const getDashboardData = async () => {
    console.log(clubId, "clubId in get");
    let payload = {
      club_id: clubId,
      booking: {
        fromDate: state.bookingsStartDate,
        toDate: state.bookingsEndDate
      },
      active_booking: {
        fromDate: state.activeBookingStartDate,
        toDate: state.activeBookingEndDate
      },
      // membership: {
      //   fromDate: state.membershipStartDate,
      //   toDate: state.membershipEndDate
      // },
      menu_preference: {
        fromDate: state.menuPreferenceStartDate,
        toDate: state.menuPreferenceEndDate
      },
      revenue: {
        fromDate: state.revenueStartDate,
        toDate: state.revenueEndDate
      },
      // city: {
      //   fromDate: state.cityStartDate,
      //   toDate: state.cityEndDate
      // },
      guest: {
        fromDate: state.cityStartDate,
        toDate: state.cityEndDate
      },
      // membership_revenue: {
      //   fromDate: state.membershipRevenueStartDate,
      //   toDate: state.membershipRevenueEndDate
      // },
      booking_revenue: {
        fromDate: state.bookingRevenueStartDate,
        toDate: state.bookingRevenueEndDate
      },
      booking_revenue: {
        fromDate: state.ageMatrixStartDate,
        toDate: state.ageMatrixEndDate
      },
      // user_gender: {
      //   fromDate: state.genderStartDate,
      //   toDate: state.genderEndDate
      // }
    };
    axios.post(`${process.env.REACT_APP_API_URL}/dashboard/v1/managerDetails`,
      payload,
      {
        headers: {
          developement: true,
          token: localStorage.getItem("authenticate"),
          uid: localStorage.getItem("uid"),
        },
      }
    ).then(res => {
      if (res.data.success == true) {
        setDataState((prevProps) => ({
          ...prevProps,
          active_booking: res.data && res.data.data && res.data.data.active_booking && res.data.data.active_booking,
          booking_analytycs: res.data && res.data.data && res.data.data.booking_analytycs && res.data.data.booking_analytycs,
          revenueFromBookings: res.data && res.data.data && res.data.data.revanueFromBooking && res.data.data.revanueFromBooking,
          ageMatrix: res.data && res.data.data && res.data.data.age_matrix && res.data.data.age_matrix,
          todayAnalytycs: res.data && res.data.data && res.data.data.today_analytycs && res.data.data.today_analytycs,
          menu_preference: res.data && res.data.data && res.data.data.menu_preference && res.data.data.menu_preference,
          revenue: res.data && res.data.data && res.data.data.revenue && res.data.data.revenue,
          guest: res.data && res.data.data && res.data.data.guest && res.data.data.guest,

          // club_stats: res.data && res.data.data && res.data.data.active_booking && res.data.data.club_stats,
          // membership_members: res.data && res.data.data && res.data.data.membership_members && res.data.data.membership_members,
          // membership_stats: res.data && res.data.data && res.data.data.active_booking && res.data.data.membership_stats,
          // membership_upgrade_analytycs: res.data && res.data.data && res.data.data.membership_upgrade_analytycs && res.data.data.membership_upgrade_analytycs,
          // profit_stats: res.data && res.data.data && res.data.data.profit_stats && res.data.data.profit_stats,
          // top_10_city: res.data && res.data.data && res.data.data.top_10_city && res.data.data.top_10_city,
          // revenueFromSubscription: res.data && res.data.data && res.data.data.revenueFromSubscription && res.data.data.revenueFromSubscription,
          // userGender: res.data && res.data.data && res.data.data.user_gender && res.data.data.user_gender,
          // monthlyBooking: res.data && res.data.data && res.data.data.monthly_booking && res.data.data.monthly_booking,
        }));
      }
    }).catch(err => {
    })
  }
  useEffect(() => {
    getDashboardData();
  }, [state]);


  const setSelectedDateRange = (start, end, type) => {
    if (type == "booking") {
      setState((prevProps) => ({
        ...prevProps,
        bookingsStartDate: start,
        bookingsEndDate: end
      }));
    } else if (type == "revenue") {
      setState((prevProps) => ({
        ...prevProps,
        revenueStartDate: start,
        revenueEndDate: end
      }))
    } else if (type == "active_booking") {
      setState((prevProps) => ({
        ...prevProps,
        activeBookingStartDate: start,
        activeBookingEndDate: end
      }))
    } else if (type == "membership") {
      setState((prevProps) => ({
        ...prevProps,
        membershipStartDate: start,
        membershipEndDate: end
      }))
    } else if (type == "menu_preference") {
      setState((prevProps) => ({
        ...prevProps,
        menuPreferenceStartDate: start,
        menuPreferenceEndDate: end
      }))
    } else if (type == "city") {
      setState((prevProps) => ({
        ...prevProps,
        cityStartDate: start,
        cityEndDate: end
      }))
    } else if (type == "guest") {
      setState((prevProps) => ({
        ...prevProps,
        guestStartDate: start,
        guestEndDate: end
      }))
    } else if (type == "membership_revenue") {
      setState((prevProps) => ({
        ...prevProps,
        membershipRevenueStartDate: start,
        membershipRevenueEndDate: end
      }))
    } else if (type == "booking_revenue") {
      setState((prevProps) => ({
        ...prevProps,
        bookingRevenueStartDate: start,
        bookingRevenueEndDate: end
      }))
    } else if (type == "age_matrix") {
      setState((prevProps) => ({
        ...prevProps,
        ageMatrixStartDate: start,
        ageMatrixEndDate: end
      }))
    } else if (type == "gender") {
      setState((prevProps) => ({
        ...prevProps,
        ageMatrixStartDate: start,
        ageMatrixEndDate: end
      }))
    }
  }

  return (
    <Layout>
      <Row className="g-4">
        <ManagerStats
          club_stats={dataState.club_stats}
          membership_stats={dataState.membership_stats}
          profit_stats={dataState.profit_stats}
        />
        {/* Bookings section */}
        <Col xs={12}>
          <Bookings
            setSelectedDateRange={setSelectedDateRange}
            booking_analytycs={dataState.booking_analytycs}
            monthlyBooking={dataState.monthlyBooking}
            bookingsStartDate={state.bookingsStartDate}
            bookingsEndDate={state.bookingsEndDate}
          />
        </Col>

        {/* Revenue section */}
        <Col lg={7}>
          <AreaChart
            revenue={dataState.revenue}
            setSelectedDateRange={setSelectedDateRange}
            revenueStartDate={state.revenueStartDate}
            revenueEndDate={state.revenueEndDate}
          />
        </Col>
        {/* Active Bookings section */}
        <Col lg={5}>
          <StackedColumn
            active_booking={dataState.active_booking}
            setSelectedDateRange={setSelectedDateRange}
            activeBookingStartDate={state.activeBookingStartDate}
            activeBookingEndDate={state.activeBookingEndDate}
          />
        </Col>
        {/* Members section */}
        {/* <Col xs={12}>
          <Members
            membership_members={dataState.membership_members}
            membership_upgrade_analytycs={dataState.membership_upgrade_analytycs}
            setSelectedDateRange={setSelectedDateRange}
            membershipStartDate={state.membershipStartDate}
            membershipEndDate={state.membershipEndDate}
          />
        </Col> */}

        {/* Menu Preference section */}
        <Col lg={12}>
          <ColumnChart
            menu_preference={dataState.menu_preference}
            setSelectedDateRange={setSelectedDateRange}
            menuPreferenceStartDate={state.menuPreferenceStartDate}
            menuPreferenceEndDate={state.menuPreferenceEndDate}
          />
        </Col>
        {/* People Preference section */}
        {/* <Col lg={12}>
          <CityWiseBookings
            setSelectedDateRange={setSelectedDateRange}
            cityStartDate={state.cityStartDate}
            cityEndDate={state.cityEndDate}
            top_10_city={dataState.top_10_city}
          />
        </Col> */}
        <Col lg={12}>
          <GuestEntries
            guest={dataState.guest}
            setSelectedDateRange={setSelectedDateRange}
            guestStartDate={state.guestStartDate}
            guestEndDate={state.guestEndDate}
          />
        </Col>
        {/* <Col lg={12}>
          <MembershipRevenue
            revenueFromSubscription={dataState.revenueFromSubscription}
            setSelectedDateRange={setSelectedDateRange}
            membershipRevenueStartDate={state.membershipRevenueStartDate}
            membershipRevenueEndDate={state.membershipRevenueEndDate}
          />
        </Col> */}
        <Col lg={12}>

          <BookingRevenue
            revenueFromBookings={dataState.revenueFromBookings}
            setSelectedDateRange={setSelectedDateRange}
            bookingRevenueStartDate={state.bookingRevenueStartDate}
            bookingRevenueEndDate={state.bookingRevenueEndDate}
          />
        </Col>
        <Col lg={12}>
          <TodaysStat
            todayAnalytycs={dataState.todayAnalytycs}
          />
        </Col>
        {/* <Col lg={12}>
          <RevenueCities
            top_10_city={dataState.top_10_city}
          />
        </Col> */}
        <Col lg={12}>
          {/* <AgeDemographics
            ageMatrix={dataState.ageMatrix}
            setSelectedDateRange={setSelectedDateRange}
            ageMatrixStartDate={state.ageMatrixStartDate}
            ageMatrixEndDate={state.ageMatrixEndDate}
          /> */}
        </Col>
        {/* <Col lg={12}>
          <UsersGender
            userGender={dataState.userGender}
            setSelectedDateRange={setSelectedDateRange}
            genderStartDate={state.genderStartDate}
            genderEndDate={state.genderEndDate}
          />
        </Col> */}
      </Row>
    </Layout>
  );
};
export default React.memo(ManagerDashboard);