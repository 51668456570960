import React from "react";
import Breadcrumb from "./Breadcrumb";
import { useState } from "react";
import { motion } from "framer-motion";
import Logout from "../../Components/Global/Popup/ConfirmModal";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const contents = {
  title: "Confirm Logout",
  permission: "Are you sure you want to log out?",
}

const SwitchLanguage = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState("");
 
  const handleLanguageChange = (event) => {
    i18n.changeLanguage(event.target.value);
    localStorage.setItem("language", event.target.value)
  };
  useEffect(()=> {
    const selectedLanguage = localStorage.getItem("language");
    if(selectedLanguage) {
      setLanguage(selectedLanguage);
      i18n.changeLanguage(selectedLanguage);
    }
  }, [])
  
 return(
  <select onChange={handleLanguageChange} value={language} className=" text-sm border border-secondary bg-transparent px-2 py-1 rounded">
  <option value="en" className="mb-3">English</option>
  <option value="es" className="mb-3">Spanish</option>
  <option value="zh" className="">Chinese</option>
</select>
  )
}

function Dropdown() {
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleButtonClick = () => setShowModal(true);
  return (
    <>
      <Logout showModal={showModal} setShowModal={setShowModal} contents={contents} logout="true" />

      <div>
        <div
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
          className="cursor-pointer pl-3 pr-3"
        >
          <i className="bi bi-person-fill"></i> Club Manager
        </div>
        {isOpen && (
          <motion.div
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
            className="position-absolute shadow-lg p-3  bg-white rounded d-block"
            variants={{
              open: { opacity: 1, y: 0 },
              closed: { opacity: 0, y: -10 },
            }}
            transition={{ duration: 0.3 }}
            initial="closed"
            animate={isOpen ? "open" : "closed"}
          >
            <ul className="mb-0">
              <li
                className="text-md cursor-pointer text-md text-nowrap"
                onClick={handleButtonClick}
              >
                Log Out
              </li>
            </ul>
          </motion.div>
        )}
      </div>
    </>
  );
}
const Header = ({path}) => {
  return (
    <>
      <div className="d-flex justify-content-lg-between align-items-lg-center pt-2 pb-5 flex-column flex-lg-row">
        <div className="mb-2 mb-lg-0"> 
          <Breadcrumb path={path} />
        </div>
        <div className="d-flex justify-content-between align-items-center">
          {/* <div className="po-header__searchField d-flex align-items-center justify-content-center gap-2 bg-white px-3 py-2">
            <i className="bi bi-search"></i>
            <input
              type="text"
              id="search"
              name="search"
              className="border-0 po-header__searchInput"
            />
          </div> */}
          <SwitchLanguage/>
          <div className="position-relative d-block">
            <Dropdown />
          </div>
         
          <Link to="/manager/profile"> <i className="bi bi-gear-fill text-dark"></i></Link>
        </div>
      </div>
    </>
  );
};

export default Header;
