import React from "react";
import Breadcrumb from "./Breadcrumb";
import { useState } from "react";
import { motion } from "framer-motion";
import Logout from "../../Components/Global/Popup/ConfirmModal";

const contents = {
  title: "Confirm Logout",
  permission: "Are you sure you want to log out?", 
}

function Dropdown() {
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleButtonClick = () => setShowModal(true);
  return (
    <>
      <Logout showModal={showModal} setShowModal={setShowModal} contents={contents} />

      <div>
        <div
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
          className="cursor-pointer"
        >
          <i className="bi bi-person-fill"></i> <span className="font-body text-xs text-xl-md">Admin</span>
        </div>
        {isOpen && (
          <motion.div
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
            className="position-absolute shadow-lg p-3  bg-white rounded d-block"
            variants={{
              open: { opacity: 1, y: 0 },
              closed: { opacity: 0, y: -10 },
            }}
            transition={{ duration: 0.3 }}
            initial="closed"
            animate={isOpen ? "open" : "closed"}
          >
            <ul className="mb-0">
              <li
                className="text-md cursor-pointer text-md text-nowrap"
                onClick={handleButtonClick}
              >
                Log Out
              </li>
            </ul>
          </motion.div>
        )}
      </div>
    </>
  );
}
const Header = () => {
  return (
    <>
      <div className="d-flex justify-content-lg-between align-items-lg-center pt-2 pb-5 flex-column flex-lg-row">
        <div className="mb-2 mb-lg-0">
          <Breadcrumb />
        </div>
        <div className="d-flex justify-content-between align-items-center gap-4">
          {/* <div className="po-header__searchField d-flex align-items-center justify-content-center gap-2 bg-white px-3 py-2">
            <i className="bi bi-search"></i>
            <input
              type="text"
              id="search"
              name="search"
              className="border-0 po-header__searchInput"
            />
          </div> */}
          <div className="position-relative d-block">
            <Dropdown />
          </div>
          {/* <i className="bi bi-gear-fill"></i> */}
        </div>
      </div>
    </>
  );
};

export default Header;
