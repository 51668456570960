import React, { useState, useEffect } from 'react';
import { collection, getDocs, where, query, getCountFromServer } from '@firebase/firestore';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import db from '../../../firebase';
import EventsTable from '../../../Components/Global/Table/ManagerEventsTable';
import PageDescription from '../../../Components/Global/PageDescription';
import PrimaryButton from '../../../Components/Global/Buttons/PrimaryButton';
import Layout from "../../../Layout/LayoutManager/Layout";
import { useRef } from 'react';

const EventManagement = () => {
  const tableHeading = {
    heading1: "EVENT NAME",
    heading2: "CLUB NAME",
    heading3: "EVENT ON",
    heading4: "STATUS",
    heading5: "ACTION",
    heading6: "UPDATED ON",
    heading7: "No. of Bookings"
  }
  const filter = {
    option1: "All Events",
    option2: "Upcoming Events",
    option3: "Old Events"
  }
  const [eventEntryCount, setEventEntryCount] = useState({});
  const [loader, setLoader] = useState(true);
  const [pageCount, setPageCount] = useState(null);
  const [totalEvents, setTotalEvents] = useState(null);
  
  const eventsPerPage = useRef(20);
  const count = useRef(0);

  // const [state, setState] = useState({
  //   allEvents: []
  // })
  // const clubId = localStorage.getItem('club_id')
  

  // useEffect(() => {
  //   eventList();
  // }, []);
  // function sortFunction(a, b) {
  //   var dateA = new Date(a.updateAt.seconds * 1000).getTime();
  //   var dateB = new Date(b.updateAt.seconds * 1000).getTime();
  //   return dateA > dateB ? -1 : 1;
  // };
  // const eventsData = state.allEvents.length && state.allEvents.sort(sortFunction);
  return (
    <Layout path="/manager/events-management">
      {/* {console.log(state.allEvents, "ALLA events in KKKKKK")} */}
      <div className="d-flex justify-content-between align-items-center gap-3 gap-lg-5 ">
        <PageDescription
          title="All events"
          caption=""
        // caption="From concerts to festivals and everything in between - explore all events available of the club with just one click." 
        />
        <PrimaryButton link="/manager/add-event" name=" Add Event" icon="bi-plus-circle" />
      </div>
      <EventsTable
        tableHeading={tableHeading}
        // tableData={eventsData}
        filter={filter}
        eventEntryCount={eventEntryCount}
        // eventListRecall={eventList}
        loader={loader}
        // pageReloadFunc={pageReloadFunc}
        count={count}
        // previousPageDataFunc={previousPageDataFunc}
        // nextPageDataFunc={nextPageDataFunc}
        eventsPerPage={eventsPerPage}
        pageCount={pageCount}
        // handleFilterEvents={handleFilterEvents}
        totalEvents={totalEvents}
        // fetchEventsCount={fetchEventsCount}
        // fetchSearchedEvents={fetchSearchedEvents}
        setLoader={setLoader}
        // orderByKey={sortBy}

      />
    </Layout>
  );
}

export default EventManagement;