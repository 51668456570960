import React from 'react';
import { doc, setDoc } from "firebase/firestore";
import UpdateEvent from "../../../Components/Events/UpdateManagerEvent";

import Layout from "../../../Layout/LayoutManager/Layout";
import db from "../../../firebase";
const CreateManagerEvent = () => {

  const newClubData = async (clubData) => {
    console.log(clubData.id, 'clubData', clubData);
    try {
      // console.log(clubData, 'event created');
      // Add a new document in collection "cities"
      await setDoc(doc(db, "events", clubData.id), clubData);
      return true;
    }
    catch (err) {
      console.log(err);
      return false
    }
  }

  return (
    <Layout path="/manager/add-event">
      <UpdateEvent newClubData={newClubData} newClub={true} />
    </Layout>
  );
};
export default CreateManagerEvent;
