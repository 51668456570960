import React from 'react'
import Layout from '../../Layout/Layout'
import ContentWrapper from '../../Components/Global/ContentWrapper'
import { useEffect, useState, } from 'react'
import { collection, deleteDoc, doc, getDocs } from 'firebase/firestore';
import db from '../../firebase';
import { Dropdown, Modal, Table } from 'react-bootstrap';
import PageDescription from '../../Components/Global/PageDescription';
import PrimaryButton from '../../Components/Global/Buttons/PrimaryButton';
import { useNavigate } from "react-router-dom";
import SecondaryButton from '../../Components/Global/Buttons/SecondaryButton';


export default function ExploreFurther() {
  const [exploreFurtherData, setExploreFurtherData] = useState([]);
  const [show, setShow] = useState(false);
  const navigate = useNavigate()
  const fetchExploreFurther = async () => {
    await getDocs(collection(db, "explore_further")).then((exploreFurtherData) => setExploreFurtherData(exploreFurtherData.docs)).catch(err => console.error(err));
  }
  const handleClose = () => setShow(false);
  const handleShow = () => {
    // console.log(id);
    setShow();
  };

  // deleting entries
  const hadnleDelete = async (data) => {
    console.log(data.id, "id");

    try {
      await deleteDoc(doc(db, "popups", data.id)).then((res) => {
        console.info("Success");
      });
      fetchExploreFurther()
      handleClose();
    } catch (err) {
      console.log(err);
    }
  };

  const ImagePreview = () => {
    return (
      <div
        style={{
          height: "75px",
          width: "150px",
          backgroundColor: "#DEDEDE",
        }}
        className="bg-gray rounded overflow-hidden d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-card-image text-6xl"></i>
      </div>
    );
  };

  useEffect(() => {
    fetchExploreFurther()
  }, [])

  return (
    <Layout>
      <ContentWrapper additionalClass="pb-5">
        <div className="d-flex justify-content-between align-items-center gap-3 gap-lg-5 font-body px-5">
          <PageDescription title="All Explore Further" caption="" />
          <PrimaryButton
            link="/explore-create"
            name="Create"
            icon="bi-plus-circle"
          />
        </div>
        <Table hover>
          <thead>
            <tr className="background-gray10">
              <th>
                <p className="text-nowrap text-xs text-xl-sm text-uppercase ps-3 color-black2 mb-0 py-2">
                  s.no
                </p>
              </th>
              <th>
                <p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">
                  Title
                </p>
              </th>
              <th>
                <p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">
                  Image
                </p>
              </th>
              <th>
                <p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">
                  Redirection Link
                </p>
              </th>

              <th></th>
            </tr>
          </thead>
          <tbody>
            {exploreFurtherData &&
              exploreFurtherData.map((record, index) => {
                return (
                  <tr key={index}>
                    <td className="align-middle">
                      <p className="text-sm mb-0 text-nowrap color-lightblue ps-3">
                        {index + 1}.
                      </p>
                    </td>
                    <td className="align-middle">
                      <p className="text-sm mb-0 text-nowrap color-lightblue">
                        {record.data().title && record.data().title}
                      </p>
                    </td>
                    <td className="align-middle">
                      {record.data().image_url ? (
                        <div
                          style={{
                            height: "70px",
                            width: "70px",
                            backgroundImage: `url(${record.data().image_url && record.data().image_url})`,
                          }}
                          className="background-position-center-top background-repeat-norepeat background-size-cover rounded overflow-hidden "
                        ></div>
                      ) : (
                        <ImagePreview />
                      )}
                    </td>

                    <td className="align-middle">
                      <p className="text-sm mb-0 text-nowrap color-lightblue">
                        {record.data().redirecting_url && record.data().redirecting_url}
                      </p>
                    </td>
                    {/* <td className="align-middle">
                      <p className="text-sm mb-0 text-center color-lightblue">
                        {data.clubId && data.clubId}
                        {record.eventId && data.eventId}
                      </p>
                    </td> */}

                    <td className="align-middle">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="link"
                          id="dropdown-basic"
                          className="text-black"
                        >
                          <i className="bi bi-three-dots-vertical text-4xl d-bloxk"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="border-0 shadow px-2">
                          <Dropdown.Item
                            className="border-bottom font-bold text-sm py-2"
                            onClick={() => {
                              navigate(`/popups/edit-popup/${2}`);
                            }}
                          >
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="font-bold text-sm py-2"
                            onClick={() => handleShow(index)}
                          >
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </ContentWrapper>
      <Modal show={show} onHide={handleClose}>
        <div className="background-appColor p-8 rounded font-body">
          <h5 className="text-2xl  color-ternary font-semibold ">
            Are you sure?
          </h5>
          <p className="background-appColor text-light border-bottom-0 py-1 ">
            You want to delete this popup
          </p>
          <div className="d-flex justify-content-end mt-12">
            <SecondaryButton
              onClick={handleClose}
              name="Cancel"
              additionalClass="text-white me-3"
            />
            <PrimaryButton
              onClick={() => hadnleDelete(show.data)}
              name="Delete"
            />
          </div>
        </div>
      </Modal>
    </Layout>
  );
}

