import React, { useCallback, useEffect, useRef, useState } from "react";
// import PaginatedItems from '../Pagination/PaginatedItems';
// import { useCycle } from "framer-motion";
import { Table } from "react-bootstrap";
import moment from "moment";
import { Link, useSearchParams } from "react-router-dom";
// import ActionIcon from "../Buttons/ActionIcons";
import { collection, getDocs, limit, query, startAfter, getCountFromServer, deleteDoc, orderBy, getDoc, doc, endBefore, limitToLast, startAt, endAt, or, where } from "@firebase/firestore";
import db from "../../../firebase";
import ConfirmModal from "../Popup/ConfirmModal";
import Skeleton from "react-loading-skeleton";
import Dropdown from "react-bootstrap/Dropdown";
import ContentWrapper from "../ContentWrapper";

const EventsTable = ({
  tableHeading,
  // tableData,
  // filter,
  // eventListRecall,
  // eventEntryCount,
  // loader,


  // tableData,
  // pageReloadFunc,
  // loader,
  count,
  // nextPageDataFunc,
  // previousPageDataFunc,
  // eventsPerPage,
  // pageCount,
  // totalEvents,
  // fetchEventsCount,
  // fetchSearchedEvents,
  // handleFilterEvents,
  // setLoader,
  // orderByKey

}) => {

  const [searchParams, setSearchParams] = useSearchParams();

  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [eventsData, setEventsData] = useState([]);
  const [eventsCount, setEventsCount] = useState(null);
  const [pageCount, setPageCount] = useState(null);
  const [loader, setLoader] = useState(true);
  
  const clubId = localStorage.getItem('club_id')

  const eventsPerPage = useRef(20);
  const sortBy = useRef({
    orderByKey: "updateAt",
    order: "desc"
  })
  const nextPage = useRef(1);
  const currentPageId = useRef("");
  const changeOrder = useRef(0); // changeOrder will be used for tracking the sorting (0 for normal, 1 for incresing and 2 for decreasing)

  const handleDeleteButton = async (id) => {
    console.log(id, "id");
    try {
      if (id !== undefined) {
        const deleteEventResponse = await deleteDoc(doc(db, "events", id));
        console.log("SuccessFully Deleted", deleteEventResponse);
        // window.location.reload()
        fetchEventList();
      }
    } catch (err) {
      console.log("Not Deleted");
      console.log(err);
    }
  };
  const onDeleteHandler = (id) => {
    setShowModal(true);
    setDeleteId(id);
  };
  function checkDateIsbefore(date) {
    let past = false;
    const d = moment(date.toDate()).format("YYYY-MM-DD");
    const date1 = moment(d, "YYYY-MM-DD")
    if (date1.isBefore()) {
      past = true;
    } else {
      past = false;
    }
    return past;
  }

  const fetchEventsCount = async () => {
    const coll = query(collection(db, "events"), where("club.id", "==", clubId));
    const eventsCount = await getCountFromServer(coll);
    // console.log(eventsCount.data().count, 'eventsCount');
    setEventsCount(eventsCount.data().count);
    setPageCount(Math.ceil(eventsCount.data().count/eventsPerPage.current));
  }
  const fetchEventList = async () => {
    // event lists fetching ***********************************************
    setLoader(true);
    fetchEventsCount();
    const clubId = localStorage.getItem('club_id');
    getDocs(query(collection(db, "events"), where("club.id", "==", clubId), orderBy(sortBy.current.orderByKey, sortBy.current.order), limit(+eventsPerPage.current)))
      .then((allEvents) => {
        let allEventsArray = [];
        allEvents.forEach(async (event) => {
          allEventsArray.push(event.data());
        });
        setEventsData(allEventsArray);
        // setState({
        //   allEvents: allEventsArray,
        // });
        setLoader(false);
      })
      .catch(error => {
        console.log(error);
        if (error.message === "Missing or insufficient permissions.") {
          window.location.replace('/');
          localStorage.removeItem('authenticate');
        }
        setLoader(false);
      })
    // try {
    //   let obj = {};
    //   let events_entry_data = await getDocs(collection(db, "event_entry_bookings"))
    //   events_entry_data.forEach(entryData => {
    //     if (obj[entryData.data().event_id]) {
    //       obj[entryData.data().event_id] += 1;
    //     }
    //     else {
    //       obj[entryData.data().event_id] = 1;
    //     }
    //   })
    //   setEventEntryCount(obj);
    //   setLoader(false);
    // }
    // catch (err) {
    //   console.log(err);
    //   setLoader(false);
    // }
  }


const pageReloadFunc = useCallback(async() => {
      console.log('pageReloadFunc function is called');
      eventsPerPage.current = +searchParams.get('itemsPerPage');
      setLoader(true);
      console.log('nextPage', nextPage);
      if(+nextPage.current===1) {
        const docSnap = count.current && await getDoc(doc(collection(db, "events"), currentPageId.current));
        getDocs(query(collection(db, "events"), where("club.id", "==", clubId), orderBy(sortBy.current.orderByKey, sortBy.current.order), startAfter(docSnap), limit(eventsPerPage.current)))
          .then((docs) => {
            setEventsData([]);
            docs.forEach((doc) => {
              setEventsData((prev) => [...prev, doc.data()]);
            });
            setLoader(false);
          })
          .catch((err) => {
            console.log(err, "err");
            setLoader(false);
          });
      } else {
        const docSnap = count.current && await getDoc(doc(collection(db, "events"), currentPageId.current));
        getDocs(query(collection(db, "events"), where("club.id", "==", clubId), orderBy(sortBy.current.orderByKey, sortBy.current.order), endBefore(docSnap), limitToLast(+eventsPerPage.current)))
        .then((docs) => {
          setEventsData([]);
          docs.forEach((doc) => {
            setEventsData((prev) => [...prev, doc.data()]);
          });
          setLoader(false);
        })
        .catch((err) => {
          console.log(err, "err");
          setLoader(false);
        });
      }
    }, [nextPage])

 

  const handleItemsPerPage = (e) => {
    setLoader(true);
    eventsPerPage.current = +e.target.value;
    if(count.current) {
      count.current = 0;
    }
    fetchEventList();
    
    setSearchParams({eventPage: 1, itemsPerPage: +e.target.value});
  }


  // on click next button
  const nextPageDataFunc = async () => {
    setLoader(true);
    count.current = count.current+1;
    const docSnap = count.current && await getDoc(doc(collection(db, "events"), eventsData[eventsData.length-1].id));
    getDocs(query(collection(db, "events"), where("club.id", "==", clubId), orderBy(sortBy.current.orderByKey, sortBy.current.order), startAfter(docSnap), limit(eventsPerPage.current)))
      .then((docs) => {
        setEventsData([]);
        docs.forEach((doc) => {
          setEventsData((prev) => [...prev, doc.data()]);
        });
        setSearchParams({eventPageNo: count.current+1, prevNext: JSON.stringify([docs.docs[0].id, docs.docs[docs.docs.length-1].id]), currentPageId: docSnap.id, itemsPerPage: eventsPerPage.current , n: 1})
        /* setSearchParams => clubPageno will help to track the page no, prevNext is storing id of first and last
          item which will help to go prev and next page.
          currentPageId will help me to be on the page same page when page reload
        */
        setLoader(false);
      })
      .catch((err) => {
        console.log(err, "err");
        setLoader(false);
      }); 
  }
  // on Prev button clicked

  const previousPageDataFunc = async () => {
    console.log('previousPageDataFunc function is called');
    setLoader(true);
    count.current = count.current - 1;
    if (!count.current) {
      fetchEventList();
      setSearchParams({});
      return;
    }
    const docSnap = count.current && await getDoc(doc(collection(db, "events"), eventsData[0].id));
    getDocs(query(collection(db, "events"), where("club.id", "==", clubId), orderBy(sortBy.current.orderByKey, sortBy.current.order), endBefore(docSnap), limitToLast(+eventsPerPage.current)))
      .then((docs) => {
        setEventsData([]);
        docs.forEach((doc) => {
          setEventsData((prev) => [...prev, doc.data()]);
        });
        setSearchParams({pageNo: count.current+1, prevNext: JSON.stringify([docs.docs[0].id, docs.docs[docs.docs.length-1].id]), currentPageId: docSnap.id, itemsPerPage: eventsPerPage.current , n: 0 })
        // if count value will be greater than 0 then will set data in the url otherwise nothing needs to set in the url
        setLoader(false);
      })
      .catch((err) => {
        console.log(err, "err");
        setLoader(false);
      });

 
  }

  // useEffect goes here-----------------------------------------------------
  useEffect(()=> {
    if(Boolean(searchParams.get('itemsPerPage'))) eventsPerPage.current = +searchParams.get('itemsPerPage');
    if(!searchParams.size) count.current = 0;
    if(Boolean(searchParams.get('eventPageNo'))) {
      currentPageId.current = searchParams.get('currentPageId');
      count.current = searchParams.get('eventPageNo') - 1;
      nextPage.current = +searchParams.get('n');
      fetchEventsCount();
      pageReloadFunc();
    }
    if(!count.current) fetchEventList();
  }, [])

  return (
    <ContentWrapper additionalClass="pb-5 font-body">
      <div className="table-responsive po-table tableFixHead">
        <Table hover>
          <thead>
            <tr className="background-gray10">
              <th className="text-nowrap">
                <p className="text-nowrap text-xs text-xl-sm text-uppercase ps-3 color-black2 mb-0 py-2">
                  S. NO
                </p>
              </th>
              <th className="text-nowrap">
                <p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">
                  {tableHeading.heading1}
                </p>
              </th>
              <th className="text-nowrap">
                <p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">
                  {tableHeading.heading2}
                </p>
              </th>
              <th className="text-nowrap">
                <p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">
                  {tableHeading.heading7}
                </p>
              </th>
              <th className="text-nowrap">
                <p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">
                  {tableHeading.heading3}
                </p>
              </th>
              <th className="text-nowrap">
                <p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">
                  {tableHeading.heading6}
                </p>
              </th>
              <th className="text-nowrap text-center">
                <p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">
                  {tableHeading.heading4}
                </p>
              </th>
              <th className="text-nowrap">
                {/* {tableHeading.heading5.toUpperCase()} */}
              </th>
            </tr>
          </thead>
          <tbody>
            {!loader ? (
              eventsData.length &&
              eventsData.map((item, index) => {
                return (
                  <tr
                    // className={`po-table__body-event px-8 py-4 d-grid border-1 border-bottom gap-4 gap-xl-5`}
                    key={index}
                  >
                    <td className="align-middle font-body ps-4">{count.current*eventsPerPage.current + index + 1}.</td>
                    <td className="align-middle">
                      <div className="d-flex align-items-center gap-3">
                        <div className="po-table__eventWrapper d-flex justify-content-center align-items-center border rounded flex-shrink-0">
                          {item.display_image ? (
                            <img
                              src={item.display_image}
                              alt={item.title}
                              className="po-table__eventImg rounded"
                            />
                          ) : (
                            <i className="bi bi-image text-5xl"></i>
                          )}
                        </div>
                        <div className="d-flex flex-column po-event__name text-nowrap">
                          <span className="text-sm mb-0 text-nowrap color-lightblue">
                            {item.name}
                          </span>
                          {/* <span className='text-xxs po-word-break'>{item.email}</span> */}
                          <span className="text-xs text-lg-sm mt-1">
                            <i className="bi bi-geo-alt-fill text-danger me-1 "></i>
                            {(item.club && item.club.city_name) ?? "Location not found"}
                          </span>
                        </div>
                      </div>
                      {/*  */}
                    </td>
                    <td className="align-middle text-sm mb-0 text-nowrap color-lightblue">
                      {(item.club && item.club.name) ?? "Club is not listed"}
                    </td>
                    <td className="align-middle text-sm mb-0 text-nowrap text-center color-lightblue">
                      {/* {eventEntryCount && eventEntryCount.hasOwnProperty(item.id)
                        ? eventEntryCount[item.id]
                        : 0} */}
                      <Link
                        className="text-black font-bold text-sm py-2 d-block ps-1"
                        to={`/events/event-bookings/${item.id}`}
                      >
                        {item.no_of_bookings}
                      </Link>
                    </td>
                    <td className="align-middle text-sm mb-0 text-nowrap color-lightblue">
                      {moment(item.date_and_time.toDate()).format("DD MMM YYYY")}
                    </td>
                    <td className=" align-middle text-sm mb-0 text-nowrap color-lightblue">
                      {moment(item.updateAt.toDate()).format("DD MMM YYYY")}
                    </td>
                    <td className=" text-sm text-left align-middle text-sm text-lg-md">
                      {(item && item.status === "active") ? <div className="d-flex gap-3 gap-xl-4 align-items-center justify-content-start">
                        {new Date() <
                          new Date(
                            moment(item.date_and_time.toDate()).format("DD MMM YYYY")
                          ) ? (
                          <div className="d-flex align-items-center justify-content-start">
                            <div
                              style={{
                                height: "10px",
                                width: "10px",
                                backgroundColor: "#13DEB9",
                              }}
                              className="rounded-circle"
                            ></div>
                            <div className="ms-2 color-lightblue text-sm">
                              Upcoming
                            </div>
                          </div>
                        ) : moment(item.date_and_time.toDate()).format(
                          "DD MMM YYYY"
                        ) === moment().format("DD MMM YYYY") ? (
                          // <Badge pill bg={"primary"} className='po-table__status'>
                          //   Today
                          // </Badge>
                          <div className="d-flex align-items-center justify-content-start">
                            <div
                              style={{
                                height: "10px",
                                width: "10px",
                                backgroundColor: "#89CFF0",
                              }}
                              className="rounded-circle"
                            ></div>
                            <div className="ms-2 color-lightblue text-sm">
                              Today
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex align-items-center justify-content-start">
                            <div
                              style={{
                                height: "10px",
                                width: "10px",
                                backgroundColor: "#B5B6B4",
                              }}
                              className="rounded-circle"
                            ></div>
                            <div className="ms-2 color-lightblue text-sm">
                              Past
                            </div>
                          </div>
                        )}
                      </div> :
                        <div className="ms-2 color-lightblue text-capitalize text-sm">
                          {item && item.status}
                        </div>
                      }
                    </td>
                    <td className="align-middle">
                      <div className="d-flex gap-3 gap-xl-4 align-items-center text-center">

                        <Dropdown>
                          <Dropdown.Toggle
                            variant="link"
                            id="dropdown-basic"
                            className="text-black"
                          >
                            <i className="bi bi-three-dots-vertical text-4xl d-bloxk"></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="border-0 shadow px-2">
                            {/* {console.log(moment(item.date_and_time.toDate()).format("YYYY-MM-DD"), "This is the event date kokookok", moment().format("YYYY-MM-DD"))} */}
                            {/* {moment(item.date_and_time.toDate()).format("YYYY-MM-DD").isBefore(moment().format("YYYY-MM-DD"))} */}
                            {!checkDateIsbefore(item.date_and_time) &&
                              <Link
                                className="text-black border-bottom font-bold text-sm py-2 d-block ps-1 "
                                to={`/manager/edit-event/${item.id}`}
                              >
                                Edit
                              </Link>
                            }
                            <div
                              className="text-black border-bottom font-bold text-sm py-2 d-block ps-1 cursor-pointer"
                              onClick={() => onDeleteHandler(item.id)}
                            >
                              Delete
                            </div>

                            <Link
                              className="text-black font-bold text-sm py-2 d-block ps-1"
                              to={`/manager/event-bookings/${item.id}`}
                            >
                              Bookings
                            </Link>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={8}>
                  <Skeleton inline={true} count={5} height={80} style={{ marginBottom: "1rem" }} baseColor='#dedede' highlightColor='#cccccc' duration={2} />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      {<div className="d-flex gap-2 justify-content-end align-items-center py-5 mt-6 me-4">
          <div>
            Rows per page: 
            <div className="d-inline-block ms-1 ms-xl-3">
              <select 
                name="item-per-page" 
                id="item-per-page" 
                value={eventsPerPage.current} 
                className="border-0 shadow-none font-medium" 
                onChange={handleItemsPerPage}
              >
                <option value={10}>10</option>
                <option value={15}>15</option>
                <option value={20}>20</option>
                <option value={25}>25</option>
                <option value={30}>30</option>
              </select>
            </div>
          </div>
          <div className="d-flex align-items-center gap-3">
            {!loader && eventsCount ? <div className="mx-6 mx-xl-12 font-medium">
              {count.current*eventsPerPage.current+1}-{(count.current+1)*eventsPerPage.current < eventsCount ? (count.current+1)*eventsPerPage.current: eventsCount} of {eventsCount}
            </div>: <div className="mx-6 mx-xl-12 font-medium">loading...</div>}
            <button className={` text-md font-body font-semibold py-3 px-6 radius-105 d-inline-block bg-transparent ${(!count.current || loader) ? "po-border border-secondary" : "color-black1 po-border po-border-yellow1"}`} type="button" 
              onClick={previousPageDataFunc}
              disabled={!count.current || loader}
            >Prev</button>
            <button className={` text-md font-body font-semibold py-3 px-6 radius-105 d-inline-block bg-transparent ${loader || (pageCount - 1 === count.current) ? "po-border border-secondary" : "color-black1 po-border po-border-yellow1"}`} type="button" 
              onClick={nextPageDataFunc} 
              disabled={(pageCount - 1 === count.current) || loader}>Next</button>
          </div>
        </div>}
      <ConfirmModal
        showModal={showModal}
        setShowModal={setShowModal}
        // contents={contents}
        Deleteid={deleteId}
        handleDeleteButton={handleDeleteButton}
      />
    </ContentWrapper>
  );
};

export default EventsTable;
