import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Row, Col } from 'react-bootstrap';
// import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber } from "react-phone-number-input"
import PhoneInput from "react-phone-number-input/react-hook-form";
import 'react-phone-number-input/style.css';
import { Country, State, City } from "country-state-city";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { Controller } from "react-hook-form";


function MyVerticallyCenteredModal(props) {
  const { register, handleSubmit, formState: { errors }, control, reset } = useForm();
  const [phone, setPhone] = useState();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  useEffect(() => {
  }, [selectedCountry]);

  async function onSubmit(data) {
    const clubRegistrationDetails = data;
    const cityId = await uuidv4();
    const stateId = await uuidv4();
    const countryId = await uuidv4();

    const payLoad = {
      name: clubRegistrationDetails && clubRegistrationDetails.enterClubName,
      company_name: clubRegistrationDetails && clubRegistrationDetails.enterCompanyName,
      city_id: cityId,
      state_id: stateId,
      country_id: countryId,
      city_name: selectedCity && selectedCity.name,
      state_name: selectedState && selectedState.name,
      country_name: selectedCountry && selectedCountry.name,
      website_url: clubRegistrationDetails && clubRegistrationDetails.enterWebsiteName,
      address: clubRegistrationDetails && clubRegistrationDetails.enterFullAddress,
      manager_contacts: [
        {
          email: clubRegistrationDetails && clubRegistrationDetails.enterEmailId,
          phone: clubRegistrationDetails && clubRegistrationDetails.phoneInput,
          name: clubRegistrationDetails && clubRegistrationDetails.clubManagerName
        }
      ],
    }
    try {
      // await axios.post(`${process.env.REACT_APP_MULTIPLEIMAGES_POSTING_URL}/club/v2/club`,payLoad,
      await axios.post("https://party-one-7bbee.uc.r.appspot.com/club/v2/club", payLoad,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }

      )
    }
    catch (error) {
    }
  }
  useEffect(() => {
    if (!props.show) {
      reset();
    }
  }, [props.show])

  return (
    <Modal
      {...props}
      onHide={() => {
        props.onHide();
        setSelectedCountry(null)
        setSelectedState(null)
        setSelectedCity(null)
        //setPhone(null)
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="border-0 p-5 pt-8 pl-8" closeButton>
        <Modal.Title className="font-body" id="contained-modal-title-vcenter">
          Club details
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body className="pt-0 pr-8 pl-8 pb-0">
          <Row>
            <Col xs={12} md={6}>
              <h4 className="text-lg font-medium color-gray mb-2 font-body">Enter Company name</h4>
              <input type="text" className="po-edit-input font-body" placeholder="Enter Company name"  {...register("enterCompanyName", { required: 'This field is required' })} />
              <p className="mb-7 text-danger font-body text-sm">{errors.enterCompanyName?.message}</p>
            </Col>
            <Col xs={12} md={6}>
              <h4 className="text-lg font-medium color-gray mb-2 font-body">Club name</h4>
              <input type="text" className="po-edit-input font-body" placeholder="Enter name" {...register("enterClubName", { required: 'This field is required' })} />
              <p className="mb-7 text-danger font-body text-sm">{errors.enterClubName?.message}</p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <h4 className="text-lg font-medium color-gray mb-2 font-body">Club manager name</h4>
              <input type="text" className="po-edit-input font-body" placeholder="Enter manager name"  {...register("clubManagerName", { required: 'This field is required' })} />
              <p className="mb-7 text-danger font-body text-sm">{errors.clubManagerName?.message}</p>
            </Col>
            <Col xs={12} md={6}>
              <h4 className="text-lg font-medium color-gray mb-2 font-body">Email</h4>
              <input type="email" className="po-edit-input font-body" placeholder="Enter mail id" {...register("enterEmailId", { required: 'This field is required' })} />
              <p className="mb-7 text-danger font-body text-sm">{errors.enterEmailId?.message}</p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <h4 className="text-lg font-medium color-gray mb-2 font-body">Phone</h4>
              <PhoneInput defaultCountry="IN" className="font-body" placeholder="Enter phone number"
                name="phoneInput"
                control={control}
                international
                id="phone"
                value={phone}
                onChange={setPhone}
                rules={{ required: 'This field is required' }}
              />
              {errors.phoneInput && <p className="text-danger font-body text-sm">{errors.phoneInput?.message}</p>}
              {phone && <span className='mb-7 text-danger font-body text-sm'>{phone ? (isValidPhoneNumber(phone) ? undefined : 'Invalid phone number') : 'Phone number required'}</span>}
              {/* <PhoneInput
                name="phone"
                id="phone"
                defaultCountry="IN"
                placeholder="Enter phone number"
                value={phone}
                onChange={setPhone}
                className='mb-1 font-body'
              />
              {phone && <span className='mb-7 text-danger font-body text-sm'>{phone ? (isValidPhoneNumber(phone) ? undefined : 'Invalid phone number') : 'Phone number required'}</span>} */}
            </Col>
            <Col xs={12} md={6}>
              <h4 className="text-lg font-medium color-gray mb-2 font-body">Website Name</h4>
              <input type="text" className="po-edit-input font-body" placeholder="Enter website name" {...register("enterWebsiteName", { required: 'This field is required' })} />
              <p className="mb-7 text-danger font-body text-sm">{errors.enterWebsiteName?.message}</p>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <h5 className="text-2xl font-body font-medium mb-5 mt-5">Club location</h5>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <h4 className="text-lg font-medium color-gray mb-2 font-body">Country</h4>
              <div className="mb-7">
                {/* <Controller
                  name="country"
                  control={control}
                  rules={{ required: 'This Field is required' }}
                  render={({ field }) => ( */}
                <Select
                  //{...field}
                  //options={countryOptions}
                  placeholder="Select country"
                  options={Country.getAllCountries()}
                  getOptionLabel={(options) => {
                    return options["name"];
                  }}
                  name="country"
                  getOptionValue={(options) => {
                    return options["name"];
                  }}
                  value={selectedCountry}
                  onChange={(item) => {
                    setSelectedCountry(item);
                  }}
                />
                {/* )}
                /> */}
                <p className="mb-7 text-danger font-body text-sm">{errors.country?.message}</p>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <h4 className="text-lg font-medium color-gray mb-2 font-body">State</h4>
              <div className="mb-7">
                <Select
                  options={State?.getStatesOfCountry(selectedCountry?.isoCode)}
                  getOptionLabel={(options) => {
                    return options["name"];
                  }}
                  getOptionValue={(options) => {
                    return options["name"];
                  }}
                  name="state"
                  value={selectedState}
                  onChange={(item) => {
                    setSelectedState(item);
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <h4 className="text-lg font-medium color-gray mb-2 font-body">City</h4>
              <div className="mb-7">
                <Select
                  options={City.getCitiesOfState(
                    selectedState?.countryCode,
                    selectedState?.isoCode
                  )}
                  getOptionLabel={(options) => {
                    return options["name"];
                  }}
                  getOptionValue={(options) => {
                    return options["name"];
                  }}
                  name="city"
                  value={selectedCity}
                  onChange={(item) => {
                    setSelectedCity(item);
                  }}
                />
              </div>
            </Col>
            <Col xs={12} md={6}>
              <h4 className="text-lg font-medium color-gray mb-2 font-body">Enter Full Address</h4>
              <textarea className="po-edit-input font-body" placeholder="Enter Full Address" {...register("enterFullAddress", { required: 'This field is required' })}></textarea>
              <p className="mb-7 text-danger font-body text-sm mb-7">{errors.enterFullAddress?.message}</p>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="mb-5 border-0">
          <button className="po-login__button font-body p-2 px-5 font-medium mr-5" type="submit">Submit</button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

function LoginModal({ buttonName }) {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <Button variant="primary" className="po-login__button font-body p-2 px-5 font-medium mr-5" onClick={() => setModalShow(true)}>
        {buttonName}
      </Button>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default LoginModal