import ContentWrapper from "../../Components/Global/ContentWrapper";
import Layout from "../../Layout/Layout";
import { useForm } from "react-hook-form";
import { Row, Col, Button, Modal, ModalBody } from "react-bootstrap";
import {
  collection,
  doc,
  getDocs,
  setDoc,
  where,
  query,
} from "@firebase/firestore";
import { v4 as uuid } from "uuid";
import { useEffect } from "react";
import db from "../../firebase";
import { useState } from "react";
import ActionIcon from "../../Components/Global/Buttons/ActionIcons";
import axios from "axios";
import { useNavigate } from "react-router";
import Success from "../../Components/Global/States/Success";
import Loading from "../../Components/Global/States/Loading";
import Error from "../../Components/Global/States/Error";
import SuccessButton from "../../Components/Global/Buttons/SuccessButton";
import OutlinedButton from "../../Components/Global/Buttons/OutlinedButton";
import Toaster from "../../Components/Global/Toast";
import { color } from "framer-motion";
import { Link } from "react-router-dom";
import SecondaryButton from "../../Components/Global/Buttons/SecondaryButton";

const CreateCountry = () => {
  const [showToaster, setShowToaster] = useState(false);
  const [show, setShow] = useState(false);
  const [toasterDetails, setToasterDetails] = useState({
    title: "",
    bodyText: "",
    bg: "",
    statusIcon: "",
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [countryImageDeleteModal, setCountryImageDeleteModal] = useState(null)
  const [countries, setCountries] = useState([]);
  // const [states, setStates] = useState([]);
  const [image, setImage] = useState({});
  const [country, setCountry] = useState("");
  const [previewImage, setPreviewImage] = useState(null);
  const [imageError, setImageError] = useState("")

  //Duplicates Validation

  const checkingForDuplicates = (value) => {
    let flag = false;
    countries.forEach((ele) => {
      console.log(ele, "ele");
      let oldCountryName = ele.name.trim();
      let newCountryName = value.trim();
      if (oldCountryName === newCountryName) {
        console.log("It is coming");
        flag = true;
      }
    });
    return flag;
  };

  //Modal Handlers
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // fetching entries
  const fetchCountries = async () => {
    const q = query(collection(db, "countries"));
    const querySnapshot = await getDocs(q);
    const newData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setCountries(newData);
  };
  useEffect(() => {
    fetchCountries();
  }, []);
  const onSubmit = async (data) => {
    // setIsLoading(true);
    const duplicateValidation = checkingForDuplicates(data.countryName);
    if (duplicateValidation) {
      setShow(true);
      return;
    }
    setShowToaster(true);
    setToasterDetails((prev) => ({
      ...prev,
      title: "Creating...",
      bodyText: "Creating country",
      bg: "warning",
      statusIcon: (
        <div className="spinner-border text-warning me-2" role="status"></div>
      ),
    }));
    const formData = new FormData();
    formData.append("images", image);
    const images = async () => {
      const imageResponse = await axios.post(
        `${process.env.REACT_APP_MULTIPLEIMAGES_POSTING_URL}/uploads/uploadImages`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            method: "POST",
          },
        }
      );
      console.log(imageResponse.data.url[0], "imageResponse");
      return imageResponse && imageResponse.data && imageResponse.data.url[0];
    };
    const id = uuid();
    const payloadImage = await images();
    const payload = {
      id: id && id,
      name: data && data,
      country_image: payloadImage ? payloadImage : null,
      name: data && data.countryName,
      latitude: data && Number(data.latitude),
      longitude: data && Number(data.longitude),
      country_code: data && data.countryCode,
      createdAt: new Date(),
      updatedAt: new Date(),
    };
    console.log(payload, "payload");
    try {
      const createCityResponse = await setDoc(
        doc(db, "countries", id),
        payload
      );
      // setIsLoading(false);
      // setIsSuccess(true);
      // navigate("/country");
      setToasterDetails((prev) => ({
        ...prev,
        title: "Success",
        bodyText: "Created Country successfully",
        bg: "success",
        statusIcon: <i className="bi bi-check text-success text-3xl me-2"></i>,
      }));
      setTimeout(() => {
        navigate("/country");
      }, 2000);
    } catch (err) {
      // setIsLoading(false);
      // setIsError(true);
      setToasterDetails((prev) => ({
        ...prev,
        title: "Error",
        bodyText: "Something went wrong",
        bg: "danger",
        statusIcon: (
          <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
        ),
      }));
      console.error("Error adding document: ", err);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = () => {
          setPreviewImage(reader.result);
        };
        reader.readAsDataURL(file);
        console.log(file, "its coming");
        setImage(file);
        setImageError("")
      }
      else {
        setImageError("Please ensure your selection is an image file.");
        return;
      }

    }
  };

  console.log(country, "country");
  return (
    <Layout path="/country/create-country">
      {/* <Link to="/country">
            <div  className="d-flex align-items-center mb-5">
               <div>
                <img
                  src="/assets/icons/sidebar/dark/location-icon-dark.svg"
                  alt="dashboard-icon"
                  width="15"
                  height="15"
                  className="d-block"
                />
              </div>

            <div className="ms-2 text-sm text-lg-md font-body font-semibold text-black">
            Country
              </div>
               <div className="ms-2 text-xs text-lg-sm font-body font-regular text-black">
               / create
              </div>
            </div>
            </Link> */}
      <ContentWrapper additionalClass="p-5 font-body">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row className="g-3">
            {/* Country Image */}
            <Col xs={12}>
              <label htmlFor="cityImage">Country image</label>
              <div
                style={{
                  height: "200px",
                  width: "350px",
                  backgroundImage: `url(${previewImage ? previewImage : null})`,
                  border: `${previewImage ? "0px" : "1px"} dashed #D2D6DA`,
                }}
                className="d-flex align-items-center justify-content-center background-position-center-top background-repeat-norepeat background-size-cover rounded overflow-hidden position-relative"
              >
                {previewImage ? (
                  <div className="position-absolute end-0 top-0">
                    <ActionIcon
                      onClick={() => {
                        setCountryImageDeleteModal(true)
                      }}
                      additionalClass="bi bi-x-circle-fill text-3xl color-red me-2"
                    />
                  </div>
                ) : null}

                {previewImage ? null : (
                  <div>
                    <input
                      accept="image/*"
                      type="file"
                      id="display_image"
                      onChange={handleImageChange}
                      className="opacity-0 position-absolute start-0 end-0 top-0 bottom-0"
                    />

                    <div className="text-center">
                      <i className="bi bi-plus-circle-fill text-5xl color-lightgreen d-block"></i>
                      <p className="text-md font-body text-center  ">
                        Upload your Image here.
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </Col>
            {imageError && <p className="text-danger">{imageError}</p>

            }
            {/* Country Name */}
            <Col xs={12}>
              <label htmlFor="cityName">
                Country name
                <span style={{ color: "red", fontSize: "20px" }}>*</span>
              </label>
              <input
                className="po-edit-input"
                type="text"
                id="cityName"
                {...register("countryName", {
                  required: true,
                  pattern: {
                    value: /^(?!\s*$).+/,
                    message: "Only alphabetic characters are allowed.",
                  },
                })}
              />
              <p className="text-sm text-danger">
                {errors.countryName &&
                  errors.countryName.type === "required" && (
                    <span>This field is required</span>
                  )}

                {errors.countryName &&
                  errors.countryName.type === "pattern" && (
                    <span>Only alphabetic characters are allowed.</span>
                  )}
              </p>
            </Col>
            {/* Latitude */}
            <Col xs={6}>
              <label htmlFor="latitude">
                Latitude
                <span style={{ color: "red", fontSize: "20px" }}>*</span>
              </label>
              <input
                type="text"
                id="latitude"
                className="po-edit-input"
                {...register("latitude", {
                  required: true,
                  pattern: /^\d+(\.\d+)?$/,
                })}
              />

              <p className="text-sm text-danger">
                {errors.latitude && errors.latitude.type === "required" && (
                  <span>This field is required</span>
                )}
                {errors.latitude && errors.latitude.type === "pattern" && (
                  <span>Invalid format (must be a number)</span>
                )}
              </p>
            </Col>

            {/* Longitude */}
            <Col xs={6}>
              <label htmlFor="longitude">
                Longitude
                <span style={{ color: "red", fontSize: "20px" }}>*</span>
              </label>
              <input
                type="text"
                id="longitude"
                className="po-edit-input"
                {...register("longitude", {
                  required: true,
                  pattern: /^\d+(\.\d+)?$/,
                })}
              />

              <p className="text-sm text-danger">
                {errors.longitude && errors.longitude.type === "required" && (
                  <span>This field is required</span>
                )}
                {errors.longitude && errors.longitude.type === "pattern" && (
                  <span>Invalid format (must be a number)</span>
                )}
              </p>
            </Col>

            {/* Country code */}
            <Col xs={12}>
              <label htmlFor="cityCode">
                Country code
                <span style={{ color: "red", fontSize: "20px" }}>*</span>
              </label>
              <input
                className="po-edit-input"
                type="text"
                id="cityName"
                {...register("countryCode", {
                  required: true,
                  pattern: {
                    value: /^(?!\s*$).+/,
                    message: "Only alphabetic characters are allowed.",
                  },
                })}
              />

              <p className="text-sm text-danger">
                {errors.countryCode &&
                  errors.countryCode.type === "required" && (
                    <span>This field is required</span>
                  )}

                {errors.countryCode &&
                  errors.countryCode.type === "pattern" && (
                    <span>Only alphabetic characters are allowed.</span>
                  )}
              </p>
            </Col>

            {/* Country time
            <Col xs={6}>
              <label htmlFor="cityCode">
                Country time zone
                <span style={{ color: "red", fontSize: "20px" }}>
                  <span style={{ color: "red", fontSize: "20px" }}>*</span>
                </span>
              </label>
              <input
                className="po-edit-input"
                type="text"
                id="cityName"
                {...register("countryZone", {
                  required: true,
                  pattern: {
                    value: /^(?!\s*$).+/,
                    message: "Only alphabetic characters are allowed.",
                  },
                })}
              />

              <p className="text-sm text-danger">
                {errors.countryZone &&
                  errors.countryZone.type === "required" && (
                    <span>This field is required</span>
                  )}

                {errors.countryZone &&
                  errors.countryZone.type === "pattern" && (
                    <span>Only alphabetic characters are allowed.</span>
                  )}
              </p>
            </Col> */}
          </Row>

          <div className="d-flex justify-content-end mt-4">
            <OutlinedButton link="/country" name="Cancel" />
            <SuccessButton
              type="submit"
              name="Create Country"
              icon="bi-check-circle"
              additionalClass={`ms-4 ${showToaster ? "opacity-06 cursor-default" : null
                }  `}
              disabled={showToaster ? true : false}
            />
          </div>
        </form>
      </ContentWrapper>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <div className="background-appColor p-8 rounded font-body">
          <h2 className="text-2xl  color-ternary font-semibold mb-4">Error</h2>
          <p className="text-md text-light text-wrap mb-1">
            {" "}
            Country is already Exists
          </p>
          <div className="text-end">
            <SecondaryButton
              onClick={handleClose}
              name="Close"
              additionalClass="text-light"
            />
          </div>
        </div>
      </Modal>
      {/* Country Image Modal */}
      <Modal size="xs" show={countryImageDeleteModal} onHide={() => setCountryImageDeleteModal(false)}  >

        <div className="background-appColor p-5 rounded font-body">
          <h5 className="text-2xl  color-ternary  mb-3">
            Are you sure?
          </h5>
          <ModalBody>
            <p className="text-light font-weight-bold">Do you want to delete country image?</p>
          </ModalBody>
          <div className="d-flex justify-content-end">
            <SecondaryButton
              onClick={() => setCountryImageDeleteModal(false)}
              name="No"
              additionalClass="text-light mt-3 me-4 px-5"
            />
            <SecondaryButton
              onClick={() => {

                // setSelectedImage(null)
                setPreviewImage(null);
                setImage(null);
                setCountryImageDeleteModal(false)
              }}
              name="Yes"
              additionalClass="text-light mt-3 me-4 px-5"
            />

          </div>
        </div>
      </Modal >

      <Toaster
        showToaster={showToaster}
        setShowToaster={setShowToaster}
        bg={toasterDetails.bg}
        title={toasterDetails.title}
        bodyText={toasterDetails.bodyText}
        statusIcon={toasterDetails.statusIcon}
      />
    </Layout>
  );
};
export default CreateCountry;
