import { Row, Col, Modal } from "react-bootstrap";
import ContentWrapper from "../../Components/Global/ContentWrapper";
// import firebase from 'firebase/app';
import Layout from "../../Layout/Layout";
import { useEffect, useState } from "react";
import SecondaryButton from "../../Components/Global/Buttons/SecondaryButton";
import PrimaryButton from "../../Components/Global/Buttons/PrimaryButton";
import { Link } from "react-router-dom";
import { doc, getDoc } from "@firebase/firestore";
import db, { auth } from "../../firebase";
import moment from "moment";
import axios from "axios";
import Toaster from "../../Components/Global/Toast";
import {
  reauthenticateWithCredential,
  updatePassword,
  EmailAuthProvider,
} from "@firebase/auth";
const Account = () => {
  const [show, setShow] = useState(false);
  const [userDetails, setUserDetails] = useState({
    userData: {},
    rolesData: {},
  });
  const [showToaster, setShowToaster] = useState(false);
  const [toasterDetails, setToasterDetails] = useState({
    title: "",
    bodyText: "",
    bg: "",
    statusIcon: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [userPasswords, setUserPasswords] = useState({
    newPassword: "",
    confirmPassword: "",
    oldPassword: "",
  });
  const [errors, setErrors] = useState({
    confirmPasswordValidationError: false,
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const baseUrl = process.env.REACT_APP_API_URL;

  // Destructing user Data
  console.log(userDetails, 'userDetails');
  const { name, email, contacts, dob, gender, createdAt, profile_image } =
    userDetails && userDetails.userData;
  const { customClaims } = userDetails.rolesData;

  // Function to get user data
  const getUserData = async () => {
    let user_id = localStorage.getItem("user_id");
    try {
      const userResponse = await getDoc(doc(db, "users", user_id));
      //user roles not available in user collection so that we are fetching from api
      let loginAndRolesResponse = await axios.get(
        `${baseUrl}/user/v1/user?uid=${user_id}`
      );
      setUserDetails({
        ...userDetails,
        rolesData: loginAndRolesResponse.data.data,
        userData: userResponse.data(),
      });
    } catch (err) {
      console.log(err);
    }
  };

  // Password validation
  const passwordsValidation = () => {
    console.log(userPasswords, "userPasswords");
    if (
      userPasswords.oldPassword === "" &&
      userPasswords.newPassword === "" &&
      userPasswords.confirmPassword === ""
    ) {
      setErrors({
        ...errors,
        confirmPassword: "This is required field",
        newPassword: "This is required field",
        currentPassword: "This is required field",
      });
      return false;
    } else if (
      userPasswords.newPassword === "" ||
      userPasswords.confirmPassword === "" ||
      userPasswords.oldPassword === ""
    ) {
      let currentErrors = errors;
      if (userPasswords.newPassword === "") {
        currentErrors.newPassword = "This is required field";
      }
      if (userPasswords.oldPassword === "") {
        currentErrors.oldPassword = "This is required field";
      }
      if (userPasswords.confirmPassword === "") {
        currentErrors.confirmPassword = "This is required field";
      }
      setErrors({ ...currentErrors });
      return false;
    }
    return true;
  };
  const changingPassword = async () => {
    if (!passwordsValidation()) {
      return;
    }
    setShow(false);
    setShowToaster(true);
    setToasterDetails((prev) => ({
      ...prev,
      title: "Updating...",
      bodyText: "Updating password...",
      bg: "warning",
      statusIcon: (
        <div className="spinner-border text-warning me-2" role="status"></div>
      ),
    }));
    let user = auth.currentUser;
    try {
      const credential = EmailAuthProvider.credential(
        userDetails.userData.email,
        userPasswords.oldPassword
      );

      // Reauthenticate the user with the provided credential
      await reauthenticateWithCredential(user, credential);

      // Update the user's password
      await updatePassword(user, userPasswords.newPassword);

      // Password update was successful
      setShowToaster(true);
      setToasterDetails((prev) => ({
        ...prev,
        title: "Success",
        bodyText: "Profile password updated successfully",
        bg: "success",
        statusIcon: <i className="bi bi-check text-success text-3xl me-2"></i>,
      }));
      setShow(false);
      setUserPasswords({
        newPassword: "",
        confirmPassword: "",
        oldPassword: "",
      });

      // Hide the toaster after 2 seconds
      setTimeout(() => {
        setShowToaster(false);
      }, 2000);
    } catch (err) {
      // Show an error message in the toaster
      setShowToaster(true);
      setToasterDetails((prev) => ({
        ...prev,
        title: "Error",
        bodyText: err.message === "Firebase: Error (auth/wrong-password)." ? "Incorrect old password" : err.message,
        bg: "danger",
        statusIcon: (
          <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
        ),
      }));
      setUserPasswords({
        newPassword: "",
        confirmPassword: "",
        oldPassword: "",
      });
      setTimeout(() => {
        setShowToaster(false);
      }, 2000);

    }


  }
  const handleCache = async () => {
    const token = localStorage.getItem("authenticate");
    const headers = {
      headers: {
        developement: true,
        token: token,
      },
    };
    await axios.post(`${process.env.REACT_APP_API_URL}/dashboard/v1/clearCache`, {}, headers).then((res) => {
      setIsLoading(true);
      setShowToaster(true);
      setToasterDetails((prev) => ({
        ...prev,
        title: "Success",
        bodyText: "Cache cleared successfully",
        bg: "success",
        statusIcon: (
          <i className="bi bi-check text-success text-3xl me-2"></i>
        ),
      }));
      setTimeout(() => {
        setShowToaster(false);
      }, 2000)
    }).catch((err) => {
      console.log(err, "err");
      setToasterDetails((prev) => ({
        ...prev,
        title: "Error",
        bodyText: "Something went wrong",
        bg: "danger",
        statusIcon: (
          <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
        ),
      }));
    })
  }
  // Getting user data with user_id already stored in local storage
  useEffect(() => {
    getUserData();
  }, []);

  // Layout
  return (
    <Layout path="/profile">
      <ContentWrapper additionalClass="p-5">
        <Row className="g-4">
          <Col
            xs={12}
            className="d-flex justify-content-between align-items-center px-2"
          >
            <h2 className="text-xl color-heading font-semibold"> Profile</h2>
            <Link to={`/edit-profile/${userDetails.userData.id}`}>
              <button className="bg-none border-0 bg-transparent color-yellow1 text-md font-semibold">
                Edit Profile
              </button>
            </Link>
          </Col>
          <Col xs={12}>
            <figure className="d-flex align-items-center">
              {profile_image === null ? (
                <div>
                  <i className="bi bi-person-circle text-8xl"></i>
                </div>
              ) : (
                <div
                  style={{
                    height: "150px",
                    width: "150px",
                    backgroundImage: `url(${profile_image})`,
                  }}
                  className="background-position-center-top background-repeat-norepeat background-size-cover rounded-circle overflow-hidden "
                ></div>
              )}
              <figcaption className="ms-3 text-lg font-semibold color-blue text-capitalize">
                {name}
              </figcaption>
            </figure>
          </Col>
          <Col lg={6} xl={3}>
            <article>
              <h4 className="text-sm color-gray mb-1">Email</h4>
              <p className="text-lg font-semibold color-blue text-wrap">
                {email}
              </p>
            </article>
          </Col>
          <Col lg={6} xl={3}>
            <article>
              <h4 className="text-sm color-gray mb-1">Phone</h4>
              <p className="text-lg font-semibold color-blue">
                {" "}
                {contacts && contacts[0].contact_no}
              </p>
            </article>
          </Col>
          <Col lg={6} xl={3}>
            <article>
              <h4 className="text-sm color-gray mb-1">Date of birth</h4>
              <p className="text-lg font-semibold color-blue">{dob && dob}</p>
            </article>
          </Col>
          <Col lg={6} xl={3}>
            <article>
              <h4 className="text-sm color-gray mb-1">Gender</h4>
              <p className="text-lg font-semibold color-blue">
                {gender && gender}
              </p>
            </article>
          </Col>

          <Col lg={6} xl={3}>
            <article>
              <h4 className="text-sm color-gray mb-1">Active since</h4>
              <p className="text-lg font-semibold color-blue">
                {createdAt &&
                  moment(
                    new Date(createdAt.seconds * 1000).toISOString()
                  ).format("DD MMM YYYY")}
              </p>
            </article>
          </Col>

          <Col lg={6} xl={6}>
            <article>
              <h4 className="text-sm color-gray mb-1">Roles</h4>
              {customClaims &&
                customClaims.role &&
                customClaims.role.map((item, index) => {
                  return (
                    <span
                      key={index}
                      className={`mx-1 d-inline-block text-sm mb-0 text-nowrap text-white text-capitalize font-semibold rounded px-3
                                    ${item.toLowerCase() === "customer"
                          ? "po-customer"
                          : item.toLowerCase() === "admin"
                            ? "po-admin"
                            : item.toLowerCase() === "manager"
                              ? "po-manager"
                              : item.toLowerCase() === "concierge"
                                ? "po-concierge"
                                : "po-influencer"
                        }
                                  `}
                    >
                      {item}
                    </span>
                  );
                })}
            </article>
          </Col>
        </Row>
        <hr className="py-3" />
        <Row className="g-4">
          <Col xl={4}>
            <button
              onClick={handleShow}
              className=" border-0 bg-light text-md font-semibold color-blue text-wrap px-4 py-6 rounded"
            >
              Change password
              <span className="color-yellow1 text-md font-semibold ms-4 d-inline-block">
                Continue
              </span>
            </button>
          </Col>
          <Col xs={12}>
            <button className="bg-none border-0 bg-transparent color-yellow1 text-md font-semibold" onClick={handleCache}>
              Clear cache
            </button>
          </Col>
        </Row>
        <Modal show={show} onHide={handleClose} centered>
          <div className="background-appColor p-8 rounded font-body">
            <h2 className="text-2xl  color-ternary font-semibold mb-4">
              Change Password
            </h2>
            <form>
              <article className="mb-3">
                <label className="text-md text-light text-wrap mb-1">
                  Current password
                </label>
                <input
                  placeholder="Please enter your old password"
                  className="po-edit-input"
                  type="text"
                  value={userPasswords.oldPassword}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      setErrors({
                        ...errors,
                        currentPassword: "This is required field",
                      });
                      setUserPasswords({ ...userPasswords, oldPassword: "" });
                    } else {
                      setUserPasswords({
                        ...userPasswords,
                        oldPassword: e.target.value,
                      });
                      setErrors({ ...errors, currentPassword: "" });
                    }
                  }}
                />
                {errors.currentPassword && (
                  <p className="text-danger mt-2">{errors.currentPassword}</p>
                )}
              </article>
              <article className="mb-3">
                <label className="text-md text-light text-wrap mb-1">
                  New password
                </label>
                <input
                  placeholder="Please enter your new password"
                  className="po-edit-input"
                  type="text"
                  onChange={(e) => {
                    if (e.target.value === "") {
                      setErrors({
                        ...errors,
                        newPassword: "This is required field",
                      });
                    } else {
                      setUserPasswords({
                        ...userPasswords,
                        newPassword: e.target.value,
                      });
                      setErrors({
                        ...errors,
                        newPassword: "",
                      });
                    }
                  }}
                />
                {errors.newPassword && (
                  <p className="text-danger mt-2">{errors.newPassword}</p>
                )}
              </article>
              <article className="mb-4">
                <label className="text-md text-light text-wrap mb-1">
                  Confirm new password
                </label>
                <input
                  placeholder="Please confirm your new password"
                  className="po-edit-input"
                  type="text"
                  onChange={(e) => {
                    let currentErrors = errors;
                    //Checking for new password and confirm password are same
                    if (e.target.value) {
                      console.log(
                        e.target.value === userPasswords.newPassword,
                        e.target.value,
                        userDetails.newPassword
                      );
                      if (e.target.value === userPasswords.newPassword) {
                        setUserPasswords({
                          ...userPasswords,
                          confirmPassword: e.target.value,
                        });
                        currentErrors.confirmPasswordValidationError = false;
                      } else {
                        currentErrors.confirmPasswordValidationError = true;
                        currentErrors.confirmPassword = "";
                      }
                    } else {
                      currentErrors.confirmPasswordValidationError = false;
                      currentErrors.confirmPassword = "This is required field";
                      setUserPasswords({
                        ...userPasswords,
                        confirmPassword: "",
                      });
                    }
                    setErrors({ ...currentErrors });
                  }}
                />
                {errors.confirmPassword && (
                  <p className="text-danger mt-2">{errors.confirmPassword}</p>
                )}
                {errors.confirmPasswordValidationError && (
                  <p className="text-danger mt-2">Password not matched</p>
                )}
              </article>
            </form>
            <div className="text-end mt-8">
              <SecondaryButton
                onClick={handleClose}
                name="Close"
                additionalClass="text-light px-5"
              />
              <PrimaryButton
                type="submit"
                name="Save"
                additionalClass="ms-3"
                onClick={changingPassword}
              />
            </div>
          </div>
        </Modal>
        <Toaster
          showToaster={showToaster}
          setShowToaster={setShowToaster}
          bg={toasterDetails.bg}
          title={toasterDetails.title}
          bodyText={toasterDetails.bodyText}
          statusIcon={toasterDetails.statusIcon}
        />
      </ContentWrapper>
    </Layout >

  );
};
export default Account;
