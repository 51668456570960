import { motion } from "framer-motion";
import { Col } from "react-bootstrap";
const Error = () => {
  return (
    <Col xs={4}>
      <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="d-flex po-state po-state__success p-2 w-25">
        <div className="po-state__icon me-3">
          <i className="bi bi-check-circle color-green text-2xl d-inline-block mt-1"></i>
        </div>
        <div className="po-state__desc ">
          <p className="color-green text-2xl mb-0 font-bold">Error</p>
          <p className="text-md mb-0">Try again...</p>
        </div>
        <div className="po-state__action"></div>
      </motion.div>
    </Col>
  );
};
export default Error;
