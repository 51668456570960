import { Link } from "react-router-dom";
const PrimaryButton = ({ name, link,icon, onClick, type, additionalClass,disabled }) => {
  return (
    <>
      {link ? (
        <Link
          to={link}
          className={`${additionalClass} text-black text-md font-body font-semibold cursor-pointer background-gradient  py-3 px-8 radius-105 border-0 d-inline-block`}
        >
          {icon ? (
            <i className={`bi ${icon} inline-block  me-2 mt-2 text-black text-xl`}></i>
          ) : null}
          {name}
        </Link>
      ) : onClick ? (
        <button
          className={` color-black1 text-md font-body font-semibold cursor-pointer background-gradient py-3 px-8 radius-105 border-0 d-inline-block ${additionalClass}`}
          onClick={onClick} disabled={disabled}
        >
          {icon ? (
            <i className={`bi ${icon} inline-block  me-2 mt-2 text-black text-xl`}></i>
          ) : null}
          {name}
        </button>
      ) : type ? (
        <button
          className={`${additionalClass} color-black1 text-md font-body font-semibold cursor-pointer background-gradient py-3 px-8 radius-105 border-0 d-inline-block`}
          type={type} disabled={disabled}
        >
          {icon ? (
            <i className={`bi ${icon} inline-block  me-2 mt-2 text-black text-xl`}></i>
          ) : null}
          {name}
        </button>
      ) : null}
    </>
  );
};
export default PrimaryButton;
