import React, { useState, useEffect } from 'react';
import UpdateEvent from "../../../Components/Events/UpdateManagerEvent";
import Layout from "../../../Layout/LayoutManager/Layout";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import db from "../../../firebase";
const ManagerEditEvent = () => {
  const { event_id } = useParams();
  const [eventDetails, setEventDetails] = useState({});
  const docRef = doc(db, "events", event_id);
  useEffect(() => {
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        // console.log("Document data:", docSnap.data());
        setEventDetails(docSnap.data());
      } else {
        // docSnap.data() will be undefined in this case
        // console.log("No such document!");
      }
    })
  }, [event_id]);
  return (
    <Layout>
      <UpdateEvent eventDetails={eventDetails} newClub={false} />
    </Layout>
  );
};
export default ManagerEditEvent;
