const PageDescription = ({title,caption, additionalClass="py-10"}) => {
  return(
    <div className={additionalClass}>
    <h2 className="color-app text-2xl text-lg-4xl font-bold font-body mb-0">{title}</h2>
      {/* <p className="color-app text-md  font-regular font-body w-75">
        {caption}
      </p> */}
    </div>
  )
}
export default PageDescription;