import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useSearchParams } from "react-router-dom";
import useCallCollection from "../../../hooks/useCallCollection";
import { collection, getDocs, query, where } from "firebase/firestore";
import db from "../../../firebase";

const mountedStyle = {
    animation: "inAnimation 250ms ease-in"
  };
  const unmountedStyle = {
    animation: "outAnimation 270ms ease-out",
    animationFillMode: "forwards"
  };

const FilterModalUser = React.forwardRef((props, wrapper) => {
  const [showFilterList, setShowFilterList] = useState(false);
  const [fetchedCities, setFetchedCities] = useState([]);
  const [citiesLoader, setCitiesLoader] = useState(false);
  const [errorFilterMessage, setErrorFilterMessage] = useState();
  const [filterOptions, setFilterOptions] = useState({
    filterCountry: true,
    filterCity: false
  })
  const [countries, loaderCountries] = useCallCollection("countries");
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, setState] = useState({
    selectedCountries: [],
    selectedCities: []
  })
  const fetchCitiesFunc = async(countryList) => {
    setFetchedCities([]);
    setCitiesLoader(true);
    let citiesData = await getDocs(query(collection(db, "cities"), where("country_name", "in", countryList)))
    citiesData.size && citiesData.forEach(city => {
        setFetchedCities(prev=>[...prev, city.data()]);
      })  
        setCitiesLoader(false);
  }
  useEffect(()=> {
    const filterQuery = JSON.parse(searchParams.get("filterQuery"));
    if(searchParams.get("filterQuery") && searchParams.get("filterQuery")!=="{}") {
        setState({...filterQuery});
        fetchCitiesFunc([...filterQuery.selectedCountries])
    }
    else {
        setState({
            selectedCountries: [],
            selectedCities: []
        })
    }
  }, [searchParams]);

  useEffect(()=> {
    if(!props.showModal) {
        if(state.selectedCities.length || (JSON.parse(searchParams.get("filterQuery")) && JSON.parse(searchParams.get("filterQuery")).selectedCities.length)) {
            setFilterOptions({
                filterCountry: true,
                filterCity: true
            })
        }
        else {
            setFilterOptions({
                filterCountry: true,
                filterCity: false
            })
        }
        if(!state.selectedCountries.length) {
            setFetchedCities([]);
        }
    }
  }, [props.showModal]);

  const handleSelect = (e, val, key, checked) => {
    if(!checked && (((state.selectedCountries.length+1) * state.selectedCities.length) < 30) && ((state.selectedCountries.length * (state.selectedCities.length + 1)) < 30)) {
        setErrorFilterMessage("");
        if(key==="selectedCities") {
            setState(prev=>({
                ...prev,
                [key] : [...state[key], {
                    country: val.country_name.toLowerCase(),
                    city: val.name.toLowerCase()
                }]
            }));
        } else {
            fetchCitiesFunc([...state.selectedCountries, val]);
            setState(prev=>({
                ...prev,
                [key] : [...state[key], val]
            }));
        }
    }
    else {
        setErrorFilterMessage("");
        if(key==="selectedCities") {
            setState(prev=>({
                ...prev,
                [key] : state[key].filter(city => city.country.toLowerCase() !== val.country_name.toLowerCase() || city.city.toLowerCase() !== val.name.toLowerCase())
            }))
        } else {
            setCitiesLoader(true);
            setState(prev=>({
                ...prev,
                [key] : state[key].filter(item=>item!== val)
            }))
            setFetchedCities([...fetchedCities.filter(item=>item.country_name!==val)]);
            setState(prev=>({
                ...prev,
                selectedCities : state.selectedCities.filter(city => city.country !== val.toLowerCase())
            }))
            setCitiesLoader(false);
        }
    }
    if(e.target.checked && state.selectedCountries.length+state.selectedCities.length > 29) {
        setErrorFilterMessage("Cannot select more than 30 options");
        setTimeout(()=> {
            setErrorFilterMessage("");
        }, 3000)
        return
    }
    else setErrorFilterMessage("");
  }

  const handleApplyFilter = () => {
    if(state.selectedCountries.length || state.selectedCities.length ) {
        let showAll = Boolean(searchParams.get('showAll'));
        searchParams.delete('searchQuery');
        searchParams.delete('pageNo');
        searchParams.delete('prevNext');
        searchParams.delete('currentPageId');
        searchParams.delete('n');
        searchParams.delete('showAll')
        if(!Boolean(searchParams.get('filterQuery'))) {
            setSearchParams({...Object.fromEntries([...searchParams]), pageNo: 1, filterQuery: JSON.stringify(state)});
        } else {
            setSearchParams({...Object.fromEntries([...searchParams]), pageNo: 1, filterQuery: JSON.stringify(state)});
        }
        props.handleFilterUsers(state, showAll);
        props.setShowModal(false);
    }
    else {
        props.setShowModal(false);
        if (Boolean(searchParams.get('filterQuery'))) {
            setSearchParams({});
            props.collectionRecall();
        }
    };
  }
  const handleClearFilter = () => {
    const resetFilterState = {
        selectedCountries: [],
        selectedCities: []
    }
    setState({...resetFilterState})
    props.setShowModal(false);
    if (!Boolean(searchParams.get('searchQuery')) && Boolean(searchParams.get('filterQuery'))) {
        setSearchParams({});
        props.collectionRecall(null, true);
    }
  }
  const handleFilterOptions = (e) => {
    setFilterOptions(prev => ({
        ...prev,
        [e.target.id]: !filterOptions[e.target.id]
    }))
    if (!showFilterList) setShowFilterList(true);
  }
  return (
    <>
        {props.showModal ? <div className={` position-absolute bg-light w-100 rounded-3 shadow overflow-hidden position-relative`} style={{zIndex:101}} ref={wrapper}>
            <div className="px-3 py-4 d-flex justify-content-between align-items-center background-appColor text-sm font-medium border-bottom pb-2">
                <button 
                    className="border-0 bg-transparent color-yellow1 text-sm font-medium po-filter__btn-clear px-0 text-capitalize" 
                    type="button"
                    onClick={handleClearFilter}
                >clear All</button>
                <button
                className={` color-black1 text-xs font-body font-semibold cursor-pointer background-gradient py-1 px-4 radius-105 border-0 d-inline-block`}
                onClick={handleApplyFilter}
                >Apply
                </button>
            </div>
            <div className="po-filter">
                <div className="py-2 d-flex align-items-start border-bottom pl-3">
                    <button 
                        className={`po-filter__itemsList-title bg-transparent border-0 text-sm text-black font-medium text-capitalize text-start text-break ${!filterOptions.filterCountry ? "w-100":""}`}
                        id="filterCountry"
                        onClick={handleFilterOptions}
                    >Country</button>
                    {filterOptions.filterCountry && <ul 
                        className={`po-filter__itemsList pb-0 ms-3 pe-3 }`} 
                        style={filterOptions.filterCountry ? mountedStyle : unmountedStyle}
                        onAnimationEnd={() => {
                        if (!filterOptions.filterCountry) setShowFilterList(false);
                        }}>
                        {!loaderCountries ? countries.sort((a,b)=>a.name < b.name ? -1 : 1).map((item, index)=> {
                            return <li key={index} 
                                       className={`po-filter__itemsList-item text-sm color-gray font-medium text-start py-2`} 
                                       title={item.name}
                                    >
                                        <label htmlFor={item.name} className={`cursor-pointer d-block text-capitalize ${state.selectedCountries && state.selectedCountries.includes(item.name) ? "color-yellow1" : ""}`}>
                                            <input 
                                                className="appearance-none opacity-0" 
                                                type="checkbox" 
                                                value={item.name}
                                                id={item.name} 
                                                name={item.name}
                                                checked={state.selectedCountries && state.selectedCountries.includes(item.name)}
                                                onChange={(e) => {handleSelect(e, item.name, "selectedCountries", state.selectedCountries.includes(item.name))}}/>
                                            <i className="bi bi-check2"></i> {item.name}
                                        </label>
                                    </li>
                        }): <Skeleton inline={true} count={5} height={30} style={{marginBottom:"1rem"}} baseColor='#dedede' highlightColor='#cccccc' duration={2} />}
                    </ul>}
                </div>
                <div className="py-2 d-flex align-items-start pl-3">
                    <button 
                        className={`po-filter__itemsList-title bg-transparent border-0 text-sm text-black font-medium text-capitalize text-start text-break ${!filterOptions.filterCity ? "w-100":""}`}
                        id="filterCity"
                        onClick={handleFilterOptions}
                    >City</button>
                    {filterOptions.filterCity ? <ul 
                        className="po-filter__itemsList pb-0 ms-3 pe-3" 
                        style={filterOptions.filterCity ? mountedStyle : unmountedStyle}
                        onAnimationEnd={() => {
                        if (!filterOptions.filterCity) setShowFilterList(false);
                        }}>
                        {!citiesLoader ? [...fetchedCities].sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0 ).map((item, index)=> {
                            return <li key={index} 
                                       className={`po-filter__itemsList-item text-sm color-gray font-medium text-start py-2 cursor-pointer ${state.selectedCities.some(city => city.country.toLowerCase() === item.country_name.toLowerCase() && city.city.toLowerCase() === item.name.toLowerCase()) ? "color-yellow1" : ""}`}
                                    >
                                        <label htmlFor={item.id} className={`cursor-pointer d-block ${state.selectedCities.some(city => city.country.toLowerCase() === item.country_name.toLowerCase() && city.city.toLowerCase() === item.name.toLowerCase()) ? "color-yellow1" : ""}`}>
                                            <input 
                                                className="appearance-none opacity-0" 
                                                type="checkbox" 
                                                value={item.name}
                                                id={item.id} 
                                                name={item.id}
                                                checked={state.selectedCities.some(city => city.country.toLowerCase() === item.country_name.toLowerCase() && city.city.toLowerCase() === item.name.toLowerCase())}
                                                onChange={(e) => {handleSelect(e, item, "selectedCities", state.selectedCities.some(city => city.country.toLowerCase() === item.country_name.toLowerCase() && city.city.toLowerCase() === item.name.toLowerCase()))}}/>
                                            <i className="bi bi-check2"></i> {item.name}
                                        </label>
                                    </li>
                        }): <Skeleton inline={true} count={5} height={30} style={{marginBottom:"1rem"}} baseColor='#dedede' highlightColor='#cccccc' duration={2} />}
                    </ul>:""}
                </div>
            </div>
            {errorFilterMessage && <p className="text-center text-xs font-medium text-danger py-2">{errorFilterMessage}</p>}
            {/* {!state.selectedCountries.length && <p className="text-center text-xs font-medium text-danger py-2">No selected Country found</p>} */}
        </div> : ""}
    </>
  );
});
export default FilterModalUser;
