import { Row, Col, Modal, Button } from "react-bootstrap";
import PrimaryButton from "../../../Components/Global/Buttons/PrimaryButton";
import ContentWrapper from "../../../Components/Global/ContentWrapper";
import PageDescription from "../../../Components/Global/PageDescription";
import Layout from "../../../Layout/Layout";
import ActionIcon from "../../../Components/Global/Buttons/ActionIcons";
import { collection, doc, setDoc, getDocs, updateDoc, where, getDoc } from "firebase/firestore";

import { useEffect, useState } from "react";
import db from "../../../firebase";
import { useParams, useSearchParams } from "react-router-dom";
import SecondaryButton from "../../../Components/Global/Buttons/SecondaryButton";

const LadiesNight = () => {
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const [day, setDay] = useState("");
  const [ladiesNightDays, setLadiesNightDays] = useState([]);
  let params = useParams();
  const [searchParams] = useSearchParams();
  let clubName = searchParams.get("name");
  let days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  // Getting All days
  const getAllDays = async () => {
    await getDoc(doc(db, "clubs", params.club_id))
      .then((res) => {
        // setLadiesNightDays(res.docs[0].data().days)
        console.log(res.data());
        res && res.data() && res.data().ladies_night && setLadiesNightDays(res.data().ladies_night)
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getAllDays();
  }, []);

  //Sorting in Alphabetical Order
  const sortingArray = (arr) => {
    const resultArray = arr.sort((a, b) => days.indexOf(a) - days.indexOf(b));
    return resultArray;
  };

  //handling Modal
  const handleClose = () => {
    setShow(false);
    setDay("");
    setError("");
  };
  const handleShow = () => setShow(true);

  //function to remove day from the list
  const removingFromList = async (index) => {
    const prevLadiesDaysArray = ladiesNightDays;
    prevLadiesDaysArray.splice(index, 1);

    setLadiesNightDays([...prevLadiesDaysArray]);
    await updateDoc(
      doc(db, "clubs", params.club_id),
      {
        ladies_night: ladiesNightDays,
      }
    )
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  //add value to array and storing to firebase
  const addingDayToList = async () => {
    if (!day) {
      setError("Please select a day");
      return;
    } else {
      setError("");
    }
    setShow(false);
    // we are duplicating the state and modifying it ...spread operator not working
    let previousLadiesNightDays = ladiesNightDays;
    previousLadiesNightDays.push(day);
    setLadiesNightDays(previousLadiesNightDays);
    await updateDoc(
      doc(db, "clubs", params.club_id),
      {
        ladies_night: ladiesNightDays,
      }
    )
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
    getAllDays();
    setDay("");
  };
  console.log(params, "params");
  return (
    <Layout>
      <div className="d-flex justify-content-between align-items-center gap-3 gap-lg-5  ">
        {/* Create button will display only if days are less than three because mandatory days are three days */}
        <PageDescription title={`Ladies nights ${clubName}`} caption="" />
        {ladiesNightDays && ladiesNightDays.length < 3 && (
          <>
            <PrimaryButton
              onClick={handleShow}
              name="Create ladies night"
              icon="bi-plus-circle"
            />
          </>
        )}
      </div>

      {/* This text will display if the array length is three */}
      <ContentWrapper additionalClass="p-5 body-font">
        {ladiesNightDays.length === 3 && (
          <p className="text-warning bold">Please remove one to create</p>
        )}

        {/* This array will map only length of array is three or more than zero */}
        <Row className="g-4">
          {ladiesNightDays.length <= 3 && ladiesNightDays.length > 0 ? (
            <>
              {sortingArray(ladiesNightDays).map((data, index) => {
                return (
                  <Col xs={4} key={index}>
                    <div
                      className="box rounded p-5 text-xl font-semibold text-light position-relative"
                      style={{ backgroundColor: "#171717" }}
                    >
                      <ActionIcon
                        onClick={(e) => {
                          removingFromList(index);
                        }}
                        additionalClass="bi-x-circle-fill text-3xl position-absolute top-0 end-0 me-2 mt-1 text-danger"
                      />
                      {data}
                    </div>
                  </Col>
                );
              })}
            </>
          ) : (
            <>
              <p className="text-center bold text-lg">No Data to Show</p>
            </>
          )}
        </Row>
      </ContentWrapper>

      {/* Modal which disaplays days */}
      <Modal show={show} onHide={handleClose}>
        <div className="background-appColor p-8 rounded font-body">
          <h5 className="text-2xl  color-ternary font-semibold mb-5">
            Select Day
          </h5>
          <select
            className="po-edit-input"
            defaultValue="Select option"
            onChange={(e) => {
              setDay(e.target.value);
              setError("");
            }}
          >
            <option value="Select option" disabled>
              Select option
            </option>
            {days.map((day, index) => {
              //Checking for already exist or not
              if (ladiesNightDays.includes(day)) {
                return (
                  <option disabled key={index}>
                    {day}
                  </option>
                );
              } else {
                return (
                  <option value={day} key={index}>
                    {day}
                  </option>
                );
              }
            })}
          </select>
          {error && <p className="text-danger mt-2 text-sm">{error}</p>}
          <div className="d-flex justify-content-end mt-12">
            <SecondaryButton
              onClick={handleClose}
              name="Cancel"
              additionalClass="text-white me-3"
            />
            <PrimaryButton onClick={addingDayToList} name="Add day" />
          </div>
        </div>
      </Modal>
    </Layout>
  );
};
export default LadiesNight;
