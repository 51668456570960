import { Button, Table } from "react-bootstrap";
import ContentWrapper from "../Global/ContentWrapper";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import SecondaryButton from "../Global/Buttons/SecondaryButton";
import Skeleton from "react-loading-skeleton";
import { useState, useEffect } from "react";
import { collection, getDocs, query, where, orderBy } from "firebase/firestore";
import db from "../../firebase";

const ManagerTableBookings = ({ clubId, usersContactDetails }) => {
  // const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [tableBookingsData, setTableBookingsData] = useState([])
  const fetchTableBookings = async () => {
    // try {
    // ================================================================================================
    const tableBookings = await getDocs(
      query(collection(db, "club_entry_bookings"), orderBy("createdAt", "desc"), where("booking_type", "==", "table_booking"), where("club_id", "==", clubId))
    ).then(res => {
      return res
    }).catch(err => {
      return err
    })
    console.log(tableBookings, "tableBookings LPLPLPL");
    setTableBookingsData([]);
    tableBookings.forEach((doc) => {
      setTableBookingsData(prev => [...prev, doc.data()])
    })
    setIsLoading(false);
    // }
    // catch (err) {
    //   console.log(err, 'err');
    // }
  }
  useEffect(() => {
    fetchTableBookings();
  }, [])
  return (
    <>
      <ContentWrapper additionalClass="font-body">
        {/* <h2 className="text-lg font-semibold ps-4 py-5 color-lightblue">Showing table bookings</h2> */}
        <div className="table-responsive po-table tableFixHead">
          <Table hover>
            <thead>
              <tr className="background-gray10">
                <th className="text-sm text-nowrap"><p className="text-nowrap text-xs text-xl-sm text-uppercase ps-3 color-black2 mb-0 py-2">S. NO</p></th>
                <th className="text-sm text-nowrap"><p className="text-nowrap text-xs text-xl-sm text-uppercase  color-black2 mb-0 py-2">Booking ID</p></th>
                <th className="text-sm text-nowrap"><p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">Name</p></th>
                <th className="text-sm text-nowrap"><p className="text-nowrap text-xs text-xl-sm text-uppercase  color-black2 mb-0 py-2">Phone no</p></th>
                <th className="text-sm text-nowrap"><p className="text-nowrap text-xs text-xl-sm text-uppercase  color-black2 mb-0 py-2">email ID</p></th>
                <th className="text-sm text-nowrap"><p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">guest COUNT</p></th>
                {/* <th className="text-sm text-nowrap"><p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">CLUB NAME</p></th>
                <th className="text-sm text-nowrap"><p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">CLUB LOGO</p></th> */}
                <th className="text-sm text-nowrap"><p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">AMOUNT</p></th>
                <th className="text-sm text-nowrap"><p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">BOOKED FOR</p></th>
                <th className="text-sm text-nowrap"><p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">STATUS</p></th>
                <th className="text-sm text-nowrap"><p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2"></p></th>
              </tr>
            </thead>
            <tbody>
              {!isLoading ?
                (
                  <>
                    {(tableBookingsData && tableBookingsData.length) ? tableBookingsData.map((tableBookingData, index) => {
                      // const date = moment(new Date(tableBookingData.booking_date.seconds * 1000).toISOString()).format("DD MMM YYYY")

                      return (
                        <tr key={index}>
                          <td className="align-middle"><p className="text-sm mb-0 text-nowrap color-lightblue ps-3">{index + 1}</p></td>
                          <td className="align-middle"><p className="text-sm mb-0 text-nowrap color-lightblue ">{tableBookingData && tableBookingData.booking_id}</p></td>
                          <td className="align-middle"><p className="text-sm mb-0 text-nowrap color-lightblue">{tableBookingData && tableBookingData.user && tableBookingData.user.name}</p></td>
                          <td className="align-middle"> <p className="text-sm mb-0 text-nowrap color-lightblue ">{usersContactDetails[tableBookingData && tableBookingData.user_id] && usersContactDetails[tableBookingData && tableBookingData.user_id][0] ? usersContactDetails[tableBookingData && tableBookingData.user_id][0][0].contact_no : ""}</p></td>
                          <td className="align-middle"> <p className="text-sm mb-0 text-nowrap color-lightblue ">{usersContactDetails[tableBookingData && tableBookingData.user_id] && usersContactDetails[tableBookingData && tableBookingData.user_id][1] ? usersContactDetails[tableBookingData && tableBookingData.user_id][1] : ""}</p></td>
                          <td className="align-middle"><p className="text-sm mb-0 text-nowrap color-lightblue">{tableBookingData && tableBookingData.attendee_count+1}</p></td>
                          {/* <td className="align-middle"><p className="text-sm mb-0 text-nowrap color-lightblue">{tableBookingData && tableBookingData.club_name}</p></td>
                          <td className="align-middle"><img src={tableBookingData && tableBookingData.club_display_image} className="rounded" alt="" width="100px" height
                            ="100px" /></td> */}
                          <td className="align-middle"><p className="text-sm mb-0 text-nowrap color-lightblue">{tableBookingData && "$ " + tableBookingData.amount}</p></td>
                          <td className="align-middle"><p className="text-sm mb-0 text-nowrap color-lightblue">{moment(new Date(tableBookingData.booking_date.seconds * 1000).toISOString()).format("DD MMM YYYY")}</p></td>
                          <td className="align-middle">
                            <div className="d-flex align-items-center justify-content-start">
                              <div
                                style={{
                                  height: "10px",
                                  width: "10px",
                                  backgroundColor: `${tableBookingData.status === "Approved"
                                    ? "#13DEB9"
                                    : tableBookingData.status === "Booking request pending"
                                      ? "#FFAE1F"
                                      : tableBookingData.status === "Rejected"
                                        ? "#FA896B"
                                        : null
                                    }`,
                                }}
                                className="rounded-circle"
                              ></div>
                              <div className="ms-2 color-lightblue text-sm text-nowrap">
                                {tableBookingData && tableBookingData.status}
                              </div>
                            </div>

                          </td>
                          <td className="align-middle">
                            <SecondaryButton link={`/manager/table-details/${tableBookingData && tableBookingData.id}`} name="View" additionalClass="text-sm" />

                          </td>
                        </tr>)
                    }) : ""
                    }
                  </>
                ) : (
                  <tr>
                    <td colSpan={10}> <Skeleton inline={true} count={5} height={80} style={{ marginBottom: "1rem" }} baseColor='#dedede' highlightColor='#cccccc' duration={2} />
                    </td>
                  </tr>
                )
              }
            </tbody>
          </Table>
        </div>
      </ContentWrapper>
    </>
  );
};
export default ManagerTableBookings;