import { collection, doc, getDoc, getDocs, orderBy, query, where } from "firebase/firestore";
import { useState, useEffect, useCallback } from "react";
import db from "../firebase";

const useCallCollection = (collectionName, subCollection="event_status", booking_type) => {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);

  const fetchData = useCallback(() => {
    setLoader(true);
    setData([]);
      getDocs(query(collection(db, collectionName),orderBy(collectionName==="events" ? "updateAt" : "updatedAt", "desc")))
      .then((docs) => {
        docs.forEach((doc) => {
          if(collectionName==="clubs") {
            if(doc.data().status === "Active") setData((prev) => [...prev, doc.data()]);
          } else if(collectionName==="events") {
            if(doc.data().active) setData((prev) => [...prev, doc.data()]);
          } else setData((prev) => [...prev, doc.data()]);
        });
        setLoader(false);
      })
      .catch((err) => {
        console.log(err, "err")
      });
  }, [collectionName]);

  useEffect(() => {
    if(booking_type) {
        setData([]);
        getDocs(query(collection(db, collectionName),where("booking_type", "==", booking_type)))
          .then((docs) => {
            docs.forEach((doc) => {
              setData((prev) => [...prev, doc.data()]);
            });
            setLoader(false);
          })
          .catch((err) => {
            console.log(err, "err");
          });
      return
    }
    if(collectionName!=="picklist") fetchData(); 
    else {
      setData([]);
      getDoc(doc(db, "picklist", subCollection))
      .then((entries) => {
        setData([...Object.values(entries.data())]);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err, "err");
      });
    }
  }, [collectionName, fetchData, subCollection, booking_type]);
  return [data,loader,fetchData];
};

export default useCallCollection;