import { Row, Col, Tooltip, OverlayTrigger, Modal } from "react-bootstrap";
import ContentWrapper from "../../Components/Global/ContentWrapper";
import Layout from "../../Layout/Layout";
import { useEffect } from "react";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import db from "../../firebase";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PageDescription from "../../Components/Global/PageDescription";
import PrimaryButton from "../../Components/Global/Buttons/PrimaryButton";

const PlanCard = ({ membershipName, thumbnail, features, id }) => {
  const navigate = useNavigate()
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <article className="bg-white p-3 rounded overflow-hidden border body-font ">
      <h4 className="text-lg px-2">{membershipName}</h4>
      <div
        className="background-appColor rounded my-4 overflow-hidden p-2"
        style={{ height: "250px", width: "100%" }}
      >
        <img
          src={thumbnail}
          alt=""
          style={{ height: "100%", width: "100%", objectFit: "contain" }}
        />
      </div>
      <div className="d-flex justify-content-end px-2">
        {/* <OverlayTrigger
          delay={{ hide: 450, show: 300 }}
          overlay={(props) => (
            <Tooltip {...props} className="rounded">
              <h2 className="font-body mb-0 ps-2 pt-2 pb-1 text-lg ">
                {membershipName} Membership
              </h2>
              <ul className="font-body mb-0 ps-2 pl-5  pb-2">
                {features && features.map((item, index) => {
                  return <li className="text-sm font-normal mb-0 mt-2 gold-bullets" key={index}>{item.description}</li>;
                })}
              </ul>
            </Tooltip>
          )}
          placement="right"
        >
          <div className="d-inline-block color-lightestblue text-sm font-semibold mb-0 font-body">
            <i className="bi bi-info-circle-fill text-xl color-yellow1"></i>
          </div>
        </OverlayTrigger> */}


        <Modal show={show} onHide={handleClose} size="lg" >
          <Modal.Header className="background-appColor p-8 font-body">
            <Modal.Title className="d-flex justify-content-between align-items-center w-100"><h2 className="font-body mb-0 ps-2 pt-2 pb-1  text-2xl  color-ternary font-semibold ">
              {membershipName} Membership
            </h2>
              <i className="bi bi-x-circle-fill text-2xl color-ternary cursor-pointer" onClick={handleClose}></i>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="background-appColor p-8 font-body">
            <ul className="font-body mb-0 ps-2 pl-5  pb-2">
              {features && features.map((item, index) => {
                return <li className="text-sm font-normal mb-0 mt-2 gold-bullets text-light" style={{ opacity: "0.8" }} key={index}>{item.description}</li>;
              })}
            </ul>
          </Modal.Body>
          <Modal.Footer className="background-appColor p-8 font-body">
            {/* <button onClick={handleClose}>Close</button> */}
          </Modal.Footer>
        </Modal>
        <i className="bi bi-info-circle-fill text-xl color-yellow1 me-2 cursor-pointer" onClick={handleShow}></i>
        <button className="bg-none border-0 bg-transparent ms-3" onClick={() => { navigate(`/plans/edit-plans/${id}`) }}>
          <i className=" text-xl bi bi-pencil-square" ></i>
        </button>

      </div>
    </article>
  );
};
const Plans = () => {
  const [vipPlans, setVipPlans] = useState([]);

  //Funtion to get all plans
  const getAllVipPlans = async () => {
    await getDocs(query(collection(db, "memberships"), orderBy("app_order"))).then((res) => {
      //mapping all data
      let plans = res.docs.map((doc) => doc.data());
      setVipPlans(plans)
    }).catch((err) => {
      console.log(err);
    })
  }

  //getting All vip plans from backend
  useEffect(() => {
    getAllVipPlans()
  }, [])
  console.log(vipPlans, 'vipPlans')
  return (
    <Layout path="/plans">
      <div className="d-flex justify-content-between align-items-center gap-3 gap-lg-5 ">
        <PageDescription title="Exclusive Plans" caption="" />
        {/* <PrimaryButton link="/create-plan" name="Create Plan" icon="bi-plus-circle" /> */}
      </div>
      <ContentWrapper additionalClass="p-5 font-body">
        <Row className="g-4">
          {vipPlans && vipPlans.map((vipPlan, index) => {
            const { name, benifits, id, flat_image } = vipPlan
            return (
              <Col xs={4} key={index}>
                <PlanCard
                  membershipName={name}
                  thumbnail={flat_image}
                  features={benifits}
                  id={id}
                />
              </Col>)
          })
          }

        </Row>
      </ContentWrapper>
    </Layout>
  );
};
export default Plans;
