import React, { useState, useEffect } from 'react';
import ReactApexChart from "react-apexcharts";
import ContentWrapper from "../../ContentWrapper";
import DateRange from "../../DateRange";
import { Row, Col } from 'react-bootstrap';
import StatsCard from '../../StatsCard';
const ColumnChart = ({ menu_preference, setSelectedDateRange, menuPreferenceStartDate, menuPreferenceEndDate }) => {
  const [state, setState] = useState({
    preffers_smoke: [],
    preffers_drink: [],
    preffers_both: [],
    months: [],
  })
  useEffect(() => {
    let preffers_smoke = [];
    let preffers_drink = [];
    let preffers_both = [];
    let months = []
    menu_preference.length > 0 && menu_preference.forEach(data => {
      preffers_smoke.push(data.smoke);
      preffers_drink.push(data.drink);
      preffers_both.push(data.both);
      months.push(data.month_name);
    })
    setState((prevProps) => ({
      ...prevProps,
      preffers_smoke: preffers_smoke,
      preffers_drink: preffers_drink,
      preffers_both: preffers_both,
      months: months,
    }))
  }, [menu_preference]);
  const chartData = {
    series: [{
      name: 'Prefers Smokes',
      data: state.preffers_smoke
    }, {
      name: 'Prefers Drinks',
      data: state.preffers_drink
    }, {
      name: 'Prefers Both',
      data: state.preffers_both
    }],

    options: {
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: 'left',
      },
      colors: ["#003f5c",
        "#444e86",
        "#955196",],
      chart: {
        type: 'bar',
        height: 350,
        fontFamily: 'Montserrat, sans-serif',
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: state.months,
      },
      yaxis: {
        title: {
          text: '(People)'
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val
          }
        }
      }
    },

  }
  const getDateRange = (start, end) => {
    setSelectedDateRange(start, end, "menu_preference");
  }
  return (
    <ContentWrapper additionalClass="p-5 rounded font-body h-100">
      <div className="d-flex justify-content-between font-body">
        <div>
          <h2 className="text-md text-xl-lg font-bold color-blue font-body mb-0">
            Menu Preference Overview
          </h2>
          {/* <p className="text-xs text-xl-sm"> Menu Preferences: 200</p> */}
        </div>

        <div>
          <DateRange
            getDateRange={getDateRange}
            startDate={menuPreferenceStartDate}
            endDate={menuPreferenceEndDate}
          />
        </div>
      </div>
      <Row className='my-5'>
        <Col lg={4}>
          <StatsCard
            cardTitle="Smoking"
            cardStats="5000"
            cardDuration="No of people who preferred smoking"
            cardIcon=""
          />
        </Col>
        <Col lg={4}>
          <StatsCard
            cardTitle="Drinking"
            cardStats="10000"
            cardDuration="No of people who preferred Drinking"
            cardIcon=""
          />
        </Col>
        <Col lg={4}>
          <StatsCard
            cardTitle="Both"
            cardStats="120000"
            cardDuration="No of people who preferred smoking and drinking"
            cardIcon=""
          />
        </Col>
      </Row>
      <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={350} /></ContentWrapper>
  )
}
export default ColumnChart;