import ContentWrapper from "../../Components/Global/ContentWrapper";
import Layout from "../../Layout/Layout";
import { Table, Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  orderBy,
  query,
} from "firebase/firestore";
import db from "../../firebase";
import Dropdown from "react-bootstrap/Dropdown";
import SecondaryButton from "../../Components/Global/Buttons/SecondaryButton";
import PageDescription from "../../Components/Global/PageDescription";
import PrimaryButton from "../../Components/Global/Buttons/PrimaryButton";
import { useNavigate } from "react-router-dom";
const ImagePreview = () => {
  return (
    <div
      style={{
        height: "75px",
        width: "150px",
        backgroundColor: "#DEDEDE",
      }}
      className="bg-gray rounded overflow-hidden d-flex align-items-center justify-content-center"
    >
      <i className="bi bi-card-image text-6xl"></i>
    </div>
  );
};
const JourneyToBeOne = () => {
  const navigate = useNavigate();
  const [justToBeOne, setJustToBeOne] = useState(null);
  // modal states
  const [show, setShow] = useState({ show: false, data: null });
  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    console.log(id);
    setShow({ show: true, data: justToBeOne[id] });
  };
  // fetching entries
  const fetchRequests = async () => {
    const q = query(
      collection(db, "journey_to_be_one"),
      orderBy("createdAt", "desc")
    );
    const querySnapshot = await getDocs(q);
    console.log(querySnapshot.docs, "querySnapshot");
    const newData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setJustToBeOne(newData);
  };
  useEffect(() => {
    fetchRequests();
  }, []);

  // deleting entries
  const hadnleDelete = async (data) => {
    console.log(data.id, "id");

    try {
      await deleteDoc(doc(db, "journey_to_be_one", data.id)).then((res) => {
        console.info("Success");
      });
      fetchRequests();
      handleClose();
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Layout>
      <ContentWrapper additionalClass="pb-5">
        <div className="d-flex justify-content-between align-items-center gap-3 gap-lg-5 font-body px-5">
          <PageDescription
            title="All records of journey to be one"
            caption=""
          />
          <PrimaryButton
            link="/create-journey"
            name="Create Card"
            icon="bi-plus-circle"
          />
        </div>
        <Table hover>
          <thead>
            <tr className="background-gray10">
              <th>
                <p className="text-nowrap text-xs text-xl-sm text-uppercase ps-3 color-black2 mb-0 py-2">
                  s.no
                </p>
              </th>
              <th>
                <p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">
                  Image
                </p>
              </th>
              <th>
                <p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">
                  Title
                </p>
              </th>
              <th>
                <p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">
                  Description
                </p>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {justToBeOne && justToBeOne.length > 0 ? (
              justToBeOne.map((data, index) => {
                return (
                  <tr key={index}>
                    <td className="align-middle">
                      <p className="text-sm mb-0 text-nowrap color-lightblue ps-3">
                        {index + 1}.
                      </p>
                    </td>
                    <td className="align-middle">
                      {data.image ? (
                        <div
                          style={{
                            height: "70px",
                            width: "70px",
                            backgroundImage: `url(${data.image && data.image})`,
                          }}
                          className="background-position-center-top background-repeat-norepeat background-size-cover rounded overflow-hidden "
                        ></div>
                      ) : (
                        <ImagePreview />
                      )}
                    </td>

                    <td className="align-middle">
                      <p className="text-sm mb-0 text-nowrap color-lightblue">
                        {data.title && data.title}
                      </p>
                    </td>
                    <td className="align-middle">
                      {data.description && data.description}
                    </td>
                    <td className="align-middle">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="link"
                          id="dropdown-basic"
                          className="text-black"
                        >
                          <i className="bi bi-three-dots-vertical text-4xl d-bloxk"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="border-0 shadow px-2">
                          <Dropdown.Item
                            className="border-bottom font-bold text-sm py-2"
                            onClick={() => {
                              navigate(`/edit-journey/${data.id}`);
                            }}
                          >
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="font-bold text-sm py-2"
                            onClick={() => handleShow(index)}
                          >
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                );
              })
            ) : (
              <td colSpan={4} className="text-center text-lg bold">
                No Data to show
              </td>
            )}
          </tbody>
        </Table>
      </ContentWrapper>
      <Modal show={show.show} onHide={handleClose}>
        <div className="background-appColor p-8 rounded font-body">
          <h5 className="text-2xl  color-ternary font-semibold ">
            Are you sure?
          </h5>
          <p className="background-appColor text-light border-bottom-0 py-1 ">
            You want to delete this popup
          </p>
          <div className="d-flex justify-content-end mt-12">
            <SecondaryButton
              onClick={handleClose}
              name="Cancel"
              additionalClass="text-white me-3"
            />
            <PrimaryButton
              onClick={() => hadnleDelete(show.data)}
              name="Delete"
            />
          </div>
        </div>
      </Modal>
    </Layout>
  );
};
export default JourneyToBeOne;
