import React, { useState, useEffect } from "react";
import { collection, deleteDoc, doc, getDocs,query,orderBy } from "firebase/firestore";
import { Table, Modal, Col, Row, Tab, Nav } from "react-bootstrap";
import { Link, useParams,useSearchParams } from "react-router-dom";
import db from "../../../../firebase";
import Layout from "../../../../Layout/Layout";
import PageDescription from "../../../../Components/Global/PageDescription";
import ContentWrapper from "../../../../Components/Global/ContentWrapper";
import Skeleton from "react-loading-skeleton";
import PrimaryButton from "../../../../Components/Global/Buttons/PrimaryButton";
import Dropdown from "react-bootstrap/Dropdown";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import SecondaryButton from "../../../../Components/Global/Buttons/SecondaryButton";
const AdminCloseBooking = () => {
  const { club_id } = useParams();
  const [loader, setLoader] = useState(true);
  const [show, setShow] = useState(false);
  const [closedBookings, setClosedBookings] = useState([]);
  const handleClose = () => setShow(false);
  const [deletingId, setDeletingId] = useState("");
  const [events, setEvents] = useState([]);
  const [searchParams] = useSearchParams();
  const clubName = searchParams.get("name");
  const localizer = momentLocalizer(moment);
  const getClosedBookingData = async () => {
    setLoader(true);
    let temp = events;
    // start
    // const qSnap = query(collection(db, "clubs", club_id, "closed_bookings"), orderBy('createdAt', 'desc'));
    // const querySnapshot = await getDocs(qSnap);
    // const newData = querySnapshot.docs.map((doc) => ({
    //   id: doc.id,
    //   ...doc.data(),
    // }));
    // end
    const subColRef = query(collection(db, "clubs", club_id, "closed_bookings"), orderBy('createdAt', 'desc'));
    const qSnap = await getDocs(subColRef);
    // let closedBookingsArray = [];
    setClosedBookings([]);
    qSnap.forEach((data) => {
      let elementdata = data.data();
      let eventDate = new Date(elementdata.date.seconds*1000)
      temp.push({
        start: eventDate,
        end: eventDate,
        title: elementdata.reason,
      });
      setClosedBookings(prev=>[...prev, elementdata]);
      // closedBookingsArray.push(data.data());
    });
    console.log(temp,"temp")
    setEvents(temp);
    // setClosedBookings(closedBookingsArray);
    setLoader(false);
  };

  useEffect(() => {
    getClosedBookingData();
  }, []);
  const confirmDelete = (deleteId) => {
    setShow(true);
    setDeletingId(deleteId);
  };
  const deleteCloseBooking = async (e, id) => {
    e.preventDefault();
    const deletion = await deleteDoc(
      doc(db, "clubs", club_id, "closed_bookings", id)
    )
      .then(() => {
        getClosedBookingData();
        setShow(false);
        window.location.reload()
      })
      .catch((err) => {
        console.log(err);
        // alert("Not Deleted");
      });
  };
  console.log(closedBookings,'closedBookings')
  return (
    <Layout path={`/clubs/close-booking`} additionalclass="no-scrollbar ">
      <PageDescription title="All Non-Operational Days" />
      <ContentWrapper>
      <h2 className="text-lg font-semibold ps-3 pt-4 color-lightblue">Showing non-operational days for <span className='text-uppercase font-bold text-dark'>{clubName}</span></h2>
        <div className="po-custom-tabs">
          <Tab.Container id="left-tabs-example" defaultActiveKey="tableView">
            <div>
              <div className="border-bottom po-custom-tabs d-flex align-items-center justify-content-between px-5">
                <Nav variant="pills" className="flex-row pb-4">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="tableView"
                      className="d-flex justify-content-center align-items-center"
                    >
                      <div>Table View</div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="calenderView"
                      className="d-flex justify-content-center align-items-center"
                    >
                      <div>Calender View</div>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <div className="my-3">
                  {" "}
                  <PrimaryButton
                    name=" Add Day"
                    link={`/clubs/close-booking/create-close-booking/${club_id}`}
                    icon="bi-plus-circle"
                  />
                </div>
              </div>
              <div>
                <Tab.Content>
                  <Tab.Pane eventKey="tableView">
                    <div className="tableFixHead pb-5 font-body rounded bg-white">
                    <Table hover>
                      <thead>
                        <tr className="background-gray10">
                          <th>
                            <p className="text-nowrap text-sm text-uppercase ps-3 color-black2 mb-0 py-2">
                              s. no.
                            </p>
                          </th>
                          <th>
                            <p className="text-nowrap text-sm text-uppercase  color-black2 mb-0 py-2">
                              Reason
                            </p>
                          </th>
                          <th>
                            <p className="text-nowrap text-sm text-uppercase  color-black2 mb-0 py-2">
                              Date
                            </p>
                          </th>
                          <th>
                            <p className="text-nowrap text-sm text-uppercase  color-black2 mb-0 py-2"></p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {!loader ? (
                          closedBookings &&
                          closedBookings.length > 0 &&
                          closedBookings.map((data, index) => {
                            return (
                              <tr key={index}>
                                <td className="align-middle">
                                  <p className="text-sm mb-0 text-nowrap color-lightblue ps-3">
                                    {index + 1}.
                                  </p>
                                </td>
                                <td className="align-middle">
                                  <p className="text-sm mb-0 text-nowrap color-lightblue">
                                    {data.reason}
                                  </p>
                                </td>
                                <td className="align-middle">
                                  <p className="text-sm mb-0 text-nowrap color-lightblue">
                                    {data && data.date && moment(data.date.toDate()).format("DD MMM YYYY")}
                                  </p>
                                </td>
                                <td className="align-middle">
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      variant="link"
                                      id="dropdown-basic"
                                      className="text-black"
                                    >
                                      <i className="bi bi-three-dots-vertical text-4xl d-bloxk"></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="border-0 shadow px-2">
                                      <Link
                                        className="border-bottom font-bold text-sm py-2 d-block text-black ps-2"
                                        to={`/clubs/close-booking/update-close-booking/${data.id}/${club_id}`}
                                      >
                                        Edit
                                      </Link>
                                      <div
                                        className="cursor-pointer font-bold text-sm py-2 d-block text-black ps-2"
                                        onClick={(e) => confirmDelete(data.id)}
                                      >
                                        Delete
                                      </div>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={8}>
                              <Skeleton inline={true} count={8} height={70} />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="calenderView">
                    <Row className="mt-4">
                      <Col xs={12}>
                        <div className="w-100 p-4">
                          {events.length > 0 ? (
                            <Calendar
                              localizer={localizer}
                              events={events}
                              defaultDate={new Date()}
                              views={["month", "week", "day"]}
                              // selectable={true}
                              // resizable={true}
                              style={{ height: "500px" }}
                            />
                          ) : (
                            <p className="text bold text-center m-5">
                              No Data to Show..
                            </p>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </div>
      </ContentWrapper>

      <Modal show={show} onHide={handleClose}>
        <div className="background-appColor p-8 rounded font-body">
          <h5 className="text-2xl  color-ternary font-semibold ">
            Are you sure?
          </h5>
          <p className="background-appColor text-light border-bottom-0 py-1 ">
            You want to delete this Non-Operational Days from the list.
          </p>
          <div className="d-flex justify-content-end mt-12">
            <SecondaryButton
              name="Cancel"
              onClick={handleClose}
              additionalClass="text-light me-3"
            />

            <PrimaryButton
              name="Delete"
              onClick={(e) => deleteCloseBooking(e, deletingId)}
            />
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

export default AdminCloseBooking;