import React, { useState, useEffect } from 'react';
import { Row, Col } from "react-bootstrap";
import ContentWrapper from "../ContentWrapper";
import DateRange from "../DateRange";
const TodaysStat = ({ todayAnalytycs }) => {
  const [state, setState] = useState({
    users: 0,
    table_booking: 0,
    event_booking: 0,
    club_entry_booking: 0
  })

  useEffect(() => {
    todayAnalytycs && todayAnalytycs && setState((prevProps) => ({
      ...prevProps,
      users: todayAnalytycs.users,
      table_booking: todayAnalytycs.table_booking,
      event_booking: todayAnalytycs.event_booking,
      club_entry_booking: todayAnalytycs.club_entry_booking
    }))
  }, [todayAnalytycs]);
  return (
    <div >
      <div className="d-flex justify-content-between font-body">
        <div>
          <h2 className="text-md text-xl-lg font-bold color-blue font-body mb-3">
            Today's Stat
          </h2>
          {/* <p className="text-xs text-xl-sm"> Revenue from bookings : 200</p> */}
        </div>
        {/* <div>
          <DateRange getDateRange="" startDate="" endDate="" />
        </div> */}
      </div>
      <Row className="g-4">
        <Col xs={3}>
          <ContentWrapper additionalClass="p-5 h-100">
            <article>
              <h2 className="color-gray text-md">Users</h2>
              <p className="text-2xl font-bold color-blue mb-3">{state.users}</p>
              <p className="color-gray mb-0 text-sm">No. of users registered</p>
            </article>
          </ContentWrapper>
        </Col>
        <Col xs={3}>
          <ContentWrapper additionalClass="p-5 h-100">
            <article>
              <h2 className="color-gray text-md">Club table bookings</h2>
              <p className="text-2xl font-bold color-blue mb-3">{state.table_booking}</p>
              <p className="color-gray mb-0 text-sm">No. of club table bookings</p>
            </article>
          </ContentWrapper>
        </Col>
        <Col xs={3}>
          <ContentWrapper additionalClass="p-5 h-100">
            <article>
              <h2 className="color-gray text-md">Club entry bookings</h2>
              <p className="text-2xl font-bold color-blue mb-3">{state.club_entry_booking}</p>
              <p className="color-gray mb-0 text-sm">No. of club entry bookings</p>
            </article>
          </ContentWrapper>
        </Col>
        <Col xs={3}>
          <ContentWrapper additionalClass="p-5 h-100">
            <article>
              <h2 className="color-gray text-md">Event bookings</h2>
              <p className="text-2xl font-bold color-blue mb-3">{state.event_booking}</p>
              <p className="color-gray mb-0 text-sm">No. of event bookings</p>
            </article>
          </ContentWrapper>
        </Col>
      </Row>
    </div>
  )
}
export default TodaysStat;