import ContentWrapper from "./ContentWrapper";
const clubs = "/assets/analytics-icon/club-registration.svg";
const membership= "/assets/analytics-icon/membership.svg";
const profit ="/assets/analytics-icon/profit.svg";

const StatsCard = ({
  cardTitle,
  cardStats,
  cardProgress,
  growth,
  cardDuration,
  cardIcon,classes
}) => {
  const styles ={
    src: cardIcon === 'danger' ? membership : cardIcon === 'ternary' ?  clubs : cardIcon === 'success' ? profit : null
  }
  return (
    <ContentWrapper additionalClass={`font-body p-5 h-100 ${classes}` }>
      <div className="d-flex justify-content-between">
        <div className="w-75">
          <h2 className="color-gray text-md">{cardTitle}</h2>
          <p className="text-2xl font-bold color-blue mb-0">{cardStats}</p>
        </div>
        <div style={{height:"50px",width:"50px"}}>
          <img  src={styles.src} alt=""  className="img-fluid" />
        </div>
      </div>
      <div className="d-flex align-items-center mt-3">
        <div className="d-flex align-items-center">
          {growth!==undefined && <i className={`bi bi-arrow-up-short text-md lineheight-0 ${growth ? '' : "text-danger po-rotate--180 " }`} />}
          <div className="text-xs font-medium">{cardProgress}</div>
        </div>
        <div className="ms-1 text-xs font-medium">{cardDuration}</div>
      </div>
      </ContentWrapper>
  );
};
export default StatsCard;
