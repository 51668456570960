import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';

import Layout from '../../../Layout/LayoutManager/Layout';
import db from '../../../firebase';
import EventEntryBooking from '../../../Components/Global/Table/ManagerEventEntryBooking';

const tableHeading = {
  heading1: "USER",
  heading2: "GUEST COUNT",
  heading3: "AMOUNT",
  heading4: "BOOKING ON",
  heading5: "STATUS"
}

const ManagerEventBookings = () => {
  const { event_id } = useParams();
  const [eventEntryBooking, setEventEntryBooking] = useState([]);
  const [loading, setLoading] = useState(true);
  const [eventDetails, setEventDetails] = useState({});
  let id_search_param = event_id;

  useEffect(() => {      // selected event details ***********************************
    const docRef = doc(db, "events", id_search_param);
    try {
      getDoc(docRef)
        .then((docSnap) => {
          setEventDetails(docSnap.data());
        })
    }
    catch (err) {
      console.log("something went wrong!");
    }

    // event entry list details **********************************
    const q = query(collection(db, "event_entry_bookings"), where("event_id", "==", id_search_param));
    getDocs(q)
      .then((docs) => {
        if (!docs.empty) {
          let tempArray = []
          docs.forEach((doc) => {
            // let temp={}
            tempArray.push(doc.data())
            setLoading(false)
          });
          setEventEntryBooking(tempArray)
        }
        else {
          console.log('no entry found!');
          setLoading(false)
        }

      })
  }, [id_search_param])
  console.log(eventEntryBooking, 'eventEntryBooking ');
  return (
    <Layout>
      <Row className="d-flex flex-column-reverse flex-lg-row justify-content-between align-items-lg-start g-3 g-lg-5 ">
        <Col lg="7" xxl="5">
          <div>
            {eventDetails && <div className='d-flex align-items-start'>
              <span className='po-eventDetails__key d-flex font-body text-nowrap me-2'>Event Name:</span>
              <span className='po-eventDetails__value font-bold pl-1 font-body'>
                {eventDetails && eventDetails.name}
              </span>
            </div>}
            <div className='d-flex align-items-start '>
              <span className='po-eventDetails__key d-flex font-body text-nowrap me-2'>Club Name:</span>
              <span className='po-eventDetails__value font-bold font-body pl-1'>
                {eventDetails && eventDetails.club && eventDetails.club.name}
              </span>
            </div>
            {eventDetails && <div className='d-flex align-items-start'>
              <span className='po-eventDetails__key d-flex font-body text-nowrap me-2'>Address:</span>
              <span className='po-eventDetails__value pl-1 font-body'>
                {eventDetails && eventDetails.club && eventDetails.club.address}
              </span>
            </div>}
          </div>
        </Col>
        <Col md="5" lg="4">
          {eventDetails && <div className='rounded-2 overflow-hidden'>
            <img src={eventDetails && eventDetails.display_image} alt='display_image' className='img-fluid' />
          </div>}
        </Col>
      </Row>
      <EventEntryBooking
        tableHeading={tableHeading}
        tableData={eventEntryBooking}
        loading={loading}
      />
    </Layout>
  )
}

export default ManagerEventBookings
