import { Link, useLocation } from "react-router-dom";

const breadCrumbData = [
  {
    icon: "/assets/icons/sidebar/dark/dashboard-icon-dark.svg",
    title: "Dashboard",
    caption: "/ Overview",
    link: "/manager",
  },
  {
    icon: "/assets/icons/sidebar/dark/menu-management-dark.svg",
    title: "Menu Management",
    caption: "/ Drinks Category",
    link: "/manager/drinks-management",
  },
  {
    icon: "/assets/icons/sidebar/dark/menu-management-dark.svg",
    title: "Menu Management / Drink Category",
    caption: "/ Add Drink Category",
    link: "/manager/drinks-management/create-category",
    to: "/manager/drinks-management"
  },
  {
    icon: "/assets/icons/sidebar/dark/menu-management-dark.svg",
    title: "Menu Management / Drink Category",
    caption: "/ Update Drink Category",
    link: "/manager/drinks-management/update-category",
    to: "/manager/drinks-management"
  },
  {
    icon: "/assets/icons/sidebar/dark/menu-management-dark.svg",
    title: "Menu Management / Smoke Category",
    caption: "/ Update Smoke Category",
    link: "/manager/smokes-management/update-category",
    to: "/manager/smokes-management"
  },
  {
    icon: "/assets/icons/sidebar/dark/bookings.svg",
    title: "Bookings",
    caption: "/ Table Details",
    link: "/manager/table-details",
    to:"/manager/booking-management"
  },
  {
    icon: "/assets/icons/sidebar/dark/bookings.svg",
    title: "Bookings",
    caption: "/ Entry Details",
    link: "/manager/entry-details",
    to:"/manager/booking-management"
  },
  {
    icon: "/assets/icons/sidebar/dark/bookings.svg",
    title: "Bookings",
    caption: "/ Event Details",
    link: "/manager/event-details",
    to:"/manager/booking-management"
  },
  {
    icon: "/assets/icons/sidebar/dark/menu-management-dark.svg",
    title: "Menu Management",
    caption: "/ Smokes Category",
    link: "/manager/smokes-management",
  },
  {
    icon: "/assets/icons/sidebar/dark/menu-management-dark.svg",
    title: "Menu Management / Smoke Category",
    caption: "/ Add Smoke Category",
    link: "/manager/smokes-management/create-category",
    to: "/manager/smokes-management"
  },
  {
    icon: "/assets/icons/sidebar/dark/events.svg",
    title: "Events",
    caption: "",
    link: "/manager/events-management",
  },
  {
    icon: "/assets/icons/sidebar/dark/events.svg",
    title: "Events",
    caption: "/ Create",
    link: "/manager/add-event",
    to:"/manager/events-management"
  },
  {
    icon: "/assets/icons/sidebar/dark/events.svg",
    title: "Events",
    caption: "/ Edit",
    link: "/manager/edit-event",
    to:"/manager/events-management"
  },
  {
    icon: "/assets/icons/sidebar/dark/club.svg",
    title: "Club",
    caption: "/ All",
    link: "/manager/club-details",
  },
  {
    icon: "/assets/icons/sidebar/dark/bookings.svg",
    title: "Bookings",
    caption: "",
    link: "/manager/booking-management",
  },
  {
    icon: "/assets/icons/sidebar/dark/bookings.svg",
    title: "Bookings",
    caption: "/ Create Table Booking",
    link: "/manager/booking-management/create-table-booking",
    to: "/manager/booking-management"
  },
  {
    icon: "/assets/icons/sidebar/dark/bookings.svg",
    title: "Bookings",
    caption: "/ Create Entry Booking",
    link: "/manager/booking-management/create-entry-booking",
    to: "/manager/booking-management"
  },
  {
    icon: "/assets/icons/sidebar/dark/bookings.svg",
    title: "Bookings",
    caption: "/ Create Event Booking",
    link: "/manager/booking-management/create-event-booking",
    to: "/manager/booking-management"
  },
  {
    icon: "/assets/icons/sidebar/dark/club.svg",
    title: "Club",
    caption: "/ Edit Club",
    link: `/manager/edit-club-details/${localStorage.getItem('club_id')}`,
    to: "/manager/club-details"
  },
  {
    icon: "/assets/icons/sidebar/dark/close-booking-dark.svg",
    title: "Non-Operational Days",
    caption: "",
    link: "/manager/close-booking",
  },
  {
    icon: "/assets/icons/sidebar/dark/close-booking-dark.svg",
    title: "Non-Operational Days",
    caption: "/ Add Non-Operational Days",
    link: "/manager/create-close-booking",
    to: "/manager/close-booking"
  },
];
const Breadcrumb = ({path}) => {
  //const { pathname } = useLocation();
  return (
    <>
      {breadCrumbData.map((data, index) => {
        if (path === data.link) {
          return (
            <Link to={data.to} key={index}>
              <div className="d-flex align-items-center">
                {
                  data.icon && <div>
                    <img
                      src={data.icon}
                      alt="dashboard-icon"
                      width="15"
                      height="15"
                      className="d-block"
                    />
                  </div>
                }
                {data.title && <div className="ms-2 text-sm text-lg-md font-body font-semibold text-black">
                  {data.title}
                </div>}
                {data.caption && <div className="ms-2 text-xs text-lg-sm font-body font-regular text-black">
                  {data.caption}
                </div>}
              </div>
            </Link>
          );
        }
      })}
    </>
  );
};
export default Breadcrumb;