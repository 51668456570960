import React, { useState, useEffect } from 'react';
import ContentWrapper from "../ContentWrapper";
import { Row, Col } from "react-bootstrap";
import DateRange from "../DateRange";
function UsersGender({ setSelectedDateRange, genderStartDate, genderEndDate, userGender }) {
  const [state, setState] = useState({
    Female: 0,
    Male: 0,
    Other: 0,
  })

  useEffect(() => {
    userGender && userGender && setState((prevProps) => ({
      ...prevProps,
      Female: userGender.Female,
      Male: userGender.Male,
      Other: userGender.Other,
    }))
  }, [userGender]);
  const getDateRange = (start, end) => {
    setSelectedDateRange(start, end, "gender");
  }
  return (
    <div>
      <div className="d-flex justify-content-between font-body">
        <div>
          <h2 className="text-md text-xl-lg font-bold color-blue font-body mb-0">
            Registered Users
          </h2>
          <p className="text-xs text-xl-sm"> Based On Gender</p>
        </div>
        <div>
          <DateRange
            getDateRange={getDateRange}
            startDate={genderStartDate}
            endDate={genderEndDate}
          />
        </div>
      </div>
      <Row className="g-4">
        <Col xs={4}>
          <ContentWrapper additionalClass="p-5 h-100">
            <article>
              <h2 className="color-gray text-md">Male</h2>
              <p className="text-2xl font-bold color-blue mb-3">{state.Male}</p>
              <p className="color-gray mb-0 text-sm">
                No. of male users registered today
              </p>
            </article>
          </ContentWrapper>
        </Col>
        <Col xs={4}>
          <ContentWrapper additionalClass="p-5 h-100">
            <article>
              <h2 className="color-gray text-md">Female</h2>
              <p className="text-2xl font-bold color-blue mb-3">{state.Female}</p>
              <p className="color-gray mb-0 text-sm">
                No. of female Users registered today
              </p>
            </article>
          </ContentWrapper>
        </Col>
        <Col xs={4}>
          <ContentWrapper additionalClass="p-5 h-100">
            <article>
              <h2 className="color-gray text-md">Others</h2>
              <p className="text-2xl font-bold color-blue mb-3">{state.Other}</p>
              {/* <p className="color-gray mb-0 text-sm">
                No of event bookings today
              </p> */}
            </article>
          </ContentWrapper>
        </Col>
      </Row>
    </div>
  );
}

export default UsersGender;
