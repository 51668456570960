import { createPortal } from "react-dom";
import PushNotification from "./Components/Global/PushNotification";
import AppRoutes from "./routes/Routes";
import "./sass/style.scss";
import { Helmet } from "react-helmet";
import Logout from "./Components/Global/Popup/ConfirmModal";
import { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const contents = {
  title: "Password Updated",
  permission: "Your password was just changed. Please login with the new password.",
};
function App() {
  const [showModal, setShowModal] = useState(false);
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .then(registration => {
          registration.unregister().then((boolean) => {
          });
        })
        .catch(error => {
          console.error('Service Worker registration failed:', error);
        });
    });
  }
  useEffect(()=> {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        if(window.location.pathname!=="/" && !Boolean(localStorage.getItem('logout'))) setShowModal(true);
      }
    });
  }, [])
  
  return (
    <>
      <Helmet>
        <title>Party One - {localStorage.getItem("userType") === "manager" ? "Manager Panel" : "Admin Panel"}</title>
      </Helmet>
      <AppRoutes />
      {createPortal(
        <PushNotification />,
        document.body
      )}
      <Logout
        showModal={showModal}
        setShowModal={setShowModal}
        contents={contents}
        logout="true"
      />
    </>
  );
}

export default App;