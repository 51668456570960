import { Link, useLocation } from "react-router-dom";

const breadCrumbData = [
  {
    icon: "/assets/icons/sidebar/dark/dashboard-icon-dark.svg",
    title: "Dashboard",
    caption: "/ Overview",
    link: "/dashboard",
  },
  {
    icon: "/assets/icons/sidebar/dark/club.svg",
    title: "Clubs",
    caption: "/ All",
    link: "/clubs",
  },
  {
    icon: "/assets/icons/sidebar/dark/club.svg",
    title: "Clubs",
    caption: "/ Create Club",
    link: "/clubs/create-club",
    to:"/clubs"
  },
  {
    icon: "/assets/icons/sidebar/dark/events.svg",
    title: "Event",
    caption: "/ All",
    link: "/events",
  },
  {
    icon: "/assets/icons/sidebar/dark/events.svg",
    title: "Events",
    caption: "/ Create",
    link: "/events/create-event",
    to:"/events"
  },
  {
    icon: "/assets/icons/sidebar/dark/dashboard-icon-dark.svg",
    title: "Ads Manager",
    caption: "All",
    link: "/ads-manager",
  },
  {
    icon: "/assets/icons/sidebar/dark/membership-icon-dark.svg",
    title: "Memberships",
    caption: "/ All",
    link: "/memberships",
  },
  {
    icon: "/assets/icons/sidebar/dark/membership-icon-dark.svg",
    title: "Memberships",
    caption: "/ User details",
    link: "/user-details",
    to:"/memberships"
  },
  {
    icon: "/assets/icons/sidebar/dark/payment-icon-dark.svg",
    title: "Payments",
    caption: "All",
    link: "/payments",
  },
  {
    icon: "/assets/icons/sidebar/dark/location-icon-dark.svg",
    title: "Locations",
    caption: "All",
    link: "/locations",
  },
   {
    icon: "/assets/icons/sidebar/dark/club.svg",
    title: "Clubs",
    caption: "/ Edit Clubs",
    link: "/edit-club",
    to:"/clubs"
  },
  {
    icon: "/assets/icons/sidebar/dark/adds-icon-dark.svg",
    title: "Advertisement Manager",
    caption: "",
    link: "/advertisement-manager",
  },
  {
    icon: "/assets/icons/sidebar/dark/adds-icon-dark.svg",
    title: "Advertisement Manager",
    caption: "/ Edit",
    link: "/advertisement-manager/edit-advertisement",
    to:"/advertisement-manager"
  },
  {
    icon: "/assets/icons/sidebar/dark/adds-icon-dark.svg",
    title: "Advertisement Manager",
    caption: "/ Create",
    link: "/advertisement-manager/create-advertisement",
    to:"/advertisement-manager"
  },
  {
    icon: "/assets/icons/sidebar/dark/bookings.svg",
    title: "Bookings",
    caption: "/ overview",
    link: "/bookings",
    to:""
  },
  {
    icon: "/assets/icons/sidebar/dark/bookings.svg",
    title: "Bookings",
    caption: "/ Event Details",
    link: "/event-details",
    to:"/bookings"
  },
  {
    icon: "/assets/icons/sidebar/dark/bookings.svg",
    title: "Bookings",
    caption: "/ Entry Details",
    link: "/entry-details",
    to:"/bookings"
  },
  {
    icon: "/assets/icons/sidebar/dark/bookings.svg",
    title: "Bookings",
    caption: "/ Table Details",
    link: "/table-details",
    to:"/bookings"
  },
 
];
const Breadcrumb = () => {
  const { pathname } = useLocation();
  return (
    <>
      {breadCrumbData.map((data, index) => {
        if (pathname === data.link) {
          return (
            <Link to={data.to} key={index}>
            <div  className="d-flex align-items-center">
              {
                data.icon &&  <div>
                <img
                  src={data.icon}
                  alt="dashboard-icon"
                  width="15"
                  height="15"
                  className="d-block"
                />
              </div>
              }
             {data.title && <div className="ms-2 text-sm text-lg-md font-body font-semibold text-black">
                {data.title}
              </div> }
              {data.caption && <div className="ms-2 text-xs text-lg-sm font-body font-regular text-black">
              {data.caption}
              </div>}
            </div>
            </Link>
          );
        }
      })}
    </>
  );
};
export default Breadcrumb;