import { useState } from "react";
import { Tab, Nav, Tabs } from "react-bootstrap";
import Layout from "../../../Layout/LayoutManager/Layout";
import ContentWrapper from "../../../Components/Global/ContentWrapper";
import { useEffect } from "react";
import { collection, getDocs, query, where, orderBy } from "firebase/firestore";
import db from "../../../firebase";
import ManagerEventBookings from "../../../Components/Booking/ManagerEventBookings";
import ManagerEntryBookings from "../../../Components/Booking/ManagerEntryBookings";
import ManagerTableBookings from "../../../Components/Booking/ManagerTableBookings";
import { useSearchParams } from "react-router-dom";
import PrimaryButton from "../../../Components/Global/Buttons/PrimaryButton";
import Skeleton from "react-loading-skeleton";

const Bookings = () => {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab") ? searchParams.get("tab") : "table";
  const [selectedTab, setSelectedTab] = useState(tab);
  const [eventsBookings, setEventsBookings] = useState([])
  const [entryBooking, setEntryBookings] = useState([]);
  const [tableBookings, setTableBookings] = useState([])
  const [usersContactDetails, setUsersContactDetails] = useState({});
  const clubId = localStorage.getItem('club_id');
  const fetchAllUsersDetails = async () => {
    let obj = {};
    setUsersContactDetails({});
    const querySnapshot = await getDocs(query(collection(db, 'users')));
    querySnapshot.docs.forEach((doc) => {
      obj[doc.data().id] = [doc.data().contacts, doc.data().email]
    });
    setUsersContactDetails(obj);
  }

  const fetchEntryBookings = async () => {
    const q = query(collection(db, 'club_entry_bookings'), where("booking_type", "==", "club_entry_booking"), where("club_id", "==", clubId), orderBy("createdAt", "desc"));
    const querySnapshot = await getDocs(q);
    const entryBookingsData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setEntryBookings(entryBookingsData);
  };

  const fetchEventsBookings = async () => {
    const q = query(collection(db, 'event_entry_bookings'), where("club_id", "==", clubId), orderBy("createdAt", "desc"));
    const querySnapshot = await getDocs(q);
    const eventsBookingsData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setEventsBookings(eventsBookingsData);
  };

  const fetchTableBookings = async () => {
    const q = query(collection(db, 'club_entry_bookings'), where("booking_type", "==", "table_booking"), where("club_id", "==", clubId), orderBy("createdAt", "desc"));
    const querySnapshot = await getDocs(q);
    const tableBookingsData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setTableBookings(tableBookingsData);
  };
  useEffect(() => {
    fetchAllUsersDetails();
    fetchTableBookings();
    fetchEventsBookings();
    fetchEntryBookings();
  }, []);

  const tableBookingsPendingCount = tableBookings.filter(obj => obj.status === "Booking request pending").length;
  const entryBookingsPendingCount = entryBooking.filter(obj => obj.status === "Waiting for approval").length;
  const eventsBookingsPendingCount = eventsBookings.filter(obj => obj.status === "Waiting for approval").length;

  const RENDERCREATEBTN = () => {

    switch (selectedTab) {
      case 'table':
        return <PrimaryButton
          link="/manager/booking-management/create-table-booking"
          name="Create Table Booking"
          icon="bi-plus-circle"
        />
      case 'clubs':
        return <PrimaryButton
          link="/manager/booking-management/create-entry-booking"
          name="Create Entry Booking"
          icon="bi-plus-circle"
        />
      case 'events':
        return <PrimaryButton
          link="/manager/booking-management/create-event-booking"
          name="Create Event Booking"
          icon="bi-plus-circle"
        />
      default:
        break;
    }
  }

  return (
    <Layout path="/manager/booking-management">
      <ContentWrapper additionalClass="p-5">
        <div className="po-custom-tabs">
          <Tab.Container id="left-tabs-example" defaultActiveKey={selectedTab} onSelect={(k) => { console.log(k, 'fdddd'); setSelectedTab(k) }}>
            <div>
              <div className="border-bottom po-custom-tabs d-flex align-items-center justify-content-between px-5">
                <Nav variant="pills" className="flex-row pb-4">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="table"
                      className="d-flex justify-content-center align-items-center"
                    >
                      <div>Table Bookings</div>{" "}
                      {tableBookingsPendingCount ? (
                        <div
                          className="d-flex justify-content-center align-items-center ms-2 rounded-circle text-center text-white"
                          style={{
                            height: "30px",
                            width: "30px",
                            background:
                              "transparent linear-gradient(290deg, #DBA611 0%, #F7CE73 100%) 0% 0% no-repeat padding-box",
                          }}
                        >
                          <div className="text-xs" title="Pending">
                            {tableBookingsPendingCount}
                          </div>
                        </div>
                      ) : <Skeleton
                            inline={true} count={1}
                            height={30} width={30}
                            baseColor="#dedede" highlightColor="#cccccc"
                            duration={2} className="ms-2" circle
                          />
                        }

                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link
                      eventKey="events"
                      className="d-flex justify-content-center align-items-center"
                    >
                      <div>Event Bookings</div>{" "}
                      {tableBookingsPendingCount ? (
                        <div
                          className="d-flex justify-content-center align-items-center ms-2 rounded-circle text-center text-white"
                          style={{
                            height: "30px",
                            width: "30px",
                            background:
                              "transparent linear-gradient(290deg, #DBA611 0%, #F7CE73 100%) 0% 0% no-repeat padding-box",
                          }}
                        >
                          <div className="text-xs" title="Waiting for approval">
                            {eventsBookingsPendingCount}
                          </div>
                        </div>
                       ) : <Skeleton
                            inline={true} count={1}
                            height={30} width={30}
                            baseColor="#dedede" highlightColor="#cccccc"
                            duration={2} className="ms-2" circle
                          />
                      }
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link
                      eventKey="clubs"
                      className="d-flex justify-content-center align-items-center"
                    >
                      <div>Entry Bookings</div>{" "}
                      {tableBookingsPendingCount ? (
                        <div
                          className="d-flex justify-content-center align-items-center ms-2 rounded-circle text-center text-white"
                          style={{
                            height: "30px",
                            width: "30px",
                            background:
                              "transparent linear-gradient(290deg, #DBA611 0%, #F7CE73 100%) 0% 0% no-repeat padding-box",
                          }}
                        >
                          <div className="text-xs" title="Waiting for approval">
                            {tableBookingsPendingCount}
                          </div>
                        </div>
                       ) : <Skeleton
                            inline={true} count={1}
                            height={30} width={30}
                            baseColor="#dedede" highlightColor="#cccccc"
                            duration={2} className="ms-2" circle
                          />
                      }
                    </Nav.Link>
                  </Nav.Item>

                </Nav>
                <div className="mb-5"><RENDERCREATEBTN /></div>
              </div>
            </div>
            <div>
              <Tab.Content>
                <Tab.Pane eventKey="table">
                  <ManagerTableBookings
                    usersContactDetails={usersContactDetails}
                    clubId={clubId}
                  />
                </Tab.Pane>

                <Tab.Pane eventKey="clubs">
                  <ManagerEntryBookings
                    usersContactDetails={usersContactDetails}
                    clubId={clubId}
                  />
                </Tab.Pane>

                <Tab.Pane eventKey="events">
                  <ManagerEventBookings
                    usersContactDetails={usersContactDetails}
                    clubId={clubId}
                  />
                </Tab.Pane>


              </Tab.Content>
            </div>
          </Tab.Container>
          {/* <Tabs
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3 text-dark"
          >
            <Tab eventKey="table" title="Table Bookings">
              <h2 className="text-xl font-body font-semibold pt-5 pb-3">
                <ManagerTableBookings
                  // tables={tableBookings}
                  clubId={clubId}
                  
                />
              </h2>
            </Tab>
            <Tab eventKey="events" title="Events Bookings">
              <h2 className="text-xl font-body font-semibold pt-5 pb-3">
                <ManagerEventBookings
                  //events={eventsBookings} 
                  clubId={clubId}
                />
              </h2>
            </Tab>
            <Tab eventKey="clubs" title=" Entry Bookings">
              <h2 className="text-xl font-body font-semibold pt-5 pb-3">
                <ManagerEntryBookings
                  //entries={entryBooking} 
                  clubId={clubId}
                />
              </h2>
            </Tab>

          </Tabs> */}
        </div>
      </ContentWrapper>
    </Layout>
  );
};
export default Bookings;