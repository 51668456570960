import { Button, Col, Row } from "react-bootstrap";
import ContentWrapper from "../../Components/Global/ContentWrapper";
import Layout from "../../Layout/Layout";
import { useState } from "react";
import { useForm } from "react-hook-form";
import SuccessButton from "../../Components/Global/Buttons/SuccessButton";
import ActionIcon from "../../Components/Global/Buttons/ActionIcons";

import axios from "axios";
import { collection, setDoc } from "firebase/firestore";
import db from "../../firebase";
const CreatePlan = () => {
  const [flatPreviewImage, setFlatPreviewImage] = useState("");
  const [flatImage, setFlatImage] = useState({
    fromBackend: true,
    ImageUrl: "",
  });
  const [benefits, setBenefits] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [benefit, setBenefit] = useState("");
  const [planImage, setPlanImage] = useState({
    fromBackend: true,
    ImageUrl: "",
  });
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    const image = await uploadImage(planImage.ImageUrl);
    const payload = {
      display_image: image,
      name: data.name,
      benefits: benefits,
    };
    console.log(payload, "payload");
    await setDoc(collection(db, "memberships"));
  };

  // Uploading Image
  const uploadImage = async (image) => {
    let form = new FormData();
    form.append("images", image);
    let responseImage = await axios
      .post(
        `${process.env.REACT_APP_MULTIPLEIMAGES_POSTING_URL}/uploads/uploadImages`,
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((resImage) => {
        return resImage && resImage.data.url[0];
      })
      .catch((error) => console.log(error, "err"));
    return responseImage;
  };

  ///Handle Image
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
      setPlanImage({
        ...planImage,
        ImageUrl: file,
        fromBackend: false,
      });
      setError("display_image", null);
    }
  };

  //Handle flat Image
  const handleFlatImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setFlatPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
      setFlatImage({
        ...flatImage,
        ImageUrl: file,
        fromBackend: false,
      });
      setError("flat_image", null);
    }
  };

  // Function to add benifit
  const addBenefit = () => {
    const newBenefit = benefit;
    if (newBenefit !== "") {
      setBenefits([...benefits, newBenefit]);
      setValue("benifit", "");
    }
  };

  // Delete benifit
  const deleteBenefit = (index) => {
    setBenefits(benefits.filter((_, i) => i !== index));
  };

  return (
    <Layout>
      <ContentWrapper additionalClass="p-5">
        <h2 className="color-heading font-body font-semibold text-lg text-lg-2xl pb-6 mb-0">
          Create a Plan
        </h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row className="g-4">

            {/* Image Upload */}
            <Col xs={6}>
              <label htmlFor="planImage">
                Plan Image
                <span style={{ color: "red", fontSize: "20px" }}>*</span>
              </label>
              <div
                style={{
                  height: "200px",
                  width: "350px",
                  backgroundImage: `url(${previewImage ? previewImage : null})`,
                  border: `${previewImage ? "0px" : "1px"} dashed #D2D6DA`,
                }}
                className="d-flex align-items-center justify-content-center background-position-center-top background-repeat-norepeat background-size-cover rounded overflow-hidden position-relative"
              >
                {previewImage ? (
                  <div className="position-absolute end-0 top-0">
                    <ActionIcon
                      onClick={() => {
                        setPreviewImage(null);
                        setPlanImage({ ...planImage, ImageUrl: "" });
                      }}
                      additionalClass="bi bi-x-circle-fill text-3xl color-red me-2"
                    />
                  </div>
                ) : null}

                {previewImage ? null : (
                  <div>
                    <input
                      type="file"
                      id="display_image"
                      {...register("display_image", {
                        required: "Image is required",
                      })}
                      onChange={handleImageChange}
                      className="opacity-0 position-absolute start-0 end-0 top-0 bottom-0"
                    />

                    <div className="text-center">
                      <i className="bi bi-plus-circle-fill text-5xl color-lightgreen d-block"></i>
                      <p className="text-md font-body text-center  ">
                        Upload your Image here.
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <p className="text-danger font-body text-sm">
                {errors.display_image?.message}
              </p>
            </Col>

            {/* Flat Image Upload */}
            <Col xs={6}>
              <label htmlFor="planImage">
                Flat Image
                <span style={{ color: "red", fontSize: "20px" }}>*</span>
              </label>
              <div
                style={{
                  height: "200px",
                  width: "350px",
                  backgroundImage: `url(${flatPreviewImage ? flatPreviewImage : null
                    })`,
                  border: `${flatPreviewImage ? "0px" : "1px"} dashed #D2D6DA`,
                }}
                className="d-flex align-items-center justify-content-center background-position-center-top background-repeat-norepeat background-size-cover rounded overflow-hidden position-relative"
              >
                {flatPreviewImage ? (
                  <div className="position-absolute end-0 top-0">
                    <ActionIcon
                      onClick={() => {
                        setFlatPreviewImage(null);
                        setFlatImage({ ...flatImage, ImageUrl: "" });
                      }}
                      additionalClass="bi bi-x-circle-fill text-3xl color-red me-2"
                    />
                  </div>
                ) : null}

                {flatPreviewImage ? null : (
                  <div>
                    <input
                      type="file"
                      id="flat_image"
                      {...register("display_image", {
                        required: "Image is required",
                      })}
                      onChange={handleFlatImageChange}
                      className="opacity-0 position-absolute start-0 end-0 top-0 bottom-0"
                    />

                    <div className="text-center">
                      <i className="bi bi-plus-circle-fill text-5xl color-lightgreen d-block"></i>
                      <p className="text-md font-body text-center  ">
                        Upload your Image here.
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <p className="text-danger font-body text-sm">
                {errors.flat_image?.message}
              </p>
            </Col>

            {/* Plan Name */}
            <Col md={6}>
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                className="po-edit-input"
                {...register("name", { required: "Please enter plan name" })}
              />
              {errors.name && (
                <span className="text-sm text-danger d-block">
                  {errors.name.message}
                </span>
              )}
            </Col>

            {/* Benefits */}
            <Col md={6}>
              <div>
                <label htmlFor="benefit">Benefit:</label>
                <div className="d-flex">
                  <input
                    className="po-edit-input"
                    type="text"
                    id="benefit"
                    {...register("benefit", {
                      required: "Please enter a benefit",
                    })}
                    onChange={(e) => {
                      setBenefit(e.target.value);
                    }}
                  />
                  <Button
                    variant="outline-success"
                    className="my-3 radius-105 text-nowrap ms-3"
                    type="button"
                    onClick={(e) => {
                      addBenefit();
                    }}
                  >
                    Add Benefit
                  </Button>
                </div>
              </div>
            </Col>

            {/* Benefits Lists */}
            <Col md={12}>
              {benefits.length > 0 && (
                <div>
                  <label className="bold my-2 text-lg">Benefits List:</label>
                  <ul className="list-group">
                    {benefits.map((benefit, index) => (
                      <li
                        key={index}
                        className="d-flex justify-content-between align-items-center list-group-item"
                      >
                        <div className="my-1">{benefit}</div>
                        <Button
                          variant="outline-danger "
                          className=" radius-105"
                          type="button"
                          onClick={() => deleteBenefit(index)}
                        >
                          Delete
                        </Button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {errors.benefits && <span>{errors.benefits.message}</span>}
            </Col>

            {/* Level */}
            <Col xs={6}>
              <label>Level</label>
              <div>
                <input
                  type="number"
                  className="po-edit-input"
                  {...register("level", {
                    required: "Please enter a level",
                  })}
                />
              </div>
              {errors.level && (
                <span className="text-danger">{errors.level.message}</span>
              )}
            </Col>

            {/* <Col xs={12}> */}
            {/* <button type="submit">Submit</button> */}
            {/* <SuccessButton type="submit" name="Create Plan" icon="bi-plus-circle"
                additionalClass="mt-5" /> */}

            {/* </Col> */}
          </Row>

          {/* Cancelation hours */}
          <div className="mt-5">
            <h2 className="color-heading font-body font-semibold text-lg text-lg-2xl pb-6 mb-0">
              Membership Benifits
            </h2>
            <Row>
              <Col xs={6}>
                <label>Cancelation hours</label>
                <div>
                  <input
                    type="number"
                    className="po-edit-input"
                    {...register("cancelation_hours", {
                      required: "Please enter a cancelation hour",
                    })}
                  />
                </div>
                {errors.cancelation_hours && (
                  <span className="text-danger">
                    {errors.cancelation_hours.message}
                  </span>
                )}
              </Col>
            </Row>
          </div>

          {/* Buttons */}
          <div className="d-flex justify-content-end">
            <Button
              type="submit"
              className="mt-5"
              name="Create Plan"
              icon="bi-plus-circle"
              variant="success"
            >
              Create Plan
            </Button>
          </div>
        </form>
      </ContentWrapper>
    </Layout>
  );
};
export default CreatePlan;
