import React, { useState, useEffect } from 'react';
import { Toaster, toast } from 'react-hot-toast';
import db, { requestPermission, onForegroundMessage } from '../../firebase';
import PrimaryButton from './Buttons/PrimaryButton';
import { doc, updateDoc } from 'firebase/firestore';
import ActionIcon from './Buttons/ActionIcons';

const membershipsLight = "/assets/icons/sidebar/light/new/vip-plan.svg";
const clubLight = "/assets/icons/sidebar/light/new/clubs.svg";
const eventLight = "/assets/icons/sidebar/light/new/events.svg";
const userLight = "/assets/icons/sidebar/light/new/users.svg";
let toastId;

function PushNotification() {
  const [notification, setNotification] = useState({ title: '', body: '', url: "" });

  useEffect(() => {
    requestPermission();
    const unsubscribe = onForegroundMessage().then((payload) => {
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
        url : payload.data.url,
        notification_id: payload.data.id
      });
    })
    .catch(err=> console.log(err, 'error'))
    if(notification.title!=="") {displayNotification()}
    return () => unsubscribe.catch((err) => console.log('failed: ', err));
  }, [notification.title]);
  
  const displayNotification = () => {
    toastId = toast(<ToastDisplay/>, {
      duration: 5000, 
      position: 'top-right',
      className: 'mt-3 mx-5 bg-dark text-white py-4 px-3',
      style: {borderRadius: "20px", minWidth: "500px"}
    });
    setTimeout(()=> {
      setNotification({ title: '', body: '', url: "" })
    }, 5000)
  }
  const handlePageRedirection = async () => {
    await updateDoc(doc(db, "notifications", notification.notification_id), {
      read_status: true
    });
    if (notification && notification.url) {
      if(notification.url.includes('table_booking')) window.open('/bookings/'+notification.url.replace('table_booking', "table-details"), '_blank');
      if(notification.url.includes('club_entry_booking')) window.open('/bookings/'+notification.url.replace('club_entry_booking', "entry-details"), "_blank");
      if(notification.url.includes('event_entry_booking')) window.open('/bookings/'+notification.url.replace('event_entry_booking', "event-details"), "_blank");
      if(notification.url.includes('membership_requests')) window.open('/memberships/'+notification.url.replace('membership_requests', "membership-request", '_blank'));
      if(notification.url.includes('user-profile')) window.open(notification.url.replace('user-profile/', '/users/user-details/'), '_blank');
      if(notification.url.includes('users')) window.open(notification.url.replace('users/', '/users/user-details/'), '_blank');
    }
  }

  function ToastDisplay() {
    return (
      <div className='d-flex gap-4 align-items-start w-100 position-relative' id={toastId}>
        <ActionIcon
          onClick={(e)=>toast.dismiss(e.target.parentNode.id)}
          additionalClass="bi bi-x-circle-fill text-4xl  color-yellow1 me-2 position-absolute end-0 d-flex align-items-center"
        />
        {!notification.url.includes('users') ? <img src={notification && notification.url && notification.url.includes('membership_requests') ? membershipsLight
                  : notification.url.includes('event_entry_booking') ? eventLight
                  : (notification.url.includes('club_entry_booking') || notification.url.includes('table_booking')) ? clubLight 
                  : notification.url.includes('user-profile') ? userLight : "" } 
            alt="Notification Icon"
            className='mt-2'/> : <i className="bi bi-person-add text-md" style={{color:"#D3BB8A"}}></i>}
        <div>
          <p className='text-md text-xl-xl' style={{width: "85%"}}>{notification?.title}</p>
          <p style={{color:"#C0C8D3"}} className='text-sm text-xl-md'>{notification?.body}</p>
          <PrimaryButton onClick={handlePageRedirection} name="Review Now" />
        </div>
      </div>
    );
  };

  return (
    <Toaster
      containerClassName="custom-toast-container"
      containerStyle={{
        overflowY: "auto",
        zIndex: 1000
      }}
    />
  );
}

export default PushNotification;