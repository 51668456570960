import ContentWrapper from "../../Components/Global/ContentWrapper";
import Layout from "../../Layout/Layout";
import { Row, Col, Button, Table } from "react-bootstrap";
import { createRef, useCallback, useState } from "react";
import { collection, doc, endAt, getDocs, orderBy, query, startAt, where } from "firebase/firestore";
import db from "../../firebase";
import OutlinedButton from "../../Components/Global/Buttons/OutlinedButton";
import SuccessButton from "../../Components/Global/Buttons/SuccessButton";
import ActionIcon from "../../Components/Global/Buttons/ActionIcons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Toaster from "../../Components/Global/Toast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useRef } from "react";

const CreateTableBooking = () => {

  const selectedDayRef = useRef("");
  const clubListRef = createRef();
  const userListRef = createRef();
  const searchInputRef = useRef("");
  const [inputLoader, setInputLoader] = useState({
    club: false,
    user: false
  });
  const [active, setActive] = useState(0);
  const searchClubIdRef = useRef("");
  const searchUserIdRef = useRef("");
  const [clubs, setClubs] = useState([]);
  const [users, setUsers] = useState([]);
  const [tableDetails, setTableDetails] = useState([]) // fetched from clubs
  const [nonOperationalDays, setNonOperationalDays] = useState([]);
  const navigate = useNavigate();
  const [category, setCategory] = useState({
    drinkDropdown: "",
    smokeDropdown: ""
  })
  const [preferredProducts, setPreferredProducts] = useState({
    drink_category: [],
    smoke_category: []
  })
  const [schedule, setSchedule] = useState({
    clubSchedule: null,
    timeZone: ""
  });
  const [selectedPreferredProducts, setSelectedPreferredProducts] = useState({
    category: "",
    category_name: "",
    brand_name: "",
    product_type: "",
    errors: {
      brand_name: "",
      category_name: ""
    }
  })
  const [productBrands, setProductBrands] = useState([]);
  const [showToaster, setShowToaster] = useState(false);
  const [toasterDetails, setToasterDetails] = useState({
    title: "",
    bodyText: "",
    bg: "",
    statusIcon: "",
  });
  const token = localStorage.getItem('authenticate');
  const [showClubList, setShowClubList] = useState(false);
  const [showUserList, setShowUserList] = useState(false);
  const [timeSlots, setTimeSlots] = useState([]);
  const [guestDetails, setGuestDetails] = useState({
    guest_name: "",
    gender: "",
    age: "",
    errors: {
      guest_name: "",
      gender: "",
      age: ""
    }
  })
  const [state, setState] = useState({
    club_name: "",
    club_id: "",
    time_slots: [],
    user_name: "",
    user_id: "",
    tables: [],   // will use for submit form
    preferred_products: [],
    people: [],
    booking_date: "",
    expected_arrival_time: "",
    errors: {
      club_name: "",
      user_name: "",
      people: "",
      booking_date: "",
      expected_arrival_time: "",
    }
  });
  const [toggleProducts, setToggleProducts] = useState(true);

  const keyDownHandler = event => {
    if (showClubList) {
      if (event.keyCode === 38 && active > 0) {
        let tempActive = active - 1;
        setActive(tempActive);
        clubListRef.current.scrollTop -= document.getElementById(`club-${active}`).offsetHeight;
      }
      if (event.keyCode === 40 && active < clubs.length - 1) {
        let nextTemp = active + 1;
        setActive(nextTemp);
        clubListRef.current.scrollTop += document.getElementById(`club-${active}`).offsetHeight;
      }
    } else {
      if (event.keyCode === 38 && active > 0) {
        let tempActive = active - 1;
        setActive(tempActive);
        userListRef.current.scrollTop -= document.getElementById(`user-${active}`).offsetHeight;
      }
      if (event.keyCode === 40 && active < users.length - 1) {
        let nextTemp = active + 1;
        setActive(nextTemp);
        userListRef.current.scrollTop += document.getElementById(`user-${active}`).offsetHeight;
      }
    }
  };
  const searchInputHandler = (e) => {
    const { name, value } = e.target;
    searchInputRef.current = value;
    if (searchInputRef.current !== "") {
      if (name === "club_name") {
        searchClubIdRef.current = "";
        setState(prev => ({
          ...prev,
          [name]: searchInputRef.current,
          club_id: "",
          time_slots: [],
          booking_date: "",
          expected_arrival_time: "",
          errors: {
            ...state.errors,
            [name]: ""
          }
        }))
        setInputLoader(prev => ({
          ...prev,
          club: true
        }));
        fetchSearchedData(searchInputRef.current, "club_name");
        setUsers([]);
      } else {
        searchUserIdRef.current = "";
        setInputLoader(prev => ({
          ...prev,
          user: true
        }));
        setState(prev => ({
          ...prev,
          [name]: searchInputRef.current,
          people: []
        }))
        if (value === "") {
          setState(prev => ({
            ...prev,
            [name]: ""
          }))
          setGuestDetails([]);
        }
        fetchSearchedData(searchInputRef.current, 'user_name');
        setClubs([]);
      }
    }
    else {
      setClubs([]);
      setUsers([]);
      setState(prev => ({
        ...prev,
        [name]: searchInputRef.current,
        [name === "club_name" ? "club_id" : "user_id"]: "",
        time_slots: [],
        booking_date: "",
        expected_arrival_time: "",
        errors: {
          ...state.errors,
          [name]: ""
        }
      }))
      setInputLoader({
        club: false,
        user: false
      })
    }
  }
  const fetchSearchedData = useCallback(
    debounce((val, searchField) => {
      if (!searchInputRef.current) return;
      if (searchField === "club_name") {
        val.length !== 0 &&
          getDocs(query(collection(db, "clubs"), where("status", "==", "Active"),
            orderBy("name_lower_case"),
            startAt(val.replace(/\s+/g, ' ').trim().toLowerCase()),
            endAt(`${val.replace(/\s+/g, ' ').trim().toLowerCase()}\uf8ff`)))
            .then((docSnapshots) => {
              setClubs([]);
              setShowUserList(false);
              setInputLoader(prev => ({
                ...prev,
                club: false
              }));
              setShowClubList(true);
              docSnapshots.forEach((docs) => {
                if (docs.data().latitude && docs.data().longitude) {
                  setClubs((prev) => [...prev, docs.data()]);
                }
              });
            })
      } else {
        axios.get(`${process.env.REACT_APP_API_URL}/user/v2/search?search_string=${encodeURIComponent(val)}&active=${true}&limit=${20}&offset=${0}`)
          .then((response) => {
            setShowClubList(false);
            setShowUserList(true);
            setUsers(response.data.result.filter(item => item.active_membership_id));
            setInputLoader(prev => ({
              ...prev,
              user: false
            }));
          }).catch((err) => {
            console.log(err, 'err');
          })
      }
    }, 1000), []);
  const onInputBlurHandler = () => {
    setTimeout(() => {
      setShowClubList(false);
      setActive(0);
      if (!searchClubIdRef.current) {
        setState(prev => ({
          ...prev,
          errors: {
            ...state.errors,
            club_name: "Please select club name"
          }
        }))
      } else {
        setState(prev => ({
          ...prev,
          errors: {
            ...state.errors,
            club_name: ""
          }
        }))
      }
    }, 300);
  };
  const onUserInputBlurHandler = () => {
    setTimeout(() => {
      setActive(0);
      setShowUserList(false);
      if (!searchUserIdRef.current) {
        setState(prev => ({
          ...prev,
          errors: {
            ...state.errors,
            user_name: "Please select user name"
          }
        }))
      } else {
        setState(prev => ({
          ...prev,
          errors: {
            ...state.errors,
            user_name: ""
          }
        }))
      }
    }, 300)
  }

  function debounce(fn, delay) {
    let timer;
    return function () {
      let context = this,
        args = arguments;
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(context, args);
      }, delay);
    };
  }
  const onSelectSearchedInput = (id, name) => {
    if (name === "user_name") {
      let selectedUserDetailsFound = users.find(item => item.id === id)
      searchUserIdRef.current = selectedUserDetailsFound.id;
      setState(prev => ({
        ...prev,
        user_name: selectedUserDetailsFound.name,
        user_id: selectedUserDetailsFound.id,
        errors: {
          ...state.errors,
          user_name: ""
        }
      }))
      return;
    }
    setState(prev => ({
      ...prev,
      tables: []
    }))
    setTableDetails([]);
    let selectedClubDetailsFound = clubs.find(item => item.id === id)
    setState(prev => ({
      ...prev,
      club_name: selectedClubDetailsFound.name,
      club_id: selectedClubDetailsFound.id,
      booking_date: "",
      time_slots: selectedClubDetailsFound.slots,
      errors: {
        ...state.errors,
        booking_date: "",
        club_name: ""
      }
    }))
    setTableDetails(selectedClubDetailsFound.sitting_tables);
    setTimeSlots(selectedClubDetailsFound.slots);
    setSchedule({
      clubSchedule: selectedClubDetailsFound.schedule,
      timeZone: selectedClubDetailsFound.time_zone
    });
    searchClubIdRef.current = selectedClubDetailsFound.id;
    setSchedule({
      clubSchedule: selectedClubDetailsFound.schedule,
      timeZone: selectedClubDetailsFound.time_zone
    });
    const selectedClubDoc = doc(collection(db, "clubs"), selectedClubDetailsFound.id);
    const subCollectionClosedBookings = collection(selectedClubDoc, "closed_bookings");
    const subCollectionDrink = collection(selectedClubDoc, "drink_category");
    const subCollectionSmoke = collection(selectedClubDoc, "smoke_category");
    getDocs(subCollectionClosedBookings)
      .then(response => {
        setNonOperationalDays([...response.docs.map(item => item.data().date)])
      })
    getDocs(subCollectionDrink)
      .then(response => {
        setPreferredProducts(prev => ({
          ...prev,
          drink_category: [...response.docs.map(item => item.data())]
        }))
      })
    getDocs(subCollectionSmoke)
      .then(response => {
        setPreferredProducts(prev => ({
          ...prev,
          smoke_category: [...response.docs.map(item => item.data())]
        }))
      })
  };

  const formValidation = () => {
    const errors = state.errors;
    let validate = true;
    if (state.club_id === "") {
      errors.club_name = "Please select club name";
      validate = false;
    }
    if (state.user_name === "") {
      errors.user_name = "Please select user name";
      validate = false;
    }
    if (state.booking_date === "") {
      errors.booking_date = "Please select booking date";
      validate = false;
    }
    if (state.expected_arrival_time === "") {
      errors.expected_arrival_time = "Please select entry time slot";
      validate = false;
    }
    setState(prev => ({
      ...prev,
      errors
    }))
    return validate
  }

  const handleFormSubmit = (e) => {
    e.preventDefault();
    let todayDate = moment(new Date())
    if (document.getElementById('club_name') === document.activeElement) {
      onSelectSearchedInput(clubs[active].id);
      onInputBlurHandler();
      return;
    }
    if (document.getElementById('user_name') === document.activeElement) {
      onSelectSearchedInput(users[active].id, 'user_name');
      onUserInputBlurHandler();
      return;
    }
    let validateForm = formValidation();
    if (validateForm) {
      setShowToaster(true);
      setGuestDetails(prev => ({
        ...prev,
        guest_name: "",
        gender: "",
        age: "",
        errors: {
          guest_name: "",
          gender: "",
          age: ""
        }
      }))
      setSelectedPreferredProducts({
        category: "",
        category_name: "",
        brand_name: "",
        product_type: "",
        errors: {
          category_name: "",
          brand_name: ""
        }
      })
      setCategory({
        drinkDropdown: "",
        smokeDropdown: ""
      })
      setToasterDetails((prev) => ({
        ...prev,
        title: "Creating...",
        bodyText: "Creating Club Table Booking",
        bg: "warning",
        statusIcon: (
          <div className="spinner-border text-warning me-2" role="status"></div>
        ),
      }));
      delete state.time_slots;
      let obj = {
        ...state,
        people: [...state.people.map((obj, index) => obj.name !== "" ? obj : { ...obj, name: `Guest ${index + 1}` })],
        booking_type: "table_booking",
        attendee_count: state.people.length,
        offset_in_min: moment(state.booking_date).diff(todayDate, "minutes")
      }
      delete obj.user_name;
      delete obj.errors;
      let config = {
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/booking/v2/clubBooking`,
        headers: {
          'developement': 'true',
          'token': token,
          'uid': obj.user_id,
          'Content-Type': 'application/json'
        },
        data: obj
      };
      axios.request(config)
        .then((response) => {
          if (response.data.success) {
            setToasterDetails((prev) => ({
              ...prev,
              title: "Success",
              bodyText: "Club Table Booking Details are added successfully",
              bg: "success",
              statusIcon: (
                <i className="bi bi-check text-success text-3xl me-2"></i>
              ),
            }));
            setTimeout(() => {
              navigate(`/bookings?tab=table`, { replace: true });
            }, 2000);
          }
          else {
            setToasterDetails((prev) => ({
              ...prev,
              title: "Error",
              bodyText: response.data.message,
              bg: "danger",
              statusIcon: (
                <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
              ),
            }));
            setTimeout(() => {
              setShowToaster(false);
            }, 2000);
          }
          setState(prev => ({
            ...prev,
            club_name: "",
            club_id: "",
            user_name: "",
            user_id: "",
            // tables: [],   // will use for submit form
            // amount: "",
            people: [],
            booking_date: "",
            expected_arrival_time: "",
          }))
          setGuestDetails(prev => ({
            ...prev,
            guest_name: "",
            gender: "",
            age: ""
          }))
          setTableDetails([]);
        })
        .catch((error) => {
          setToasterDetails((prev) => ({
            ...prev,
            title: "Error",
            bodyText: "Something went wrong, Please try again",
            bg: "danger",
            statusIcon: (
              <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
            ),
          }));
          setTimeout(() => {
            setShowToaster(false);
          }, 2000);
        });
      console.log(obj, "Objjjjjext")
    }
    else {
      console.log("Please fill all the required fields");
    }
  };

  const handleDateChange = (date) => {
    if (!state.time_slots.length && selectedDayRef.current !== moment(date).format('dddd')) {
      const selectedDateSchedules = schedule.clubSchedule[moment(date).format('dddd')];
      setTimeSlots([]);
      selectedDayRef.current = moment(date).format('dddd');
      for (let time = selectedDateSchedules["from_time"]; time < (selectedDateSchedules["from_time"] < selectedDateSchedules["to_time"] ? selectedDateSchedules["to_time"] : 1440 + selectedDateSchedules["to_time"]); time += 30) {
        const formattedToTime = moment({
          hours: moment.duration(time, "minutes").hours(),
          minute: moment.duration(time, "minutes").minutes(),
        }).format("h:mm A");
        setTimeSlots(prev => [...prev, { slot_time: formattedToTime, zone: schedule.timeZone }])
      }
    }
    if (date) {
      setState(prev => ({
        ...prev,
        booking_date: date.setHours(5, 30),
        errors: {
          ...state.errors,
          booking_date: ""
        }
      }))
    }
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setState(prev => ({
      ...prev,
      [name]: value,
    }))
    setState(prev => ({
      ...prev,
      errors: {
        ...state.errors,
        [name]: ""
      }
    }))
  }

  const handleGuestDetailsChange = (event) => {
    const { name, value } = event.target;
    setGuestDetails(prev => ({
      ...prev,
      [name]: value,
      errors: {
        ...guestDetails.errors,
        [name]: ""
      }
    }))
    if (name === "age") {
      if (!value) {
        setGuestDetails(prev => ({
          ...prev,
          errors: {
            ...guestDetails.errors,
            age: "Please enter age"
          }
        }))
      }
      else if (value < 21) {
        setGuestDetails(prev => ({
          ...prev,
          errors: {
            ...guestDetails.errors,
            age: "Age must be at least 21 years"
          }
        }))
      }
      else if (value > 99) {
        setGuestDetails(prev => ({
          ...prev,
          errors: {
            ...guestDetails.errors,
            age: "Age must be below 100 years"
          }
        }))
      }
      else {
        setGuestDetails(prev => ({
          ...prev,
          errors: {
            ...guestDetails.errors,
            age: ""
          }
        }))
      }
    }
  };
  const handleGenderChange = (event) => {
    setGuestDetails(prev => ({
      ...prev,
      gender: event.target.value,
      errors: {
        ...guestDetails.errors,
        gender: ""
      }
    }))
  };
  const validateGuestDetailsFunc = () => {
    let validatedDetails = true;
    const errors = guestDetails.errors;
    if (guestDetails.gender === "") {
      errors.gender = "Please select gender"
      validatedDetails = false;
    }
    if (!guestDetails.age) {
      errors.age = "Please enter age"
      validatedDetails = false;
    }
    if (guestDetails.errors.age) {
      errors.age = "Please enter a valid age"
      validatedDetails = false;
    }
    setGuestDetails(prev => ({
      ...prev,
      errors: errors
    }))
    if (errors.gender || errors.age) validatedDetails = false;
    return validatedDetails;
  }
  const addingGuestDetails = () => {
    const validateGuestDetails = validateGuestDetailsFunc()
    if (validateGuestDetails) {
      let guest = {
        name: guestDetails.guest_name ? guestDetails.guest_name : "",
        gender: guestDetails.gender,
        age: guestDetails.age
      }
      setState(prev => ({
        ...prev,
        people: [...state.people, guest]
      }))
      setGuestDetails(prev => ({
        ...prev,
        guest_name: "",
        gender: "",
        age: ""
      }))
    }
  }
  const handleTableDetailsChange = (event, selectedTableDetails) => {
    const isChecked = event.target.checked;
    if (!state.tables.length) {
      setToggleProducts(true);
    }
    // Update the selectedOptions array based on the checkbox change
    let tablePayload = {
      table_type: selectedTableDetails.name,
      count: selectedTableDetails.seater_count,
      minimum_spend: selectedTableDetails.minimum_spent
    }
    if (isChecked) {
      setState(prev => ({
        ...prev,
        tables: [...state.tables, tablePayload]
      }))
    } else {
      const filteredOptions = state.tables.filter((option) => option.table_type !== tablePayload.table_type);
      setState(prev => ({
        ...prev,
        tables: filteredOptions
      }))
    }
  };

  const handleRemoveGuest = (index) => {
    const guestList = [...state.people];
    const remainGuest = guestList.filter((item, i) => i !== index);
    setState(prev => ({
      ...prev,
      people: [...remainGuest]
    }))
  }
  const handleRemovePreferredProducts = (index) => {
    const productList = [...state.preferred_products];
    const remainProducts = productList.filter((item, i) => i !== index);
    // const remainProducts = productList.splice(index, 1);
    setState(prev => ({
      ...prev,
      preferred_products: [...remainProducts]
    }))
  }
  const handlePreferredDrinkProducts = (e) => {
    setProductBrands([])
    if (e.target.checked) {
      setToggleProducts(false)
    }
    else {
      setToggleProducts(true)
    }
  }
  const onChangeProductCategory = (e, product) => {
    const { value, name } = e.target;
    if (!value.trim()) {
      setProductBrands([]);
    }
    setCategory(prev => ({
      ...prev,
      [name]: value
    }))
    if (product === "drink") {
      const filteredDrinkBrands = preferredProducts.drink_category.filter(item => item.id === e.target.options[e.target.selectedIndex].id);
      if (filteredDrinkBrands && filteredDrinkBrands[0]) {
        setSelectedPreferredProducts(prev => ({
          ...prev,
          category: filteredDrinkBrands[0].id,
          category_name: filteredDrinkBrands[0].name,
          product_type: "drink",
          errors: {
            ...selectedPreferredProducts.errors,
            category_name: ""
          }
        }))
        setProductBrands(filteredDrinkBrands[0].brands)
      }
    }
    else {
      const filteredSmokeBrands = preferredProducts.smoke_category.filter(item => item.id === e.target.options[e.target.selectedIndex].id);
      if (filteredSmokeBrands && filteredSmokeBrands[0]) {
        setSelectedPreferredProducts(prev => ({
          ...prev,
          category: filteredSmokeBrands[0].id,
          category_name: filteredSmokeBrands[0].name,
          product_type: "smoke",
          errors: {
            ...selectedPreferredProducts.errors,
            category_name: ""
          }
        }))
        setProductBrands(filteredSmokeBrands[0].brands)
      }
    }

  }

  const onChangeProductBrands = (e) => {
    setSelectedPreferredProducts(prev => ({
      ...prev,
      brand_name: e.target.options[e.target.selectedIndex].value,
      errors: {
        ...selectedPreferredProducts.errors,
        brand_name: ""
      }
    }))
  }

  const addingProductDetails = () => {
    if (selectedPreferredProducts.brand_name.trim() && selectedPreferredProducts.category_name.trim()) {
      delete selectedPreferredProducts.errors;
      setState(prev => ({
        ...prev,
        preferred_products: [...state.preferred_products, selectedPreferredProducts]
      }))
      setSelectedPreferredProducts({
        category: "",
        category_name: "",
        brand_name: "",
        product_type: "",
        errors: {
          category_name: "",
          brand_name: ""
        }
      })
      setCategory({
        drinkDropdown: "",
        smokeDropdown: ""
      })
    }
    else {
      const errors = selectedPreferredProducts.errors;
      if (!selectedPreferredProducts.brand_name.trim()) {
        errors.brand_name = "Please Select Brand";
      }
      if (!selectedPreferredProducts.category_name.trim()) {
        errors.category_name = "Please Select Category";
      }
      setSelectedPreferredProducts(prev => ({
        ...prev,
        errors: errors
      }))
    }
  }
  return (
    <Layout path="/bookings/create-table-booking">
      <ContentWrapper additionalClass="p-5">
        <form className="mt-6" onSubmit={handleFormSubmit}>
          <h5 className="text-2xl font-body font-bold mb-3">Table booking details</h5>
          <Row className="g-4">
            <Col xs={6} className="font-body">
              <label
                htmlFor="club_name"
                className="text-lg font-medium color-gray mb-3"
              >
                Club Name <span className="text-danger">*</span>
              </label>
              <div className="position-relative">
                <input
                  id="club_name"
                  name="club_name"
                  type="search"
                  className="po-edit-input mb-1"
                  placeholder="Enter Club Name"
                  value={state.club_name}
                  onChange={searchInputHandler}
                  onBlur={onInputBlurHandler}
                  onKeyDown={keyDownHandler}
                />
                {!state.club_name && !inputLoader.club && (
                  <ActionIcon additionalClass="bi bi-chevron-down po-input__dropdownIcon position-absolute" />
                )}
                {inputLoader.club && <div
                  className="spinner-border po-input__dropdownIcon position-absolute bg-white"
                  style={{ width: "1.4rem", height: "1.4rem" }}
                  role="status">
                </div>}
              </div>
              {showClubList && (
                <ul className="po-updateEvent__clubLists" ref={clubListRef}>
                  {clubs && clubs.length ? clubs.map((club, index) => (
                    <li
                      key={index}
                      tabIndex={index}
                      id={`club-${index}`}
                      className={`p-2 font-medium d-flex justify-content-between gap-4 ${active === index ? 'background-skyBlue text-dark' : ''}`}
                      onClick={(e) => onSelectSearchedInput(club.id)}
                    >
                      <span className='text-nowrap'>{club.name}</span>
                      <span className='text-xs text-end'>{club.address}</span>
                    </li>
                  )) : <li className="p-2 font-medium">No Record Found!</li>}
                </ul>)}
              {state.errors.club_name &&
                <p className="text-danger font-body text-sm">{state.errors.club_name}</p>
              }
            </Col>
            <Col xs={6} className="font-body">
              <label
                htmlFor="user_name"
                className="text-lg font-medium color-gray mb-3"
              >
                User Name <span className="text-danger">*</span>
              </label>
              <div className="position-relative">
                <input
                  id="user_name"
                  name="user_name"
                  type="search"
                  className="po-edit-input mb-1"
                  placeholder="Enter User Name"
                  value={state.user_name}
                  onChange={searchInputHandler}
                  onBlur={onUserInputBlurHandler}
                  onKeyDown={keyDownHandler}
                />

                {!state.user_name && !inputLoader.user && (
                  <ActionIcon additionalClass="bi bi-chevron-down po-input__dropdownIcon position-absolute" />
                )}
                {inputLoader.user && state.user_name && <div
                  className="spinner-border po-input__dropdownIcon position-absolute bg-white"
                  style={{ width: "1.4rem", height: "1.4rem" }}
                  role="status">
                </div>}
              </div>
              {showUserList && (
                <ul className="po-updateEvent__clubLists" ref={userListRef}>
                  {users && users.length ? users.map((user, index) => (
                    <li
                      key={index}
                      tabIndex={index}
                      id={`user-${index}`}
                      className={`p-2 font-medium d-flex justify-content-between gap-4 ${active === index ? 'background-skyBlue text-dark' : ''}`}
                      onClick={(e) => onSelectSearchedInput(user.id, 'user_name')}
                    >
                      <span className='text-nowrap'>{user.name}</span>
                      <span className='text-xs text-end text-break'>{user.email}</span>
                    </li>
                  )) : <li className="p-2 font-medium">No Record Found!</li>}
                </ul>
              )}
              {state.errors.user_name &&
                <p className="text-danger font-body text-sm">{state.errors.user_name}</p>
              }
            </Col>
          </Row>
          {/* preferred products ********************************************* */}

          {state.tables.length ? <Row className="g-4 mt-2">
            <Col xs={6}>
              <h4 className="text-2xl  font-bold text-black">
                Add Preferred Products Details
              </h4>
              <div>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox" onChange={handlePreferredDrinkProducts}
                    role="switch"
                    id="flexSwitchCheckDisabled"
                    disabled={!state.tables.length}
                  />
                  <label className="form-check-label text-medium" htmlFor="flexSwitchCheckDisabled">{toggleProducts ? "Preferred Drink" : "Preferred Smoke"}</label>
                </div>
              </div>
              {/* Product category ******************************************* */}

              <div className="mt-5">
                <h4 className="text-md font-semibold color-gray mb-2">{toggleProducts ? "Preferred Drink" : "Preferred Smoke"} <span className="text-danger">*</span></h4>
                <select
                  name={toggleProducts ? "drinkDropdown" : "smokeDropdown"}
                  id={toggleProducts ? "drinkDropdown" : "smokeDropdown"}
                  className="po-edit-input "
                  onChange={(e) => { onChangeProductCategory(e, (toggleProducts ? "drink" : "smoke")) }}
                  value={toggleProducts ? category.drinkDropdown : category.smokeDropdown}
                >
                  <option key={toggleProducts ? category.drinkDropdown : category.smokeDropdown} value="">
                    {toggleProducts ? "Select Drink" : "Select Smoke"}</option>
                  {preferredProducts && (toggleProducts ? preferredProducts.drink_category : preferredProducts.smoke_category) &&
                    (toggleProducts ? preferredProducts.drink_category : preferredProducts.smoke_category).map((product, index) => {
                      return <option value={product.name} id={product.id} key={index}>{product.name}</option>;
                    })}
                </select>
                {selectedPreferredProducts.errors.category_name &&
                  <p className="text-danger font-body text-sm">{selectedPreferredProducts.errors.category_name}</p>
                }
              </div>

              {/* Product Brands ********************************************** */}

              <div className="mt-5">
                <h4 className="text-md font-semibold color-gray mb-2">{toggleProducts ? "Preferred Drink Brands" : "Preferred Smoke Brands"} <span className="text-danger">*</span></h4>
                <select
                  name="brand"
                  id="brand"
                  className="po-edit-input "
                  onChange={onChangeProductBrands}
                  value={selectedPreferredProducts.brand_name}
                >
                  <option key="brand" value="">{toggleProducts ? "Select Drink Brand" : "Select Smoke Brand"}</option>
                  {productBrands && productBrands.length ?
                    productBrands.map((brand, index) => {
                      return <option value={brand.name} key={index}>{brand.name}</option>;
                    }) : ""}
                </select>
                {selectedPreferredProducts.errors.brand_name &&
                  <p className="text-danger font-body text-sm">{selectedPreferredProducts.errors.brand_name}</p>
                }
              </div>

              <div className="text-end mt-5 font-body">
                <Button className="color-black1 text-md font-body font-semibold cursor-pointer po-border po-border-yellow1 py-3 px-3 radius-105 d-inline-block bg-transparent text-dark"
                  onClick={addingProductDetails} name="Add Table Data">
                  Add Product Details
                </Button>
              </div>
            </Col>

            {/* product list ***************************************** */}

            {state.preferred_products.length ? <Col xs={6}>
              <h4 className="text-2xl  font-bold text-black">
                Preferred Products List
              </h4>
              <Table responsive hover>
                <thead>
                  <tr>
                    <th className="text-md">S. No.</th>
                    <th className="text-md">Category Name</th>
                    <th className="text-md">Product Type</th>
                    <th className="text-md">Brand Name</th>
                  </tr>
                </thead>
                <tbody>
                  {state.preferred_products.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.category_name}</td>
                        <td className="text-capitalize">{item.product_type}</td>
                        <td>
                          <span className="d-flex align-items-center justify-content-between">
                            <span>{item.brand_name}</span>
                            <ActionIcon
                              onClick={() => { handleRemovePreferredProducts(index) }}
                              additionalClass="bi bi-x-circle-fill text-3xl color-red me-2"
                            />
                          </span>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </Col> : ""}
          </Row> : ""}

          <Row className="g-4 mt-2">
            <Col xs={6}>
              <label htmlFor="booking_date" className="text-lg font-medium color-gray mb-2">
                Booked For <span className="text-danger">*</span>
              </label>
              <DatePicker
                selected={state.booking_date}
                className="po-edit-input mb-1 w-100"
                disabled={!state.club_id}
                dateFormat="dd/MM/YYY"
                placeholderText="dd/mm/yyyy"
                minDate={new Date()}
                excludeDates={nonOperationalDays.map(item => item.toDate())}
                onChange={handleDateChange}
              />
              {state.errors.booking_date &&
                <p className="text-danger font-body text-sm">{state.errors.booking_date}</p>
              }
            </Col>
            <Col xs={6}>
              <div>
                <label htmlFor="expected_arrival_time" className="text-lg font-medium color-gray mb-2">
                  Entry Time Slot <span className="text-danger">*</span>
                </label>
                <select
                  value={state.expected_arrival_time}
                  className="po-edit-input mb-1"
                  name="expected_arrival_time"
                  onChange={onChangeHandler}
                  disabled={!state.club_name || !state.booking_date}
                >
                  <option key="entry_time" value="">Select entry time</option>
                  {(timeSlots && timeSlots.length) ?
                    timeSlots.map((slot, index) => {
                      return <option value={slot.slot_time + " " + slot.zone} key={index}>{slot.slot_time + " " + slot.zone}</option>;
                    }) : ""}
                  {/* Add more time slots as needed */}
                </select>
              </div>
              {state.errors.expected_arrival_time &&
                <p className="text-danger font-body text-sm">{state.errors.expected_arrival_time}</p>
              }
            </Col>
          </Row>
          {/* guest details goes here **************************************** */}
          <Row className="g-4 mt-2">
            <Col xs={6}>
              <h4 className="text-2xl  font-bold text-black">
                Add guest details
              </h4>
              <label className="font-body text-md mt-3">Name
                {/* <span className="text-danger">*</span> */}
              </label>
              <input
                name="guest_name"
                id="guest_name"
                className="po-edit-input"
                placeholder="Please enter the name of guest"
                value={guestDetails.guest_name.charAt(0).toUpperCase() + guestDetails.guest_name.slice(1)}
                onChange={handleGuestDetailsChange}
              />
              {guestDetails.errors.guest_name &&
                <p className="text-danger font-body text-sm">{guestDetails.errors.guest_name}</p>
              }
              <div>
                <label className="font-body text-md d-block mt-3">Gender <span className="text-danger">*</span></label>
                <label className="me-2 font-medium text-md d-inline-flex align-items-center">
                  <input
                    type="radio"
                    value="male"
                    className="me-1"
                    checked={guestDetails.gender === 'male'}
                    onChange={handleGenderChange}
                  />
                  Male
                </label>
                <label className="me-2 font-medium text-md d-inline-flex align-items-center">
                  <input
                    type="radio"
                    className="me-1"
                    value="female"
                    checked={guestDetails.gender === 'female'}
                    onChange={handleGenderChange}
                  />
                  Female
                </label>
                <label className="me-2 font-medium text-md d-inline-flex align-items-center">
                  <input
                    type="radio"
                    className="me-1"
                    value="other"
                    checked={guestDetails.gender === 'other'}
                    onChange={handleGenderChange}
                  />
                  Other
                </label>
              </div>
              {guestDetails.errors.gender &&
                <p className="text-danger font-body text-sm">{guestDetails.errors.gender}</p>
              }
              <label className="font-body text-md mt-3 ">Age <span className="text-danger">*</span></label>
              <input
                className="po-edit-input"
                name="age"
                type="number"
                value={guestDetails.age ? +guestDetails.age : ""}
                min={0}
                max={100}
                onChange={handleGuestDetailsChange}
              />
              {guestDetails.errors.age &&
                <p className="text-danger font-body text-sm">{guestDetails.errors.age}</p>
              }
              <div className="text-end mt-5 font-body">
                <Button className="color-black1 text-md font-body font-semibold cursor-pointer po-border po-border-yellow1 py-3 px-3 radius-105 d-inline-block bg-transparent text-dark" onClick={addingGuestDetails} name="Add Table Data">
                  Add Guest Details
                </Button>
              </div>
            </Col>
            <Col xs={6}>
              {state.people.length ?
                <div>
                  <h4 className="text-2xl  font-bold text-black">Guest Details List</h4>
                  <Table responsive hover>
                    <thead>
                      <tr>
                        <th className="text-md">S. No</th>
                        <th className="text-md">Name</th>
                        <th className="text-md">Gender</th>
                        <th className="text-md">Age</th>
                      </tr>
                    </thead>
                    <tbody>
                      {state.people.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.name ? item.name : `Guest ${index + 1}`}</td>
                            <td>{item.gender}</td>
                            <td>
                              <span className="d-flex align-items-center gap-2">
                                <span>{item.age}</span>
                                <ActionIcon
                                  onClick={() => { handleRemoveGuest(index) }}
                                  additionalClass="bi bi-x-circle-fill text-xl color-red me-2 d-flex align-items-center"
                                />
                              </span>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div> : ""
              }
            </Col>
            <Col
              xs={12}
              className="d-flex justify-content-end align-items-center mb-6"
            >
              {showToaster && (
                <img
                  src="/assets/icons/loading_icon.gif"
                  alt="processing"
                  width={100}
                />
              )}
              <OutlinedButton link="/bookings" name="Cancel" />
              <SuccessButton
                type="submit"
                name="Save"
                icon="bi-check-circle"
                disabled={showToaster ? true : false}
                additionalClass="ms-4"
              />
            </Col>
          </Row>
        </form>
      </ContentWrapper>
      <Toaster
        showToaster={showToaster}
        setShowToaster={setShowToaster}
        bg={toasterDetails.bg}
        title={toasterDetails.title}
        bodyText={toasterDetails.bodyText}
        statusIcon={toasterDetails.statusIcon}
      />
    </Layout>
  );
};

export default CreateTableBooking;