import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { Button } from "react-bootstrap";
import db from '../../../../firebase';
import Layout from '../../../../Layout/Layout';
import PageDescription from '../../../../Components/Global/PageDescription';
import ContentWrapper from '../../../../Components/Global/ContentWrapper';
import Toaster from '../../../../Components/Global/Toast';
import SuccessButton from '../../../../Components/Global/Buttons/SuccessButton';
import OutlinedButton from '../../../../Components/Global/Buttons/OutlinedButton';
const AdminUpdateSmokeCategory = () => {
//   const clubId = localStorage.getItem('club_id');
  const { smoke_id, club_id } = useParams();
  const navigate = useNavigate();
  const [showToaster, setShowToaster] = useState(false);
  const [toasterDetails, setToasterDetails] = useState({
    title: "",
    bodyText: "",
    bg: "",
    statusIcon: ""
  })
  const [state, setState] = useState({
    name: "",
    brands: [],
    errors: {
      name: "",
      brands: "",
    }
  })
  const validate = (errors) => {
    let valid = true;
    Object.values(errors).forEach(val => val.length > 0 && (val = false));
    if (state.name.trim() == "" || state.brands.length == 0)
      valid = false;
    state.brands.forEach(vals => {
      if (vals.name.trim() == "") {
        valid = false;
      }
    })
    return valid;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    function removeSpace(d) {
      let newData = [];
      d.forEach(d => {
        newData.push({ name: d.name.trimStart().replace(/\s+/g, ' ').trimEnd()})
      })
      return newData;
    }
    if (validate(state.errors)) {
      setShowToaster(true);
      setToasterDetails(prev=> ({
        ...prev,
        title: "Updating...",
        bodyText: "Updating Smoke Category Details",
        bg: "warning",
        statusIcon: <div className="spinner-border text-warning me-2" role="status">
        </div>
      }))
      const updateData = {
        name: state.name.trimStart().replace(/\s+/g, ' ').trimEnd(),
        brands: removeSpace(state.brands),
        updatedAt: new Date()
      };
    //   console.log(updateData, "update data");
      const clubResponse = await updateDoc(doc(db, "clubs", club_id, "smoke_category", smoke_id), updateData)
        .then(() => {
          // alert("Data successfully Updated");
          setToasterDetails(prev=> ({
            ...prev,
            title: "Success",
            bodyText: "Smoke Category Details are updated successfully",
            bg: "success",
            statusIcon: <i className="bi bi-check text-success text-3xl me-2"></i>
          }))
          setTimeout(()=> {
            navigate(`/clubs/smoke-management/${club_id}`, {replace: true});
          }, 2000);
        })
        .catch(err => {
          setToasterDetails(prev=> ({
            ...prev,
            title: "Error",
            bodyText: "Something went wrong",
            bg: "danger",
            statusIcon: <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
          }))
          setTimeout(()=> {
            setShowToaster(false);
          }, 2000)
        });
    } else {
      let errors = state.errors;
      if (state.name.trim() == "")
        errors.name = "Enter the brand name"
      if (state.brands.length == 0)
        errors.brands = "Add atleast one brand to the category"
      state.brands.forEach(vals => {
        if (vals.name.trim() == "") {
          errors.brands = "Add brand names for all"
        }
      })
      setState((prevProps) => ({
        ...prevProps,
        errors: errors
      }));
    }
  };

  const getDrinkData = async () => {
    const subColRef = (await getDoc(doc(db, "clubs", club_id, "smoke_category", smoke_id))).data();
    setState(prev => ({
      ...prev,
      name: subColRef && subColRef.name && subColRef.name,
      brands: subColRef && subColRef.brands && subColRef.brands,
    }))
  };
  useEffect(() => {
    getDrinkData();
  }, []);
  const nameChangeHandler = (event) => {
    const { value } = event.target;
    let errors = state.errors;
    errors.name = ""
    setState(prev => ({
      ...prev,
      name: value.replace(/\s+/g, ' '),
      errors: errors
    }))
  }
  const handlechangeBrandName = (event, ind) => {
    const upd_obj = state.brands
    upd_obj[ind].name = event.target.value.replace(/\s+/g, ' ');
    let errors = state.errors;
    errors.brands = ""
    setState(prev => ({
      ...prev,
      brands: upd_obj,
      errors: errors,
    }))
  }
  const handleUppendBrandName = (event, ind) => {
    const upd_obj = [...state.brands, { name: "" }]
    setState(prev => ({
      ...prev,
      brands: upd_obj,
    }))
  }
  const handleRemoveBrandFromList = (ind) => {
    console.log(ind);
    let upd_obj = state.brands;
    upd_obj.splice(ind, 1)
    setState(prev => ({
      ...prev,
      brands: upd_obj,
    }))
  }
  const breadCrumbData = {
    title: "All Smokes",
    caption: "/ Edit"
  }
  return (
    <Layout link={`/clubs/smoke-management/${club_id}`} breadCrumbData={breadCrumbData}>
      <PageDescription title="Edit Smoke Category" caption="" />
      <ContentWrapper additionalClass="p-5 font-body">
        <form onSubmit={(e) => handleSubmit(e)}>
          <label className="text-lg mb-2">Category:</label>
          <input
            className="po-edit-input"
            type="text"
            name="name"
            value={state.name}
            onChange={(e) => nameChangeHandler(e)}
          />
          {state.errors.name && (
            <p className="text-sm text-danger">{state.errors.name}</p>
          )}

          <label className="text-lg mb-2 mt-3">Brands:</label>
          {state.brands && state.brands.length > 0 && state.brands.map((field, index) => (
            <div key={index} className="d-flex">

              <input
                type="text"
                className="po-edit-input me-3 mb-3"
                name={`brands[${index}]`}
                value={field.name}
                onChange={(e) => handlechangeBrandName(e, index)}
              />

              <Button variant="outline-danger" className="mb-3 radius-105" onClick={() => handleRemoveBrandFromList(index)}  >Delete</Button>
              {/* {errors.(`brands.${index}.name`) && <span>this field is required</span>} */}

            </div>
          ))}
          {state.errors.brands && (
            <p className="text-sm text-danger">{state.errors.brands}</p>
          )}

          <div>
            {/* <Button type="button" variant="success" onClick={() => handleUppendBrandName()} className="mt-5">+ Add Brand</Button> */}
            <SuccessButton
              onClick={() => handleUppendBrandName()}
              name="Add Brand"
              icon="bi-plus-circle"
              additionalClass="mt-5"
            />
          </div>
          <div className="mt-5 d-flex justify-content-end align-items-center">
            {showToaster && <img
              src="/assets/icons/loading_icon.gif"
              alt="processing" width={100} />}
            {/* <Button 
              variant="success" 
              type="submit" 
              className="me-3"
              disabled={showToaster ? true : false}
            >Submit</Button>
            <Link to={`/clubs/smoke-management/${club_id}`}> <Button variant="danger">Cancel</Button></Link> */}
            <OutlinedButton 
              link={`/clubs/smoke-management/${club_id}`} 
              name="Cancel"
              style={{pointerEvents: !showToaster ? '' : 'none'}}
            />
            <SuccessButton
              type="submit"
              name="Update category"
              icon="bi-check-circle"
              additionalClass="ms-3"
              disabled={showToaster}
            />
          </div>

        </form>
      </ContentWrapper>
      <Toaster 
        showToaster={showToaster} 
        setShowToaster={setShowToaster}
        bg={toasterDetails.bg}
        title={toasterDetails.title}
        bodyText={toasterDetails.bodyText}
        statusIcon={toasterDetails.statusIcon}
      />
    </Layout>
  )
}
export default AdminUpdateSmokeCategory;