import React, { useState, useEffect, useRef, useCallback } from "react";
import { Tab, Nav } from "react-bootstrap";
import Layout from "../../Layout/Layout";
import ContentWrapper from "../../Components/Global/ContentWrapper";
import { collection, getDocs, query, where } from "firebase/firestore";
import db from "../../firebase";
// import TableBookings from "../../Components/Booking/TableBookings";
// import EventBookings from "../../Components/Booking/EventBookings";
// import EntryBookings from "../../Components/Booking/EntryBookings";
import { useNavigate, useSearchParams } from "react-router-dom";
import PrimaryButton from "../../Components/Global/Buttons/PrimaryButton";
import PageDescription from "../../Components/Global/PageDescription";
// import useCallCollection from "../../hooks/useCallCollection";
import Skeleton from "react-loading-skeleton";
import FilterModalBookings from "../../Components/Global/Popup/FilterModalBookings";
import FilterModalEventBookings from "../../Components/Global/Popup/FilterModalEventBookings";
import ClubTableBooking from "../../Components/Booking/ClubTableBooking";
import ClubEventBooking from "../../Components/Booking/ClubEventBooking";
import ClubEntryBooking from "../../Components/Booking/ClubEntryBooking";

const ClubBookings = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab") ? searchParams.get("tab") : "club_booking_table";
  const [selectedTab, setSelectedTab] = useState(tab);
  const [usersContactDetails, setUsersContactDetails] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterKeys, setFilterKeys] = useState({
    selectedClubs: [],
    selectedEvents: [],
    selectedStatus: []
  });
  const [pendingCount, setPendingCount] = useState({
    table: 0,
    event: 0,
    entry: 0
  })
  const clubId = window.location.pathname.split("/")[2];
  const clubName = searchParams.get("club_name");
  const filterIcon = useRef(null);

  const filterKeysRef = useRef({
    selectedClubs: [],
    selectedEvents: [],
    selectedStatus: []
  });

  const tableBookingPendingCount = async () => {
    try {
      const response = await getDocs(query(collection(db, "club_entry_bookings"), where("status", "==", "Pending"), where("booking_type", "==", "table_booking"), where("club_id", "==", clubId)))
      let tableCount = response.docs.length;
      console.log(tableCount, "tableCount")
      return tableCount;
    }
    catch (err) {
      console.error(err)
    }
  }
  const eventBookingPendingCount = async () => {
    try {
      const response = await getDocs(query(collection(db, "event_entry_bookings"), where("status", "==", "Pending"), where("club_id", "==", clubId)))
      let eventCount = response.docs.length;
      return eventCount;
    }
    catch (err) {
      console.error(err)
    }
  }
  const entryBookingPendingCount = async () => {
    try {
      const response = await getDocs(query(collection(db, "club_entry_bookings"), where("status", "==", "Pending"), where("booking_type", "==", "club_entry_booking"), where("club_id", "==", clubId)))
      const entryCount = response.docs.length;
      return entryCount;
    }
    catch (err) {
      console.error(err)
    }
  }


  const bookingRef = useRef(null);

  // Table Height : Start
  const [firstSectionHeight, setFirstSectionHeight] = useState(0);
  const secondSectionHeight = `calc(100vh - ${firstSectionHeight}px)`;
  // Calculate the height of the first section on mount and window resize
  useEffect(() => {
    const calculateHeight = () => {
      const firstSection = document.getElementById('first-section');
      if (firstSection) {
        setFirstSectionHeight(firstSection.clientHeight);
      }

    };

    calculateHeight();
    window.addEventListener('resize', calculateHeight);

    return () => {
      window.removeEventListener('resize', calculateHeight);
    };
  }, []);


  useEffect(() => {
    calculatePendingCounts()
  }, [])

  // Usage of Promise.all()
  const calculatePendingCounts = async () => {
    try {
      const [tableCount, eventCount, entryCount] = await Promise.all([
        tableBookingPendingCount(),
        eventBookingPendingCount(),
        entryBookingPendingCount(),
      ]);

      setPendingCount({
        table: tableCount,
        event: eventCount,
        entry: entryCount,
      });
    } catch (error) {
      console.error(error);
    }
  };

  // Table Height : End

  const fetchAllUsersDetails = async () => {
    let obj = {};
    setUsersContactDetails({});
    const querySnapshot = await getDocs(query(collection(db, "users")));
    querySnapshot.docs.forEach((doc) => {
      obj[doc.data().id] = [doc.data().contacts, doc.data().email];
    });
    setUsersContactDetails(obj);
  };

  const filterComponent = React.createRef();
  const handleClickOutside = useCallback((event) => {
    if (filterComponent.current && !filterComponent.current.contains(event.target) && event.target !== filterIcon.current) {
      setShowModal(false);
    }
  }, [filterComponent]);

  // Searching ****************************************************************************!

  const handleSearchQuery = (e) => {
    setFilterKeys({
      selectedClubs: [],
      selectedEvents: [],
      selectedStatus: []
    })
    filterKeysRef.current = {
      selectedClubs: [],
      selectedEvents: [],
      selectedStatus: []
    }
    if (e.target.value) {
      searchParams.delete('filterQuery');
      searchParams.delete('pageNo');
      searchParams.delete('prevNext');
      searchParams.delete('currentPageId');
      searchParams.delete('n');
      setSearchParams({ ...Object.fromEntries([...searchParams]), searchQuery: e.target.value })
      setSearchQuery(e.target.value);
    }
    else {
      setSearchQuery("");
      if (searchParams.get('sortBy')) {
        navigate(`/bookings?tab=${tab}&sortBy=${searchParams.get('sortBy')}&changeOrder=${searchParams.get("changeOrder")}`)
      }
      else {
        navigate(`/bookings?tab=${tab}`);
      }
    }
  }

  // Filter **************************************************************************************************!

  const handleModifyFilter = (e, item, filterType) => {
    // localIdBasedSortIcon.current=""
    if (filterType === "club") {
      setFilterKeys({
        ...filterKeys,
        selectedClubs: filterKeys.selectedClubs.filter(club => club.id !== item)
      })

      filterKeysRef.current = {
        ...filterKeysRef.current,
        selectedClubs: filterKeysRef.current.selectedClubs.filter(club => club.id !== item)
      }
    }
    if (filterType === "event") {
      setFilterKeys({
        ...filterKeys,
        selectedEvents: filterKeys.selectedEvents.filter(event => event.id !== item)
      })

      filterKeysRef.current = {
        ...filterKeysRef.current,
        selectedEvents: filterKeysRef.current.selectedEvents.filter(event => event.id !== item)
      }
    }
    if (filterType === "status") {
      setFilterKeys({
        ...filterKeys,
        selectedStatus: filterKeys.selectedStatus.filter(status => status !== item)
      })
      filterKeysRef.current = {
        ...filterKeysRef.current,
        selectedStatus: filterKeysRef.current.selectedStatus.filter(status => status !== item)
      }
    }
    if (filterKeysRef.current.selectedEvents.length + filterKeysRef.current.selectedClubs.length + filterKeysRef.current.selectedStatus.length === 0) {
      searchParams.delete('filterQuery');
      searchParams.delete('pageNo');
      setSearchParams({ ...Object.fromEntries([...searchParams]), bookingsPerPage: 20 });
    } else {
      setSearchParams({ ...Object.fromEntries([...searchParams]), filterQuery: JSON.stringify(filterKeysRef.current) });
    }
  }

  // filter goes here -----------------------------------------------------------------------------------

  // useEffect goes here **********************************************************************************

  useEffect(() => {
    if (JSON.stringify(usersContactDetails) === "{}") {
      fetchAllUsersDetails();
    }
    if (Boolean(searchParams.get('searchQuery'))) {
      setSearchQuery(searchParams.get('searchQuery'))
    }
    if (Boolean(searchParams.get('filterQuery'))) {
      setSearchQuery("");
      setFilterKeys(prev => ({
        ...prev,
        ...JSON.parse(searchParams.get('filterQuery'))
      }))
      filterKeysRef.current = {
        ...filterKeysRef.current,
        ...JSON.parse(searchParams.get('filterQuery'))
      }
    }
    if (!Boolean(searchParams.get('filterQuery'))) {
      setFilterKeys({
        selectedClubs: [],
        selectedEvents: [],
        selectedStatus: []
      })
      filterKeysRef.current = {
        selectedClubs: [],
        selectedEvents: [],
        selectedStatus: []
      }
    }
  }, [searchParams]);

  useEffect(() => { // this useEffect is used when user will click other area than filter popup
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handleClickOutside]);

  // const tableBookingsPendingCount = tableBookings.filter(
  //   (obj) => obj.status === "Pending"
  // ).length;



  const RENDERCREATEBTN = () => {
    switch (selectedTab) {
      case "table":
        return (
          <PrimaryButton
            link="/bookings/create-table-booking"
            name="Create Table Booking"
            icon="bi-plus-circle"
          />
        );
      case "clubs":
        return (
          <PrimaryButton
            link="/bookings/create-entry-booking"
            name="Create Entry Booking"
            icon="bi-plus-circle"
          />
        );
      case "events":
        return (
          <PrimaryButton
            link="/bookings/create-event-booking"
            name="Create Event Booking"
            icon="bi-plus-circle"
          />
        );
      default:
        break;
    }
  };

  return (
    <Layout path="/clubs" additionalclass="no-scrollbar">
      <div className='po-table-content mb-8'>
        <div id="first-section" style={{ height: 'auto', minHeight: '100px' }}>
          <PageDescription title={clubName + " Bookings"} caption="" additionalClass="py-2" />
          <div className='row d-flex justify-content-between g-3'>
            <div className='col-5 col-xxl-4'>
              <input
                type="search"
                className="po-edit-input mb-1"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => handleSearchQuery(e)}
              />
            </div>
            <div className='col-6 col-xxl-4'>
              <div className='text-end position-relative'>
                <button className='border-0 bg-transparent' type='button' onClick={() => { setShowModal(prev => !prev) }}>
                  <i
                    className={`bi bi-funnel-fill text-xl ${showModal ? "color-yellow1" : ""}`}
                  ></i></button>
                {selectedTab !== "events" ? <FilterModalBookings
                  ref={filterComponent}
                  showModal={showModal}
                  setShowModal={setShowModal}
                /> : <FilterModalEventBookings
                  ref={filterComponent}
                  showModal={showModal}
                  setShowModal={setShowModal}
                />}
              </div>
            </div>
          </div>
        </div>
        <div className={`mt-3 ${(!filterKeys.selectedClubs.length && !filterKeys.selectedEvents.length && !filterKeys.selectedStatus.length) ? "d-none" : ""}`}>
          {filterKeys && filterKeys.selectedClubs && filterKeys.selectedClubs.length ? <div className='d-flex align-items-start my-3 gap-3 text-sm font-medium'>
            <span className='text-nowrap'>Selected Clubs</span> :
            <div className='d-flex align-items-start flex-wrap gap-3'>
              {filterKeys.selectedClubs.map((item, index) => <div
                className='text-xxs font-medium pl-3 pe-0 py-1 border-1 background-gradient rounded-pill text-capitalize d-flex align-items-center justify-content-between'
                key={index}
              >
                {item.name}
                <button
                  className='bg-transparent border-0 d-flex align-items-center'
                  onClick={(e) => handleModifyFilter(e, item.id, 'club')}
                >
                  <i className="bi bi-x-circle-fill text-md lineheight-0"></i>
                </button>
              </div>
              )}
            </div>
          </div> : ""}
          {filterKeys && filterKeys.selectedEvents && filterKeys.selectedEvents.length ? <div className='d-flex align-items-start my-3 gap-3 text-sm font-medium'>
            <span className='text-nowrap'>Selected Events</span> :
            <div className='d-flex align-items-start flex-wrap gap-3'>
              {filterKeys.selectedEvents.map((item, index) => <div
                className='text-xxs font-medium pl-3 pe-0 py-1 border-1 background-gradient rounded-pill text-capitalize d-flex align-items-center justify-content-between'
                key={index}
              >
                {item.name}
                <button
                  className='bg-transparent border-0 d-flex align-items-center'
                  onClick={(e) => handleModifyFilter(e, item.id, 'event')}
                >
                  <i className="bi bi-x-circle-fill text-md lineheight-0"></i>
                </button>
              </div>
              )}
            </div>
          </div> : ""}
          {filterKeys && filterKeys.selectedStatus && filterKeys.selectedStatus.length ? <div className='d-flex align-items-start my-3 gap-3 text-sm font-medium'>
            <span className='text-nowrap'>Selected Status</span> :
            <div className='d-flex align-items-start flex-wrap gap-3'>
              {filterKeys.selectedStatus.map((item, index) => <div
                className='text-xxs font-medium pl-3 pe-0 py-1 border-1 background-gradient rounded-pill text-capitalize d-flex align-items-center justify-content-between'
                key={index}
              >
                {item}
                <button
                  className='bg-transparent border-0 d-flex align-items-center'
                  onClick={(e) => handleModifyFilter(e, item, 'status')}
                >
                  <i className="bi bi-x-circle-fill text-md lineheight-0"></i>
                </button>
              </div>
              )}
            </div>
          </div> : ""}
        </div>
        <ContentWrapper additionalClass="py-5" >
          <div className="po-custom-tabs" style={{ height: secondSectionHeight }}>
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey={selectedTab}
              onSelect={(k) => { setSelectedTab(k); navigate(`/clubs-bookings/${clubId}?club_name=${clubName}&tab=${k}`) }}
            >
              <div>
                <div className="border-bottom po-custom-tabs d-flex align-items-center justify-content-between px-5">
                  <Nav variant="pills" className="flex-row pb-4">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="club_booking_table"
                        className="d-flex justify-content-center align-items-center"
                      >
                        <div>Table Bookings</div>{" "}
                        {pendingCount.table !== undefined ? (
                          <div
                            className="d-flex justify-content-center align-items-center ms-2 rounded-circle text-center text-white"
                            style={{
                              height: "30px",
                              width: "30px",
                              background:
                                "transparent linear-gradient(290deg, #DBA611 0%, #F7CE73 100%) 0% 0% no-repeat padding-box",
                            }}
                          >
                            <div className="text-xs" title="Pending">
                              {pendingCount.table}
                            </div>
                          </div>
                        ) : <Skeleton
                          inline={true} count={1}
                          height={30} width={30}
                          baseColor="#dedede" highlightColor="#cccccc"
                          duration={2} className="ms-2" circle
                        />
                        }
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="club_booking_events"
                        className="d-flex justify-content-center align-items-center"
                      >
                        <div>Event Booking</div>{" "}
                        {pendingCount.event !== undefined ? (
                          <div
                            className="d-flex justify-content-center align-items-center ms-2 rounded-circle text-center text-white"
                            style={{
                              height: "30px",
                              width: "30px",
                              background:
                                "transparent linear-gradient(290deg, #DBA611 0%, #F7CE73 100%) 0% 0% no-repeat padding-box",
                            }}
                          >
                            <div className="text-xs" title="Waiting for approval">
                              {pendingCount.event}
                            </div>
                          </div>
                        ) : <Skeleton
                          inline={true} count={1}
                          height={30} width={30}
                          baseColor="#dedede" highlightColor="#cccccc"
                          duration={2} className="ms-2" circle
                        />}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="club_booking_clubs"
                        className="d-flex justify-content-center align-items-center"
                      >
                        <div>Entry Bookings</div>{" "}
                        {pendingCount.entry !== undefined ? (
                          <div
                            className="d-flex justify-content-center align-items-center ms-2 rounded-circle text-center text-white"
                            style={{
                              height: "30px",
                              width: "30px",
                              background:
                                "transparent linear-gradient(290deg, #DBA611 0%, #F7CE73 100%) 0% 0% no-repeat padding-box",
                            }}
                          >
                            <div className="text-xs" title="Waiting for approval">
                              {pendingCount.entry}
                            </div>
                          </div>
                        ) : <Skeleton
                          inline={true} count={1}
                          height={30} width={30}
                          baseColor="#dedede" highlightColor="#cccccc"
                          duration={2} className="ms-2" circle
                        />
                        }
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <div className="mb-5">
                    <RENDERCREATEBTN />
                  </div>
                </div>
                <div>
                  <Tab.Content>
                    <Tab.Pane eventKey="club_booking_table">
                      {selectedTab === "club_booking_table" &&
                        <ClubTableBooking
                          clubId={clubId}
                          bookingRef={bookingRef}
                          //secondSectionHeight={secondSectionHeight}
                          usersContactDetails={usersContactDetails}
                        />}
                    </Tab.Pane>
                    <Tab.Pane eventKey="club_booking_events">
                      {selectedTab === "club_booking_events" &&
                        <ClubEventBooking
                          clubId={clubId}
                          bookingRef={bookingRef}
                          usersContactDetails={usersContactDetails}
                        //secondSectionHeight={secondSectionHeight}
                        />}
                    </Tab.Pane>
                    <Tab.Pane eventKey="club_booking_clubs">
                      {selectedTab === "club_booking_clubs" &&
                        <ClubEntryBooking
                          clubId={clubId}
                          bookingRef={bookingRef}
                          usersContactDetails={usersContactDetails}
                        //secondSectionHeight={secondSectionHeight}
                        />}
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          </div>
        </ContentWrapper>
      </div>
    </Layout>
  );
};
export default ClubBookings;
