import { Col } from "react-bootstrap";
import ContentWrapper from "../../../Components/Global/ContentWrapper";
import Layout from "../../../Layout/Layout";
import SuccessButton from "../../../Components/Global/Buttons/SuccessButton";
import OutlinedButton from "../../../Components/Global/Buttons/OutlinedButton";

const EditLadiesNight = () => {
  return(
    <Layout>
      <ContentWrapper additionalClass="p-5">
        <form className="row g-4">
          <Col md={6}>
            <label className="text-lg font-medium color-gray mb-3">
              Club name <span className="text-danger">*</span>
            </label>
            <input type="text" className="po-edit-input" />
          </Col>
          <Col md={6}>
            <label className="text-lg font-medium color-gray mb-3">
              Club Address <span className="text-danger">*</span>
            </label>
            <input type="text" className="po-edit-input" />
          </Col>
          <Col md={6}>
            <label className="text-lg font-medium color-gray mb-3">
              City Name <span className="text-danger">*</span>
            </label>
            <input type="text" className="po-edit-input" />
          </Col>
          <Col md={6}>
          <label className="text-lg font-medium color-gray mb-3">
              Choose days <span className="text-danger">*</span>
            </label>
            <div className="d-flex justify-content-between flex-wrap">
            <div>
              <input type="checkbox" id="Sun" name="Sun" value="Sun" />
              <label for="Sun" className="text-sm ms-2">
                {" "}
                Sun
              </label>
            </div>
            <div>
              <input type="checkbox" id="Mon" name="Mon" value="Mon" />
              <label for="Mon" className="text-sm ms-2">
                {" "}
                Mon
              </label>
            </div>
            <div>
              <input type="checkbox" id="Tue" name="Tue" value="Tue" />
              <label for="Tue" className="text-sm ms-2">
                {" "}
                Tue
              </label>
            </div>
            <div>
              <input type="checkbox" id="Wed" name="Wed" value="Wed" />
              <label for="Wed" className="text-sm ms-2">
                {" "}
                Wed
              </label>
            </div>
            <div>
              <input type="checkbox" id="Thu" name="Thu" value="Thu" />
              <label for="Thu" className="text-sm ms-2">
                {" "}
                Thu
              </label>
            </div>
            <div>
              <input type="checkbox" id="Fri" name="Fri" value="Fri" />
              <label for="Fri" className="text-sm ms-2">
                {" "}
                Fri
              </label>
            </div>
            <div>
              <input type="checkbox" id="Sat" name="Sat" value="Sat" />
              <label for="Sat" className="text-sm ms-2">
                {" "}
                Sat
              </label>
            </div>
            </div>
          </Col>
          <Col md={12} className="text-end">
              <OutlinedButton link="/ladies-night" name="Cancel" />
              <SuccessButton
                type="submit"
                name="Create"
                icon="bi-check-circle"
                // disabled={showToaster ? true : false}
                additionalClass="ms-4"
              />
          </Col>
        </form>
      </ContentWrapper>
    </Layout>
  )
}
export default EditLadiesNight;