import { Col, Row } from "react-bootstrap";
import StatsCard from "../../Global/StatsCard";
import { useState } from "react";
import { useEffect } from "react";
import { auth } from "../../../firebase";

const club = {
  title: "Lorem Ipsum",
  caption: "Since last Month",
  icon: "ternary"
}
const membership = {
  title: "Lorem Ipsum",
  caption: "Since last Month",
  icon: "danger"
}
const revenue = {
  title: "Revenue This Month",
  caption: "Since last Month",
  icon: "success"
}

const ManagerStats = ({ club_stats, membership_stats, profit_stats }) => {
  const loading = <span className="font-normal text-sm">Loading...</span>
  return (
    <>
      <Col xs={12} sm={6} lg={4}>
        {/* {club_stats && */}
        <StatsCard
          cardTitle={club.title}
          // cardStats={club_stats && club_stats.current_month_count ? +club_stats.current_month_count.toFixed(2) : loading}
          // cardProgress={statsData.club_stats && statsData.club_stats.rate}
          // growth={statsData.club_stats && statsData.club_stats.growth_up}
          // cardDuration={club.caption}
          cardIcon={club.icon}
        />
        {/* } */}
      </Col>
      <Col xs={12} sm={6} lg={4}>
        {/* {membership_stats && */}
        <StatsCard
          cardTitle={membership.title}
          // cardStats={membership_stats && membership_stats.current_month_count ? +membership_stats.current_month_count.toFixed(2) : loading}
          // cardProgress={statsData.membership_stats && statsData.membership_stats.rate}
          // growth={statsData.membership_stats && statsData.membership_stats.growth_up}
          // cardDuration={membership.caption}
          cardIcon={membership.icon}
        />
        {/* } */}
      </Col>
      <Col xs={12} sm={6} lg={4}>
        {/* {profit_stats && */}
        <StatsCard
          cardTitle={revenue.title}
          // cardStats={profit_stats && profit_stats.current_month_revenue ? `$ ${profit_stats.current_month_revenue.toFixed(2)} USD` : loading}
          // cardProgress={statsData.revenue_stats && statsData.revenue_stats.rate}
          // growth={statsData.revenue_stats && statsData.revenue_stats.growth_up}
          cardDuration="From booking and membership subscription"
          cardIcon={revenue.icon}
        />
        {/* } */}
      </Col>
    </>
  );
};
export default ManagerStats;
