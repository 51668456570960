import ReactApexChart from 'react-apexcharts';
import ContentWrapper from '../ContentWrapper';
import DateRange from '../DateRange';

const LineChart = ({ membership_members }) => {

  const chartData = {

    series: [{
      data: membership_members ? Object.values(membership_members) : []
    }],
    options: {
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: 'left',
      },
      chart: {
        type: 'bar',
        height: 380,
        fontFamily: 'Montserrat, sans-serif',
      },
      plotOptions: {
        bar: {
          barHeight: '100%',
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: 'bottom'
          },
        }
      },
      colors: [
        "#003f5c",
        "#444e86",
        "#955196",
        "#dd5182",
        "#ff6e54",
        "#ffa600"
      ],
      dataLabels: {
        enabled: true,
        textAnchor: 'start',
        style: {
          colors: ['#fff']
        },
        formatter: function (val, opt) {
          return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
        },
        offsetX: 0,
        dropShadow: {
          enabled: true
        }
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      xaxis: {
        categories: ['Solitaire', 'Platinum', 'Gold', 'Amethyst'
        ],
      },
      yaxis: {
        labels: {
          show: false
        }
      },
      // title: {
      //     text: '',
      //     align: 'center',
      //     floating: true
      // },
      // subtitle: {
      //     text: 'Category Names as DataLabels inside bars',
      //     align: 'center',
      // },
      tooltip: {
        // theme: 'dark',
        x: {
          show: true
        },
        y: {
          title: {
            formatter: function () {
              return ''
            }
          }
        }
      }
    },

  }
  return (
    // <ContentWrapper additionalClass="p-5 rounded font-body h-100">
    //    <div className="d-flex justify-content-between font-body">
    //     <div>
    //     <h2 className="text-lg font-bold color-blue font-body mb-0">Membership upgrades</h2>
    //     <p className="text-sm">Total upgrades: 200</p>
    //     </div>
    //     <div>
    //     <DateRange/>
    //     </div>
    //   </div>
    <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={450} />
    //  </ContentWrapper>
  )
}
export default LineChart;