// I am storing id of last item of previous page so that after page reload pagination should persist
import React, { useState, useEffect, useCallback, useRef } from "react";
import { Table } from "react-bootstrap";
import moment from "moment";
import SecondaryButton from "../Global/Buttons/SecondaryButton";
import { and, collection, doc, endAt, endBefore, getCountFromServer, getDoc, getDocs, limit, limitToLast, orderBy, query, startAfter, startAt, where } from "firebase/firestore";
import db from "../../firebase";
import Ripple from "../Global/Buttons/Ripple";
import Skeleton from "react-loading-skeleton";
import { Link, useSearchParams } from "react-router-dom";

const sortIcon = "/assets/icons/icon-sort.svg";
const sortIconAsc = "/assets/icons/icon-sort-asc.svg";
const sortIconDesc = "/assets/icons/icon-sort-desc.svg";

const TableBookings = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const count = useRef(0);
  const changeOrder = useRef(0);
  const nextPage = useRef(1);
  const currentPageId = useRef("");
  const sortBy = useRef({
    orderByKey: "createdAt",
    order: "desc"
  })
  const bookingsPerPage = useRef(20);
  const filterKeysRef = useRef({
    selectedLocations: [],
    selectedClubs: [],
    selectedStatus: []
  });
  const localSearchedArray = useRef([]);
  const targetElementForSort = useRef("");
  const searchQueryInputRef = useRef("");

  const [loader, setLoader] = useState(true);
  const [tableBookingData, setTableBookingData] = useState([]);
  const [totalBookings, setTotalBookings] = useState(null);
  const [pageCount, setPageCount] = useState(null);
  const pageNo = searchParams.get('pageNo');

  const fetchBookingsCount = async () => { // It is giving me total no. of club_entry_bookings and no. of pages
    const bookingsCount = await getCountFromServer(query(collection(db, "club_entry_bookings"), where("booking_type", "==", "table_booking")));
    setTotalBookings(bookingsCount.data().count);
    setPageCount(Math.ceil(bookingsCount.data().count / bookingsPerPage.current));
  }

  const pageReloadFunc = useCallback(async () => {
    bookingsPerPage.current = +searchParams.get('bookingsPerPage');
    setLoader(true);
    if (nextPage.current === 1) {
      const docSnap = count.current && await getDoc(doc(collection(db, "club_entry_bookings"), currentPageId.current));
      getDocs(query(collection(db, "club_entry_bookings"), where("booking_type", "==", "table_booking"), orderBy(sortBy.current.orderByKey, sortBy.current.order), startAfter(docSnap), limit(bookingsPerPage.current)))
        .then((docs) => {
          setTableBookingData([]);
          docs.forEach((doc) => {
            setTableBookingData((prev) => [...prev, doc.data()]);
          });
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
        });
    } else {
      const docSnap = count.current && await getDoc(doc(collection(db, "club_entry_bookings"), currentPageId.current));
      getDocs(query(collection(db, "club_entry_bookings"), where("booking_type", "==", "table_booking"), orderBy(sortBy.current.orderByKey, sortBy.current.order), endBefore(docSnap), limitToLast(+bookingsPerPage.current)))
        .then((docs) => {
          setTableBookingData([]);
          docs.forEach((doc) => {
            setTableBookingData((prev) => [...prev, doc.data()]);
          });
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  }, [nextPage])

  const fetchData = () => { // this function will execute on first render only when count value will be zero or clubPageNo will be 1 and if items per page will change
    fetchBookingsCount();
    setLoader(true);
    getDocs(query(collection(db, "club_entry_bookings"), where("booking_type", "==", "table_booking"), orderBy(sortBy.current.orderByKey, sortBy.current.order), limit(+bookingsPerPage.current)))
      .then((docs) => {
        setTableBookingData([]);
        docs.forEach((doc) => {
          setTableBookingData((prev) => [...prev, doc.data()]);
        });
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  }

  const previousPageDataFunc = async () => { // this function will be called when previous button clicked
    setLoader(true);
    count.current = count.current - 1;
    if (!count.current) {
      fetchData();
      setSearchParams({});
      return;
    }
    const docSnap = count.current && await getDoc(doc(collection(db, "club_entry_bookings"), tableBookingData[0].id));
    getDocs(query(collection(db, "club_entry_bookings"), where("booking_type", "==", "table_booking"), orderBy(sortBy.current.orderByKey, sortBy.current.order), endBefore(docSnap), limitToLast(+bookingsPerPage.current)))
      .then((docs) => {
        setTableBookingData([]);
        docs.forEach((doc) => {
          setTableBookingData((prev) => [...prev, doc.data()]);
        });
        if (Boolean(searchParams.get('tab'))) setSearchParams({ tab: searchParams.get('tab'), pageNo: count.current + 1, prevNext: JSON.stringify([docs.docs[0].id, docs.docs[docs.docs.length - 1].id]), currentPageId: docSnap.id, bookingsPerPage: bookingsPerPage.current, n: 0, sortBy: JSON.stringify(sortBy.current), changeOrder: changeOrder.current })
        else setSearchParams({ pageNo: count.current + 1, prevNext: JSON.stringify([docs.docs[0].id, docs.docs[docs.docs.length - 1].id]), currentPageId: docSnap.id, bookingsPerPage: bookingsPerPage.current, n: 0, sortBy: JSON.stringify(sortBy.current), changeOrder: changeOrder.current })
        // if count value will be greater than 0 then will set data in the url otherwise nothing needs to set in the url
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  }

  const nextPageDataFunc = async () => { // this will called when next button will called
    try {
      setLoader(true);
      count.current = count.current + 1;
      const docSnap = count.current && await getDoc(doc(collection(db, "club_entry_bookings"), tableBookingData[tableBookingData.length - 1].id));
      getDocs(query(collection(db, "club_entry_bookings"), where("booking_type", "==", "table_booking"), orderBy(sortBy.current.orderByKey, sortBy.current.order), startAfter(docSnap), limit(bookingsPerPage.current)))
        .then((docs) => {
          setTableBookingData([]);
          docs.forEach((doc) => {
            setTableBookingData((prev) => [...prev, doc.data()]);
          });
          if (Boolean(searchParams.get('tab'))) setSearchParams({ tab: searchParams.get('tab'), pageNo: count.current + 1, prevNext: JSON.stringify([docs.docs[0].id, docs.docs[docs.docs.length - 1].id]), currentPageId: docSnap.id, bookingsPerPage: bookingsPerPage.current, n: 1, sortBy: JSON.stringify(sortBy.current), changeOrder: changeOrder.current })
          else setSearchParams({ pageNo: count.current + 1, prevNext: JSON.stringify([docs.docs[0].id, docs.docs[docs.docs.length - 1].id]), currentPageId: docSnap.id, bookingsPerPage: bookingsPerPage.current, n: 1, sortBy: JSON.stringify(sortBy.current), changeOrder: changeOrder.current })
          /* setSearchParams => clubPageno will help to track the page no, prevNext is storing id of first and last
            item which will help to go prev and next page.
            currentPageId will help me to be on the page same page when page reload
          */
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
        });  
    } catch (error) {
      console.log(error,"tterrror")
    }
    
  }

  // Items Per Page Change **************************************************

  const handleItemsPerPage = (e) => { // I am recalling the fetchData func when items per page whenever change but not calling fetchBookingsCount because it won't change if items per page will change
    count.current = 0;
    setLoader(true);
    bookingsPerPage.current = +e.target.value;
    if (Boolean(searchParams.get('prevNext'))) {
      setSearchParams({ bookingsPerPage: bookingsPerPage.current })
    }
    if (searchParams.get('searchQuery') || searchParams.get('filterQuery')) {
      setSearchParams({ ...Object.fromEntries([...searchParams]), bookingsPerPage: bookingsPerPage.current })
    }
    if (Boolean(searchParams.get('sortBy')) && !Boolean(searchParams.get('filterQuery')) && !Boolean(searchParams.get('searchQuery'))) {
      setSearchParams({ sortBy: JSON.stringify(sortBy.current), changeOrder: changeOrder.current, bookingsPerPage: bookingsPerPage.current })
    }
    count.current = 0;
    if (!Boolean(searchParams.get('searchQuery')) && !Boolean(searchParams.get('filterQuery'))) {
      fetchData();
    }
    else if (Boolean(searchParams.get("searchQuery"))) fetchSearchedBookings(searchParams.get("searchQuery"));
    else if (Boolean(searchParams.get("filterQuery"))) {
      handleFilterBookings(JSON.parse(searchParams.get("filterQuery")))
    }
    else console.log('endingg');
  }

  // searching goes here ----------------------------------------------------------------------------------------
  const fetchSearchedBookings = useCallback(
    debounce((val) => {
      count.current = 0;
      if (val && searchQueryInputRef.current) {
        setLoader(true);
        const searchedBookingsByBookingId = query(collection(db, "club_entry_bookings"), where("booking_type", "==", "table_booking"), orderBy("booking_id"), where("booking_id", "==", +val));
        const searchedBookingsByUserName = query(collection(db, "club_entry_bookings"), where("booking_type", "==", "table_booking"), orderBy("user.name_lower_case"), startAt(val.replace(/\s+/g, ' ').trim().toLowerCase()), endAt(`${val.toLowerCase()}\uf8ff`));
        const searchedBookingsByClubName = query(collection(db, "club_entry_bookings"), where("booking_type", "==", "table_booking"), orderBy("club_name_lower_case"), startAt(val.replace(/\s+/g, ' ').trim().toLowerCase()), endAt(`${val.toLowerCase()}\uf8ff`));
        const searchedBookingsByUserEmail = query(collection(db, "club_entry_bookings"), where("booking_type", "==", "table_booking"), orderBy("user.email_lower_case"), startAt(val.replace(/\s+/g, ' ').trim().toLowerCase()), endAt(`${val.toLowerCase()}\uf8ff`));
        localSearchedArray.current = [];
        getDocs(searchedBookingsByBookingId).then((docSnapshots) => {
          docSnapshots.forEach((docs) => {
            localSearchedArray.current = [...localSearchedArray.current, docs.data()]
          });
        });
        getDocs(searchedBookingsByUserName).then((docSnapshots) => {
          docSnapshots.forEach((docs) => {
            localSearchedArray.current = [...localSearchedArray.current, docs.data()]
          });
        });
        getDocs(searchedBookingsByClubName).then((docSnapshots) => {
          docSnapshots.forEach((docs) => {
            localSearchedArray.current = [...localSearchedArray.current, docs.data()]
          });
        });
        getDocs(searchedBookingsByUserEmail).then((docSnapshots) => {
          docSnapshots.forEach((docs) => {
            localSearchedArray.current = [...localSearchedArray.current, docs.data()]
          });
          uniqueSearchedBookings(localSearchedArray.current, val);
          setLoader(false);
        });
        searchParams.delete('filterQuery');
      }
    }, 1000),
    []);

  const uniqueSearchedBookings = (localSearchedArray, val) => {
    switch (+changeOrder.current) {
      case 0:
        setTableBookingData([...new Map(localSearchedArray.map(item =>
          [item['id'], item])).values()].splice(0, bookingsPerPage.current));
        break;
      case 1:
        setTableBookingData([...new Map(localSearchedArray.map(item =>
          [item['id'], item])).values()].sort((a, b) => (targetElementForSort.current === "user.name_lower_case" ? a.user.name_lower_case : a[targetElementForSort.current]) > (targetElementForSort.current === "user.name_lower_case" ? b.user.name_lower_case : b[targetElementForSort.current]) ? 1 : (targetElementForSort.current === "user.name_lower_case" ? a.user.name_lower_case : a[targetElementForSort.current]) < (targetElementForSort.current === "user.name_lower_case" ? b.user.name_lower_case : b[targetElementForSort.current]) ? -1 : 0).splice(0, bookingsPerPage.current));
        break;
      default:
        setTableBookingData([...new Map(localSearchedArray.map(item =>
          [item['id'], item])).values()].sort((a, b) => (targetElementForSort.current === "user.name_lower_case" ? a.user.name_lower_case : a[targetElementForSort.current]) < (targetElementForSort.current === "user.name_lower_case" ? b.user.name_lower_case : b[targetElementForSort.current]) ? 1 : (targetElementForSort.current === "user.name_lower_case" ? a.user.name_lower_case : a[targetElementForSort.current]) > (targetElementForSort.current === "user.name_lower_case" ? b.user.name_lower_case : b[targetElementForSort.current]) ? -1 : 0).splice(0, bookingsPerPage.current));

    }
    let totalBookingsCount = [...new Map(localSearchedArray.map(item =>
      [item['id'], item])).values()].length;
    setTotalBookings(totalBookingsCount);
  }

  function debounce(fn, delay) {
    let timer;
    return function () {
      let context = this,
        args = arguments;
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(context, args);
      }, delay);
    };
  }

  // filter goes here *****************************************************************************************

  const handleFilterBookings = async (filterState) => {
    count.current = 0
    setLoader(true);
    let arr = [];
    filterKeysRef.current = filterState;
    searchQueryInputRef.current = "";

    // --------------------------------------------------------------------------------------------------------

    let selectedLocations = filterKeysRef.current.selectedLocations.length;
    let selectedClubs = filterKeysRef.current.selectedClubs.length;
    let filteredStatus = filterKeysRef.current.selectedStatus.length;
    if (selectedLocations && selectedClubs && filteredStatus) {
      arr = [where("club_location", "in", filterKeysRef.current.selectedLocations), where("club_id", "in", filterKeysRef.current.selectedClubs.map(item => item.id)), where("status", "in", filterKeysRef.current.selectedStatus)]
    } else if (selectedLocations && selectedClubs) {
      arr = [where("club_id", "in", filterKeysRef.current.selectedClubs.map(item => item.id)), where("club_location", "in", filterKeysRef.current.selectedLocations)]
    } else if (selectedClubs && filteredStatus) {
      arr = [where("club_id", "in", filterKeysRef.current.selectedClubs.map(item => item.id)), where("status", "in", filterKeysRef.current.selectedStatus)]
    } else if (selectedLocations && filteredStatus) {
      arr = [where("club_location", "in", filterKeysRef.current.selectedLocations), where("status", "in", filterKeysRef.current.selectedStatus)]
    } else if (selectedClubs) {
      arr = [where("club_id", "in", filterKeysRef.current.selectedClubs.map(item => item.id))]
    } else if (filteredStatus) {
      arr = [where("status", "in", filterKeysRef.current.selectedStatus)]
    } else if (selectedLocations) {
      arr = [where("club_location", "in", filterKeysRef.current.selectedLocations)]
    } else console.log("not applicable");
    let bookings_data = (selectedLocations || selectedClubs || filteredStatus) && await getDocs(query(collection(db, "club_entry_bookings"), and(where("booking_type", "==", "table_booking"), ...arr), orderBy(sortBy.current.orderByKey, sortBy.current.order)))
    if (bookings_data) {
      let localFilteredBookings = []
      bookings_data.size && bookings_data.forEach(event => {
        localFilteredBookings = [...localFilteredBookings, event.data()];
      })
      setTableBookingData([...localFilteredBookings].splice(0, bookingsPerPage.current));
      setTotalBookings(bookings_data.size);
      setLoader(false);
    }
  }

  // show all searched booking or filtered bookings

  const handleShowAll = () => {
    setLoader(true);
    bookingsPerPage.current = +totalBookings;
    setSearchParams({ ...Object.fromEntries([...searchParams]), bookingsPerPage: bookingsPerPage.current, showAll: true })
  }

  // sorting *******************************************************************************************************

  const handleSorting = (e, localId) => {
    setLoader(true);
    if (targetElementForSort.current !== localId) {
      changeOrder.current = 0;
      sortBy.current = { orderByKey: localId, order: "asc" }
      targetElementForSort.current = localId;
    }
    if (count.current) { // coming back to first page when you will be on other page
      count.current = 0;
      sortBy.current = { orderByKey: localId, order: "asc" }
      fetchData();
      setSearchParams({ bookingsPerPage: bookingsPerPage.current, sortBy: JSON.stringify(sortBy.current), changeOrder: 1 })
      return;
    }
    if (searchParams.get('searchQuery') || searchParams.get('filterQuery')) {
      if (Boolean(searchParams.get('searchQuery'))) {
        if (!changeOrder.current) {
          changeOrder.current = changeOrder.current + 1;
          sortBy.current = { orderByKey: targetElementForSort.current, order: "asc" }
          setTableBookingData([...tableBookingData].sort((a, b) => a[targetElementForSort.current] > b[targetElementForSort.current] ? 1 : a[targetElementForSort.current] < b[targetElementForSort.current] ? -1 : 0).splice(0, bookingsPerPage.current));
        }
        else if (changeOrder.current === 1) {
          changeOrder.current = changeOrder.current + 1;
          sortBy.current = { orderByKey: targetElementForSort.current, order: "desc" }
          setTableBookingData([...tableBookingData].sort((a, b) => a[targetElementForSort.current] < b[targetElementForSort.current] ? 1 : a[targetElementForSort.current] > b[targetElementForSort.current] ? -1 : 0).splice(0, bookingsPerPage.current));
        }
        else {
          changeOrder.current = 0;
          setLoader(true);
          sortBy.current = { orderByKey: "createdAt", order: "desc" }
          fetchSearchedBookings(searchParams.get('searchQuery'));
        }
      }
      else {
        if (!changeOrder.current) {
          changeOrder.current = changeOrder.current + 1;
          sortBy.current = { orderByKey: targetElementForSort.current, order: "asc" }
          setTableBookingData([...tableBookingData].sort((a, b) => a[targetElementForSort.current] > b[targetElementForSort.current] ? 1 : a[targetElementForSort.current] < b[targetElementForSort.current] ? -1 : 0).splice(0, bookingsPerPage.current));
        }
        else if (changeOrder.current === 1) {
          changeOrder.current = changeOrder.current + 1;
          sortBy.current = { orderByKey: targetElementForSort.current, order: "desc" }
          setTableBookingData([...tableBookingData].sort((a, b) => a[targetElementForSort.current] < b[targetElementForSort.current] ? 1 : a[targetElementForSort.current] > b[targetElementForSort.current] ? -1 : 0).splice(0, bookingsPerPage.current));
        }
        else {
          changeOrder.current = 0;
          setLoader(true);
          sortBy.current = { orderByKey: "createdAt", order: "desc" }
          handleFilterBookings(JSON.parse(searchParams.get('filterQuery')));
        }
      }
    }
    else {
      if (!changeOrder.current) {
        changeOrder.current = changeOrder.current + 1;
        sortBy.current = { orderByKey: targetElementForSort.current, order: "asc" }
      }
      else if (changeOrder.current === 1) {
        changeOrder.current = changeOrder.current + 1;
        sortBy.current = { orderByKey: targetElementForSort.current, order: "desc" }
      }
      else {
        changeOrder.current = 0;
        sortBy.current = { orderByKey: "createdAt", order: "desc" }
      }
    }
    const currentParams = Object.fromEntries([...searchParams]);
    setSearchParams({ ...currentParams, sortBy: JSON.stringify(sortBy.current), changeOrder: changeOrder.current });
  }

  // useEffect goes here ----------------------------------------------------------------------------------------

  useEffect(() => {
    if (Boolean(searchParams.get('bookingsPerPage'))) bookingsPerPage.current = +searchParams.get('bookingsPerPage'); // this will be used when searching or filter will be removed and show all will be applied
    if (Boolean(searchParams.get('showAll'))) {
      if (Boolean(searchParams.get('searchQuery'))) fetchSearchedBookings(searchParams.get('searchQuery'))
      if (Boolean(searchParams.get('filterQuery'))) handleFilterBookings(JSON.parse(searchParams.get('filterQuery')))
      return;
    }
    if (Boolean(JSON.parse(searchParams.get('sortBy')))) {
      sortBy.current = JSON.parse(searchParams.get('sortBy'));
      targetElementForSort.current = sortBy.current.orderByKey;
      changeOrder.current = +searchParams.get('changeOrder');
      if (Boolean(searchParams.get('searchQuery')) && !Boolean(searchParams.get('prevNext'))) { // searching is applied but not pagination
        searchQueryInputRef.current = searchParams.get('searchQuery')
        filterKeysRef.current = {
          selectedLocations: [],
          selectedClubs: [],
          selectedStatus: []
        };
        fetchSearchedBookings(searchParams.get('searchQuery'));
        return
      }
      if (Boolean(searchParams.get('filterQuery')) && !Boolean(searchParams.get('prevNext'))) {
        searchQueryInputRef.current = "";
        filterKeysRef.current = JSON.parse(searchParams.get('filterQuery'));
        handleFilterBookings(JSON.parse(searchParams.get('filterQuery')));
        return
      }
      if (!Boolean(searchParams.get('filterQuery')) && !Boolean(searchParams.get('prevNext')) && !Boolean(searchParams.get('searchQuery'))) {
        searchQueryInputRef.current = "";
        filterKeysRef.current = {
          selectedLocations: [],
          selectedClubs: [],
          selectedStatus: []
        };
        count.current = 0;
        fetchData();
      }
    }
    if (Boolean(searchParams.get('bookingsPerPage')) && searchParams.size === 1 && !tableBookingData.length) { // if page will be refreshed...
      bookingsPerPage.current = +searchParams.get('bookingsPerPage');
      count.current = 0
      fetchData();
    }
    if (Boolean(searchParams.get('searchQuery')) || Boolean(searchParams.get('filterQuery'))) {
      if (Boolean(searchParams.get('sortBy'))) sortBy.current = JSON.parse(searchParams.get('sortBy'));
      if (Boolean(searchParams.get('searchQuery'))) {
        searchQueryInputRef.current = searchParams.get('searchQuery')
        filterKeysRef.current = {
          selectedLocations: [],
          selectedClubs: [],
          selectedStatus: []
        };
        fetchSearchedBookings(searchParams.get('searchQuery'));
      } if (Boolean(searchParams.get('filterQuery'))) {
        searchQueryInputRef.current = "";
        filterKeysRef.current = JSON.parse(searchParams.get('filterQuery'));
        handleFilterBookings(JSON.parse(searchParams.get('filterQuery')));
      }
    }
    if (!searchParams.size || (searchParams.get('tab') && searchParams.size === 1) || (!Boolean(searchParams.get('searchQuery')) && !Boolean(searchParams.get('filterQuery')) && !Boolean(searchParams.get("prevNext")) && !Boolean(searchParams.get("bookingsPerPage")))) {
      count.current = 0;
      bookingsPerPage.current = 20;
      searchQueryInputRef.current = "";
      fetchData();
      return;
    }
    if (Boolean(searchParams.get('prevNext')) && (!tableBookingData.length)) {
      nextPage.current = +searchParams.get("n"); // for tracking which button is clicked next or prev. it will help when page reload to identify which query should be used in order to reload the page(startAfter or lastToEnd)
      count.current = Boolean(pageNo) ? pageNo - 1 : count.current;
      currentPageId.current = searchParams.get('currentPageId');
      fetchBookingsCount();
      pageReloadFunc();
      return;
    }
    if (!Boolean(searchParams.get('filterQuery'))) {
      filterKeysRef.current = {
        selectedLocations: [],
        selectedClubs: [],
        selectedStatus: []
      }
    }
    if (filterKeysRef.current && filterKeysRef.current.selectedClubs && filterKeysRef.current.selectedStatus && !filterKeysRef.current.selectedClubs.length && !filterKeysRef.current.selectedStatus.length && !Boolean(searchParams.get('searchQuery')) && !Boolean(searchParams.get('filterQuery')) && !Boolean(searchParams.get('prevNext'))) {
      fetchData();
    }
  }, [searchParams]);
  // useEffect ends here***********************************************************
  const TableBookingEntries = ({ tableBookingData }) => {
    return (
      <div className="table-responsive po-table tableFixHead-tabs">
        <Table hover >
          <thead>
            <tr className="background-gray10 position-sticky top-0 z-10">
              <th>
                <p className="text-nowrap text-xs text-xl-sm text-uppercase ps-3 color-black2 mb-0 py-2">
                  S. No
                </p>
              </th>
              <th valign="middle">
                <span
                  onClick={(e) => handleSorting(e, "booking_id")}
                  className={`cursor-pointer text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2 d-inlin-flex align-items-center ${targetElementForSort.current !== "booking_id" || !changeOrder.current ? "" : "color-yellow1"}`}>
                  Booking ID
                  <img
                    className="ms-1"
                    src={(targetElementForSort.current !== "booking_id" || !changeOrder.current) ? sortIcon : changeOrder.current === 1 ? sortIconAsc : sortIconDesc}
                    alt="sort-icon" height={12} />
                </span>
              </th>
              <th valign="middle">
                <span
                  onClick={(e) => handleSorting(e, "user.name_lower_case")}
                  className={`cursor-pointer text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2 d-inline-flex align-items-center ${targetElementForSort.current !== "user.name_lower_case" || !changeOrder.current ? "" : "color-yellow1"}`}>
                  Name
                  <img
                    className="ms-1"
                    src={(targetElementForSort.current !== "user.name_lower_case" || !changeOrder.current) ? sortIcon : changeOrder.current === 1 ? sortIconAsc : sortIconDesc}
                    alt="sort-icon" height={12} />
                </span>
              </th>
              <th>
                <p className="text-nowrap text-xs text-xl-sm text-uppercase  color-black2 mb-0 py-2">
                  Phone no
                </p>
              </th>
              <th>
                <p className="text-nowrap text-xs text-xl-sm text-uppercase  color-black2 mb-0 py-2">
                  Email ID
                </p>
              </th>
              <th valign="middle">
                <span
                  onClick={(e) => handleSorting(e, "club_name_lower_case")}
                  className={`cursor-pointer text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2 d-inline-flex align-items-center ${targetElementForSort.current !== "club_name_lower_case" || !changeOrder.current ? "" : "color-yellow1"}`}>
                  CLUB NAME
                  <img
                    className="ms-1"
                    src={(targetElementForSort.current !== "club_name_lower_case" || !changeOrder.current) ? sortIcon : changeOrder.current === 1 ? sortIconAsc : sortIconDesc} alt="sort-icon" height={12} />
                </span>
              </th>
              <th>
                <p className="text-nowrap text-xs text-xl-sm text-uppercase  color-black2 mb-0 py-2">
                  CLUB LOGO
                </p>
              </th>
              <th>
                <p className="text-nowrap text-xs text-xl-sm text-uppercase  color-black2 mb-0 py-2">
                  guest COUNT
                </p>
              </th>
              <th>
                <p className="text-nowrap text-xs text-xl-sm text-uppercase  color-black2 mb-0 py-2">
                  Current Membership
                </p>
              </th>
              <th valign="middle">
                <span
                  onClick={(e) => handleSorting(e, "createdAt")}
                  className={`cursor-pointer text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2 d-inline-flex align-items-center ${targetElementForSort.current !== "createdAt" || !changeOrder.current ? "" : "color-yellow1"}`}>
                  Booked On
                  <img
                    className="ms-1"
                    src={(targetElementForSort.current !== "createdAt" || !changeOrder.current) ? sortIcon : changeOrder.current === 1 ? sortIconAsc : sortIconDesc} alt="sort-icon" height={12} />
                </span>
              </th>
              <th valign="middle">
                <span
                  onClick={(e) => handleSorting(e, "booking_date")}
                  className={`cursor-pointer text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2 d-inline-flex align-items-center ${targetElementForSort.current !== "booking_date" || !changeOrder.current ? "" : "color-yellow1"}`}>
                  BOOKED FOR
                  <img
                    className="ms-1"
                    src={(targetElementForSort.current !== "booking_date" || !changeOrder.current) ? sortIcon : changeOrder.current === 1 ? sortIconAsc : sortIconDesc} alt="sort-icon" height={12} />
                </span>
              </th>
              <th valign="middle">
                <span
                  onClick={(e) => handleSorting(e, "status")}
                  className={`cursor-pointer text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2 d-inline-flex align-items-center ${targetElementForSort.current !== "status" || !changeOrder.current ? "" : "color-yellow1"}`}>
                  STATUS
                  <img
                    className="ms-1"
                    src={(targetElementForSort.current !== "status" || !changeOrder.current) ? sortIcon : changeOrder.current === 1 ? sortIconAsc : sortIconDesc} alt="sort-icon" height={12} />
                </span>
              </th>
              <th>
                <p className="text-nowrap text-xs text-xl-sm text-uppercase  color-black2 mb-0 py-2"></p>
              </th>
            </tr>
          </thead>
          <tbody>
            {!loader ? (
              <>
                {tableBookingData && tableBookingData.length
                  ? tableBookingData.map((table, index) => {
                    const date = moment(
                      new Date(
                        table.booking_date.seconds * 1000
                      ).toISOString()
                    ).format("DD MMM YYYY");
                    const booking_date = moment(
                      new Date(
                        table.createdAt.seconds * 1000
                      ).toISOString()
                    ).format("DD MMM YYYY");
                    return (
                      <tr key={index}>
                        <td className="align-middle">
                          <p className="ps-3 text-sm mb-0 text-nowrap text-black">
                            {count.current * bookingsPerPage.current + index + 1}
                          </p>
                        </td>
                        <td className="align-middle">
                          {" "}
                          <p className="text-sm mb-0 text-nowrap text-black">
                            {table && table.booking_id}
                          </p>
                        </td>
                        <td className="align-middle">
                          {" "}
                          <Link to={`/bookings/table-details/${table.id}`} className="text-sm mb-0 text-nowrap text-black text-capitalize" target="_blank">
                            {table && table.user.name}
                          </Link>
                        </td>
                        <td className="align-middle">
                          {" "}
                          <p className="text-sm mb-0 text-nowrap text-black">
                            {table.user && table.user.phone_no
                              ? table.user.phone_no
                              : ""}
                          </p>
                        </td>
                        <td className="align-middle">
                          {" "}
                          <p className="text-sm mb-0 text-nowrap text-black">
                            {table.user && table.user.email_lower_case
                              ? table.user.email_lower_case
                              : ""}
                          </p>
                        </td>
                        <td className="align-middle">
                          {" "}
                          <p
                            className="text-sm mb-0 text-wrap text-black"
                            style={{ maxWidth: "250px", minWidth: "150px", lineHeight: "20px" }}
                          >
                            {table && table.club_name}
                          </p>
                        </td>
                        <td className="align-middle" align="center">
                          <div
                            className="d-flex justify-content-center align-items-center border rounded overflow-hidden"
                            style={{ height: "60px", width: "60px" }}
                          >
                            {table.club_display_image ? (
                              <img
                                src={table.club_display_image}
                                className="rounded po-objectFit--contain po-objectPosition"
                                width="100%"
                                height="100%"
                                alt=""
                              />
                            ) : (
                              <i className="bi bi-image text-5xl"></i>
                            )}
                          </div>
                        </td>
                        <td className="align-middle">
                          {" "}
                          <p className="text-sm mb-0 text-nowrap text-center text-black">
                            {table && table.attendee_count + 1}
                          </p>
                        </td>
                        <td className="align-middle">
                          {" "}
                          <p className="text-sm mb-0 text-nowrap text-black">
                            {table && table.user.membership_name ? table.user.membership_name : "No Membership"}
                          </p>
                        </td>
                        <td className="align-middle">
                          {" "}
                          <p className="text-sm mb-0 text-nowrap text-black">
                            {booking_date}
                          </p>
                        </td>
                        <td className="align-middle">
                          {" "}
                          <p className="text-sm mb-0 text-nowrap text-black">
                            {date}
                          </p>
                        </td>
                        <td className="align-middle">
                          <div className="d-flex align-items-center justify-content-start">
                            {table.status === "Pending" ? (
                              <Ripple color="#FFAE1F" />
                            ) : (
                              <div
                                style={{
                                  height: "10px",
                                  width: "10px",
                                  backgroundColor: `${table.status === "Approved"
                                    ? "#13DEB9"
                                    : table.status ===
                                      "Pending"
                                      ? "#FFAE1F"
                                      : table.status === "Rejected"
                                        ? "#FA896B"
                                        : table.status === "Cancelled"
                                          ? "#FA896B"
                                          : table.status === "Completed"
                                            ? "#13DEB9"
                                            : table.status === "Waiting for payment verification"
                                              ? "#FFAE1F"
                                              : null
                                    }`,
                                }}
                                className="rounded-circle flex-shrink-0"
                              ></div>
                            )}
                            <div className="ms-2 text-black text-sm text-nowrap">
                              {table.status === "Approved"
                                ? "Approved"
                                : table.status === "Pending"
                                  ? "Pending"
                                  : table.status === "Rejected"
                                    ? "Rejected"
                                    : table.status === "Cancelled"
                                      ? "Cancelled"
                                      : table.status === "Completed"
                                        ? "Completed"
                                        : table.status === "Waiting for payment verification"
                                          ? "Waiting for payment verification"
                                          : null}
                            </div>
                          </div>
                        </td>
                        <td className="align-middle">
                          <SecondaryButton
                            link={`/bookings/table-details/${table.id}`}
                            name="View"
                            target="_blank"
                          />
                        </td>
                      </tr>
                    )
                  })
                  : <tr><td colSpan={10} className="text-center text-2xl font-bold">No Data Found</td></tr>}
              </>
            ) : (
              <tr>
                <td colSpan={14}>
                  <Skeleton
                    inline={true}
                    count={5}
                    height={80}
                    style={{ marginBottom: "1rem" }}
                    baseColor="#dedede"
                    highlightColor="#cccccc"
                    duration={2}
                  />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    );
  };
  return (
    <>
      <TableBookingEntries
        tableBookingData={tableBookingData}
      />
      {tableBookingData.length ? <div className="d-flex gap-2 justify-content-end align-items-center py-4 me-4 text-xs">
        {(Boolean(searchParams.get('searchQuery')) || Boolean(searchParams.get('filterQuery'))) && totalBookings && !loader ? <div className={`my-3 text-md ${tableBookingData.length !== totalBookings ? "mx-12" : ""}`} >No. of bookings displaying: <span className="font-bold ms-3">{totalBookings}</span></div> : ""}
        {(tableBookingData.length !== totalBookings) ? <div>
          Rows per page:
          <div className="d-inline-block ms-1 ms-xl-3">
            <select
              name="item-per-page"
              id="item-per-page"
              value={bookingsPerPage.current}
              className="border-0 shadow-none font-medium"
              onChange={handleItemsPerPage}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
        </div> : ""}
        {(Boolean(searchParams.get('searchQuery')) || Boolean(searchParams.get('filterQuery'))) && !loader && totalBookings > tableBookingData.length ?
          <button
            className="bg-transparent border-1 rounded-3 ml-12"
            type="button"
            onClick={handleShowAll}
          >Show All</button> : ""}
        {!Boolean(searchParams.get('searchQuery')) && !Boolean(searchParams.get('filterQuery')) ? <div className="d-flex align-items-center gap-3">
          {!loader && tableBookingData.length ? <div className="mx-6 mx-xl-12 font-medium">
            {count.current * bookingsPerPage.current + 1}-{(count.current + 1) * bookingsPerPage.current < totalBookings ? (count.current + 1) * bookingsPerPage.current : totalBookings} of {totalBookings}
          </div> : <div className="mx-6 mx-xl-12 font-medium">loading...</div>}
          <button className={`font-body font-bold rounded-circle po-paginationBtn d-inline-block bg-transparent ${(!count.current || loader) ? "po-border border-secondary" : "color-black1 po-border po-border-yellow1"}`} type="button"
            onClick={previousPageDataFunc}
            disabled={!count.current || loader}
          ><i className="bi bi-chevron-left text-black font-black text-lg"></i>
          </button>
          <button className={`font-body font-bold rounded-circle po-paginationBtn po-paginationBtn--next d-inline-block bg-transparent ${(pageCount - 1 === count.current) || loader ? "po-border border-secondary" : "color-black1 po-border po-border-yellow1"}`} type="button"
            onClick={nextPageDataFunc}
            disabled={(pageCount - 1 === count.current) || loader}><i className="bi bi-chevron-right text-black font-black text-lg"></i></button>
        </div> : ""}
      </div> : ""}
    </>
  );
};
export default React.memo(TableBookings);
