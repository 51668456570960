import { Link } from "react-router-dom";
const SuccessButton = ({
  name,
  link,
  onClick,
  type,
  icon,
  additionalClass,
  disabled
}) => {
  return (
    <>
      {link ? (
        <Link
          to={link}
          className={`${additionalClass} text-white text-md font-body cursor-pointer background-lightgreen py-3 px-4 radius-105 border-0 d-inline-block`}
        >
          {icon ? (
            <i className={`bi ${icon} inline-block text-white me-2 mt-1`}></i>
          ) : null}{" "}
          {name}
        </Link>
      ) : onClick ? (
        <button
          className={`${additionalClass} text-white text-md font-body cursor-pointer background-lightgreen py-3 px-4 radius-105 border-0 d-inline-block`}
          onClick={onClick}
          disabled={disabled}
        >
          {icon ? (
            <i className={`bi ${icon} inline-block text-white me-2 mt-1`}></i>
          ) : null}{" "}
          {name}
        </button>
      ) : type ? (
        <button
          
          className={`${additionalClass} text-white text-md font-body  cursor-pointer background-lightgreen py-3 px-4 radius-105 border-0 d-inline-block`}
          type={type}
          disabled={disabled}
        >
          {icon ? (
            <i className={`bi ${icon} inline-block text-white me-2 mt-1`}></i>
          ) : null}{" "}
          {name}
        </button>
      ) : null}
    </>
  );
};
export default SuccessButton;
