import ContentWrapper from "../../../Components/Global/ContentWrapper";
import Layout from "../../../Layout/LayoutManager/Layout";
import { Row, Col, Button, Table } from "react-bootstrap";
// import SuccessButtonCheck from "../Components/Global/Buttons/SuccessButtonCheck";
// import db from "../../firebase";
import { useEffect, useState } from "react";
import { collection, doc, getDocs, orderBy, query, where } from "firebase/firestore";
import db from "../../../firebase";
// import PrimaryInput from "../../Components/UI/PrimaryInput";
import OutlinedButton from "../../../Components/Global/Buttons/OutlinedButton";
import SuccessButton from "../../../Components/Global/Buttons/SuccessButton";
import ActionIcon from "../../../Components/Global/Buttons/ActionIcons";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import Toaster from "../../../Components/Global/Toast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ManagerCreateTableBooking = () => {
  const [clubs, setClubs] = useState([]);
  const [users, setUsers] = useState([]);
  const [tableDetails, setTableDetails] = useState([]) // fetched from clubs
  const [nonOperationalDays, setNonOperationalDays] = useState([]);
  const [country, setCountry] = useState({
    country: "",
    timezone: ""
  })
  const navigate = useNavigate();
  const [category, setCategory] = useState({
    drinkDropdown: "",
    smokeDropdown: ""
  })
  const [preferredProducts, setPreferredProducts] = useState({
    drink_category: [],
    smoke_category: []
  })
  const [countries, setCountries] = useState(null)
  const [selectedPreferredProducts, setSelectedPreferredProducts] = useState({
    category: "",
    category_name: "",
    brand_name: "",
    product_type: "",
    errors: {
      brand_name: "",
      category_name: ""
    }
  })
  const [productBrands, setProductBrands] = useState([]);
  const [showToaster, setShowToaster] = useState(false);
  const [toasterDetails, setToasterDetails] = useState({
    title: "",
    bodyText: "",
    bg: "",
    statusIcon: "",
  });
  const token = localStorage.getItem('authenticate');
  const [timeSlots, setTimeSlots] = useState([]);
  const [guestDetails, setGuestDetails] = useState({
    guest_name: "",
    gender: "",
    age: "",
    errors: {
      guest_name: "",
      gender: "",
      age: ""
    }
  })
  const [state, setState] = useState({
    club_name: "",
    club_id: "",
    user_name: "",
    user_id: "",
    tables: [],   // will use for submit form
    amount: "",
    preferred_products: [],
    people: [],
    booking_date: "",
    expected_arrival_time: "",
    errors: {
      club_name: "",
      user_name: "",
      tables: "",
      amount: "",
      people: "",
      booking_date: "",
      expected_arrival_time: "",
    }
  });
  const [toggleProducts, setToggleProducts] = useState(true);
  // fetching clubs details ***********************************
  const fetchClubs = () => {
    getDocs(query(
      collection(db, "clubs"),
      orderBy("name"))).then((docSnapshots) => {
        setClubs([]);
        docSnapshots.forEach((docs) => {
          if (docs.data().latitude && docs.data().longitude) {
            setClubs((prev) => [...prev, docs.data()]);
          }
        });
      });
  }

  //Getting All Countries
  const getAllCountries = async () => {
    await getDocs(collection(db, "countries")).then((countries) => setCountries(countries.docs)).catch((error) => console.error(error))
  }
  useEffect(() => {
    getAllCountries()
  }, [])

  // fetching users details *******************************************
  const fetchUsers = () => {
    getDocs(query(
      collection(db, "users"),
      orderBy("name"))).then((docSnapshots) => {
        setUsers([]);
        docSnapshots.forEach((docs) => {
          if(docs.data().active_membership_name) setUsers((prev) => [...prev, docs.data()]);
        });
      });
  }
  useEffect(() => {
    // getAllCountries();
    fetchClubs();
    fetchUsers();
  }, []);

  const formValidation = () => {
    const errors = state.errors;
    let validate = true;
    if (state.club_name === "") {
      errors.club_name = "Please select club name";
      validate = false;
    }
    if (state.user_name === "") {
      errors.user_name = "Please select user name";
      validate = false;
    }
    if (!state.tables.length) {
      errors.tables = "Please select atleast one table";
      validate = false;
    }
    if (state.booking_date === "") {
      errors.booking_date = "Please select booking date";
      validate = false;
    }
    if (state.expected_arrival_time === "") {
      errors.expected_arrival_time = "Please select entry time slot";
      validate = false;
    }
    if (state.amount === "") {
      errors.amount = "Please enter amount";
      validate = false;
    }
    setState(prev => ({
      ...prev,
      errors
    }))
    return validate
  }

  const handleFormSubmit = (e) => {
    e.preventDefault();
    let validateForm = formValidation()
    if (validateForm) {
      setShowToaster(true);
      setGuestDetails(prev => ({
        ...prev,
        guest_name: "",
        gender: "",
        age: "",
        errors: {
          guest_name: "",
          gender: "",
          age: ""
        }
      }))
      setSelectedPreferredProducts({
        category: "",
        category_name: "",
        brand_name: "",
        product_type: "",
        errors: {
          category_name: "",
          brand_name: ""
        }
      })
      setCategory({
        drinkDropdown: "",
        smokeDropdown: ""
      })
      setToasterDetails((prev) => ({
        ...prev,
        title: "Creating...",
        bodyText: "Creating Club Table Booking",
        bg: "warning",
        statusIcon: (
          <div className="spinner-border text-warning me-2" role="status"></div>
        ),
      }));
      let obj = {
        ...state,
        booking_type: "table_booking",
        attendee_count: state.people.length
      }
      delete obj.user_name;
      delete obj.errors;
      let config = {
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/booking/v2/clubBooking`,
        headers: {
          'developement': 'true',
          'token': token,
          'uid': obj.user_id,
          'Content-Type': 'application/json'
        },
        data: obj
      };
      axios.request(config)
        .then((response) => {
          if (response.data.success) {
            setToasterDetails((prev) => ({
              ...prev,
              title: "Success",
              bodyText: "Club Entry Booking Details are added successfully",
              bg: "success",
              statusIcon: (
                <i className="bi bi-check text-success text-3xl me-2"></i>
              ),
            }));
            setTimeout(() => {
              navigate(`/bookings?tab=table`, { replace: true });
            }, 2000);
          }
          else {
            setToasterDetails((prev) => ({
              ...prev,
              title: "Error",
              bodyText: response.data.message,
              bg: "danger",
              statusIcon: (
                <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
              ),
            }));
            setTimeout(() => {
              setShowToaster(false);
            }, 2000);
          }

          setState(prev => ({
            ...prev,
            club_name: "",
            club_id: "",
            user_name: "",
            user_id: "",
            tables: [],   // will use for submit form
            amount: "",
            people: [],
            booking_date: "",
            expected_arrival_time: "",
          }))
          setGuestDetails(prev => ({
            ...prev,
            guest_name: "",
            gender: "",
            age: ""
          }))
          setTableDetails([]);
        })
        .catch((error) => {
          setToasterDetails((prev) => ({
            ...prev,
            title: "Error",
            bodyText: "Something went wrong, Please try again",
            bg: "danger",
            statusIcon: (
              <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
            ),
          }));
          setTimeout(() => {
            setShowToaster(false);
          }, 2000);
        });
    }
    else {
      console.log("Please fill all the required fields");
    }
  };

  const handleDateChange = (date) => {
    if(date) {
      setState(prev => ({
        ...prev,
        booking_date: date.setHours(5, 30),
        errors: {
          ...state.errors,
          booking_date: ""
        }
      }))
    }
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    if (name !== "amount" || name !== "club_name" || name !== "user_name") {
      setState(prev => ({
        ...prev,
        [name]: value,
      }))
    }
    if (name === "amount") {
      setState(prev => ({
        ...prev,
        amount: value ? +value : "",
      }))
      if (value < 0) {
        setState(prev => ({
          ...prev,
          amount: "",
          errors: {
            ...state.errors,
            amount: "Please enter valid amount"
          }
        }))
      }
    }
    if (name === "club_name") {
      setState(prev => ({
        ...prev,
        tables: []
      }))
      if (value === "") {
        setState(prev => ({
          ...prev,
          [name]: ""
        }))
        setTableDetails([]);
      }
      else {
        let selectedClubDetailsFound = clubs.find(item => item.id === e.target.options[e.target.selectedIndex].id)
        setTableDetails(selectedClubDetailsFound.sitting_tables);
        setTimeSlots(selectedClubDetailsFound.slots);
        setState(prev => ({
          ...prev,
          club_id: selectedClubDetailsFound.id
        }))

        const parentRef = doc(collection(db, "clubs"), selectedClubDetailsFound.id);
        const subCollectionClosedBookings = collection(parentRef, "closed_bookings");
        const subCollectionDrink = collection(parentRef, "drink_category");
        const subCollectionSmoke = collection(parentRef, "smoke_category");
        getDocs(subCollectionClosedBookings)
          .then(response => {
            setNonOperationalDays([...response.docs.map(item => item.data().date)])
          })
        getDocs(subCollectionDrink)
          .then(response => {
            setPreferredProducts(prev => ({
              ...prev,
              drink_category: [...response.docs.map(item => item.data())]
            }))
          })
        getDocs(subCollectionSmoke)
          .then(response => {
            setPreferredProducts(prev => ({
              ...prev,
              smoke_category: [...response.docs.map(item => item.data())]
            }))
          })
      }
    }
    if (name === "user_name") {
      setState(prev => ({
        ...prev,
        people: []
      }))
      if (value === "") {
        setState(prev => ({
          ...prev,
          [name]: ""
        }))
        setGuestDetails([]);
      }
      else {
        setState(prev => ({
          ...prev,
          user_id: e.target.options[e.target.selectedIndex].id
        }))
      }
    }
    setState(prev => ({
      ...prev,
      errors: {
        ...state.errors,
        [name]: ""
      }
    }))
  }

  const handleGuestDetailsChange = (event) => {
    const { name, value } = event.target;
    setGuestDetails(prev => ({
      ...prev,
      [name]: value,
      errors: {
        ...guestDetails.errors,
        [name]: ""
      }
    }))
    if (name === "age") {
      if (!value) {
        setGuestDetails(prev => ({
          ...prev,
          errors: {
            ...guestDetails.errors,
            age: "Please enter age"
          }
        }))
      }
      else if (value < 21) {
        setGuestDetails(prev => ({
          ...prev,
          errors: {
            ...guestDetails.errors,
            age: "Age must be at least 21 years"
          }
        }))
      }
      else if (value > 99) {
        setGuestDetails(prev => ({
          ...prev,
          errors: {
            ...guestDetails.errors,
            age: "Age must be below 100 years"
          }
        }))
      }
      else {
        setGuestDetails(prev => ({
          ...prev,
          errors: {
            ...guestDetails.errors,
            age: ""
          }
        }))
      }
    }
  };
  const handleGenderChange = (event) => {
    setGuestDetails(prev => ({
      ...prev,
      gender: event.target.value,
      errors: {
        ...guestDetails.errors,
        gender: ""
      }
    }))
  };
  const validateGuestDetailsFunc = () => {
    let validatedDetails = true;
    const errors = guestDetails.errors;
    if (guestDetails.guest_name.trim() === "") {
      errors.guest_name = "Please enter guest name"
      validatedDetails = false;
    }
    if (guestDetails.gender === "") {
      errors.gender = "Please select gender"
      validatedDetails = false;
    }
    if (!guestDetails.age) {
      errors.age = "Please enter age"
      validatedDetails = false;
    }
    if (guestDetails.errors.age) {
      errors.age = "Please enter a valid age"
      validatedDetails = false;
    }
    setGuestDetails(prev => ({
      ...prev,
      errors: errors
    }))
    return validatedDetails;
  }
  const addingGuestDetails = () => {
    const validateGuestDetails = validateGuestDetailsFunc()
    if (validateGuestDetails) {
      let guest = {
        name: guestDetails.guest_name,
        gender: guestDetails.gender,
        age: guestDetails.age
      }
      setState(prev => ({
        ...prev,
        people: [...state.people, guest]
      }))
      setGuestDetails(prev => ({
        ...prev,
        guest_name: "",
        gender: "",
        age: ""
      }))
    }
  }
  const handleTableDetailsChange = (event, selectedTableDetails) => {
    const isChecked = event.target.checked;
    if (!state.tables.length) {
      setToggleProducts(true);
    }
    if (preferredProducts && preferredProducts.drink_category) {
      // setProductBrands(preferredProducts.drink_category);
    }
    setState(prev => ({
      ...prev,
      errors: {
        ...state.errors,
        tables: ""
      }
    }))
    // Update the selectedOptions array based on the checkbox change
    let tablePayload = {
      table_type: selectedTableDetails.name,
      count: selectedTableDetails.seater_count,
      minimum_spend: selectedTableDetails.minimum_spent
    }
    if (isChecked) {
      setState(prev => ({
        ...prev,
        tables: [...state.tables, tablePayload]
      }))
    } else {
      const filteredOptions = state.tables.filter((option) => option.table_type !== tablePayload.table_type);
      setState(prev => ({
        ...prev,
        tables: filteredOptions
      }))
    }
  };

  const handleRemoveGuest = (index) => {
    const guestList = [...state.people];
    const remainGuest = guestList.splice(index, 1);
    setState(prev => ({
      ...prev,
      people: [...guestList]
    }))
  }
  const handleRemovePreferredProducts = (index) => {
    const productList = [...state.preferred_products];
    const remainProducts = productList.splice(index, 1);
    setState(prev => ({
      ...prev,
      preferred_products: [...productList]
    }))
  }
  const handlePreferredDrinkProducts = (e) => {
    setProductBrands([])
    if (e.target.checked) {
      setToggleProducts(false)
    }
    else {
      setToggleProducts(true)
    }
  }
  const onChangeProductCategory = (e, product) => {
    const { value, name } = e.target;
    if (!value.trim()) {
      setProductBrands([]);
    }
    setCategory(prev => ({
      ...prev,
      [name]: value
    }))
    if (product === "drink") {
      const filteredDrinkBrands = preferredProducts.drink_category.filter(item => item.id === e.target.options[e.target.selectedIndex].id);
      if (filteredDrinkBrands && filteredDrinkBrands[0]) {
        setSelectedPreferredProducts(prev => ({
          ...prev,
          category: filteredDrinkBrands[0].id,
          category_name: filteredDrinkBrands[0].name,
          product_type: "drink",
          errors: {
            ...selectedPreferredProducts.errors,
            category_name: ""
          }
        }))
        setProductBrands(filteredDrinkBrands[0].brands)
      }
    }
    else {
      const filteredSmokeBrands = preferredProducts.smoke_category.filter(item => item.id === e.target.options[e.target.selectedIndex].id);
      if (filteredSmokeBrands && filteredSmokeBrands[0]) {
        setSelectedPreferredProducts(prev => ({
          ...prev,
          category: filteredSmokeBrands[0].id,
          category_name: filteredSmokeBrands[0].name,
          product_type: "smoke",
          errors: {
            ...selectedPreferredProducts.errors,
            category_name: ""
          }
        }))
        setProductBrands(filteredSmokeBrands[0].brands)
      }
    }

  }
  const checkIsSelected = (tableName) => {
    const included = state.tables.filter(data => data.table_type === tableName)
    return included.length > 0 ? true : false;
  }

  const onChangeProductBrands = (e) => {
    setSelectedPreferredProducts(prev => ({
      ...prev,
      brand_name: e.target.options[e.target.selectedIndex].value,
      errors: {
        ...selectedPreferredProducts.errors,
        brand_name: ""
      }
    }))
  }

  const addingProductDetails = () => {
    if (selectedPreferredProducts.brand_name.trim() && selectedPreferredProducts.category_name.trim()) {
      delete selectedPreferredProducts.errors;
      setState(prev => ({
        ...prev,
        preferred_products: [...state.preferred_products, selectedPreferredProducts]
      }))
      setSelectedPreferredProducts({
        category: "",
        category_name: "",
        brand_name: "",
        product_type: "",
        errors: {
          category_name: "",
          brand_name: ""
        }
      })
      setCategory({
        drinkDropdown: "",
        smokeDropdown: ""
      })
    }
    else {
      const errors = selectedPreferredProducts.errors;
      if (!selectedPreferredProducts.brand_name.trim()) {
        errors.brand_name = "Please Select Brand";
      }
      if (!selectedPreferredProducts.category_name.trim()) {
        errors.category_name = "Please Select Category";
      }
      setSelectedPreferredProducts(prev => ({
        ...prev,
        errors: errors
      }))
    }
  }
  return (
    <Layout path="/bookings/create-table-booking">
      <ContentWrapper additionalClass="p-5">
        <form className="mt-6" onSubmit={handleFormSubmit}>
          <h5 className="text-2xl font-body font-bold mb-3">Table booking details</h5>
          <Row className="g-4">
            <Col xs={6} className="font-body">
              <h4 className="text-md font-semibold color-gray mb-2">Club name <span className="text-danger">*</span></h4>
              <select
                name="club_name"
                id="club_name"
                className="po-edit-input"
                onChange={onChangeHandler}
                value={state.club_name}
              >
                <option key="club_name" value="" >Select club name</option>
                {clubs &&
                  clubs.map((club) => {
                    return <option value={club.name} key={club.id} id={club.id} className="text-md" title={club.address}>{club.name}</option>
                  })}
              </select>
              {state.errors.club_name &&
                <p className="text-danger font-body text-sm">{state.errors.club_name}</p>
              }
            </Col>
            <Col xs={6} className="font-body">
              <h4 className="text-md font-semibold color-gray mb-2">User name <span className="text-danger">*</span></h4>
              <select
                name="user_name"
                id="user_name"
                className="po-edit-input "
                // placeholder="Enter Manager Name"
                onChange={onChangeHandler}
                value={state.user_name}
              >
                <option key="user_name" value="">Select user name</option>
                {users &&
                  users.map((user, index) => {
                    return <option key={index} value={user.name} id={user.id} className="text-md" title={user.email}>{user.name}</option>
                  })}
              </select>
              {state.errors.user_name &&
                <p className="text-danger font-body text-sm">{state.errors.user_name}</p>
              }
            </Col>
            {/* <Col xs={6} className="font-body">
              <h4 className="text-md font-semibold color-gray mb-2">Choose country <span className="text-danger">*</span></h4>
              <select className="po-edit-input" onChange={(e) => {
                setCountry({ ...country, country: e.target.options[e.target.selectedIndex].text, timezone: e.target.value },)
              }}>
                {countries && countries.map((ele) => {
                  return <option value={ele.data().country_timezone}>{ele.data().name}</option>
                })
                }
              </select>
            </Col> */}
          </Row>

          {/* Table Details goes here ***************************************************** */}
          {tableDetails.length ? <Row className="g-4 mt-2">
            <Col xs={6}>
              <h4 className="text-2xl  font-bold text-black">
                Add Table Details
              </h4>
              <div>
                {tableDetails.map((tableDetail, index) => (
                  <label key={tableDetail.name} className="d-flex align-items-center gap-2 text-md font-medium">
                    <input
                      type="checkbox"
                      value={tableDetail.name}
                      className="po-checkbox"
                      checked={checkIsSelected(tableDetail.name, index)}
                      onChange={(e) => { handleTableDetailsChange(e, tableDetail) }}
                    />
                    {tableDetail.name}
                  </label>
                ))}
              </div>
              {state.errors.tables &&
                <p className="text-danger font-body text-sm">{state.errors.tables}</p>
              }
            </Col>
            {state.tables.length ? <Col xs={6}>
              <h4 className="text-2xl  font-bold text-black">
                Table details list
              </h4>
              <Table responsive hover>
                <thead>
                  <tr>
                    <th className="text-md">S. No</th>
                    <th className="text-md">Table Type</th>
                    <th className="text-md">No of people</th>
                    <th className="text-md">Minimum Spent</th>
                  </tr>
                </thead>
                <tbody>
                  {state.tables.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.table_type}</td>
                        <td>{item.count}</td>
                        <td>{item.minimum_spend}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </Col> : ""}
          </Row> : ""}

          {/* preferred products ********************************************* */}

          {state.tables.length ? <Row className="g-4 mt-2">
            <Col xs={6}>
              <h4 className="text-2xl  font-bold text-black">
                Add Preferred Products Details
              </h4>
              <div>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox" onChange={handlePreferredDrinkProducts}
                    role="switch"
                    id="flexSwitchCheckDisabled"
                    disabled={!state.tables.length}
                  />
                  <label className="form-check-label text-medium" htmlFor="flexSwitchCheckDisabled">{toggleProducts ? "Preferred Drink" : "Preferred Smoke"}</label>
                </div>
              </div>

              {/* Product category ******************************************* */}

              <div className="mt-5">
                <h4 className="text-md font-semibold color-gray mb-2">{toggleProducts ? "Preferred Drink" : "Preferred Smoke"} <span className="text-danger">*</span></h4>
                <select
                  name={toggleProducts ? "drinkDropdown" : "smokeDropdown"}
                  id={toggleProducts ? "drinkDropdown" : "smokeDropdown"}
                  className="po-edit-input "
                  onChange={(e) => { onChangeProductCategory(e, (toggleProducts ? "drink" : "smoke")) }}
                  value={toggleProducts ? category.drinkDropdown : category.smokeDropdown}
                >
                  <option key={toggleProducts ? category.drinkDropdown : category.smokeDropdown} value="">
                    {toggleProducts ? "Select Drink" : "Select Smoke"}</option>
                  {preferredProducts && (toggleProducts ? preferredProducts.drink_category : preferredProducts.smoke_category) &&
                    (toggleProducts ? preferredProducts.drink_category : preferredProducts.smoke_category).map((product, index) => {
                      return <option value={product.name} id={product.id} key={index}>{product.name}</option>;
                    })}
                </select>
                {selectedPreferredProducts.errors.category_name &&
                  <p className="text-danger font-body text-sm">{selectedPreferredProducts.errors.category_name}</p>
                }
              </div>

              {/* Product Brands ********************************************** */}

              <div className="mt-5">
                <h4 className="text-md font-semibold color-gray mb-2">{toggleProducts ? "Preferred Drink Brands" : "Preferred Smoke Brands"} <span className="text-danger">*</span></h4>
                <select
                  name="brand"
                  id="brand"
                  className="po-edit-input "
                  onChange={onChangeProductBrands}
                  value={selectedPreferredProducts.brand_name}
                >
                  <option key="brand" value="">{toggleProducts ? "Select Drink Brand" : "Select Smoke Brand"}</option>
                  {productBrands && productBrands.length ?
                    productBrands.map((brand, index) => {
                      return <option value={brand.name} key={index}>{brand.name}</option>;
                    }) : ""}
                </select>
                {selectedPreferredProducts.errors.brand_name &&
                  <p className="text-danger font-body text-sm">{selectedPreferredProducts.errors.brand_name}</p>
                }
              </div>

              <div className="text-end mt-5 font-body">
                <Button className="color-black1 text-md font-body font-semibold cursor-pointer po-border po-border-yellow1 py-3 px-3 radius-105 d-inline-block bg-transparent text-dark"
                  onClick={addingProductDetails} name="Add Table Data">
                  Add Product Details
                </Button>
              </div>
            </Col>

            {/* product list ***************************************** */}

            {state.preferred_products.length ? <Col xs={6}>
              <h4 className="text-2xl  font-bold text-black">
                Preferred Products List
              </h4>
              <Table responsive hover>
                <thead>
                  <tr>
                    <th className="text-md">S. No.</th>
                    <th className="text-md">Category Name</th>
                    <th className="text-md">Product Type</th>
                    <th className="text-md">Brand Name</th>
                  </tr>
                </thead>
                <tbody>
                  {state.preferred_products.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.category_name}</td>
                        <td className="text-capitalize">{item.product_type}</td>
                        <td>
                          <span className="d-flex align-items-center justify-content-between">
                            <span>{item.brand_name}</span>
                            <ActionIcon
                              onClick={() => { handleRemovePreferredProducts(index) }}
                              additionalClass="bi bi-x-circle-fill text-3xl color-red me-2"
                            />
                          </span>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </Col> : ""}
          </Row> : ""}

          {/* guest details goes here **************************************** */}
          <Row className="g-4 mt-2">
            <Col xs={6}>
              <h4 className="text-2xl  font-bold text-black">
                Add guest detail
              </h4>
              <label className="font-body text-md mt-3">Name <span className="text-danger">*</span></label>
              <input
                name="guest_name"
                id="guest_name"
                className="po-edit-input"
                placeholder="Please enter the name of guest"
                value={guestDetails.guest_name.charAt(0).toUpperCase() + guestDetails.guest_name.slice(1)}
                onChange={handleGuestDetailsChange}
              />
              {guestDetails.errors.guest_name &&
                <p className="text-danger font-body text-sm">{guestDetails.errors.guest_name}</p>
              }
              <div>
                <label className="font-body text-md d-block mt-3">Gender <span className="text-danger">*</span></label>
                <label className="me-2 font-medium text-md d-inline-flex align-items-center">
                  <input
                    type="radio"
                    value="male"
                    className="me-1"
                    checked={guestDetails.gender === 'male'}
                    onChange={handleGenderChange}
                  />
                  Male
                </label>
                <label className="me-2 font-medium text-md d-inline-flex align-items-center">
                  <input
                    type="radio"
                    className="me-1"
                    value="female"
                    checked={guestDetails.gender === 'female'}
                    onChange={handleGenderChange}
                  />
                  Female
                </label>
                <label className="me-2 font-medium text-md d-inline-flex align-items-center">
                  <input
                    type="radio"
                    className="me-1"
                    value="other"
                    checked={guestDetails.gender === 'other'}
                    onChange={handleGenderChange}
                  />
                  Other
                </label>
              </div>
              {guestDetails.errors.gender &&
                <p className="text-danger font-body text-sm">{guestDetails.errors.gender}</p>
              }
              <label className="font-body text-md mt-3 ">Age <span className="text-danger">*</span></label>
              <input
                className="po-edit-input"
                name="age"
                type="number"
                value={guestDetails.age ? +guestDetails.age : ""}
                min={0}
                max={100}
                onChange={handleGuestDetailsChange}
              />
              {guestDetails.errors.age &&
                <p className="text-danger font-body text-sm">{guestDetails.errors.age}</p>
              }
              <div className="text-end mt-5 font-body">
                <Button className="color-black1 text-md font-body font-semibold cursor-pointer po-border po-border-yellow1 py-3 px-3 radius-105 d-inline-block bg-transparent text-dark" onClick={addingGuestDetails} name="Add Table Data">
                  Add Guest Details
                </Button>
              </div>
            </Col>
            <Col xs={6}>
              {state.people.length ?
                <div>
                  <h4 className="text-2xl  font-bold text-black">Guest Details List</h4>
                  <Table responsive hover>
                    <thead>
                      <tr>
                        <th className="text-md">S. No</th>
                        <th className="text-md">Name</th>
                        <th className="text-md">Gender</th>
                        <th className="text-md">Age</th>
                      </tr>
                    </thead>
                    <tbody>
                      {state.people.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.name}</td>
                            <td>{item.gender}</td>
                            <td>
                              <span className="d-flex align-items-center gap-2">
                                <span>{item.age}</span>
                                <span className="">
                                  <ActionIcon
                                    onClick={() => { handleRemoveGuest(index) }}
                                    additionalClass="bi bi-x-circle-fill text-3xl color-red me-2"
                                  />
                                </span>
                              </span>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div> : ""
              }
            </Col>
          </Row>
          <Row className="g-4 mt-2">
            <Col xs={6}>
              <label htmlFor="booking_date" className="text-lg font-medium color-gray mb-2">
                Booking Date <span className="text-danger">*</span>
              </label>
              <DatePicker
                selected={state.booking_date}
                className="po-edit-input mb-1 w-100"
                dateFormat="dd/MM/YYY"
                placeholderText="dd/mm/yyyy"
                minDate={new Date()}
                excludeDates={nonOperationalDays.map(item => item.toDate())}
                onChange={handleDateChange}
              />
              {state.errors.booking_date &&
                <p className="text-danger font-body text-sm">{state.errors.booking_date}</p>
              }
            </Col>
            <Col xs={6}>
              <div>
                <label htmlFor="expected_arrival_time" className="text-lg font-medium color-gray mb-2">
                  Entry Time Slot <span className="text-danger">*</span>
                </label>
                <select
                  value={state.expected_arrival_time}
                  className="po-edit-input mb-1"
                  name="expected_arrival_time"
                  onChange={onChangeHandler}
                  disabled={!state.club_name}
                >
                  <option key="entry_time" value="">Select entry time</option>
                  {(timeSlots && timeSlots.length) ?
                    timeSlots.map((slot, index) => {
                      return <option value={slot.slot_time} key={index}>{slot.slot_time}</option>;
                    }) : ""}
                  {/* Add more time slots as needed */}
                </select>
              </div>
              {state.errors.expected_arrival_time &&
                <p className="text-danger font-body text-sm">{state.errors.expected_arrival_time}</p>
              }
            </Col>
            <Col xs={6}>
              <label htmlFor="amount" className="text-lg font-medium color-gray mb-2">
                Amount Paid <span className="text-danger">*</span>
              </label>
              <input
                id="amount"
                name="amount"
                type="number"
                value={state.amount}
                placeholder="500"
                className="po-edit-input mb-1"
                onChange={onChangeHandler}
              />
              {state.errors.amount &&
                <p className="text-danger font-body text-sm">{state.errors.amount}</p>
              }
            </Col>
            {/* <Col xs={6}>
              <label htmlFor="status" className="text-lg font-medium color-gray mb-2">
                Status*
              </label>
              <input
                id="status"
                name="status"
                type="text"
                value={state.status}
                className="po-edit-input mb-1"
                onChange={onChangeHandler}
              />
            </Col> */}
            <Col
              xs={12}
              className="d-flex justify-content-end align-items-center mb-6"
            >
              {showToaster && (
                <img
                  src="/assets/icons/loading_icon.gif"
                  alt="processing"
                  width={100}
                />
              )}
              <OutlinedButton link="/bookings" name="Cancel" />
              <SuccessButton
                type="submit"
                name="Save"
                icon="bi-check-circle"
                disabled={showToaster ? true : false}
                additionalClass="ms-4"
              />
            </Col>
          </Row>
        </form>
      </ContentWrapper>
      <Toaster
        showToaster={showToaster}
        setShowToaster={setShowToaster}
        bg={toasterDetails.bg}
        title={toasterDetails.title}
        bodyText={toasterDetails.bodyText}
        statusIcon={toasterDetails.statusIcon}
      />
    </Layout>
  );
};

export default ManagerCreateTableBooking;