import moment from "moment";
import { useState } from "react";
import { useEffect, useRef } from "react";
import { Dropdown, Table } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { Link, useSearchParams } from "react-router-dom";


const sortIcon = "/assets/icons/icon-sort.svg";
const sortIconAsc = "/assets/icons/icon-sort-asc.svg";
const sortIconDesc = "/assets/icons/icon-sort-desc.svg";

const ImagePreview = () => {
  return (
    <div
      style={{
        height: "50px",
        width: "50px",
        backgroundColor: "#DEDEDE",
      }}
      className="bg-gray rounded-circle overflow-hidden d-flex align-items-center justify-content-center"
    >
      <i className="bi bi-person-fill text-6xl"></i>
    </div>
  );
};

const Users = ({ loader, onDeleteHandler, currentItems, usersPerPage, count, fetchUsers, handleFilterUsers }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [usersList, setUsersList] = useState([]);
  const targetElementForSort = useRef("");
  const sortBy = useRef({
    orderByKey: "createdAt",
    order: "desc"
  })
  const changeOrder = useRef(0);

  useEffect(() => {
    if(Boolean(searchParams.get('sortBy'))) {
      sortBy.current = JSON.parse(searchParams.get('sortBy'));
      targetElementForSort.current = sortBy.current.orderByKey;
      changeOrder.current = +searchParams.get('changeOrder')
    } else {
      sortBy.current = {
        orderByKey: "createdAt",
        order: "desc"
      }
      targetElementForSort.current = "";
    }
    if(Boolean(searchParams.get('sortBy') && Boolean(searchParams.get('searchQuery')))) {
      sortBy.current = JSON.parse(searchParams.get('sortBy'));
      changeOrder.current= +searchParams.get('changeOrder')
      handleSorting(null, sortBy.current.orderByKey);
    } else setUsersList([...currentItems])
  }, [currentItems])

    // sorting *******************************************************************************************************

    const handleSorting = (e, localId) => {
      if(targetElementForSort.current !== localId) {
        changeOrder.current = 0;
        sortBy.current = { orderByKey: localId, order: "asc" }
        targetElementForSort.current = localId;
      }
      if(count.current) { // coming back to first page when you will be on other page
        count.current = 0;
        sortBy.current = { orderByKey: localId, order: "asc" }
        fetchUsers(sortBy.current);
        return;
      }
      if(searchParams.get('searchQuery') || searchParams.get('filterQuery')) {
        if (Boolean(searchParams.get('searchQuery'))) {
          if (!changeOrder.current) {
            changeOrder.current = changeOrder.current + 1;
            sortBy.current = { orderByKey: targetElementForSort.current, order: "asc" }
            if(usersList.length) {
              setUsersList([...usersList].sort((a, b) => a[targetElementForSort.current] > b[targetElementForSort.current] ? 1 : a[targetElementForSort.current] < b[targetElementForSort.current] ? -1 : 0));
            } else {
              setUsersList([...currentItems].sort((a, b) => a[targetElementForSort.current] > b[targetElementForSort.current] ? 1 : a[targetElementForSort.current] < b[targetElementForSort.current] ? -1 : 0));
            }
          }
          else if (changeOrder.current === 1) {
            changeOrder.current = changeOrder.current + 1;
            sortBy.current = { orderByKey: targetElementForSort.current, order: "desc" }
            if(usersList.length) {
              setUsersList([...usersList].sort((a, b) => a[targetElementForSort.current] < b[targetElementForSort.current] ? 1 : a[targetElementForSort.current] > b[targetElementForSort.current] ? -1 : 0));
            } else {
              setUsersList([...currentItems].sort((a, b) => a[targetElementForSort.current] < b[targetElementForSort.current] ? 1 : a[targetElementForSort.current] > b[targetElementForSort.current] ? -1 : 0));
            }
          }
          else {
            changeOrder.current = 0;
            sortBy.current = { orderByKey: "createdAt", order: "desc" }
            setUsersList([...currentItems]);
          }
        }
        else {
          if (!changeOrder.current) {
            changeOrder.current = changeOrder.current + 1;
            sortBy.current = { orderByKey: targetElementForSort.current, order: "asc" }
            handleFilterUsers(JSON.parse(searchParams.get("filterQuery")), false, sortBy.current)
          }
          else if (changeOrder.current === 1) {
            changeOrder.current = changeOrder.current + 1;
            sortBy.current = { orderByKey: targetElementForSort.current, order: "desc" }
            handleFilterUsers(JSON.parse(searchParams.get("filterQuery")), false, sortBy.current)
          }
          else {
            changeOrder.current = 0;
            sortBy.current = { orderByKey: "createdAt", order: "desc" }
            handleFilterUsers(JSON.parse(searchParams.get("filterQuery")), false, sortBy.current)
          }
        }
      }
      else{
        if(!changeOrder.current) {
          changeOrder.current = changeOrder.current + 1;
          sortBy.current = { orderByKey: targetElementForSort.current, order: "asc" }
        }
        else if (changeOrder.current === 1) {
          changeOrder.current = changeOrder.current + 1;
          sortBy.current = { orderByKey: targetElementForSort.current, order: "desc" }
        }
        else {
          changeOrder.current = 0;
          sortBy.current = { orderByKey: "createdAt", order: "desc" }
        }
        fetchUsers(sortBy.current);
      }
      searchParams.delete('pageNo');
      searchParams.delete('currentPageId');
      searchParams.delete('n');
      setSearchParams({ ...Object.fromEntries([...searchParams]), sortBy: JSON.stringify(sortBy.current), changeOrder: changeOrder.current });
    }

  return (
    <div className="table-responsive po-table tableFixHead" >
      <Table hover>
        <thead>
          <tr className="background-gray10">
            <th><p className="text-nowrap text-xs text-xl-sm text-uppercase ps-3 color-black2 mb-0 py-2">S. No</p></th>
            <th valign="middle"> 
              <span 
                onClick={(e) => handleSorting(e, "name")}
                className={`text-nowrap cursor-pointer text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2 d-inline-flex align-items-center gap-1 ${targetElementForSort.current !== "name" || !changeOrder.current ? "" : "color-yellow1"}`}>
                Name
                <img src={(targetElementForSort.current !== "name" || !changeOrder.current) ? sortIcon : changeOrder.current === 1 ? sortIconAsc : sortIconDesc} alt="sort-icon" height={12} />
              </span></th>
            <th valign="middle">
              <span 
                onClick={(e) => handleSorting(e, "email")}
                className={`text-nowrap cursor-pointer text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2 d-inline-flex align-items-center gap-1 ${targetElementForSort.current !== "email" || !changeOrder.current ? "" : "color-yellow1"}`}>
                Email ID
                <img src={(targetElementForSort.current !== "email" || !changeOrder.current) ? sortIcon : changeOrder.current === 1 ? sortIconAsc : sortIconDesc} alt="sort-icon" height={12} />
              </span>
            </th>
            <th>
              <p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">Phone Number</p>
            </th>
            <th valign="middle">
              <span 
                onClick={(e) => handleSorting(e, "createdAt")}
                className={`text-nowrap cursor-pointer text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2 d-inline-flex align-items-center gap-1 ${targetElementForSort.current !== "createdAt" || !changeOrder.current ? "" : "color-yellow1"}`}>
                Registered On
                <img src={(targetElementForSort.current !== "createdAt" || !changeOrder.current) ? sortIcon : changeOrder.current === 1 ? sortIconAsc : sortIconDesc} alt="sort-icon" height={12} />
              </span>
            </th>
            <th>
              <p className="text-nowrap text-center text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">Role</p>
            </th>
            <th>
              <p className="text-nowrap text-center text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">Membership</p>
            </th>
            <th valign="middle">
              <span 
                onClick={(e) => handleSorting(e, "loyality_point")}
                className={`text-nowrap cursor-pointer text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2 d-inline-flex align-items-center gap-1 ${targetElementForSort.current !== "loyality_point" || !changeOrder.current ? "" : "color-yellow1"}`}>
                Loyalty Points
                <img src={(targetElementForSort.current !== "loyality_point" || !changeOrder.current) ? sortIcon : changeOrder.current === 1 ? sortIconAsc : sortIconDesc} alt="sort-icon" height={12} />
              </span>
            </th>
            <th valign="middle">
              <div onClick={(e) => handleSorting(e, "status")} className={`cursor-pointer text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2 d-flex align-items-center gap-1 ${targetElementForSort.current !== "status" || !changeOrder.current ? "" : "color-yellow1"}`}>
                Status
                <img src={(targetElementForSort.current !== "status" || !changeOrder.current) ? sortIcon : changeOrder.current === 1 ? sortIconAsc : sortIconDesc} alt="sort-icon" height={12} />
              </div>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {!loader ? (
            usersList && usersList.length ?
              usersList.map((element, index) => {
                return (
                  <tr key={index}>
                    <td className="align-middle"><p className="text-sm mb-0 text-nowrap color-black ps-3">
                      {count * usersPerPage + index + 1}.
                    </p></td>
                    <td className="align-middle">
                      <Link className="font-bold text-sm py-2 text-black ps-2" to={`/users/user-details/${element.id}`} target="_blank">
                        <div className="d-inline-flex align-items-center">
                          <div className="me-2">
                            {element.profile_image ? (
                              <div
                                style={{
                                  height: "50px",
                                  width: "50px",
                                  backgroundImage: `url(${element.profile_image})`,
                                }}
                                className="background-position-center-top background-repeat-norepeat background-size-cover rounded-circle overflow-hidden "
                              ></div>
                            ) : (
                              <ImagePreview />
                            )}
                          </div>
                          <div>
                            <p className="text-sm mb-0 text-capitalize text-nowrap color-black font-normal">
                              {element.name}
                            </p>
                          </div>
                        </div>
                      </Link>

                    </td>
                    <td className="align-middle">
                      <p className="text-sm mb-0 text-nowrap color-black">
                        {element.email}
                      </p>
                    </td>
                    <td className="align-middle">
                      <p className="text-sm mb-0 text-nowrap color-black">
                        {element.contacts && element.contacts[0] && element.contacts[0].contact_no}
                      </p>
                    </td>
                    <td className="align-middle">
                      <p className="text-sm mb-0 text-nowrap color-black">
                        {!Boolean(searchParams.get('filterQuery')) ? element.createdAt &&
                          moment(new Date(element.createdAt)).format(
                            "DD MMM YYYY"
                          ) : moment(new Date(element.createdAt.toDate())).format(
                            "DD MMM YYYY"
                          )}
                      </p>
                    </td>
                    <td className="align-middle text-center">
                      {element && element.role && element.role.map((item, index) => {
                        return (
                          <span
                            key={index}
                            className={`mx-1 d-inline-block text-xs mb-0 text-nowrap text-white text-capitalize font-semibold rounded px-3
                                ${item.toLowerCase() === 'customer' ? "po-customer" : item.toLowerCase() === 'admin' ? "po-admin" : item.toLowerCase() === 'manager' ? "po-manager" : item.toLowerCase() === 'concierge' ? "po-concierge" : "po-influencer"}
                              `}>{item}</span>
                        )
                      })}
                    </td>
                    <td className="align-middle">
                      <div className="d-flex align-items-center justify-content-start">
                        <div className="ms-2 color-black text-sm w-100 text-center">
                          {element.active_membership_name ? element.active_membership_name : "No Active Membership"}
                        </div>
                      </div>
                    </td>
                    <td className="align-middle">
                      <div className="d-flex align-items-center justify-content-start">
                        <div className="ms-2 color-black text-sm w-100 text-center">
                          {element.loyality_point}
                        </div>
                      </div>
                    </td>
                    <td className="align-middle">
                      <div className="d-flex align-items-center justify-content-start">
                        <div
                          style={{
                            height: "10px",
                            width: "10px",
                            backgroundColor: `${element.status === "Active" ? "#009b38" : "rgb(244 25 25)"
                              }`,
                          }}
                          className="rounded-circle"
                        ></div>
                        <div className="ms-2 color-black text-sm">
                          {element.status==="Inactive" ? "Deleted" : element.status}
                        </div>
                      </div>
                    </td>
                    <td className="align-middle">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="link"
                          id="dropdown-basic"
                          className="text-black"
                        >
                          <i className="bi bi-three-dots-vertical text-4xl d-bloxk"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="border-0 shadow px-2">
                          {element.status === "Active" && 
                            <Link className="border-bottom font-bold text-sm py-2 text-black d-block ps-2" to={`/users/update-user/${element.id}`}>
                              Edit
                            </Link>}
                          {element.status === "Active" && <div
                            className="border-bottom font-bold text-sm py-2 text-black d-block ps-2 cursor-pointer"
                            onClick={() => onDeleteHandler(element.id)}
                          >
                            Delete
                          </div>}
                          <Link className="font-bold text-sm py-2 text-black d-block ps-2" to={`/users/user-details/${element.id}`} target="_blank">View</Link>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                );
              }) :
              <tr>
                <td colSpan={10}>
                  <p className="bold text-lg text-center mt-4">No Data to show</p>
                </td>
              </tr>
          ) : (
            <tr>
              <td colSpan={10}>
                <Skeleton inline={true} count={5} height={80} style={{ marginBottom: "1rem" }} baseColor='#dedede' highlightColor='#cccccc' duration={2} />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default Users;