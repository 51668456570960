import React, { useState, useEffect } from 'react';
import ContentWrapper from "../../Components/Global/ContentWrapper";
import PageDescription from "../../Components/Global/PageDescription";
import Layout from "../../Layout/Layout";
import { Button, Col, Row, Modal } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import PrimaryInput from "../../Components/UI/PrimaryInput";
import PrimaryButton from '../../Components/Global/Buttons/PrimaryButton';
import OutlinedButton from '../../Components/Global/Buttons/OutlinedButton';
import SuccessButton from '../../Components/Global/Buttons/SuccessButton';

import { doc, collection, query, where, getDoc } from "firebase/firestore";
import db from '../../firebase';
import SecondaryButton from '../../Components/Global/Buttons/SecondaryButton';
import Skeleton from 'react-loading-skeleton';


const Support = () => {
    const [supportDetails, setSupportDetails] = useState({});
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const fetchSupportDetails = async() => {

        const docRef = doc(db, "support","contact");
        const querySnapshot = await getDoc(docRef);
        console.log(querySnapshot.data(), "support Details"); 
        querySnapshot && setSupportDetails(querySnapshot.data())

    }

    useEffect(()=>{
        fetchSupportDetails();
    },[])
    console.log(supportDetails,"xxx");
    
    return (
        <Layout path="/support">
            <div className="d-flex justify-content-between align-items-center gap-3 gap-lg-5 font-body">
                <PageDescription title="Support" caption="" />
                <PrimaryButton 
                    name="Edit details" 
                    icon="bi-pencil-square"  
                    onClick={handleShow}
                />
            </div>
            <ContentWrapper additionalClass="p-5 mb-4">
                <Row>
                    <Col xs={12} className="font-body mb-7">
                        <p class="color-lightestblue font-normal text-sm mb-0 font-body">Phone number</p>
                        {supportDetails && supportDetails.whatsApp ? 
                            <p class="color-lightblue font-semibold text-md text-lg-lg mb-0 font-body text-wrap text-break">{supportDetails && supportDetails.phone}</p> 
                            : 
                            <Skeleton
                                inline={true} count={1}
                                height={20} width={200}
                                baseColor="#dedede" highlightColor="#cccccc"
                                duration={2} rectangle
                            /> 
                        }
                    </Col>
                    <Col xs={12} className="font-body mb-7">
                        <p class="color-lightestblue font-normal text-sm mb-0 font-body">Whatsapp number</p>
                        {supportDetails && supportDetails.whatsApp ? 
                            <p class="color-lightblue font-semibold text-md text-lg-lg mb-0 font-body text-wrap text-break">{supportDetails && supportDetails.whatsApp}</p> 
                            : 
                            <Skeleton
                                inline={true} count={1}
                                height={20} width={200}
                                baseColor="#dedede" highlightColor="#cccccc"
                                duration={2} rectangle
                            /> 
                        }
                    </Col>
                    <Col xs={12} className="font-body mb-7">
                        <p class="color-lightestblue font-normal text-sm mb-0 font-body">Email</p>
                        {supportDetails && supportDetails.whatsApp ? 
                            <p class="color-lightblue font-semibold text-md text-lg-lg mb-0 font-body text-wrap text-break">{supportDetails && supportDetails.email}</p> 
                            : 
                            <Skeleton
                                inline={true} count={1}
                                height={20} width={200}
                                baseColor="#dedede" highlightColor="#cccccc"
                                duration={2} rectangle
                            /> 
                        }
                    </Col>
                    <Col xs={6} className="font-body mb-7">
                        {/* <PhoneInput
                            name="whatsappNo"
                            id="whatsappNo"
                            defaultCountry="IN"
                            placeholder="Enter whatsapp number"
                            className="mb-1"
                        /> */}
                        
                    </Col>
                    <Col xs={6} className="font-body mb-7">
                        {/* <PrimaryInput
                        id="email"
                        label="Email"
                        placeholder="Enter email address"
                        /> */}
                        {/* {state.errorMessage.email && (
                        <span className="text-danger font-medium">
                            {state.errorMessage.email}
                        </span>
                        )} */}
                    </Col>
                    <Modal show={show} onHide={handleClose} centered>
                        <div className="background-appColor p-8 rounded font-body">
                            <h2 className="text-2xl  color-ternary font-semibold mb-4">
                                Edit Support Details
                            </h2>
                            <form>
                                <article className="mb-5">
                                    <label className="text-md text-light text-wrap mb-1">
                                        Phone number
                                    </label>
                                    <PhoneInput
                                        name="phone"
                                        id="phone"
                                        defaultCountry="IN"
                                        placeholder="Enter phone number"
                                        className="po-supportModal mb-1"
                                    /> 
                                </article>
                                <article className="mb-5">
                                    <label className="text-md text-light text-wrap mb-1">
                                        Whatsapp number
                                    </label>
                                    <PhoneInput
                                        name="whatsappNo"
                                        id="whatsappNo"
                                        defaultCountry="IN"
                                        placeholder="Enter whatsapp number"
                                        className="po-supportModal mb-1"
                                    />
                                </article>
                                <article className="mb-4">
                                    <label className="text-md text-light text-wrap mb-1">
                                        Email
                                    </label>
                                    <PrimaryInput
                                        id="email"
                                        placeholder="Enter email address"
                                    />
                                </article>
                            </form>
                            <div className="text-end mt-8">
                            <SecondaryButton
                                onClick={handleClose}
                                name="Cancel"
                                additionalClass="text-light px-5"
                            />
                            <PrimaryButton
                                type="submit"
                                name="Save"
                                additionalClass="ms-3"
                                //onClick={changingPassword}
                            />
                            </div>
                        </div>
                    </Modal>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-end">
                        <OutlinedButton link="/support" name="Cancel" />
                        <SuccessButton
                            type="submit"
                            name="Update"
                            icon="bi-check-circle"
                            additionalClass="ms-3"
                        />
                    </Col>
                </Row>
            </ContentWrapper>
        </Layout>
    )
}

export default Support;



