import ContentWrapper from "../../Components/Global/ContentWrapper";
import Layout from "../../Layout/Layout";
import { Table, Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  orderBy,
  query,
} from "firebase/firestore";
import db from "../../firebase";
import Dropdown from "react-bootstrap/Dropdown";
import SecondaryButton from "../../Components/Global/Buttons/SecondaryButton";
import PageDescription from "../../Components/Global/PageDescription";
import PrimaryButton from "../../Components/Global/Buttons/PrimaryButton";
import { useNavigate } from "react-router-dom";
const ImagePreview = () => {
  return (
    <div
      style={{
        height: "75px",
        width: "150px",
        backgroundColor: "#DEDEDE",
      }}
      className="bg-gray rounded overflow-hidden d-flex align-items-center justify-content-center"
    >
      <i className="bi bi-card-image text-6xl"></i>
    </div>
  );
};
const DashboardPopUp = () => {
  const navigate = useNavigate();
  const [popUps, setPopUps] = useState([]);
  // modal states
  const [show, setShow] = useState({ show: false, data: null });
  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setShow({ show: true, data: popUps[id] });
  };
  // fetching entries
  const fetchRequests = async () => {
    const q = query(collection(db, "popups"), orderBy("updatedAt", "desc"));
    const querySnapshot = await getDocs(q);
    const newData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setPopUps(newData);
  };
  useEffect(() => {
    fetchRequests();
  }, []);


  // deleting entries
  const hadnleDelete = async (data) => {
    try {
      await deleteDoc(doc(db, "popups", data.id)).then((res) => {
        console.info("Success");
      });
      fetchRequests();
      handleClose();
    } catch (err) {
      console.log(err);
    }
  };
  //Changing First letter Capital
  function capitalizeFirstLetter(inputString) {
    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
  }
  return (
    <Layout path="/popups" additionalclass="d-flex flex-column">
      <div className="d-flex justify-content-between align-items-center gap-3 gap-lg-5 font-body">
        <PageDescription title="All Popups" caption="" />
        <PrimaryButton
          link="/popups/create-popup"
          name="Create popup"
          icon="bi-plus-circle"
        />
      </div>
      <ContentWrapper additionalClass="pb-5">
        <div className="table-responsive po-table tableFixHead">
          <Table hover>
            <thead>
              <tr className="background-gray10">
                <th>
                  <p className="text-nowrap text-xs text-xl-sm text-uppercase ps-3 color-black2 mb-0 py-2">
                    s.no
                  </p>
                </th>
                <th>
                  <p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">
                    City
                  </p>
                </th>
                <th>
                  <p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">
                    thumbnail
                  </p>
                </th>
                <th>
                  <p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">
                    redirection type
                  </p>
                </th>
                <th>
                  <p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">
                    Club or Event Name
                  </p>
                </th>
                <th>
                  <p className="text-nowrap text-xs text-xl-sm text-center text-uppercase color-black2 mb-0 py-2">
                    id
                  </p>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {popUps &&
                popUps.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td className="align-middle">
                        <p className="text-sm mb-0 text-nowrap color-black ps-3">
                          {index + 1}.
                        </p>
                      </td>
                      <td className="align-middle">
                        <p className="text-sm mb-0 text-nowrap color-black">
                          {data.city && data.city}
                        </p>
                      </td>
                      <td className="align-middle">
                        {data.image ? (
                          <div
                            style={{
                              height: "70px",
                              width: "70px",
                              backgroundImage: `url(${data.image && data.image})`,
                            }}
                            className="background-position-center-top background-repeat-norepeat background-size-cover rounded overflow-hidden "
                          ></div>
                        ) : (
                          <ImagePreview />
                        )}
                      </td>

                      <td className="align-middle">
                        <p className="text-sm mb-0 text-nowrap color-black">
                          {data.redirectionType && capitalizeFirstLetter(data.redirectionType)}
                        </p>
                      </td>
                      <td className="align-middle">
                        <p className="text-sm mb-0 text-nowrap color-black">
                          {data.club_event_name && data.club_event_name}
                        </p>
                      </td>
                      <td className="align-middle">
                        <p className="text-sm mb-0 text-center color-black">
                          {data.clubId && data.clubId}
                          {data.eventId && data.eventId}
                        </p>
                      </td>

                      <td className="align-middle">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="link"
                            id="dropdown-basic"
                            className="text-black"
                          >
                            <i className="bi bi-three-dots-vertical text-4xl d-bloxk"></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="border-0 shadow px-2">
                            <Dropdown.Item
                              className="border-bottom font-bold text-sm py-2 text-black"
                              onClick={() => {
                                navigate(`/popups/edit-popup/${data.id}?city=${data.city}`);
                              }}
                            >
                              Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="font-bold text-sm py-2 text-black"
                              onClick={() => handleShow(index)}
                            >
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>

      </ContentWrapper>
      <Modal show={show.show} onHide={handleClose}>
        <div className="background-appColor p-8 rounded font-body">
          <h5 className="text-2xl  color-ternary font-semibold ">
            Are you sure?
          </h5>
          <p className="background-appColor text-light border-bottom-0 py-1 ">
            You want to delete this popup
          </p>
          <div className="d-flex justify-content-end mt-12">
            <SecondaryButton
              onClick={handleClose}
              name="Cancel"
              additionalClass="text-white me-3"
            />
            <PrimaryButton
              onClick={() => hadnleDelete(show.data)}
              name="Delete"
            />
          </div>
        </div>
      </Modal>
    </Layout>
  );
};
export default DashboardPopUp;
