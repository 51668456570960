import { Link } from "react-router-dom";
import { motion } from "framer-motion";
const OutlinedButton = ({ name, link, onClick, type, additionalClass }) => {
  return (
    <>
      {link ? (
        <Link to={link} className={`d-inline-block text-dark text-md font-body font-semibold cursor-pointer po-border po-border-black py-3 px-6 radius-105  d-inline-block bg-transparent ${additionalClass}`}>
          {name}
        </Link>
      ) : onClick ? (
        <motion.button
          className={`text-black text-md font-body font-semibold cursor-pointer po-border po-border-black py-3 px-6 radius-105 d-inline-block bg-transparent ${additionalClass}`}
          onClick={onClick}
        >
          {name}
        </motion.button>
      ) : type ? (
        <motion.button
          className={` text-black text-md font-body font-semibold cursor-pointer po-border po-border-black py-3 px-6 radius-105  d-inline-block bg-transparent ${additionalClass}`}
          type={type}
        >
          {name}
        </motion.button>
      ) : null}
    </>
  );
};
export default OutlinedButton;