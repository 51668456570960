
import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";

const Layout = (props) => {
  const logoutHandler = (isLoggedIn) => {
    console.log(isLoggedIn);
    localStorage.setItem("authenticate", isLoggedIn);
    // setAuthentication(localStorage.getItem('authenticate'));
  }
  return (
    <div className="po-layout pl-6 pl-xl-8 py-5 overflow-hidden">
      <main className="po-main d-flex gap-4 h-100">
        <Sidebar logoutHandler={logoutHandler}/>

        <div className="w-100 po-section__content pr-6 pr-xl-8">
          <Header />
          {props.children}
        </div>
      </main>
    </div>
  );
};

export default Layout;