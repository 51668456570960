import { Button, Table } from "react-bootstrap";
import ContentWrapper from "../Global/ContentWrapper";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import SecondaryButton from "../Global/Buttons/SecondaryButton";
import Skeleton from "react-loading-skeleton";
import { useState, useEffect } from "react";
import { collection, getDocs, query, where, orderBy } from "firebase/firestore";
import db from "../../firebase";

const ManagerEntryBookings = ({ clubId, usersContactDetails }) => {
  console.log("coming inside theknKKKNKn knknknkmn");
  const [isLoading, setIsLoading] = useState(true);
  const [entryBookingsData, setEntryBookingsData] = useState([]);

  const fetchEntryBookings = async () => {
    try {
      const entryBookings = await getDocs(
        query(collection(db, 'club_entry_bookings'), orderBy("createdAt", "desc"), where("booking_type", "==", "club_entry_booking"), where("club_id", "==", clubId))
      );
      setEntryBookingsData([]);
      entryBookings.forEach((doc) => {
        setEntryBookingsData(prev => [...prev, doc.data()])
      })
      setIsLoading(false);
    }
    catch (err) {
      console.log(err, 'err ddddddddddddddddddddddddd');
    }
  }
  useEffect(() => {
    fetchEntryBookings();
    console.log("comes inside uf");
  }, [])

  return (
    <>
      <ContentWrapper>
        {/* <h2 className="text-lg font-semibold ps-4 py-5 color-lightblue">Showing entry bookings</h2> */}
        <div className="table-responsive po-table tableFixHead">
          <Table hover>
            <thead>
              <tr className="background-gray10">
                <th><p className="text-nowrap text-xs text-xl-sm text-uppercase ps-3 color-black2 mb-0 py-2">S. NO</p></th>
                <th><p className="text-nowrap text-xs text-xl-sm text-uppercase  color-black2 mb-0 py-2">Booking ID</p></th>
                <th><p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">name</p></th>
                <th><p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">phone number</p></th>
                <th><p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">email id</p></th>
                <th><p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">guest count</p></th>
                <th><p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">AMOUNT</p></th>
                <th><p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">BOOKED FOR</p></th>
                <th><p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">STATUS</p></th>
                <th><p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2"></p></th>
              </tr>
            </thead>
            <tbody>
              {!isLoading ?
                (
                  <>
                    {(entryBookingsData && entryBookingsData.length) ? entryBookingsData.map((entryBookingData, index) => {
                      // console.log(entryBookingData, "entryBookingData");
                      const date = moment(new Date(entryBookingData.booking_date.seconds * 1000).toISOString()).format("DD MMM YYYY")
                      return (
                        <tr key={index}>
                          <td className="align-middle"><p className="text-sm mb-0 text-nowrap color-lightblue ps-3">{index + 1}</p></td>
                          <td className="align-middle"><p className="text-sm mb-0 text-nowrap color-lightblue ">{entryBookingData && entryBookingData.booking_id}</p></td>
                          <td className="align-middle"><p className="text-sm mb-0 text-nowrap color-lightblue">{entryBookingData && entryBookingData.user && entryBookingData.user.name && entryBookingData.user.name}</p></td>
                          <td className="align-middle">{" "}
                          <p className="text-sm mb-0 text-nowrap color-lightblue">
                            {usersContactDetails[entryBookingData && entryBookingData.user_id] &&
                              usersContactDetails[entryBookingData && entryBookingData.user_id][0]
                              ? usersContactDetails[entryBookingData && entryBookingData.user_id][0][0]
                                .contact_no
                              : ""}
                          </p>
                        </td>
                        <td className="align-middle">
                          {" "}
                          <p className="text-sm mb-0 text-nowrap color-lightblue ">
                            {usersContactDetails[entryBookingData && entryBookingData.user_id] &&
                              usersContactDetails[entryBookingData && entryBookingData.user_id][1]
                              ? usersContactDetails[entryBookingData && entryBookingData.user_id][1]
                              : ""}
                          </p>
                        </td>
                          {/* <td className="align-middle"><p className="text-sm mb-0 text-nowrap color-lightblue">{entryBookingData && entryBookingData.club_name && entryBookingData.club_name}</p></td>
                          <td className="align-middle"><img src={entryBookingData && entryBookingData.club_display_image && entryBookingData.club_display_image} className="rounded" width="100px" height="100px" alt="" /></td> */}
                          <td className="align-middle"><p className="text-sm mb-0 text-nowrap color-lightblue">{entryBookingData && entryBookingData.attendee_count + 1}</p></td>
                          <td className="align-middle"><p className="text-sm mb-0 text-nowrap color-lightblue">{entryBookingData && "$ " + entryBookingData.amount}</p></td>
                          <td className="align-middle"><p className="text-sm mb-0 text-nowrap color-lightblue">{date && date}</p></td>
                          <td className="align-middle">
                            <div className="d-flex align-items-center justify-content-start">
                              <div
                                style={{
                                  height: "10px",
                                  width: "10px",
                                  backgroundColor: `${entryBookingData.status === "Approved"
                                    ? "#13DEB9"
                                    : entryBookingData.status === "Waiting for approval"
                                      ? "#FFAE1F"
                                      : entryBookingData.status === "Rejected"
                                        ? "#FA896B"
                                        : null
                                    }`,
                                }}
                                className="rounded-circle"
                              ></div>
                              <div className="ms-2 color-lightblue text-sm text-nowrap">
                                {entryBookingData && entryBookingData.status}
                              </div>
                            </div>

                          </td>
                          {/* <select className="po-edit-input">
                              <option>Accept</option>
                              <option>Decline</option>
                            </select> */}

                          <td className="align-middle text-sm font-medium">
                            <SecondaryButton link={`/manager/entry-details/${entryBookingData && entryBookingData.id && entryBookingData.id}`} name="View" />

                          </td>
                        </tr>)
                    }) : ""
                    }
                  </>
                ) : (
                  <>
                    <tr>
                      <td colSpan={10}> <Skeleton inline={true} count={5} height={80} style={{ marginBottom: "1rem" }} baseColor='#dedede' highlightColor='#cccccc' duration={2} />
                      </td>
                    </tr>
                  </>
                )
              }
            </tbody>
          </Table>
        </div>
      </ContentWrapper >
    </>
  );
};
export default ManagerEntryBookings;