import React, { useEffect, useState } from 'react'
import Layout from '../../Layout/Layout'
import UpdateUser from '../../Components/User/UpdateUser'
import { useParams, useLocation } from 'react-router-dom';

const UpdateUserPage = () => {
  let location = useLocation();
  const { user_id } = useParams();
  const type = location.state && location.state.type;
  const [userDetails, setUserDetails] = useState({});
  let token = localStorage.getItem('authenticate');
  useEffect(() => {
    var myHeadersPayload = new Headers();
    myHeadersPayload.append("developement", "true");
    myHeadersPayload.append("token", token);
    myHeadersPayload.append("uid", user_id);
    var requestOptions = {
      method: 'GET',
      headers: myHeadersPayload,
      redirect: 'follow'
    };
    fetch(`${process.env.REACT_APP_API_URL}/user/v1/user?uid=${user_id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setUserDetails(result);
      })
      .catch(error => console.log('error', error));

  }, [token, user_id])
  return (
    <Layout path="/users/update-user">
      <UpdateUser userDetails={userDetails} type={type} />
    </Layout>
  )
}

export default UpdateUserPage
