import React, { useState, useEffect } from 'react';
import ContentWrapper from "../ContentWrapper";
import ReactApexChart from "react-apexcharts";
import DateRange from "../DateRange";
const CityWiseBookings = ({ setSelectedDateRange, cityStartDate, cityEndDate, top_10_city }) => {
  const [state, setState] = useState({
    tables: [],
    events: [],
    entries: [],
    cities: [],
  })
  useEffect(() => {
    let tables = [];
    let events = [];
    let entries = [];
    let cities = []
    top_10_city.length > 0 && top_10_city.forEach(data => {
      tables.push(data.table_booking);
      events.push(data.event_entry_booking);
      entries.push(data.club_entry_booking);
      cities.push(data.city);
    })
    setState((prevProps) => ({
      ...prevProps,
      tables: tables,
      events: events,
      entries: entries,
      cities: cities,
    }))
  }, [top_10_city]);

  const chartData = {
    series: [
      {
        name: "Club Table",
        data: state.tables
      },
      {
        name: "Club Entry",
        data: state.entries
      },
      {
        name: "Events",
        data: state.events
      },
    ],

    options: {
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "left",
      },
      colors: ["#ff6e54", "#dd5182", "#444e86"],
      chart: {
        type: "bar",
        height: 350,
        fontFamily: "Montserrat, sans-serif",
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: state.cities
      },
      yaxis: {
        title: {
          text: "(People)",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
    },
  };
  const getDateRange = (start, end) => {
    setSelectedDateRange(start, end, "city");
  }
  return (
    <ContentWrapper additionalClass="p-5 mt-5">
      <div className="d-flex justify-content-between font-body">
        <div>
          <h2 className="text-md text-xl-lg font-bold color-blue font-body mb-0">
            City Wise backgroundColorookings[Top 10]
          </h2>
          {/* <p className="text-xs text-xl-sm"> Menu Preferences: 200</p> */}
        </div>

        <div>
          <DateRange
            getDateRange={getDateRange}
            startDate={cityStartDate}
            endDate={cityEndDate}
          />
        </div>
      </div>
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        height={450}
      />
    </ContentWrapper>
  );
};
export default CityWiseBookings;