import ContentWrapper from "../../Components/Global/ContentWrapper";
import PageDescription from "../../Components/Global/PageDescription";
import Layout from "../../Layout/Influencer/Layout";
import { Table,Button } from "react-bootstrap";
import { Link } from "react-router-dom";
const ReferralsCode = () => {
  return (
    <Layout>
      <div className="d-flex justify-content-between align-items-center gap-3 gap-lg-5 font-body">
        <PageDescription title="Referral Codes" caption="" />
        <Link to="/advertisement-manager/create-advertisement">
          <Button variant="light" className="text-nowrap text-md px-5 py-2">
            Add New Referral Code
          </Button>
        </Link>
      </div>
      <ContentWrapper additionalClass="py-5 font-body">
        <Table striped hover responsive>
          <thead>
            <tr>
              <th>
                <p className="text-nowrap text-md text-uppercase ps-3">ID</p>
              </th>
              <th>
                <p className="text-nowrap text-md text-uppercase">Code</p>
              </th>
              <th>
                <p className="text-nowrap text-md text-uppercase">
                Created on 
                </p>
              </th>
              <th>
                <p className="text-nowrap text-md text-uppercase">
                status
                </p>
              </th>
              <th>
                <p className="text-nowrap text-md text-uppercase">Redeemed</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="align-middle">
                <p className="text-md ps-3"></p>
              </td>
              <td className="align-middle">
                <p className="text-md mb-0 text-nowrap"></p>
              </td>
              <td className="align-middle">
                <p className="text-md mb-0"></p>
              </td>
              <td className="align-middle">
                <p className="text-md mb-0"></p>
              </td>
              <td className="align-middle">
                <p className="text-md mb-0"></p>
              </td>
             
            </tr>
          </tbody>
        </Table>
      </ContentWrapper>
    </Layout>
  );
};
export default ReferralsCode;
