import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Col, Row, Modal, ModalBody } from "react-bootstrap";
import ContentWrapper from "../../Components/Global/ContentWrapper";
import Layout from "../../Layout/Layout";
import ActionIcon from "../../Components/Global/Buttons/ActionIcons";
import {
  collection,
  getDocs,
  orderBy,
  query,
  addDoc,
  serverTimestamp,
  setDoc,
  doc,
  getDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import db from "../../firebase";
import SuccessButton from "../../Components/Global/Buttons/SuccessButton";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import Toaster from "../../Components/Global/Toast";
import SecondaryButton from "../../Components/Global/Buttons/SecondaryButton";
import OutlinedButton from "../../Components/Global/Buttons/OutlinedButton";
import moment from "moment";
import { useRef } from "react";
import AdsModal from "../advertisement-manager/ads-modal";
const EditPopUp = () => {
  const params = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [redirectionDropDownError, setRedirectionDropDownError] = useState(false);
  const [clubRedirectionDropDownError, setClubRedirectionDropDownError] = useState(false)
  const [showToaster, setShowToaster] = useState(false);
  const [toasterDetails, setToasterDetails] = useState({
    title: "",
    bodyText: "",
    bg: "",
    statusIcon: "",
  });
  const navigate = useNavigate();
  const [popUpImageDeleteModal, setPopUpImageDeleteModal] = useState("")

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    setError,
    getValues,
    formState: { errors },
    reset,
  } = useForm();
  const selectedOption = watch("redirectionType"); // Watch the selected redirection Type
  const [selectedImage, setSelectedImage] = useState(null);
  const [ufile, setUfile] = useState({});
  const [showDateValidationModal, setShowDateValidationModal] = useState(false);
  const clubAndEventRef = useRef();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(URL.createObjectURL(file));
    setUfile(file);
    setError("image", null);
  };

  const handleDeselectImage = () => {
    setSelectedImage(null);
    const inputElement = document.getElementById("imageInput");
    if (inputElement) {
      inputElement.value = null;
    }
  };

  console.log(queryParams.get("redirectionId"), "params");

  // Get Data with id
  const getPopUpData = async () => {
    await getDoc(doc(db, "popups", params.popup_id))
      .then((response) => {
        const formattedStartDate = moment.unix(response.data().start_date.seconds).format("YYYY-MM-DD hh:mm");
        const formattedEndDate = moment.unix(response.data().end_date.seconds).format("YYYY-MM-DD hh:mm");
        const {
          image,
          city,
          redirectionType,
          clubId,
          eventId,
        } = response.data();

        // setPopData(response.data());
        console.log(city, "city");
        reset({
          city: city,
          redirectionType: redirectionType,
          start_date: formattedStartDate,
          end_date: formattedEndDate
          // club: clubId,
          // event: eventId,
        });
        setValue("club", clubId);
        setValue("event", eventId);
        setSelectedImage(image);
        setUfile(image);
      })
      .catch((error) => console.error(error));
  };

  // upload images
  const uploadImage = async () => {
    const formData = new FormData();
    formData.append("images", ufile);
    // console.log(ufile, "hhhhh");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_MULTIPLEIMAGES_POSTING_URL}/uploads/uploadImages`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            method: "POST",
          },
        }
      );
      return response.data.url[0];
    } catch (error) {
      console.log(error);
    }
  };

  // events fetch
  const [events, setEvents] = useState([]);
  const fetchEvents = async (city) => {
    const q = query(collection(db, "events"), orderBy("name"), where("club.city_name", "==", city), where("active", "==", true));
    const querySnapshot = await getDocs(q);
    const eventsData = querySnapshot.docs.map((doc) => ({
      value: doc.id,
      label: doc.data().name,

      // ...doc.data(),
    }));
    setEvents(eventsData);
    eventsData.length === 0 ? setRedirectionDropDownError(true) : setRedirectionDropDownError(false)
  };
  // clubs fetch
  const [clubs, setClubs] = useState([]);
  const fetchClubs = async (city) => {
    const q = query(collection(db, "clubs"), orderBy("name"), where("city_name", "==", city), where("status", "==", "Active"));
    const querySnapshot = await getDocs(q);
    const clubsData = querySnapshot.docs.map((doc) => ({
      value: doc.id,
      label: doc.data().name,
      address: doc.data().address
    }));
    setClubs(clubsData);
    // console.log(clubsData.length, "clubsData");
    clubsData.length === 0 ? setClubRedirectionDropDownError(true) : setClubRedirectionDropDownError(false)
  };


  // Cities fetch
  const [cities, setCities] = useState([]);
  const fetchCities = async () => {
    const q = query(collection(db, "cities"), orderBy("name"));
    const querySnapshot = await getDocs(q);
    const citiesData = querySnapshot.docs.map((doc) => ({
      value: doc.id,
      label: doc.data().name,
    }));
    setCities(citiesData);
  };

  useEffect(() => {
    fetchCities();
    fetchClubs(queryParams.get("city"));
    fetchEvents(queryParams.get("city"));
    getPopUpData();

  }, []);

  // On Submit function
  const onSubmit = async (data) => {
    console.log(data, "iiiidata")
    setShowToaster(true);
    setToasterDetails((prev) => ({
      ...prev,
      title: "Updating...",
      bodyText: "Updating pop up",
      bg: "warning",
      statusIcon: (
        <div className="spinner-border text-warning me-2" role="status"></div>
      ),
    }));
    const clubOrEventName = data.redirectionType === "clubs" ? clubs.find((ele) => ele.value === data.club) : events.find((ele) => ele.value === data.event);
    const payload = {
      city: data.city,
      image: typeof ufile === "object" ? await uploadImage(ufile) : ufile,
      redirectionType: data.redirectionType,
      clubId: data.redirectionType === "clubs" ? data.club : null,
      eventId: data.redirectionType === "events" ? data.event : null,
      updatedAt: serverTimestamp(),
      club_event_name: clubOrEventName.label,
      redirect_url: data.redirectionType === "clubs" ? `clubs/${data.club}` : `events/${data.event}`,
      updatedAt: serverTimestamp(),
      start_date: new Date(data.start_date),
      end_date: new Date(data.end_date),
    };

    console.log(payload, "payload")

    try {
      const docRef = await updateDoc(
        doc(db, "popups", params.popup_id),
        payload
      );
      // reset();
      // setSelectedImage(null);
      setToasterDetails((prev) => ({
        ...prev,
        title: "Success",
        bodyText: "Updated popup Successfully",
        bg: "success",
        statusIcon: <i className="bi bi-check text-success text-3xl me-2"></i>,
      }));
      setTimeout(() => {
        navigate("/popups");
      }, 2000);
    } catch (e) {
      console.error("Error adding document: ", e);
      setToasterDetails((prev) => ({
        ...prev,
        title: "Error",
        bodyText: "Something went wrong",
        bg: "danger",
        statusIcon: (
          <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
        ),
      }));
    }
  };
  return (
    <Layout path="/popups/edit-popup">
      <ContentWrapper additionalClass="p-5">
        {/* Form Submission */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row className="g-4">
            {/* Image */}
            <Col xs={6}>
              <label className="text-md mb-1">Image <span className="text-danger"> *</span></label>
              <div
                id="displayImage"
                style={{
                  height: "240px",
                  width: "100%",
                  backgroundImage: `url(${selectedImage ? selectedImage : null
                    })`,
                  border: `${selectedImage ? "0px" : "1px"} dashed #D2D6DA`,
                }}
                className="d-flex align-items-center justify-content-center background-position-center-top background-repeat-norepeat background-size-cover rounded overflow-hidden position-relative"
              >
                {selectedImage ? (
                  <div className="position-absolute end-0 top-0">
                    <ActionIcon
                      onClick={() => {
                        setPopUpImageDeleteModal(true)
                      }}
                      additionalClass="bi bi-x-circle-fill text-3xl color-red me-2"
                    />
                  </div>
                ) : null}

                {selectedImage ? null : (
                  <div>
                    <input
                      type="file"
                      id="imageInput"
                      {...register("image", { required: "Image is required" })}
                      onChange={handleImageChange}
                      accept="image/*"
                      className="opacity-0 position-absolute start-0 end-0 top-0 bottom-0 w-100 h-100"
                    />

                    <div className="text-center">
                      <i className="bi bi-plus-circle-fill text-5xl color-lightgreen d-block"></i>
                      <p className="text-md font-body text-center  ">
                        Upload your Image here.
                      </p>
                    </div>
                  </div>
                )}
              </div>
              {errors.image && (
                <p className="text-sm text-danger mt-1 font-body">
                  {errors.image.message}
                </p>
              )}
            </Col>
            {/* City */}
            <Col xs={6}>
              <label className="text-md mb-1">City <span className="text-danger"> *</span></label>
              <select
                className="po-edit-input"
                {...register("city", { required: "City is required" })}
                onChange={(e) => {
                  setError("city", null)
                  fetchEvents(e.target.value);
                  fetchClubs(e.target.value);
                  setValue("redirectionType", "events");
                  setValue("club", null);
                  setValue("event", "")
                }}
              >
                <option value="" disabled>
                  Select city
                </option>
                {cities &&
                  cities.map((data, index) => {
                    return (
                      <option key={index} value={data.label}>
                        {data.label}
                      </option>
                    );
                  })}
              </select>
              {errors.city && (
                <p className="text-sm text-danger mt-1 font-body">
                  {errors.city.message}
                </p>
              )}
            </Col>
            {/* Redirection */}
            <Col xs={6}>
              <label className="text-md mb-1">Redirection </label>
              <select
                className="po-edit-input"
                {...register("redirectionType", {
                  required: "Event type is required",
                })}
              >
                <option value="">Select Destination type</option>
                <option value="events">Events</option>
                <option value="clubs">Clubs</option>
              </select>
              {errors.redirectionType && (
                <p className="text-sm text-danger mt-1 font-body">
                  {errors.redirectionType.message}
                </p>
              )}
            </Col>
            {/* Event */}
            <Col xs={6}>
              {selectedOption === "events" ? (
                <div>
                  <label className="text-md mb-1">Event <span className="text-danger">*</span></label>
                  <select
                    disabled={events.length === 0 ? true : false}
                    className="po-edit-input"
                    {...register("event", { required: "Event is required" })}
                  >
                    <option value="">Select event</option>
                    {events.map((data, index) => {
                      return (
                        <option key={index} value={data.value}>
                          {data.label}
                        </option>
                      );
                    })}
                  </select>
                  {errors.event && (
                    <p className="text-sm text-danger mt-1 font-body">
                      {errors.event.message}
                    </p>
                  )}
                  {redirectionDropDownError && <p className="text-warning text-sm m-1 bold"> No events are available for the selected city.Please choose different city</p>}
                </div>
              ) : (
                <div>
                  <label className="text-md mb-1">Club <span className="text-danger">*</span></label>
                  <select
                    disabled={clubs.length === 0 ? true : false}
                    className="po-edit-input"
                    {...register("club", { required: "Club is required" })}
                  >
                    <option value="" >Select Club</option>
                    {clubs.map((data, index) => {
                      return (
                        <option key={index} value={data.value}>
                          {data.label} - {data.address}
                        </option>
                      );
                    })}
                  </select>
                  {errors.club && (
                    <p className="text-sm text-danger mt-1 font-body">
                      {errors.club.message}
                    </p>
                  )}

                  {clubRedirectionDropDownError && <p className="text-warning text-sm m-1 bold">No clubs are available for the selected city. Please choose different city</p>}

                </div>
              )}
            </Col>
            <Col xs={6}>
              <label className="text-md mb-1">Start Date <span className="text-danger"> *</span></label>
              <input
                min={`${new Date().toISOString().slice(0, 16)}`}
                className="po-edit-input"
                type="datetime-local"
                {...register("start_date", { required: "Start Date required" })}
              />


              {errors.startDate && (
                <p className="text-sm text-danger mt-1 font-body">
                  {errors.startDate.message}
                </p>
              )}
            </Col>
            <Col xs={6}>
              <label className="text-md mb-1">End Date <span className="text-danger"> *</span></label>
              <input
                // min={`${new Date().toISOString().slice(0, 16)}`}
                className="po-edit-input"
                type="datetime-local"
                {...register("end_date", {
                  required: "End Date required",
                  validate: (value) => {
                    const startDate = new Date(getValues('start_date'));
                    const endDate = new Date(value);
                    if (startDate > endDate) {
                      setShowDateValidationModal(true);
                      setTimeout(() => {
                        setShowDateValidationModal(false)
                      }, 1000)
                      return false;
                    }

                    return true;
                  },
                })}

              />
              {errors.endDate && (
                <p className="text-sm text-danger mt-1 font-body">
                  {errors.endDate.message}
                </p>
              )}
            </Col>
            {/* Buttons */}
            <Col className="text-end">
              <OutlinedButton
                link="/popups"
                name="Cancel"
                additionalClass="me-3"
                disabled={showToaster}
              />
              <SuccessButton
                disabled={showToaster}
                type="submit"
                name="Save"
                icon="bi-check-circle"
              />
            </Col>
          </Row>
        </form>
      </ContentWrapper>
      {/* Popup Image */}
      <Modal size="xs" show={popUpImageDeleteModal} onHide={() => setPopUpImageDeleteModal(false)}  >

        <div className="background-appColor p-5 rounded font-body">
          <h5 className="text-2xl  color-ternary  mb-3">
            Are you sure?
          </h5>
          <ModalBody>
            <p className="text-light font-weight-bold">Do you want to delete this popup image?</p>
          </ModalBody>
          <div className="d-flex justify-content-end">
            <SecondaryButton
              onClick={() => setPopUpImageDeleteModal(false)}
              name="No"
              additionalClass="text-light mt-3 me-4 px-5"
            />
            <SecondaryButton
              onClick={() => {
                setSelectedImage(null)
                setPopUpImageDeleteModal(false)
              }}
              name="Yes"
              additionalClass="text-light mt-3 me-4 px-5"
            />

          </div>
        </div>
      </Modal >
      <AdsModal
        icon={true}
        showModal={showDateValidationModal}
        setShowModal={() => setShowDateValidationModal(true)}
        warningText="Warning"
        bodyText="Please ensure that the selected end date is lesser than the start date"
        bodyParaClass="text-light font-weight-bold"
        successButtonClass="text-light mt-3 me-4 px-5"
        cancelButtonClass="text-light mt-3 me-4 px-5"
      />
      <Toaster
        showToaster={showToaster}
        setShowToaster={setShowToaster}
        bg={toasterDetails.bg}
        title={toasterDetails.title}
        bodyText={toasterDetails.bodyText}
        statusIcon={toasterDetails.statusIcon}
      />
    </Layout>
  );
};
export default EditPopUp;
