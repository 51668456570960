import { initializeApp } from "firebase/app";
import { arrayUnion, doc, getFirestore, updateDoc } from "firebase/firestore"
import { getAuth } from "firebase/auth";
import { getDatabase } from "@firebase/database";
import { getToken, getMessaging, onMessage } from 'firebase/messaging';

let user_id = localStorage.getItem('user_id');
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PRODUCTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID
};

const firebase_app = initializeApp(firebaseConfig);
export const db = getFirestore(firebase_app)
export const auth = getAuth(firebase_app)
export const database = getDatabase(firebase_app);
export default db

const messaging = getMessaging(firebase_app);

export const requestPermission = () => {
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      return getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY })
        .then((currentToken) => {
          if (currentToken) {
            updateDoc(doc(db, "users", user_id), {device_token: arrayUnion(currentToken)})
            .then(response=>console.log(response))
            .catch(err=>console.log("err", err))
          } else {
            console.log('Failed to generate the app registration token.');
          }
        })
        .catch((err) => {
          console.log('An error occurred when requesting to receive the token.', err);
        });
      } else {
        console.log("User Permission Denied.");
      }
    });
  }

export const onForegroundMessage = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});