import { Col, Row } from "react-bootstrap";
import ContentWrapper from "../../Components/Global/ContentWrapper";
import Layout from "../../Layout/Influencer/Layout";

const InfluencerProfile = () => {
  return (
    <Layout>
      <ContentWrapper additionalClass="font-body p-5">
      <h6 className="text-2xl font-bold mb-5">Influencer Profile</h6>
        <Row>
          <Col xs="12" md="8">
           
            <Row className="g-4">
              <Col md="6">
                <h4 className="text-lg mb-1 text-black font-semibold">Name</h4>
               <div className="d-flex align-items-center">
               <div style={{height:"70px",width:"70px"}} className="rounded-circle bg-danger"></div>
                <p className="text-md text-black ms-2">
                Tatiya Vichu
              </p>
               </div>
              </Col>
              <Col md="6">
              <h4 className="text-lg mb-1 text-black font-semibold">Email</h4>
              <p className="text-md text-black">
                <a href="mailto:vichu@gmail.com" className="text-black">vichu@gmail.com</a>
              </p>
              </Col>
              <Col md="6">
              <h4 className="text-lg mb-1 text-black font-semibold">Phone</h4>
              <p className="text-md text-black">
                <a href="tel:9876543210" className="text-black">9876543210</a>
              </p>
              </Col>
              <Col md="6">
              <h4 className="text-lg mb-1 text-black font-semibold">Country</h4>
              <p className="text-md text-black">
                India
              </p>
              </Col>
              <Col md="6">
              <h4 className="text-lg mb-1 text-black font-semibold">City</h4>
              <p className="text-md text-black">
                Nala sopara
              </p>
              </Col>
              
            </Row>
          </Col>
          <Col xs="12" md="4">
            <div className="rounded bg-light p-4 mb-5 w-75">
              <div className="text-sm mb-2">Profit</div>
              <div className="text-xl font-bold mb-4">$15878</div>
              <div className="text-xs">
              <i className="bi bi-arrow-up me-1"></i>20% since last month
              </div>
            </div>
            <div className="rounded bg-light p-4 w-75">
              <div className="text-sm mb-2">Total referrals</div>
              <div className="text-xl font-bold mb-4">20</div>
              <div className="text-xs">
              <i className="bi bi-arrow-up me-1"></i>5% since last month
              </div>
            </div>
          </Col>
        </Row>
      </ContentWrapper>
    </Layout>
  );
};
export default InfluencerProfile;
