import Layout from "../../Layout/Layout";
import ContentWrapper from "../../Components/Global/ContentWrapper";
// import Layout from "../../Layout/Layout";
import { useForm } from "react-hook-form";
import { Row, Col, Modal, ModalBody } from "react-bootstrap";
import {
  collection,
  doc,
  getDocs,
  where,
  query,
  getDoc,
  updateDoc,
} from "@firebase/firestore";
import { useEffect } from "react";
import db from "../../firebase";
import { useState } from "react";
import ActionIcon from "../../Components/Global/Buttons/ActionIcons";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router";
// import { useNavigate } from "react-router-dom";
// import Success from "../../Components/Global/States/Success";
// import Loading from "../../Components/Global/States/Loading";
// import Error from "../../Components/Global/States/Error";
import SuccessButton from "../../Components/Global/Buttons/SuccessButton";
import OutlinedButton from "../../Components/Global/Buttons/OutlinedButton";
import Toaster from "../../Components/Global/Toast";
import { Link } from "react-router-dom";
import SecondaryButton from "../../Components/Global/Buttons/SecondaryButton";
const EditCity = () => {
  const [showToaster, setShowToaster] = useState(false);
  const [show, setShow] = useState(false);
  const [cities, setCities] = useState(null);
  const [toasterDetails, setToasterDetails] = useState({
    title: "",
    bodyText: "",
    bg: "",
    statusIcon: "",
  });
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { city_id } = useParams();
  const id = city_id;
  const [countries, setCountries] = useState([]);
  const [cityImageDeleteModal, setCityImageDeleteModal] = useState(null)
  const [states, setStates] = useState([]);
  const [image, setImage] = useState({});
  const [country, setCountry] = useState("");
  const [countryId, setCountryId] = useState("");
  const [state, setState] = useState("");
  const [stateId, setStateId] = useState("");
  const [previewImage, setPreviewImage] = useState(null);
  const [updateData, setUpdateData] = useState({});
  const [imageFromBackend, setImageFromBackend] = useState(true);

  const navigate = useNavigate();
  const insertingImage = async (imageData) => {
    let form = new FormData();
    form.append("images", imageData);
    let responseImage = await axios
      .post(
        `${process.env.REACT_APP_MULTIPLEIMAGES_POSTING_URL}/uploads/uploadImages`,
        form,
        { headers: { "Content-Type": "multipart/form-data" } }
      )
      .then((resImage) => {
        return resImage && resImage.data.url[0];
      })
      .catch((error) => console.log(error, "err"));
    return responseImage;
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //Duplicates Validation
  const checkingForDuplicates = (value) => {
    let flag = false;
    cities.forEach((ele) => {
      if (ele.id != city_id) {
        if (
          ele.data().name === value.trim() &&
          ele.data().country_id === countryId &&
          ele.data().state_id === stateId
        ) {
          flag = true;
        }
      }
    });
    return flag;
  };
  const getAllCities = async () => {
    await getDocs(collection(db, "cities"))
      .then((res) => {
        setCities(res.docs);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  // removing cache
  const handleCache = async () => {
    const token = localStorage.getItem("authenticate");
    const headers = {
      headers: {
        developement: true,
        token: token,
      },
    };
    await axios
      .post(`${process.env.REACT_APP_API_URL}/dashboard/v1/clearCache`, {}, headers)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };



  useEffect(() => {
    getAllCities();
  }, []);

  const onSubmit = async (data) => {
    console.log(data, "datadatadata1111")
    // setIsLoading(true);
    const duplicatesResult = checkingForDuplicates(data.cityName);
    if (duplicatesResult) {
      setShow(true);
      return;
    }
    setShowToaster(true);
    setToasterDetails((prev) => ({
      ...prev,
      title: "Updating...",
      bodyText: "Updating city",
      bg: "warning",
      statusIcon: (
        <div className="spinner-border text-warning me-2" role="status"></div>
      ),
    }));
    let payloadImage = "";
    if (imageFromBackend) {
      payloadImage = previewImage;
    } else if (imageFromBackend == false) {
      payloadImage = await insertingImage(image);
    }

    const payload = {
      name: data && data.cityName,
      city_image: payloadImage,
      country_name: country && country,
      country_id: countryId && countryId,
      latitude: data && Number(data.latitude),
      longitude: data && Number(data.longitude),
      party_location: data && data.partyLocation,
      // popular_hometown: data && data.popularHometown,
      state_name: state,
      state_id: stateId && stateId,
      country_timezone: data.countrytimezone,
    };
    console.log(payload, "payload");
    try {
      const clubResponse = await updateDoc(doc(db, "cities", id), payload).then(
        () => {
          // setIsLoading(false);
          // setIsSuccess(true);
          setToasterDetails((prev) => ({
            ...prev,
            title: "Success",
            bodyText: "Updated city successfully",
            bg: "success",
            statusIcon: (
              <i className="bi bi-check text-success text-3xl me-2"></i>
            ),
          }));
          setTimeout(() => {
            handleCache();
            navigate("/city");
          }, 2000);
          settingData();
        }
      );
    } catch (err) {
      setToasterDetails((prev) => ({
        ...prev,
        title: "Error",
        bodyText: "Something went wrong",
        bg: "danger",
        statusIcon: (
          <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
        ),
      }));
      console.log(err, "err");
      // setIsLoading(false);
      // setIsError(true);
    }
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    console.log(file, "file");
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
      console.log(file, "its coming");
      setImage(file);
      setImageFromBackend(false);
    }
  };
  const handleState = (e) => {

    e.target.options && setState(e.target.options[e.target.selectedIndex].text);
    setStateId(e.target.value);
    setState(e.target.options[e.target.selectedIndex].text);
  };
  const getAllStates = async (id) => {
    const statesResponse = await getDocs(
      query(collection(db, "states"), where("country_id", "==", id))
    );
    setStates(statesResponse.docs);
  };
  const handleCountry = (e) => {
    console.log(e.target.options[e.target.selectedIndex].text, "country");
    e.target.options &&
      setCountry(e.target.options[e.target.selectedIndex].text);
    setCountryId(e.target.value);
    getAllStates(e.target.value);
  };
  const getAllCountriesAndStates = async () => {
    const countriesResponse = await getDocs(collection(db, "countries"));
    // console.log(countriesResponse.docs, "countries");
    setCountries(countriesResponse.docs);

    // console.log(statesResponse.docs, "states");
  };

  const settingData = async () => {
    const cityResponse = await getDoc(doc(db, "cities", id));
    let ResponseData = cityResponse.data();
    console.log(ResponseData, "cityResponse");
    cityResponse && setUpdateData(ResponseData);
    getAllCountriesAndStates();

    ResponseData &&
      ResponseData.country_id &&
      setCountryId(ResponseData.country_id);
    ResponseData &&
      ResponseData.country_id &&
      getAllStates(ResponseData.country_id);
    ResponseData && setStateId(ResponseData.state_id);
    ResponseData && setCountry(ResponseData.country_name);
    setState(ResponseData.state_name);
    reset((register) => ({
      ...register,
      // countryName: updateData && updateData.country_name,
      cityName: ResponseData.name,
      partyLocation: ResponseData.party_location,
      // popularHometown: ResponseData.popular_hometown,
      // stateName: updateData.state_name,
      latitude: ResponseData.latitude,
      longitude: ResponseData.longitude,
      countryName: ResponseData.country_id,
      countrytimezone: ResponseData.country_timezone,
      // display_image: updateData.city_image
      stateName: ResponseData.state_id,
    }));
    ResponseData &&
      ResponseData.city_image &&
      setPreviewImage(ResponseData.city_image);
    setImageFromBackend(true);
  };
  useEffect(() => {
    if (city_id) {
      settingData();
    }
  }, [city_id]);
  return (
    <Layout path="/city/edit-city">
      {/* <Link to="/city">
            <div  className="d-flex align-items-center mb-5">
               <div>
                <img
                  src="/assets/icons/sidebar/dark/location-icon-dark.svg"
                  alt="dashboard-icon"
                  width="15"
                  height="15"
                  className="d-block"
                />
              </div>

            <div className="ms-2 text-sm text-lg-md font-body font-semibold text-black">
            City
              </div>
               <div className="ms-2 text-xs text-lg-sm font-body font-regular text-black">
               / edit
              </div>
            </div>
            </Link> */}
      <ContentWrapper additionalClass="p-5 font-body">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row className="g-3">
            <Col xs={12}>
              <label htmlFor="cityImage">City image<span style={{ color: "red", fontSize: "20px" }}>*</span></label>
              <div
                style={{
                  height: "200px",
                  width: "350px",
                  backgroundImage: `url(${previewImage ? previewImage : null})`,
                  border: `${previewImage ? "0px" : "1px"} dashed #D2D6DA`,
                }}
                className="d-flex align-items-center justify-content-center background-position-center-top background-repeat-norepeat background-size-cover rounded overflow-hidden position-relative"
              >
                {previewImage ? (
                  <div className="position-absolute end-0 top-0">
                    <ActionIcon
                      onClick={() => {
                        // setPreviewImage(null);
                        setCityImageDeleteModal(true)
                      }}
                      additionalClass="bi bi-x-circle-fill text-3xl color-red me-2"
                    />
                  </div>
                ) : null}

                {previewImage ? null : (
                  <div>
                    <input
                      accept="image/*"
                      type="file"
                      id="display_image"
                      {...register("display_image", {
                        required: "Image is required",
                      })}
                      onChange={handleImageChange}
                      className="opacity-0 position-absolute start-0 end-0 top-0 bottom-0"
                    />

                    <div className="text-center">
                      <i className="bi bi-plus-circle-fill text-5xl color-lightgreen d-block"></i>
                      <p className="text-md font-body text-center  ">
                        Upload your Image here.
                      </p>
                    </div>
                  </div>
                )}
              </div>
              {
                previewImage ? null : <p className="text-sm text-danger">

                  {errors.display_image && <span>This field is required</span>}
                </p>
              }

            </Col>

            <Col xs={6}>
              <label htmlFor="countryName">Country name<span style={{ color: "red", fontSize: "20px" }}>*</span></label>
              <select
                id="countryName"
                {...register("countryName", { required: "This field is required" })}
                className="po-edit-input"
                onChange={handleCountry}
                value={countryId}
              >
                <option value="">Select a country</option>
                {countries &&
                  countries.map((country, index) => (
                    <option
                      key={index}
                      value={country.data() && country.data().id}
                    >
                      {country.data() && country.data().name}
                    </option>
                  ))}
              </select>
              <p className="text-sm text-danger">
                {errors.countryName?.message}
              </p>
            </Col>
            <Col xs={6}>
              <label htmlFor="stateName">State name<span style={{ color: "red", fontSize: "20px" }}>*</span></label>
              <select
                className="po-edit-input"
                id="stateName"
                {...register("stateName", { required: "This field is required" })}
                onChange={handleState}
                value={stateId}
              >
                <option value="" >Select a state</option>
                {states &&
                  states.map((state, index) => (
                    <option key={index} value={state.data().id}>
                      {state.data().name}
                    </option>
                  ))}
              </select>

              <p className="text-sm text-danger">
                {" "}
                {errors.stateName?.message}
              </p>
            </Col>
            <Col xs={12}>
              <label htmlFor="cityName">City name<span style={{ color: "red", fontSize: "20px" }}>*</span></label>
              <input
                className="po-edit-input"
                type="text"
                id="cityName"
                {...register("cityName", {
                  required: true,
                  pattern: {
                    value: /^(?!\s*$).+/,
                    message: 'Only alphabetic characters are allowed.',
                  }
                })}
              />
              <p className="text-sm text-danger">
                {errors.cityName && errors.cityName.type === "required" && (
                  <span>This field is required</span>
                )}

                {errors.cityName && errors.cityName.type === "pattern" && (
                  <span>Only alphabetic characters are allowed.</span>
                )}
              </p>
            </Col>
            <Col xs={6}>
              <label htmlFor="latitude">Latitude<span style={{ color: "red", fontSize: "20px" }}>*</span></label>
              <input
                type="text"
                id="latitude"
                className="po-edit-input"
                {...register("latitude", {
                  required: true,
                  pattern: /^\d+(\.\d+)?$/,
                })}
              />

              <p className="text-sm text-danger">
                {errors.latitude && errors.latitude.type === "required" && (
                  <span>This field is required</span>
                )}
                {errors.latitude && errors.latitude.type === "pattern" && (
                  <span>Invalid format (must be a number)</span>
                )}
              </p>
            </Col>
            <Col xs={6}>
              <label htmlFor="longitude">Longitude<span style={{ color: "red", fontSize: "20px" }}>*</span></label>
              <input
                type="text"
                id="longitude"
                className="po-edit-input"
                {...register("longitude", {
                  required: true,
                  pattern: /^\d+(\.\d+)?$/,
                })}
              />

              <p className="text-sm text-danger">
                {errors.longitude && errors.longitude.type === "required" && (
                  <span>This field is required</span>
                )}
                {errors.longitude && errors.longitude.type === "pattern" && (
                  <span>Invalid format (must be a number)</span>
                )}
              </p>
            </Col>

            {/* <Col xs={6}>
              <input
                type="checkbox"
                id="popularHometown"
                {...register("popularHometown")}
              />
              <label htmlFor="popularHometown">Popular hometown</label>
            </Col> */}


            {/* Country Timezone */}
            <Col xs={6}>
              <label >Country timezone</label>
              <input type="text"
                {...register("countrytimezone", {
                  required: "This field is required"
                })}
                className="po-edit-input"
              // placeholder="Please enter country code"
              />
              {errors.countrytimezone && <p className="text-danger text-sm">{errors.countrytimezone.message}</p>}
            </Col>
            <Col xs={12} className="d-flex align-items-center">
              <input
                type="checkbox"
                id="partyLocation"
                {...register("partyLocation")}
              />
              <label htmlFor="partyLocation" className="ms-2">Status</label>
            </Col>
          </Row>

          <div className="d-flex justify-content-end">
            <OutlinedButton link="/city" name="Cancel" />
            <SuccessButton
              type="submit"
              name="Update city"
              icon="bi-check-circle"
              additionalClass={`ms-4 ${showToaster ? "opacity-06 cursor-default" : null
                }  `}
              disabled={showToaster ? true : false}
            />
          </div>
        </form>
      </ContentWrapper>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <div className="background-appColor p-8 rounded font-body">
          <h2 className="text-2xl  color-ternary font-semibold mb-4">Error</h2>
          <p className="text-md text-light text-wrap mb-1">
            {" "}
            City is already Exists
          </p>
          <div className="text-end">
            <SecondaryButton
              onClick={handleClose}
              name="Close"
              additionalClass="text-light"
            />
          </div>
        </div>
      </Modal>
      {/* City Delete Image Modal */}
      <Modal size="xs" show={cityImageDeleteModal} onHide={() => setCityImageDeleteModal(false)}  >

        <div className="background-appColor p-5 rounded font-body">
          <h5 className="text-2xl  color-ternary  mb-3">
            Are you sure?
          </h5>
          <ModalBody>
            <p className="text-light font-weight-bold">Do you want to delete city image?</p>
          </ModalBody>
          <div className="d-flex justify-content-end">
            <SecondaryButton
              onClick={() => setCityImageDeleteModal(false)}
              name="No"
              additionalClass="text-light mt-3 me-4 px-5"
            />
            <SecondaryButton
              onClick={() => {

                // setSelectedImage(null)
                setPreviewImage(null);
                // setImage(null);
                setCityImageDeleteModal(false)
              }}
              name="Yes"
              additionalClass="text-light mt-3 me-4 px-5"
            />

          </div>
        </div>
      </Modal >

      <Toaster
        showToaster={showToaster}
        setShowToaster={setShowToaster}
        bg={toasterDetails.bg}
        title={toasterDetails.title}
        bodyText={toasterDetails.bodyText}
        statusIcon={toasterDetails.statusIcon}
      />
    </Layout>
  );
};
export default EditCity;
