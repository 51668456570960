import React from 'react';
import { Col } from "react-bootstrap";
import StatsCard from "../Global/StatsCard";

const club = {
  title: "Total Clubs Registered",
  caption: "Since last Month",
  icon: "ternary"
}
const membership = {
  title: "Total Paid Memberships",
  caption: "Since last Month",
  icon: "danger"
}
const revenue = {
  title: "Total Revenue",
  caption: "Since last Month",
  icon: "success"
}

const Stats = ({ all_clubs, all_membership_request, total_revenue, }) => {
  return (
    <>
      {all_clubs ? <Col xs={12} sm={6} lg={4}>
        <StatsCard
          cardTitle={club.title}
          cardStats={all_clubs}
          cardIcon={club.icon}
        />
      </Col> :""}
      {all_membership_request ? <Col xs={12} sm={6} lg={4}>
        <StatsCard
          cardTitle={membership.title}
          cardStats={all_membership_request}
          cardIcon={membership.icon}
        />
      </Col>:""}
      {all_membership_request ? <Col xs={12} sm={6} lg={4}>
        <StatsCard
          cardTitle={revenue.title}
          cardStats={total_revenue}
          cardDuration="From bookings and membership subscriptions"
          cardIcon={revenue.icon}
        />
      </Col> :""}
    </>
  );
};
export default Stats;
