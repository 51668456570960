import { Fragment } from "react";
import { useUserRoles } from "../utils/useUserRoles";
import { Navigate } from "react-router-dom";

export const RolesAuthRoute =  ({ children, element, roles }) => {

    const userRoles = useUserRoles();

    const canAccess = userRoles && userRoles.some(userRole => roles.includes(userRole));

    if (canAccess)
        return (
            <Fragment>
                {element}
            </Fragment>
        );

    return (<Navigate to="/" />);
}
