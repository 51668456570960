import ContentWrapper from "../../Components/Global/ContentWrapper";
import Layout from "../../Layout/Layout";
import { Row, Col, Button, Table } from "react-bootstrap";
import { createRef, useCallback, useRef } from "react";
import { useState } from "react";
import { Timestamp, and, collection, endAt, getDocs, orderBy, query, startAt, where } from "firebase/firestore";
import db from "../../firebase";
import OutlinedButton from "../../Components/Global/Buttons/OutlinedButton";
import SuccessButton from "../../Components/Global/Buttons/SuccessButton";
import ActionIcon from "../../Components/Global/Buttons/ActionIcons";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Toaster from "../../Components/Global/Toast";
import moment from "moment";

const CreateEventBooking = () => {
  const eventListRef = createRef();
  const userListRef = createRef();
  const searchInputRef = useRef("");
  const [inputLoader, setInputLoader] = useState({
    event: false,
    user: false
  });
  const [showEventList, setShowEventList] = useState(false);
  const [showUserList, setShowUserList] = useState(false);
  const [active, setActive] = useState(0);
  const searchEventIdRef = useRef("");
  const searchUserIdRef = useRef("");
  const [events, setEvents] = useState([]);
  const [users, setUsers] = useState([]);
  const token = localStorage.getItem("authenticate");
  const [showToaster, setShowToaster] = useState(false);
  const [toasterDetails, setToasterDetails] = useState({
    title: "",
    bodyText: "",
    bg: "",
    statusIcon: "",
  });
  const [guestErrors, setGuestErrors] = useState({
    gender: false,
    age: {
      age: false,
      ageInputValidation: false,
      ageAboveHundredYears: false,
      ageBelowTwenty: false,
    },
  });
  const navigate = useNavigate();
  // const [formErrors, setFormErrors] = useState({
  //   name: false,
  //   username: false,
  // });
  const [guestDetails, setGuestDetails] = useState({
    guest_name: "",
    gender: "",
    age: "",
  });
  const [userId, setUserId] = useState("");
  const [eventId, setEventId] = useState("");
  const [state, setState] = useState({
    event_name: "", // will use for submit form
    user_name: "",
    people: [],
    errors: {
      event_name: "",
      user_name: "",
      people: "",
    },
  });

  const keyDownHandler = event => {
    if (showEventList) {
      if (event.keyCode === 38 && active > 0) {
        let tempActive = active - 1;
        setActive(tempActive);
        eventListRef.current.scrollTop -= document.getElementById(`event-${active}`).offsetHeight;
      }
      if (event.keyCode === 40 && active < events.length - 1) {
        let nextTemp = active + 1;
        setActive(nextTemp);
        eventListRef.current.scrollTop += document.getElementById(`event-${active}`).offsetHeight;
      }
    } else {
      if (event.keyCode === 38 && active > 0) {
        let tempActive = active - 1;
        setActive(tempActive);
        userListRef.current.scrollTop -= document.getElementById(`user-${active}`).offsetHeight;
      }
      if (event.keyCode === 40 && active < users.length - 1) {
        let nextTemp = active + 1;
        setActive(nextTemp);
        userListRef.current.scrollTop += document.getElementById(`user-${active}`).offsetHeight;
      }
    }
  };
  const searchInputHandler = (e) => {
    const { name, value } = e.target;
    searchInputRef.current = value;
    if (searchInputRef.current !== "") {
      if (name === "event_name") {
        searchEventIdRef.current = "";
        setState(prev => ({
          ...prev,
          [name]: searchInputRef.current, // will use for submit form
          errors: {
            ...state.errors,
            [name]: ""
          }
        }))
        setInputLoader(prev => ({
          ...prev,
          event: true
        }));
        fetchSearchedData(searchInputRef.current, "event_name");
        setUsers([]);
      } else {
        searchUserIdRef.current = "";
        setInputLoader(prev => ({
          ...prev,
          user: true
        }));
        setState(prev => ({
          ...prev,
          [name]: searchInputRef.current, // will use for submit form
          people: [],
          errors: {
            ...state.errors,
            [name]: ""
          }
        }))
        fetchSearchedData(searchInputRef.current, 'user_name');
        setEvents([]);
      }
    }
    else {
      setActive(0);
      setEvents([]);
      setUsers([]);
      setState(prev => ({
        ...prev,
        [name]: searchInputRef.current,
        errors: {
          ...state.errors,
          [name]: ""
        }
      }))
      setInputLoader({
        event: false,
        user: false
      })
    }
  }
  const fetchSearchedData = useCallback(
    debounce((val, searchField) => {
      if (!searchInputRef.current) return;
      if (searchField === "event_name") {
        val.length !== 0 &&
          getDocs(query(collection(db, "events"),
            where("active", "==", true),
            orderBy("name_lowercase"),
            startAt(val.replace(/\s+/g, ' ').trim().toLowerCase()),
            endAt(`${val.replace(/\s+/g, ' ').trim().toLowerCase()}\uf8ff`)),
          )
            .then((docSnapshots) => {
              setEvents([]);
              setShowUserList(false);
              setInputLoader(prev => ({
                ...prev,
                event: false
              }));
              setShowEventList(true);
              docSnapshots.forEach((docs) => {
                if (docs.data().date_and_time > Timestamp.now()) setEvents((prev) => [...prev, docs.data()]);
              });
            })
      } else {
        axios.get(`${process.env.REACT_APP_API_URL}/user/v2/search?search_string=${encodeURIComponent(val)}&active=${true}&limit=${20}&offset=${0}`)
          .then((response) => {
            setShowEventList(false);
            setShowUserList(true);
            setUsers(response.data.result.filter(item => item.active_membership_id));
            setInputLoader(prev => ({
              ...prev,
              user: false
            }));
          }).catch((err) => {
            console.log(err, 'err');
          })
      }
    }, 1000), []);
  const onInputBlurHandler = () => {
    setTimeout(() => {
      setShowEventList(false);
      setActive(0);
      if (!searchEventIdRef.current) {
        setState(prev => ({
          ...prev,
          errors: {
            ...state.errors,
            event_name: "Please select event name"
          }
        }))
      } else {
        setState(prev => ({
          ...prev,
          errors: {
            ...state.errors,
            event_name: ""
          }
        }))
      }
    }, 300);
  };
  const onUserInputBlurHandler = () => {
    setTimeout(() => {
      setActive(0);
      setShowUserList(false);
      if (!searchUserIdRef.current) {
        setState(prev => ({
          ...prev,
          errors: {
            ...state.errors,
            user_name: "Please select user name"
          }
        }))
      } else {
        setState(prev => ({
          ...prev,
          errors: {
            ...state.errors,
            user_name: ""
          }
        }))
      }
    }, 300)
  }

  function debounce(fn, delay) {
    let timer;
    return function () {
      let context = this,
        args = arguments;
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(context, args);
      }, delay);
    };
  }
  const handleSelectSearchedInput = (name, id, input_name) => {
    setState(prev => ({
      ...prev,
      [input_name]: name,
      errors: {
        ...state.errors,
        [input_name]: ""
      }
    }))
    if (input_name === "user_name") {
      searchUserIdRef.current = id;
      setUserId(searchUserIdRef.current);
    } else {
      searchEventIdRef.current = id;
      setEventId(searchEventIdRef.current);
    }
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    let todayDate = moment(new Date())
    if (document.getElementById('event_name') === document.activeElement && events[active] && events[active].id) {
      searchEventIdRef.current = events[active].id;
      setEventId(searchEventIdRef.current);
      setState(prev => ({
        ...prev,
        event_name: events[active].name,
        errors: {
          ...state.errors,
          event_name: ""
        }
      }))
      onInputBlurHandler();
      return;
    }
    if (document.getElementById('user_name') === document.activeElement && users[active] && users[active].id) {
      searchUserIdRef.current = users[active].id;
      setState(prev => ({
        ...prev,
        user_name: users[active].name,
        errors: {
          ...state.errors,
          user_name: ""
        }
      }))
      setUserId(searchUserIdRef.current);
      onUserInputBlurHandler();
      return;
    }
    if (eventId === "" || userId === "") {
      let validation = state.errors;
      if (eventId === "") {
        validation.event_name = "Please select event name";
      }
      if (userId === "") {
        validation.user_name = "Please select user name";
      }
      setState({
        ...state,
        ...validation
      });
      return;
    }
    setShowToaster(true);
    setToasterDetails((prev) => ({
      ...prev,
      title: "Creating...",
      bodyText: "Creating Event Booking",
      bg: "warning",
      statusIcon: (
        <div className="spinner-border text-warning me-2" role="status"></div>
      ),
    }));
    setGuestErrors({
      gender: false,
      age: false,
      ageInputValidation: false,
    });
    const payload = {
      booking_type: "event_entry_booking",
      user_id: userId,
      event_id: eventId,
      event_name: state.event_name,
      attendee_count: state.people.length,
      people: [...state.people.map((obj, index) => obj.name !== "" ? obj : { ...obj, name: `Guest ${index + 1}` })],
      offset_in_min: moment(state.booking_date).diff(todayDate, "minutes")
    };
    const headers = {
      headers: {
        developement: true,
        token: token,
      },
    };
    axios.post(`${process.env.REACT_APP_API_URL}/booking/v2/eventBooking`,
      payload, headers).then(function (response) {
        setToasterDetails((prev) => ({
          ...prev,
          title: "Success",
          bodyText: "Event Booking Details are added successfully",
          bg: "success",
          statusIcon: (
            <i className="bi bi-check text-success text-3xl me-2"></i>
          ),
        }));

        setTimeout(() => {
          navigate(`/bookings?tab=events`, { replace: true });
        }, 2000);
      }).catch(function (error) {
        // alert(error.message);
        setToasterDetails((prev) => ({
          ...prev,
          title: "Error",
          bodyText: "Something went wrong, Please try again",
          bg: "danger",
          statusIcon: (
            <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
          ),
        }));
        setTimeout(() => {
          setShowToaster(false);
        }, 2000);
      })
      .catch(function (error) {
        alert(error.message);
      });
  };

  // const onChangeHandler = (e) => {
  //   setFormErrors({
  //     ...formErrors,
  //     name: false,
  //   });
  //   setEventName(e.target.options[e.target.selectedIndex].text);
  //   setEventId(e.target.value);
  // };

  const handleGenderChange = (event) => {
    setGuestDetails((prev) => ({
      ...prev,
      gender: event.target.value,
    }));
    setGuestErrors({
      ...guestErrors,
      gender: false,
    });
  };

  /** Adding guest list to the table*/
  const addingGuestDetails = () => {
    if (
      // guestDetails.guest_name === "" &&
      guestDetails.age === "" &&
      guestDetails.gender === ""
    ) {
      setGuestErrors({
        // guestName: true,
        gender: true,
        age: {
          age: true,
        },
      });
      return;
    }
    if (
      // guestDetails.guest_name === "" ||
      guestDetails.age === "" ||
      guestDetails.gender === ""
    ) {
      let temp = guestErrors;
      // if (guestDetails.guest_name === "") {
      //   temp.guestName = true;
      // }
      if (guestDetails.gender === "") {
        temp.gender = true;
      }
      if (guestDetails.age === "") {
        temp.age.age = true;
      }
      setGuestErrors({ ...temp });
      return;
    }

    if (
      guestErrors.age === true ||
      guestErrors.gender === true ||
      // guestErrors.guestName === true ||
      guestErrors.age.age === true ||
      guestErrors.age.ageAboveHundredYears === true ||
      guestErrors.age.ageBelowTwenty === true
    ) {
      return;
    } else {
      let guest = {
        // name: guestDetails.guest_name,
        name: guestDetails.guest_name ? guestDetails.guest_name : "",
        gender: guestDetails.gender,
        age: guestDetails.age,
      };
      setState((prev) => ({
        ...prev,
        people: [...state.people, guest],
      }));
      setGuestDetails({
        guest_name: "",
        gender: "",
        age: "",
      });
    }
  };

  /** Removing guest from the list */
  const handleRemoveGuest = (index) => {
    const guestList = [...state.people];
    guestList.splice(index, 1);
    setState((prev) => ({
      ...prev,
      people: [...guestList],
    }));
  };
  // const OnChangeUserHandler = (e) => {
  //   setFormErrors({
  //     ...formErrors,
  //     username: false,
  //   });
  //   setUserId(e.target.value);
  // };

  // const onChangeAmount = (event) => {
  //   setState((prev) => ({
  //     ...prev,
  //     amount: event.target.value,
  //   }));
  //   setFormErrors({
  //     ...formErrors,
  //     amount: false,
  //   });
  // };

  /* Handle function for guest name in guest details */
  const handleGuestName = (e) => {
    // Setting guest name
    setGuestDetails({
      ...guestDetails,
      guest_name: e.target.value,
    });

    // Setting guest name validation
    setGuestErrors({
      ...guestErrors,
      // guestName: false,
    });
  };

  /* Handle function for age input in guest */
  const handleAge = (e) => {
    // Destructuring the data from event
    const { name, value } = e.target;
    //Setting the value, it will give error if we not set the value
    setGuestDetails((prevState) => ({
      ...prevState,
      age: value,
    }));
    // Checking for Empty field
    if (!value) {
      setGuestErrors({
        ...guestErrors,
        age: {
          age: true,
        },
      });
      // Checking for below 20
    } else if (value < 21) {
      setGuestErrors({
        ...guestErrors,
        age: {
          ageBelowTwenty: true,
        },
      });
      //Checking for above 99
    } else if (value > 99) {
      setGuestErrors({
        ...guestErrors,
        age: {
          ageAboveHundredYears: true,
        },
      });

      // if All Okay we will set all errors false
    } else {
      setGuestErrors({
        ...guestErrors,
        age: {
          age: false,
          ageAboveHundredYears: false,
          ageBelowTwenty: false,
        },
      });
    }
  };
  return (
    <Layout path="/bookings/create-event-booking">
      <ContentWrapper additionalClass="p-5">
        <form className="mt-6" onSubmit={handleFormSubmit}>
          <h5 className="text-2xl font-body font-bold mb-3">
            Event Booking Details
          </h5>
          <Row className="g-4">
            <Col xs={6} className="font-body">
              <label
                htmlFor="event_name"
                className="text-lg font-medium color-gray mb-3"
              >
                Event Name <span className="text-danger">*</span>
              </label>
              <div className="position-relative">
                <input
                  id="event_name"
                  name="event_name"
                  type="search"
                  className="po-edit-input mb-1"
                  placeholder="Enter Event Name"
                  value={state.event_name}
                  onChange={searchInputHandler}
                  onBlur={onInputBlurHandler}
                  onKeyDown={keyDownHandler}
                />
                {!state.event_name && !inputLoader.event && (
                  <ActionIcon additionalClass="bi bi-chevron-down po-input__dropdownIcon position-absolute" />
                )}
                {inputLoader.event && <div
                  className="spinner-border po-input__dropdownIcon position-absolute bg-white"
                  style={{ width: "1.4rem", height: "1.4rem" }}
                  role="status">
                </div>}
              </div>
              {showEventList && (
                <ul className="po-updateEvent__clubLists" ref={eventListRef}>
                  {events && events.length ? events.map((event, index) => (
                    <li
                      key={index}
                      tabIndex={index}
                      id={`event-${index}`}
                      className={`p-2 font-medium d-flex justify-content-between gap-4 ${active === index ? 'background-skyBlue text-dark' : ''}`}
                      onClick={(e) => handleSelectSearchedInput(event.name, event.id, "event_name")}
                    >
                      <span className='d-inline-block'>{event.name}</span>
                      <span className='text-xs text-end d-inline-block'>{event.club && event.club.address && event.club.address}</span>
                    </li>
                  )) : <li className="p-2 font-medium">No Record Found!</li>}
                </ul>)}
              {state.errors.event_name &&
                <p className="text-danger font-body text-sm">{state.errors.event_name}</p>
              }
            </Col>
            <Col xs={6} className="font-body">
              <label
                htmlFor="user_name"
                className="text-lg font-medium color-gray mb-3"
              >
                User Name <span className="text-danger">*</span>
              </label>
              <div className="position-relative">
                <input
                  id="user_name"
                  name="user_name"
                  type="search"
                  className="po-edit-input mb-1"
                  placeholder="Enter User Name"
                  value={state.user_name}
                  onChange={searchInputHandler}
                  onBlur={onUserInputBlurHandler}
                  onKeyDown={keyDownHandler}
                />

                {!state.user_name && !inputLoader.user && (
                  <ActionIcon additionalClass="bi bi-chevron-down po-input__dropdownIcon position-absolute" />
                )}
                {inputLoader.user && state.user_name && <div
                  className="spinner-border po-input__dropdownIcon position-absolute bg-white"
                  style={{ width: "1.4rem", height: "1.4rem" }}
                  role="status">
                </div>}
              </div>
              {showUserList && (
                <ul className="po-updateEvent__clubLists" ref={userListRef}>
                  {users && users.length ? users.map((user, index) => (
                    <li
                      key={index}
                      tabIndex={index}
                      id={`user-${index}`}
                      className={`p-2 font-medium d-flex justify-content-between gap-4 ${active === index ? 'background-skyBlue text-dark' : ''}`}
                      onClick={(e) => handleSelectSearchedInput(user.name, user.id, 'user_name')}
                    >
                      <span className='text-nowrap'>{user.name}</span>
                      <span className='text-xs text-end text-break'>{user.email}</span>
                    </li>
                  )) : <li className="p-2 font-medium">No Record Found!</li>}
                </ul>
              )}
              {state.errors.user_name &&
                <p className="text-danger font-body text-sm">{state.errors.user_name}</p>
              }
            </Col>
          </Row>

          {/* guest details goes here **************************************** */}
          <Row className="g-4 mt-2">
            <Col xs={6}>
              <h4 className="text-2xl  font-bold text-black">
                Add guest details
              </h4>
              <label className="font-body text-md mt-3">
                Name
                {/* <span className="text-danger">*</span> */}
              </label>
              <input
                name="guest_name"
                id="guest_name"
                className="po-edit-input text-capitalize"
                placeholder="please enter the name of guest"
                value={guestDetails.guest_name}
                onChange={handleGuestName}
              />
              <div>
                <label className="font-body text-md d-block mt-3">Gender <span className="text-danger">*</span></label>
                <label className="me-2 font-medium text-md d-inline-flex align-items-center">
                  <input
                    type="radio"
                    value="male"
                    className="me-1"
                    checked={guestDetails.gender === "male"}
                    onChange={handleGenderChange}
                  />
                  Male
                </label>
                <label className="me-2 font-medium text-md d-inline-flex align-items-center">
                  <input
                    type="radio"
                    className="me-1"
                    value="female"
                    checked={guestDetails.gender === "female"}
                    onChange={handleGenderChange}
                  />
                  Female
                </label>
                <label className="me-2 font-medium text-md d-inline-flex align-items-center">
                  <input
                    type="radio"
                    className="me-1"
                    value="other"
                    checked={guestDetails.gender === "other"}
                    onChange={handleGenderChange}
                  />
                  Other
                </label>
              </div>
              {
                guestErrors.gender && (
                  <p className="text-danger font-body text-sm">
                    Please select gender
                  </p>
                )
              }
              <label className="font-body text-md mt-3 ">
                Age <span className="text-danger">*</span>
              </label>
              <input
                className="po-edit-input"
                name="age"
                type="number"
                value={guestDetails.age}
                min={0}
                onChange={handleAge}
              />
              {
                guestErrors.age.age ? (
                  <p className="text-danger font-body text-sm">
                    Please enter age
                  </p>
                ) : guestErrors.age.ageAboveHundredYears ? (
                  <p className="text-danger font-body text-sm">
                    Age must be below 100 years
                  </p>
                ) : guestErrors.age.ageBelowTwenty ? (
                  <p className="text-danger font-body text-sm">
                    Age must be above 21 years
                  </p>
                ) : null
              }
              <div className="text-end mt-5 font-body">
                <Button
                  className="color-black1 text-md font-body font-semibold cursor-pointer po-border po-border-yellow1 py-3 px-3 radius-105 d-inline-block bg-transparent text-dark"
                  onClick={addingGuestDetails}
                >
                  Add Guest Details
                </Button>
              </div>
            </Col>
            <Col xs={6}>
              {state.people.length ? (
                <div>
                  <h4 className="text-2xl  font-bold text-black">
                    Guest Details List
                  </h4>
                  <Table responsive hover>
                    <thead>
                      <tr>
                        <th className="text-md">Sr. no.</th>
                        <th className="text-md">Name</th>
                        <th className="text-md">Gender</th>
                        <th className="text-md">Age</th>
                      </tr>
                    </thead>
                    <tbody>
                      {state.people.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            {/* <td>{item.name}</td> */}
                            <td>{item.name ? item.name : `Guest ${index + 1}`}</td>
                            <td>{item.gender}</td>
                            <td>
                              <span className="d-flex align-items-center gap-2">
                                <span>{item.age}</span>
                                <ActionIcon
                                  onClick={() => { handleRemoveGuest(index) }}
                                  additionalClass="bi bi-x-circle-fill text-xl color-red me-2 d-flex align-items-center"
                                />
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Row className="g-4 mt-2">
            {/* <Col xs={6}>
              <label htmlFor="amount" className="text-lg font-medium color-gray mb-2">
                Amount Paid <span className="text-danger">*</span>
              </label>
              <input
                id="amount"
                name="amount"
                type="number"
                value={state.amount}
                placeholder="500"
                className="po-edit-input mb-1"
                onChange={onChangeAmount}
              />
              {formErrors.amount && (
                <p className="text-danger">Please enter amount</p>
              )}
            </Col> */}

            {/* <Col xs={6}>
              <label htmlFor="status" className="text-lg font-medium color-gray mb-2">
                Status*
              </label>
              <input
                id="status"
                name="status"
                type="text"
                value={state.status}
                className="po-edit-input mb-1"
                onChange={onChangeHandler}
              />
            </Col> */}
            <Col
              xs={12}
              className="d-flex justify-content-end align-items-center mb-6"
            >
              {showToaster && (
                <img
                  src="/assets/icons/loading_icon.gif"
                  alt="processing"
                  width={100}
                />
              )}
              <OutlinedButton link="/bookings" name="Cancel" />
              <SuccessButton
                type="submit"
                name="Save"
                icon="bi-check-circle"
                disabled={showToaster ? true : false}
                additionalClass="ms-4"
              />
            </Col>
          </Row>
        </form>
      </ContentWrapper>
      <Toaster
        showToaster={showToaster}
        setShowToaster={setShowToaster}
        bg={toasterDetails.bg}
        title={toasterDetails.title}
        bodyText={toasterDetails.bodyText}
        statusIcon={toasterDetails.statusIcon}
      />
    </Layout>
  );
};

export default CreateEventBooking;
