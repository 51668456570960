import { useForm, Controller } from 'react-hook-form';
// import { Button } from "react-bootstrap";
import { collection, doc, getDocs, setDoc } from "firebase/firestore";
import { v4 as uuid } from "uuid";
import { useNavigate, useParams } from "react-router-dom";
import db from "../../../../firebase";
import Layout from "../../../../Layout/Layout";
import PageDescription from "../../../../Components/Global/PageDescription";
import ContentWrapper from "../../../../Components/Global/ContentWrapper";
import { useState } from "react";
import Toaster from "../../../../Components/Global/Toast";
import SuccessButton from "../../../../Components/Global/Buttons/SuccessButton";
import OutlinedButton from "../../../../Components/Global/Buttons/OutlinedButton";
import { momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AdminCreateCloseBooking = () => {
  const { club_id } = useParams();
  const [nonOperationalDays, setNonOperationalDays] = useState([]);
  const navigate = useNavigate();
  const [showToaster, setShowToaster] = useState(false);
  // const [show, setShow] = useState(false);
  const localizer = momentLocalizer(moment);
  // const [eventDetail, setEventDetail] = useState({
  //   title: "",
  //   start: null,
  //   end: null
  // });

  const [toasterDetails, setToasterDetails] = useState({
    title: "",
    bodyText: "",
    bg: "",
    statusIcon: "",
  });
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  useEffect(()=> {
    const parentRef = doc(collection(db, "clubs"), club_id );
    const subCollectionClosedBookings = collection(parentRef, "closed_bookings");
    getDocs(subCollectionClosedBookings)
    .then(response=> {
      console.log(response,"response")
      setNonOperationalDays([...response.docs.map(item=> item.data().date)])
    })
  }, [])


  const onSubmit = async (data) => {
    let id = uuid();
    console.log(data.date, 'data.date');
    const updateData = {
      id: id,
      date: data.date,
      reason: data.message.trimStart().replace(/\s+/g, ' ').trimEnd(),
      createdAt: new Date(),
      updatedAt: new Date()
    };
    setShowToaster(true);
    setToasterDetails((prev) => ({
      ...prev,
      title: "Creating...",
      bodyText: "Creating non-operational day",
      bg: "warning",
      statusIcon: (
        <div className="spinner-border text-warning me-2" role="status"></div>
      ),
    }));
    const clubResponse = await setDoc(
      doc(db, "clubs", club_id, "closed_bookings", id),
      updateData
    )
      .then(() => {
        setToasterDetails((prev) => ({
          ...prev,
          title: "Success",
          bodyText: "Created non-operational day successfully",
          bg: "success",
          statusIcon: (
            <i className="bi bi-check text-success text-3xl me-2"></i>
          ),
        }));
        setTimeout(() => {
          navigate(`/clubs/close-booking/${club_id}`, { replace: true });
        }, 2000);
      })
      .catch((err) => {
        setToasterDetails((prev) => ({
          ...prev,
          title: "Error",
          bodyText: "Something went wrong",
          bg: "danger",
          statusIcon: (
            <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
          ),
        }));
        setTimeout(() => {
          setShowToaster(false);
        }, 2000);
      });
  };
  const validateField = (value) => {
    if (value.trim() === "") {
      return "This field should not be empty.";
    }
    if (/^\s+$/.test(value)) {
      return "This field should not contain only whitespace characters.";
    }
    return true;
  };
  const breadCrumbData = {
    title: "Non Operational Days",
    caption: "/ Create"
  }
  return (
    <Layout link={`/clubs/close-booking/${club_id}`} breadCrumbData={breadCrumbData}>
      <div className="d-flex justify-content-between align-items-center gap-3 gap-lg-5 font-body">
        <PageDescription title="Add Non-Operational Days" caption="" />
      </div>

      <ContentWrapper additionalClass="p-6 font-body">
        <form onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="date" className="text-lg font-medium color-gray mb-3">
            Date<span style={{ color: "red", fontSize: "20px" }}>*</span>
          </label>
          {/* <input
            className="po-edit-input"
            type="date"
            id="date"
            {...register("date", { required: true })}
          /> */}
          <Controller
            control={control}
            name="date"
            rules={{ required: 'Date is required' }} // Add the required rule
            render={({ field }) => (
              <DatePicker
                {...field}
                selected={field.value}
                onChange={(date) => field.onChange(date)}
                dateFormat="dd/MM/yyy"
                placeholderText="dd/mm/yyyy"
                className='po-edit-input'
                minDate={new Date()}
                excludeDates={nonOperationalDays.map(item => item.toDate())}
              />
            )}
          />
          {errors.date && (
            <p className="text-sm text-danger">{errors.date.message}</p>
          )}

          <label
            htmlFor="message"
            className="text-lg font-medium color-gray mb-3 mt-8"
          >
            Reason<span style={{ color: "red", fontSize: "20px" }}>*</span>
          </label>
          <textarea
            className="po-edit-input"
            id="message"
            {...register("message", { validate: validateField })}
          />
          {errors.message && (
            <p className="text-sm text-danger">Please enter a message.</p>
          )}

          <div className="text-end mt-4">
            {showToaster && (
              <img
                src="/assets/icons/loading_icon.gif"
                alt="processing"
                width={100}
              />
            )}
            {/* <Button variant="success" type="submit">
              Submit
            </Button> */}
            <OutlinedButton
              link={`/clubs/close-booking/${club_id}`}
              name="Cancel"
              style={{pointerEvents: !showToaster ? '' : 'none'}}
            />
            <SuccessButton
              type="submit"
              name="Add Day"
              icon="bi-check-circle"
              additionalClass="ms-3"
              disabled={showToaster}
            />
          </div>
        </form>
      </ContentWrapper>


      <Toaster
        showToaster={showToaster}
        setShowToaster={setShowToaster}
        bg={toasterDetails.bg}
        title={toasterDetails.title}
        bodyText={toasterDetails.bodyText}
        statusIcon={toasterDetails.statusIcon}
      />

    </Layout>
  );
};
export default AdminCreateCloseBooking;
