import React from "react";
import Layout from "../../Layout/Layout";
import ContentWrapper from "../../Components/Global/ContentWrapper";
import { useForm, Controller, set } from "react-hook-form";
import { useState, useEffect } from "react";
import ActionIcon from "../../Components/Global/Buttons/ActionIcons";
import SuccessButton from "../../Components/Global/Buttons/SuccessButton";
import OutlinedButton from "../../Components/Global/Buttons/OutlinedButton";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {
  collection,
  getDocs,
  // addDoc,
  serverTimestamp,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import db from "../../firebase";
import { getImageSize } from "react-image-size";
import { Col, Row } from "react-bootstrap";
import Toaster from "../../Components/Global/Toast";
import PrimaryButton from "../../Components/Global/Buttons/PrimaryButton";
import moment from "moment";
// import { Button } from "react-bootstrap";
const EditAdvertisement = () => {
  // const location = useLocation();
  const [showToaster, setShowToaster] = useState(false);
  const [toasterDetails, setToasterDetails] = useState({
    title: "",
    bodyText: "",
    bg: "",
    statusIcon: "",
  });
  const navigate = useNavigate();
  const [previewImage, setPreviewImage] = useState(null);
  const [ufile, setUfile] = useState("");
  const [advertisementsCategories, setAdvertisementsCategories] = useState([]);
  const [selectCategory, setSelectCategory] = useState(null);
  const [selectCategoryId, setSelectCategoryId] = useState(null);
  const [dimage, setdimage] = useState();
  const [isGlobal, setIsGlobal] = useState(true)
  const [clubData, setClubData] = useState([]);
  const [eventsData, setEventsData] = useState([]);
  const [categoryValues, setCategoryValues] = useState([]);
  const [categoryValue, setCategoryValue] = useState("");
  const [selectedCategoryType, setSelectedCategoryType] = useState("");
  const [errorDisplayImage, setErrorDisplayImage] = useState("");
  const [redirectingType, setRedirectingType] = useState("");
  const [cities, setCities] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState("");
  const [selectedCities, setSelectedCIties] = useState([]);
  const [selectedCategoryName, setSelectedCategoryName] = useState("");
  const [advertisementResponse, setAdvertisementResponse] = useState({});

  // Form states
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  // const id = location.state.id
  const history = useNavigate();
  const [redirectingCategory, setRedirectingCategory] = useState("");
  // const id  =ads_id
  // const history = useNavigate();
  const { ads_id } = useParams();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    setError,
    formState: { errors },
  } = useForm();
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    // console.log(file, "file");
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        fetchImageSize(reader.result, file);
      };
      reader.readAsDataURL(file);

      setUfile(file);
    }
  };
  async function fetchImageSize(url, file) {
    // console.log(file, 'testing');
    try {
      const dimensions = await getImageSize(url);
      // console.log(dimensions.width, 'dimensions');
      // 640w*240h
      if (dimensions.width === 320 && dimensions.height === 180) {
        setErrorDisplayImage("");
        setPreviewImage(url);
        setUfile(file);
        setErrorDisplayImage("");
      } else {
        // console.log("Please upload the image of mentioned dimensions");
        setErrorDisplayImage("Please upload the image of mentioned dimensions");

      }
    } catch (error) {
      console.error(error);
    }
  }
  const fetchCategories = async () => {
    await getDocs(collection(db, "advertisement_categories")).then(
      (QuerySnapshot) => {
        const newData = QuerySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setAdvertisementsCategories(newData);
        // console.log(advertisementsCategories, newData, "bb");
      }
    );
  };
  const getAllCities = async () => {
    await getDocs(collection(db, "cities")).then((response) => setCities(response.docs)).catch(err => console.log(err));
  }
  useEffect(() => {
    fetchCategories();
    getAllCities();
  }, []);
  const getAllData = async (type) => {
    try {
      const clubsResponse = await getDocs(collection(db, "clubs"));
      console.log(clubsResponse.docs, "clubsResponse.docs");
      clubsResponse && setClubData(clubsResponse.docs);
      const eventResponse = await getDocs(collection(db, "events"));
      eventResponse && setEventsData(eventResponse.docs);
      if (type === "Club") {
        setCategoryValues(clubsResponse.docs);
      } else if (type === "Event") {
        setCategoryValues(eventResponse.docs);
      }
    } catch (err) {
      console.log(err);
    }
  };

  //Uploading image
  const insertingImage = async (imageData) => {
    let form = new FormData();
    form.append("images", imageData);
    let responseImage = await axios
      .post(
        `${process.env.REACT_APP_MULTIPLEIMAGES_POSTING_URL}/uploads/uploadImages`,
        form,
        { headers: { "Content-Type": "multipart/form-data" } }
      )
      .then((resImage) => {
        return resImage && resImage.data.url[0];
      })
      .catch((error) => console.log(error, "err"));
    return responseImage;
  };


  const handleSearchBox = (e) => {
    const { value } = e.target;
    setSearchItem(value); // Update searchTerm state immediately

    if (value === "") {
      setSuggestions([]);
    }
    else if (value.trim().length === 0) {
      setError("city", {
        type: "custom",
        message: "Only whitespace not allowed"
      })
    }
    else {
      const results = getSearchResults(value);
      setSuggestions(results);
      setError("city", {
        type: "custom",
        message: null
      })
      setValue("city", value)
    }
  };

  const getSearchResults = (input) => {
    if (typeof input !== "string") {
      return [];
    }


    return cities.filter(
      (city) =>
        city &&
        city.data().name &&
        city.data().name.toLowerCase().includes(input.toLowerCase())
    );
  };


  const handleUserOnClick = (e) => {
    let cityName = e.target.getAttribute("value");
    let duplicateValidation = selectedCities.find(city => city === cityName)
    if (!duplicateValidation) {
      setSuggestions([]);
      setSelectedCIties([
        ...selectedCities,
        cityName
      ])
      setSearchItem("");
      setError("city", {
        custom: true,
        message: null
      })
    }
    else {
      setError("city", {
        custom: true,
        message: "This city is already selected"
      })
      setSearchItem("");
      setSuggestions([]);
    }

  };

  // User suggesstion on click
  useEffect(() => {
    // console.log(location.state.id);
    getDoc(doc(db, "advertisements", ads_id)).then(async (res) => {
      const formattedStartDate = moment.unix(res && res.data() && res.data().start_date && res.data().start_date.seconds).format("YYYY-MM-DD HH:mm");
      const formattedEndDate = moment.unix(res && res.data() && res.data().end_date && res.data().end_date.seconds).format("YYYY-MM-DD HH:mm");
      let category_id = res.data().redirecting_url.split("/");

      await getAllData(res.data().advertisement_trigger_category);
      reset((register) => ({
        ...register,
        name: res && res.data() && res.data().name,
        externalurl:
          res && res.data() && res.data().redirect_type === "external"
            ? res && res.data() && res.data().redirecting_url
            : "",
        ad_category_name: res && res.data() && res.data().ad_category_name,
        redirect_type: res && res.data() && res.data().redirect_type,
        ad_category_id: res && res.data() && res.data().ad_category_id,
        status: res && res.data() && res.data().status,
        category: res && res.data() && res.data().advertisement_trigger_category,
        category_value: category_id && category_id[1],
        city: res && res.data() && res.data().cities && res.data().cities[0],
        start_date: formattedStartDate,
        end_date: formattedEndDate
      }));
      setAdvertisementResponse(res.data());
      setSelectCategory(res.data().ad_category_name);
      setSelectCategoryId(res.data().ad_category_id);
      setdimage(res.data().display_image);
      setPreviewImage(res.data().display_image);
      setRedirectingType(res.data().redirect_type);
      setIsGlobal(res.data().global)
      setSelectedCIties(res.data().cities);
      setSelectedCategoryName(res.data().category_selected_name)
    });
  }, [ads_id, setValue, ads_id]);

  const onSubmit = async (data) => {
    setShowToaster(true);
    setToasterDetails((prev) => ({
      ...prev,
      title: "Updating...",
      bodyText: "Updating advertisement",
      bg: "warning",
      statusIcon: (
        <div className="spinner-border text-warning me-2" role="status"></div>
      ),
    }));
    console.log(data, "123456");
    let redirectingUrl = "";
    if (data.redirect_type === "external") redirectingUrl = data.externalurl;
    else
      redirectingUrl =
        data.category === "Club"
          ? `clubs/${data.category_value}`
          : data.category === "Event"
            ? `events/${data.category_value}`
            : data.url;
    if (!errorDisplayImage) {
      setIsLoading(true);
      const resultantImage = typeof ufile === "object" ? await insertingImage(ufile) : dimage;

      const payload = {
        name: data.name, //yes
        redirecting_url: redirectingUrl, //yes
        display_image: resultantImage,
        ad_category_name: selectCategory && selectCategory.name
          ? selectCategory.name
          : selectCategory, //yes
        ad_category_id: selectCategory && selectCategory.id
          ? selectCategory.id
          : selectCategoryId, //yes
        createdAt: serverTimestamp(),
        redirect_type: data.redirect_type, //yes
        status: data.status, //yes
        updatedAt: serverTimestamp(), //yes
        advertisement_trigger_category:
          data.redirect_type === "internal" ? data.category : null,
        global: isGlobal,
        cities: selectedCities,
        category_selected_name: data.redirect_type === "internal" ? selectedCategoryName : null
      };
      setRedirectingType(data.redirect_type);
      console.log(payload, "payload", ads_id);
      updateDoc(
        doc(db, "advertisements", ads_id), payload)
        .then(() => {
          setToasterDetails((prev) => ({
            ...prev,
            title: "Success",
            bodyText: "Updated advertisement successfully",
            bg: "success",
            statusIcon: (
              <i className="bi bi-check text-success text-3xl me-2"></i>
            ),
          }));
          setTimeout(() => {
            navigate("/advertisement-manager");
          }, 2000);
        })
        .catch((err) => {
          setToasterDetails((prev) => ({
            ...prev,
            title: "Error",
            bodyText: "Something went wrong",
            bg: "danger",
            statusIcon: (
              <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
            ),
          }));
          console.log(err);
        });
      reset();
      console.log(ufile, selectCategory);
    } else {
      console.log("Error...");
      setIsLoading(true);
      setIsError(true);
      setTimeout(() => {
        setIsLoading(false);
        setIsError(false);
      }, 2000);
    }
  };

  const categoryHandler = (e) => {
    const selectCategories = {
      name: e.target.options[e.target.selectedIndex].value,
      id: e.target.options[e.target.selectedIndex].id,
    };
    setSelectCategory(selectCategories);
  };
  return (
    <Layout path="/advertisement-manager/edit-advertisement">
      {/* <Link to="/advertisement-manager">
            <div  className="d-flex align-items-center mb-5">
               <div>
                <img
                  src="/assets/icons/sidebar/dark/adds-icon-dark.svg"
                  alt="dashboard-icon"
                  width="15"
                  height="15"
                  className="d-block"
                />
              </div>

            <div className="ms-2 text-sm text-lg-md font-body font-semibold text-black">
            Advertisement Manager
              </div>
               <div className="ms-2 text-xs text-lg-sm font-body font-regular text-black">
               / Edit
              </div>
            </div>
            </Link> */}
      <ContentWrapper additionalClass="p-5 font-body">
        <form onSubmit={handleSubmit(onSubmit)} className="row g-3">
          <div className="col-12 col-sm-12">
            <label
              className="text-md font-body d-block font-bold mb-1"
              htmlFor="image"
            >
              Ad image <span className="text-danger">*</span>
            </label>

            <div
              style={{
                height: "240px",
                width: "640px",
                backgroundImage: `url(${previewImage ? previewImage : dimage})`,
              }}
              className=" d-flex align-items-center justify-content-center background-position-center-top background-repeat-norepeat background-size-cover rounded overflow-hidden position-relative"
            >
              {previewImage ? (
                <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                  <input
                    type="file"
                    id="display_image"
                    {...register("display_image", {})}
                    accept="image/*"
                    onChange={handleImageChange}
                    className="cursor-pointer opacity-0 position-absolute start-0 end-0 top-0 bottom-0 w-100 h-100"
                  />

                  <div className="text-center">
                    <div
                      style={{ height: "50px", width: "50px" }}
                      className="rounded-circle background-lightgreen1 d-flex align-items-center justify-content-center shadow-lg"
                    >
                      <ActionIcon additionalClass="bi bi-pencil-square text-2xl color-lightgreen d-block" />
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="d-flex align-items-center mt-2">
              <i className="bi bi-info-square-fill text-xl me-1 text-danger"></i>
              <div className="text-xs font-body">
                Please upload the image with the dimensions 320w X 180h px
              </div>
            </div>
            {errors.display_image && !errorDisplayImage && !previewImage && (
              <p className="text-sm text-danger mt-1 font-body">
                {errors.display_image.message}
              </p>
            )}
            {errorDisplayImage && (
              <p className="text-sm text-danger mt-1 font-body">
                {errorDisplayImage}
              </p>
            )}
          </div>

          <div className="col-12 col-md-6">
            <label className="text-md font-body d-block font-bold mb-1">
              Ad name <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              {...register("name", { required: "Please enter a name" })}
              className="po-edit-input"
            />
            {errors.name && (
              <p className="text-sm text-danger mt-1 font-body">
                {errors.name.message}
              </p>
            )}
          </div>

          <div className="col-12 col-sm-6">
            <label
              className="text-md font-body d-block font-bold mb-1"
              htmlFor="url"
            >
              Destination Type <span className="text-danger">*</span>
            </label>
            <select
              {...register("redirect_type", {
                required: "Redirect type is required",
              })}
              className="po-edit-input font-body"
              onChange={(e) => {
                setRedirectingType(e.target.value);
                if (e.target.value === "external") {
                  setValue("category", "");
                  setSelectedCategoryType("");
                  setValue("category_value", "");
                }
              }}
            >
              <option value="">Select Destination type</option>
              <option value="internal">Internal</option>
              <option value="external">External</option>
            </select>

            {errors.redirect_type && (
              <p className="text-sm text-danger mt-1 font-body">
                {errors.redirect_type.message}
              </p>
            )}
          </div>
          {redirectingType == "external" && (
            <div className="col-12 col-md-6">
              <label className="text-md font-body d-block font-bold mb-1">
                Redirecting URL <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                {...register("externalurl", { required: "Please enter a URL" })}
                className="po-edit-input"
              />
              {errors.url && (
                <p className="text-sm text-danger mt-1 font-body">
                  {errors.url.message}
                </p>
              )}
            </div>
          )}
          <div className="col-12 col-md-6">
            <label
              className="text-md font-body d-block font-bold mb-1"
              htmlFor="url"
            >
              Status <span className="text-danger">*</span>
            </label>
            <select
              {...register("status", {
                required: "Redirect type is required",
              })}
              className="po-edit-input font-body"
            >
              <option value="">Select Status</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </select>

            {errors.status && (
              <p className="text-sm text-danger mt-1 font-body">
                {errors.status.message}
              </p>
            )}
          </div>
          {redirectingType === "internal" && (
            <>
              <Col md={6}>
                <label className="text-md mb-1" htmlFor="internalRedirect">
                  <p className="bold m-0">Select Category <span className="text-danger">*</span></p>
                </label>

                <select
                  id="categoryid"
                  className="po-edit-input"
                  {...register("category", {
                    required: "Category is required",
                  })}
                  onChange={(e) => {
                    console.log(e.target.value, "value");
                    setSelectedCategoryType(e.target.value);
                    switch (e.target.value) {
                      case "Club":
                        setCategoryValues(clubData);
                        setSelectedCategoryName(clubData.name)
                        break;
                      case "Event":
                        setCategoryValues(eventsData);
                        setSelectedCategoryName(eventsData.name)
                        break;
                      case "":
                        setCategoryValues([])
                        document.getElementById("categoryid").value = ''
                        document.getElementById("categoryvalueid").value = ""
                        break;
                      default:
                        break;
                    }
                  }}
                >
                  {selectedCategoryType == "" && (
                    <option value="" selected="selected" disabled>
                      Select Category
                    </option>
                  )}
                  <option value="Club">Club</option>
                  <option value="Event">Event</option>
                  {/* <option value="">Clear</option> */}
                </select>
                {errors.internalRedirect && (
                  <p className="text-sm text-danger mt-1 font-body">
                    {errors.category.message}
                  </p>
                )}
              </Col>
              <Col md={6}>
                {selectedCategoryType == "Club" ? (
                  <label
                    className="text-md mb-1"
                    htmlFor="internalRedirect"
                    style={{ fontWeight: "bold" }}
                  >
                    {`Select ${selectedCategoryType} `}
                  </label>
                ) : selectedCategoryType == "Event" ? (
                  <label
                    className="text-md mb-1"
                    htmlFor="internalRedirect"
                    style={{ fontWeight: "bold" }}
                  >
                    {`Select ${selectedCategoryType} `}
                  </label>
                ) : selectedCategoryType == "" ? (
                  <label className="text-md mb-1" htmlFor="internalRedirect">
                    <p className="m-0 bold">Select Value <span className="text-danger">*</span></p>
                  </label>
                ) : null}

                <select
                  className="po-edit-input"
                  {...register("category_value", {
                    required: "Value is required",
                  })}
                  onChange={(e) => {
                    setCategoryValue(e.target.value);
                    setSelectedCategoryName(e.target.options[e.target.selectedIndex].text)

                  }}
                >
                  <option value="" selected="selected" disabled>
                    Select Value
                  </option>
                  {categoryValues &&
                    categoryValues.length > 0 &&
                    categoryValues.map((club) => {
                      return (
                        <option value={club.data().id} title={club.data().address}>
                          {club && club.data().name}
                        </option>
                      );
                    })}
                </select>
                {errors.internalRedirect && (
                  <p className="text-sm text-danger mt-1 font-body">
                    {errors.category_value.message}
                  </p>
                )}
              </Col>
            </>
          )}
          <div className="col-12 col-sm-6">
            <label
              className="text-md font-body d-block font-bold mb-1"
              htmlFor="url"
            >
              Start Date <span className="text-danger">*</span>
            </label>
            <input
              type="datetime-local"
              min={advertisementResponse && advertisementResponse.start_date && moment(advertisementResponse.start_date.seconds * 1000).format("YYYY-MM-DDTHH:mm")}
              className="po-edit-input"
              {...register("start_date", {
                required: "Start date is required",
              })}
            />
            {errors.start_date && (
              <p className="text-sm text-danger mt-1 font-body">
                {errors.start_date.message}
              </p>
            )}
          </div>
          <div className="col-12 col-sm-6">
            <label
              className="text-md font-body d-block font-bold mb-1"
              htmlFor="url"
            >
              End Date <span className="text-danger">*</span>
            </label>
            <input
              type="datetime-local"
              // min={`${new Date().toISOString().slice(0, 16)}`}
              className="po-edit-input"
              {...register("end_date", {
                required: "End date is required",
              })}
            />
            {errors.end_date && (
              <p className="text-sm text-danger mt-1 font-body">
                {errors.end_date.message}
              </p>
            )}
          </div>
          {/* Global section  */}
          <Col xs={6}>
            <label
              className="text-md font-body d-block font-bold mb-1"
              htmlFor="url"
            >
              Global <span className="text-danger">*</span>
            </label>
            <div style={{
              width: "75px",
              height: "90px"
            }}>
              <input type="checkbox"
                className=""
                style={{ height: "20px", width: "20px" }}
                value={isGlobal}
                checked={isGlobal}
                onChange={() => {
                  setIsGlobal(!isGlobal)
                  setSelectedCIties([]);
                }}
              />
            </div>
          </Col>
          {/* If it is not Global, it will show city */}
          {isGlobal === false &&
            <>
              <div className="col-12 col-md-6">
                <label
                  className="text-md font-body d-block font-bold mb-1"
                  htmlFor="url"
                >
                  City <span className="text-danger">*</span>
                </label>
                {/* <ContentWrapper additionalClass="p-5"> */}
                <form>
                  <Row>
                    <Col xs={12}>
                      <div>
                        <Controller
                          control={control}
                          name="city"
                          rules={{ required: "This is required field" }}
                          render={({ fields }) => {
                            return (
                              <input
                                {...fields}
                                placeholder="Please enter some text..."
                                value={searchItem}
                                className="po-edit-input"
                                type="search"
                                onChange={handleSearchBox}

                              />
                            )
                          }}

                        />
                        {/* <input
                              placeholder="Please enter some text..."
                              value={searchItem}
                              className="po-edit-input"
                              type="search"
                              onChange={handleSearchBox}

                        /> */}

                        <ul className="mt-2">
                          {suggestions &&
                            suggestions.map((city, index) => (
                              <li
                                onKeyDown={(e) => console.log()}
                                key={index}
                                value={city.data().name}
                                className={`on-hover ${index === selectedSuggestionIndex ? 'selected' : ''}`}
                                onClick={handleUserOnClick}
                                tabIndex={0}
                              >
                                {city.data().name}
                              </li>
                            ))}
                        </ul>
                      </div>

                      <div className="d-flex">
                        {selectedCities && selectedCities.length > 0 &&
                          selectedCities.map((ele, index) => {
                            return (
                              <div key={index} className="d-flex m-1 bg-light w-50 p-4 justify-content-between flex-wrap">
                                <p className="mt-1 bold">{ele}</p>
                                <ActionIcon
                                  onClick={() => {
                                    let tempCities = selectedCities;
                                    tempCities.splice(index, 1);
                                    setSelectedCIties([...tempCities])
                                  }}
                                  additionalClass="bi bi-x-circle-fill text-2xl color-red ms-1"
                                />
                              </div>
                            )
                          })

                        }
                      </div>
                      {errors.city && (
                        <p className="mb-0 mt-0 text-sm text-danger">
                          {errors.city.message}
                        </p>
                      )}

                    </Col>

                    <div className="text-end">
                      <PrimaryButton
                        disabled={showToaster}
                        name="Apply"
                      // onClick={handleApplyButton}
                      />
                    </div>
                  </Row>
                </form>
                {/* </ContentWrapper> */}


              </div>

            </>
          }
          <div className="col-12  text-end">
            <OutlinedButton
              link="/advertisement-manager"
              name="Cancel"
              additionalClass="px-10"
            />
            <SuccessButton
              type="submit"
              name="Update"
              icon="bi-check-circle"
              additionalClass={`ms-4 ${showToaster ? "opacity-06 cursor-default" : null}  `} disabled={showToaster ? true : false}
            />
          </div>
        </form>
      </ContentWrapper>

      <Toaster
        showToaster={showToaster}
        setShowToaster={setShowToaster}
        bg={toasterDetails.bg}
        title={toasterDetails.title}
        bodyText={toasterDetails.bodyText}
        statusIcon={toasterDetails.statusIcon}
      />
    </Layout>
  );
};
export default EditAdvertisement;