import { Button, Table } from "react-bootstrap";
import ContentWrapper from "../Global/ContentWrapper";
import moment from "moment";
// import { useNavigate } from "react-router-dom";
// import { Link } from "react-router-dom";
import SecondaryButton from "../Global/Buttons/SecondaryButton";
import Skeleton from "react-loading-skeleton";
import { useState, useEffect } from "react";
import { collection, getDocs, query, where, orderBy } from "firebase/firestore";
import db from "../../firebase";


const ManagerEventBookings = ({ events, clubId, usersContactDetails }) => {
  // console.log(events, "===================================");
  // const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [eventBookingsData, setEventBookingsData] = useState([]);

  const fetchEventBookings = async () => {
    try {
      const eventBookings = await getDocs(
        query(collection(db, 'event_entry_bookings'), where("club_id", "==", clubId), orderBy("createdAt", "desc"))
      )
      setEventBookingsData([]);
      eventBookings.forEach((doc) => {
        setEventBookingsData(prev => [...prev, doc.data()])
      })
      setIsLoading(false);
    }
    catch (err) {
      console.log(err, "err");
      setIsLoading(false);
    }
  }
  useEffect(() => {
    fetchEventBookings();
  }, [])
  return (
    <>
      <ContentWrapper>
        {/* <h2 className="text-lg font-semibold ps-4 py-5 color-lightblue">Showing event bookings</h2> */}
        <div className="table-responsive po-table tableFixHead">
          <Table hover>
            <thead>
              <tr className="background-gray10">
              <th><p className="text-nowrap text-xs text-xl-sm text-uppercase ps-3 color-black2 mb-0 py-2">S. No</p></th>
              <th><p className="text-nowrap text-xs text-xl-sm text-uppercase  color-black2 mb-0 py-2">Booking ID</p></th>
              <th><p className="text-nowrap text-xs text-xl-sm text-uppercase  color-black2 mb-0 py-2">name</p></th>
              <th><p className="text-nowrap text-xs text-xl-sm text-uppercase  color-black2 mb-0 py-2">Phone no</p></th>
              <th><p className="text-nowrap text-xs text-xl-sm text-uppercase  color-black2 mb-0 py-2">email ID</p></th>
              <th><p className="text-nowrap text-xs text-xl-sm text-uppercase  color-black2 mb-0 py-2">EVENT NAME</p></th>
              <th><p className="text-nowrap text-xs text-xl-sm text-uppercase  color-black2 mb-0 py-2">EVENT IMAGE</p></th>
              <th><p className="text-nowrap text-xs text-xl-sm text-uppercase  color-black2 mb-0 py-2">Guest COUNT</p></th>
              <th><p className="text-nowrap text-xs text-xl-sm text-uppercase  color-black2 mb-0 py-2">AMOUNT</p></th>
              <th><p className="text-nowrap text-xs text-xl-sm text-uppercase  color-black2 mb-0 py-2">BOOKED FOR</p></th>
              <th><p className="text-nowrap text-xs text-xl-sm text-uppercase  color-black2 mb-0 py-2">STATUS</p></th>
              <th><p className="text-sm text-nowrap"></p></th>
              </tr>
            </thead>
            <tbody>
              {!isLoading ?
                (
                  <>
                    {(eventBookingsData && eventBookingsData.length) ? eventBookingsData.map((eventBookingData, index) => {
                      console.log(eventBookingData, "eventBookingData JIIJIIJI");
                      const date = moment(new Date(eventBookingData.booking_date.seconds * 1000).toISOString()).format("DD MMM YYYY")
                      return (
                        <tr key={eventBookingData.id}>
                          <td className="align-middle "><p className="text-sm mb-0 text-nowrap color-lightblue ps-3">{index + 1}</p></td>
                          <td className="align-middle"><p className="text-sm mb-0 text-nowrap color-lightblue ">{eventBookingData && eventBookingData.booking_id}</p></td>
                          <td className="align-middle"><p className="text-sm mb-0 text-nowrap color-lightblue">{eventBookingData && eventBookingData.user && eventBookingData.user.name && eventBookingData.user.name}</p></td>
                          <td className="align-middle"> <p className="text-sm mb-0 text-nowrap color-lightblue ">{usersContactDetails[eventBookingData && eventBookingData.user_id] && usersContactDetails[eventBookingData && eventBookingData.user_id][0] ? usersContactDetails[eventBookingData && eventBookingData.user_id][0][0].contact_no : ""}</p></td>
                          <td className="align-middle"> <p className="text-sm mb-0 text-nowrap color-lightblue ">{usersContactDetails[eventBookingData && eventBookingData.user_id] && usersContactDetails[eventBookingData && eventBookingData.user_id][1] ? usersContactDetails[eventBookingData && eventBookingData.user_id][1] : ""}</p></td>
                          <td className="align-middle"><p className="text-sm mb-0 text-nowrap color-lightblue  " style={{ minWidth: "250px", lineHeight: "20px" }}>{eventBookingData && eventBookingData.event_name}</p></td>
                          <td className="align-middle"><img src={eventBookingData && eventBookingData.event_display_image && eventBookingData.event_display_image} className="rounded" alt="" width="100px" height="100px" /></td>
                          <td className="align-middle"><p className="text-sm mb-0 text-nowrap color-lightblue">{ eventBookingData && eventBookingData.attendee_count + 1}</p></td>
                          <td className="align-middle"><p className="text-sm mb-0 text-nowrap color-lightblue">{eventBookingData && "$ " + eventBookingData.amount}</p></td>
                          <td className="align-middle"><p className="text-sm mb-0 text-nowrap color-lightblue">{date && date}</p></td>
                          <td className="align-middle">
                            <div className="d-flex align-items-center justify-content-start">
                              <div
                                style={{
                                  height: "10px",
                                  width: "10px",
                                  backgroundColor: `${eventBookingData.status === "Approved"
                                    ? "#13DEB9"
                                    : eventBookingData.status === "Waiting for approval"
                                      ? "#FFAE1F"
                                      : eventBookingData.status === "Rejected"
                                        ? "#FA896B"
                                        : null
                                    }`,
                                }}
                                className="rounded-circle"
                              ></div>
                              <div className="ms-2 color-lightblue text-sm text-nowrap">
                                {eventBookingData && eventBookingData.status}
                              </div>
                            </div>

                          </td>
                          <td className="align-middle">
                            <SecondaryButton link={`/manager/event-details/${eventBookingData && eventBookingData.id && eventBookingData.id}`} name="View" />

                          </td>
                        </tr>)
                    }) : ""
                    }
                  </>
                ) : (
                  <tr>
                    <td colSpan={12}> <Skeleton inline={true} count={5} height={80} style={{ marginBottom: "1rem" }} baseColor='#dedede' highlightColor='#cccccc' duration={2} />
                    </td>
                    
                  </tr>
                )
              }
            </tbody>
          </Table>
        </div>
      </ContentWrapper>
    </>
  );
};
export default ManagerEventBookings;
