import React, { useState, useEffect } from 'react';
import ContentWrapper from "../../ContentWrapper";
import ReactApexChart from "react-apexcharts";
import DateRange from "../../DateRange";
const BookingRevenue = ({ setSelectedDateRange, bookingRevenueStartDate, bookingRevenueEndDate, revenueFromBookings }) => {
  const [state, setState] = useState({
    entry: [],
    table: [],
    event: [],
    months: [],
  })

  useEffect(() => {
    let entry = [];
    let table = [];
    let event = [];
    let months = []

    revenueFromBookings.length > 0 && revenueFromBookings.forEach(data => {
      entry.push(data.club_entry_booking);
      table.push(data.table_booking);
      event.push(data.event_entry_booking);
      months.push(data.month_name);

    })
    setState((prevProps) => ({
      ...prevProps,
      entry: entry,
      table: table,
      event: event,
      months: months
    }))
  }, [revenueFromBookings]);

  const chartData = {
    options: {
      colors: [
        "#dd5182",
        "#ff6e54",
        "#444e86"],
      chart: {
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          columnWidth: "70%",
        },
      },
      dataLabels: {
        total: {
          enabled: true,
          style: {
            fontSize: '13px',
            fontWeight: 900
          }
        }
      },
      xaxis: {
        categories: state.months
      },
    },
    series: [
      {
        name: "Club table bookings",
        data: state.table
      },
      {
        name: "Club entry bookings",
        data: state.entry
      },
      {
        name: "Event bookings",
        data: state.event
      },
    ],
  };
  const getDateRange = (start, end) => {
    setSelectedDateRange(start, end, "booking_revenue");
  }
  return (
    <ContentWrapper additionalClass="p-5">
      <div className="d-flex justify-content-between font-body">
        <div>
          <h2 className="text-md text-xl-lg font-bold color-blue font-body mb-0">
            Bookings Revenue
          </h2>
          <p className="text-xs text-xl-sm"> Revenue from bookings : 200</p>
        </div>
        <div>
          <DateRange
            getDateRange={getDateRange}
            startDate={bookingRevenueStartDate}
            endDate={bookingRevenueEndDate}
          />
        </div>
      </div>
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        height={350}
      />
    </ContentWrapper>
  )
}
export default BookingRevenue;