import ActionIcon from "../../Components/Global/Buttons/ActionIcons";
import SuccessButton from "../../Components/Global/Buttons/SuccessButton";
import ContentWrapper from "../../Components/Global/ContentWrapper";
import Layout from "../../Layout/Layout";
import React, { useEffect, useRef, useState } from 'react';
import PageDescription from "../../Components/Global/PageDescription";
import axios from "axios";
import { collection, doc, getDocs, updateDoc } from "firebase/firestore";
import db from "../../firebase";
import Toaster from "../../Components/Global/Toast";
import { Modal, ModalBody } from "react-bootstrap";
import SecondaryButton from "../../Components/Global/Buttons/SecondaryButton";

const CreateVideo = () => {
    const videoRef = useRef(null);
    const fileInputRef = useRef(null);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [show, setShow] = useState(false);
    const [videoError, setVideoError] = useState("")
    const [toasterDetails, setToasterDetails] = useState({
        title: "",
        bodyText: "",
        bg: "",
        statusIcon: "",
    });
    const [showToaster, setShowToaster] = useState(false);
    const [fileUpload, setFileUpload] = useState(false);
    const [video, setVideo] = useState({
        videoData: "",
        previewVideoUrl: "",
        error: "",
        id: "",
        showButton: false
    });

    const [videoDeleteModal, setVideoDeleteModal] = useState(null)

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const videoUrl = URL.createObjectURL(file);
        setSelectedVideo(videoUrl);
    };

    const handleDeselect = () => {
        setVideo({ ...video, previewVideoUrl: "", videoData: "" })
    };

    const fetchVideo = async () => {
        await getDocs(collection(db, "videos")).then(response => response && response.docs && response.docs[0] && setVideo({ ...video, previewVideoUrl: response.docs[0].data().video_url, id: response.docs[0].data().id, showButton: false }))
    }

    useEffect(() => {
        fetchVideo()
    }, [])


    // Uploading video
    const uploadingVideo = async (videoData) => {
        let form = new FormData();
        form.append("images", videoData);
        let responseVideo = await axios
            .post(
                `${process.env.REACT_APP_MULTIPLEIMAGES_POSTING_URL}/uploads/uploadImages`,
                form,
                { headers: { "Content-Type": "multipart/form-data" } }
            )
            .then((resVideo) => {
                return resVideo && resVideo.data.url[0];
            })
            .catch((error) => console.log(error, "err"));
        return responseVideo;
    };

    // Upload video
    const createVideo = async (e) => {
        e.preventDefault();
        console.log(video.videoData, "Hello");
        if (video.videoData === "") {
            setVideo({ ...video, error: "Please upload video..." })
            return;
        }
        setShowToaster(true);
        setToasterDetails((prev) => ({
            ...prev,
            title: "Uploading...",
            bodyText: "Uploading Video...",
            bg: "warning",
            statusIcon: (
                <div className="spinner-border text-warning me-2" role="status"></div>
            ),
        }));
        let uploadingVideoUrl = await uploadingVideo(video.videoData);
        await updateDoc(doc(db, "videos", video.id), {
            video_url: uploadingVideoUrl
        }).then((responseVideo) => {
            setToasterDetails((prev) => ({
                ...prev,
                title: "Success",
                bodyText: "Video Uploaded successfully",
                bg: "success",
                statusIcon: (
                    <i className="bi bi-check text-success text-3xl me-2"></i>
                ),
            }));
            fetchVideo();
            // setFileUpload(!fileUpload)
            setTimeout(() => {
                setShowToaster(false);
            }, 2000)
            // window.location.reload();
        }).catch(error => console.log(error, "err"))

    }

    const handleUploadVideo = async () => {
        if (!selectedVideo) {
            return;
        }
        const file = fileInputRef.current.files[0];
        const formData = new FormData();
        formData.append('video', file);
        let responseVideo = await axios
            .post(`${process.env.REACT_APP_MULTIPLEIMAGES_POSTING_URL}/uploads/uploadImages`, formData)
            .then((response) => {
                console.log('Video uploaded successfully:', response.data);
                // Perform any additional actions after successful upload
            })
            .catch((error) => {
                console.error('Error uploading video:', error);
                // Handle the error appropriately
            });
        return responseVideo;
    };
    return (
        <Layout path="/create-video">
            <div className="d-flex justify-content-between align-items-center gap-3 gap-lg-5 font-body">
                <PageDescription title="Upload Dashboard Video" caption="" />
            </div>
            <ContentWrapper additionalClass="p-5">

                <form onSubmit={createVideo}>
                    {
                        video.previewVideoUrl ? null : <div className="d-flex justify-content-center">
                            <div style={{ height: "250px", width: "450px" }} className="cursor-pointer position-relative border rounded border-dashed mt-5 d-flex justify-content-center align-items-center">
                                <div className="text-center">
                                    <img src="./assets/icons/icon-mediaplayer.svg" alt="icon" />
                                    <h5 className="text-lg mt-2">Select your video</h5>
                                </div>
                                <input
                                    type="file"
                                    accept="video/*"
                                    // onChange={handleFileChange}
                                    ref={fileInputRef}
                                    className="position-absolute start-0 end-0 top-0 bottom-0 opacity-0 cursor-pointer"
                                    onChange={(e) => {
                                        let file = e.target.files[0];
                                        if (file && file.name.endsWith('.mp4')) {
                                            // Check if the file size is less than 5 MB (5 * 1024 * 1024 bytes)
                                            if (file.size <= 5 * 1024 * 1024) {
                                                const videoUrl = URL.createObjectURL(file);
                                                if (videoUrl) {
                                                    setVideo({
                                                        ...video,
                                                        previewVideoUrl: videoUrl,
                                                        error: "",
                                                        videoData: file,
                                                        showButton: true
                                                    });
                                                }
                                            } else {
                                                setVideoError("File size exceeds 10 MB limit");
                                                setTimeout(() => {
                                                    setVideoError("");
                                                }, 2000);
                                            }
                                        } else {
                                            setVideoError("Invalid format");
                                            setTimeout(() => {
                                                setVideoError("");
                                            }, 2000);
                                        }
                                    }}

                                />
                            </div>
                        </div>
                    }

                    {video.previewVideoUrl ? (
                        <>
                            <div className="d-flex justify-content-center">
                                <div className="position-relative d-inline-block">
                                    <ActionIcon onClick={(e) => {
                                        setVideoDeleteModal(true)
                                    }} additionalClass="bi bi-x-circle-fill text-3xl color-red me-2 position-absolute top-0 end-0 mt-2 zIndex__10" />
                                    <video
                                        ref={videoRef}
                                        src={video.previewVideoUrl}
                                        style={{ height: "450px", width: "250px" }}
                                        controls
                                        autoPlay
                                        className="rounded border"
                                    >
                                        Sorry, your browser doesn't support embedded videos.
                                    </video>
                                </div>
                            </div>
                            {video.showButton &&
                                <div className="text-center">  <SuccessButton type="submit"

                                    name="Upload"
                                    icon="bi-check-circle" additionalClass="mt-4 text-center" /></div>
                            }
                        </>
                    ) : (
                        <p className="text-danger mt-2 text-md text-center">No video selected.</p>
                    )}

                </form>
                {videoError && <p className="text-danger mt-2 text-md text-center">{videoError}</p>}
                <div className="d-flex justify-content-center mt-5">
                    <i className="bi bi-info-circle-fill text-md color-yellow1 me-2">
                        <span className=" text-sm ml-1 text-dark text-center">
                            Only MP4 format is allowed
                        </span>
                    </i>
                </div>
                {/* Video Modal */}
                <Modal size="xs" show={videoDeleteModal} onHide={() => setVideoDeleteModal(false)}  >

                    <div className="background-appColor p-5 rounded font-body">
                        <h5 className="text-2xl  color-ternary  mb-3">
                            Are you sure?
                        </h5>
                        <ModalBody>
                            <p className="text-light font-weight-bold">Do you want to delete video?</p>
                        </ModalBody>
                        <div className="d-flex justify-content-end">
                            <SecondaryButton
                                onClick={() => setVideoDeleteModal(false)}
                                name="No"
                                additionalClass="text-light mt-3 me-4 px-5"
                            />
                            <SecondaryButton
                                onClick={() => {
                                    handleDeselect();
                                    // setSelectedImage(null)
                                    setVideoDeleteModal(false)
                                }}
                                name="Yes"
                                additionalClass="text-light mt-3 me-4 px-5"
                            />

                        </div>
                    </div>
                </Modal >
                <Toaster
                    showToaster={showToaster}
                    setShowToaster={setShowToaster}
                    bg={toasterDetails.bg}
                    title={toasterDetails.title}
                    bodyText={toasterDetails.bodyText}
                    statusIcon={toasterDetails.statusIcon}
                />
            </ContentWrapper>
        </Layout>
    )
}
export default CreateVideo;