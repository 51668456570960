import React, { useState, useEffect } from "react";
import ContentWrapper from "../../Components/Global/ContentWrapper";
import PageDescription from "../../Components/Global/PageDescription";
import Layout from "../../Layout/Layout";
import { collection, getDocs, doc, deleteDoc, orderBy, query } from "firebase/firestore";
import db from "../../firebase";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Table } from "react-bootstrap";
import PrimaryButton from '../../Components/Global/Buttons/PrimaryButton';
import Dropdown from "react-bootstrap/Dropdown";
import SecondaryButton from "../../Components/Global/Buttons/SecondaryButton";
import ReactPaginate from 'react-paginate';
import Skeleton from "react-loading-skeleton";
const Country = () => {
  const [countries, setCountries] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const navigate = useNavigate()
  const [show, setShow] = useState({ show: false, data: null });
  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setShow({ show: true, data: countries[id] });
  };
  const hadnleDelete = async (data) => {
    try {
      await deleteDoc(doc(db, "countries", data.id));
      fetchCountries()
      handleClose();
    } catch (err) {
      console.log(err);
    }
  };
  // Table Height : Start
  const [firstSectionHeight, setFirstSectionHeight] = useState(0);
  const secondSectionHeight = `calc(75vh - ${firstSectionHeight}px)`;
  // Calculate the height of the first section on mount and window resize
  useEffect(() => {
    const calculateHeight = () => {
      const firstSection = document.getElementById('first-section');
      if (firstSection) {
        setFirstSectionHeight(firstSection.clientHeight);
      }
    };

    calculateHeight();
    window.addEventListener('resize', calculateHeight);

    return () => {
      window.removeEventListener('resize', calculateHeight);
    };
  }, []);
  // Table Height : End
  // fetching entries
  const fetchCountries = async () => {
    const q = query(collection(db, 'countries'), orderBy('createdAt', 'desc'));
    const querySnapshot = await getDocs(q);
    const newData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setCountries(newData);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchCountries();
  }, []);

  // Pagination
  const CountryEntries = ({ currentItems, itemOffset }) => {
    return (
      <div className="tableFixHead pb-5 font-body rounded bg-white" style={{ height: secondSectionHeight }}>
        <Table hover>
          <thead>
            <tr className="background-gray10">
              <th className="text-nowrap"><p className="text-nowrap text-xs text-xl-sm text-uppercase ps-3 color-black2 mb-0 py-2">s. no</p></th>
              <th className="text-nowrap"> <p className="text-nowrap text-xs text-xl-sm text-uppercase ps-3 color-black2 mb-0 py-2">Country Name</p></th>
              <th className="text-nowrap"> <p className="text-nowrap text-xs text-xl-sm text-uppercase ps-3 color-black2 mb-0 py-2">Country code</p></th>
              <th className="text-nowrap"> </th>
            </tr>
          </thead>
          <tbody>
            {!isLoading ?
              (
                <>
                  {(currentItems && currentItems.length) ? currentItems.map((country, index) => {
                    return (
                      <tr key={index}>
                        <td className="align-middle"><p className='ps-3 text-sm mb-0 text-nowrap color-black'>{itemOffset + index + 1}.</p></td>
                        <td className="align-middle"><p className='ps-3 text-sm mb-0 text-nowrap color-black'>{country.name}</p></td>
                        <td className="align-middle"><p className='ps-3 text-sm mb-0 text-nowrap color-black'>{country.country_code}</p></td>

                        <td className="align-middle">
                          <Dropdown>
                            <Dropdown.Toggle variant="link" id="dropdown-basic" className="text-black">
                              <i className="bi bi-three-dots-vertical text-4xl d-bloxk"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="border-0 shadow px-2">
                              <Dropdown.Item className="border-bottom font-bold text-sm py-2 text-black" onClick={() => {
                                navigate(`/country/edit-country/${country.id}`)
                              }}>
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item className="font-bold text-sm py-2 text-black" onClick={() => { handleShow(index) }}>
                                Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>

                        </td>
                      </tr>
                    );
                  }) : ""
                  }
                </>
              ) : (
                <tr>
                  <td colSpan={3}>
                    <Skeleton inline={true} count={5} height={80} style={{ marginBottom: "1rem" }} baseColor='#dedede' highlightColor='#cccccc' duration={2} />
                  </td>
                </tr>
              )
            }
          </tbody>
        </Table>
      </div>
    )
  }

  function PaginatedItems({ itemsPerPage }) {
    const [pageCount, setPageCount] = useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    const [currentItems, setCurrentItems] = useState(null)

    useEffect(() => {
      // Fetch items from another resources.
      const endOffset = itemOffset + itemsPerPage;
      console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      setCurrentItems(countries.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(countries.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
      const newOffset = event.selected * itemsPerPage % countries.length;
      console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
      setItemOffset(newOffset);
    };
    return (
      <>
        <CountryEntries currentItems={currentItems} itemOffset={itemOffset} />
        <ReactPaginate
          nextLabel={<i className="bi bi-chevron-right"></i>}
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel={<i className="bi bi-chevron-left"></i>}
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination pe-4 pt-4 d-flex justify-content-end"
          // activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </>
    );
  }
  return (
    <Layout path="/country" additionalclass="d-flex flex-column">
      <div className='po-table-content mb-8' id="first-section" style={{ height: 'auto', minHeight: '100px' }}>
        <div className="d-flex justify-content-between align-items-center gap-3 gap-lg-5 ">
          <PageDescription
            title="All countries"
            caption=""
          />
          <PrimaryButton name="Add Country" link="/country/create-country" icon="bi-plus-circle" />
        </div>
      </div>

      <ContentWrapper additionalClass="pb-5 font-body">
        <PaginatedItems itemsPerPage={20} />
      </ContentWrapper>
      <Modal show={show.show} onHide={handleClose}>
        <div className="background-appColor p-8 rounded font-body">
          <h5 className="text-2xl  color-ternary font-semibold ">
            Warning: Location Linked to Clubs
          </h5>
          <p className="background-appColor text-light border-bottom-0 py-1 ">
            This country is linked to one or more clubs. Deleting it will also remove the association with these clubs. Proceed with deletion?
          </p>
          <div className="d-flex justify-content-end mt-12">
            <SecondaryButton onClick={handleClose} name="Cancel" additionalClass="text-white px-6 me-3" />
            <PrimaryButton onClick={() => hadnleDelete(show.data)} name="Delete" />
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

export default Country;
