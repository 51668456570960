import { Row, Col, Button } from "react-bootstrap";
import ContentWrapper from "../../Components/Global/ContentWrapper";
import Layout from "../../Layout/Layout";
import { useForm } from "react-hook-form";
import { useState } from "react";
import ActionIcon from "../../Components/Global/Buttons/ActionIcons";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import db from "../../firebase";
// import UserDetails from "../users/user-details";
import axios from "axios";
import SuccessButton from "../../Components/Global/Buttons/SuccessButton";
import OutlinedButton from "../../Components/Global/Buttons/OutlinedButton";
import Toaster from "../../Components/Global/Toast";
const EditAccount = () => {
  const [showToaster, setShowToaster] = useState(false);
  const [toasterDetails, setToasterDetails] = useState({
    title: "",
    bodyText: "",
    bg: "",
    statusIcon: "",
  });

  const [previewImage, setPreviewImage] = useState("");
  const [image, setImage] = useState({
    url: "",
    changedImage: false,
  });
  const { user_id } = useParams(); //getting user id from params
  const [userDetails, setUserDetails] = useState({});
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  // Getting image url from api
  const uploadImage = async (image) => {
    console.log(image, "123456");
    let form = new FormData();
    form.append("images", image);
    let responseImage = await axios
      .post(
        `${process.env.REACT_APP_MULTIPLEIMAGES_POSTING_URL}/uploads/uploadImages`,
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      )
      .then((resImage) => {
        return resImage && resImage.data.url[0];
      })
      .catch((error) => console.log(error, "err"));
    return responseImage;
  };

  const onSubmit = async (data) => {
    setShowToaster(true);
    setToasterDetails((prev) => ({
      ...prev,
      title: "Updating...",
      bodyText: "Updating profile",
      bg: "warning",
      statusIcon: (
        <div className="spinner-border text-warning me-2" role="status"></div>
      ),
    }));
    try {
      //we have to change contact details of user we are not able to directly modify state so that we are storing in variable and modifying it.
      let contacts = userDetails.contacts;
      contacts[0].contact_no = data.phoneNumber;
      //Uploading image if image changed
      let imageUrl = image.changedImage
        ? await uploadImage(image.url)
        : userDetails.profile_image;
      // Payload
      let payload = {
        profile_image: imageUrl,
        name: data.name,
        email: data.email,
        contacts: contacts,
      };
      await updateDoc(doc(db, "users", user_id), payload);
      // alert("Updated");
      // navigate("/profile");
      setToasterDetails((prev) => ({
        ...prev,
        title: "Success",
        bodyText: "Profile updated successfully",
        bg: "success",
        statusIcon: <i className="bi bi-check text-success text-3xl me-2"></i>,
      }));
      setTimeout(() => {
        navigate("/profile");
      }, 2000)
    } catch (err) {
      setToasterDetails((prev) => ({
        ...prev,
        title: "Error",
        bodyText: "Something went wrong",
        bg: "danger",
        statusIcon: (
          <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
        ),
      }));
      console.error(err);
    }
  };

  // Handling image upload
  const handleImage = (data) => {
    let imageState = image;
    const file = data.target.files[0];
    setPreviewImage(file);
    imageState.url = file;
    if (file) {
      // file reader is used to convert image object to url, preview image we defined as a background image so we need url
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
    setValue("image", file)
    setImage({
      ...image,
      changedImage: true,
    });
    //changing image error to null
    setError("image", null);

  };

  //function to get user data
  const getUserData = async () => {
    await getDoc(doc(db, "users", user_id))
      .then((res) => {
        setUserDetails(res.data());
        // setting values for inputs
        setValue("name", res.data().name);
        setValue("email", res.data().email);
        setValue("phoneNumber", res.data().contacts[0].contact_no);
        res.data().profile_image && setPreviewImage(res.data().profile_image);
        setValue("image", res.data().profile_image)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Getting user data from firebase with user_id
  useEffect(() => {
    getUserData();
  }, [user_id]);

  //Layout
  return (
    <Layout path="/profile/edit-profile">
      <ContentWrapper additionalClass="p-5">
        <h2 className="text-xl color-heading font-semibold mb-5">
          Edit profile
        </h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row className="g-4">
            <Col xl={12} className="font-body">
              <label className="text-sm color-gray mb-2">
                Profile Image<span className="text-danger">*</span>
              </label>

              <div
                style={{
                  backgroundImage: `url(${previewImage ? previewImage : null})`,
                  border: `${previewImage ? "0px" : "1px"} dashed #D2D6DA`,
                  height: "150px",
                  width: "150px",
                }}
                className=" d-flex align-items-center justify-content-center background-position-center-top background-repeat-norepeat background-size-cover rounded-circle  position-relative"
                {...register("image", {
                  required: "Image is required",
                })}
              >
                {previewImage ? (
                  <div className="position-absolute end-0 top-0">
                    <ActionIcon
                      onClick={() => {
                        setPreviewImage("");
                        setImage({ ...image, url: "" });

                      }}
                      additionalClass="bi bi-x-circle-fill text-3xl color-red me-2"
                    />
                  </div>
                ) : null}
                {previewImage ? null : (
                  <div>
                    <input
                      type="file"
                      id="image"
                      accept="image/*"
                      // value={state.display_image}
                      {...register("display_image", { required: "Image is required" })}
                      onChange={handleImage}
                      // value={image.url}
                      className="opacity-0 position-absolute start-0 end-0 top-0 bottom-0"
                    />
                    <div className="text-center">
                      <i className="bi bi-plus-circle-fill text-5xl color-lightgreen d-block"></i>
                      <p className="text-xs font-semibold font-body text-center color-gray2 ">
                        Upload image here
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <p className="text-danger font-body text-sm">
                {errors.display_image?.message}
              </p>
            </Col>
            <Col lg={6}>
              <article>
                <h4 className="text-sm color-gray mb-1">Name</h4>
                <input
                  className="po-edit-input"
                  {...register("name", { required: "Name is required" })}
                />

                {errors.name && (
                  <span className="text-danger font-body text-sm">
                    {errors.name.message}
                  </span>
                )}
              </article>
            </Col>
            <Col lg={6}>
              <article>
                <h4 className="text-sm color-gray mb-1">Email</h4>
                <input
                  className="po-edit-input"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^\S+@\S+$/i,
                      message: "Invalid email address",
                    },
                  })}
                />

                {errors.email && (
                  <span className="text-danger font-body text-sm">
                    {errors.email.message}
                  </span>
                )}
              </article>
            </Col>
            <Col lg={6}>
              <article>
                <h4 className="text-sm color-gray mb-1">Phone Number</h4>
                <input
                  className="po-edit-input"
                  {...register("phoneNumber", {
                    required: "Phone number is required",
                  })}
                />

                {errors.phoneNumber && (
                  <span className="text-danger font-body text-sm">
                    {errors.phoneNumber.message}
                  </span>
                )}
              </article>
            </Col>
          </Row>
          <div className="text-end mt-4">
            <OutlinedButton
              link="/profile"
              name="Cancel"
              additionalClass="px-10 me-3"
            />
            <SuccessButton
              type="submit"
              name="Update"
              icon="bi-check-circle"
              additionalClass=""
            />

          </div>

        </form>
      </ContentWrapper>
      <Toaster
        showToaster={showToaster}
        setShowToaster={setShowToaster}
        bg={toasterDetails.bg}
        title={toasterDetails.title}
        bodyText={toasterDetails.bodyText}
        statusIcon={toasterDetails.statusIcon}

      />
    </Layout>
  );
};
export default EditAccount;
