import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
// import { Button } from "react-bootstrap";
import { doc, setDoc, getDoc } from "firebase/firestore";
import ContentWrapper from "../../../Components/Global/ContentWrapper";
import Layout from "../../../Layout/LayoutManager/Layout";
import PageDescription from '../../../Components/Global/PageDescription';
import db from "../../../firebase";
import moment from 'moment';
// import PrimaryButton from "../../../Components/Global/Buttons/PrimaryButton";
import OutlinedButton from "../../../Components/Global/Buttons/OutlinedButton";
import SuccessButton from '../../../Components/Global/Buttons/SuccessButton';
import Toaster from '../../../Components/Global/Toast';

const ManagerUpdateCloseBooking = () => {
  const navigate = useNavigate();
  const clubId = localStorage.getItem('club_id');
  const { close_booking_id } = useParams();
  const [closeBookingDetails, setCloseBookingDetails] = useState({});
  const [showToaster, setShowToaster] = useState(false);
  const [toasterDetails, setToasterDetails] = useState({
    title: "",
    bodyText: "",
    bg: "",
    statusIcon: "",
  });
  const validateField = (value) => { if (value.trim() === '') { return "This field should not be empty."; } if (/^\s+$/.test(value)) { return "This field should not contain only whitespace characters."; } return true; };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {

    setShowToaster(true);
    setToasterDetails((prev) => ({
      ...prev,
      title: "Updating...",
      bodyText: "Updating non operational days details",
      bg: "warning",
      statusIcon: (
        <div className="spinner-border text-warning me-2" role="status"></div>
      ),
    }));
    const updateData = {
      id: close_booking_id,
      date: data.date,
      reason: data.message.trimStart().replace(/\s+/g, ' ').trimEnd(),
      updatedAt: new Date()
    };
    const clubResponse = await setDoc(doc(db, "clubs", clubId, "closed_bookings", close_booking_id), updateData)
      .then(() => {
        setToasterDetails((prev) => ({
          ...prev,
          title: "Success",
          bodyText: "Updated non operational days successfully",
          bg: "success",
          statusIcon: (
            <i className="bi bi-check text-success text-3xl me-2"></i>
          ),
        }));
        setTimeout(()=> {
          navigate(`/manager/close-booking`, { replace: true })
        }, 2000)
      })
      .catch(err=> {
        console.log(err, 'err');
        setToasterDetails((prev) => ({
          ...prev,
          title: "Error",
          bodyText: "Something went wrong, Please try again",
          bg: "danger",
          statusIcon: (
            <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
          ),
        }));
        setTimeout(() => {
          setShowToaster(false);
        }, 2000);
      })
  };
  const getClosedBookingData = async () => {
    const subColRef = (await getDoc(doc(db, "clubs", clubId, "closed_bookings", close_booking_id))).data();
    setCloseBookingDetails(subColRef);
  };
  useEffect(() => {
    getClosedBookingData();
  }, []);
  return (
    <Layout>
      <div className="d-flex justify-content-between align-items-center gap-3 gap-lg-5 font-body">
        <PageDescription title="Update Non-Operational Days" caption="" />
      </div>
      <ContentWrapper additionalClass="p-5 font-body">
        {closeBookingDetails.hasOwnProperty('reason') && closeBookingDetails.hasOwnProperty('date') && <form onSubmit={handleSubmit(onSubmit)} className="col-12">
          <label htmlFor="date" className="text-md">
            Date<span className="text-danger">*</span>
          </label>
          <input
            className="po-edit-input"
            type="date"
            id="date"
            min={moment().format('YYYY-MM-DD')}
            defaultValue={closeBookingDetails.date}
            {...register("date", { required: true })}
          />
          {errors.date && (
            <p className="text-sm text-danger">Please select a date.</p>
          )}

          <label htmlFor="message" className="text-md mt-3">
            Reason<span className="text-danger">*</span>
          </label>
          <textarea
            className="po-edit-input"
            id="message"
            defaultValue={closeBookingDetails.reason}
            {...register("message", { validate: validateField })}
          />
          {errors.message && (
            <p className="text-sm text-danger">Please enter a message.</p>
          )}

          <div className="text-end mt-4">
            {/* <Button variant="success" type="submit">
              Submit
            </Button> */}
            {showToaster && (
                <img
                  src="/assets/icons/loading_icon.gif"
                  alt="processing"
                  width={100}
                />
              )}
            <OutlinedButton link="/manager/close-booking" name="Cancel" additionalClass="me-3" />
            <SuccessButton type="Submit" name="Update Non-Operational Days" icon="bi-check-circle" />
          </div>
        </form>}
      </ContentWrapper>
      <Toaster
        showToaster={showToaster}
        setShowToaster={setShowToaster}
        bg={toasterDetails.bg}
        title={toasterDetails.title}
        bodyText={toasterDetails.bodyText}
        statusIcon={toasterDetails.statusIcon}
      />
    </Layout>
  );
};
export default ManagerUpdateCloseBooking;
