import React from 'react'
import Layout from '../../Layout/Layout'
import UpdateUser from '../../Components/User/UpdateUser'
import { useLocation, useParams } from 'react-router-dom'
// import { Link } from 'react-router-dom'

const CreateUser = () => {
  let location = useLocation();
  let type = location && location.state && location.state.type;
  let clubName = location && location.state && location.state.clubName;
  const searchParams = new URLSearchParams(location.search);
  const club_id = searchParams.get('club_id');
  const createNewUser = async (newUserInfo) => {
    let userCreation;
    let responseMessage = await fetch(`${process.env.REACT_APP_API_URL}/user/v2/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newUserInfo),
    })
      .then(response => response.json())
      .then(result => {
        result.success === true ? userCreation = true : userCreation = result.message;
        console.log(result, 'resultresultresult');
      })
      .catch(error => {
        console.log(error, 'error');
        userCreation = false
      })
    return userCreation;
  }
  return (
    <Layout path="/users/create-user">
      <UpdateUser createNewUser={createNewUser} type={type} club_id={club_id} clubName={clubName} />
    </Layout>
  )
}

export default CreateUser
