import React, { useState, useEffect } from 'react';
import { collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import db from "../../../firebase";
import ActionIcon from "../../../Components/Global/Buttons/ActionIcons";
import EditButton from '../../../Components/Global/Buttons/EditButton';
import Layout from "../../../Layout/LayoutManager/Layout";
import ContentWrapper from "../../../Components/Global/ContentWrapper";
import { Row, Col } from 'react-bootstrap';

const ClubDetailsManagement = () => {
  const navigate = useNavigate();

  const [state, setState] = useState({
    clubName: "",
    clubManagersDetails: [],
    // clubManagerName: "",
    // phoneNumber: "",
    // email: "",
    dressCode: "",
    website: "",
    clubLogo: {},
    clubPhoto: [],
    scheduledTimings: [],
    country: "",
    city: "",
    state: "",
    fullAddress: "",
    tableDetails: [],
  })
  const clubId = localStorage.getItem('club_id');

  const getClubData = async () => {
    console.log(clubId, "cludid");
    let clubQuery = doc(db, "clubs", clubId);
    const clubResponse = await getDoc(clubQuery);
    console.log(clubResponse.data(), "clubrepose");
    const clubDetails = clubResponse.data();
    console.log(clubDetails && clubDetails.manager_contacts, "ind details check");
    setState({
      clubName: clubDetails && clubDetails.name && clubDetails.name,
      clubManagersDetails: clubDetails && clubDetails.manager_contacts && clubDetails.manager_contacts,
      dressCode: clubDetails && clubDetails.dress && clubDetails.dress,
      website: clubDetails && clubDetails.website_url && clubDetails.website_url,
      clubLogo: clubDetails && clubDetails.logo && clubDetails.logo,
      clubPhoto: clubDetails && clubDetails.photos && clubDetails.photos,
      scheduledTimings: clubDetails && clubDetails.schedule && clubDetails.schedule,
      country: clubDetails && clubDetails.country_name && clubDetails.country_name,
      city: clubDetails && clubDetails.city_name && clubDetails.city_name,
      state: clubDetails && clubDetails.state_name && clubDetails.state_name,
      fullAddress: clubDetails && clubDetails.address && clubDetails.address,
      tableDetails: clubDetails && clubDetails.sitting_tables && clubResponse.sitting_tables,
    });
  }
  console.log(state.tableDetails, "TABLE DETAILS");
  useEffect(() => {
    getClubData();
  }, []);
  // da168b83-705e-4778-8347-a8cfeb516581
  const onClickHandler = () => {
    if (clubId != null) {
      navigate(`/manager/edit-club-details/${clubId}`);
    }
  }
  return (
    <Layout>
      <ContentWrapper>
        <Row className="py-7 px-8">
          <Col xs={12}>
            <h2 className="color-heading font-body font-semibold text-lg text-lg-2xl pb-6 mb-0">Club Details</h2>
            {/* <ActionIcon additionalClass="bi-pencil-square text-2xl me-3"
              onClick={(e) => onClickHandler("update")}
            /> */}
            <div className="d-flex justify-content-end">
              <EditButton buttonName="Edit" additionalClassName="text-2xl d-flex align-items-center"
                onClick={(e) => onClickHandler("update")}
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="pt-4 font-body">
              <p className="color-lightestblue font-normal text-xs mb-0">Club Name</p>
              <p className="color-lightblue font-semibold text-md text-lg-lg mb-0">{state.clubName}</p>
            </div>
            <div className="pt-4 font-body">
              <p className="color-lightestblue font-normal text-xs mb-0">Phone</p>
              <p className="color-lightblue font-semibold text-md text-lg-lg mb-0">{state.clubManagersDetails.length > 0 ? state.clubManagersDetails[0].phone ? state.clubManagersDetails[0].phone : "--" : "--"}</p>
            </div>
            <div className="pt-4 font-body">
              <p className="color-lightestblue font-normal text-xs mb-0">Dress Codes</p>
              <p className="color-lightblue font-semibold text-md text-lg-lg mb-0">{state.dressCode}</p>
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="pt-4 font-body">
              <p className="color-lightestblue font-normal text-xs mb-0">Club Manager Name</p>
              <p className="color-lightblue font-semibold text-md text-lg-lg mb-0">{state.clubManagersDetails.length > 0 ? state.clubManagersDetails[0].name ? state.clubManagersDetails[0].name : "--" : "--"}</p>
            </div>
            <div className="pt-4 font-body">
              <p className="color-lightestblue font-normal text-xs mb-0">Email</p>
              <p className="color-lightblue font-semibold text-md text-lg-lg mb-0">{state.clubManagersDetails.length > 0 ? state.clubManagersDetails[0].email ? state.clubManagersDetails[0].email : "--" : "--"}</p>
            </div>
            <div className="pt-4 font-body">
              <p className="color-lightestblue font-normal text-xs mb-0">Website Link</p>
              <p className="color-lightblue font-semibold text-md text-lg-lg mb-0 text-break">{state.website}</p>
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="pt-4 font-body">
              <p className="color-lightestblue font-normal text-xs mb-0">Club Logo</p>
              <div className="overflow-auto">
                <img src={state.clubLogo} alt="Club Logo" />
              </div>
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="pt-4 font-body">
              <p className="color-lightestblue font-normal text-xs mb-0">Club Photo</p>
              <div className="po-club-container overflow-x-scroll">
                <img src={state.clubPhoto.length > 0 && state.clubPhoto[0].url} alt="Club Photo" />
              </div>
            </div>
          </Col>
          <Col xs={12}>
            <h2 className="color-heading font-body font-semibold text-lg text-lg-2xl mt-10">Club Timings</h2>
          </Col>
          <Col xs={12}>
            <div className="font-body">
              <h2 className="color-heading font-medium text-lg mt-4">Monday</h2>
              <div className="d-flex">
                <p className="color-heading mb-0 mr-5">Start time : <span className="color-lightblue font-semibold text-md text-lg-lg mb-0">7:00am</span></p>
                <p className="color-heading mb-0">End time : <span className="color-lightblue font-semibold text-md text-lg-lg mb-0">10:00pm</span></p>
              </div>
            </div>
            <div className="font-body">
              <h2 className="color-heading font-medium text-lg mt-4">Tuesday</h2>
              <div className="d-flex">
                <p className="color-heading mb-0 mr-5">Start time : <span className="color-lightblue font-semibold text-md text-lg-lg mb-0">7:00am</span></p>
                <p className="color-heading mb-0">End time : <span className="color-lightblue font-semibold text-md text-lg-lg mb-0">10:00pm</span></p>
              </div>
            </div>
            <div className="font-body">
              <h2 className="color-heading font-medium text-lg mt-4">Wednesday</h2>
              <div className="d-flex">
                <p className="color-heading mb-0 mr-5">Start time : <span className="color-lightblue font-semibold text-md text-lg-lg mb-0">7:00am</span></p>
                <p className="color-heading mb-0">End time : <span className="color-lightblue font-semibold text-md text-lg-lg mb-0">10:00pm</span></p>
              </div>
            </div>
            <div className="font-body">
              <h2 className="color-heading font-medium text-lg mt-4">Thursday</h2>
              <div className="d-flex">
                <p className="color-heading mb-0 mr-5">Start time : <span className="color-lightblue font-semibold text-md text-lg-lg mb-0">7:00am</span></p>
                <p className="color-heading mb-0">End time : <span className="color-lightblue font-semibold text-md text-lg-lg mb-0">10:00pm</span></p>
              </div>
            </div>
            <div className="font-body">
              <h2 className="color-heading font-medium text-lg mt-4">Friday</h2>
              <div className="d-flex">
                <p className="color-heading mb-0 mr-5">Start time : <span className="color-lightblue font-semibold text-md text-lg-lg mb-0">7:00am</span></p>
                <p className="color-heading mb-0">End time : <span className="color-lightblue font-semibold text-md text-lg-lg mb-0">10:00pm</span></p>
              </div>
            </div>
            <div className="font-body">
              <h2 className="color-heading font-medium text-lg mt-4">Saturday</h2>
              <div className="d-flex">
                <p className="color-heading mb-0 mr-5">Start time : <span className="color-lightblue font-semibold text-md text-lg-lg mb-0">7:00am</span></p>
                <p className="color-heading mb-0">End time : <span className="color-lightblue font-semibold text-md text-lg-lg mb-0">10:00pm</span></p>
              </div>
            </div>
            <div className="font-body">
              <h2 className="color-heading font-medium text-lg mt-4">Sunday</h2>
              <div className="d-flex">
                <p className="color-heading mb-0 mr-5">Start time : <span className="color-lightblue font-semibold text-md text-lg-lg mb-0">7:00am</span></p>
                <p className="color-heading mb-0">End time : <span className="color-lightblue font-semibold text-md text-lg-lg mb-0">10:00pm</span></p>
              </div>
            </div>
          </Col>
          <Col xs={12}>
            <h2 className="color-heading font-body font-semibold text-lg text-lg-2xl mt-10">Club Location</h2>
          </Col>
          <Col xs={12} lg={6}>
            <div className="pt-4 font-body">
              <p className="color-lightestblue font-normal text-xs mb-0">Country</p>
              <p className="color-lightblue font-semibold text-md text-lg-lg mb-0">{state.country}</p>
            </div>
            <div className="pt-4 font-body">
              <p className="color-lightestblue font-normal text-xs mb-0">State</p>
              <p className="color-lightblue font-semibold text-md text-lg-lg mb-0">{state.state}</p>
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="pt-4 font-body">
              <p className="color-lightestblue font-normal text-xs mb-0">City</p>
              <p className="color-lightblue font-semibold text-md text-lg-lg mb-0">{state.city}</p>
            </div>
            <div className="pt-4 font-body">
              <p className="color-lightestblue font-normal text-xs mb-0">Enter Full Address</p>
              <p className="color-lightblue font-semibold text-md text-lg-lg mb-0">{state.fullAddress}</p>
            </div>
          </Col>
          <Col xs={12}>
            <h2 className="color-heading font-body font-semibold text-lg text-lg-2xl mt-10 mb-3">Table Details</h2>
          </Col>
          <Col xs={12} lg={6} className="font-body">
            <div className="d-flex mb-3">
              <p className="color-heading mb-0 mr-5">Tables for 1: <span className="color-lightblue font-semibold text-md text-lg-lg mb-0">1</span></p>
              <p className="color-heading mb-0">Min. spent: <span className="color-lightblue font-semibold text-md text-lg-lg mb-0">1000</span></p>
            </div>
            <div className="d-flex mb-3">
              <p className="color-heading mb-0 mr-5">Tables for 2: <span className="color-lightblue font-semibold text-md text-lg-lg mb-0">1</span></p>
              <p className="color-heading mb-0">Min. spent: <span className="color-lightblue font-semibold text-md text-lg-lg mb-0">1000</span></p>
            </div>
            <div className="d-flex mb-3">
              <p className="color-heading mb-0 mr-5">Tables for 3: <span className="color-lightblue font-semibold text-md text-lg-lg mb-0">1</span></p>
              <p className="color-heading mb-0">Min. spent: <span className="color-lightblue font-semibold text-md text-lg-lg mb-0">1000</span></p>
            </div>
            <div className="d-flex mb-3">
              <p className="color-heading mb-0 mr-5">Tables for 4: <span className="color-lightblue font-semibold text-md text-lg-lg mb-0">1</span></p>
              <p className="color-heading mb-0">Min. spent: <span className="color-lightblue font-semibold text-md text-lg-lg mb-0">1000</span></p>
            </div>
            <div className="d-flex mb-3 ">
              <p className="color-heading mb-0 mr-5">Tables for 5: <span className="color-lightblue font-semibold text-md text-lg-lg mb-0">1</span></p>
              <p className="color-heading mb-0">Min. spent: <span className="color-lightblue font-semibold text-md text-lg-lg mb-0">1000</span></p>
            </div>
            <div className="d-flex mb-3">
              <p className="color-heading mb-0 mr-5">Tables for 6: <span className="color-lightblue font-semibold text-md text-lg-lg mb-0">1</span></p>
              <p className="color-heading mb-0">Min. spent: <span className="color-lightblue font-semibold text-md text-lg-lg mb-0">1000</span></p>
            </div>
            <div className="d-flex mb-3">
              <p className="color-heading mb-0 mr-5">Tables for 7: <span className="color-lightblue font-semibold text-md text-lg-lg mb-0">1</span></p>
              <p className="color-heading mb-0">Min. spent: <span className="color-lightblue font-semibold text-md text-lg-lg mb-0">1000</span></p>
            </div>
          </Col>
        </Row>
      </ContentWrapper>
    </Layout>
  );
}

export default ClubDetailsManagement;