import React, { useEffect, useState } from "react";
import PageDescription from "../../Components/Global/PageDescription";
import Layout from "../../Layout/Layout";
import { Table, Modal } from "react-bootstrap";
import ContentWrapper from "../../Components/Global/ContentWrapper";
import { collection, getDocs, doc, deleteDoc, orderBy, query } from "firebase/firestore";
import db from "../../firebase";
import { useNavigate } from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";
import PrimaryButton from "../../Components/Global/Buttons/PrimaryButton";
import Dropdown from "react-bootstrap/Dropdown";
import SecondaryButton from "../../Components/Global/Buttons/SecondaryButton";
import ReactPaginate from 'react-paginate';
import Skeleton from "react-loading-skeleton";
import moment from "moment";

const ImagePreview = () => {
  return (
    <div
      style={{
        height: "75px",
        width: "150px",
        backgroundColor: "#DEDEDE",
      }}
      className="bg-gray rounded overflow-hidden d-flex align-items-center justify-content-center"
    >
      <i className="bi bi-card-image text-6xl"></i>
    </div>
  );
};

const AdvertisementManager = () => {

  const [advertisements, setAdvertisements] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  // modal states
  const [show, setShow] = useState({ show: false, data: null });
  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setShow({ show: true, data: advertisements[id] });
  };

  // Table Height : Start
  const [firstSectionHeight, setFirstSectionHeight] = useState(0);
  const secondSectionHeight = `calc(75vh - ${firstSectionHeight}px)`;
  // Calculate the height of the first section on mount and window resize
  useEffect(() => {
    const calculateHeight = () => {
      const firstSection = document.getElementById('first-section');
      if (firstSection) {
        setFirstSectionHeight(firstSection.clientHeight);
      }
    };

    calculateHeight();
    window.addEventListener('resize', calculateHeight);

    return () => {
      window.removeEventListener('resize', calculateHeight);
    };
  }, []);
  // Table Height : End

  // fetching entries
  const fetchRequests = async () => {

    const q = query(collection(db, 'advertisements'), orderBy('createdAt', 'desc'));
    const querySnapshot = await getDocs(q);
    const newData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    setAdvertisements(newData);
    setIsLoading(false);
  };

  // deleting entries
  const hadnleDelete = async (data) => {
    try {
      await deleteDoc(doc(db, "advertisements", data.id));
      fetchRequests();
      handleClose();
    } catch (err) {
    }
  };

  //Changing First letter Capital
  function capitalizeFirstLetter(inputString) {
    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
  }

  useEffect(() => {
    fetchRequests();
  }, []);

  // Editing method
  const history = useNavigate();
  const handleEdit = (id) => {
    history(`/advertisement-manager/edit-advertisement/${id}`);
  };
  const TableEntries = ({ currentItems, itemOffset }) => {
    return (
      <div className="tableFixHead pb-5 font-body rounded bg-white" style={{ height: secondSectionHeight }}>
        <Table hover>
          <thead>
            <tr className="background-gray10">
              <th>
                <p className="text-nowrap text-xs text-xl-sm text-uppercase ps-3 color-black2 mb-0 py-2">S. No</p>
              </th>
              <th>
                <p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">thumbnail</p>
              </th>
              <th>
                <p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">title</p>
              </th>
              {/* <th>
                <p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">category</p>
              </th> */}
              <th>
                <p className="text-nowrap text-xs text-xl-sm text-center text-uppercase color-black2 mb-0 py-2">Redirection Type</p>
              </th>
              <th>
                <p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">Destination URL</p>
              </th>
              <th>
                <p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">Created On</p>
              </th>
              <th>
                <p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">Start Date</p>
              </th>
              <th>
                <p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">End Date</p>
              </th>
              <th>
                <p className="text-nowrap text-xs text-xl-sm text-uppercase text-center color-black2 mb-0 py-2">status</p>
              </th>
              <th>
                {/* <p className="text-nowrap text-md text-uppercase">Actions</p> */}
              </th>
            </tr>
          </thead>
          <tbody>
            {!isLoading ?
              (
                <>
                  {(currentItems && currentItems.length) ? currentItems.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td className="align-middle">
                          <p className="text-sm mb-0 text-nowrap color-black ps-3">{itemOffset + index + 1}.</p>
                        </td>
                        <td className="align-middle">
                          {data.display_image ? (
                            <div
                              style={{
                                height: "75px",
                                width: "150px",
                                backgroundImage: `url(${data.display_image && data.display_image
                                  })`,
                              }}
                              className="background-position-center-top background-repeat-norepeat background-size-cover rounded overflow-hidden "
                            ></div>
                          ) : (
                            <ImagePreview />
                          )}
                        </td>
                        <td className="align-middle">
                          <p className="text-sm mb-0 text-nowrap color-black">
                            {data.name && data.name}
                          </p>
                        </td>
                        {/* <td className="align-middle">
                          <p className="text-sm mb-0 text-nowrap color-black">
                            {data.ad_category_name && data.ad_category_name}
                          </p>
                        </td> */}
                        <td className="align-middle">
                          <p className="text-sm mb-0 text-center color-black">
                            {data && capitalizeFirstLetter(data.redirect_type)}
                          </p>
                        </td>
                        <td className="align-middle">
                          <p className="text-sm mb-0 color-black">
                            {data.redirect_type === "internal" ? `${data.category_selected_name} (${data.advertisement_trigger_category})` : data.redirecting_url && data.redirecting_url}
                          </p>
                        </td>
                        <td className="align-middle">
                          <p className="text-sm mb-0 color-black">
                            {moment(new Date(data.createdAt.seconds * 1000).toISOString()).format("DD MMM YYYY")}
                          </p>
                        </td>
                        <td className="align-middle">
                          <p className="text-sm mb-0 color-black text-nowrap">
                            {data && data.start_date && data.start_date.seconds && moment(new Date(data.start_date.seconds * 1000).toISOString()).format("DD MMM YYYY")}
                          </p>
                        </td>
                        <td className="align-middle">
                          <p className="text-sm mb-0 color-black text-nowrap">
                            {data && data.end_date && data.end_date.seconds && moment(new Date(data.end_date.seconds * 1000).toISOString()).format("DD MMM YYYY")}
                          </p>
                        </td>
                        <td className="align-middle">
                          <div className="d-flex align-items-center justify-content-start">
                            <div style={{ height: "10px", width: '10px', backgroundColor: `${data.status === "Active" ? "#13DEB9" : "#6c757d"}` }} className="rounded-circle"></div>
                            <div className="ms-2 text-sm color-black">{data && data.status}</div>
                          </div>
                        </td>
                        <td className="align-middle">
                          <Dropdown>
                            <Dropdown.Toggle variant="link" id="dropdown-basic" className="text-black">
                              <i className="bi bi-three-dots-vertical text-4xl d-bloxk"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="border-0 shadow px-2">
                              <Dropdown.Item className="border-bottom font-bold text-sm py-2 color-black" onClick={() => handleEdit(data.id)}>
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item className="font-bold text-sm py-2 color-black" onClick={() => handleShow(index)}>
                                Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  }) : ""
                  }
                </>
              ) : (
                <tr>
                  <td colSpan={8}> <Skeleton inline={true} count={5} height={80} style={{ marginBottom: "1rem" }} baseColor='#dedede' highlightColor='#cccccc' duration={2} />
                  </td>
                </tr>
              )
            }
          </tbody>
        </Table>
      </div>
    )
  }
  function PaginatedItems({ itemsPerPage }) {
    const [pageCount, setPageCount] = useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    const [currentItems, setCurrentItems] = useState(null)

    useEffect(() => {
      // Fetch items from another resources.
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(advertisements.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(advertisements.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
      const newOffset = event.selected * itemsPerPage % advertisements.length;
      setItemOffset(newOffset);
    };
    return (
      <>

        <TableEntries currentItems={currentItems} itemOffset={itemOffset} />
        <ReactPaginate
          nextLabel={<i className="bi bi-chevron-right"></i>}
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel={<i className="bi bi-chevron-left"></i>}
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination pe-4 pt-5 d-flex justify-content-end"
          // activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </>
    );
  }
  // const currentItems = ""
  return (
    <Layout path="/advertisement-manager" additionalclass="d-flex flex-column">
      <div className='po-table-content mb-8' id="first-section" style={{ height: 'auto', minHeight: '100px' }}>
        <div className="d-flex justify-content-between align-items-center gap-3 gap-lg-5 font-body">
          <PageDescription title="Advertisement Manager" caption="" />
          <PrimaryButton
            link="/advertisement-manager/create-advertisement"
            name="Create"
            icon="bi-plus-circle"
          />
        </div>
      </div>


      <ContentWrapper additionalClass="pb-2 font-body">


        <PaginatedItems itemsPerPage={20} />
      </ContentWrapper>
      <Modal show={show.show} onHide={handleClose}>
        <div className="background-appColor p-8 rounded font-body">
          <h5 className="text-2xl  color-ternary font-semibold ">
            Are you sure?
          </h5>
          <p className="background-appColor text-light border-bottom-0 py-1 ">
            You want to delete this advertisement
          </p>
          <div className="d-flex justify-content-end mt-12">
            <SecondaryButton onClick={handleClose} name="Cancel" additionalClass="text-white me-3" />
            <PrimaryButton onClick={() => hadnleDelete(show.data)} name="Delete" />
          </div>
        </div>
      </Modal>
    </Layout>
  );
};
export default AdvertisementManager;
