import React, { useState, useEffect } from "react";
import { Table, Modal } from "react-bootstrap";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import {
  Link,
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";
import db from "../../../../firebase";
import PageDescription from "../../../../Components/Global/PageDescription";
import Layout from "../../../../Layout/Layout";
import ContentWrapper from "../../../../Components/Global/ContentWrapper";
// import ActionIcon from "../../../../Components/Global/Buttons/ActionIcons";
// import Skeleton from "react-loading-skeleton";
import PrimaryButton from "../../../../Components/Global/Buttons/PrimaryButton";
import Dropdown from "react-bootstrap/Dropdown";
import SecondaryButton from "../../../../Components/Global/Buttons/SecondaryButton";
import Toaster from "../../../../Components/Global/Toast";
const ConceigersList = () => {
  const [users, setUsers] = useState([]);
  let navigate = useNavigate();
  const location = useLocation();
  let { club_id } = useParams();
  const clubName = location.state;
  const [showToaster, setShowToaster] = useState(false);
  const [show, setShow] = useState(false);
  const [toasterDetails, setToasterDetails] = useState({
    title: "",
    bodyText: "",
    bg: "",
    statusIcon: "",
  });
  const [deleteId, setDeleteId] = useState();

  // Fetch all the users records from the firestore
  const fetchAllUsersRecords = async () => {
    await getDocs(query(collection(db, "users"), where("club_id", "!=", null)))
      .then((users) => {
        let tempArray = [];

        // with users response from that filtering club_id
        users &&
          users.docs.forEach((user) => {
            if (user.data().club_id === club_id) {
              let userData = user.data();

              // Checking if the user is concierge or not
              if (
                userData &&
                userData.role &&
                userData.role.includes("concierge")
              ) {
                tempArray.push(user.data());
              }
            }
          });
        // setting tempArray to the users state
        setUsers(tempArray);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchAllUsersRecords();
  }, []);

  // Delete conceiger
  const deleteConceiger = async () => {
    setShow(false);
    setShowToaster(true);
    setToasterDetails((prev) => ({
      ...prev,
      title: "Deleting...",
      bodyText: "Deleting conceiger...",
      bg: "warning",
      statusIcon: (
        <div className="spinner-border text-warning me-2" role="status"></div>
      ),
    }));
    console.log(deleteId, "users.id");
    await deleteDoc(doc(db, "users", deleteId))
      .then(() => {
        setToasterDetails((prev) => ({
          ...prev,
          title: "Success",
          bodyText: "Successfully Deleted",
          bg: "success",
          statusIcon: (
            <i className="bi bi-check text-success text-3xl me-2"></i>
          ),
        }));
        setTimeout(() => {
          setShowToaster(false);
          fetchAllUsersRecords();
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        setToasterDetails((prev) => ({
          ...prev,
          title: "Error",
          bodyText: "Something went wrong",
          bg: "danger",
          statusIcon: (
            <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
          ),
        }));
      });
  };

  return (
    <Layout
      path="/clubs/drink-management"
      additionalClass="no-scrollbar d-flex flex-column justify-content-between"
    >
      <div className="d-flex justify-content-between align-items-center gap-3 gap-lg-5 font-body">
        <PageDescription title="All Concierges Lists" caption="" />
        <PrimaryButton name="Add Category" icon="bi-plus-circle" />
        {/* <Button
          variant="success"
          onClick={(e) =>
            navigate(`/users/create-user?club_id=${club_id}`, { state: { type: "concierge", clubName: clubName } })
          }
        >
          Add Conceiger
        </Button> */}
        <PrimaryButton
          onClick={(e) =>
            navigate(`/users/create-user?club_id=${club_id}`, {
              state: { type: "concierge", clubName: clubName },
            })
          }
          name="Add Conceiger"
          icon="bi-plus-circle"
        />
      </div>
      <ContentWrapper additionalClass="py-5 rounded font-body">
        <h2 className="text-lg font-semibold ps-4 py-3 color-lightblue">
          Showing Concierges List For{" "}
          <span className="text-uppercase font-bold text-dark">{clubName}</span>
          <span className="text-uppercase font-bold text-dark"></span>
        </h2>
        <div className="table-responsive po-table tableFixHead">
          <Table hover>
            <thead>
              <tr className="background-gray10">
                <th>
                  <p className="text-nowrap text-xs text-xl-md text-uppercase ps-3 color-black2 mb-0 py-2">
                    S. no.
                  </p>
                </th>
                <th>
                  <p className="text-nowrap text-xs text-xl-md text-uppercase  color-black2 mb-0 py-2">
                    {" "}
                    Name
                  </p>
                </th>
                <th>
                  <p className="text-nowrap text-xs text-xl-md text-uppercase  color-black2 mb-0 py-2">
                    Email
                  </p>
                </th>
                <th>
                  <p className="text-nowrap text-xs text-xl-md text-uppercase  color-black2 mb-0 py-2">
                    Phone number
                  </p>
                </th>
                <th>
                  <p className="text-nowrap text-xs text-xl-md text-uppercase  color-black2 mb-0 py-2"></p>
                </th>
              </tr>
            </thead>
            <tbody>
              {users &&
                users.map((user, index) => {
                  return (
                    <tr key={index}>
                      <td className="align-middle">
                        <p className="text-sm mb-0 text-nowrap color-lightblue ps-3">
                          {index + 1}.
                        </p>
                      </td>
                      <td className="align-middle">
                        <p className="text-sm mb-0 text-nowrap color-lightblue">
                          {user.name}.
                        </p>
                      </td>
                      <td className="align-middle">
                        <p className="text-sm mb-0 text-nowrap color-lightblue">
                          {user.email}.
                        </p>
                      </td>
                      <td className="align-middle">
                        <p className="text-sm mb-0 text-nowrap color-lightblue">
                          {user.contacts[0].contact_no}
                        </p>
                      </td>
                      <td className="align-middle">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="link"
                            id="dropdown-basic"
                            className="text-black"
                          >
                            <i className="bi bi-three-dots-vertical text-4xl d-bloxk"></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="border-0 shadow px-2">
                            <Link
                              className="border-bottom font-bold text-sm py-2 d-block text-black ps-2"
                              to={`/users/update-user/${user.id}`}
                              state={{ type: "concierge" }}
                            >
                              Edit
                            </Link>
                            <div
                              className="cursor-pointer font-bold text-sm py-2 d-block text-black ps-2"
                              onClick={(e) => {
                                setShow(true);
                                setDeleteId(user.id);
                              }}
                            >
                              Delete
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
        <Modal
          show={show}
          onHide={() => {
            setShow(false);
          }}
        >
          <div className="background-appColor p-5 rounded font-body">
            <h5 className="text-2xl  color-ternary  mb-3">Confirm Delete</h5>
            <div>
              <p className="background-appColor text-light border-bottom-0 py-1 ">
                Are you sure you want to delete concierge user?
              </p>
            </div>
            <div className="mt-6 text-end">
              <SecondaryButton
                onClick={() => setShow(false)}
                name="No"
                additionalClass="text-light me-3 px-5"
              />
              <PrimaryButton onClick={deleteConceiger} name="Yes" />
            </div>
          </div>
        </Modal>
        <Toaster
          showToaster={showToaster}
          setShowToaster={setShowToaster}
          bg={toasterDetails.bg}
          title={toasterDetails.title}
          bodyText={toasterDetails.bodyText}
          statusIcon={toasterDetails.statusIcon}
        />
      </ContentWrapper>
    </Layout>
  );
};
export default ConceigersList;
