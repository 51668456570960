import { Col } from "react-bootstrap";
import {motion} from 'framer-motion';

const Loading = ({status="Uploading..."}) => {
  return (
    <Col xs={4}>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="d-flex po-state po-state__loading p-2 w-25"
      >
        <div className="po-state__icon me-3">
        
        </div>
        <div className="po-state__desc ">
          <p className=" text-xl mb-0 font-bold mt-1">{status}</p>
        </div>
        <div className="po-state__action"></div>
      </motion.div>
    </Col>
  );
};
export default Loading;
