import React, { Suspense } from "react";

import {
	BrowserRouter,
	// createBrowserRouter,
	// Navigate,
	Route,
	// RouterProvider,
	Routes,
} from "react-router-dom";
// import AddManger from "../pages/ads-manager";

// import manager panel related pages--------------------------------**********------------------------------------------

import ManagerDashboard from "../pages/manager-pages/dashboard";
import ClubDetailsManagement from "../pages/manager-pages/club-details-management";
import EventManagement from "../pages/manager-pages/event-management";
import ManagerEditEvent from "../pages/manager-pages/event-management/editEvent";
import CreateManagerEvent from "../pages/manager-pages/event-management/addEvent";
import EditManagerClub from "../pages/manager-pages/club-details-management/editManagerClubDetails";
import BookingManagement from "../pages/manager-pages/booking-management";
import ManagerEventBookingDetails from "../pages/manager-pages/booking-management/ManagerEventBookingDetails";
import ManagerEntryBookingDetails from "../pages/manager-pages/booking-management/ManagerEntryBookingDetail";
import ManagerTableBookingDetails from "../pages/manager-pages/booking-management/ManagerTableBookingDetail";
import DrinkManagement from "../pages/manager-pages/drinks-management";
import CreateCategory from "../pages/manager-pages/drinks-management/create-category";
import UpdateCategory from "../pages/manager-pages/drinks-management/category-update";
import SmokesManagement from "../pages/manager-pages/smokes-management";
import CreateSmokeCategory from "../pages/manager-pages/smokes-management/create-category";
import UpdateSmokeCategory from "../pages/manager-pages/smokes-management/category-update";
import ManagerCloseBooking from "../pages/manager-pages/close-booking";
// import ManagerCreateCloseBooking from "../pages/manager-pages/close-booking/create-close-booking";
import ManagerUpdateCloseBooking from "../pages/manager-pages/close-booking/update-close-booking";
import ManagerEventBookings from "../pages/manager-pages/event-management/managerEventBookings";


// Influencer related pages--------------------------------------------------------------------------
import InfluencerProfile from "../pages/influencer/influencer-profile";
import InfluencerReferrals from "../pages/influencer/influencer-referrals";
import ReferralsCode from "../pages/influencer/referral-code";



// Admin panel related pages--------------------------------------------------------------------------


import Events from "../pages/events";
import Payments from "../pages/payments";
import Dashboard from "../pages/dashboard";
import Login from "../pages/login";
import Clubs from "../pages/clubs/index";
import State from "../pages/state";
import Country from "../pages/country/index";
import City from "../pages/city";
import EditEvent from "../pages/events/edit-event";

import RequestDetails from "../pages/request-details";
import EventDetails from "../pages/events/event-bookings";
import { RolesAuthRoute } from "./RolesAuthRoute";
import AdvertisementManager from "../pages/advertisement-manager";
import CreateAdvertisement from "../pages/advertisement-manager/create-advertisement";
import EditAdvertisement from "../pages/advertisement-manager/edit-advertisement";
import MembershipRequests from "../pages/membership-requests/index";
import CreateEvent from "../pages/events/create-event";
import States from "../pages/state/index"
import Bookings from "../pages/bookings";
import BecomeAPartner from "../pages/become-a-partner";
import EditCity from "../pages/city/edit-city";
import CreateCity from "../pages/city/create-city";
import EventBookingDetails from "../Components/Booking/EventBookingDetails";
import EntryBookingDetails from "../Components/Booking/EntryBookingDetail";
import TableBookingDetails from "../Components/Booking/TableBookingDetail";
import UsersList from "../pages/users";
import UserDetails from "../pages/users/user-details";
import ManagerCreateCloseBooking from "../pages/manager-pages/close-booking/create-close-booking";
import Notifications from "../pages/Notifications";
import CreateNotifications from "../pages/Notifications/create-notifications";
import EditNotifications from "../pages/Notifications/edit-notifications";
import MembershipRequestDetails from "../pages/membership-requests/membership-request";
import CreateState from "../pages/state/create-state";
import EditState from "../pages/state/edit-state";
import CreateCountry from "../pages/country/create-country";
import EditCountry from "../pages/country/edit-country";
import CreateUser from "../pages/users/create-user";
import UpdateUserPage from "../pages/users/update-user";
import AdminDrinkManagement from "../pages/clubs/menu-management/drink-management";
import AdminCreateCategory from "../pages/clubs/menu-management/drink-management/create-category";
import AdminUpdateCategory from "../pages/clubs/menu-management/drink-management/update-category";
import AdminSmokeManagement from "../pages/clubs/menu-management/smoke-management";
import AdminCreateSmokeCategory from "../pages/clubs/menu-management/smoke-management/create-category";
import AdminUpdateSmokeCategory from "../pages/clubs/menu-management/smoke-management/update-category";
import AdminCloseBooking from "../pages/clubs/menu-management/close-booking.js";
import AdminCreateCloseBooking from "../pages/clubs/menu-management/close-booking.js/create-close-booking";
import AdminUpdateCloseBooking from "../pages/clubs/menu-management/close-booking.js/update-close-booking";
import Pagenation from "../pages/pagenation";
import CreateTableBooking from "../pages/bookings/create-table-booking";
import CreateEventBooking from "../pages/bookings/create-event-booking";
import CreateEntryBooking from "../pages/bookings/create-entry-booking";
import ManagerCreateEntryBooking from "../pages/manager-pages/booking-management/create-entry-booking";
import ManagerCreateEventBooking from "../pages/manager-pages/booking-management/create-event-booking";
import ManagerCreateTableBooking from "../pages/manager-pages/booking-management/create-table-booking";
import Account from "../pages/account";
import ManagerAccount from "../pages/account/manager-profile";
import EditManagerAccount from "../pages/account/manager-edit-profile";
import EditAccount from "../pages/account/edit-account";
import Plans from "../pages/membership-requests/plans";
import EditPlans from "../pages/membership-requests/edit-plans";
import CreatePlan from "../pages/membership-requests/create-plan";
import CreateLadiesNight from "../pages/clubs/ladies-night/create";
import LadiesNight from "../pages/clubs/ladies-night";
import EditLadiesNight from "../pages/clubs/ladies-night/edit";
import CreatePopUp from "../pages/pop-up/create";
import DashboardPopUp from "../pages/pop-up";
import EditPopUp from "../pages/pop-up/edit";
import JourneyToBeOne from "../pages/journey-to-be-one";
import CreateJourneyToBeOne from "../pages/journey-to-be-one/create-journey";
import EditJourneyToBeOne from "../pages/journey-to-be-one/edit-journey";
import ConceigersList from "../pages/clubs/menu-management/conceirges";
import ManagersList from "../pages/clubs/menu-management/managers";
import DashboardVideo from "../pages/dashboard-video";
import ExploreFurther from "../pages/explore-further";
import CreateExploreFuther from "../pages/explore-further/create";
import Kyc from "../pages/kyc";
import KycDetails from "../pages/kyc/kyc-detail";
import CreateVideo from "../pages/dashboard-video/create";
import ClubDetails from "../pages/clubs/club-details";
import ClubBookings from "../pages/clubs/club-bookings";
import PushNotificationPage from "../pages/push-notification";
import Club from "../pages/clubs/club";
import CreateMembership from "../pages/membership-requests/create-membership";
import ActiveMemberships from "../pages/membership-requests/active-memberships";
import Support from "../pages/support/index.js";

const AppRoutes = () => {
	return (
		<BrowserRouter>
			<Suspense fallback={<div>Loading...</div>}>
				<Routes>
					{/* Manager panel related routes -------------------------------------------*/}

					<Route path="/manager"
						element={
							<RolesAuthRoute roles={["admin", "manager"]} element={<ManagerDashboard />} />
						}
					/>
					<Route path="/manager/close-booking"
						element={
							<RolesAuthRoute roles={["admin", "manager"]} element={<ManagerCloseBooking />} />
						}
					/>
					<Route path="/manager/create-close-booking"
						element={
							<RolesAuthRoute roles={["admin", "manager"]} element={<ManagerCreateCloseBooking />} />
						}
					/>
					<Route path="/manager/update-close-booking/:close_booking_id"
						element={
							<RolesAuthRoute roles={["admin", "manager"]} element={<ManagerUpdateCloseBooking />} />
						}
					/>
					<Route path="/manager/club-details"
						element={
							<RolesAuthRoute roles={["admin", "manager"]} element={<ClubDetailsManagement />} />
						}
					/>
					<Route path="/manager/events-management"
						element={
							<RolesAuthRoute roles={["admin", "manager"]} element={<EventManagement />} />
						}
					/>
					<Route path="/manager/edit-event/:event_id"
						element={
							<RolesAuthRoute roles={["admin", "manager"]} element={<ManagerEditEvent />} />
						}
					/>
					<Route path="/manager/event-bookings/:event_id"
						element={
							<RolesAuthRoute roles={["manager"]} element={<ManagerEventBookings />} />
						}
					/>

					<Route path="/manager/add-event"
						element={
							<RolesAuthRoute roles={["admin", "manager"]} element={<CreateManagerEvent />} />
						}
					/>
					<Route path="/manager/edit-club-details/:club_id"
						element={
							<RolesAuthRoute roles={["admin", "manager"]} element={<EditManagerClub />} />
						}
					/>
					<Route path="/manager/booking-management"
						element={
							<RolesAuthRoute roles={["admin", "manager"]} element={<BookingManagement />} />
						}
					/>
					<Route path="/manager/club-details"
						element={
							<RolesAuthRoute roles={["admin", "manager"]} element={<ClubDetailsManagement />} />
						}
					/>
					<Route path="/manager/event-details/:event_id"
						element={
							<RolesAuthRoute roles={["admin", "manager"]} element={<ManagerEventBookingDetails />} />
						}
					/>
					<Route path="/manager/entry-details/:entry_id"
						element={
							<RolesAuthRoute roles={["admin", "manager"]} element={<ManagerEntryBookingDetails />} />
						}
					/>
					<Route path="/manager/table-details/:table_id"
						element={
							<RolesAuthRoute roles={["admin", "manager"]} element={<ManagerTableBookingDetails />} />
						}
					/>
					<Route
						path="/manager/drinks-management"
						element={
							<RolesAuthRoute
								roles={["admin", "manager"]}
								element={<DrinkManagement />}
							/>
						}
					/>
					<Route
						path="/manager/drinks-management/create-category"
						element={
							<RolesAuthRoute
								roles={["admin", "manager"]}
								element={<CreateCategory />}
							/>
						}
					/>
					<Route
						path="/manager/drinks-management/update-category/:drink_id"
						element={
							<RolesAuthRoute
								roles={["admin", "manager"]}
								element={<UpdateCategory />}
							/>
						}
					/>

					<Route
						path="/manager/smokes-management"
						element={
							<RolesAuthRoute
								roles={["admin", "manager"]}
								element={<SmokesManagement />}
							/>
						}
					/>

					<Route
						path="/manager/smokes-management/create-category"
						element={
							<RolesAuthRoute
								roles={["admin", "manager"]}
								element={<CreateSmokeCategory />}
							/>
						}
					/>
					<Route
						path="/manager/smokes-management/update-category/:smoke_id"
						element={
							<RolesAuthRoute
								roles={["admin", "manager"]}
								element={<UpdateSmokeCategory />}
							/>
						}
					/>
					<Route
						path="/manager/booking-management/create-entry-booking"
						element={
							<RolesAuthRoute roles={["admin", "manager"]} element={<ManagerCreateEntryBooking />} />
						}
					/>
					<Route
						path="/manager/booking-management/create-event-booking"
						element={
							<RolesAuthRoute roles={["admin", "manager"]} element={<ManagerCreateEventBooking />} />
						}
					/>
					<Route
						path="/manager/booking-management/create-table-booking"
						element={
							<RolesAuthRoute roles={["manager"]} element={<ManagerCreateTableBooking />} />
						}
					/>

					{/* Influencer related routes ______________________+++++++++++++++++++++++++++++++++++++++++++++++++ */}
					<Route path="/influencer/profile" element={<InfluencerProfile />} />
					<Route path="/influencer/referrals" element={<InfluencerReferrals />} />
					<Route path="/influencer/codes" element={<ReferralsCode />} />

					{/* Admin related routes ______________________+++++++++++++++++++++++++++++++++++++++++++++++++ */}

					<Route path="/" element={<Login />} />
					<Route path="/club-registration" element={<BecomeAPartner />} />
					<Route
						path="/dashboard"
						element={
							<RolesAuthRoute roles={["admin"]} element={<Dashboard />} />
						}
					/>

					<Route
						path="/advertisement-manager"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={<AdvertisementManager />}
							/>
						}
					/>
					<Route
						path="/advertisement-manager/create-advertisement"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={<CreateAdvertisement />}
							/>
						}
					/>
					<Route
						path="/advertisement-manager/edit-advertisement/:ads_id"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={<EditAdvertisement />}
							/>
						}
					/>
					{/* <Route
						path="/dashboard"
						element={
							<RolesAuthRoute roles={["admin"]} element={<Dashboard />} />
						}
					/> */}
					{/* <Route
						path="/user-details"
						element={
							<RolesAuthRoute roles={["admin"]} element={<UserDetails />} />
						}
					/> */}
					{/* routes related to events goes here! */}
					<Route
						path="/events"
						element={<RolesAuthRoute roles={["admin"]} element={<Events />} />}
					/>
					<Route
						path="/events/create-event"
						element={
							<RolesAuthRoute roles={["admin"]} element={<CreateEvent />} />
						}
					/>
					<Route
						path="/events/edit-event/:event_id"
						element={
							<RolesAuthRoute roles={["admin"]} element={<EditEvent />} />
						}
					/>
					<Route
						path="/events/event-bookings/:event_id"
						element={
							<RolesAuthRoute roles={["admin"]} element={<EventDetails />} />
						}
					/>
					{/* <Route
						path="/events/entry-user-details"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={<EntryUserDetails />}
							/>
						}
					/> */}
					<Route
						path="/payments"
						element={
							<RolesAuthRoute roles={["admin"]} element={<Payments />} />
						}
					/>
					<Route
						path="/memberships"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={<MembershipRequests />}
							/>
						}
					/>
					<Route
						path="/request-details"
						element={
							<RolesAuthRoute roles={["admin"]} element={<RequestDetails />} />
						}
					/>
					{/* <Route
					<Route
						path="/country/create-country"
						element={<RolesAuthRoute roles={["admin"]} element={<CreateCountry />} />}
					/>
					<Route
						path="/edit-club"
						element={
							<RolesAuthRoute roles={["admin"]} element={<EditClub />} />
						}
					/>

					<Route
						path="/change-password"
						element={
							<RolesAuthRoute roles={["admin"]} element={<ChangePassword />} />
						}
					/>
					<Route
						path="/food-menu"
						element={
							<RolesAuthRoute roles={["admin"]} element={<FoodMenu />} />
						}
					/>

					<Route
						path="/bookings"
						element={
							<RolesAuthRoute roles={["admin"]} element={<Bookings />} />
						}
					/>
					<Route
						path="/requests"
						element={
							<RolesAuthRoute roles={["admin"]} element={<Requests />} />
						}
					/>
					<Route path="/bookings"
						element={
							<RolesAuthRoute roles={["admin"]} element={<Bookings />} />
						}
					/>
					<Route path="/requests"
						element={
							<RolesAuthRoute roles={["admin"]} element={<Requests />} />
						}
					/>

					{/*  Routes related to club goes here ************** */}
					{/* <Route path="/edit-club"
						element={
							<RolesAuthRoute roles={["admin"]} element={<EditClub />} />
						}
					/> */}
					{/* <Route path="/clubs/create-club"
						element={
							<RolesAuthRoute roles={["admin"]} element={<CreateClub />} />
						}
					/> */}
					{/* <Route path="/event-details"
						element={
							<RolesAuthRoute roles={["admin"]} element={<EventBookingDetails />} />
						}
					/> */}
					<Route path="/bookings/entry-details/:entry_id"
						element={
							<RolesAuthRoute roles={["admin"]} element={<EntryBookingDetails />} />
						}
					/>
					<Route path="/bookings/table-details/:table_id"
						element={
							<RolesAuthRoute roles={["admin"]} element={<TableBookingDetails />} />
						}
					/>
					<Route path="/bookings"
						element={
							<RolesAuthRoute roles={["admin"]} element={<Bookings />} />
						}
					/>
					{/* users routes goes here ********************************** */}
					<Route path="/users"
						element={
							<RolesAuthRoute roles={["admin"]} element={<UsersList />} />
						}
					/>
					<Route
						path="/users/entry-details"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={<EntryBookingDetails />}
							/>
						}
					/>
					<Route path="/users/create-user"
						element={
							<RolesAuthRoute roles={["admin"]} element={<CreateUser />} />
						}
					/>
					<Route path="/users/update-user/:user_id"
						element={
							<RolesAuthRoute roles={["admin"]} element={<UpdateUserPage />} />
						}
					/>
					<Route path="/users/user-details/:uid"
						element={
							<RolesAuthRoute roles={["admin"]} element={<UserDetails />} />
						}
					/>
					{/* Routes related to clubs goes here ******************** */}
					<Route
						path="/clubs"
						element={<RolesAuthRoute roles={["admin"]} element={<Clubs />} />}
					/>
					<Route
						path="clubs/edit-club/:club_id"
						element={
							<RolesAuthRoute roles={["admin"]} element={<Club />} />
						}
					/>
					<Route
						path="/clubs/create-club"
						element={
							<RolesAuthRoute roles={["admin"]} element={<Club />} />
						}
					/>
					{/* Routes related to menu management goes here */}
					<Route
						path="/clubs/drink-management/:club_id"
						element={
							<RolesAuthRoute roles={["admin"]} element={<AdminDrinkManagement />} />
						}
					/>
					<Route
						path="/clubs/drink-management/create-category/:club_id"
						element={
							<RolesAuthRoute roles={["admin"]} element={<AdminCreateCategory />} />
						}
					/>
					<Route
						path="/clubs/drink-management/update-category/:drink_id/:club_id"
						element={
							<RolesAuthRoute roles={["admin"]} element={<AdminUpdateCategory />} />
						}
					/>
					<Route
						path="/clubs/smoke-management/:club_id"
						element={
							<RolesAuthRoute roles={["admin"]} element={<AdminSmokeManagement />} />
						}
					/>
					<Route
						path="/clubs/smoke-management/create-category/:club_id"
						element={
							<RolesAuthRoute roles={["admin"]} element={<AdminCreateSmokeCategory />} />
						}
					/>
					<Route
						path="/clubs/smoke-management/update-category/:smoke_id/:club_id"
						element={
							<RolesAuthRoute roles={["admin"]} element={<AdminUpdateSmokeCategory />} />
						}
					/>
					<Route
						path="/clubs/close-booking/:club_id"
						element={
							<RolesAuthRoute roles={["admin"]} element={<AdminCloseBooking />} />
						}
					/>
					<Route
						path="/clubs/close-booking/create-close-booking/:club_id"
						element={
							<RolesAuthRoute roles={["admin"]} element={<AdminCreateCloseBooking />} />
						}
					/>
					<Route
						path="/clubs/close-booking/update-close-booking/:close_booking_id/:club_id"
						element={
							<RolesAuthRoute roles={["admin"]} element={<AdminUpdateCloseBooking />} />
						}
					/>
					{/* Routes for booking page start ****************************** */}
					<Route
						path="/bookings/event-details/:entry_id"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={<EventBookingDetails />}
							/>
						}
					/>
					<Route
						path="/entry-details"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={<EntryBookingDetails />}
							/>
						}
					/>
					<Route
						path="/table-details"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={<TableBookingDetails />}
							/>
						}
					/>
					<Route
						path="/bookings"
						element={
							<RolesAuthRoute roles={["admin"]} element={<Bookings />} />
						}
					/>
					<Route
						path="/bookings/create-table-booking"
						element={
							<RolesAuthRoute roles={["admin"]} element={<CreateTableBooking />} />
						}
					/>
					<Route
						path="/bookings/create-event-booking"
						element={
							<RolesAuthRoute roles={["admin"]} element={<CreateEventBooking />} />
						}
					/>
					<Route
						path="/bookings/create-entry-booking"
						element={
							<RolesAuthRoute roles={["admin"]} element={<CreateEntryBooking />} />
						}
					/>

					{/* Routes related to location goes here ***************************** */}
					<Route
						path="/pagination"
						element={
							<RolesAuthRoute roles={["admin"]} element={<Pagenation />} />
						}
					/>

					{/* Routes related to location goes here ***************************** */}
					<Route
						path="/city"
						element={
							<RolesAuthRoute roles={["admin", "manager"]} element={<City />} />
						}
					/>
					<Route
						path="/city/edit-city/:city_id"
						element={
							<RolesAuthRoute
								roles={["admin", "manager"]}
								element={<EditCity />}
							/>
						}
					/>
					<Route
						path="/city/create-city"
						element={
							<RolesAuthRoute
								roles={["admin", "manager"]}
								element={<CreateCity />}
							/>
						}
					/>
					<Route
						path="/location/city"
						element={<RolesAuthRoute roles={["admin"]} element={<City />} />}
					/>
					<Route
						path="/location/state"
						element={<RolesAuthRoute roles={["admin"]} element={<State />} />}
					/>
					<Route
						path="/country"
						element={<RolesAuthRoute roles={["admin"]} element={<Country />} />} />

					<Route
						path="/country/create-country"
						element={<RolesAuthRoute roles={["admin"]} element={<CreateCountry />} />}
					/>
					<Route
						path="/support"
						element={<RolesAuthRoute roles={["admin"]} element={<Support />} />}
					/>
					<Route
						path="/states/create-state"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={<CreateState />}
							/>
						}
					/>
					<Route
						path="/states"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={<States />}
							/>
						}
					/>
					<Route
						path="/states/edit-state/:state_id"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={<EditState />}
							/>
						}
					/>
					<Route
						path="/country/edit-country/:country_id"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={<EditCountry />}
							/>
						}
					/>
					{/* Everything related Push Notifications */}
					<Route
						path="/notifications"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={<Notifications />}
							/>
						}
					/>
					<Route
						path="/push-notification"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={<PushNotificationPage />}
							/>
						}
					/>
					<Route
						path="/notifications/create-notifications"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={<CreateNotifications />}
							/>
						}
					/>
					<Route
						path="/memberships/membership-request/:membership_id"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={<MembershipRequestDetails />}
							/>
						}
					/>
					<Route
						path="/notifications/edit-notifications/:notification_id"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={<EditNotifications />}
							/>
						}
					/>
					<Route
						path="/page"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={<Pagenation />}
							/>
						}
					/>
					<Route
						path="/profile"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={<Account />}
							/>
						}
					/>
					<Route
						path="/manager/profile"
						element={
							<RolesAuthRoute
								roles={["admin", "manager"]}
								element={<ManagerAccount />}
							/>
						}
					/>
					<Route
						path="/manager/manager-edit-profile/:user_id"
						element={
							<RolesAuthRoute
								roles={["admin", "manager"]}
								element={<EditManagerAccount />}
							/>
						}
					/>
					<Route
						path="/edit-profile/:user_id"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={<EditAccount />}
							/>
						}
					/>
					<Route
						path="/plans"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={<Plans />}
							/>
						}
					/>
					<Route
						path="/plans/edit-plans/:plan_id"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={<EditPlans />}
							/>
						}
					/>
					<Route
						path="/create-plan"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={<CreatePlan />}
							/>
						}
					/>
					<Route
						path="/create-ladies-night"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={<CreateLadiesNight/>}
							/>
						}
					/>
					<Route
						path="/ladies-night/:club_id"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={<LadiesNight/>}
							/>
						}
					/>
					<Route
						path="/edit-ladies-night"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={<EditLadiesNight/>}
							/>
						}
					/>
					<Route
						path="/popups/create-popup"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={<CreatePopUp/>}
							/>
						}
					/>
					<Route
						path="/popups/edit-popup/:popup_id"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={<EditPopUp/>}
							/>
						}
					/>
					<Route
						path="/popups"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={<DashboardPopUp/>}
							/>
						}
					/>
					<Route
						path="/journey"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={<JourneyToBeOne/>}
							/>
						}
					/>
					<Route
						path="/create-journey"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={<CreateJourneyToBeOne/>}
							/>
						}
					/>
					<Route
						path="/edit-journey/:journey_id"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={<EditJourneyToBeOne/>}
							/>

						}
					/>
					<Route
						path="/conceigers/:club_id"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={<ConceigersList/>}
							/>

						}
					/>
					<Route
						path="/managers/:club_id"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={<ManagersList/>}
							/>

						}
					/>
					<Route
						path="/video"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={<DashboardVideo />}
							/>

						}
					/>
					<Route
						path="/explore-further"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={<ExploreFurther />}
							/>

						}
					/>
					<Route
						path="/explore-create"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={ <CreateExploreFuther />}
							/>

						}
					/>
					<Route
						path="/kyc"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={ <Kyc />}
							/>

						}
					/>

					<Route
						path="/kyc-view/:kyc_id"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={ <KycDetails />}
							/>

						}
					/>
					<Route
						path="/create-video"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={ <CreateVideo />}
							/>

						}
					/>
						<Route
						path="/clubs/club-details/:club_id"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={ <ClubDetails />}
							/>

						}
					/>
					<Route
						path="/clubs-bookings/:club_id"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={ <ClubBookings />}
							/>

						}
					/>

					<Route
						path="/create-membership"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={ <CreateMembership />}
							/>

						}
					/>
				 <Route
						path="/active-memberships"
						element={
							<RolesAuthRoute
								roles={["admin"]}
								element={ <ActiveMemberships />}
							/>

						}
					/>
				</Routes>

			</Suspense>
		</BrowserRouter>
	);
};

export default AppRoutes;
