import { Link } from "react-router-dom";

const breadCrumbsData = [
  {
    icon: "/assets/icons/sidebar/dark/new/dashboard.svg",
    title: "Dashboard",
    caption: "/ Overview",
    link: "/dashboard",
  },
  {
    icon: "/assets/icons/sidebar/dark/new/clubs.svg",
    title: "Clubs",
    caption: "/ All",
    link: "/clubs",
  },
  {
    icon: "/assets/icons/sidebar/dark/new/clubs.svg",
    title: "Clubs",
    caption: "/ Create Club",
    link: "/clubs/create-club",
    to: "/clubs"
  },
  {
    icon: "/assets/icons/sidebar/dark/new/clubs.svg",
    title: "Clubs",
    caption: "/ Club Details",
    link: "/clubs/club-details",
    to: "/clubs"
  },
  {
    icon: "/assets/icons/sidebar/dark/new/clubs.svg",
    title: "Clubs",
    caption: "/ Edit Club",
    link: "/clubs/edit-club",
    to: "/clubs"
  },
  {
    icon: "/assets/icons/sidebar/dark/new/clubs.svg",
    title: "Clubs",
    caption: "/ All Drinks",
    link: "/clubs/drink-management",
    to: "/clubs"
  },
  {
    icon: "/assets/icons/sidebar/dark/new/clubs.svg",
    title: "Clubs",
    caption: "/ All Smokes",
    link: "/clubs/smoke-management",
    to: "/clubs"
  },
  {
    icon: "/assets/icons/sidebar/dark/new/clubs.svg",
    title: "Clubs",
    caption: "/ All Non-Operational Days",
    link: "/clubs/close-booking",
    to: "/clubs"
  },
  {
    icon: "/assets/icons/sidebar/dark/new/events.svg",
    title: "Event",
    caption: "/ All",
    link: "/events",
  },
  {
    icon: "/assets/icons/sidebar/dark/new/events.svg",
    title: "Events",
    caption: "/ Create",
    link: "/events/create-event",
    to: "/events"
  },
  {
    icon: "/assets/icons/sidebar/dark/new/events.svg",
    title: "Events",
    caption: "/ Edit events",
    link: "/events/edit-event",
    to: "/events"
  },
  {
    icon: "/assets/icons/sidebar/dark/new/events.svg",
    title: "Events",
    caption: "/ Event bookings",
    link: "/events/event-bookings",
    to: "/events"
  },
  {
    icon: "/assets/icons/sidebar/dark/dashboard-icon-dark.svg",
    title: "Ads Manager",
    caption: "All",
    link: "/ads-manager",
  },
  {
    icon: "/assets/icons/sidebar/dark/new/vip-plan.svg",
    title: "Memberships",
    caption: "/ Active Memberships",
    link: "/active-memberships",
  },
  {
    icon: "/assets/icons/sidebar/dark/new/vip-plan.svg",
    title: "Memberships",
    caption: "/ Membership Requests",
    link: "/memberships",
  },
  {
    icon: "/assets/icons/sidebar/dark/new/vip-plan.svg",
    title: "Memberships",
    caption: "/ Membership Request Details",
    link: "/memberships/membership-request",
    to: "/memberships"
  },
  {
    icon: "/assets/icons/sidebar/dark/new/vip-plan.svg",
    title: "Memberships",
    caption: "/ Create Membership Request",
    link: "/create-membership",
    to: "/memberships"
  },
  {
    icon: "/assets/icons/sidebar/dark/new/vip-plan.svg",
    title: "Memberships",
    caption: "/ Active Memberships / Membership Details",
    link: "/memberships/active-request",
    to: "/active-memberships"
  },
  {
    icon: "/assets/icons/sidebar/dark/new/vip-plan.svg",
    title: "Exclusive Plans",
    caption: "/ All",
    link: "/plans",
    to: "/plans"
  },
  {
    icon: "/assets/icons/sidebar/dark/new/vip-plan.svg",
    title: "Exclusive Plans",
    caption: "/ Edit",
    link: "/plans/edit-plans",
    to: "/plans"
  },
  {
    icon: "/assets/icons/sidebar/dark/new/users.svg",
    title: "Users",
    caption: "/ All",
    link: "/users",
  },
  {
    icon: "/assets/icons/sidebar/dark/new/users.svg",
    title: "Users",
    caption: "/ Create",
    link: "/users/create-user",
    to: "/users"
  },
  {
    icon: "/assets/icons/sidebar/dark/new/users.svg",
    title: "Users",
    caption: "/ Update",
    link: "/users/update-user",
    to: "/users"
  },
  {
    icon: "/assets/icons/sidebar/dark/new/users.svg",
    title: "Users",
    caption: "/ User Details",
    link: "/users/user-details",
    to: "/users"
  },
  // {
  //   icon: "/assets/icons/sidebar/dark/membership-icon-dark.svg",
  //   title: "Memberships",
  //   caption: "/ User details",
  //   link: "/user-details",
  //   to:"/memberships"
  // },
  {
    icon: "/assets/icons/sidebar/dark/payment-icon-dark.svg",
    title: "Payments",
    caption: "All",
    link: "/payments",
  },
  {
    icon: "/assets/icons/sidebar/dark/new/location.svg",
    title: "Locations",
    caption: "All",
    link: "/locations",
  },
  {
    icon: "/assets/icons/sidebar/dark/new/location.svg",
    title: "City",
    caption: "/ all",
    link: "/city",
  },
  {
    icon: "/assets/icons/sidebar/dark/new/location.svg",
    title: "City",
    caption: "/ create",
    link: "/city/create-city",
    to: "/city"
  },
  {
    icon: "/assets/icons/sidebar/dark/new/location.svg",
    title: "City",
    caption: "/ edit",
    link: "/city/edit-city",
    to: "/city"
  },
  {
    icon: "/assets/icons/sidebar/dark/new/location.svg",
    title: "States",
    caption: "/ all",
    link: "/states",
  },
  {
    icon: "/assets/icons/sidebar/dark/new/location.svg",
    title: "States",
    caption: "/ create",
    link: "/states/create-state",
    to: "/states"
  },
  {
    icon: "/assets/icons/sidebar/dark/new/location.svg",
    title: "States",
    caption: "/ edit",
    link: "/state/edit-state",
    to: "/states"
  },
  // routes related to country ************************************
  {
    icon: "/assets/icons/sidebar/dark/new/location.svg",
    title: "Country",
    caption: "/ all",
    link: "/country",
  },
  {
    icon: "/assets/icons/sidebar/dark/new/location.svg",
    title: "Country",
    caption: "/ create",
    link: "/country/create-country",
    to: "/country"
  },
  {
    icon: "/assets/icons/sidebar/dark/new/location.svg",
    title: "Country",
    caption: "/ edit",
    link: "/country/edit-country",
    to: "/country"
  },
  // {
  //   icon: "/assets/icons/sidebar/dark/new/clubs.svg",
  //   title: "Clubs",
  //   caption: "/ Edit Clubs",
  //   link: "/edit-club",
  //   to:"/clubs"
  // },
  {
    icon: "/assets/icons/sidebar/dark/new/notifications.svg",
    title: "Advertisement Manager",
    caption: "",
    link: "/advertisement-manager",
  },
  {
    icon: "/assets/icons/sidebar/dark/new/notifications.svg",
    title: "Advertisement Manager",
    caption: "/ Edit",
    link: "/advertisement-manager/edit-advertisement",
    to: "/advertisement-manager"
  },
  {
    icon: "/assets/icons/sidebar/dark/new/notifications.svg",
    title: "Advertisement Manager",
    caption: "/ Create",
    link: "/advertisement-manager/create-advertisement",
    to: "/advertisement-manager"
  },
  {
    icon: "/assets/icons/sidebar/dark/new/bookings.svg",
    title: "Bookings",
    caption: "/ overview",
    link: "/bookings",
  },
  {
    icon: "/assets/icons/sidebar/dark/new/bookings.svg",
    title: "Bookings",
    caption: "/ Event Details",
    link: "/bookings/event-details",
    to: "/bookings?tab=events"
  },
  {
    icon: "/assets/icons/sidebar/dark/new/bookings.svg",
    title: "Bookings",
    caption: "/ Entry Details",
    link: "/bookings/entry-details",
    to: "/bookings?tab=clubs"
  },
  {
    icon: "/assets/icons/sidebar/dark/new/bookings.svg",
    title: "Bookings",
    caption: "/ Table Details",
    link: "/bookings/table-details",
    to: "/bookings"
  },
  {
    icon: "/assets/icons/sidebar/dark/new/bookings.svg",
    title: "Bookings",
    caption: "/ Create",
    link: "/bookings/create-table-booking",
    to: "/bookings"
  },
  {
    icon: "/assets/icons/sidebar/dark/new/bookings.svg",
    title: "Bookings",
    caption: "/ Create",
    link: "/bookings/create-entry-booking",
    to: "/bookings"
  },
  {
    icon: "/assets/icons/sidebar/dark/new/bookings.svg",
    title: "Bookings",
    caption: "/ Create",
    link: "/bookings/create-event-booking",
    to: "/bookings"
  },
  {
    icon: "/assets/icons/sidebar/dark/new/notifications.svg",
    title: "Campaigns",
    caption: "",
    link: "/notifications",
    to: ""
  },

  {
    icon: "/assets/icons/sidebar/dark/new/notifications.svg",
    title: "Campaigns",
    caption: "/ create",
    link: "/notifications/create-notifications",
    to: "/notifications"
  },
  {
    icon: "/assets/icons/sidebar/dark/new/notifications.svg",
    title: "Campaigns",
    caption: "/ edit notifications",
    link: "/notifications/edit-notifications",
    to: "/notifications"
  },
  {
    icon: "/assets/icons/gear-fill-icon.svg",
    title: "Settings",
    link: "/profile",
    to: "/profile"
  },
  {
    icon: "/assets/icons/gear-fill-icon.svg",
    title: "Settings",
    caption: "/ edit profile",
    link: "/profile/edit-profile",
    to: "/profile"
  },
  {
    icon: "/assets/icons/sidebar/dark/new/notifications.svg",
    title: "Notifications",
    caption: "/ edit notifications",
    link: "/table-details",
    to: "/notifications"
  },
  {
    icon: "/assets/icons/sidebar/dark/new/notifications.svg",
    title: "Popups",
    caption: "",
    link: "/popups"
  },
  {
    icon: "/assets/icons/sidebar/dark/new/notifications.svg",
    title: "Popups",
    caption: "/ create-popup",
    link: "/popups/create-popup",
    to: "/popups"
  },
  {
    icon: "/assets/icons/sidebar/dark/new/notifications.svg",
    title: "Popups",
    caption: "/ edit-popup",
    link: "/popups/edit-popup",
    to: "/popups"
  },
  {
    icon: "/assets/icons/sidebar/dark/new/cms.svg",
    title: "Background Video",
    caption: "",
    link: "/create-video",
  },
  {
    icon: "/assets/icons/bellicon-black.svg",
    title: "Notifications",
    caption: "",
    link: "/push-notification",
  },
  {
    icon: "/assets/dashboard_icons/support.svg",
    title: "Support",
    caption: "",
    link: "/support",
  },


];
const Breadcrumb = ({ path, link, breadCrumbData }) => {

  return (
    <>
      {breadCrumbsData.map((data, index) => {
        if (path === data.link) {
          return (
            <Link to={data.to} key={index}>
              <div className="d-flex align-items-center">
                {
                  data.icon && <div>
                    <img
                      src={data.icon}
                      alt="dashboard-icon"
                      width="16"
                      height="16"
                      className="d-block"
                    />
                  </div>
                }
                {data.title && <div className="ms-2 text-sm text-lg-md font-body font-semibold text-black">
                  {data.title}
                </div>}
                {data.caption && <div className="ms-2 text-xs text-lg-sm font-body font-regular text-black">
                  {data.caption}
                </div>}
              </div>
            </Link>
          );
        } else return ""
      })}
      {!path && <Link to={link}>
        <div className="d-flex align-items-center">

          {/* {
            data.icon &&  <div>
            <img
              src={data.icon}
              alt="dashboard-icon"
              width="15"
              height="15"
              className="d-block"
            />
          </div>
          } */}
          {breadCrumbData && breadCrumbData.title && <div className="ms-2 text-sm text-lg-md font-body font-semibold text-black">
            {breadCrumbData.title}
          </div>}
          {breadCrumbData && breadCrumbData.caption && <div className="ms-2 text-xs text-lg-sm font-body font-regular text-black">
            {breadCrumbData.caption}
          </div>}
        </div>
      </Link>}
    </>
  );
};
export default Breadcrumb;