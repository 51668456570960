import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { NavLink, useLocation } from "react-router-dom";
import Logout from "../../Components/Global/Popup/ConfirmModal";

const contents = {
  title: "Confirm Logout",
  permission: "Are you sure you want to log out?",
};


const dashboardLight = "/assets/icons/sidebar/light/home.svg";
const dashboardDark = "/assets/icons/sidebar/dark/home.svg";
const clubLight = "/assets/icons/sidebar/light/clubs.svg";
const clubDark = "/assets/icons/sidebar/dark/club.svg";
const eventLight = "/assets/icons/sidebar/light/events.svg";
const eventDark = "/assets/icons/sidebar/dark/events.svg";
const bookingLight = "/assets/icons/sidebar/light/bookings.svg";
const bookingDark = "/assets/icons/sidebar/dark/bookings.svg";
const menuManagementLight = "/assets/icons/sidebar/light/mm-light.svg";
const logout = "/assets/icons/sidebar/logout.svg";
const closeBookingLight = "/assets/icons/sidebar/light/close-booking-light.svg";
const closeBookingDark = "/assets/icons/sidebar/dark/close-booking-dark.svg";
const Dropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const [activeRoutes, setActiveRoutes] = useState();

  const { locationname } = useLocation();
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const match = ["/manager/drinks-management", "/manager/smokes-management"].some((route) =>
      location.pathname.startsWith(route)
    );
    if (match) {
      setIsOpen(true);
    }
    setActiveRoutes(match);
  }, [location.pathname]);
  const { pathname } = useLocation();
  return (
    <div
      className={`font-body po-dropdown position-relative p-3 ${activeRoutes ? "po-dropdown--solid mb-2" : null
        }  ${isOpen ? "po-dropdown--solid mb-2" : ""}`}
    >
      <div
        className="d-flex justify-content-between align-items-center cursor-pointer"
        onClick={handleClick}
      >
        <div className="po-w-10">
          <img
            src={`${isOpen ? menuManagementLight : menuManagementLight}`}
            alt="dashboard-icon"
            width="15"
            height="15"
            className="d-block"
          />
        </div>

        <div className="po-w-90 d-flex align-items-center justify-content-between">
          <div
            className={`font-medium text-md ${isOpen ? " text-white" : " text-white"
              }`}
          >
            Manage Menu
          </div>
          <div>
            <i
              className={`bi  ${isOpen
                ? "bi-chevron-up text-white"
                : "bi-chevron-down text-white"
                }`}
            ></i>
          </div>
        </div>
      </div>
      <motion.div
        className="dropdown"
        animate={isOpen ? "open" : "closed"}
        variants={{
          open: { opacity: 1, height: "auto" },
          closed: { opacity: 0, height: 0 },
        }}
        transition={{ duration: 0.4 }}
      >
        {isOpen && (
          <motion.ul
            className=" text-white ms-4 mt-1"
            animate={isOpen ? "open" : "closed"}
            variants={{
              open: { opacity: 1, height: "auto" },
              closed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.7 }}
          >
            <li>
              <NavLink
                // exact
                to="/manager/drinks-management"
                // activeClassName="active"
                className={`text-white text-sm ${
                  pathname === "/manager/drinks-management" ? "c-golden" : null
                }`}
              >
                Drinks Category
              </NavLink>
            </li>
            <li>
              <NavLink
                // exact
                to="/manager/smokes-management"
                // activeClassName="active"
                className={`text-white text-sm ${
                  pathname === "/manager/smokes-management" ? "c-golden" : null
                }`}
              >
                Smokes Category
              </NavLink>
            </li>

          </motion.ul>
        )}
      </motion.div>
    </div>
  );
};

const NormalItems = ({ itemName, itemLink, iconDark, iconLight }) => {
  const { pathname } = useLocation();
  return (
    <>
      <Link to={itemLink}>
        <div
          className={`d-flex align-items-center po-dropdown p-3 font-body ${pathname === itemLink ? "po-dropdown--solid" : null
            }`}
        >
          <div className="po-w-10 me-1">
            <img
              src={iconLight}
              alt="dashboard-icon"
              width="15"
              height="15"
              className="d-block"
            />
          </div>
          <div className="po-w-90 font-medium text-sm  text-white">
            {itemName}
          </div>
        </div>
      </Link>
    </>
  );
};

const Sidebar = () => {
  const [showModal, setShowModal] = useState(false);
  const handleButtonClick = () => setShowModal(true);

  return (
    <>
      <motion.aside
        className="po-sidebar px-3 pt-9 position-relative d-flex flex-column justify-content-between overflow-auto"
      >
        <ul>
          <li>
            <div className="po-sidebar__logo m-auto pb-9">
              <Link to="/dashboard">
                <img
                  src="/assets/logos/party-one-new.svg"
                  alt="dashboard logo"
                  className="img-fluid"
                />
              </Link>
            </div>
          </li>
          <li className="mb-2">
            <NormalItems
              itemName="Dashboard"
              itemLink="/manager"
              iconDark={dashboardDark}
              iconLight={dashboardLight}
            />
          </li>
          <li className="mb-2">
            <NormalItems
              itemName="Bookings"
              itemLink="/manager/booking-management"
              iconDark={bookingDark}
              iconLight={bookingLight}
            />
          </li>
          <li className="mb-2">
            <NormalItems
              itemName="Events"
              itemLink="/manager/events-management"
              iconDark={eventDark}
              iconLight={eventLight}
            />
          </li>
          <li className="mb-2">
            <NormalItems
              itemName="Non-Operational Days"
              itemLink="/manager/close-booking"
              iconDark={closeBookingDark}
              iconLight={closeBookingLight}
            />
          </li>
          <li className="mb-2">
            <Dropdown />
          </li>
          <li className="mb-2">
            <NormalItems
              itemName="Edit Club Details"
              itemLink={`/manager/edit-club-details/${localStorage.getItem('club_id')}`}
              // "/manager/club-details"
              // /manager/edit-club-details
              iconDark={clubDark}
              iconLight={clubLight}
            />
          </li>

        </ul>

        <div className="po-sidebar__bottom  font-body p-3">
          <div
            className="bg-transparent border-0 text-white font-body font-medium d-flex align-items-center gap-2 shadow-none mb-4 cursor-pointer"
            onClick={handleButtonClick}
          >
            <img
              src={logout}
              alt="logout"
              width="15"
              height="15"
              className="d-block"
            />
            <span>Logout</span>
          </div>
          <h4 className="text-uppercase font-semibold text-sm mb-0">
            Party One
          </h4>
          <h5 className="font-semibold text-lg text-lg-xl">Club Manager</h5>
        </div>
      </motion.aside>
      <Logout
        showModal={showModal}
        setShowModal={setShowModal}
        contents={contents}
        logout="true"
      />
    </>
  );
};

export default Sidebar;
