import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Link, useSearchParams } from "react-router-dom";
import { NavLink, useLocation } from "react-router-dom";
import Logout from "../Components/Global/Popup/ConfirmModal";
const contents = {
  title: "Confirm Logout",
  permission: "Are you sure you want to log out?",
};

const dashboardLight = "/assets/icons/sidebar/light/new/dashboard.svg";
const dashboardDark = "/assets/icons/sidebar/dark/home.svg";
const clubLight = "/assets/icons/sidebar/light/new/clubs.svg";
const clubDark = "/assets/icons/sidebar/dark/club.svg";
const eventLight = "/assets/icons/sidebar/light/new/events.svg";
const eventDark = "/assets/icons/sidebar/dark/events.svg";
// const adsLight = "/assets/icons/sidebar/light/adds-icon-light.svg";
// const adsDark = "/assets/icons/sidebar/dark/adds-icon-dark.svg";
const membershipsLight = "/assets/icons/sidebar/light/new/vip-plan.svg";
const membershipsDark = "/assets/icons/sidebar/dark/vip-plan.svg";
const bookingLight = "/assets/icons/sidebar/light/new/bookings.svg";
const bookingDark = "/assets/icons/sidebar/dark/bookings.svg";
// const locationLight = "/assets/icons/sidebar/light/new/location.svg";
const logout = "/assets/icons/sidebar/light/new/logout.svg";
const userLight = "/assets/icons/sidebar/light/new/users.svg";
const userDark = "/assets/icons/sidebar/dark/icons-user-dark.png";
// const campaignsDark = "/assets/icons/sidebar/dark/campaigns-icon-dark.png";
const campaignsLight = "/assets/icons/sidebar/light/new/cms.svg";
const notificationsLight = "/assets/icons/sidebar/light/new/notifications.svg";
const bellIcon = "/assets/icons/bellicon.svg";

const CmsUpload = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const [activeRoutes, setActiveRoutes] = useState();

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const match = ['/create-video', '/plans', '/country', '/states', '/city'].some((route) =>
      location.pathname.startsWith(route)
    );
    if (match) {
      setIsOpen(true);
    }
    setActiveRoutes(match);
  }, [location.pathname]);

  const { pathname } = useLocation();
  return (
    <div
      className={`font-body po-dropdown position-relative p-2 ${activeRoutes ? "po-dropdown--solid mb-2" : null
        }  ${isOpen ? "po-dropdown--solid mb-2" : ""}`}
    >
      <div
        className="d-flex justify-content-between align-items-center cursor-pointer"
        onClick={handleClick}
      >
        <div className="po-w-10 me-2 me-lg-1">
          <img
            src={`${isOpen ? campaignsLight : campaignsLight}`}
            alt="dashboard-icon"
            width="15"
            height="15"
            className="d-block"
          />
        </div>

        <div className="po-w-90 d-flex align-items-center justify-content-between">
          <div
            className={`font-medium text-sm ${isOpen ? " text-white" : " text-white"
              }`}
          >
            CMS
          </div>
          <div>
            <i
              className={`bi text-white ${isOpen ? "bi-chevron-up" : "bi-chevron-down"
                }`}
            ></i>
          </div>
        </div>
      </div>
      <motion.div
        className="dropdown"
        animate={isOpen ? "open" : "closed"}
        variants={{
          open: { opacity: 1, height: "auto" },
          closed: { opacity: 0, height: 0 },
        }}
        transition={{ duration: 0.4 }}
      >
        {isOpen && (
          <motion.ul
            className=" text-white ms-4 mt-1"
            animate={isOpen ? "open" : "closed"}
            variants={{
              open: { opacity: 1, height: "auto" },
              closed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.7 }}
          >
            <li>
              <NavLink
                // exact
                to="/create-video"
                // activeClassName="active"
                className={`text-white text-sm d-block ${pathname.includes("/create-video") ? "c-golden" : null
                  }`}
              >
                Background Video
              </NavLink>
            </li>
            <li>
              <NavLink
                // exact
                to="/plans"
                // activeClassName="active"
                className={`text-white text-sm d-block ${pathname.includes("/plans") ? "c-golden" : null
                  }`}
              >
                Membership Plans
              </NavLink>
            </li>
            <li>
              <NavLink
                // exact
                to="/country"
                // activeClassName="active"
                className={`text-white text-sm d-block ${pathname === "/country" ? "c-golden" : null
                  }`}
              >
                Country
              </NavLink>
            </li>
            <li>
              <NavLink
                // exact
                to="/states"
                // activeClassName="active"
                className={`text-white text-sm d-block ${pathname === "/states" ? "c-golden" : null
                  }`}
              >
                State
              </NavLink>
            </li>
            <li>
              <NavLink
                // exact
                to="/city"
                // activeClassName="active"
                className={`text-white text-sm d-block ${pathname === "/city" ? "c-golden" : null
                  }`}
              >
                City
              </NavLink>
            </li>
          </motion.ul>
        )}
      </motion.div>
    </div>
  );
}

const DropdownMemberships = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const [active, setActive] = useState(false);
  const [activeRoutes, setActiveRoutes] = useState();
  const [searchParams] = useSearchParams();
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const match = ["/memberships", "/active-memberships"].some((route) =>
      location.pathname.startsWith(route)
    );
    setActive(searchParams.get('active'));
    if (match) {
      setIsOpen(true);
    }
    setActiveRoutes(match);
  }, [location.pathname]);
  const { pathname } = useLocation();
  return (
    <div
      className={`font-body po-dropdown position-relative p-2 ${activeRoutes ? "po-dropdown--solid mb-2" : null
        }  ${isOpen ? "po-dropdown--solid mb-2" : ""}`}
    >
      <div
        className="d-flex justify-content-between align-items-center cursor-pointer"
        onClick={handleClick}
      >
        <div className="po-w-10 me-2 me-lg-1">
          <img
            src={`${isOpen ? membershipsLight : membershipsLight}`}
            alt="dashboard-icon"
            width="15"
            height="15"
            className="d-block"
          />
        </div>
        <div className="po-w-90 d-flex align-items-center justify-content-between">
          <div
            className={`font-medium text-sm ${isOpen ? " text-white" : " text-white"
              }`}
          >
            Memberships
          </div>
          <div>
            <i
              className={`bi text-white ${isOpen ? "bi-chevron-up" : "bi-chevron-down"
                }`}
            ></i>
          </div>
        </div>
      </div>
      <motion.div
        className="dropdown"
        animate={isOpen ? "open" : "closed"}
        variants={{
          open: { opacity: 1, height: "auto" },
          closed: { opacity: 0, height: 0 },
        }}
        transition={{ duration: 0.4 }}
      >
        {isOpen && (
          <motion.ul
            className=" text-white ms-4 mt-1"
            animate={isOpen ? "open" : "closed"}
            variants={{
              open: { opacity: 1, height: "auto" },
              closed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.7 }}
          >
            <li>
              <NavLink
                // exact
                to="/active-memberships"
                // activeClassName="active"
                className={`text-white text-sm d-block ${pathname.includes("/active-memberships") || active ? "c-golden" : null
                  }`}
              >
                Active Memberships
              </NavLink>
            </li>
            <li>
              <NavLink
                // exact
                to="/memberships"
                // activeClassName="active"
                className={`text-white text-sm d-block ${pathname.includes("/memberships") && !active ? "c-golden" : null
                  }`}
              >
                Membership Requests
              </NavLink>
            </li>
          </motion.ul>
        )}
      </motion.div>
    </div>
  );
};

const DropdownMarketing = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const [activeRoutes, setActiveRoutes] = useState();
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const match = ["/advertisement-manager", "/notifications", '/popups'].some((route) =>
      location.pathname.startsWith(route)
    );
    if (match) {
      setIsOpen(true);
    }
    setActiveRoutes(match);
  }, [location.pathname]);
  const { pathname } = useLocation();
  return (
    <div
      className={`font-body po-dropdown position-relative p-2 ${activeRoutes ? "po-dropdown--solid mb-2" : null
        }  ${isOpen ? "po-dropdown--solid mb-2" : ""}`}
    >
      <div
        className="d-flex justify-content-between align-items-center cursor-pointer"
        onClick={handleClick}
      >
        <div className="po-w-10 me-2 me-lg-1">
          <img
            src={`${isOpen ? notificationsLight : notificationsLight}`}
            alt="dashboard-icon"
            width="15"
            height="15"
            className="d-block"
          />
        </div>

        <div className="po-w-90 d-flex align-items-center justify-content-between">
          <div
            className={`font-medium text-sm ${isOpen ? " text-white" : " text-white"
              }`}
          >
            Marketing
          </div>
          <div>
            <i
              className={`bi text-white ${isOpen ? "bi-chevron-up" : "bi-chevron-down"
                }`}
            ></i>
          </div>
        </div>
      </div>
      <motion.div
        className="dropdown"
        animate={isOpen ? "open" : "closed"}
        variants={{
          open: { opacity: 1, height: "auto" },
          closed: { opacity: 0, height: 0 },
        }}
        transition={{ duration: 0.4 }}
      >
        {isOpen && (
          <motion.ul
            className=" text-white ms-4 mt-1"
            animate={isOpen ? "open" : "closed"}
            variants={{
              open: { opacity: 1, height: "auto" },
              closed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.7 }}
          >
            <li>
              <NavLink
                // exact
                to="/advertisement-manager"
                // activeClassName="active"
                className={`text-white text-sm d-block ${pathname.includes("/advertisement-manager") ? "c-golden" : null
                  }`}
              >
                Ads Manager
              </NavLink>
            </li>
            <li>
              <NavLink
                // exact
                to="/notifications"
                // activeClassName="active"
                className={`text-white text-sm d-block ${pathname.includes("/notifications") ? "c-golden" : null
                  }`}
              >
                Campaigns
              </NavLink>
            </li>
            <li>
              <NavLink
                // exact
                to="/popups"
                // activeClassName="active"
                className={`text-white text-sm d-block ${pathname.includes("/popups") ? "c-golden" : null
                  }`}
              >
                Offer Manager
              </NavLink>
            </li>
          </motion.ul>
        )}
      </motion.div>
    </div>
  );
};
const NormalItems = ({ itemName, itemLink, iconDark, iconLight }) => {
  const { pathname } = useLocation();
  return (
    <>
      <Link to={itemLink}>
        <div
          className={`d-flex align-items-center po-dropdown p-2 font-body ${pathname.includes(itemLink) ? "po-dropdown--solid" : null
            }`}
        >
          <div className="po-w-10 me-2 me-lg-1">
            <img
              src={iconLight}
              alt="dashboard-icon"
              width="15"
              height="15"
              className="d-block"
            />
          </div>
          <div className="po-w-90 font-medium text-sm text-capitalize text-white">
            {itemName}
          </div>
        </div>
      </Link>
    </>
  );
};

const Sidebar = () => {
  const [showModal, setShowModal] = useState(false);
  const handleButtonClick = () => setShowModal(true);
  return (
    <>
      <motion.aside
        className="po-sidebar px-3 pt-9 position-relative d-flex flex-column justify-content-between overflow-auto"
      >
        <ul>
          <li>
            <div className="po-sidebar__logo m-auto pb-9">
              <Link to="/dashboard">
                <img
                  src="/partyone-new-logo.svg"
                  alt="dashboard logo"
                  className="img-fluid"
                />
              </Link>
            </div>
          </li>
          <li className="mb-2">
            <NormalItems
              itemName="dashboard"
              itemLink="/dashboard"
              iconDark={dashboardDark}
              iconLight={dashboardLight}
            />
          </li>
          <li className="mb-2">
            <NormalItems
              itemName="bookings"
              itemLink="/bookings"
              iconDark={bookingDark}
              iconLight={bookingLight}
            />
          </li>
          <li className="mb-2">
            <DropdownMemberships />
          </li>
          {/* <li className="mb-2">
            <DropdownVIP />
          </li> */}
          <li className="mb-2">
            <NormalItems
              itemName="users"
              itemLink="/users"
              iconDark={userDark}
              iconLight={userLight}
            />
          </li>
          <li className="mb-2">
            <NormalItems
              itemName="clubs"
              itemLink="/clubs"
              iconDark={clubDark}
              iconLight={clubLight}
            />
          </li>
          <li className="mb-2">
            <NormalItems
              itemName="Events"
              itemLink="/events"
              iconDark={eventDark}
              iconLight={eventLight}
            />
          </li>
          {/* <li className="mb-2">
            <Dropdown />
          </li> */}

          {/* <li className="mb-2">
            <NormalItems
              itemName="Ads Manager"
              itemLink="/advertisement-manager"
              iconDark={adsDark}
              iconLight={adsLight}
            />
          </li> */}

          {/* <li className="mb-2">
            <NormalItems
              itemName="Campaigns"
              itemLink="/notifications"
              iconDark={campaignsDark}
              iconLight={campaignsLight}
            />
          </li> */}
          <li className="mb-2">
            <DropdownMarketing />
          </li>
          <li className="mb-2">
            <CmsUpload />
          </li>
          <li className="mb-2">
            <NormalItems
              itemName="Notifications"
              itemLink="/push-notification"
              iconLight={bellIcon}
            />
          </li>
        </ul>

        <div className="po-sidebar__bottom  font-body p-3">
          <div
            className="bg-transparent border-0 text-white font-body font-medium d-flex align-items-center gap-2 shadow-none mb-4 cursor-pointer"
            onClick={handleButtonClick}
          >
            <img
              src={logout}
              alt="logout"
              width="15"
              height="15"
              className="d-block"
            />
            <span>Logout</span>
          </div>
          <h4 className="text-uppercase font-semibold text-sm mb-0">
            Party One
          </h4>
          <h5 className="font-semibold text-lg text-lg-xl">Admin</h5>
        </div>
      </motion.aside>
      <Logout
        showModal={showModal}
        setShowModal={setShowModal}
        contents={contents}
        logout="true"
      />
    </>
  );
};

export default Sidebar;
