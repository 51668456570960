import React from "react";
import { useParams, useNavigate } from "react-router";
import {
  Row,
  Col,
  Table,
  Modal,
  Button,
  ModalBody,
  Tabs,
  Tab,
} from "react-bootstrap";
import ContentWrapper from "../../Components/Global/ContentWrapper";
import Layout from "../../Layout/Layout";
import { useEffect } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import db from "../../firebase";
import { useState } from "react";
import axios from "axios";
import moment from "moment";
import Ripple from "../../Components/Global/Buttons/Ripple";
import Zoom from "react-medium-image-zoom";
import SecondaryButton from "../../Components/Global/Buttons/SecondaryButton";
import { Link } from "react-router-dom";

const UserDetails = () => {
  const [showModal, setShowModal] = useState(false);
  const [showEditMembershipModal, setShowEditMembershipModal] = useState(false);
  const [ameystModal, setAmeystModal] = useState(false);
  const [images, setImages] = useState({
    frontId: null,
    backId: null,
    userPhoto: null,
  });
  const [cabinCrew, setCabinCrew] = useState({
    frontId: null,
    backId: null,
  });
  const params = useParams();
  const [kycDetails, setKycDetails] = useState({});
  const [oldMembershipDetails, setOldMembershipDetails] = useState([]);
  const [loadingMemberships, setLoadingMemberships] = useState(true);
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({});
  const [membershipDetails, setMembershipDetails] = useState({});
  const [BookingDetails, setBookingDetails] = useState({
    tableBookingDetails: [],
    eventBookingDetails: [],
    entryBookingDetails: [],
  });
  const wordLengths = [3, 4];
  // membership details pop up
  const [showMember, setShowMember] = useState(false);
  const memberHandleClose = () => setShowMember(false);
  const memberHandleShow = () => setShowMember(true);
  const handleClose = () => setShowModal(false);

  const userDetailsList = [
    {
      query: "Email",
      userResponse: userDetails.email,
    },
    {
      query: "Mobile No",
      userResponse:
        userDetails.contacts &&
        userDetails.contacts[0] &&
        userDetails.contacts[0].contact_no,
    },
    {
      query: "Whatsapp No",
      userResponse:
        userDetails.contacts &&
        userDetails.contacts[1] &&
        userDetails.contacts[1].contact_no,
    },
    {
      query: "Date of Birth",
      userResponse: userDetails.dob,
    },
    {
      query: "Gender",
      userResponse: userDetails.gender,
    },
    {
      query: "Home Country",
      userResponse: userDetails.homeCountry,
    },
    {
      query: "State",
      userResponse: userDetails.homeState,
    },
    {
      query: "Home City",
      userResponse: userDetails.homeCity,
    },
    {
      query: "Role",
      userResponse: userDetails && userDetails.role && userDetails.role.map((item, index) => {
        return (
          <span
            key={index}
            className={`m-1 d-inline-block text-md mb-0 text-nowrap font-normal text-white text-capitalize rounded px-3
                ${item.toLowerCase() === 'customer' ? "po-customer" : item.toLowerCase() === 'admin' ? "po-admin" : item.toLowerCase() === 'manager' ? "po-manager" : item.toLowerCase() === 'concierge' ? "po-concierge" : "po-influencer"}
              `}>{item}</span>
        )
      }),
    },
    {
      query: "Active Membership",
      userResponse: userDetails.activeMembership,
    },
    {
      query: "Registered On",
      userResponse: new Date(userDetails.registeredOn).toString(),
    },
    {
      query: "Are you a cabin crew member?",
      userResponse: userDetails.activeMembership === "Amethyst" ? "Yes" : "No",
    },
    {
      query: "Status",
      userResponse: userDetails.status,
    },

  ];

  // Membership request and Kyc     --Start
  const fetchUserKycDetail = async (status = "Approved") => {
    await getDocs(
      query(
        collection(db, "user_kyc"),
        where("user_id", "==", params.uid),
        where("status", "==", `${status}`)
      )
    )
      .then((response) => {
        response.docs.length > 0 &&
          response.docs &&
          setKycDetails(response.docs[0].data());
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    fetchUserKycDetail();
    params.uid && getUserDetails();
    getAllMembershipDetails();
    (async () => {
      try {
        const response = await getDocs(
          query(collection(db, "user_kyc"), where("user_id", "==", params.uid))
        );

        response.docs.length > 0 && setKycDetails(response.docs[0].data());
      } catch (err) {
        console.error(err);
      }
    })();
    (async () => {
      const { tableBookings, eventBookings, entryBookings, } = fetchUserBookings();
      const [tableResponse, eventResponse, entryResponse] = await Promise.all([
        tableBookings(),
        eventBookings(),
        entryBookings(),
      ]);
      setBookingDetails({
        ...BookingDetails,
        tableBookingDetails: tableResponse.docs,
        eventBookingDetails: eventResponse.docs,
        entryBookingDetails: entryResponse.docs,
      });
    })()
  }, []);

  const fetchUserBookings = () => {
    const tableBookings = async () => {
      const tableResponse = await getDocs(
        query(
          collection(db, "club_entry_bookings"),
          where("user.id", "==", params.uid),
          where("booking_type", "==", "table_booking")
        )
      );
      return tableResponse;
    };
    const eventBookings = async () => {
      const eventResponse = await getDocs(query(
        collection(db, "event_entry_bookings"),
        where("user.id", "==", params.uid)
      )
      );
      return eventResponse;
    };
    const entryBookings = async () => {
      const entryResponse = await getDocs(
        query(
          collection(db, "club_entry_bookings"),
          where("user.id", "==", params.uid),
          where("booking_type", "==", "club_entry_booking")
        )
      );
      return entryResponse;
    };

    return { tableBookings, eventBookings, entryBookings };
  };

  const getUserDetails = async () => {
    try {

      let responseUsers = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/v1/user?uid=${params.uid}`
      );
      const userResponse = await getDoc(doc(db, "users", params.uid));
      console.log(userResponse, "params.uidparams.uidparams.uid")
      const userInfo = userResponse.data();
      console.log(userResponse.data(), "userinfifiififif")
      if (responseUsers.data) {
        const data = responseUsers.data.data;
        const userDetail = {
          name: userInfo && userInfo.name,
          email: data && data.email,
          emailVerified: data && data.emailVerified,
          photoURL: userInfo && userInfo.profile_image,
          gender: userInfo && userInfo.gender,
          homeCity: userInfo && userInfo.home_city_name,
          homeCountry: userInfo && userInfo.home_country_name,
          homeState: userInfo && userInfo.home_state_name,
          dob: userInfo && userInfo.dob,
          contacts: userInfo && userInfo.contacts,
          activeMembership: userInfo && userInfo.active_membership_name,
          status: userInfo && userInfo.status,
          role: userInfo && userInfo.role,
          registeredOn: data && data.metadata && data.metadata.creationTime,
          lastSignInTime: data && data.metadata && data.metadata.lastSignInTime,
          registrationStatus: userInfo ? "Completed" : "InComplete",
          favorite_clubs:
            userInfo && userInfo.favorite_clubs && userInfo.favorite_clubs,
          socialHandle:
            userInfo && userInfo.socialHandle && userInfo.socialHandle,
          membershipCardNo: userInfo && userInfo.active_membership_card_no,
          membershipStartat: userInfo && userInfo.mebership_starts_at,
          membershipEndat: userInfo && userInfo.mebership_ends_at

        };
        console.log(userDetail, "userResponse")
        if (userInfo && userInfo.active_membership_id) {
          try {
            const fetchMembershipDetails = await getDoc(
              doc(db, "memberships", userInfo.active_membership_id)
            );
            setMembershipDetails(fetchMembershipDetails.data());
          } catch (err) { }
        }
        setUserDetails(userDetail);
      }
    } catch (err) {
      console.log(err, "Eeeeeee")

    }
  };
  const getAllMembershipDetails = async () => {
    setOldMembershipDetails([]);
    let queryforDataFetching = await getDocs(query(collection(db, "membership_requests"), where("user.id", "==", params.uid), orderBy("createdAt", "desc")));
    for (let index = 0; index < queryforDataFetching.docs.length; index++) {
      setOldMembershipDetails(prev => [...prev, queryforDataFetching.docs[index].data()]);
    }
    setLoadingMemberships(false);
  };

  const splitString = (input) => {
    let words = ""
    const threeLetters = input.substring(0, 3); // Get the first three letters
    const remainingLetters = input.substring(3); // Get the remaining letters
    [threeLetters, ...remainingLetters.match(/.{1,4}/g)].map(ele => words += ele + " ") // Split the remaining letters into groups of four
    return words
  };
  // }

  return (
    <Layout path="/users/user-details">
      <ContentWrapper additionalClass="p-5 font-body">
        <Row className="g-4">
          <Col xs={12}>
            <h2 className="color-heading font-body font-semibold text-lg text-lg-2xl pb-6 mb-0">
              User Details
            </h2>
            <figure className="d-flex align-items-center">
              {userDetails.photoURL ? (
                <img
                  className="po-userDetails__image"
                  src={userDetails.photoURL}
                  alt="Party One Member"
                />
              ) : (
                <div
                  style={{
                    height: "80px",
                    width: "80px",
                    backgroundColor: "#DEDEDE",
                  }}
                  className="bg-gray rounded-circle overflow-hidden d-flex align-items-center justify-content-center"
                >
                  <i className="bi bi-person-fill text-5xl"></i>
                </div>
              )}

              <figcaption className="color-lightblue font-semibold text-md text-lg-2xl mb-0 pl-3 font-body">
                {userDetails.name}
              </figcaption>
            </figure>
          </Col>
          {userDetailsList &&
            userDetailsList.map((item, index) => {
              if (item.userResponse) {
                return (
                  <Col
                    xs={12}
                    md={6}
                    lg={3}
                    className="pt-4 font-body"
                    key={index}
                  >
                    {(item.query.toLowerCase() === "email") || (item.query.toLowerCase() === "mobile no") || (item.query.toLowerCase() === "whatsapp no") ?
                      <div>
                        <h4 className="color-lightestblue font-normal text-xs font-body mb-1">
                          {item.query}
                          {userDetails &&
                            userDetails.emailVerified !== undefined &&
                            item &&
                            item.query &&
                            item.query.toLowerCase() === "email" ? (
                            <span
                              className={` text-white ms-2 px-2 py-1 rounded ${Boolean(userDetails.emailVerified)
                                ? "bg-success"
                                : "bg-secondary"
                                }`}
                            >
                              {Boolean(userDetails.emailVerified)
                                ? "Verified"
                                : "Not Verified"}
                            </span>
                          ) : (
                            ""
                          )}
                        </h4>
                        <a
                          className="color-lightblue font-semibold text-md text-break text-lg-lg mb-0 font-body"
                          href={`${item.query.toLowerCase() === "email" ? "mailto:" + item.userResponse : item.query.toLowerCase() === "mobile no" ? "tel:" + item.userResponse : "https://api.whatsapp.com/send?phone=" + item.userResponse}`}
                          target={`${item.query.toLowerCase() === "whatsapp no" ? "_blank" : "_self"}`}
                          rel="noreferrer"
                        >
                          {item.userResponse}
                        </a>
                      </div>
                      : <div>
                        <h4 className="color-lightestblue font-normal text-xs mb-0 font-body mb-1">
                          {item.query}
                        </h4>
                        {item.query === "Active Membership" ? (
                          <>
                            <div
                              style={{
                                backgroundColor: `${item.userResponse === "Amethyst"
                                  ? "#955196"
                                  : item.userResponse === "Solitaire"
                                    ? "#dd5182"
                                    : item.userResponse === "Platinum"
                                      ? "#FA896B"
                                      : item.userResponse === "Gold"
                                        ? "#ff6e54"
                                        : item.userResponse === "Silver"
                                          ? "#13DEB9"
                                          : null
                                  }`,
                              }}
                              className="rounded d-inline-block px-3 text-white"
                            >
                              {item.userResponse}
                            </div>
                          </>
                        ) : item.query === "Status" &&
                          item.userResponse === "Active" ? (
                          <p className="text-success bold">
                            {item.userResponse}
                          </p>
                        ) :
                          <p
                            className="color-lightblue font-semibold text-md text-break text-lg-lg mb-0 font-body"
                            key={index}
                          >
                            {item.userResponse}
                          </p>
                        }
                      </div>}
                  </Col>
                );
              } else return "";
            })}
          {userDetails.socialHandle &&
            <Col md={3}>
              <h4 className="color-lightestblue font-normal text-sm mb-0 font-body mb-1">Social media links</h4>
              <ul>
                {userDetails.socialHandle.instagram &&
                  <div className="mt-1">
                    <li><i className="bi bi-instagram text-lg"></i>
                      <a
                        href={userDetails.socialHandle.instagram}
                        className="p-2 text-lg text-dark"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {userDetails.socialHandle.instagram}
                      </a>
                    </li>
                  </div>

                }
                {userDetails.socialHandle.facebook &&
                  <div className="mt-1">
                    <li><i className="bi bi-facebook text-lg"></i>
                      <a
                        href={"/" + userDetails.socialHandle.facebook}
                        className="p-2 text-lg text-dark"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {userDetails.socialHandle.facebook}
                      </a>
                    </li>
                  </div>}
                {userDetails.socialHandle.twitter &&
                  <div className="mt-1">
                    <li><i className="bi bi-twitter text-lg"></i>
                      <a
                        href={"/" + userDetails.socialHandle.twitter}
                        className="p-2 text-lg text-dark"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {userDetails.socialHandle.twitter}
                      </a>
                    </li>
                  </div>}
              </ul>
            </Col>
          }
          {userDetails && userDetails.favorite_clubs && userDetails.favorite_clubs.length ? userDetails.favorite_clubs.every((ele) => ele === "") ? <></> :
            <Col md={3}>
              <h4 className="color-lightestblue font-normal text-sm mb-0 font-body mb-1">Favourite Clubs</h4>
              <ul>
                {userDetails && userDetails.favorite_clubs && userDetails.favorite_clubs.map((ele, index) => (
                  <li key={index}>{index + 1} . {ele ? ele : "Not added"}</li>
                ))}
              </ul>
            </Col> : ""}
        </Row>
      </ContentWrapper>

      {/* Bookings : Start */}
      <ContentWrapper additionalClass="mt-4 p-4">
        <Row className="g-4">
          <Col xs={12}>
            <h2 className="color-heading font-body font-semibold text-lg text-lg-2xl mb-0">
              Bookings
            </h2>
            {/* Tabs : Start */}
            <Tabs
              defaultActiveKey="TableBookings"
              id="justify-tab-example"
              className="my-3 users-tabs border-0"
            >
              <Tab eventKey="TableBookings" title="Table Bookings">
                <div>
                  {BookingDetails.tableBookingDetails.length > 0 ? (
                    <div className="p-2">
                      <Table bordered hover>
                        <thead>
                          <tr>
                            <th className="p-2">S.No</th>
                            <th className="p-2">Booking id</th>
                            <th className="p-2">Club name</th>
                            <th className="p-2">Booked on</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {BookingDetails &&
                            BookingDetails.tableBookingDetails.map(
                              (ele, index) => {
                                let tableBookingData = ele.data();
                                return (
                                  <tr key={index}>
                                    <td className="p-2 align-middle">
                                      {index + 1}
                                    </td>
                                    <td className="align-middle">
                                      {tableBookingData.booking_id}
                                    </td>
                                    <td className="align-middle">
                                      {tableBookingData.club_name}
                                    </td>
                                    <td className="align-middle">
                                      {moment(
                                        tableBookingData.createdAt.seconds *
                                        1000
                                      ).format("DD-MM-YYYY")}
                                    </td>
                                    <td>
                                      <div className="py-1">
                                        <SecondaryButton
                                          name="View"
                                          link={`/bookings/table-details/${ele.id}`}
                                          target="_blank"
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                        </tbody>
                      </Table>
                    </div>
                  ) : (
                    <p className="text-lg bold text-center">
                      No table bookings
                    </p>
                  )}
                </div>
              </Tab>
              <Tab eventKey="EventBookings" title="Event Bookings">
                <div>
                  {BookingDetails.eventBookingDetails.length > 0 ? (
                    <div className="p-2">
                      <Table bordered hover>
                        <thead>
                          <tr>
                            <th className="p-2">S.No</th>
                            <th className="p-2">Booking id</th>
                            <th className="p-2">Club name</th>
                            <th className="p-2">Booked on</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {BookingDetails &&
                            BookingDetails.eventBookingDetails.map(
                              (ele, index) => {
                                let eventBookingData = ele.data();
                                return (
                                  <tr key={index}>
                                    <td className="p-2 align-middle">
                                      {index + 1}
                                    </td>
                                    <td className="align-middle">
                                      {eventBookingData.booking_id}
                                    </td>
                                    <td className="align-middle">
                                      {eventBookingData.club_name}
                                    </td>
                                    <td className="align-middle">
                                      {moment(
                                        eventBookingData.createdAt.seconds *
                                        1000
                                      ).format("DD-MM-YYYY")}
                                    </td>
                                    <td className="align-middle">
                                      <div className="py-1">
                                        <SecondaryButton
                                          name="View"
                                          link={`/bookings/event-details/${ele.id}`}
                                          target="_blank"
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                        </tbody>
                      </Table>
                    </div>
                  ) : (
                    <p className="text-lg bold text-center">
                      No event bookings
                    </p>
                  )}
                </div>
              </Tab>
              <Tab eventKey="EntryBookings" title="Entry Bookings">
                <div>
                  {BookingDetails.entryBookingDetails.length > 0 ? (
                    <div className="p-2">
                      <Table bordered hover>
                        <thead>
                          <tr>
                            <th className="p-2">S.No</th>
                            <th className="p-2">Booking id</th>
                            <th className="p-2">Club name</th>
                            <th className="p-2">Booked on</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {BookingDetails.entryBookingDetails.map(
                            (ele, index) => {
                              let bookingData = ele.data();
                              return (
                                <tr key={index}>
                                  <td className="p-2 align-middle">
                                    {index + 1}
                                  </td>
                                  <td className="align-middle">
                                    {bookingData.booking_id}
                                  </td>
                                  <td className="align-middle">
                                    {bookingData.club_name}
                                  </td>
                                  <td className="align-middle">
                                    {moment(
                                      bookingData.createdAt.seconds * 1000
                                    ).format("DD-MM-YYYY")}
                                  </td>
                                  <td className="align-middle">
                                    <div className="py-1">
                                      <SecondaryButton
                                        name="View"
                                        link={`/bookings/entry-details/${ele.id}`}
                                        target="_blank"
                                      />
                                    </div>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </Table>
                    </div>
                  ) : (
                    <p className="text-lg bold text-center">
                      No entry bookings
                    </p>
                  )}
                </div>
              </Tab>
            </Tabs>
            {/* Tabs : End */}
          </Col>
        </Row>
      </ContentWrapper>
      {/* Bookings : End */}
      {/* KYC : Start */}
      {
        Object.keys(kycDetails).length === 0 ? (
          <ContentWrapper additionalClass="p-5 mt-5">
            <p className="text-center bold">No KYC Details</p>
          </ContentWrapper>
        ) : (
          <ContentWrapper additionalClass="p-5 mt-5">
            <h5>Active membership form details</h5>
            <Row className="g-4">
              {/* name */}
              <Col xs={6} xl={3}>
                <p className="color-lightestblue font-normal text-sm mb-0 font-body">
                  User photo
                </p>
                <div className="d-flex align-items-center overflow-hidden rounded" style={{ height: "160px", width: "160px" }}>                  <Zoom>
                  <img
                    className="rounded img-fluid"
                    src={kycDetails && kycDetails.user_image}

                    alt="user profile"
                  />
                </Zoom>
                </div>
              </Col>
              <Col xs={6} xl={3}>
                <p className="color-lightestblue font-normal text-sm mb-0 font-body">
                  Name
                </p>
                <div className="d-flex align-items-center">
                  <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                    {kycDetails && kycDetails.name}
                  </p>
                </div>
              </Col>

              {/* nationality */}

              <Col xs={6} xl={3}>
                <p className="color-lightestblue font-normal text-sm mb-0 font-body">
                  Nationality
                </p>
                <div className="d-flex align-items-center">
                  <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                    {kycDetails && kycDetails.nationalty}
                  </p>
                </div>
              </Col>

              {/* frequency clubing */}

              <Col xs={6} xl={3}>
                <p className="color-lightestblue font-normal text-sm mb-0 font-body">
                  Frequency Clubbing
                </p>
                <div className="d-flex align-items-center">
                  <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                    {kycDetails && kycDetails.frequency_of_clubbing}
                  </p>
                </div>
              </Col>

              {/* country */}

              <Col xs={6} xl={3}>
                <p className="color-lightestblue font-normal text-sm mb-0 font-body">
                  Home Country
                </p>
                <div className="d-flex align-items-center">
                  <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body text-capitalize">
                    {kycDetails && kycDetails.home_country_name}
                  </p>
                </div>
              </Col>

              {/* state */}

              <Col xs={6} xl={3}>
                <p className="color-lightestblue font-normal text-sm mb-0 font-body">
                  Home State
                </p>
                <div className="d-flex align-items-center">
                  <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body text-capitalize">
                    {kycDetails && kycDetails.home_state_name}
                  </p>
                </div>
              </Col>

              {/* city */}

              <Col xs={6} xl={3}>
                <p className="color-lightestblue font-normal text-sm mb-0 font-body">
                  Home City
                </p>
                <div className="d-flex align-items-center">
                  <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body text-capitalize">
                    {kycDetails && kycDetails.home_city_name}
                  </p>
                </div>
              </Col>

              {/* status */}

              <Col xs={6} xl={3}>
                <p className="color-lightestblue font-normal text-sm mb-0 font-body">
                  Mailing Address
                </p>
                <div className="d-flex align-items-center">
                  <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                    {kycDetails && kycDetails.permanent_address}
                  </p>
                </div>
              </Col>

              {/* KYC Applied on */}

              <Col xs={6} xl={3}>
                <p className="color-lightestblue font-normal text-sm mb-0 font-body">
                  KYC Applied On
                </p>
                <div className="d-flex align-items-center">
                  <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                    {kycDetails &&
                      kycDetails.createdAt &&
                      moment(kycDetails.createdAt.seconds * 1000).format(
                        "DD MMM YYYY"
                      )}
                  </p>
                </div>
              </Col>

              {/* KYC Status */}

              <Col xs={6} xl={3}>
                <p className="color-lightestblue font-normal text-sm mb-0 font-body">
                  KYC Status
                </p>
                <div className="d-flex align-items-center">
                  <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                    {kycDetails && kycDetails.status}
                  </p>
                </div>
              </Col>
              {userDetails.mebership_starts_at &&
                < Col xs={6} xl={3}>
                  <p className="color-lightestblue font-normal text-sm mb-0 font-body">
                    Current Membership Start Date
                  </p>
                  <div className="d-flex align-items-center">
                    <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                      {userDetails &&
                        userDetails.mebership_starts_at &&
                        moment(
                          new Date(userDetails.mebership_starts_at.seconds * 1000).toISOString()
                        ).format("DD MMM YYYY")}
                    </p>
                  </div>
                </Col>
              }

              {/* Current membership end date */}

              {userDetails.mebership_ends_at &&
                <Col xs={6} xl={3}>
                  <p className="color-lightestblue font-normal text-sm mb-0 font-body">
                    Current Membership End Date
                  </p>
                  <div className="d-flex align-items-center">
                    <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                      {userDetails &&
                        userDetails.mebership_ends_at &&
                        moment(
                          new Date(userDetails.mebership_ends_at.seconds * 1000).toISOString()
                        ).format("DD MMM YYYY")}
                    </p>
                  </div>
                </Col>}

              {/* Current membership
              {/* Cabin Crew */}

              <Col xs={6} xl={3}>
                <p className="color-lightestblue font-normal text-sm mb-0 font-body">
                  Cabin crew
                </p>
                <div className="d-flex align-items-center">
                  <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                    {userDetails.activeMembership === "Amethyst" ? "Yes" : "No"}
                  </p>
                </div>
              </Col>

              {/*Cabin crew Images  */}

              {membershipDetails && membershipDetails.createdAt && membershipDetails.createdAt.seconds && <Col xs={6} xl={3}>
                <p className="color-lightestblue font-normal text-sm mb-0 font-body">
                  Membership Requested date
                </p>
                <div className="d-flex align-items-center">
                  <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                    {membershipDetails && membershipDetails.createdAt && membershipDetails.createdAt.seconds && moment(membershipDetails.createdAt.seconds * 1000).format("DD MMM YYYY")}
                  </p>
                </div>
              </Col>}
              {membershipDetails && ((membershipDetails.cabin_crew_front_image_id) || (membershipDetails.cabin_crew_back_image_id)) &&
                <Col xs={6} xl={3}>
                  <p className="color-lightestblue font-normal text-sm mb-0 font-body">
                    Cabin crew
                  </p>
                  <div className="d-flex align-items-center">
                    <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                      <Button
                        variant="warning"
                        className="text-warning bold bg-white border-0 ps-0"
                        onClick={(e) => setAmeystModal(true)}
                      >
                        Click Here
                      </Button>
                    </p>
                  </div>
                </Col>
              }

              {/* Ameytst Plan */}
              {/*
              {membershipDetails.cabin_crew &&
                <Col xs={6} xl={3}>
                  <p className="color-lightestblue font-normal text-sm mb-0 font-body">
                    Ameythest Plan
                  </p>
                  <p className="color-lightblue font-semibold text-md text-lg-lg mb-0 font-body text-wrap text-break">
                    {membershipDetails.membership_plan_id === "amethyst-one-year" ? "12 Months" : "3 Months"}
                  </p>
                </Col>} */}
            </Row>
          </ContentWrapper>
        )
      }

      {/* KYC  */}

      <ContentWrapper additionalClass="p-5 mt-5">
        <h2 className="color-heading font-body font-semibold text-lg text-lg-2xl pb-6 mb-0">
          ID Verification
        </h2>
        <Row>
          <Col xs={4} xl={3}>
            <div>
              <p className="color-lightestblue font-normal text-sm mb-0 font-body">
                Government ID Number
              </p>
              <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                {kycDetails && kycDetails.government_id_number}
              </p>
            </div>
          </Col>
          <Col xs={4} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              Government ID
            </p>
            <div className="d-flex align-items-center">
              <div className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                <Button
                  variant="warning"
                  className="text-warning bold bg-white border-0 ps-0"
                  onClick={(e) => setShowModal(true)}
                >
                  Click Here
                </Button>
              </div>
            </div>
          </Col>
          {kycDetails && kycDetails.user_image && <Col xs={4} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              User Image
            </p>
            <Zoom>
              <img
                className="rounded po-objectFit--cover po-objectPosition"
                src={kycDetails && kycDetails.user_image}
                width={80}
                height={60}
                alt="user profile"
              />
            </Zoom>
          </Col>}
        </Row>
      </ContentWrapper>

      {userDetails.activeMembership && <ContentWrapper additionalClass="mt-5 p-5">
        <h5>Current Membership Card</h5>
        <article className={`membership-card  background-position-center-center background-repeat-norepeat background-size-cover overflow-hidden  p-8 d-flex flex-column justify-content-between ${userDetails.activeMembership === "Gold" ? "text-dark" : userDetails.activeMembership === "Silver" ? "text-dark" : userDetails.activeMembership === "Amethyst" ? "color-ternary" : "text-light"}`}
          style={{
            backgroundImage: `url(${membershipDetails && membershipDetails.display_image && membershipDetails.display_image})`, height: '280px', width: '450px', borderRadius: "30px"
          }}>
          <h4 className="text-3xl">{userDetails && userDetails.name}</h4>
          <div>
            <p className="text-lg mb-0">{userDetails && userDetails.membershipCardNo && splitString(userDetails.membershipCardNo)}</p>
            <p className="mb-0 mt-3 text-2xl">{userDetails.activeMembership}</p>
            {userDetails && <p className="mb-1 text-xxs">Member since {userDetails && userDetails.membershipStartat ? moment(userDetails.membershipStartat && userDetails.membershipStartat.seconds * 1000).format("DD MMM YYYY") : ""}</p>}
            {userDetails.membershipEndat && <p className="mb-1 text-xxs">Valid till {userDetails && userDetails.membershipEndat ? moment(userDetails.membershipEndat && userDetails.membershipEndat.seconds * 1000).format("DD MMM YYYY") : ""}</p>}
          </div>
        </article>
      </ContentWrapper>}
      {/* KYC : End */}
      {/* Membership Requests : Start */}
      <ContentWrapper additionalClass="p-4 mt-4">
        <Row className="g-4">
          <Col xs={12}>
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="color-heading font-body font-semibold text-lg text-lg-2xl">
                Membership Requests
              </h2>
              {!loadingMemberships && !oldMembershipDetails.some(item => item.status === "Pending") && <Link
                to={`/create-membership?user_id=${params.uid}`}
                target="_blank"
                className={`text-black text-md font-body font-semibold cursor-pointer background-gradient  py-3 px-8 radius-105 border-0 d-inline-block`}
              >
                <i className={`bi bi-plus-circle inline-block  me-2 mt-2 text-black text-xl`}></i>
                Add membership request
              </Link>}
            </div>
          </Col>
          {membershipDetails && membershipDetails.name && (
            <Col xs={4}>
              <h4 className="color-lightestblue font-normal text-xs mb-0 font-body">
                Active membership
              </h4>
              <div className="d-flex align-items-center mt-2 mb-3">
                <i
                  className="bi bi-info-circle-fill text-xl color-yellow1 me-2 cursor-pointer"
                  onClick={memberHandleShow}
                ></i>
                <p className="color-lightblue font-semibold text-md text-break text-lg-lg mb-1 font-body">
                  {membershipDetails.name}
                </p>
              </div>
            </Col>
          )}
        </Row>
        {!loadingMemberships ? (
          oldMembershipDetails.length > 0 ? (
            <Row className="">
              <Col xs={12}>
                <Table hover responsive>
                  <thead>
                    <tr className="background-gray10">
                      <th>
                        <p className="text-nowrap text-xs text-xl-md text-uppercase  color-black2 mb-0 py-2">
                          s. no.
                        </p>
                      </th>
                      <th>
                        <p className="text-nowrap text-xs text-xl-md text-uppercase color-black2 mb-0 py-2">
                          Membership Plan
                        </p>
                      </th>
                      <th>
                        <p className="text-nowrap text-xs text-xl-md text-uppercase color-black2 mb-0 py-2">
                          Requested At
                        </p>
                      </th>
                      <th>
                        <p className="text-nowrap text-xs text-xl-md text-uppercase color-black2 mb-0 py-2">
                          Status
                        </p>
                      </th>
                      <th>
                        <p className="text-nowrap text-xs text-xl-md text-uppercase color-black2 mb-0 py-2">
                          Start Date
                        </p>
                      </th>
                      <th>
                        <p className="text-nowrap text-xs text-xl-md text-uppercase color-black2 mb-0 py-2">
                          End Date
                        </p>
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {oldMembershipDetails &&
                      oldMembershipDetails.length > 0 &&
                      oldMembershipDetails.map((ele, index) => {
                        return (
                          <tr key={index}>
                            <td className="align-middle">
                              <p className="text-md mb-0 color-lightblue font-semibold ps-3">
                                {index + 1}
                              </p>
                            </td>
                            <td className="align-middle">
                              <div
                                style={{
                                  backgroundColor: `${ele.membership_name === "Amethyst"
                                    ? "#955196"
                                    : ele.membership_name === "Solitaire"
                                      ? "#dd5182"
                                      : ele.membership_name === "Platinum"
                                        ? "#FA896B"
                                        : ele.membership_name === "Gold"
                                          ? "#ff6e54"
                                          : ele.membership_name === "Silver"
                                            ? "#13DEB9"
                                            : null
                                    }`,
                                }}
                                className="rounded d-inline-block px-3 text-white"
                              >
                                {ele.membership_name}
                              </div>
                            </td>
                            <td className="align-middle">
                              <p className="text-md mb-0 color-lightblue font-semibold">
                                {ele &&
                                  ele.createdAt &&
                                  ele.createdAt.seconds &&
                                  moment(
                                    new Date(
                                      ele.createdAt.seconds * 1000
                                    ).toISOString()
                                  ).format("DD MMM YYYY")}
                              </p>
                            </td>
                            <td className="align-middle">
                              <div className="d-flex align-items-center justify-content-start">
                                {ele.status === "Pending" ? (
                                  <Ripple color="#FFAE1F" />
                                ) : (
                                  <div
                                    style={{
                                      height: "10px",
                                      width: "10px",
                                      backgroundColor: `${ele.status === "Approved"
                                        ? "#13DEB9"
                                        : ele.status === "Rejected"
                                          ? "#FA896B"
                                          : null
                                        }`,
                                    }}
                                    className="rounded-circle"
                                  ></div>
                                )}

                                <div className="ms-2 color-lightblue font-semibold">
                                  {ele && ele.status}
                                </div>

                              </div>
                            </td>
                            {ele &&
                              ele.starts_at &&
                              ele.starts_at.seconds ?
                              (<td className="align-middle">
                                {ele &&
                                  ele.starts_at &&
                                  ele.starts_at.seconds &&
                                  moment(
                                    new Date(
                                      ele.starts_at.seconds * 1000
                                    ).toISOString()
                                  ).format("DD MMM YYYY")}
                              </td>) : <td>No Start Date</td>
                            }

                            {ele &&
                              ele.ends_at &&
                              ele.ends_at.seconds ?
                              (<td className="align-middle">
                                {ele &&
                                  ele.ends_at &&
                                  ele.ends_at.seconds &&
                                  moment(
                                    new Date(
                                      ele.ends_at.seconds * 1000
                                    ).toISOString()
                                  ).format("DD MMM YYYY")}
                              </td>) : <td>No End Date</td>
                            }
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          ) : (
            <p className="text-lg color-red text-center">
              No membership requests
            </p>
          )
        ) : (
          <p className="text-lg color-warning text-center">Loading...</p>
        )}
      </ContentWrapper>
      {/* Membership Requests : End */}

      <Modal
        show={showModal}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <div className=" p-5 rounded font-body">
          <ModalBody>
            <div className="row g-4">
              <div className="col-12 d-flex justify-content-between">
                <h5 className="text-2xl  color-ternary mb-0">
                  Government ID Photos
                </h5>
                <div
                  style={{
                    height: "40px",
                    width: "40px",
                    backgroundColor: "#090F17",
                  }}
                  className="cursor-pointer rounded-circle d-flex align-items-center justify-content-center"
                  onClick={() => setShowModal(false)}
                  name="Close"
                >
                  <i className="bi bi-x-lg text-white"></i>
                </div>
              </div>
              {kycDetails &&
                kycDetails.govt_id_front &&
                (kycDetails.govt_id_front !== null ||
                  kycDetails.govt_id_front !== "") ? (
                <div className="col-6">
                  <p className="text-xl mb-1">Front side of ID</p>

                  <Zoom>
                    <img
                      className="rounded img-fluid"
                      src={kycDetails.govt_id_front}
                      alt="front id"
                      height="250px"
                      width="200px"
                    />
                  </Zoom>
                </div>
              ) : (
                <div className="col-6">
                  <p>No Front side of ID</p>
                </div>
              )}

              {kycDetails &&
                kycDetails.govt_id_back &&
                (kycDetails.govt_id_back !== null ||
                  kycDetails.govt_id_back !== "") && (
                  <div className="col-6">
                    <p className="text-xl  mb-1">Back side of ID </p>

                    <Zoom>
                      <img
                        className="rounded img-fluid"
                        src={kycDetails.govt_id_back}
                        alt="back id"
                        height="250px"
                        width="200px"
                      />
                    </Zoom>
                  </div>
                )}
            </div>
          </ModalBody>
        </div>
      </Modal>

      <Modal show={showMember} onHide={memberHandleClose} size="lg">
        <Modal.Header className="background-appColor p-8 font-body">
          <Modal.Title className="d-flex justify-content-between align-items-center w-100">
            <h2 className="font-body mb-0 ps-2 pt-2 pb-1  text-2xl  color-ternary font-semibold ">
              {membershipDetails.name} Membership
            </h2>
            <i
              className="bi bi-x-circle-fill text-2xl color-ternary cursor-pointer"
              onClick={memberHandleClose}
            ></i>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="background-appColor p-8 font-body">
          <ul className="font-body mb-0 ps-2 pl-5  pb-2">
            {membershipDetails.benifits &&
              membershipDetails.benifits.map((item, index) => {
                return (
                  <li
                    className="text-sm font-normal mb-0 mt-2 gold-bullets text-light"
                    style={{ opacity: "0.8" }}
                    key={index}
                  >
                    {item.description}
                  </li>
                );
              })}
          </ul>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default UserDetails;
