import React, { useState, useEffect } from 'react';
import ContentWrapper from "../ContentWrapper";
import DateRange from "../DateRange";
import Chart from "react-apexcharts";
const MembershipRevenue = ({ setSelectedDateRange, membershipRevenueStartDate, membershipRevenueEndDate, revenueFromSubscription }) => {
  const [state, setState] = useState({
    Amethyst: [],
    Gold: [],
    Platinum: [],
    Solitaire: [],
    months: [],
  })

  useEffect(() => {
    let Amethyst = [];
    let Gold = [];
    let Platinum = [];
    let Solitaire = []
    let months = []

    revenueFromSubscription.length > 0 && revenueFromSubscription.forEach(data => {
      Amethyst.push(data.Amethyst);
      Gold.push(data.Gold);
      Platinum.push(data.Platinum);
      Solitaire.push(data.Solitaire);
      months.push(data.month_name);

    })
    setState((prevProps) => ({
      ...prevProps,
      Amethyst: Amethyst,
      Gold: Gold,
      Platinum: Platinum,
      Solitaire: Solitaire,
      months: months,
    }))
  }, [revenueFromSubscription]);

  const chartOptions = {
    chart: {
      id: "line-chart",
    },
    xaxis: {
      categories: state.months
    },
    yaxis: {
      // title: {
      //   text: 'Value',
      // },
    },
    colors: ["#955196", "#dd5182", "#FA896B", "#ff6e54", "#13DEB9"],
    fill: {
      opacity: 1.0,
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: 'left',
    },
    dataLabels: {
      enabled: false,
    },
  };
  const chartSeries = [
    {
      name: "Amethyst",
      data: state.Amethyst
    },
    {
      name: "Solitaire",
      data: state.Solitaire
    },
    {
      name: "Platinum",
      data: state.Platinum
    },
    {
      name: "Gold",
      data: state.Gold
    },
    {
      name: "Silver",
      data: state.Silver
    },
  ];
  const getDateRange = (start, end) => {
    setSelectedDateRange(start, end, "membership_revenue");
  }
  return (
    <ContentWrapper additionalClass="p-5">
      <div className="d-flex justify-content-between font-body">
        <div>
          <h2 className="text-md text-xl-lg font-bold color-blue font-body mb-0">
            Memberships Revenue
          </h2>
          <p className="text-xs text-xl-sm"> Revenue From Memberships : 200</p>
        </div>

        <div>
          <DateRange
            getDateRange={getDateRange}
            startDate={membershipRevenueStartDate}
            endDate={membershipRevenueEndDate}
          />
        </div>
      </div>
      <Chart
        options={chartOptions}
        series={chartSeries}
        type="line"
        height={500}
      />
    </ContentWrapper>
  );
};
export default MembershipRevenue;
