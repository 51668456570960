import React, { useCallback, useEffect, useState } from 'react'
import db from '../../firebase';
import PageDescription from '../../Components/Global/PageDescription';
import Layout from '../../Layout/Layout';
import { collection, getDocs, limit, query, startAfter, getCountFromServer, orderBy, getDoc, doc, endBefore, limitToLast, startAt, endAt, or, where } from '@firebase/firestore';
import EventsTable from '../../Components/Global/Table/EventsTable';
import PrimaryButton from '../../Components/Global/Buttons/PrimaryButton';
import { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import FilterModalEvents from '../../Components/Global/Popup/FilterModalEvents';

const Events = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const lastVisibleList = useRef([]);
  const count = useRef(0);
  const itemsPerPage = useRef(20);
  const currentItem = useRef("");
  const nextPage = useRef(1);
  const filterKeys = useRef({
    selectedLocations: [],
    selectedClubs: [],
    selectedStatus: []
  });
  const searchQueryInput = useRef("");
  const sortBy = useRef({
    orderByKey: "updateAt",
    order: "desc"
  })
  const filterIcon = useRef(null);

  const [events, setEvents] = useState([]);
  const [loader, setLoader] = useState(true);
  const [pageCount, setPageCount] = useState(null);
  const [totalEvents, setTotalEvents] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const filterComponent = React.createRef();

  const handleClickOutside = useCallback((event) => {
    if (filterComponent.current && !filterComponent.current.contains(event.target) && event.target !== filterIcon.current) {
      setShowModal(false);
    }
  }, [filterComponent]);

  useEffect(() => { // this useEffect is used when user will click other area than filter popup
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handleClickOutside]);

  useEffect(() => { // the side effect of this use effect will come on action only if filter will be applied
    if (Boolean(searchParams.get("filterQuery"))) {
      setSearchQuery("");
      setLoader(true);
      setEvents([]);
      count.current = 0
      lastVisibleList.current = [];
      currentItem.current = "";
      filterKeys.current = { ...JSON.parse(searchParams.get("filterQuery")) };
      if ((filterKeys.current.selectedLocations.length + filterKeys.current.selectedClubs.length + filterKeys.current.selectedStatus.length) === 0) {
        itemsPerPage.current = 20;
        searchParams.delete('filterQuery');
        setSearchParams({ ...Object.fromEntries([...searchParams]), filterQuery: JSON.stringify(filterKeys.current), itemsPerPage: itemsPerPage.current });
      }
      if (filterKeys.current.selectedLocations.length || filterKeys.current.selectedStatus.length || filterKeys.current.selectedClubs.length) handleFilterEvents();
      else eventList("next");
    } else if (Boolean(searchParams.get("searchQuery"))) {
      return
    } else {
      filterKeys.current = {
        selectedLocations: [],
        selectedClubs: [],
        selectedStatus: []
      }
      fetchEventsCount();
      if (currentItem.current) pageReloadFunc();
      else eventList("next");
    }
  }, [searchParams]);

  const eventList = async (next, perPageItemsChange, sortByArg, removeParam) => {
    setLoader(true);
    if (sortByArg) {
      sortBy.current = {
        orderByKey: sortByArg.orderByKey,
        order: sortByArg.order
      }
    } if (removeParam) {
      searchParams.delete('filterQuery');
      searchParams.delete('searchQuery');
      setSearchParams({ ...Object.fromEntries([...searchParams]) });
      filterKeys.current = {
        selectedLocations: [],
        selectedClubs: [],
        selectedStatus: []
      }
    }
    // event lists fetching ***********************************************
    if (perPageItemsChange) {
      count.current = 0
      lastVisibleList.current = [];
      currentItem.current = "";
      if (!removeParam) {
        setSearchParams({
          eventPage: count.current + 1,
          lastItems: JSON.stringify(lastVisibleList.current),
          itemsPerPage: itemsPerPage.current,
          currentItem: currentItem.current,
          n: nextPage.current,
        });
      }
    }
    try {
      const docSnap = count.current && await getDoc(doc(collection(db, "events"), lastVisibleList.current[next ? 1 : 0]));
      let events_data;
      const currentParams = Object.fromEntries([...searchParams]);
      if (!count.current) {
        events_data = await getDocs(query(collection(db, "events"), orderBy(sortBy.current.orderByKey, sortBy.current.order), limit(+itemsPerPage.current)));
        if (events_data.size) lastVisibleList.current = [events_data.docs[0].id, events_data.docs[events_data.docs.length - 1].id];
        setSearchParams({
          ...currentParams,
          eventPage: count.current + 1,
          lastItems: JSON.stringify(lastVisibleList.current),
          itemsPerPage: itemsPerPage.current,
          n: nextPage.current
        });
      }
      else if (next && count.current) {
        events_data = await getDocs(query(collection(db, "events"), orderBy(sortBy.current.orderByKey, sortBy.current.order), startAfter(docSnap), limit(+itemsPerPage.current)));
        currentItem.current = docSnap.id;
        lastVisibleList.current = [events_data.docs[0].id, events_data.docs[events_data.docs.length - 1].id];
        setSearchParams({
          ...currentParams,
          eventPage: count.current + 1,
          lastItems: JSON.stringify(lastVisibleList.current),
          itemsPerPage: itemsPerPage.current,
          currentItem: currentItem.current,
          n: nextPage.current,
        });
      }
      else {
        events_data = await getDocs(query(collection(db, "events"), orderBy(sortBy.current.orderByKey, sortBy.current.order), endBefore(docSnap), limitToLast(+itemsPerPage.current)));
        lastVisibleList.current = [events_data.docs[0].id, events_data.docs[events_data.docs.length - 1].id];
        currentItem.current = docSnap.id;
        nextPage.current = 0;
        setSearchParams({
          ...currentParams,
          eventPage: count.current + 1,
          lastItems: JSON.stringify(lastVisibleList.current),
          itemsPerPage: itemsPerPage.current,
          currentItem: currentItem.current,
          n: nextPage.current
        });
      }
      if (events_data) {
        setEvents([]);
        events_data.size && events_data.forEach(event => {
          setEvents(prev => [...prev, event.data()])
        })
        setLoader(false);
      }
      if (sortByArg) {
        setSearchParams({
          ...currentParams,
          sortBy: JSON.stringify(sortBy.current),
          changeOrder: sortByArg.changeOrder
        })
      }
    }
    catch (err) {
      console.log(err);
      setLoader(false);
    }
  }


  const fetchEventsCount = async () => {
    const coll = collection(db, "events");
    const eventsCount = await getCountFromServer(coll);
    setTotalEvents(eventsCount.data().count);
    setPageCount(Math.ceil(eventsCount.data().count / itemsPerPage.current));
  }

  const previousPageDataFunc = () => {
    count.current = count.current - 1;
    eventList("");
  }
  const nextPageDataFunc = () => {
    count.current = count.current + 1;
    nextPage.current = 1;
    eventList("next");
  }

  const pageReloadFunc = async () => { // after first render this function will execute
    setLoader(true);
    if (searchParams.get("currentItem")) {
      count.current = searchParams.get("eventPage") - 1;
      itemsPerPage.current = +searchParams.get("itemsPerPage");
      lastVisibleList.current = JSON.parse(searchParams.get("lastItems"));
      currentItem.current = searchParams.get("currentItem");
      nextPage.current = +searchParams.get("n");
    }
    setEvents([]);
    const docSnap = count.current && await getDoc(doc(collection(db, "events"), currentItem.current));
    let events_data;
    if (nextPage.current) events_data = await getDocs(query(collection(db, "events"), orderBy(sortBy.current.orderByKey, sortBy.current.order), startAfter(docSnap), limit(+itemsPerPage.current)));
    else events_data = await getDocs(query(collection(db, "events"), orderBy(sortBy.current.orderByKey, sortBy.current.order), endBefore(docSnap), limitToLast(+itemsPerPage.current)));
    if (events_data) {
      events_data.size && events_data.forEach(event => {
        setEvents(prev => [...prev, event.data()])
      })
      setLoader(false);
    }
  }

  useEffect(() => {
    if (JSON.parse(searchParams.get('sortBy'))) {
      sortBy.current = {
        ...JSON.parse(searchParams.get('sortBy'))
      }
    }
    if (searchParams.get("itemsPerPage")) {
      count.current = searchParams.get("eventPage") - 1;
      itemsPerPage.current = +searchParams.get("itemsPerPage");
      lastVisibleList.current = JSON.parse(searchParams.get("lastItems"));
      currentItem.current = searchParams.get("currentItem");
      nextPage.current = +searchParams.get("n");
    }
    searchQueryInput.current = searchParams.get("searchQuery");
    filterKeys.current = { ...JSON.parse(searchParams.get("filterQuery")) };
    if (searchQueryInput.current) {
      setSearchQuery(searchQueryInput.current);
      fetchSearchedEvents(searchQueryInput.current);
    }
    else if (JSON.stringify(filterKeys.current) !== "{}") {
      handleFilterEvents();
    }
    else {
      fetchEventsCount();
      if (currentItem.current && count.current) pageReloadFunc();
      else eventList("next");
    }
  }, []);

  // Searching ****************************************************************************!
  const handleSearchQuery = (e) => {
    let inputValue = (e.target.value).trim();
    if (inputValue.trim().length > 0) {
      setLoader(true);
      setSearchQuery(e.target.value);
      filterKeys.current = {
        selectedLocations: [],
        selectedClubs: [],
        selectedStatus: []
      }
      searchQueryInput.current = e.target.value;
      if (e.target.value) {
        fetchSearchedEvents(e.target.value);
      }
      else {
        itemsPerPage.current = +searchParams.get('itemsPerPage');
        setTimeout(() => {
          setEvents([]);
          eventList('next', 'perPageItemsChange', null, "removeParam");
          fetchEventsCount()
        }, 1000)
      }
    }
    else {
      setSearchQuery("");
      console.log(searchParams, "searchParams")
      searchParams.delete('prevNext');
      searchParams.delete('currentPageId');
      searchParams.delete('filterQuery');
      searchParams.delete('n');
      searchParams.delete('searchQuery');
      setSearchParams(searchParams);
      setTimeout(() => {
        setEvents([]);
        eventList('next', 'perPageItemsChange', null, "removeParam");
        fetchEventsCount()
      }, 1000)
      searchQueryInput.current = "";
      setSearchQuery(searchQueryInput.current);
    }
  }
  const fetchSearchedEvents = useCallback(
    debounce((val) => {
      if (searchQueryInput.current) {
        const searchedEventByName = query(collection(db, "events"), orderBy("name_lowercase"), startAt(val.replace(/\s+/g, ' ').trim().toLowerCase()), endAt(`${val.replace(/\s+/g, ' ').trim().toLowerCase()}\uf8ff`));
        const searchedEventByClubName = query(collection(db, "events"), orderBy("club_name_lower_case"), startAt(val.replace(/\s+/g, ' ').trim().toLowerCase()), endAt(`${val.replace(/\s+/g, ' ').trim().toLowerCase()}\uf8ff`));
        const searchedEventByLocation = query(collection(db, "events"), orderBy("city_name_lower_case"), startAt(val.replace(/\s+/g, ' ').trim().toLowerCase()), endAt(`${val.replace(/\s+/g, ' ').trim().toLowerCase()}\uf8ff`));
        getDocs(searchedEventByName).then((docSnapshots) => {
          setEvents([]);
          currentItem.current = docSnapshots.docs[0] ? docSnapshots.docs[0].id : "";
          docSnapshots.forEach((docs) => {
            setEvents((prev) => [...prev, docs.data()]);
          });
        });
        getDocs(searchedEventByClubName).then((docSnapshots) => {
          currentItem.current = docSnapshots.docs[0] ? docSnapshots.docs[0].id : "";
          docSnapshots.forEach((docs) => {
            setEvents((prev) => [...prev, docs.data()]);
          });
        });
        getDocs(searchedEventByLocation).then((docSnapshots) => {
          currentItem.current = docSnapshots.docs[0] ? docSnapshots.docs[0].id : "";
          setSearchParams({ eventPage: count.current + 1, itemsPerPage: itemsPerPage.current, searchQuery: val });
          docSnapshots.forEach((docs) => {
            setEvents((prev) => [...prev, docs.data()]);
          });
          setLoader(false);
        });
      }
    }, 1000),
    []);

  function debounce(fn, delay) {
    let timer;
    return function () {
      let context = this,
        args = arguments;
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(context, args);
      }, delay);
    };
  }

  // Filter **************************************************************************************************!

  const handleFilterEvents = async () => {
    let arr = [];
    let filteredClubs = filterKeys.current["selectedClubs"].length;
    let filteredLocations = filterKeys.current["selectedLocations"].length;
    let filteredStatus = filterKeys.current["selectedStatus"].length;

    if (Boolean(filteredClubs) && Boolean(filteredLocations) && Boolean(filteredStatus)) {
      arr = [where('club.id', "in", [...filterKeys.current["selectedClubs"].map(item => item.id)]), where("city_name_lower_case", "in", filterKeys.current["selectedLocations"]), where("status", "in", filterKeys.current["selectedStatus"])]
    } else if (filteredClubs && filteredLocations) {
      arr = [where('club.id', "in", [...filterKeys.current["selectedClubs"].map(item => item.id)]), where("city_name_lower_case", "in", filterKeys.current["selectedLocations"])]
    } else if (filteredLocations && filteredStatus) {
      arr = [where("city_name_lower_case", "in", filterKeys.current["selectedLocations"]), where("status", "in", filterKeys.current["selectedStatus"])]
    } else if (filteredLocations && filteredClubs) {
      arr = [where("city_name_lower_case", "in", filterKeys.current["selectedLocations"]), where('club.id', "in", filterKeys.current["selectedClubs"].map(item => item.id))]
    } else if (filteredClubs) {
      arr = [where('club.id', "in", [...filterKeys.current["selectedClubs"].map(item => item.id)])]
    } else if (filteredLocations) {
      arr = [where("city_name_lower_case", "in", filterKeys.current["selectedLocations"])]
    } else if (filteredStatus) {
      arr = [where("status", "in", filterKeys.current["selectedStatus"])]
    } else console.log("not applicable");

    let events_data = (JSON.stringify(filterKeys.current) !== "{}") && (filteredClubs || filteredLocations || filteredStatus) && await getDocs(query(collection(db, "events"), or(...arr), orderBy(sortBy.current.orderByKey, sortBy.current.order)))
    if (events_data) {
      setEvents([]);
      events_data.size && events_data.forEach(event => {
        setEvents(prev => [...prev, event.data()])
      })
      setLoader(false);
    }
  }

  const handleModifyFilter = (e, item, filterType) => {
    if (filterType === "location") {
      filterKeys.current = {
        ...filterKeys.current,
        selectedLocations: filterKeys.current.selectedLocations.filter(location => location !== item)
      }
    }
    if (filterType === "club") {
      filterKeys.current = {
        ...filterKeys.current,
        selectedClubs: filterKeys.current.selectedClubs.filter(club => club.id !== item)
      }
    }
    if (filterType === "status") {
      filterKeys.current = {
        ...filterKeys.current,
        selectedStatus: filterKeys.current.selectedStatus.filter(status => status !== item)
      }
    }
    const currentParams = Object.fromEntries([...searchParams]);
    setSearchParams({ ...currentParams, filterQuery: JSON.stringify(filterKeys.current) });
  }

  return (
    <Layout path="/events" additionalclass="d-flex flex-column">
      <div className='po-table-content'>
        <div className="d-flex justify-content-between align-items-center gap-3 gap-lg-5">
          <PageDescription
            title="All Events"
            caption="" />
          <PrimaryButton link="/events/create-event" name=" Add Event" icon="bi-plus-circle" />
        </div>
        <div className='row d-flex justify-content-between g-3'>
          <div className='col-5 col-xxl-4'>
            <input
              id="search"
              type="search"
              className="po-edit-input mb-1"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchQuery}
            />
            {Boolean(searchQuery || searchParams.get("filterQuery")) && !loader && events.length ? <div className='text-md ps-2 mt-2'>No. of events displaying: <span className="font-bold ms-3">{events.length}</span></div> : ""}
          </div>
          <div className='col-6 col-xxl-4'>
            <div className='text-end position-relative'>
              <button className='border-0 bg-transparent' type='button' onClick={() => { setShowModal(prev => !prev) }}><i className={`bi bi-funnel-fill text-xl ${showModal ? "color-yellow1" : ""}`} ref={filterIcon}></i></button>
              <FilterModalEvents
                ref={filterComponent}
                showModal={showModal}
                setShowModal={setShowModal}
                collectionRecall={eventList}
                fetchEventsCount={fetchEventsCount}
              />
            </div>
          </div>
        </div>
        <div className={`mt-3 ${JSON.stringify(filterKeys.current) !== "{}" ? !filterKeys.current.selectedLocations.length && !filterKeys.current.selectedClubs.length && !filterKeys.current.selectedStatus.length ? "d-none" : "" : 'd-none'}`}>
          {filterKeys.current && filterKeys.current.selectedLocations && filterKeys.current.selectedLocations.length ? <div className='d-flex align-items-start my-3 gap-3 text-sm font-medium'>
            <span className='text-nowrap'>Selected Location</span> :
            <div className='d-flex align-items-start flex-wrap gap-3'>
              {filterKeys.current && filterKeys.current.selectedLocations && filterKeys.current.selectedLocations.map((item, index) => <div
                className='text-xxs font-medium pl-3 pe-0 py-1 border-1 background-gradient rounded-pill text-capitalize d-flex align-items-center justify-content-between'
                key={index}
              >
                {item}
                <button className='bg-transparent border-0 d-flex align-items-center' onClick={(e) => handleModifyFilter(e, item, 'location')}>
                  <i className="bi bi-x-circle-fill text-md lineheight-0"></i>
                </button>
              </div>
              )}
            </div>
          </div> : ""}
          {filterKeys.current && filterKeys.current.selectedClubs && filterKeys.current.selectedClubs.length ? <div className='d-flex align-items-start my-3 gap-3 text-sm font-medium'>
            <span className='text-nowrap'>Selected Clubs</span> :
            <div className='d-flex align-items-start flex-wrap gap-3'>
              {filterKeys.current && filterKeys.current.selectedClubs && filterKeys.current.selectedClubs.map((item, index) => <div
                className='text-xxs font-medium pl-3 pe-0 py-1 border-1 background-gradient rounded-pill text-capitalize d-flex align-items-center justify-content-between'
                key={index}
              >
                {item.name}
                <button className='bg-transparent border-0 d-flex align-items-center' onClick={(e) => handleModifyFilter(e, item.id, 'club')}>
                  <i className="bi bi-x-circle-fill text-md lineheight-0"></i>
                </button>
              </div>
              )}
            </div>
          </div> : ""}
          {filterKeys.current && filterKeys.current.selectedStatus && filterKeys.current.selectedStatus.length ? <div className='d-flex align-items-start my-3 gap-3 text-sm font-medium'>
            <span className='text-nowrap'>Selected Status</span> :
            <div className='d-flex align-items-start flex-wrap gap-3'>
              {filterKeys.current && filterKeys.current.selectedStatus && filterKeys.current.selectedStatus.map((item, index) => <div
                className='text-xxs font-medium pl-3 pe-0 py-1 border-1 background-gradient rounded-pill text-capitalize d-flex align-items-center justify-content-between'
                key={index}
              >
                {item}
                <button className='bg-transparent border-0 d-flex align-items-center' onClick={(e) => handleModifyFilter(e, item, 'status')}>
                  <i className="bi bi-x-circle-fill text-md lineheight-0"></i>
                </button>
              </div>
              )}
            </div>
          </div> : ""}
        </div>
      </div>
      <div className="font-body po-card rounded bg-white">
        <EventsTable
          searchParamss={searchParams}
          setSearchQuery={setSearchQuery}
          setEvents={setEvents}
          tableData={events}
          eventListRecall={eventList}
          pageReloadFunc={pageReloadFunc}
          loader={loader}
          count={count}
          previousPageDataFunc={previousPageDataFunc}
          nextPageDataFunc={nextPageDataFunc}
          itemsPerPage={itemsPerPage}
          pageCount={pageCount}
          handleFilterEvents={handleFilterEvents}
          totalEvents={totalEvents}
          fetchEventsCount={fetchEventsCount}
          fetchSearchedEvents={fetchSearchedEvents}
          setLoader={setLoader}
          orderByKey={sortBy}
        />
      </div>
    </Layout>
  )
}

export default Events;

