import React, { useEffect } from "react";
import Breadcrumb from "./Breadcrumb";
import { useState } from "react";
import { motion } from "framer-motion";
import Logout from "../Components/Global/Popup/ConfirmModal";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
const contents = {
  title: "Confirm Logout",
  permission: "Are you sure you want to log out?",
};

const SwitchLanguage = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState("");
 
  const handleLanguageChange = (event) => {
    i18n.changeLanguage(event.target.value);
    localStorage.setItem("language", event.target.value)
    setLanguage(event.target.value);

  };
  useEffect(()=> {
    if(localStorage.getItem("language")) {
      setLanguage(localStorage.getItem("language"));
      i18n.changeLanguage(localStorage.getItem("language"))
    }
  }, [])
  
 return(
  <select onChange={handleLanguageChange} value={language} className=" text-sm border border-secondary bg-transparent px-2 py-1 rounded">
    <option value="en" className="mb-3">English</option>
    <option value="es" className="mb-3">Spanish</option>
    <option value="zh" className="">Chinese</option>
  </select>
  )
}

function Dropdown() {
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleButtonClick = () => setShowModal(true);
  const userName = localStorage.getItem('user_name') || "Admin";
  
  return (
    <>
      <Logout
        showModal={showModal}
        setShowModal={setShowModal}
        contents={contents}
        logout="true"
      />
      <div>
        <div
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
          className="cursor-pointer d-flex align-items-center mx-4"
        >
          <i className="bi bi-person-fill lineheight-0"></i>{" "}
          <span className="font-body text-xs text-xl-md ms-1 text-capitalize">{userName}</span>
        </div>
        {isOpen && (
          <motion.div
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
            className="position-absolute shadow-lg p-3  bg-white rounded d-block"
            variants={{
              open: { opacity: 1, y: 0 },
              closed: { opacity: 0, y: -10 },
            }}
            transition={{ duration: 0.3 }}
            initial="closed"
            animate={isOpen ? "open" : "closed"}
          >
            <ul className="mb-0">
              <li
                className="text-md cursor-pointer text-md text-nowrap"
                onClick={handleButtonClick}
              >
                Log Out
              </li>
              {/* <li
                className="text-md cursor-pointer text-md text-nowrap">
                Switch to Manager
              </li> */}
            </ul>
          </motion.div>
        )}
      </div>
    </>
  );
}
const Header = ({ path, link, breadCrumbData }) => {
  return (
    <>
      <div className="d-flex justify-content-lg-between align-items-lg-center pt-2 pb-5 flex-column flex-lg-row">
        <div className="mb-2 mb-lg-0">
          <Breadcrumb path={path} link={link} breadCrumbData={breadCrumbData} />
        </div>
        <div className="d-flex justify-content-between align-items-center">
          {/* <SwitchLanguage/> */}
          <div className="position-relative d-block">
            <Dropdown />
          </div>
          <Link to="/profile"> <i className="bi bi-gear-fill text-dark"></i></Link>
        </div>
      </div>
    </>
  );
};

export default Header;