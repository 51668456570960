import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useSearchParams } from "react-router-dom";
import useCallCollection from "../../../hooks/useCallCollection";

const mountedStyle = {
    animation: "inAnimation 250ms ease-in"
};
const unmountedStyle = {
    animation: "outAnimation 270ms ease-out",
    animationFillMode: "forwards"
};

const FilterModalMembershipsRequest = React.forwardRef((props, wrapper) => {
    const [memberships, loaderMemberships] = useCallCollection("memberships");
    const [showFilterList, setShowFilterList] = useState(false);
    const [errorFilterMessage, setErrorFilterMessage] = useState();
    const [filterOptions, setFilterOptions] = useState({
        membership: true,
        status: false
    })
    const [picklist, loaderPicklist] = useCallCollection("picklist", "membership_request_status");
    const [searchParams, setSearchParams] = useSearchParams();
    const [state, setState] = useState({
        selectedMemberships: [],
        selectedStatus: []
    })

    useEffect(() => {
        const filterQuery = JSON.parse(searchParams.get("filterQuery"));
        if (searchParams.get("filterQuery") && searchParams.get("filterQuery") !== "{}") {
            setState({ ...filterQuery });
        }
        else {
            setState({
                selectedMemberships: [],
                selectedStatus: []
            })
        }
    }, [searchParams]);

    useEffect(() => {
        if (!props.showModal) {
            if (state.selectedStatus.length || (JSON.parse(searchParams.get("filterQuery")) && JSON.parse(searchParams.get("filterQuery")).selectedStatus.length)) {
                setFilterOptions({
                    membership: true,
                    status: true
                })
            }
            else {
                setFilterOptions({
                    membership: true,
                    status: false
                })
            }
        }
    }, [props.showModal]);

    const handleSelect = (e, val, key) => {
        console.log(val, 'val', key);
        if (e.target.checked && (((state.selectedMemberships.length + 1) * state.selectedStatus.length) < 30) && ((state.selectedMemberships.length * (state.selectedStatus.length + 1)) < 30)) {
            setErrorFilterMessage("");
            setState(prev => ({
                ...prev,
                [key]: [...state[key], val]
            }));
        }
        else {
            setErrorFilterMessage("");
            setState(prev => ({
                ...prev,
                [key]: state[key].filter(item => item !== val)
            }))
        }
        if (e.target.checked && state.selectedMemberships.length + state.selectedStatus.length > 29) {
            setErrorFilterMessage("Cannot select more than 30 options");
            setTimeout(() => {
                setErrorFilterMessage("");
            }, 3000)
            return
        }
        else setErrorFilterMessage("");
    }

    const handleApplyFilter = () => {
        if (state.selectedMemberships.length || state.selectedStatus.length) {
            // const currentParams = Object.fromEntries([...searchParams]);
            searchParams.delete('searchQuery');
            searchParams.delete('pageNo');
            searchParams.delete('prevNext');
            searchParams.delete('currentPageId');
            searchParams.delete('n');
            if (!Boolean(searchParams.get('filterQuery'))) {
                setSearchParams({ ...Object.fromEntries([...searchParams]), pageNo: 1, filterQuery: JSON.stringify(state) });
            } else {
                setSearchParams({ ...Object.fromEntries([...searchParams]), pageNo: 1, filterQuery: JSON.stringify(state) });
            }
            // setSearchParams({clubPageNo: 1, filterQuery: JSON.stringify(state)});
            props.handleFilterMembershipsRequest(state);
            props.setShowModal(false);
        }
        else {
            // setSearchParams({clubPageNo: 1, filterQuery: JSON.stringify(state), itemsPerPage: props.clubsPerPage});
            props.setShowModal(false);
            if (!Boolean(searchParams.get('searchQuery'))) {
                setSearchParams({});
                props.collectionRecall();
                props.fetchClubsCount();
            }
        };
    }
    const handleClearFilter = () => {
        const resetFilterState = {
            selectedMemberships: [],
            selectedStatus: []
        }
        setState({ ...resetFilterState })
        props.setShowModal(false);
        if (!Boolean(searchParams.get('searchQuery')) && Boolean(searchParams.get('filterQuery'))) {
            setSearchParams({});
            props.collectionRecall();
        }
    }
    const handleFilterOptions = (e) => {
        setFilterOptions(prev => ({
            ...prev,
            [e.target.id]: !filterOptions[e.target.id]
        }))
        if (!showFilterList) setShowFilterList(true);
    }
    return (
        <>
            {props.showModal ? <div className={` position-absolute bg-light w-100 rounded-3 shadow overflow-hidden position-relative`} style={{ zIndex: 101 }} ref={wrapper}>
                <div className="px-3 py-4 d-flex justify-content-between align-items-center background-appColor text-sm font-medium border-bottom pb-2">
                    <button
                        className="border-0 bg-transparent color-yellow1 text-sm font-medium po-filter__btn-clear px-0 text-capitalize"
                        type="button"
                        onClick={handleClearFilter}
                    >clear All</button>
                    <button
                        className={` color-black1 text-xs font-body font-semibold cursor-pointer background-gradient py-1 px-4 radius-105 border-0 d-inline-block`}
                        onClick={handleApplyFilter}
                    >Apply
                    </button>
                </div>
                <div className="po-filter">
                    <div className="py-2 d-flex align-items-start border-bottom pl-3">
                        <button
                            className={`po-filter__itemsList-title bg-transparent border-0 text-sm text-black font-medium text-capitalize text-start text-break ${!filterOptions.membership ? "w-100" : ""}`}
                            id="membership"
                            onClick={handleFilterOptions}
                        >Memberships</button>
                        {filterOptions.membership && <ul
                            className={`po-filter__itemsList pb-0 ms-3 pe-3 }`}
                            style={filterOptions.membership ? mountedStyle : unmountedStyle}
                            onAnimationEnd={() => {
                                if (!filterOptions.membership) setShowFilterList(false);
                            }}>
                            {!loaderMemberships ? [...memberships].sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0).map((item, index) => {
                                return <li key={index}
                                    className={`po-filter__itemsList-item text-sm color-gray font-medium text-start py-2`}
                                    title={item.country_name}
                                >
                                    <label htmlFor={item.name} className={`cursor-pointer d-block ${state.selectedMemberships && state.selectedMemberships.includes(item.name.toLowerCase()) ? "color-yellow1" : ""}`}>
                                        <input
                                            className="appearance-none opacity-0"
                                            type="checkbox"
                                            value={item.name}
                                            id={item.name}
                                            name={item.name}
                                            checked={state.selectedMemberships && state.selectedMemberships.includes(item.name.toLowerCase())}
                                            onChange={(e) => { handleSelect(e, item.name.toLowerCase(), "selectedMemberships") }} />
                                        <i className="bi bi-check2"></i> {item.name}
                                    </label>
                                </li>
                            }) : <Skeleton inline={true} count={5} height={30} style={{ marginBottom: "1rem" }} baseColor='#dedede' highlightColor='#cccccc' duration={2} />}
                        </ul>}
                    </div>
                    {/* <div className="py-2 d-flex align-items-start pl-3">
                    <button
                        className={`po-filter__itemsList-title bg-transparent border-0 text-sm text-black font-medium text-capitalize text-start text-break ${!filterOptions.status ? "w-100":""}`}
                        id="status"
                        onClick={handleFilterOptions}
                    >Status</button>
                    {filterOptions.status ? <ul
                        className="po-filter__itemsList pb-0 ms-3 pe-3"
                        style={filterOptions.status ? mountedStyle : unmountedStyle}
                        onAnimationEnd={() => {
                        if (!filterOptions.status) setShowFilterList(false);
                        }}>
                        {!loaderPicklist ? picklist.sort((a, b) => a > b ? 1 : a < b ? -1 : 0 ).map((item, index)=> {
                            if(item!=="Rejected") {
                                return <li key={index}
                                       className={`po-filter__itemsList-item text-sm color-gray font-medium text-start py-2 cursor-pointer ${state.selectedStatus && state.selectedStatus.includes(item) ? "color-yellow1" : ""}`}
                                       title={item}
                                    >
                                        <label htmlFor={item} className={`cursor-pointer d-block ${state.selectedStatus && state.selectedStatus.includes(item.toLowerCase()) ? "color-yellow1" : ""}`}>
                                            <input
                                                className="appearance-none opacity-0"
                                                type="checkbox"
                                                value={item}
                                                id={item}
                                                name={item}
                                                checked={state.selectedStatus && state.selectedStatus.includes(item)}
                                                onChange={(e) => {handleSelect(e, item, "selectedStatus")}}/>
                                            <i className="bi bi-check2"></i> {item}
                                        </label>
                                    </li>
                            } else return ""
                        }): <Skeleton inline={true} count={5} height={30} style={{marginBottom:"1rem"}} baseColor='#dedede' highlightColor='#cccccc' duration={2} />}
                    </ul>:""}
                </div> */}
                </div>
                {errorFilterMessage && <p className="text-center text-xs font-medium text-danger py-2">{errorFilterMessage}</p>}
            </div> : ""}
        </>
    );
});
export default FilterModalMembershipsRequest;
