import React, { useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import moment from "moment";
import { Link, useSearchParams } from "react-router-dom";
import { deleteDoc, doc } from "@firebase/firestore";
import db from "../../../firebase";
import ConfirmModal from "../Popup/ConfirmModal";
import Skeleton from "react-loading-skeleton";
import Dropdown from "react-bootstrap/Dropdown";
const sortIcon = "/assets/icons/icon-sort.svg";
const sortIconAsc = "/assets/icons/icon-sort-asc.svg";
const sortIconDesc = "/assets/icons/icon-sort-desc.svg";

const tableHeading = {
  heading1: "EVENT NAME",
  heading2: "CLUB NAME",
  heading3: "EVENT ON",
  heading4: "STATUS",
  heading5: "ACTION",
  heading6: "UPDATED ON",
  heading7: "No. of Bookings"
}
const EventsTable = ({
  tableData,
  eventListRecall,
  pageReloadFunc,
  loader,
  count,
  nextPageDataFunc,
  previousPageDataFunc,
  itemsPerPage,
  pageCount,
  totalEvents,
  fetchEventsCount,
  fetchSearchedEvents,
  handleFilterEvents,
  setLoader,
  orderByKey,
  setEvents,
  setSearchQuery,
  searchParamss
}) => {
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const changeOrder = useRef(0);
  const [sortedEvents, setSortedEvents] = useState([]);
  const targetElementId = useRef("");
  const localTitleId = useRef("");

  const searchQuery = searchParams.get("searchQuery");
  const filterQuery = searchParams.get("filterQuery") ? JSON.stringify(searchParams.get("filterQuery")) : "";
  if (JSON.parse(searchParams.get('sortBy'))) {
    localTitleId.current = JSON.parse(searchParams.get('sortBy')).orderByKey
    targetElementId.current = localTitleId.current
    changeOrder.current = +searchParams.get('changeOrder');
  }
  let items = searchParams.get('itemsPerPage');
  useEffect(() => {
    if (!changeOrder.current) {
      setSortedEvents([...tableData].splice(0, items));
    }
    else if (changeOrder.current === 1) {
      setSortedEvents([...tableData].sort((a, b) => a[targetElementId.current] > b[targetElementId.current] ? 1 : a[targetElementId.current] < b[targetElementId.current] ? -1 : 0).splice(0, items));
    }
    else {
      setSortedEvents([...tableData].sort((a, b) => a[targetElementId.current] < b[targetElementId.current] ? 1 : a[targetElementId.current] > b[targetElementId.current] ? -1 : 0).splice(0, items));
    }
  }, [tableData, items])

  const handleDeleteButton = async (id) => {
    try {
      if (id !== undefined) {
        await deleteDoc(doc(db, "events", id));
        if (searchParams.get("filterQuery")) {
          handleFilterEvents();
        }
        else if (searchParams.get("searchQuery")) {
          fetchSearchedEvents(searchParams.get("searchQuery"));

        }
        else if (count.current) pageReloadFunc();
        else eventListRecall();
        fetchEventsCount();
        itemsPerPage.current = +searchParams.get('itemsPerPage');
        searchParamss.delete("searchParams");
        setSearchQuery("")
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onDeleteHandler = (id) => {
    setShowModal(true);
    setDeleteId(id);
  };

  const handleItemsPerPage = (e) => {
    setLoader(true);
    itemsPerPage.current = +e.target.value;
    if (searchParams.get("filterQuery")) {
      const currentParams = Object.fromEntries([...searchParams]);
      setSearchParams({ ...currentParams, itemsPerPage: +e.target.value });
    }
    else if (searchParams.get("searchQuery")) {
      fetchSearchedEvents(searchParams.get("searchQuery"));
    }
    else {
      fetchEventsCount();
      eventListRecall("", "perPageItemsChange");
      const currentParams = Object.fromEntries([...searchParams]);
      setSearchParams({ ...currentParams, eventPage: 1, lastItems: JSON.stringify([]), itemsPerPage: +e.target.value, searchQuery: "" });
    }
  }

  // sorting *******************************************************************************************************

  const handleSorting = (e, localId) => {
    localTitleId.current = localId;
    if (targetElementId.current !== localId) {
      changeOrder.current = 0;
      targetElementId.current = localId;
    }
    if (count.current) { // coming back to first page when you will be on other page
      count.current = 0;
      orderByKey.current = { orderByKey: localId, order: "asc" }
      fetchEventsCount();
      eventListRecall('next', 'perPageItemsChange', { ...orderByKey.current, changeOrder: 1 });
      setSearchParams({ eventPage: 1, lastItems: JSON.stringify([]), itemsPerPage: +e.target.value, sortBy: JSON.stringify(orderByKey.current), changeOrder: 1 });
      return;
    }
    if (searchParams.get('searchQuery') || searchParams.get('filterQuery')) {
      if (!changeOrder.current) {
        changeOrder.current = changeOrder.current + 1;
        setSortedEvents([...sortedEvents].sort((a, b) => a[targetElementId.current] > b[targetElementId.current] ? 1 : a[targetElementId.current] < b[targetElementId.current] ? -1 : 0).splice(0, itemsPerPage.current));
      }
      else if (changeOrder.current === 1) {
        changeOrder.current = changeOrder.current + 1;
        setSortedEvents([...sortedEvents].sort((a, b) => a[targetElementId.current] < b[targetElementId.current] ? 1 : a[targetElementId.current] > b[targetElementId.current] ? -1 : 0).splice(0, itemsPerPage.current));
      }
      else {
        changeOrder.current = 0;
        setSortedEvents([...tableData].splice(0, itemsPerPage.current));
      }
    }
    else {
      if (!changeOrder.current) {
        changeOrder.current = changeOrder.current + 1;
        orderByKey.current = { orderByKey: targetElementId.current, order: "asc" }
        eventListRecall(null, null, { ...orderByKey.current, changeOrder: changeOrder.current });
      }
      else if (changeOrder.current === 1) {
        changeOrder.current = changeOrder.current + 1;
        orderByKey.current = { orderByKey: targetElementId.current, order: "desc" }
        eventListRecall(null, null, { ...orderByKey.current, changeOrder: changeOrder.current });
      }
      else {
        changeOrder.current = 0;
        orderByKey.current = { orderByKey: "updateAt", order: "desc" }
        eventListRecall(null, null, { ...orderByKey.current, changeOrder: changeOrder.current });
      }
      const currentParams = Object.fromEntries([...searchParams]);
      setSearchParams({ ...currentParams, sortBy: JSON.stringify(orderByKey.current), changeOrder: changeOrder.current });
    }
  }

  const handleShowAll = () => {
    setSortedEvents(tableData);
    itemsPerPage.current = tableData.length
  }
  return (
    <>
      <div className="tableFixHead pb-5 font-body rounded bg-white">
        <Table hover>
          <thead>
            <tr className="background-gray10">
              <th className="text-nowrap">
                <p className="text-nowrap text-xs text-xl-sm text-uppercase ps-3  mb-0 py-2">
                  s. no
                </p>
              </th>
              <th valign="middle" className="">
                <div
                  onClick={(e) => handleSorting(e, "name_lowercase")}
                  className={`mw-15 w-100 text-nowrap text-xs text-xl-sm text-uppercase  mb-0 py-2 d-inline-flex align-items-center cursor-pointer gap-1 ${localTitleId.current !== "name_lowercase" || !changeOrder.current ? "" : "color-yellow1"}`} id="name_lowercase">
                  {tableHeading.heading1}
                  <img src={(localTitleId.current !== "name_lowercase" || !changeOrder.current) ? sortIcon : changeOrder.current === 1 ? sortIconAsc : sortIconDesc} alt="sort-icon" height={12} />
                </div>
              </th>
              <th valign="middle">
                <div
                  onClick={(e) => handleSorting(e, "club_name_lower_case")}
                  className={`mw-15 w-100 text-nowrap text-xs text-xl-sm text-uppercase  mb-0 py-2 d-inline-flex align-items-center gap-1 cursor-pointer ${localTitleId.current !== "club_name_lower_case" || !changeOrder.current ? "" : "color-yellow1"}`} id="club_name_lower_case">
                  {tableHeading.heading2}
                  <img src={(localTitleId.current !== "club_name_lower_case" || !changeOrder.current) ? sortIcon : changeOrder.current === 1 ? sortIconAsc : sortIconDesc} alt="sort-icon" height={12} />
                </div>
              </th>
              <th valign="middle">
                <div
                  onClick={(e) => handleSorting(e, "no_of_bookings")}
                  className={`mw-15 text-nowrap text-xs text-xl-sm text-uppercase text-center  mb-0 py-2 d-inline-flex align-items-center cursor-pointer gap-1 ${localTitleId.current !== "no_of_bookings" || !changeOrder.current ? "" : "color-yellow1"}`} id="no_of_bookings">
                  {tableHeading.heading7}
                  <img src={(localTitleId.current !== "no_of_bookings" || !changeOrder.current) ? sortIcon : changeOrder.current === 1 ? sortIconAsc : sortIconDesc} alt="sort-icon" height={12} />
                </div>
              </th>
              <th valign="middle">
                <div
                  onClick={(e) => handleSorting(e, "date_and_time")}
                  className={`mw-15 w-100 text-nowrap text-xs text-xl-sm text-uppercase  mb-0 py-2 d-inline-flex align-items-center cursor-pointer gap-1 ${localTitleId.current !== "date_and_time" || !changeOrder.current ? "" : "color-yellow1"}`} id="date_and_time">
                  {tableHeading.heading3}
                  <img src={(localTitleId.current !== "date_and_time" || !changeOrder.current) ? sortIcon : changeOrder.current === 1 ? sortIconAsc : sortIconDesc} alt="sort-icon" height={12} />
                </div>
              </th>
              <th valign="middle">
                <div
                  onClick={(e) => handleSorting(e, "updateAt")}
                  className={`mw-15 w-100 text-nowrap text-xs text-xl-sm text-uppercase  mb-0 py-2 d-inline-flex align-items-center cursor-pointer gap-1 ${localTitleId.current !== "updateAt" || !changeOrder.current ? "" : "color-yellow1"}`} id="updateAt">
                  {tableHeading.heading6}
                  <img src={(localTitleId.current !== "updateAt" || !changeOrder.current) ? sortIcon : changeOrder.current === 1 ? sortIconAsc : sortIconDesc} alt="sort-icon" height={12} />
                </div>
              </th>
              <th valign="middle">
                <div
                  onClick={(e) => handleSorting(e, "status")}
                  className={`mw-15 w-100 text-nowrap text-xs text-xl-sm text-uppercase  mb-0 py-2 d-inline-flex align-items-center cursor-pointer gap-1 ${localTitleId.current !== "status" || !changeOrder.current ? "" : "color-yellow1"}`} id="status">
                  {tableHeading.heading4}
                  <img src={(localTitleId.current !== "status" || !changeOrder.current) ? sortIcon : changeOrder.current === 1 ? sortIconAsc : sortIconDesc} alt="sort-icon" height={12} />
                </div>
              </th>
              <th className="text-nowrap">
              </th>
            </tr>
          </thead>
          <tbody>
            {!loader && tableData ? (
              sortedEvents.length ?
                sortedEvents.map((item, index) => {
                  return (
                    <tr
                      key={index}
                    >
                      <td className="align-middle font-body ps-4 text-sm">{!searchQuery ? (count.current * itemsPerPage.current + index + 1) : index + 1}.</td>
                      <td className="align-middle">
                        <div className="d-flex align-items-center gap-3">
                          <div className="po-table__eventWrapper d-flex justify-content-center align-items-center border rounded flex-shrink-0">
                            {item.display_image ? (
                              <img
                                src={item.display_image}
                                alt={item.title}
                                className="po-table__eventImg rounded"
                              />
                            ) : (
                              <i className="bi bi-image text-5xl"></i>
                            )}
                          </div>
                          <div className="d-flex flex-column po-event__name text-nowrap">
                            <span className=" text-sm mb-0 color-black">
                              {item.name.length > 45 ? item.name.substring(0, 45) + "..." : item.name}
                            </span>
                            <span className="text-xs mt-1 color-black">
                              <i className="bi bi-geo-alt-fill text-danger me-1 "></i>
                              {(item.club && item.club.city_name) ?? "Location not found"}
                            </span>
                          </div>
                        </div>
                        {/*  */}
                      </td>
                      <td className="align-middle text-sm mb-0 text-nowrap color-black">
                        {(item.club && item.club.name) ?? "Club is not listed"}
                      </td>
                      <td className="align-middle text-sm mb-0 text-nowrap color-black">
                        {/* {item.no_of_bookings} */}
                        <Link
                          className="text-black font-bold text-sm py-2 d-block ps-1"
                          to={`/events/event-bookings/${item.id}`}
                        >
                          {item.no_of_bookings}
                        </Link>
                      </td>
                      <td className="align-middle text-sm mb-0 text-nowrap color-black">
                        {moment(item.date_and_time.toDate()).format("DD MMM YYYY")}
                      </td>
                      <td className=" align-middle text-sm mb-0 text-nowrap color-black">
                        {moment(item.updateAt.toDate()).format("DD MMM YYYY")}
                      </td>
                      <td className=" text-sm text-left align-middle text-sm text-lg-md">
                        {item.status ?
                          <div className="d-flex align-items-center justify-content-start gap-2">
                            <div
                              style={{
                                height: "10px",
                                width: "10px",
                                backgroundColor: `${item.status === "Inactive" ? "#bab7ad" : item.status === "Past" ? "#000" : item.status === "Today" ? "#89CFF0" : "#cfb929"}`,
                              }}
                              className="rounded-circle"
                            ></div>
                            {item.status}
                          </div>
                          : ""}
                      </td>
                      <td className="align-middle">
                        <div className="d-flex gap-3 gap-xl-4 align-items-center text-center">
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="link"
                              id="dropdown-basic"
                              className="text-black"
                            >
                              <i className="bi bi-three-dots-vertical text-4xl d-bloxk"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="border-0 shadow px-2">
                              {!(item.date_and_time.toDate() < new Date()) && <Link
                                className="text-black border-bottom font-bold text-sm py-2 d-block ps-1 text-black"
                                to={`/events/edit-event/${item.id}`}
                              >
                                {" "}
                                Edit
                              </Link>}
                              <div
                                className="text-black border-bottom font-bold text-sm py-2 d-block ps-1 cursor-pointer text-black"
                                onClick={() => onDeleteHandler(item.id)}
                              >
                                Delete
                              </div>

                              <Link
                                className="text-black font-bold text-sm py-2 d-block ps-1 text-black"
                                to={`/events/event-bookings/${item.id}`}
                              >
                                Bookings
                              </Link>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </td>
                    </tr>
                  );
                }) : <tr><td colSpan={10} className="text-center text-2xl font-bold">No Data Found</td></tr>
            ) : (
              <tr>
                <td colSpan={8}>
                  <Skeleton inline={true} count={5} height={80} style={{ marginBottom: "1rem" }} baseColor='#dedede' highlightColor='#cccccc' duration={2} />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      {sortedEvents.length ? <div className="d-flex gap-2 justify-content-end align-items-center py-4 me-4 text-xs">
        {(tableData.length !== sortedEvents.length) || (Boolean(filterQuery) ? Boolean(searchQuery || !filterQuery) : Boolean(!searchQuery || filterQuery)) ? <div>
          Rows per page:
          <div className="d-inline-block ms-1 ms-xl-3">
            <select name="item-per-page" id="item-per-page" value={itemsPerPage.current} className="border-0 shadow-none font-medium" onChange={handleItemsPerPage}>
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
              <option value={25}>25</option>
              <option value={30}>30</option>
            </select>
          </div>
        </div> : ""}
        {(searchQuery || filterQuery) && tableData.length > sortedEvents.length ?
          <button
            className="bg-transparent border-1 rounded-3 ml-12"
            type="button"
            onClick={handleShowAll}
          >Show All</button> : ""}
        {!Boolean(searchQuery || filterQuery) ? <div className="d-flex align-items-center gap-3">
          {totalEvents ? <div className="mx-6 mx-xl-12 font-medium">
            {count.current * itemsPerPage.current + 1}-{(count.current + 1) * itemsPerPage.current < totalEvents ? (count.current + 1) * itemsPerPage.current : totalEvents} of {totalEvents}
          </div> : <div className="mx-6 mx-xl-12 font-medium">loading...</div>}
          <button className={`font-body font-bold rounded-circle po-paginationBtn d-inline-block bg-transparent ${(!count.current || loader) ? "po-border border-secondary" : "color-black1 po-border po-border-yellow1"}`} type="button"
            onClick={() => previousPageDataFunc()}
            disabled={!count.current || loader}
          ><i className="bi bi-chevron-left text-black font-black text-lg"></i></button>
          <button className={`font-body font-bold rounded-circle po-paginationBtn po-paginationBtn--next d-inline-block bg-transparent ${loader || (pageCount - 1 === count.current) ? "po-border border-secondary" : "color-black1 po-border po-border-yellow1"}`} type="button"
            onClick={nextPageDataFunc}
            disabled={(pageCount - 1 === count.current) || loader}><i className="bi bi-chevron-right text-black font-black text-lg"></i></button>
        </div> : <div className="px-2 py-3"></div>}
      </div> : ""}
      <ConfirmModal
        showModal={showModal}
        setShowModal={setShowModal}
        Deleteid={deleteId}
        handleDeleteButton={handleDeleteButton}
      />
    </>
  );
};

export default EventsTable;
