import { Link } from "react-router-dom";
const SecondaryButton = ({ name, link, onClick, type, additionalClass, disabled, target }) => {
  return (
    <>
      {link ? (
        <Link 
          to={link}
          target={target} 
          className={`d-inline-block color-black1 text-md font-body font-semibold cursor-pointer po-border po-border-yellow1 py-3 px-3 radius-105  d-inline-block bg-transparent ${additionalClass}`}>
          {name}
        </Link>
      ) : onClick ? (
        <button
          disabled={disabled}
          className={`color-black1 text-md font-body font-semibold cursor-pointer po-border po-border-yellow1 py-3 px-3 radius-105 d-inline-block bg-transparent ${additionalClass}`}
          onClick={onClick}
        >
          {name}
        </button>
      ) : type ? (
        <button
          disabled={disabled}

          className={` color-black1 text-md font-body font-semibold cursor-pointer po-border po-border-yellow1 py-3 px-3 radius-105  d-inline-block bg-transparent ${additionalClass}`}
          type={type}
        >
          {name}
        </button>
      ) : null}
    </>
  );
};
export default SecondaryButton;
