import React from "react";
import { Row, Col, Table, Modal, Button } from "react-bootstrap";
import ContentWrapper from "../../../Components/Global/ContentWrapper";
import Layout from '../../../Layout/LayoutManager/Layout';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import db from "../../../firebase";
import Success from "../../../Components/Global/States/Success";
import Loading from "../../../Components/Global/States/Loading";
import Error from "../../../Components/Global/States/Error";
import { v4 as uuid } from 'uuid';
import { useState } from "react";
import moment from "moment";
import { useForm } from "react-hook-form";
import axios from "axios";
import PrimaryButton from "../../../Components/Global/Buttons/PrimaryButton";
import OutlinedButton from "../../../Components/Global/Buttons/OutlinedButton";
import SecondaryButton from "../../../Components/Global/Buttons/SecondaryButton";
import SuccessButton from "../../../Components/Global/Buttons/SuccessButton";
import Toaster from "../../../Components/Global/Toast";
import ActionIcon from "../../../Components/Global/Buttons/ActionIcons";

const EntryBookingDetails = () => {
  const [showToaster, setShowToaster] = useState(false);
  const [selection, setSelection] = useState("");
  const [showRejectionModal, setshowRejectionModal] = useState(false);
  const [selectButtonError, setSelectButtonError] = useState(false);
  const [bookingCompletionModal, setBookingCompletionModal] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState({
    data: {
      transactionId: "",
      transactionDate: "",
    },
    errors: {
      id: "",
      date: "",
    },
  });
  const [bookingCharge, setBookingCharge] = useState({
    coverChargeAmount: 0,
    paymentLink: "",
    cardFillingPaymentLink: "",
    errors: {
      coverCharger: "",
      paymentLink: "",
      cardFillingPaymentLink: "",
    },
  });
  const [multipleEmails, setMultipleEmails] = useState({
    email: "",
    error: "",
    emails: []
  })
  const [invoice, setInvoice] = useState({
    invoiceFile: "",
    amount: 0,
    loyaltyPoint: 0,
    error: {
      file: "",
      amount: ""
    }
  });
  const [showCancelBookingModal, setCancelBookingModal] = useState(false);
  const [bookingCancellationDetails, setBookingCancellationDetails] = useState({
    reason: "",
    trasactionId: "",
    trasactionDate: "",
    transactionAmount: "",
  });
  const [cancelBookingErrors, setCancelBookingErrors] = useState({
    reason: "",
    trasactionId: "",
    transactionAmount: "",
    transactionDate: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [toasterDetails, setToasterDetails] = useState({
    title: "",
    bodyText: "",
    bg: "",
    statusIcon: "",
  });
  const [reason, setReason] = useState({
    reason: "",
    error: "",
  });
  // const location = useLocation();
  const navigate = useNavigate();
  const [guests, setGuests] = useState([]);
  const [status, setStatus] = useState("");
  // const id = location.state.id;
  const [entryDetail, setEntryDetail] = useState({});
  const [guestsToShow, setGuestsToShow] = useState([]);
  const user_id = localStorage.getItem("user_id");
  // const user_name = localStorage.getItem("user_name");
  const user_email = localStorage.getItem("email");
  const [allComments, setAllComments] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [commentLoader, setCommentLoader] = useState(false);
  // const [searchParams] = useSearchParams();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // let id = searchParams.get('id');
  const { entry_id } = useParams();
  const getEntryDetailWithId = async () => {
    const entryResponse = await getDoc(
      doc(db, "club_entry_bookings", entry_id)
    );
    setEntryDetail(entryResponse.data());
    const userResponse = await getDoc(
      doc(db, "users", entryResponse.data().user_id)
    );
    setUserDetails(userResponse.data());
    entryResponse.data().comments &&
      setAllComments(entryResponse.data().comments);
    setGuests(entryResponse.data().people);
    setStatus(entryResponse.data().status);
  };

  // radio selection
  const handleSelectionChange = (e) => {
    setSelection(e.target.value);
    setSelectButtonError(false)
    setBookingCharge({
      ...bookingCharge,
      coverChargeAmount: 0,
      paymentLink: "",
      cardFillingPaymentLink: "",
      errors: {
        ...errors,
        coverCharger: "",
        paymentLink: "",
        cardFillingPaymentLink: "",
      },
    });
  };

    // This function is for transaction modal, We are disabling dates which are after booking date , Because transaction should not happen after booking date
    const maxTransactionDate = () => {
      if (
        !entryDetail ||
        !entryDetail.booking_date ||
        !entryDetail.booking_date.seconds
      ) {
        return null;
      }
      let bookingDate = new Date(entryDetail.booking_date.seconds * 1000);
      const year = bookingDate.getFullYear();
      const month = bookingDate.getMonth() + 1;
      const day = bookingDate.getDate();
      return `${year}-${month < 10 ? `0${month}` : month}-${day}`;
    };

    // Future dates we are not showing to user with this function
    const getCurrentDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      let month = today.getMonth() + 1;
      let day = today.getDate();

      // Pad the month and day values with leading zeros if necessary
      month = month < 10 ? `0${month}` : month;
      day = day < 10 ? `0${day}` : day;

      return `${year}-${month}-${day}`;
    };


    // Handle booking model
    const handleBookingCompletionModal = () => {
      setBookingCompletionModal(true)
    }

    //Validation Modal
    const validationModal = (type) => {
      console.log(type, "type");
      if (type === "cover_charge") {
        let errors = {};
        if (bookingCharge.coverChargeAmount === 0) {
          errors.coverCharger = "Please enter a charge amount";
        }
        if (bookingCharge.paymentLink === "") {
          errors.paymentLink = "Please enter a payment";
        }
        setBookingCharge({
          ...bookingCharge,
          errors: errors,
        });
        return Object.keys(errors).length > 0;
      } else if (type === "card_filling") {
        let errors = {};
        if (bookingCharge.cardFillingPaymentLink === "") {
          errors.cardFillingPaymentLink = "Please enter a payment url";
        }
        setBookingCharge({
          ...bookingCharge,
          errors: errors,
        });
        return Object.keys(errors).length > 0;
      }
    };


    // multiple email submit button
    const multipleEmailOnSubmit = async () => {
      setShowToaster(true);
      setToasterDetails((prev) => ({
        ...prev,
        title: "Sending...",
        bodyText: "Sending Booking Details...",
        bg: "warning",
        statusIcon: (
          <div
            className="spinner-border text-warning me-2"
            role="status"
          ></div>
        ),
      }));
      try {
        let token = localStorage.getItem("authenticate")
        let payload = {
          email_arr: multipleEmails.emails,
          id: entryDetail.id,
          booking_type: entryDetail.booking_type
        };
        await axios.post(`${process.env.REACT_APP_API_URL}/booking/v1/sendemail`, payload, {
          headers: {
            developement: true,
            token: token,
            "Content-Type": "application/json",
          },
        }).then((response) => {
          console.info(response);
          setShowModal(false);
          setToasterDetails((prev) => ({
            ...prev,
            title: "Success",
            bodyText: "Successfully sent",
            bg: "success",
            statusIcon: (
              <i className="bi bi-check text-success text-3xl me-2"></i>
            ),
          }));
          setMultipleEmails({ ...multipleEmails, email: "", emails: [], error: "" });
          setTimeout(() => {
            setShowToaster(false);
          }, 2000)
        }).catch(err => console.error(err));
      }
      catch (err) {
        setToasterDetails((prev) => ({
          ...prev,
          title: "Error",
          bodyText: "Something went wrong",
          bg: "danger",
          statusIcon: (
            <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
          ),
        }));
        setTimeout(() => {
          setShowModal(false);
        }, 2000)
      }
    }

    //email validation
    const emailValidation = (email) => {
      let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (email === "") {
        setMultipleEmails({ ...multipleEmails, error: "Please enter a email..." })
        return false;
      }
      else if (!emailRegex.test(email)) {
        setMultipleEmails({ ...multipleEmails, error: "Invalid email..." });
        return false;
      }
      // checking for existing email
      else if (multipleEmails.emails.includes(email)) {
        setMultipleEmails({ ...multipleEmails, error: "This email already added..." });
        return false;
      }
      return true;
    }

    //Multiple email handler
    const multipleEmailHandler = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        let emailValidationResult = emailValidation(e.target.value);
        if (emailValidationResult) {
          if (e.target.value.trim() !== '') {
            // setMultipleEmails({ ...multipleEmails, emails: [...emailse.target.value], email: e.target.value });
            setMultipleEmails((prevEmail) => ({
              ...prevEmail,
              emails: [...prevEmail.emails, prevEmail.email],
              error: "",
              email: '', // Clear the email input after adding it to the emails array
            }));
          }
        }
      }
    }

    //Invoice handler
    const invoiceHandler = (e) => {
      const file = e.target.files[0];
      if (file && file.type === "application/pdf") {
        setInvoice({
          ...invoice, invoiceFile: file, error: {
            ...invoice.error, file: ""
          }
        });
      } else {
        if (!file.type === "application/pdf") {
          setInvoice({ ...invoice, error: "Invalid invoice" });
        }
      }
    };

    //Invoice submission
    const invoiceSubmit = async () => {
      //checking if already error is there
      const { amount, file } = invoice.error;
      if (amount === "" || file === "") {
        const token = localStorage.getItem("authenticate");
        const headers = {
          headers: {
            developement: true,
            token: token,
            "Content-Type": "multipart/form-data",
          },
        };
        if (invoice.invoiceFile === "" || invoice.invoiceFile === undefined || invoice.amount === 0) {
          setInvoice({
            ...invoice, error: {
              ...invoice.error,
              file: (invoice.invoiceFile === "" || invoice.invoiceFile === undefined) ? "Please choose a invoice" : "",
              amount: invoice.amount === 0 ? "Please enter loyality point amount" : ""
            },
          });
          return;

        }
        //Invoice upload
        setShowToaster(true);
        setToasterDetails((prev) => ({
          ...prev,
          title: "Updating...",
          bodyText: "Uploading Invoice...",
          bg: "warning",
          statusIcon: (
            <div
              className="spinner-border text-warning me-2"
              role="status"
            ></div>
          ),
        }));
        try {
          await axios
            .post(
              `${process.env.REACT_APP_API_URL}/uploads/v1/uploadInvoice`,
              { invoice: invoice.invoiceFile },
              headers
            )
            .then(async (response) => {
              let payload = {
                invoice_url: response.data.url,
                booking_type: "club_entry_booking",
                loyality_point: parseInt(invoice.loyaltyPoint),
                id: entry_id
              };

              await axios.post(`${process.env.REACT_APP_API_URL}/booking/v1/creditLoyalityPoint`, payload, {
                headers: {
                  developement: true,
                  token: token,
                  "Content-Type": "application/json",
                },
              })
                .then(() => {

                  setShowModal(false);
                  setToasterDetails((prev) => ({
                    ...prev,
                    title: "Success",
                    bodyText: "Updated the status successfully",
                    bg: "success",
                    statusIcon: (
                      <i className="bi bi-check text-success text-3xl me-2"></i>
                    ),
                  }));
                  setInvoice({ ...invoice, error: "", invoiceFile: "" });
                  setTimeout(() => {
                    navigate("/bookings?tab=clubs");
                  }, 2000);
                })
                .catch((err) => console.log(err));
            })
            .catch((err) => console.log(err));
        } catch (err) {
          setToasterDetails((prev) => ({
            ...prev,
            title: "Error",
            bodyText: "Something went wrong",
            bg: "danger",
            statusIcon: (
              <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
            ),
          }));
          setTimeout(() => {
            setShowModal(false);
          }, 2000);
        }

      }

    };


    // Form Validation
    const formValidation = () => {
      const regexPattern = /^\s*$/;
      const {
        reason,
        trasactionDate,
        trasactionId,
        transactionAmount,
      } = bookingCancellationDetails;
      let errors = {};
      if (reason === "") {
        errors.reason = "Please enter a reason";
      }
      if (trasactionDate === "") {
        errors.transactionDate = "Please enter a transaction date";
      }
      if (trasactionId === "") {
        errors.trasactionId = "Please enter a transaction Id";
      }
      if (transactionAmount === "") {
        errors.transactionAmount = "Please enter a transaction amount";
      }
      if (reason !== "") {
        if (regexPattern.test(reason))
          errors.reason = "Only alpha-numeric value allowed";
      }
      if (trasactionId !== "") {
        if (regexPattern.test(trasactionId))
          errors.trasactionId = "Only alpha-numeric value allowed";
      }
      if (transactionAmount !== "") {
        if (regexPattern.test(transactionAmount))
          errors.transactionAmount = "Only alpha-numeric value allowed";
      }
      setCancelBookingErrors(errors);
      return Object.keys(errors).length === 0;
    };

    //Handling Transaction details
    const handleTransaction = (e) => {
      e.preventDefault();
      // Validation
      let { transactionId, transactionDate } = transactionDetails.data;
      if (transactionId === "" || transactionDate === "") {
        let transactionTempForError = transactionDetails;
        if (transactionDate === "") {
          transactionTempForError.errors.id = "Please enter a transaction ID";
        }
        if (transactionDate === "") {
          transactionDetails.errors.date = "Transaction date should not be empty";
        }
        setTransactionDetails({ ...transactionTempForError });
        return;
      }
      if (
        [transactionDetails.errors.date, transactionDetails.errors.id].includes(
          ""
        )
      ) {
        actionSubmit("Approved");
      }
    };
    // api call
    const formCancellationApiCall = async () => {
      const jwtToken = localStorage.getItem("authenticate");
      setCancelBookingModal(false);
      setShowToaster(true);
      setToasterDetails((prev) => ({
        ...prev,
        title: "Cancelling...",
        bodyText: "Cancelling the booking...",
        bg: "warning",
        statusIcon: (
          <div className="spinner-border text-warning me-2" role="status"></div>
        ),
      }));
      let payload = {
        id: entry_id,
        booking_type: entryDetail.booking_type,
        refund_transaction_id: bookingCancellationDetails.trasactionId,
        refund_transaction_date: bookingCancellationDetails.trasactionDate,
        reason_for_cancellation: bookingCancellationDetails.reason,
      };
      await axios
        .post(`${process.env.REACT_APP_API_URL}/booking/v1/bookingCancellation`, payload, {
          headers: {
            developement: true,
            token: jwtToken,
          },
        })
        .then((response) => {
          setShowModal(false);
          setToasterDetails((prev) => ({
            ...prev,
            title: "Success",
            bodyText: "Successfully Cancelled",
            bg: "success",
            statusIcon: (
              <i className="bi bi-check text-success text-3xl me-2"></i>
            ),
          }));

          setTimeout(() => {
            navigate("/bookings?tab=clubs");
          }, 2000);
        })
        .catch((error) => console.log(error));
    };

    const handleCancelBooking = async () => {
      let validationResponse = formValidation();
      if (validationResponse) {
        setCancelBookingModal(true);
      }
    };

    //Modal handler
    //Modal handler
    const handleClose = () => {
      setShowModal(false);
      setSelectButtonError(false)
      setBookingCharge({
        ...bookingCharge,
        coverChargeAmount: 0,
        paymentLink: "",
        cardFillingPaymentLink: "",
        errors: {
          ...errors,
          coverCharger: "",
          paymentLink: "",
          cardFillingPaymentLink: "",
        },
      });
      setSelection("");
    };
    const handleShow = () => setShowModal(true);

    const actionSubmit = async (type) => {
      // setIsLoading(true);
      let payload = {};
      if (type === "Approved") {
        const jwtToken = localStorage.getItem("authenticate");
        payload = {
          id: entry_id,
          status: type,
          booking_type: entryDetail.booking_type,
          transaction_id: transactionDetails.data.transactionId,
          transaction_date: transactionDetails.data.transactionDate,
        };
        try {
          await axios
            .post(
              `${process.env.REACT_APP_API_URL}/booking/v1/updateBookingStatus`,
              payload,
              {
                headers: {
                  developement: true,
                  token: jwtToken,
                },
              }
            )
            .then(() => {
              setShowModal(false);
              setToasterDetails((prev) => ({
                ...prev,
                title: "Success",
                bodyText: "Updated the status successfully",
                bg: "success",
                statusIcon: (
                  <i className="bi bi-check text-success text-3xl me-2"></i>
                ),
              }));

              setTimeout(() => {
                navigate("/bookings?tab=table");
              }, 2000);
            });

        } catch (err) {
          setToasterDetails((prev) => ({
            ...prev,
            title: "Error",
            bodyText: "Something went wrong",
            bg: "danger",
            statusIcon: (
              <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
            ),
          }));

          setTimeout(() => {
            setShowToaster(false);
          }, 2000);
          console.log(err, "error");
        }
      } else {
        if (reason.error === "") {
          if (reason.reason === "") {
            setReason({ ...reason, error: "Please enter a reason" });
            return;
          }
        }

        setshowRejectionModal(false);
        setShowToaster(true);
        setToasterDetails((prev) => ({
          ...prev,
          title: "Updating...",
          bodyText: "Updating Request",
          bg: "warning",
          statusIcon: (
            <div className="spinner-border text-warning me-2" role="status"></div>
          ),
        }));

        payload = {
          status: type,
          id: entry_id,
          booking_type: entryDetail.booking_type,
        };
        try {
          const jwtToken = localStorage.getItem("authenticate");
          await axios.post(
            `${process.env.REACT_APP_API_URL}/booking/v1/updateBookingStatus`,
            payload,
            {
              headers: {
                developement: true,
                token: jwtToken,
              },
            }
          );
          // setIsLoading(false);
          // setIsSuccess(true);
          setToasterDetails((prev) => ({
            ...prev,
            title: "Success",
            bodyText: "Updated request successfully",
            bg: "success",
            statusIcon: <i className="bi bi-check text-success text-3xl me-2"></i>,
          }));
          setTimeout(() => {
            navigate("/bookings?tab=clubs");
          }, 2000);
          // navigate("/bookings?tab=clubs");
        } catch (err) {
          // setIsLoading(false);
          // setIsError(true);
          setToasterDetails((prev) => ({
            ...prev,
            title: "Error",
            bodyText: "Something went wrong",
            bg: "danger",
            statusIcon: (
              <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
            ),
          }));
          setTimeout(() => {
            setShowToaster(false);
          }, 2000);
          console.log(err, "error");
        }
      }
    };
    function getAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    }


  const userDetailsList = [
    {
      query: "User",
      userResponse:
        entryDetail &&
        entryDetail.user &&
        entryDetail.user.name &&
        entryDetail.user.name,
    },
    {
      query: "Booking ID",
      userResponse: entryDetail && entryDetail.booking_id,
    },
    {
      query: "Club Name",
      userResponse:
        entryDetail && entryDetail.club_name && entryDetail.club_name,
    },
    {
      query: "Club location",
      userResponse:
        entryDetail && entryDetail.club_name && entryDetail.club_location,
    },
    {
      query: "Arrival Time",
      userResponse:
        entryDetail &&
        entryDetail.club_name &&
        entryDetail.expected_arrival_time,
    },
    {
      query: "Guest Count",
      userResponse:
        entryDetail && entryDetail.club_name && entryDetail.attendee_count,
    },
    {
      query: "Booking Date",
      userResponse:
        entryDetail.booking_date &&
        moment(
          new Date(entryDetail.booking_date.seconds * 1000).toISOString()
        ).format("DD/MM/YYYY"),
    },
    {
      query: "Status",
      userResponse: entryDetail && entryDetail.status,
    },
    {
      query: "Amount",
      userResponse: entryDetail && `USD $ ${entryDetail.amount}`,
    },
    {
      query: "Transacion date",
      userResponse:
        entryDetail &&
        entryDetail.transaction_date &&
        entryDetail.transaction_date.seconds &&
        moment(
          new Date(entryDetail.transaction_date.seconds * 1000).toISOString()
        ).format("DD MMM YYYY"),
    },
    {
      query: "Transacion ID",
      userResponse: entryDetail && entryDetail.transaction_id,
    },
    {
      query: "Choosen payment option",
      userResponse: entryDetail.cover_charge === "required" ? "Cover Charge" : "Card filling"
    },
    {
      query: "Cover charge amount",
      userResponse: entryDetail && entryDetail.cover_charge_amount
    },
    {
      query: "Payment link",
      userResponse: entryDetail && entryDetail.cover_charge_payment_link
    },

  ];

  useEffect(() => {
    getEntryDetailWithId();
  }, []);

  //Handling Charge details
  const handleCoverCharge = async (type) => {
    if (type === "") {
      setSelectButtonError(true);
      return;
    }
    let validation = validationModal(type);
    if (!validation) {
      if (type === "cover_charge") {
        setShowModal(false);
        setShowToaster(true);
        setToasterDetails((prev) => ({
          ...prev,
          title: "Updating...",
          bodyText: "Updating the status",
          bg: "warning",
          statusIcon: (
            <div
              className="spinner-border text-warning me-2"
              role="status"
            ></div>
          ),
        }));
        let payload = {
          id: entry_id,
          booking_type: entryDetail.booking_type,
          payment_link: bookingCharge.paymentLink,
          cover_charge: "required",
          cover_charge_amount: bookingCharge.coverChargeAmount,
        };
        const jwtToken = localStorage.getItem("authenticate");
        await axios
          .post(
            `${process.env.REACT_APP_API_URL}/booking/v1/updateCoverCharges`,
            payload,
            {
              headers: {
                developement: true,
                token: jwtToken,
              },
            }
          )
          .then(() => {
            setShowModal(false);
            setToasterDetails((prev) => ({
              ...prev,
              title: "Success",
              bodyText: "Updated the status successfully",
              bg: "success",
              statusIcon: (
                <i className="bi bi-check text-success text-3xl me-2"></i>
              ),
            }));
            setTimeout(() => {
              navigate("/bookings?tab=clubs");
            }, 2000);
            setBookingCharge({
              ...bookingCharge,
              paymentLink: "",
              coverChargeAmount: 0,
              errors: {
                ...errors,
                paymentLink: "",
                coverCharger: "",
              },
            });
          })
          .catch((err) => {
            setToasterDetails((prev) => ({
              ...prev,
              title: "Not Updated",
              bodyText: "Something went wrong",
              bg: "danger",
              statusIcon: (
                <i className="bi bi-check text-success text-3xl me-2"></i>
              ),
            }));
          });
      } else if (type === "card_filling") {
        setShowModal(false);
        setShowToaster(true);
        setToasterDetails((prev) => ({
          ...prev,
          title: "Updating...",
          bodyText: "Updating the status",
          bg: "warning",
          statusIcon: (
            <div
              className="spinner-border text-warning me-2"
              role="status"
            ></div>
          ),
        }));
        const jwtToken = localStorage.getItem("authenticate");
        let payload = {
          id: entry_id,
          booking_type: entryDetail.booking_type,
          payment_link: bookingCharge.cardFillingPaymentLink,
          cover_charge: "card filling required",
          cover_charge_amount: null,
        };
        await axios
          .post(
            `${process.env.REACT_APP_API_URL}/booking/v1/updateCoverCharges`,
            payload,
            {
              headers: {
                developement: true,
                token: jwtToken,
              },
            }
          )
          .then(() => {
            setToasterDetails((prev) => ({
              ...prev,
              title: "Success",
              bodyText: "Updated the status successfully",
              bg: "success",
              statusIcon: (
                <i className="bi bi-check text-success text-3xl me-2"></i>
              ),
            }));
            setTimeout(() => {
              navigate("/bookings?tab=clubs");
            }, 2000);
            setBookingCharge({
              ...bookingCharge,
              paymentLink: "",
              coverChargeAmount: 0,
              errors: {
                ...errors,
                paymentLink: "",
                coverCharger: "",
              },
            });
          })
          .catch((err) => {
            setToasterDetails((prev) => ({
              ...prev,
              title: "Not Updated",
              bodyText: "Something went wrong",
              bg: "danger",
              statusIcon: (
                <i className="bi bi-check text-success text-3xl me-2"></i>
              ),
            }));
          });
      } else {
        setShowModal(false);
        setShowToaster(true);
        setToasterDetails((prev) => ({
          ...prev,
          title: "Updating...",
          bodyText: "Updating the status",
          bg: "warning",
          statusIcon: (
            <div
              className="spinner-border text-warning me-2"
              role="status"
            ></div>
          ),
        }));
        const jwtToken = localStorage.getItem("authenticate");
        let payload = {
          id: entry_id,
          booking_type: entryDetail.booking_type,
          cover_charge: "not required",
        };
        await axios
          .post(
            `${process.env.REACT_APP_API_URL}/booking/v1/updateCoverCharges`,
            payload,
            {
              headers: {
                developement: true,
                token: jwtToken,
              },
            }
          )
          .then(() => {
            setToasterDetails((prev) => ({
              ...prev,
              title: "Success",
              bodyText: "Updated the status successfully",
              bg: "success",
              statusIcon: (
                <i className="bi bi-check text-success text-3xl me-2"></i>
              ),
            }));
            setTimeout(() => {
              navigate("/bookings?tab=clubs");
            }, 2000);
            setBookingCharge({
              ...bookingCharge,
              paymentLink: "",
              coverChargeAmount: 0,
              errors: {
                ...errors,
                paymentLink: "",
                coverCharger: "",
              },
            });
          })
          .catch((err) => {
            setToasterDetails((prev) => ({
              ...prev,
              title: "Not Updated",
              bodyText: "Something went wrong",
              bg: "danger",
              statusIcon: (
                <i className="bi bi-check text-success text-3xl me-2"></i>
              ),
            }));
          });
      }
    }
  };

  const amethystMembershipFeatures = [
    "Exclusively for Cabin Crew",
    "Two free drink for 365 nights a year",
    "No free/free walk-in to any HTP integrated Clubs",
    "Access to accumulated rewards after 30 days",
    "No access to discover ladies’ nights",
    "No Invitation to exclusive celebrity events",
    "No priority booking for clubs & events",
  ];

  useEffect(() => {
    addingElementToArray(guests, {
      name: userDetails.name,
      gender: userDetails.gender,
      age:
        userDetails && userDetails.dob
          ? getAge(
            new Date(
              moment(userDetails.dob, "DD-MM-YYYY").format("MM-DD-YYYY")
            )
          )
          : "",
    });
  }, [guests]);

  const onSubmit = async (data) => {
    setCommentLoader(true);
    let id = uuid();
    const updateData = {
      comments: [
        {
          comment: data.message,
          createdAt: new Date(),
          user_id: user_id,
          user_name: user_email,
          id: id,
        },
        ...allComments,
      ],
    };
    try {
      const eventUpdateResponse = await updateDoc(
        doc(db, "club_entry_bookings", entry_id),
        updateData
      );
      getEntryDetailWithId();
      setCommentLoader(false);
      reset();
      // navigate("/bookings?tab=clubs");
    } catch (err) {
      setCommentLoader(false);
    }
  };
  const deleteThisComment = async (id) => {
    let removedData = allComments;
    removedData = allComments.filter((data) => data.id != id);
    const updateData = {
      comments: [...removedData],
    };
    try {
      const eventUpdateResponse = await updateDoc(
        doc(db, "club_entry_bookings", entry_id),
        updateData
      );
      getEntryDetailWithId();
      reset();
    } catch (err) {
      console.log(err, "error");
    }
  };
  function addingElementToArray(array, newItem) {
    let guestsItem = array;
    guestsItem.unshift(newItem);
    setGuestsToShow(guestsItem);
  }

  return (
    <Layout path="/manager/entry-details">
      <ContentWrapper additionalClass="p-5">
        <h2 className="color-heading font-body font-semibold text-lg text-lg-2xl pb-6 mb-0">
          Club Entry Details
        </h2>
        <Row className="g-4">
          <Col xs={12}>
            <figure>
              <figcaption className="color-lightblue font-semibold text-md text-lg-lg mb-1">
                Club image
              </figcaption>
              {entryDetail.club_display_image && (
                <img
                  className="img-fluid rounded"
                  src={entryDetail && entryDetail.club_display_image}
                  style={{ width: "500px", height: "300px" }}
                  alt="Amethyst Membership Card"
                />
              )}
            </figure>
          </Col>
          {userDetailsList &&
            userDetailsList.map((item, index) => {
              if (item.userResponse) {
                return (
                  <Col xs={6} lg={3} className=" font-body" key={index}>
                    <article>
                      <h4 className="color-lightestblue text-sm font-semibold mb-0 font-body">
                        {item.query}
                      </h4>
                      {item.query === "Status" ? (
                        <div
                          style={{
                            backgroundColor: `${item.userResponse === "Approved"
                              ? "#13DEB9"
                              : item.userResponse === "Waiting for approval"
                                ? "#FFAE1F"
                                : item.userResponse === "Rejected"
                                  ? "#FA896B"
                                  : item.userResponse === "Cancelled"
                                    ? "#FA896B"
                                    : item.userResponse === "Completed"
                                      ? "#13DEB9"
                                      : null
                              }`,
                          }}
                          className="rounded d-inline-block px-3 text-white"
                        >
                          {item.userResponse === "Waiting for approval"
                            ? "Pending"
                            : item.userResponse}
                        </div>
                      ) : (
                        <p className="color-lightblue font-semibold text-md text-lg-lg mb-0 font-body text-wrap text-break">
                          {item.userResponse}
                        </p>
                      )}
                    </article>
                  </Col>
                );
              }
            })
          }

<Col xs={12}>
            <div className="text-end">
              {(status === "Waiting for approval" ||
                status === "Booking request pending") && (
                  <div>
                    <OutlinedButton
                      onClick={() => {
                        // actionSubmit("Rejected"
                        setshowRejectionModal(true);
                      }}
                      name="Reject"
                      additionalClass="me-4"
                    />

                    <SuccessButton
                      onClick={() => {
                        // actionSubmit("Approved");
                        handleShow(true);
                      }}
                      name="Accept"
                      icon="bi-check-circle"
                      additionalClass="px-5"
                    />
                  </div>
                )}

              {/* <div> */}
              {status === "Waiting for payment verification" && (
                <div>
                  <OutlinedButton
                    onClick={() => {
                      setshowRejectionModal(true);
                    }}
                    name="Reject Booking"
                    additionalClass="me-4"
                  />

                  <SuccessButton
                    onClick={() => {
                      // actionSubmit("Approved");
                      // actionSubmit("Approved")
                      setBookingCompletionModal(true);
                    }}
                    name="Approve Booking"
                    icon="bi-check-circle"
                    additionalClass="px-5"
                  />
                  {/* <p className="text-lg font-semibold mt-4">
                    Status on pending
                  </p> */}
                </div>
              )}
              {/* </div> */}
            </div>
          </Col>
        </Row>
      </ContentWrapper>

      <ContentWrapper additionalClass="p-5 mt-5">
        <h2 className="color-heading font-body font-semibold text-lg text-lg-2xl pb-6 mb-0">
          User details
        </h2>
        <Row className="g-4">
          <Col xs={6} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              User name
            </p>
            <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
              {userDetails && userDetails.name}
            </p>
          </Col>
          <Col xs={6} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              Email
            </p>

            <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
              {userDetails && userDetails.email}
            </p>
          </Col>
          <Col xs={6} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              Phone number
            </p>

            <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
              {userDetails &&
                userDetails.contacts &&
                userDetails.contacts[0] &&
                userDetails.contacts[0].contact_no}
            </p>
          </Col>
          <Col xs={6} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              Date of birth
            </p>
            <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
              {userDetails && userDetails.dob}
            </p>
          </Col>
          <Col xs={6} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              Gender
            </p>
            <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
              {userDetails && userDetails.gender}
            </p>
          </Col>
          <Col xs={6} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              Home country
            </p>
            <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
              {userDetails && userDetails.home_country_name}
            </p>
          </Col>
          <Col xs={6} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              Home city
            </p>
            <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
              {userDetails && userDetails.home_city_name}
            </p>
          </Col>
          <Col xs={6} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              Active membership
            </p>

            {userDetails && (
              <div
                style={{
                  backgroundColor: `${userDetails.active_membership_name === "Amethyst"
                    ? "#955196"
                    : userDetails.active_membership_name === "Solitaire"
                      ? "#dd5182"
                      : userDetails.active_membership_name === "Platinum"
                        ? "#FA896B"
                        : userDetails.active_membership_name === "Gold"
                          ? "#ff6e54"
                          : userDetails.active_membership_name === "Silver"
                            ? "#13DEB9"
                            : null
                    }`,
                }}
                className="rounded d-inline-block px-3 text-white"
              >
                {userDetails.active_membership_name}
              </div>
            )}
          </Col>
          <Col xs={6} xl={3}>
            <div>
              <h5>User Status</h5>
              <div className="d-flex align-items-center">
                <p className="mb-0 me-2">Active</p>
                {userDetails.status === "Active" ? (
                  <input type="checkbox" className="po-checkbox" checked />
                ) : (
                  <input type="checkbox" className="po-checkbox" />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </ContentWrapper>
      <ContentWrapper additionalClass="p-5 mt-5">
        <Col xs={12}>
          {guestsToShow.length > 0 ? (
              <>
                <h2 className="color-heading font-body font-semibold text-lg text-lg-2xl pb-6 mb-0">
                  Guest Lists
                </h2>
                <Table responsive hover>
                  <thead>
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">Name</th>
                      <th scope="col">Gender</th>
                      <th scope="col">Age</th>
                    </tr>
                  </thead>
                  <tbody>
                    {guestsToShow &&
                      guestsToShow.map((guest, index = 0) => {
                        return (
                          <tr key={index}>
                            <td className="align-middle">{index + 1}</td>
                            <td className="align-middle">{guest.name}</td>
                            <td className="align-middle">{guest.gender}</td>
                            <td className="align-middle">{guest.age}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </>
            ) : (
              <p className="color-lightblue font-semibold text-md text-lg-lg mb-0 font-body text-danger">
                No Guests are there
              </p>
            )
          }
        </Col>
      </ContentWrapper>

      {/* Upload Invoice  */}
      {entryDetail.status === "Approved"
        && (
          <ContentWrapper additionalClass="p-5 mt-4">
            <h5>Upload Invoice</h5>
            {entryDetail.status === "Approved" && (
              <div>
                <p className="color-lightestblue font-normal text-sm mb-0 font-body p-1">
                  Click on the button to upload
                </p>

                <div className="w-100">
                  <input
                    className="po-edit-input"
                    type="file"
                    accept="application/pdf"
                    onChange={invoiceHandler}
                  />
                  {/* <Document
            file="path/to/your/pdf.pdf"

          /> */}

                  {invoice.error.file && (
                    <p className="text-danger">{invoice.error.file}</p>
                  )}
                  {/* Invoice amount */}
                  <div className="mt-3">
                    <input type="Number" className="po-edit-input" placeholder="Enter amount (minimum 10)" min="10"
                      onChange={(e) => {
                        let value = e.target.value;
                        let loyaltyPoint = 7 / 100 * value;
                        setInvoice({
                          ...invoice, error: {
                            ...invoice.error,
                            amount: value < 10 ? "Please enter grether than 10" : ""
                          },
                          amount: value,
                          loyaltyPoint: Math.round(loyaltyPoint)
                        })
                      }}
                    />
                  </div>

                  {invoice.error.amount && <p className="text-danger">{invoice.error.amount}</p>}
                  <div className="d-flex justify-content-between mt-4">
                    <p className="bold">Loyalty Points : {invoice.amount > 10 ? invoice.loyaltyPoint : 0}</p>
                    <SecondaryButton
                      type="submit"
                      name="Upload"
                      onClick={invoiceSubmit}
                      additionalClass="mt-3"
                    />
                  </div>
                </div>
              </div>
            )}


            {entryDetail.status === "Completed" && (
              <div>
                {/* <p className="text-warning">Click to view above invoice</p> */}
                <a href={entryDetail.invoice_url} target="_blank">
                  Click to view invoice
                </a>
              </div>
            )}
          </ContentWrapper>
        )}
      {/* Upload Invoice  */}

      {/* Booking Cancellation*/}
      {status === "Approved" && (
        <ContentWrapper additionalClass="p-5">
          <div className="d-flex justify-content-between">
            <h2 className="color-lightblue font-semibold text-md text-lg-lg mb-0 font-body">
              Booking Cancellation
            </h2>
            {/* <Button variant="warning" className="m-3" onClick={(e) => setEditable({ ...editable, status: true, className: "po-edit-input" })}>Edit Cancellation</Button> */}
          </div>
          <Row className="g-3 mt-1 ">
            <Col xs={6}>
              <div className="">
                <label className="color-lightestblue font-normal text-sm mb-0 font-body">
                  Reason for Cancellation
                </label>
                <textarea
                  className="po-edit-input"
                  style={{ height: "90px" }}
                  placeholder="Please enter the reason..."
                  onChange={(e) => {
                    let { value } = e.target;
                    setCancelBookingErrors({
                      ...cancelBookingErrors,
                      reason: value ? "" : "Field should not be empty",
                    });
                    setBookingCancellationDetails({
                      ...bookingCancellationDetails,
                      reason: value,
                    });
                  }}
                ></textarea>
                {cancelBookingErrors.reason && (
                  <p className="text-danger">{cancelBookingErrors.reason}</p>
                )}
              </div>
            </Col>
            <Col xs={6}>
              <div className="">
                <label className="color-lightestblue font-normal text-sm mb-0 font-body">
                  Transaction Id
                </label>
                <input
                  type="text"
                  className="po-edit-input"
                  placeholder="Please enter transaction id..."
                  onChange={(e) => {
                    const regexPattern = /^\s*$/;
                    let { value } = e.target;
                    let isValid = regexPattern.test(value);
                    setCancelBookingErrors({
                      ...cancelBookingErrors,
                      trasactionId: value ? "" : "Field should not be empty",
                    });
                    setBookingCancellationDetails({
                      ...bookingCancellationDetails,
                      trasactionId: value,
                    });
                  }}
                />
                {cancelBookingErrors.trasactionId && (
                  <p className="text-danger">
                    {cancelBookingErrors.trasactionId}
                  </p>
                )}
              </div>
            </Col>
            <Col xs={6}>
              <div className="">
                <label className="color-lightestblue font-normal text-sm mb-0 font-body">
                  Transaction Date
                </label>
                <input
                  type="date"
                  className="po-edit-input"
                  max={getCurrentDate()}
                  onChange={(e) => {
                    const regexPattern = /^\s*$/;
                    let { value } = e.target;
                    setCancelBookingErrors({
                      ...cancelBookingErrors,
                      transactionDate: value ? "" : "Field should not be empty",
                    });
                    setBookingCancellationDetails({
                      ...bookingCancellationDetails,
                      trasactionDate: value,
                    });
                  }}
                />
                {cancelBookingErrors.transactionDate && (
                  <p className="text-danger">
                    {cancelBookingErrors.transactionDate}
                  </p>
                )}
              </div>
            </Col>
            <Col xs={6}>
              <div className="">
                <label className="color-lightestblue font-normal text-sm mb-0 font-body">
                  Transaction Amount
                </label>
                <input
                  type="number"
                  className="po-edit-input"
                  placeholder="Please enter amount..."
                  onChange={(e) => {
                    const regexPattern = /^\s*$/;
                    let { value } = e.target;
                    setCancelBookingErrors({
                      ...cancelBookingErrors,
                      transactionAmount: value
                        ? ""
                        : "Field should not be empty",
                    });

                    setBookingCancellationDetails({
                      ...bookingCancellationDetails,
                      transactionAmount: value,
                    });
                  }}
                />
                {cancelBookingErrors.transactionAmount && (
                  <p className="text-danger">
                    {cancelBookingErrors.transactionAmount}
                  </p>
                )}
              </div>
            </Col>
          </Row>
          <div className="d-flex justify-content-end">
            <Button
              variant="success"
              className="mt-3"
              type="submit"
              onClick={handleCancelBooking}
            >
              Cancel Booking
            </Button>
          </div>
        </ContentWrapper>
      )}
      {/* Booking Cancellation*/}

      {/* Multiple Emails Sending  */}
      <ContentWrapper additionalClass="p-5 mt-5">
        <div>
          <h2 className="color-lightblue font-semibold text-md text-lg-lg mb-3 font-body">
            Send Booking Details
          </h2>
          {/* <p className="m-0 p-0 text-info text-sm" >Please press enter key to add</p> */}
          <label className="color-lightestblue font-normal text-sm mb-0 font-body">
            Enter email addresses
          </label>
          <input type="email"
            className="po-edit-input"
            placeholder="Press enter to add email..."
            value={multipleEmails.email}
            onChange={(e) => {
              var regex = /^.+\s.+$/g;
              if (regex.test(e.target.value)) {
                setMultipleEmails((prev) => ({
                  ...prev, error: "Please enter valid email address"
                }))
              }
              else {
                setMultipleEmails((prev) => ({
                  ...prev, error: ""
                }))
              }
              setMultipleEmails((prev) => ({
                ...prev, email: e.target.value

              }))

            }}
            onKeyDown={multipleEmailHandler}
          />
        </div>
        {multipleEmails.error && <p className="text-danger m-0">{multipleEmails.error}</p>}
        <div className="d-flex">
          {multipleEmails.emails && multipleEmails.emails.length > 0 && multipleEmails.emails.map((email, index) => {
            return (

              <p className="p-2 my-2 me-2 border border-light rounded bg-light text-md semibold">{email} <ActionIcon
                onClick={() => {
                  let prevMultipleEmails = multipleEmails.emails;
                  prevMultipleEmails.splice(index, 1);
                  setMultipleEmails({ ...multipleEmails, emails: [...prevMultipleEmails] });
                }}
                additionalClass="bi bi-x-circle-fill text-1xl color-red me-2"
              /></p>
            )
          })
          }
        </div>
        {multipleEmails.emails.length > 0 &&
          <div className="d-flex justify-content-end">
            <SecondaryButton
              type="submit"
              name="Send email"
              onClick={multipleEmailOnSubmit}
              additionalClass="mt-3"
            />
          </div>
        }
      </ContentWrapper>
      {/* Multiple Emails Sending  */}

      <ContentWrapper additionalClass="p-5 mt-5">
        <Row>
          <Col xs={6}>
            <h5 className="color-heading font-body font-semibold text-lg text-lg-2xl pb-6 mb-0">
              Make a Note.
            </h5>
            <ul className="mt-3">
              {entryDetail &&
                entryDetail.comments &&
                entryDetail.comments.length > 0 &&
                entryDetail.comments.map((data, index) => {
                  return (
                    <li key={index}>
                      <div className="shadow-sm p-3 mb-5 bg-white rounded font-body d-flex justify-content-between">
                        <div className="d-flex align-items-start">
                          <div
                            style={{
                              height: "60px",
                              width: "60px",
                              backgroundColor: "#ededed",
                            }}
                            className="d-flex align-items-center justify-content-center rounded-circle"
                          >
                            <i className="bi bi-person-circle text-4xl"></i>
                          </div>
                          <div className="ms-3">
                            <div className="mb-1 d-flex align-items-center">
                              <div className="text-sm font-bold me-3">
                                {data.user_name
                                  ? data.user_name
                                  : "Unknown user"}
                              </div>
                              <div className="text-xxs">
                                {data.createdAt &&
                                  moment(
                                    new Date(
                                      data.createdAt.seconds * 1000
                                    ).toISOString()
                                  ).format("DD/MM/YYYY")}
                              </div>
                            </div>
                            <div className="w-100">
                              <p className="text-sm mb-0 text-wrap">
                                {data.comment}
                              </p>
                            </div>
                          </div>
                        </div>
                        <i
                          className="bi bi-trash-fill"
                          onClick={() => deleteThisComment(data.id)}
                          style={{ cursor: "pointer" }}
                        ></i>
                      </div>
                    </li>
                  );
                })}
            </ul>

            <form onSubmit={handleSubmit(onSubmit)} className="mt-5 font-body">
              <textarea
                className="po-edit-input"
                id="message"
                {...register("message", { required: true })}
              />
              {errors.message && (
                <p className="text-sm text-danger mt-1">
                  Please enter a Comment.
                </p>
              )}

              <div className="text-end d-flex justify-content-end align-items-center mb-6">
                {commentLoader && (
                  <img
                    src="/assets/icons/loading_icon.gif"
                    alt="processing"
                    width={100}
                  />
                )}
                <SecondaryButton
                  type="submit"
                  additionalClass="mt-4"
                  name="Comment"
                />
              </div>
            </form>
          </Col>
        </Row>
      </ContentWrapper>

      <Modal
        show={showCancelBookingModal}
        onHide={() => {
          setCancelBookingModal(false);
        }}
      >
        <div className="background-appColor p-5 rounded font-body">
          <h5 className="text-2xl  color-ternary  mb-3">
            Confirm cancellation
          </h5>
          <div>
            <p className="background-appColor text-light border-bottom-0 py-1 ">
              Are you sure you want to cancel booking?
            </p>
          </div>
          <div className="mt-6 text-end">
            <SecondaryButton
              onClick={() => setCancelBookingModal(false)}
              name="No"
              additionalClass="text-light me-3 px-5"
            />
            <PrimaryButton onClick={formCancellationApiCall} name="Yes" />
          </div>
        </div>
      </Modal>

      {/* Approve modal */}
      <Modal show={showModal} onHide={handleClose}>
        <div className="background-appColor p-5 rounded font-body">
          {/* <h5 className="text-2xl  color-ternary  mb-3">Transaction details</h5> */}
          <h3 className="text-2xl color-ternary">
            Does this booking require cover charge?
          </h3>

          <div>
            <div className="d-flex align-items-center mb-3">
              <label className="ms-2 text-light">
                <input
                  type="radio"
                  value="option1"
                  checked={selection === "option1"}
                  onChange={handleSelectionChange}
                />
                <span className="ml-2">Yes required</span></label>
            </div>
            <div className="d-flex align-items-center mb-3">
              <label className="ms-2 text-light">
                <input
                  type="radio"
                  value="option2"
                  checked={selection === "option2"}
                  onChange={handleSelectionChange}
                />
                <span className="ml-2">Card filing required</span></label>
            </div>
            <div className="d-flex align-items-center mb-3">
              <label className="ms-2 text-light">
                <input
                  type="radio"
                  value="option3"
                  checked={selection === "option3"}
                  onChange={handleSelectionChange}
                />
                <span className="ml-2">No not required</span></label>
            </div>

            {selection === "option1" && (
              <div>
                <div className="mb-3">
                  <label className="d-block mb-1 text-md text-light">
                    Amount (Cover charge)
                  </label>
                  <input
                    min={0}
                    type="Number"
                    className="po-edit-input"
                    value={bookingCharge.coverChargeAmount}
                    placeholder="Enter amount"
                    onChange={(e) => {
                      let value = e.target.value === 0;
                      setBookingCharge({
                        ...bookingCharge,
                        errors: {
                          ...bookingCharge.errors,
                          coverCharger: value ? "Enter some value" : "",
                        },
                        coverChargeAmount: Number(e.target.value),
                      });
                    }}
                  />
                </div>
                {bookingCharge.errors.coverCharger && (
                  <p className="text-danger">
                    {bookingCharge.errors.coverCharger}
                  </p>
                )}
                <div className="mb-3">
                  <label className="d-block mb-1 text-md text-light">
                    Enter payment link
                  </label>
                  <input
                    type="text "
                    value={bookingCharge.paymentLink}
                    className="po-edit-input"
                    placeholder="Enter link"
                    onChange={(e) => {
                      const urlPattern = new RegExp(
                        '^(https?://)?([a-zA-Z0-9.-]+)\\.([a-zA-Z]{2,})(:[0-9]{2,5})?(/.*)?$'
                      );
                      let value = e.target.value === "";
                      setBookingCharge({
                        ...bookingCharge,
                        errors: {
                          ...bookingCharge.errors,
                          paymentLink: value
                            ? "Field should be empty"
                            : e.target.value.trim().length === 0 ? "Whitespace not allowed" :
                              !urlPattern.test(e.target.value) ? "Url only allowed" : "",
                        },
                        paymentLink: e.target.value,
                      });
                    }}
                  />
                </div>
                {bookingCharge.errors.paymentLink && (
                  <p className="text-danger">
                    {bookingCharge.errors.paymentLink}
                  </p>
                )}
              </div>
            )}

            {selection === "option2" && (
              <div>
                <div className="mb-3">
                  <label className="d-block mb-1 text-md text-light">
                    Enter payment link
                  </label>
                  <input
                    type="text "
                    value={bookingCharge.cardFillingPaymentLink}
                    className="po-edit-input"
                    placeholder="Enter link"
                    onChange={(e) => {
                      const urlPattern = new RegExp(
                        '^(https?://)?([a-zA-Z0-9.-]+)\\.([a-zA-Z]{2,})(:[0-9]{2,5})?(/.*)?$'
                      );
                      let value = e.target.value === "";
                      setBookingCharge({
                        ...bookingCharge,
                        errors: {
                          ...bookingCharge.errors,
                          cardFillingPaymentLink: value
                            ? "Field should be empty"
                            : e.target.value.trim().length === 0 ? "Whitespace not allowed" :
                              !urlPattern.test(e.target.value) ? "Url Only allowed" : "",
                        },
                        cardFillingPaymentLink: e.target.value,
                      });
                    }}
                  />
                </div>
                {bookingCharge.errors.cardFillingPaymentLink && (
                  <p className="text-danger">
                    {bookingCharge.errors.cardFillingPaymentLink}
                  </p>
                )}
              </div>
            )}

            {selection === "option3" && <div></div>}
          </div>
          {selectButtonError && <p className="text-danger">Please select any one option</p>}

          {selection === "option1" ? (
            <div className="mt-6 text-end">
              <SecondaryButton
                onClick={handleClose}
                name="Close"
                additionalClass="text-light me-3 px-5"
              />
              <PrimaryButton
                onClick={(e) => handleCoverCharge("cover_charge")}
                name="Send cover charges"
              />
            </div>
          ) : selection === "option2" ? (
            <div className="mt-6 text-end">
              <SecondaryButton
                onClick={handleClose}
                name="Close"
                additionalClass="text-light me-3 px-5"
              />
              <PrimaryButton
                onClick={(e) => handleCoverCharge("card_filling")}
                name="Send card filling"
              />
            </div>
          ) :
            selection === "option3" ? (
              <div className="mt-6 text-end">
                <SecondaryButton
                  onClick={handleClose}
                  name="Close"
                  additionalClass="text-light me-3 px-5"
                />
                <PrimaryButton
                  onClick={(e) => handleCoverCharge("not required")}
                  name="Send email"
                />
              </div>
            ) :
              <div className="d-flex justify-content-end">
                <SecondaryButton
                  onClick={handleClose}
                  name="Close"
                  additionalClass="text-light me-3 px-5"
                />
                <PrimaryButton
                  onClick={(e) => handleCoverCharge("")}
                  name="Send"
                />
              </div>
          }
        </div>
      </Modal>
      {/* Approve modal */}

      {/* Booking Completion Model */}
      <Modal show={bookingCompletionModal} onHide={handleBookingCompletionModal}>
        <div className="background-appColor p-5 rounded font-body">
          <h5 className="text-2xl  color-ternary  mb-3">Transaction details</h5>
          <div>
            <div>
              <label className="text-md m-1 text-light">
                Transaction ID{" "}
                <span style={{ color: "red", width: "10px" }}>*</span>
              </label>
              <input
                type="text"
                className="po-edit-input m-1"
                placeholder="Please enter transactioin Id"
                onChange={(e) => {
                  let regex = /\s/;
                  let temp = { ...transactionDetails };
                  if (e.target.value === "") {
                    temp.errors.id = "Field should not be empty";
                  } else if (regex.test(e.target.value)) {
                    temp.errors.id = "Only alphabetic characters are allowed";
                  } else {
                    temp.errors.id = "";
                  }
                  temp.data.transactionId = e.target.value;
                  setTransactionDetails(temp);
                }}
              />
              {transactionDetails.errors.id && (
                <p className="text-danger">{transactionDetails.errors.id}</p>
              )}
            </div>
            <div className="mt-4">
              <label className="text-md m-1 text-light ">
                Transaction Date{" "}
                <span style={{ color: "red", width: "10px" }}>*</span>
              </label>
              <input
                type="date"
                className="po-edit-input  mt-1"
                max={maxTransactionDate()}
                onChange={(e) => {
                  let regex = /\s/;
                  let temp = transactionDetails;
                  if (e.target.value === "" || regex.test(e.target.value)) {
                    if (e.target.value === "")
                      temp.errors.date = "Field should not be empty";
                    else if (regex.test(e.target.value))
                      temp.errors.date =
                        "Only alphabetic characters are allowed";
                    setTransactionDetails({ ...temp });
                  }
                  temp.errors.date = "";
                  setTransactionDetails({ ...temp });
                  setTransactionDetails({
                    ...transactionDetails,
                    data: {
                      ...transactionDetails.data,
                      transactionDate: e.target.value,
                    },
                  });
                }}
              />
            </div>
            {transactionDetails.errors.date && (
              <p className="text-danger">{transactionDetails.errors.date}</p>
            )}
          </div>

          <div className="mt-6 text-end">
            <SecondaryButton
              onClick={() => setBookingCompletionModal(false)}
              name="Close"
              additionalClass="text-light me-3 px-5"
            />
            <PrimaryButton onClick={handleTransaction} name="Save Changes" />
          </div>
        </div>
      </Modal>
      {/* Booking Completion Model */}

      {/* Rejection Model */}
      <Modal
        show={showRejectionModal}
        onHide={() => {
          setshowRejectionModal(false);
        }}
      >
        <div className="background-appColor p-5 rounded font-body">
          <h5 className="text-2xl  color-ternary  mb-3">
            Reason for Rejection
          </h5>
          <div></div>
          <div>
            <textarea
              className="po-edit-input"
              placeholder="Please enter reason..."
              onChange={(e) => {
                const str = e.target.value;
                if (str === "") {
                  setReason({
                    reason: "",
                    error: "Field should not be empty...",
                  });
                } else if (str.trim() === "") {
                  setReason({
                    reason: "",
                    error: "Whitespace should allowed...",
                  });
                } else {
                  setReason({ reason: str, error: "" });
                }
              }}
            ></textarea>
            <p className="text-danger mt-1">{reason.error && reason.error}</p>
          </div>
          <div className="mt-6 text-end">
            <SecondaryButton
              onClick={() => {
                setshowRejectionModal(false);
                setReason({ reason: "", error: "" });
              }}
              name="Close"
              additionalClass="text-light me-3 px-5"
            />
            <SecondaryButton
              onClick={(e) => actionSubmit("Rejected")}
              name="Submit"
              additionalClass="text-light me-3 px-5"
            />
          </div>
        </div>
      </Modal>
      {/* Rejection Model */}

      <Toaster
        showToaster={showToaster}
        setShowToaster={setShowToaster}
        bg={toasterDetails.bg}
        title={toasterDetails.title}
        bodyText={toasterDetails.bodyText}
        statusIcon={toasterDetails.statusIcon}
      />
    </Layout>
  );
};
export default EntryBookingDetails;
