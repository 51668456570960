import React from 'react'
import ContentWrapper from '../../Components/Global/ContentWrapper';
import PageDescription from '../../Components/Global/PageDescription';
import Layout from '../../Layout/Layout'
import { useEffect } from 'react';
import { collection, getDocs, doc, deleteDoc, orderBy, query } from "firebase/firestore";
import db from '../../firebase';
import { useState } from 'react';
import { Modal, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import PrimaryButton from '../../Components/Global/Buttons/PrimaryButton';
import Dropdown from "react-bootstrap/Dropdown";
import SecondaryButton from '../../Components/Global/Buttons/SecondaryButton';
import ReactPaginate from "react-paginate";
import Skeleton from 'react-loading-skeleton';

const City = () => {
  const [cities, setCities] = useState([]);
  const [show, setShow] = useState({ show: false, data: null });
  const handleClose = () => setShow(false);
  const [isLoading, setIsLoading] = useState([]);
  const handleShow = (id) => {
    setShow({ show: true, data: cities[id] });
  };
  const navigate = useNavigate();

  // fetching entries
  const fetchCities = async () => {
    const q = query(collection(db, 'cities'), orderBy('createdAt', 'desc'));
    const querySnapshot = await getDocs(q);
    const newData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setCities(newData);
    setIsLoading(false);
  };
  const hadnleDelete = async (data) => {
    try {
      await deleteDoc(doc(db, "cities", data.id));
      fetchCities()
      handleClose();
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchCities();
  }, []);
  // pagination
  const CityEntries = ({ currentItems, itemOffset }) => {
    return (
      <div className="table-responsive po-table tableFixHead">
        <Table hover>
          <thead>
            <tr className="background-gray10">
              <th className="text-nowrap"><p className="text-nowrap text-xs text-xl-sm text-uppercase ps-3 color-black2 mb-0 py-2">s. no</p></th>
              <th className="text-nowrap"><p className="text-nowrap text-xs text-xl-sm text-uppercase  color-black2 mb-0 py-2">City</p></th>
              <th className="text-nowrap"><p className="text-nowrap text-xs text-xl-sm text-uppercase  color-black2 mb-0 py-2">State</p></th>
              <th className="text-nowrap"><p className="text-nowrap text-xs text-xl-sm text-uppercase  color-black2 mb-0 py-2">Country</p></th>
              <th className="text-nowrap"><p className="text-nowrap text-xs text-xl-sm text-uppercase  color-black2 mb-0 py-2">Country Timezone</p></th>
              <th className="text-nowrap"><p className="text-nowrap text-xs text-xl-sm text-uppercase  color-black2 mb-0 py-2">Status</p></th>
              <th className="text-nowrap"><p className="text-nowrap text-xs text-xl-sm text-uppercase  color-black2 mb-0 py-2"></p></th>
            </tr>
          </thead>
          <tbody>
            {!isLoading ?
              (
                <>
                  {(currentItems && currentItems.length) ? currentItems.map((city, index) => {
                    console.log(city, "city");
                    return (
                      <tr key={index}>
                        <td className="align-middle"><p className='ps-3 text-sm mb-0 text-nowrap text-black'>{itemOffset + index + 1}.</p></td>
                        <td className="align-middle"><p className='text-sm mb-0 text-nowrap text-black'>{city.name}</p></td>
                        <td className="align-middle"><p className='text-sm mb-0 text-nowrap text-black'>{city.state_name}</p></td>
                        <td className="align-middle"><p className='text-sm mb-0 text-nowrap text-black'>{city.country_name}</p></td>
                        <td className="align-middle"><p className='text-sm mb-0 text-nowrap text-black'>{city.country_timezone}</p></td>
                        <td className="align-middle"><p className='text-sm mb-0 text-nowrap text-black'>{city.party_location ? "Active" : "InActive"}</p></td>
                        <td className="align-middle">

                          <div className='d-flex justify-content-center align-items-center'>
                            <Dropdown>
                              <Dropdown.Toggle variant="link" id="dropdown-basic" className="text-black">
                                <i className="bi bi-three-dots-vertical text-4xl d-bloxk"></i>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="border-0 shadow px-2">
                                <Dropdown.Item className="border-bottom font-bold text-sm py-2 text-black" onClick={() => {
                                  navigate(`/city/edit-city/${city.id}`)
                                }}>
                                  Edit
                                </Dropdown.Item>
                                <Dropdown.Item className="font-bold text-sm py-2 text-black" onClick={() => handleShow(index)}>
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>

                        </td>
                      </tr>
                    );
                  }) : ""
                  }
                </>
              ) : (
                <tr>
                  <td colSpan={7}>
                    <Skeleton inline={true} count={5} height={80} style={{ marginBottom: "1rem" }} baseColor='#dedede' highlightColor='#cccccc' duration={2} />
                  </td>
                </tr>
              )
            }
          </tbody>
        </Table>
      </div>
    )
  }
  function PaginatedItems({ itemsPerPage }) {
    const [pageCount, setPageCount] = useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    const [currentItems, setCurrentItems] = useState(null);

    useEffect(() => {
      // Fetch items from another resources.
      const endOffset = itemOffset + itemsPerPage;
      console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      setCurrentItems(cities.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(cities.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % cities.length;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      setItemOffset(newOffset);
    };
    return (
      <>
        <CityEntries currentItems={currentItems} itemOffset={itemOffset} />
        <ReactPaginate
          nextLabel={<i className="bi bi-chevron-right"></i>}
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel={<i className="bi bi-chevron-left"></i>}
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination pe-4 pt-4 d-flex justify-content-end"
          // activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </>
    );
  }
  return (
    <Layout path="/city" additionalclass="d-flex flex-column">
      {/* <Link to="/city">
            <div  className="d-flex align-items-center mb-5">
               <div>
                <img
                  src="/assets/icons/sidebar/dark/location-icon-dark.svg"
                  alt="dashboard-icon"
                  width="15"
                  height="15"
                  className="d-block"
                />
              </div>

            <div className="ms-2 text-sm text-lg-md font-body font-semibold text-black">
            City
              </div>
               <div className="ms-2 text-xs text-lg-sm font-body font-regular text-black">
               / all
              </div>
            </div>
            </Link> */}
      <div className="d-flex justify-content-between align-items-center gap-3 gap-lg-5 ">
        <PageDescription title="All cities" caption="" />
        <PrimaryButton name="Add City" link="/city/create-city" icon="bi-plus-circle" />
      </div>
      <ContentWrapper additionalClass="pb-5 font-body">
        <PaginatedItems itemsPerPage={20} />
      </ContentWrapper>
      <Modal show={show.show} onHide={handleClose}>
        <div className="background-appColor p-8 rounded font-body">
          <h5 className="text-2xl  color-ternary font-semibold ">
            Warning: Location Linked to Clubs
          </h5>
          <p className="background-appColor text-light border-bottom-0 py-1 ">
            This city is linked to one or more clubs. Deleting it will also remove the association with these clubs. Proceed with deletion?
          </p>
          <div className="d-flex justify-content-end mt-12">
            <SecondaryButton name="Cancel" onClick={handleClose} additionalClass="px-6 text-white me-4" />
            <PrimaryButton name="Delete" onClick={() => hadnleDelete(show.data)} />
          </div>
        </div>
      </Modal>
    </Layout>
  )
}

export default City;