
import ContentWrapper from "../../ContentWrapper";
import DateRange from "../../DateRange";
import ReactApexChart from 'react-apexcharts';

const PolarChart = ({ booking_analytycs }) => {
  const chartData = {
    series: [booking_analytycs.club_entry_booking, booking_analytycs.table_booking, booking_analytycs.event_booking],
    options: {
      chart: {
        height: 350,
        type: 'polarArea',
        fontFamily: 'Montserrat, sans-serif',
      },
      colors: [
        "#dd5182",
        "#ff6e54",
        "#444e86"],
      labels: ['Booked Entries', 'Booked Clubs', 'Booked Events'],
      legend: {
        position: 'bottom',
        offsetY: 0,
        labels: {
          // colors: ['#bfbfbf'],
          // useSeriesColors: false,
        },
        markers: {
          fillColors: ['#ffffff'],
        },
      },
      fill: {
        opacity: 1,
      },
      stroke: {
        width: 0,
      },
      plotOptions: {
        polarArea: {
          rings: {
            strokeWidth: 0,
            strokeColor: '#eaeaea',
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              height: 300,
            },
          },
        },
      ],
    },
  };
  return (
    <ContentWrapper additionalClass="p-5 rounded font-body h-100">
      <div className="d-flex justify-content-between font-body">
        <div>
          <h2 className="text-md text-xl-lg font-bold color-blue font-body mb-0">
            User Preference Overview
          </h2>
          <p className="text-xs text-xl-sm">Total Preference: 200</p>
        </div>
        <div>
          <DateRange />

        </div>
      </div>
      <ReactApexChart options={chartData.options} series={chartData.series} type="polarArea" height={350} />
    </ContentWrapper>
  )
}
export default PolarChart;