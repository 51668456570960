import React from "react";
import { Row, Col, Table, Modal, Button, ModalBody } from "react-bootstrap";
import ContentWrapper from "../../Components/Global/ContentWrapper";
import Layout from "../../Layout/Layout";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { collection, doc, getDoc, updateDoc } from "firebase/firestore";
import db from "../../firebase";
import moment from "moment";
import { set, useForm } from "react-hook-form";
import axios from "axios";
import SecondaryButton from "../../Components/Global/Buttons/SecondaryButton";
import Toaster from "../../Components/Global/Toast";
import SuccessButton from "../../Components/Global/Buttons/SuccessButton";



const KycDetails = () => {
  const [showRejectionModal, setshowRejectionModal] = useState(false);
  const [showToaster, setShowToaster] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [reason, setReason] = useState({
    reason: "",
    error: ""
  });
  const [toasterDetails, setToasterDetails] = useState({
    title: "",
    bodyText: "",
    bg: "",
    statusIcon: "",
  });
  const navigate = useNavigate();

  //kyc
  const [kycDetails, setKycDetails] = useState([]);

  const { kyc_id } = useParams();

  //Modal handler
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const getEntryDetailWithId = async () => {
    const entryResponse = await getDoc(doc(db, "user_kyc", kyc_id));
    setKycDetails(entryResponse.data());
  };

  useEffect(() => {
    getEntryDetailWithId();
  }, []);

  const rejectButtonModalSubmit = async () => {
    if (reason.error === "") {
      if (reason.reason === "") {
        setReason({ ...reason, error: "Please enter a reason" })
        return;
      }
    }
    setshowRejectionModal(false);
    await onSubmitReason("Rejected")
  }


  const onSubmitReason = async (type) => {
    const token = localStorage.getItem('authenticate');
    setShowToaster(true);
    setToasterDetails((prev) => ({
      ...prev,
      title: "Updating...",
      bodyText: "Updating Kyc status",
      bg: "warning",
      statusIcon: (
        <div className="spinner-border text-warning me-2" role="status"></div>
      ),
    }));
    let payload = {
      id: kyc_id,
      status: type,
      rejecting_remakrs: type === "Approved" ? null : reason.reason
    }
    const headers = {
      'developement': 'true',
      'token': token,
      'Content-Type': 'application/json'
    }
    await axios.post(`${process.env.REACT_APP_API_URL}/kyc/v1/approve-reject-kyc`, payload, {
      headers: headers,
    }).then(() => {
      type === "Approved" ?
        (
          setToasterDetails((prev) => ({
            ...prev,
            title: "Success",
            bodyText: "Kyc Successfully Approved",
            bg: "success",
            statusIcon: (
              <i className="bi bi-check text-success text-3xl me-2"></i>
            ),
          }))
        ) : setToasterDetails((prev) => ({
          ...prev,
          title: "Success",
          bodyText: "Kyc Successfully Rejected",
          bg: "success",
          statusIcon: (
            <i className="bi bi-check text-success text-3xl me-2"></i>
          ),
        }))


      setTimeout(() => {
        navigate(`/kyc`, { replace: true });
      }, 2000);
    }).catch((err) => {
      setToasterDetails((prev) => ({
        ...prev,
        title: "Error",
        bodyText: "Something went wrong",
        bg: "danger",
        statusIcon: (
          <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
        ),
      }));
      setTimeout(() => {
        setShowToaster(false);
      }, 2000);
    })
  }

  return (
    // Layout
    <Layout path="/bookings/table-details">
      <ContentWrapper additionalClass="p-5 mt-5">
        <h5>KYC Details</h5>
        <Row className="g-4">
          {/* name */}
          <Col xs={6} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              User photo
            </p>
            <div className="d-flex align-items-center mt-2">
              <div className="background-position-center-top background-repeat-norepeat background-size-cover rounded overflow-hidden rounded-circle"
                style={{
                  height: "95px",
                  width: "95px",
                  backgroundImage: `url(${kycDetails && kycDetails.user_image})`,
                }}
              ></div>
            </div>
          </Col>
          <Col xs={6} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              Name
            </p>
            <div className="d-flex align-items-center">
              <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                {kycDetails && kycDetails.name}
              </p>
            </div>
          </Col>
          {/* nationality */}
          <Col xs={6} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              Nationality
            </p>
            <div className="d-flex align-items-center">
              <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                {kycDetails && kycDetails.nationalty}
              </p>
            </div>
          </Col>
          {/* residency */}
          <Col xs={6} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              Residency
            </p>
            <div className="d-flex align-items-center">
              <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                {kycDetails && kycDetails.residency}
              </p>
            </div>
          </Col>
          {/* frequency clubing */}
          <Col xs={6} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              Frequency Clubing
            </p>
            <div className="d-flex align-items-center">
              <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                {kycDetails && kycDetails.frequency_of_clubbing}
              </p>
            </div>
          </Col>
          {/* country */}
          <Col xs={6} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              Home Country
            </p>
            <div className="d-flex align-items-center">
              <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                {kycDetails && kycDetails.home_country_name}
              </p>
            </div>
          </Col>
          {/* state */}
          <Col xs={6} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              Home State
            </p>
            <div className="d-flex align-items-center">
              <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                {kycDetails && kycDetails.home_state_name}
              </p>
            </div>
          </Col>
          {/* city */}
          <Col xs={6} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              Home city
            </p>
            <div className="d-flex align-items-center">
              <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                {kycDetails && kycDetails.home_city_name}
              </p>
            </div>
          </Col>
          {/* status */}
          <Col xs={6} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              Passport or Drivers License number
            </p>
            <div className="d-flex align-items-center">
              <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                {kycDetails && kycDetails.government_id_number}
              </p>
            </div>
          </Col>
          <Col xs={6} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              Permanent Address
            </p>
            <div className="d-flex align-items-center">
              <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                {kycDetails && kycDetails.permanent_address}
              </p>
            </div>
          </Col>
          <Col xs={6} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              Applied on
            </p>
            <div className="d-flex align-items-center">
              <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                {kycDetails &&
                  kycDetails.createdAt &&
                  moment(kycDetails.createdAt.seconds * 1000).format(
                    "DD MMM YYYY"
                  )}
              </p>
            </div>
          </Col>
          <Col xs={6} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              Government Id Photo
            </p>
            <div className="d-flex align-items-center">
              <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                <Button
                  variant="warning"
                  className="text-warning bold bg-white border-0 "
                  onClick={(e) => setShowModal(true)}
                >
                  Click here
                </Button>
              </p>
            </div>
          </Col>
          {kycDetails.status === "Pending" &&
            <Col xs={12} className="text-end">
              {/* <Button variant="success" onClick={(e) => onSubmitReason("Approved")}>Approve</Button> */}
              <SuccessButton name="Approve" onClick={(e) => onSubmitReason("Approved")} icon="bi-check-circle" />
              {/* <Button
                variant="danger ms-3"
                onClick={() => setshowRejectionModal(true)}
              >
                Reject
              </Button> */}
              <SecondaryButton
                onClick={() => setshowRejectionModal(true)}
                name="Reject"
                additionalClass="mt-3 ms-4"
              />
            </Col>
          }
        </Row>
      </ContentWrapper>

      <Modal size="xl" show={showModal} onHide={handleClose} centered >
        <div className="background-appColor p-5 rounded font-body">
          <h5 className="text-2xl  color-ternary  mb-3">
            Government ID Photos
          </h5>
          <ModalBody>
            <div className="d-flex">
              <div>
                <p className="text-light">Front Photo</p>
                <div className="object-contain-wrapper">
                  <img
                    src={kycDetails.govt_id_front}
                    alt="front"

                  />
                </div>
              </div>
              <div className="ms-3">
                <p className="text-light ms-5">Back Photo</p>
                <div className="object-contain-wrapper"><img
                  src={kycDetails.govt_id_front}
                  alt="back"
                /></div>

              </div>
            </div>
            <div className="d-flex justify-content-end">
              <SecondaryButton
                onClick={() => setShowModal(false)}
                name="Close"
                additionalClass="text-light mt-3 me-4"
              />
            </div>
          </ModalBody>
        </div>
      </Modal>


      {/* <Modal
        show={showRejectionModal}
        onHide={() => {
          setshowRejectionModal(false);
        }}
      >
        <div className="background-appColor p-5 rounded font-body">
          <h5 className="text-2xl  color-ternary  mb-3">
            Reason for Rejection
          </h5>
          <div></div>
          <div>
            <textarea
              className="po-edit-input"
              placeholder="Please enter reason..."
              onChange={(e) => {
                const str = e.target.value;
                if (str === "") {
                  setReason({ reason: "", error: "Field should not be empty..." });
                }
                else if (str.trim() === "") {
                  setReason({ reason: "", error: "Whitespace should allowed..." });
                }
                else {
                  setReason({ reason: str, error: "" });
                }
              }
              }
            ></textarea>
            <p className="text-danger">{reason.error && reason.error}</p>
          </div>
          <div className="mt-6 text-end">
            <SecondaryButton
              onClick={() => {
                setshowRejectionModal(false);
                setReason({ reason: "", error: "" })
              }}
              name="Close"
              additionalClass="text-light me-3 px-5"
            />
            <SecondaryButton
              onClick={rejectButtonModalSubmit}
              name="Submit"
              additionalClass="text-light me-3 px-5"
            />
          </div>
        </div>
      </Modal> */}
      <Toaster
        showToaster={showToaster}
        setShowToaster={setShowToaster}
        bg={toasterDetails.bg}
        title={toasterDetails.title}
        bodyText={toasterDetails.bodyText}
        statusIcon={toasterDetails.statusIcon}
      />
    </Layout >
  );
};

export default KycDetails;
