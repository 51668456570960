import { Modal, Button, Form } from "react-bootstrap";
import PrimaryInput from "../../UI/PrimaryInput";
import { useEffect, useState } from "react";
import React from "react";

const UpdatePassword = ({ showModal, setShowModal, userId, userDetails }) => {
  const handleClose = () => setShowModal(false);
  const [isLoading, setIsLoading] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [apiError, setApiError] = useState("");
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState("")
  useEffect(() => {
    if (showModal) {
      setConfirmPassword("");
      setConfirmPasswordErrorMessage("")
      setPassword("");
      setErrorMessage("");
      setUpdated(false);
    } else {
      setIsLoading(false);
    }
  }, [showModal]);
  //   const [userId, setUserId] = useState("");
  const onSubmitHandler = async (data) => {
    if (password.trim() === "" || confirmPassword === "" || confirmPasswordErrorMessage !== "" || errorMessage !== "") {
      if (password.trim() === "") {
        setErrorMessage("Please enter a new password...");
        document.getElementById('password').focus();
      }
      else if (password !== "" && password.length < 8) {
        setErrorMessage('Password length must be of 8 characters...');
        document.getElementById('password').focus();
      }

      if (confirmPassword.trim() === "") {
        setConfirmPasswordErrorMessage("Please enter a confirmation password")
      }

      return;
    }
    else {

      setErrorMessage("");
      setIsLoading(true);
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const raw = JSON.stringify({
        "uid": userId,
        "password": password,
        "email": userDetails.email,
        "phone": userDetails.phoneNumber,
        "name": userDetails.displayName,
        "photoURL": userDetails.photoURL,
        "role": userDetails.role,
        "club_id": userDetails.club_id
      });

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      fetch(`${process.env.REACT_APP_API_URL}/user/v1/updateUser?uid=${userId}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          setUpdated(true);
          setIsLoading(false);
          setTimeout(() => {
            setShowModal(false);
            setIsLoading(false);
          }, 1500)
        })
        .catch(err => {
          setApiError("Something went wrong!")
          setIsLoading(false);
        })

    }
  }
  const onPasswordChangeHandler = (e) => {
    setPassword(e.target.value.trim());
    if (e.target.value.trim() === "") {
      setErrorMessage("Please enter a new password...");
    }
    if (e.target.value.trim() !== "" && e.target.value.length < 8) {
      setErrorMessage('Password length must be of 8 characters...');
    }
    if (e.target.value.trim().length > 7) {
      setErrorMessage("");
    }
  }
  const confirmPasswordHandler = (e) => {
    setConfirmPassword(e.target.value.trim());
    if (e.target.value.trim() !== password) {
      setConfirmPasswordErrorMessage("Password not matched...")
    }
    else {
      setConfirmPasswordErrorMessage("")
    }

  }



  return (
    <Modal show={showModal} onHide={handleClose} contentClassName="po-modalContent--radius overflow-hidden">
      <Modal.Header className="po-primaryModal__header border-0 flex-column align-items-start px-10 pt-7 pb-4">
        <Modal.Title className="color-heading text-xl lineheight-6">
          <span>Update Password</span>
        </Modal.Title>
        <p className="mt-1 text-sm color-lightestblue">Are you sure you want to change your password?</p>
      </Modal.Header>
      <Modal.Body className="px-10 pt-7 pb-3">
        <Form>
          {/* <label htmlFor="Phone" className="text-lg font-medium color-gray mb-2">User Id</label> */}
          <div className="mt-2">
            <PrimaryInput
              name="user_id"
              id="user_id"
              label="User Id"
              value={userId}
              onChange={() => { }}
              className='mb-1'
              disabled
            // error={value ? (isValidPhoneNumber(value) ? undefined : 'Invalid phone number') : 'Phone number required'}
            />
          </div>
          <div className="mt-8">
            <PrimaryInput
              id="password"
              label="Password*"
              placeholder="Enter password"
              type='password'
              onChange={onPasswordChangeHandler}
              value={password}
            />
            {errorMessage && <span className="text-danger font-medium">{errorMessage}</span>}
          </div>
          <div className="mt-8">
            <PrimaryInput
              id="confirm-password"
              label="Confirm password*"
              placeholder="Enter password"
              type='password'
              onChange={confirmPasswordHandler}
              value={confirmPassword}
            />
            {confirmPasswordErrorMessage && <span className="text-danger font-medium">{confirmPasswordErrorMessage}</span>}
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer className="border-0 pb-9">
        {isLoading && !apiError ? <img
          src="/assets/icons/loading_icon.gif"
          alt="processing" width={100} /> : updated && <span className="me-5 text-danger font-bold">Updated</span>}
        {apiError && <span className="text-danger font-medium">{apiError}</span>}
        <Button
          variant="secondary"
          disabled={isLoading}
          className="me-2 px-6"
          onClick={handleClose}>Cancel</Button>
        <Button
          type="submit"
          id="submit"
          variant="success"
          disabled={isLoading}
          className="px-6"
          onClick={onSubmitHandler}
        >Update</Button>
      </Modal.Footer>
    </Modal>
  );
};
export default React.memo(UpdatePassword);
