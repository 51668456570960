import React from "react";
import { Table } from "react-bootstrap";
import ContentWrapper from "../ContentWrapper";

const RevenueCities = ({ top_10_city }) => {
  return (
    <ContentWrapper additionalClass="py-5">
      <div className="d-flex justify-content-between font-body ps-4">
        <div>
          <h2 className="text-sm text-xl-lg font-bold color-blue font-body mb-4">
            Top Revenue by cities [ Top 10 cities ]
          </h2>
          {/* <p className="text-xs text-xl-sm">Total Revenue : 200</p> */}
        </div>

        {/* <div>
          <DateRange getDateRange="" startDate="" endDate="" />
        </div> */}
      </div>
      <Table hover responsive>
        <thead>
          <tr className="background-gray10">
            <th>
              <p className="text-nowrap text-xs text-xl-sm text-uppercase ps-3 color-black2 mb-0 py-2">
                s. no
              </p>
            </th>
            <th>
              <p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">
                Cities
              </p>
            </th>
            <th>
              <p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">
                Total Booking Revenue
              </p>
            </th>
            <th>
              <p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">
                No. of total Bookings
              </p>
            </th>
            <th>
              <p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">
                NO. of Event Bookings
              </p>
            </th>

            <th>
              <p className="text-nowrap text-xs text-xl-sm  text-uppercase color-black2 mb-0 py-2">
                No. of Club Entry Bookings
              </p>
            </th>
            <th>
              <p className="text-nowrap text-xs text-xl-sm  text-uppercase color-black2 mb-0 py-2">
                No. of Table Bookings
              </p>
            </th>
            <th>
              <p className="text-nowrap text-xs text-xl-sm  text-uppercase color-black2 mb-0 py-2">
                Average
              </p>
            </th>
          </tr>
        </thead>
        <tbody>
          {top_10_city &&
            top_10_city.length > 0 &&
            top_10_city.map((item, index) => {
              return (
                <tr key={index}>
                  <td className="align-middle">
                    <p className="text-sm mb-0 text-nowrap color-lightblue ps-3">
                      {index + 1}
                    </p>
                  </td>
                  <td className="align-middle">
                    <p className="text-sm mb-0 text-nowrap color-lightblue">
                      {item.city}
                    </p>
                  </td>
                  <td className="align-middle">
                    <p className="text-sm mb-0 text-nowrap color-lightblue">
                      $ {item.amount.toFixed(2)} USD
                    </p>
                  </td>
                  <td className="align-middle">
                    <p className="text-sm mb-0 text-nowrap color-lightblue">
                      {item.total}
                    </p>
                  </td>
                  <td className="align-middle">
                    <p className="text-sm mb-0 text-nowrap color-lightblue">
                      {item.event_entry_booking}
                    </p>
                  </td>
                  <td className="align-middle">
                    <p className="text-sm mb-0 text-nowrap color-lightblue">
                      {item.club_entry_booking}
                    </p>
                  </td>
                  <td className="align-middle">
                    <p className="text-sm mb-0 text-nowrap color-lightblue">
                      {item.table_booking}
                    </p>
                  </td>
                  <td className="align-middle">
                    <p className="text-sm mb-0 text-nowrap color-lightblue">
                      $ {item.average.toFixed(2)} USD
                    </p>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </ContentWrapper>
  );
};
export default RevenueCities;