import { Col } from "react-bootstrap";
import ContentWrapper from "../../Components/Global/ContentWrapper";
import Layout from "../../Layout/Layout";
import { useForm } from "react-hook-form";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import {
  collection,
  getDocs,
  addDoc,
  serverTimestamp,
  orderBy,
  query,
} from "firebase/firestore";
import db from "../../firebase";
import { v4 as uuidv4 } from "uuid";
import OutlinedButton from "../../Components/Global/Buttons/OutlinedButton";
import SuccessButton from "../../Components/Global/Buttons/SuccessButton";
import Toaster from "../../Components/Global/Toast";
import ActionIcon from "../../Components/Global/Buttons/ActionIcons";
const CreateNotifications = () => {
  const [showToaster, setShowToaster] = useState(false);
  const [toasterDetails, setToasterDetails] = useState({
    title: "",
    bodyText: "",
    bg: "",
    statusIcon: "",
  });
  const navigate = useNavigate();
  const [previewImage, setPreviewImage] = useState(null);
  const [existingCityError, setExisitingCityError] = useState(null);
  const [image, setImage] = useState({});
  const [clubData, setClubData] = useState([]);
  const [eventsData, setEventsData] = useState([]);
  const [categoryValues, setCategoryValues] = useState([]);
  const [categoryValue, setCategoryValue] = useState("");
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [cities, setCities] = useState([])
  const [debouncedQuery, setDebouncedQuery] = useState('');
  const [selectedCities, setSelectedCities] = useState([])
  let timer;

  const {
    register,
    setValue,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
  } = useForm();
  const [isInternal, setIsInternal] = useState(null);
  const [selectedCategoryType, setSelectedCategoryType] = useState("");


  const getAllData = async () => {
    try {
      const clubsResponse = await getDocs(
        query(collection(db, "clubs"), orderBy("name", "asc"))
      );
      console.log(clubsResponse.docs, "clubsResponse.docs");
      clubsResponse && setClubData(clubsResponse.docs);
      const eventResponse = await getDocs(
        query(collection(db, "events"), orderBy("name", "asc"))
      );
      eventResponse && setEventsData(eventResponse.docs);




    } catch (err) {
      console.log(err);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
      console.log(file, "its coming");
      setImage(file);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);




  const citiesOnChange = async (e) => {
    const inputValue = e.target.value;

    setSearchItem(inputValue);
  };

  useEffect(() => {
    // Fetch suggestions from the API when 'inputValue' changes


    timer = setTimeout(() => {

      if (searchItem != "") {
        fetch(`${process.env.REACT_APP_API_URL}/location/v1/allCities?name=${searchItem}`)
          .then((response) => response.json())
          .then((data) => {
            setCitiesOptions(data.data); // Assuming the API response is an array of suggestions
          })
          .catch((error) => {
            console.error('Error fetching suggestions:', error);
          });
      } else {

        setCitiesOptions([]); // Clear suggestions when the input is empty
      }

    }, 300
    )
    return () => clearTimeout(timer);
  }, [searchItem]);


  //Uploading image
  const insertingImage = async (imageData) => {
    let form = new FormData();
    form.append("images", imageData);
    let responseImage = await axios
      .post(
        `${process.env.REACT_APP_MULTIPLEIMAGES_POSTING_URL}/uploads/uploadImages`,
        form,
        { headers: { "Content-Type": "multipart/form-data" } }
      )
      .then((resImage) => {
        return resImage && resImage.data.url[0];
      })
      .catch((error) => console.log(error, "err"));
    return responseImage;
  };


  //Changing First letter Capital
  function capitalizeFirstLetter(inputString) {
    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
  }




  // uploading data
  const onSubmit = async (data) => {
    setShowToaster(true);
    setToasterDetails((prev) => ({
      ...prev,
      title: "Creating...",
      bodyText: "Creating push notification",
      bg: "warning",
      statusIcon: (
        <div className="spinner-border text-warning me-2" role="status"></div>
      ),
    }));
    let imageUrl = await insertingImage(image)
    let url =
      selectedCategoryType === "Club"
        ? `clubs/${categoryValue}`
        : selectedCategoryType === "Event"
          ? `events/${categoryValue}`
          : data.url;
    let payload = null;
    if (isInternal) {
      payload = {
        id: uuidv4(),
        title: data.title,
        body: data.body,
        createdAt: serverTimestamp(),
        internal_redirect: isInternal,
        // read_status:data.readStatus === "YES" ? true : false,
        url:
          selectedCategoryType == "Club"
            ? `clubs/${categoryValue}`
            : selectedCategoryType == "Event"
              ? `events/${categoryValue}`
              : null,
        notification_category_id: categoryValue,
        notification_type: selectedCategoryType,
        cities: selectedCities,
        display_image: imageUrl
      };
    } else {
      payload = {
        id: uuidv4(),
        title: data.title,
        body: data.body,
        createdAt: serverTimestamp(),
        internal_redirect: isInternal,
        // read_status:data.readStatus === "YES" ? true : false,
        url: data.url,
        notification_category_id: null,
        notification_type: null,
        cities: selectedCities,
        display_image: imageUrl
      };
    }
    console.log(payload, "payloadpayloadpayloadpayload")
    try {
      const jwtToken = localStorage.getItem("authenticate");

      const notificationResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/notification/v2/notifications`,
        {
          body: data.body,
          title: data.title,
          url,
          internal_redirect: data.internalRedirect === "YES",
          cities: selectedCities
        },
        {
          headers: {
            developement: true,
            token: jwtToken,
          },
        }
      );

      const docRef = await addDoc(collection(db, "notifications_campaign"), payload);

      setToasterDetails((prev) => ({
        ...prev,
        title: "Success",
        bodyText: "Push notification created successfully",
        bg: "success",
        statusIcon: <i className="bi bi-check text-success text-3xl me-2"></i>,
      }));

      setTimeout(() => {
        navigate(`/notifications`, { replace: true });
      }, 2000);
    } catch (error) {
      console.error("Error:", error);

      setToasterDetails((prev) => ({
        ...prev,
        title: "Error",
        bodyText: error && error.response && error.response.data.message,
        bg: "danger",
        statusIcon: (
          <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
        ),
      }));
      setTimeout(() => {
        setShowToaster(false);
      }, 2000)
      if (error.response) {
        console.log(error.response.data.message, "Error Notification");
      }
    }

    // reset();
  };


  const citiesOnClick = (value) => {
    let cityDetails = citiesOptions.find(c => c.id === value);
    let duplicateValidation = selectedCities.find(citee => capitalizeFirstLetter(citee.city) === cityDetails.name);
    if (duplicateValidation) {
      setSearchItem("");
      setCitiesOptions([]);
      setError("cities", {
        type: "manual",
        message: "This city already exists"
      })
      return;
    }
    let previousCities = selectedCities;
    previousCities.push({
      city: cityDetails.name.toLowerCase(),
      country: cityDetails.country_name.toLowerCase()
    });
    setError("cities", null)
    setSelectedCities(previousCities);
    setSearchItem("")
    setCitiesOptions([]);
  }


  return (
    <Layout path="/notifications/create-notifications">
      <ContentWrapper additionalClass="p-5 font-body">
        <form className="row g-4" onSubmit={handleSubmit(onSubmit)}>
          {/* Image */}
          {/* Country Image */}
          <Col xs={12}>
            <label htmlFor="cityImage">Campaign Image</label>
            <div
              style={{
                height: "200px",
                width: "350px",
                backgroundImage: `url(${previewImage ? previewImage : null})`,
                border: `${previewImage ? "0px" : "1px"} dashed #D2D6DA`,
              }}
              className="d-flex align-items-center justify-content-center background-position-center-top background-repeat-norepeat background-size-cover rounded overflow-hidden position-relative"
            >
              {previewImage ? (
                <div className="position-absolute end-0 top-0">
                  <ActionIcon
                    onClick={() => {
                      setImage(null);
                      setPreviewImage(null)
                    }}
                    additionalClass="bi bi-x-circle-fill text-3xl color-red me-2"
                  />
                </div>
              ) : null}

              {previewImage ? null : (
                <div>
                  <input
                    {
                    ...register("display_image", {
                      required: "Campaign image is required"
                    })
                    }
                    accept="image/*"
                    type="file"
                    id="display_image"
                    onChange={handleImageChange}
                    className="opacity-0 position-absolute start-0 end-0 top-0 bottom-0"
                  />

                  <div className="text-center">
                    <i className="bi bi-plus-circle-fill text-5xl color-lightgreen d-block"></i>
                    <p className="text-md font-body text-center  ">
                      Upload your Image here.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </Col>
          {errors.display_image &&
            <p className="text-sm text-danger mt-1">{errors.display_image.message}</p>
          }

          {/* Title */}
          <Col md={6}>
            <label className="text-md mb-1" htmlFor="title">
              Title: <span className="text-danger">*</span>
            </label>
            <input
              className="po-edit-input"
              {...register("title", {
                required: "Title is required",
                pattern: {
                  value: /^(?!\s*$).+/,
                  message: "Only alphabetic characters are allowed.",
                },
              })}
            />

            {errors.title && (
              <p className="text-sm text-danger mt-1 font-body">
                {errors.title.message}
              </p>
            )}
          </Col>

          {/* Body */}
          <Col md={6}>
            <label className="text-md mb-1" htmlFor="body">
              Body: <span className="text-danger">*</span>
            </label>
            <textarea
              className="po-edit-input"
              {...register("body", {
                required: "Body is required",
                pattern: {
                  value: /^(?!\s*$).+/,
                  message: "Only alphabetic characters are allowed.",
                },
              })}
            />
            {errors.body && (
              <p className="text-sm text-danger mt-1 font-body">
                {errors.body.message}
              </p>
            )}
          </Col>

          {/* Internal redirect */}
          <Col md={6}>
            <label className="text-md mb-1">
              Internal redirect: <span className="text-danger">*</span>
            </label>

            <select
              className="po-edit-input"
              {...register("internalRedirect", {
                required: "Internal Redirect is required",
              })}
              onChange={(e) => {
                setIsInternal(e.target.value === "YES" ? true : false);
                setError("internalRedirect", {
                  type: "manual",
                  message: "",
                });
              }}
            >
              <option value="">Internal Redirect </option>
              <option value="YES">Yes</option>
              <option value="NO">No</option>
            </select>
            {errors.internalRedirect && (
              <p className="text-sm text-danger mt-1 font-body">
                {errors.internalRedirect.message}
              </p>
            )}
          </Col>
          {isInternal === false && (
            <Col md={6}>
              <label className="text-md mb-1" htmlFor="url">
                URL:
              </label>
              <input
                className="po-edit-input"
                {...register("url", {
                  required: "URL is required",
                  pattern: {
                    value: /^(?!\s*$).+/,
                    message: "Only alphabetic characters are allowed.",
                  },
                })}
              />
              {errors.url && (
                <p className="text-sm text-danger mt-1 font-body">
                  {errors.url.message}
                </p>
              )}
            </Col>
          )}
          {isInternal && (
            <>
              <Col md={6}>
                <label className="text-md mb-1">Select Category</label>

                <select
                  className="po-edit-input"
                  {...register("category", {
                    required: "Category is required",
                  })}
                  onChange={(e) => {
                    console.log(e.target.value, "value");
                    setSelectedCategoryType(e.target.value);
                    switch (e.target.value) {
                      case "Club":
                        setCategoryValues(clubData);
                        break;
                      case "Event":
                        setCategoryValues(eventsData);
                        break;
                      case "":
                        setCategoryValues([]);
                        break;
                      default:
                        break;
                    }
                  }}
                >
                  {selectedCategoryType === "" && (
                    <option value="" selected="selected" disabled>
                      Select Category
                    </option>
                  )}
                  <option value="Club">Club</option>
                  <option value="Event">Event</option>
                </select>
                {errors.category && (
                  <p className="text-sm text-danger mt-1 font-body">
                    {errors.category.message}
                  </p>
                )}
              </Col>
              <Col md={6}>
                {selectedCategoryType === "Club" ? (
                  <label className="text-md mb-1">
                    {`Select ${selectedCategoryType}`}
                  </label>
                ) : selectedCategoryType === "Event" ? (
                  <label className="text-md mb-1">
                    {`Select ${selectedCategoryType}`}
                  </label>
                ) : selectedCategoryType === "" ? (
                  <label className="text-md mb-1">Select Value</label>
                ) : null}

                <select
                  className="po-edit-input"
                  {...register("category_value", {
                    required: "Value is required",
                    pattern: /^(?!\s*$).+/,
                  })}
                  onChange={(e) => {
                    setCategoryValue(e.target.value);
                  }}
                >
                  <option value="" selected="selected" disabled>
                    Select Value
                  </option>
                  {categoryValues &&
                    categoryValues.length > 0 &&
                    categoryValues.map((club) => {
                      return (
                        <option
                          value={club.data().id}
                          title={club.data().address}
                        >
                          {club && club.data().name}
                        </option>
                      );
                    })}
                </select>
                {errors.category_value && (
                  <p className="text-sm text-danger mt-1 font-body">
                    {errors.category_value.message}
                  </p>
                )}
              </Col>
            </>
          )}


          {/* Cities Section */}

          <Col md={6}>
            <label className="text-md">
              Cities :
            </label>
            <input
              {...register("cities")}
              type="search"
              className="po-edit-input"
              placeholder="Please enter some text..."
              value={searchItem}

              onChange={citiesOnChange}

            />
            {errors.cities && (
              <p className="mt-2 text-danger">{errors.cities.message}</p>
            )}

            <div className={`card mt-2 p-2 ${citiesOptions.length === 0 ? "d-none" : ""}`}>
              {citiesOptions &&
                citiesOptions.map((ele, index) => (
                  <p
                    key={index}
                    className="on-hover pt-1 ps-2 m-0"
                    onClick={() => {
                      if (selectedCities.some(selectedCity => selectedCity.city === ele.name)) {
                        // setExisitingCityError("Selected city already exists");
                        setError("cities", {
                          type: "manual",
                          message: "City already exists"
                        })
                      } else {
                        citiesOnClick(ele.id);
                        // setValue("cities", ele.name)
                      }

                    }}
                  >
                    {ele.name}
                  </p>
                ))}
            </div>

            <div className="d-flex ms-0 flex-wrap">
              {selectedCities &&
                selectedCities.length > 0 &&
                selectedCities.map((ele, index) => (
                  <div key={index} className="card d-flex flex-row justify-content-between m-1">
                    <p className="p-2 semi-bold text-nowrap">{ele.city}</p>
                    <ActionIcon
                      additionalClass="text-danger text-xl bi-x-circle-fill p-2"
                      onClick={() => {
                        // Create a new array without the selected city
                        const updatedCities = selectedCities.filter((city, i) => i !== index);
                        setSelectedCities(updatedCities);
                      }}
                    />
                  </div>
                ))}
            </div>
          </Col>


          {/* Cities Section */}


          {/* Buttons */}
          <div className="text-end">
            <OutlinedButton
              link="/notifications"
              name="Cancel"
              additionalClass="me-3"
            />

            <SuccessButton
              type="submit"
              name="Create notification"
              icon="bi-check-circle"
              additionalClass={`ms-4 ${showToaster ? "opacity-06 cursor-default" : null
                }  `}
              disabled={showToaster ? true : false}
            />
          </div>
        </form>
      </ContentWrapper>

      <Toaster
        showToaster={showToaster}
        setShowToaster={setShowToaster}
        bg={toasterDetails.bg}
        title={toasterDetails.title}
        bodyText={toasterDetails.bodyText}
        statusIcon={toasterDetails.statusIcon}
      />
    </Layout>
  );
};
export default CreateNotifications;
