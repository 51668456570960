import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import { Controller, useForm } from "react-hook-form";
import { Button, Col, Row } from "react-bootstrap";
import ContentWrapper from "../../Components/Global/ContentWrapper";
import PhoneInput from "react-phone-number-input";
import ActionIcon from "../../Components/Global/Buttons/ActionIcons";
import ClubModal from "./club-modal";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import db from "../../firebase";
import moment from "moment";
import { SimpleMdeReact } from "react-simplemde-editor";
import OutlinedButton from "../../Components/Global/Buttons/OutlinedButton";
import SuccessButton from "../../Components/Global/Buttons/SuccessButton";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { v4 as uuid } from "uuid";
import Toaster from "../../Components/Global/Toast";
import "easymde/dist/easymde.min.css";

export default function Club() {
  const navigate = useNavigate();
  const [previewImage, setPreviewImage] = useState(null);
  const [logo, setLogo] = useState(null);
  const [logoDeleteModal, setLogoDeleteModal] = useState(false);
  const [coverPreviewImage, setCoverPreviewImage] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [coverImageDeleteModal, setCoverImageDeleteModal] = useState(false);
  const [status, setStatus] = useState(true);
  const [ladiesNights, setLadiesNights] = useState([]);
  const [ladiesNightWarningModal, setLadiesNightWarningModal] = useState(false)
  const [toasterDetails, setToasterDetails] = useState({
    title: "",
    bodyText: "",
    bg: "",
    statusIcon: "",
  });
  const { club_id } = useParams();

  // Photos
  const [clubPhotos, setClubPhotos] = useState([]);
  const [maximumPhotoSize, setMaximumPhotoSize] = useState(null);
  const [photoDeleteModal, setPhotoDeleteModal] = useState(null);
  const [clubPhotosUrl, setClubPhotosUrl] = useState([]);
  const [photoError, setPhotoError] = useState(null);
  const [photoPreviewImage, setphotoPreviewImage] = useState(null);
  const [photoIndex, setPhotoIndex] = useState({
    type: "",
    indexNumber: 0,
  });
  // Photos

  // Locations
  const [countryId, setCountryId] = useState("");
  const [country, setCountry] = useState("India");
  const [states, setStates] = useState([]);
  const [stateId, setStateId] = useState("");
  const [state, setState] = useState("");
  const [cities, setCities] = useState([]);
  const [cityId, setCityId] = useState("");
  const [city, setCity] = useState("");
  const [timezone, setTimeZone] = useState("");
  const [countries, setCountries] = useState([]);
  const [photoValue, setPhotoValue] = useState("");
  const [wrongAddress, setWrongAddress] = useState(false);
  // Locations

  // Slots
  const [tableEntitiesData, setTableEntitiesData] = useState([]);
  const [tableDetailError, setTableDetailError] = useState({
    seater_count: false,
    count: false,
    minimum_spent: false,
    name: false,
    seater_count_validation: false,
    count_validation: false,
    minimum_spent_validation: false,
  });
  const [tableEntities, setTableEntities] = useState({
    startTime: "",
  });
  const [tableEntitiesError, setTableEntitiesError] = useState({
    startTime: false,
    tableList: false,
  });
  const [showModal, setShowModal] = useState(false);
  // Slots

  // Toaster
  const [showToaster, setShowToaster] = useState(false);

  // useform validation
  let {
    register,
    setValue,
    handleSubmit,
    setError,
    control,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  // LOGO

  const handleLogo = (e) => {
    const file = e.target.files[0];
    if (file && !isValidImageFile(file.name)) {
      setError("logo", {
        type: "manual",
        message: "Please select image according to mentioned formats",
      });
      setTimeout(() => {
        clearErrors("logo");
      }, 1000);
      return;
    }
    isValidImageDimensions(file, 200, 200)
      .then((isValidDimensions) => {
        if (!isValidDimensions) {
          // Handle invalid dimensions, e.g., show an error message
          setError("logo", {
            type: "manual",
            message: "Image dimensions are not valid.",
          });
          setTimeout(() => {
            clearErrors("logo");
          }, 1000);
        } else {
          setLogo(file);
          setPreviewImage(file);
          if (file) {
            const reader = new FileReader();
            reader.onload = () => {
              setPreviewImage(reader.result);
            };
            reader.readAsDataURL(file);
          }
          setError("logo", null);
        }
      })
      .catch((err) => {
        setTimeout(() => {
          setError("coverImage", {
            type: "custom",
            message: "Please select image according to mentioned dimensions",
          });
        }, 1000);
      });
  };

  const logoModalOnclick = () => {
    setPreviewImage(null);
    setLogo(null);
    setError("logo", {
      manual: true,
      message: "This is required field",
    });
    setLogoDeleteModal(false);
  };
  const closeLogoModal = () => {
    setLogoDeleteModal(false);
  };

  // LOGO

  // COVER IMAGE

  const handleCoverImage = (e) => {
    const file = e.target.files[0];
    if (file && !isValidImageFile(file.name)) {
      setError("coverImage", {
        type: "manual",
        message: "Please select image according to mentioned formats",
      });
      setTimeout(() => {
        clearErrors("coverImage");
      }, 1000);
      return;
    }
    isValidImageDimensions(file, 600, 552)
      .then((isValidDimensions) => {
        if (!isValidDimensions) {
          // Handle invalid dimensions, e.g., show an error message
          setError("coverImage", {
            type: "manual",
            message: "Image dimensions are not valid.",
          });
          setTimeout(() => {
            clearErrors("coverImage");
          }, 1000);
        } else {
          setCoverImage(file);
          setCoverPreviewImage(file);
          setValue("coverImage", file)
          if (file) {
            const reader = new FileReader();
            reader.onload = () => {
              setCoverPreviewImage(reader.result);
            };
            reader.readAsDataURL(file);
          }
          setError("coverImage", null);
        }
      })
      .catch((err) => {
        setTimeout(() => {
          setError("coverImage", {
            type: "custom",
            message: "Please select image according to mentioned dimensions",
          });
        }, 1000);
      });
  };

  const coverImageModalOnClick = () => {
    setCoverPreviewImage(null);
    setCoverImage(null);
    setError("coverImage", {
      manual: true,
      message: "This is required field",
    });
    setValue("coverImage", null)
    setCoverImageDeleteModal(false);
  };
  const coverImageModalOnClose = () => {
    setCoverImageDeleteModal(false);
  };

  // COVER IMAGE

  // PHOTOS
  const handleClubPhotos = async (e) => {
    const files = e.target.files;
    let validatingPhotos = [...clubPhotos, ...clubPhotosUrl, ...files];
    console.log(validatingPhotos.length, "length")
    if (validatingPhotos.length > 6) {
      setMaximumPhotoSize("Only six photos allowed");
      return;
    }

    if (files.length > 6) {
      setMaximumPhotoSize("Only six photos allowed");
      return;
    } else {
      setMaximumPhotoSize("");
    }

    for (const file of files) {
      if (!file) {
        continue; // Skip null files
      }

      // Check if the file name matches valid image formats (assuming you have this function defined)
      if (!isValidImageFile(file.name)) {
        setError("clubPhotos", {
          type: "manual",
          message:
            "Please select images with valid formats (JPEG, PNG, GIF, BMP).",
        });
        continue; // Skip this file and move to the next one
      }

      try {
        // Check image dimensions asynchronously
        const isValidDimensions = await isValidImageDimensions(file, 760, 352);

        if (!isValidDimensions) {
          setError("clubPhotos", {
            type: "manual",
            message:
              "Image dimensions are not valid (760x352 pixels required).",
          });
          setTimeout(() => {
            clearErrors("clubPhotos");
          }, 1000);
        } else {
          // Valid image, add it to the clubPhotos state
          setClubPhotos((prev) => [...prev, file]);
          setPhotoError(false);
          setValue("clubPhotos", "clubPhotos");
        }
      } catch (err) {
        setError("clubPhotos", {
          type: "custom",
          message: "An error occurred while checking image dimensions.",
        });
        setTimeout(() => {
          clearErrors("clubPhotos");
        }, 1000);
      }
    }
  };

  const photosModalOnClose = () => {
    setPhotoDeleteModal(false);
  };

  const photosModalOnClick = () => {
    photoIndex.type === "newlyAdded"
      ? onDeleteImagesList(photoIndex.indexNumber)
      : onUpdateImagesList(photoIndex.indexNumber);
    clubPhotosUrl.concat(clubPhotos).length === 0
      ? setPhotoError("This is required field")
      : setPhotoError("");
  };

  const onDeleteImagesList = (item) => {
    let newArrayEventPhotos = clubPhotos;
    newArrayEventPhotos.splice(item, 1);
    setClubPhotos(newArrayEventPhotos);
    setPhotoDeleteModal(false);
  };

  const onUpdateImagesList = (imageIndex) => {
    clubPhotosUrl.splice(imageIndex, 1);
    setClubPhotosUrl(clubPhotosUrl);
    setPhotoDeleteModal(false);
  };

  // PHOTOS

  // IMAGES
  const allowedImageFormats = ["jpg", "jpeg", "png", "gif", "bmp"];
  function isValidImageFile(filename) {
    // Extract the file extension (assuming the filename has a valid format)
    const fileExtension = filename.split(".").pop().toLowerCase();
    // Check if the file extension is in the list of allowed formats
    return allowedImageFormats.includes(fileExtension);
  }
  const isValidImageDimensions = (file, maxWidth, maxHeight) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = function () {
        const width = this.width;
        const height = this.height;

        if (width <= maxWidth && height <= maxHeight) {
          resolve(true); // Image dimensions are valid
        } else {
          resolve(false); // Image dimensions are not valid
        }
      };

      img.onerror = function () {
        reject("Failed to load the image");
      };
    });
  };
  // IMAGES

  // LOCATIONS

  const handleCountry = async (e) => {
    setCountryId(e.target.value);
    getAllStates(e.target.value);
    await getCitiesWithCountryID(e.target.value);
    e.target.options &&
      setCountry(e.target.options[e.target.selectedIndex].text);
  };

  // Get all states

  const getAllStates = async (countryId) => {
    try {
      let q = query(
        collection(db, "states"),
        where("country_id", "==", countryId)
      );
      let response = await getDocs(q)
        .then((responseData) => {
          return responseData;
        })
        .catch((err) => console.log(err));
      if (response.docs.length > 0) {
        response && setStates(response.docs);
        response &&
          response.docs &&
          response.docs.length > 0 &&
          response.docs[0] &&
          setStateId(response.docs[0].data().id);
        response &&
          response.docs &&
          response.docs.length > 0 &&
          response.docs[0] &&
          setState(response.docs[0].data().name);
        response &&
          response.docs &&
          response.docs.length > 0 &&
          response.docs[0] &&
          getCities(response.docs[0].data().id);
      } else {
        setStateId("");
        setState("");
        setStates([]);
        getCities(null);
      }
    } catch (err) {
      setStateId("");
      setState("");
      setStates([]);
      getCities(null);
    }
  };

  // Get All Cities
  const getCities = async (stateId) => {
    let tableEntities = tableEntitiesData;
    if (stateId === null) {
      setCities([]);
      setCityId(null);
      setCity("");
    } else {
      let CitiesNames = [];
      let q = query(collection(db, "cities"), where("state_id", "==", stateId), where("party_location", "==", true));
      const response = stateId && (await getDocs(q));
      response &&
        response.docs &&
        response.docs.forEach((ele) => {
          CitiesNames.push(ele.data());
        });

      response &&
        response.docs &&
        response.docs.length > 0 &&
        response.docs[0] &&
        setTimeZone(response.docs[0].data().country_timezone);
      response &&
        response.docs &&
        response.docs.length > 0 &&
        setCities(response.docs);
      response &&
        response.docs &&
        response.docs.length > 0 &&
        response.docs[0] &&
        setCityId(response.docs[0].data().id);
      response &&
        response.docs &&
        response.docs.length > 0 &&
        response.docs[0] &&
        response.docs[0].data &&
        response.docs[0].data() &&
        setCity(response.docs[0].data().name);
      tableEntities.length > 0 &&
        tableEntities.map(
          (ele) => (ele["zone"] = response.docs[0].data().country_timezone)
        );
      setTableEntitiesData(tableEntities);
    }
  };

  // Fetching cities with country id
  const getCitiesWithCountryID = async (countryId) => {
    let q = query(
      collection(db, "cities"),
      where("country_id", "==", countryId)
    );
    let response = await getDocs(q);
    return response.docs.map((ele) => ele.data());
  };

  // State Handler
  const handleState = (e) => {
    e.target.options && setState(e.target.options[e.target.selectedIndex].text);
    setStateId(e.target.value);
    getCities(e.target.value);
  };

  // Get all countries
  const getAllCountries = async () => {
    try {
      const responseArray = [];
      let q = query(collection(db, "countries"));
      let response = await getDocs(q)
        .then((responseData) => {
          return responseData.docs;
        })
        .catch((err) => console.log(err));
      const insertingData = response.forEach((doc) => {
        let data = doc.data();
        responseArray.push(data);
      });
      // setCountry(responseArray[0].id)
      responseArray && responseArray.length > 0 && setCountries(response);
      responseArray &&
        responseArray.length > 0 &&
        setCountryId(responseArray[0].id);
      responseArray &&
        responseArray.length > 0 &&
        getAllStates(responseArray[0].id);
      responseArray &&
        responseArray.length > 0 &&
        setCountry(responseArray[0].name);
    } catch (err) { }
  };

  useEffect(() => {
    getAllCountries();
    if (club_id) {
      getClubData();
    }
  }, []);

  // LOCATIONS

  // SLOTS
  const handleTableEntities = () => {
    if (tableEntities.startTime === "") {
      setTableEntitiesError({
        ...tableEntitiesError,
        startTime: true,
      });
      return;
    } else {
      let isTimeAlreadyExists = {
        slot_time: moment(tableEntities.startTime, ["HH:mm"]).format("hh:mm a"),
        zone: timezone,
      };
      if (
        tableEntitiesData.find(
          (ele) => isTimeAlreadyExists.slot_time === ele.slot_time
        )
      ) {
        setShowModal(true);
        return;
      }

      setTableEntitiesData([
        ...tableEntitiesData,
        {
          slot_time: moment(tableEntities.startTime, ["HH:mm"]).format(
            "hh:mm a"
          ),
          zone: timezone,
        },
      ]);
      setTableEntities({ ...tableEntities, startTime: "" });
    }
  };

  // SLOTS

  // CLUB UPDATION

  const getClubData = async () => {
    let clubQuery = doc(db, "clubs", club_id);
    // Club response
    const clubResponse = await getDoc(clubQuery);
    const responseData = clubResponse.data();

    let logo = responseData && responseData.logo ? responseData.logo : null;
    let coverImage =
      responseData && responseData.display_image
        ? responseData.display_image
        : null;
    if (responseData) {
      try {
        if (responseData) {
          reset({
            clubPhotos:
              (responseData.photos.length > 0 && responseData.photos) || [],
            clubName: responseData.name || "",
            vipManagerName: responseData.club_vip_manager?.name || "",
            vipEmailId: responseData.club_vip_manager?.email || "",
            vipManagerPhone: responseData.club_vip_manager?.contact_no || "",
            vipManagerWhatsapp:
              responseData.club_vip_manager?.whatsapp_no || "",
            doorManagerWhatsapp:
              responseData.club_door_manager?.whatsapp_no || "",
            doorManagerPhone: responseData.club_door_manager?.contact_no || "",
            doorManagerEmail: responseData.club_door_manager?.email || "",
            doorManagerName: responseData.club_door_manager?.name || "",
            communicationEmail: responseData.club_email_id || "",
            communicationLink: responseData.website_url || "",
            clubDescription: responseData.description || "",
            financeCoordinatorName:
              responseData.club_account_details?.finance_coordinator_name || "",
            financeCoordinatorEmail:
              responseData.club_account_details?.email || "",
            accountPhone: responseData.club_account_details?.mobile || "",
            accountWhatsapp:
              responseData.club_account_details?.whatsapp_no || "",
            financeCoordinatorDetails:
              responseData.club_account_details?.account_details || "",
            dress: responseData.dress || "",
            mondayTitle: responseData.schedule?.Monday?.title || "",
            monday_start_timing: responseData.schedule?.Monday?.from_time
              ? moment
                .utc()
                .startOf("day")
                .add(responseData.schedule.Monday.from_time, "minutes")
                .format("HH:mm")
              : "",
            monday_end_timing: responseData.schedule?.Monday?.to_time
              ? moment
                .utc()
                .startOf("day")
                .add(responseData.schedule.Monday.to_time, "minutes")
                .format("HH:mm")
              : "",
            tuesdayTitle: responseData.schedule?.Tuesday?.title || "",
            tuesday_start_timing: responseData.schedule?.Tuesday?.from_time
              ? moment
                .utc()
                .startOf("day")
                .add(responseData.schedule.Tuesday.from_time, "minutes")
                .format("HH:mm")
              : "",
            tuesday_end_timing: responseData.schedule?.Tuesday?.to_time
              ? moment
                .utc()
                .startOf("day")
                .add(responseData.schedule.Tuesday.to_time, "minutes")
                .format("HH:mm")
              : "",
            wednesdayTitle: responseData.schedule?.Wednesday?.title || "",
            wednesday_start_timing: responseData.schedule?.Wednesday?.from_time
              ? moment
                .utc()
                .startOf("day")
                .add(responseData.schedule.Wednesday.from_time, "minutes")
                .format("HH:mm")
              : "",
            wednesday_end_timing: responseData.schedule?.Wednesday?.to_time
              ? moment
                .utc()
                .startOf("day")
                .add(responseData.schedule.Wednesday.to_time, "minutes")
                .format("HH:mm")
              : "",
            thursdayTitle: responseData.schedule?.Thursday?.title || "",
            thursday_start_timing: responseData.schedule?.Thursday?.from_time
              ? moment
                .utc()
                .startOf("day")
                .add(responseData.schedule.Thursday.from_time, "minutes")
                .format("HH:mm")
              : "",
            thursday_end_timing: responseData.schedule?.Thursday?.to_time
              ? moment
                .utc()
                .startOf("day")
                .add(responseData.schedule.Thursday.to_time, "minutes")
                .format("HH:mm")
              : "",
            fridayTitle: responseData.schedule?.Friday?.title || "",
            friday_start_timing: responseData.schedule?.Friday?.from_time
              ? moment
                .utc()
                .startOf("day")
                .add(responseData.schedule.Friday.from_time, "minutes")
                .format("HH:mm")
              : "",
            friday_end_timing: responseData.schedule?.Friday?.to_time
              ? moment
                .utc()
                .startOf("day")
                .add(responseData.schedule.Friday.to_time, "minutes")
                .format("HH:mm")
              : "",
            saturdayTitle: responseData.schedule?.Saturday?.title || "",
            saturday_start_timing: responseData.schedule?.Saturday?.from_time
              ? moment
                .utc()
                .startOf("day")
                .add(responseData.schedule.Saturday.from_time, "minutes")
                .format("HH:mm")
              : "",
            saturday_end_timing: responseData.schedule?.Saturday?.to_time
              ? moment
                .utc()
                .startOf("day")
                .add(responseData.schedule.Saturday.to_time, "minutes")
                .format("HH:mm")
              : "",
            sundayTitle: responseData.schedule?.Sunday?.title || "",
            sunday_start_timing: responseData.schedule?.Sunday?.from_time
              ? moment
                .utc()
                .startOf("day")
                .add(responseData.schedule.Sunday.from_time, "minutes")
                .format("HH:mm")
              : "",
            sunday_end_timing: responseData.schedule?.Sunday?.to_time
              ? moment
                .utc()
                .startOf("day")
                .add(responseData.schedule.Sunday.to_time, "minutes")
                .format("HH:mm")
              : "",
            enterFullAddress: responseData.address || "",
            termsAndConditions: responseData.terms_and_conditions || "",
            logo: (responseData && responseData.logo) || "",
            coverImage: (responseData && responseData.display_image) || "",
          });
        }
        console.log(responseData.status, "responseDataresponseDataresponseDataresponseData")
        // setValue("photos", responseData.photos[0].url)
        responseData.status == "Active" ? setStatus(true) : setStatus(false)
        // setStatus(false);
        responseData.photos &&
          responseData.photos.length > 0 &&
          setClubPhotosUrl(responseData.photos);
        logo && setLogo(responseData.logo);
        logo && setPreviewImage(responseData.logo);
        coverImage && setCoverImage(responseData.display_image);
        coverImage && setCoverPreviewImage(responseData.display_image);
        responseData &&
          responseData.time_zone &&
          setTimeZone(responseData.time_zone);
        responseData && responseData.ladies_night && setLadiesNights(responseData.ladies_night)

        // For Locations

        try {
          const statesData = await getAllStates(responseData.country_id);
          const citiesData = await getCities(responseData.state_id);

          responseData && setCountryId(responseData.country_id);
          responseData && setCountry(responseData.country_name);

          responseData && setStateId(responseData.state_id);
          responseData && setState(responseData.state_name);

          responseData && setCityId(responseData.city_id);
          responseData && setCity(responseData.city_name);
          responseData.slots && setTableEntitiesData(responseData.slots);
        } catch (error) {
          console.error(error);
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  // CLUB UPDATION

  // Address validation
  async function addressValidation(address) {
    let myAPIKey = process.env.REACT_APP_GOOGLEMAP_KEY;
    let url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${myAPIKey}`;
    const result = await axios
      .get(url)
      .then((res) => {
        if (res.data.status === "ZERO_RESULTS") {
          return {
            status: false,
          };
        } else {
          return {
            status: true,
            results: res.data.results,
          };
        }
      })
      .catch((err) => {
        return {
          status: false,
        };
      });
    return result;
  }

  // Image Uploading

  //Uploading image
  const insertingImage = async (imageData) => {
    let form = new FormData();
    form.append("images", imageData);
    let responseImage = await axios
      .post(
        `${process.env.REACT_APP_MULTIPLEIMAGES_POSTING_URL}/uploads/uploadImages`,
        form,
        { headers: { "Content-Type": "multipart/form-data" } }
      )
      .then((resImage) => {
        return resImage && resImage.data.url[0];
      })
      .catch((error) => console.log(error, "err"));
    return responseImage;
  };

  //Uploading images
  const insertingImages = async (images) => {
    const form = new FormData();
    let resultantArray = [];
    for (let index = 0; index < images.length; index++) {
      form.append("images", images[index]);
    }
    let responseImages = await axios
      .post(
        `${process.env.REACT_APP_MULTIPLEIMAGES_POSTING_URL}/uploads/uploadImages`,
        form,
        { headers: { "Content-Type": "multipart/form-data" } }
      )
      .then((resImage) => {
        return resImage && resImage.data.url;
      })
      .catch((error) => console.log(error, "err"));
    // return responseImage;
    responseImages &&
      responseImages.map((image) => {
        resultantArray.push({
          url: image,
          status: "Active",
          featured: true,
        });
      });
    return resultantArray;
  };

  // Image Uploading

  // Cache
  const handleCache = async () => {
    const token = localStorage.getItem("authenticate");
    const headers = {
      headers: {
        developement: true,
        token: token,
      },
    };
    await axios
      .post(`${process.env.REACT_APP_API_URL}/dashboard/v1/clearCache`, {}, headers)
      .then((res) => { })
      .catch((err) => { });
  };


  // URL VALIDATION
  const isURLValid = (url) => {
    // Regular expression to validate URLs
    const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    return urlPattern.test(url);
  };


  // Ladies Night
  const handleLadiesNight = (day, event) => {
    if ((ladiesNights.length >= 3)) {
      if (!ladiesNights.includes(day)) {
        setLadiesNightWarningModal(true);
        event.target.checked = false;
        setTimeout(() => {
          setLadiesNightWarningModal(false);
        }, 3000)
        return;
      }
    }
    switch (day) {
      case "Monday": {
        if (!ladiesNights.includes(day)) {
          setLadiesNights([...ladiesNights, day]);
        } else {
          setLadiesNights(ladiesNights.filter(d => d !== day));
        }
        break;
      }
      case "Tuesday": {
        if (!ladiesNights.includes(day)) {
          setLadiesNights([...ladiesNights, day]);
        } else {
          setLadiesNights(ladiesNights.filter(d => d !== day));
        }
        break;
      }
      case "Wednesday": {
        if (!ladiesNights.includes(day)) {
          setLadiesNights([...ladiesNights, day]);
        } else {
          setLadiesNights(ladiesNights.filter(d => d !== day));
        }
        break;
      }
      case "Thursday": {
        if (!ladiesNights.includes(day)) {
          setLadiesNights([...ladiesNights, day]);
        } else {
          setLadiesNights(ladiesNights.filter(d => d !== day));
        }
        break;
      }
      case "Friday": {
        if (!ladiesNights.includes(day)) {
          setLadiesNights([...ladiesNights, day]);
        } else {
          setLadiesNights(ladiesNights.filter(d => d !== day));
        }
        break;
      }
      case "Saturday": {
        if (!ladiesNights.includes(day)) {
          setLadiesNights([...ladiesNights, day]);
        } else {
          setLadiesNights(ladiesNights.filter(d => d !== day));
        }
        break;
      }
      case "Sunday": {
        if (!ladiesNights.includes(day)) {
          setLadiesNights([...ladiesNights, day]);
        } else {
          setLadiesNights(ladiesNights.filter(d => d !== day));
        }
        break;
      }
      default:
        console.log("Invalid")
    }
  }






  //  CLUB SUBMISSION

  const handleForm = async (data) => {
    let {
      enterFullAddress,
      termsAndConditions,
      financeCoordinatorDetails,
      accountWhatsapp,
      accountPhone,
      financeCoordinatorEmail,
      financeCoordinatorName,
      clubName,
      dress,
      vipManagerPhone,
      vipEmailId,
      vipManagerName,
      vipManagerWhatsapp,
      doorManagerPhone,
      doorManagerEmail,
      doorManagerName,
      doorManagerWhatsapp,
      communicationEmail,
      communicationLink,
      clubDescription,
      isFeatured,
      // clubLatLong,
    } = data;
    let latitude = 0;
    let longitude = 0;
    let id = club_id ? club_id : uuid();
    let addressResult = await addressValidation(data.enterFullAddress);
    if (addressResult.status === true) {
      setWrongAddress(false);
      latitude = addressResult.results[0].geometry.location.lat;
      longitude = addressResult.results[0].geometry.location.lng;
    } else {
      setWrongAddress(true);
      return;
    }
    // if (clubPhotos.concat(clubPhotosUrl).length === 0) {
    //   setPhotoError("This is required field");
    //   return;
    // }

    //  Loading started  --- Validation completed

    setShowToaster(true);
    setToasterDetails((prev) => ({
      ...prev,
      title: club_id ? "Updating..." : "Creating...",
      bodyText: club_id ? "Updating Club" : "Creating Club",
      bg: "warning",
      statusIcon: (
        <div className="spinner-border text-warning me-2" role="status"></div>
      ),
    }));

    // Image Uploading
    let logoImage =
      typeof logo === "object" ? await insertingImage(logo) : logo;
    let uploadCoverImage =
      typeof coverImage === "object"
        ? await insertingImage(coverImage)
        : coverImage;
    let newlyInsertedPhotos =
      clubPhotos.length > 0 ? await insertingImages(clubPhotos) : [];
    let mergingBothNewAndOldPhotos = clubPhotosUrl.concat(newlyInsertedPhotos);

    const payload = {
      id: id,
      name: clubName,
      name_lower_case: clubName.toLowerCase(),
      status: status ? "Active" : "Inactive",
      country_id: countryId,
      country_name: country,
      state_id: stateId,
      state_name: state,
      city_id: cityId,
      city_name: city,
      city_name_lower_case: city.toLowerCase(),
      featured: false,
      slots: tableEntitiesData,
      dress: dress,
      address: enterFullAddress,
      display_image: uploadCoverImage ? uploadCoverImage : null,
      photos: mergingBothNewAndOldPhotos ? mergingBothNewAndOldPhotos : null,
      logo: logoImage ? logoImage : null,
      club_vip_manager: {
        contact_no: vipManagerPhone,
        email: vipEmailId,
        name: vipManagerName,
        whatsapp_no: vipManagerWhatsapp,
      },
      club_door_manager: {
        contact_no: doorManagerPhone,
        email: doorManagerEmail,
        name: doorManagerName,
        whatsapp_no: doorManagerWhatsapp,
      },
      club_email_id: communicationEmail,
      website_url: communicationLink,
      description: clubDescription,
      //  club_address: clubAddress,
      club_account_details: {
        finance_coordinator_name: financeCoordinatorName,
        email: financeCoordinatorEmail,
        mobile: accountPhone,
        whatsapp_no: accountWhatsapp,
        account_details: financeCoordinatorDetails,
      },

      schedule: {
        Monday: {
          from_time: moment
            .duration({
              hour: data.monday_start_timing.slice(0, 2),
              minutes: data.monday_start_timing.slice(3, 5),
            })
            .asMinutes(),
          to_time: moment
            .duration({
              hour: data.monday_end_timing.slice(0, 2),
              minutes: data.monday_end_timing.slice(3, 5),
            })
            .asMinutes(),
          title: data.mondayTitle,
        },
        Tuesday: {
          from_time: moment
            .duration({
              hour: data.tuesday_start_timing.slice(0, 2),
              minutes: data.tuesday_start_timing.slice(3, 5),
            })
            .asMinutes(),
          to_time: moment
            .duration({
              hour: data.tuesday_end_timing.slice(0, 2),
              minutes: data.tuesday_end_timing.slice(3, 5),
            })
            .asMinutes(),
          title: data.tuesdayTitle,
        },
        Wednesday: {
          from_time: moment
            .duration({
              hour: data.wednesday_start_timing.slice(0, 2),
              minutes: data.wednesday_start_timing.slice(3, 5),
            })
            .asMinutes(),
          to_time: moment
            .duration({
              hour: data.wednesday_end_timing.slice(0, 2),
              minutes: data.wednesday_end_timing.slice(3, 5),
            })
            .asMinutes(),
          title: data.wednesdayTitle,
        },
        Thursday: {
          from_time: moment
            .duration({
              hour: data.thursday_start_timing.slice(0, 2),
              minutes: data.thursday_start_timing.slice(3, 5),
            })
            .asMinutes(),
          to_time: moment
            .duration({
              hour: data.thursday_end_timing.slice(0, 2),
              minutes: data.thursday_end_timing.slice(3, 5),
            })
            .asMinutes(),
          title: data.thursdayTitle,
        },
        Friday: {
          from_time: moment
            .duration({
              hour: data.friday_start_timing.slice(0, 2),
              minutes: data.friday_start_timing.slice(3, 5),
            })
            .asMinutes(),
          to_time: moment
            .duration({
              hour: data.friday_end_timing.slice(0, 2),
              minutes: data.friday_end_timing.slice(3, 5),
            })
            .asMinutes(),
          title: data.fridayTitle,
        },
        Saturday: {
          from_time: moment
            .duration({
              hour: data.saturday_start_timing.slice(0, 2),
              minutes: data.saturday_start_timing.slice(3, 5),
            })
            .asMinutes(),
          to_time: moment
            .duration({
              hour: data.saturday_end_timing.slice(0, 2),
              minutes: data.saturday_end_timing.slice(3, 5),
            })
            .asMinutes(),
          title: data.saturdayTitle,
        },
        Sunday: {
          from_time: moment
            .duration({
              hour: data.sunday_start_timing.slice(0, 2),
              minutes: data.sunday_start_timing.slice(3, 5),
            })
            .asMinutes(),
          to_time: moment
            .duration({
              hour: data.sunday_end_timing.slice(0, 2),
              minutes: data.sunday_end_timing.slice(3, 5),
            })
            .asMinutes(),
          title: data.sundayTitle,
        },
      },
      createdAt: new Date(),
      updatedAt: new Date(),
      terms_and_conditions: termsAndConditions,
      longitude: longitude,
      latitude: latitude,
      time_zone: timezone,
      status: status ? "Active" : "Inactive",
      featured: isFeatured,
      ladies_night: ladiesNights
    };

    try {
      let query = club_id
        ? updateDoc(doc(db, "clubs", club_id), payload)
        : setDoc(doc(db, "clubs", id), payload);
      await query
        .then(() => {
          setToasterDetails((prev) => ({
            ...prev,
            title: "Success",
            bodyText: club_id
              ? "Club Updated Successfully"
              : "Club Created Successfully",
            bg: "success",
            statusIcon: (
              <i className="bi bi-check text-success text-3xl me-2"></i>
            ),
          }));
          handleCache();
          // Redirection to club index page
          setTimeout(() => {
            navigate("/clubs");
          }, 2000);
        })
        .catch((err) => console.error(err));
    } catch (err) {
      setToasterDetails((prev) => ({
        ...prev,
        title: "Error",
        bodyText: "Something went wrong",
        bg: "danger",
        statusIcon: (
          <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
        ),
      }));
    }
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const validateImage = (file) => {
    if (!file) {
      return "Image is required.";
    }

    const allowedExtensions = ["jpg", "jpeg", "png", "gif"]; // Add more if needed
    const extension = file.name.split(".").pop();

    if (!allowedExtensions.includes(extension.toLowerCase())) {
      return "Invalid file type. Please upload an image.";
    }

    return true;
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const validation = validateImage(file);

    if (validation !== true) {
      setError("image", {
        type: "manual",
        message: validation,
      });
    } else {
      clearErrors("image");
    }

    setSelectedFile(file);
  };

  return (
    <Layout path={club_id ? '/clubs/edit-club' : '/clubs/create-club'}>
      <form onSubmit={handleSubmit(handleForm)}>
        {/* Club Name : Start  */}

        <ContentWrapper additionalClass="p-5 mb-4">
          <Row className="g-4">
            <Col xs={12}>
              <h5 className="text-2xl font-body font-bold">CLUB NAME</h5>
            </Col>
            <Col xl={12}>
              <h4 className="text-md font-semibold color-gray mb-2">
                Club name <span className="text-danger">*</span>
              </h4>
              <input
                type="text"
                className="po-edit-input"
                placeholder="Enter name"
                name="club-name"
                {...register("clubName", {
                  required: "This is required field",
                  pattern: {
                    value: /^(?!\s*$).+/,
                    message: "Only alpha-numeric characters are allowed.",
                  },
                })}
              />
              <p className="text-danger font-body text-sm mb-0">
                {errors.clubName?.message}
              </p>
            </Col>
          </Row>
        </ContentWrapper>

        {/* Club Name : End */}

        {/* Club VIP Managers : Start */}

        <ContentWrapper additionalClass="p-5 mb-4">
          <Row className="g-4">
            <Col xs={12}>
              <h5 className="text-2xl font-body font-bold">CLUB VIP MANAGER</h5>
            </Col>
            {/* Club Manager Name */}
            <Col xl={6}>
              <h4 className="text-md font-semibold color-gray mb-2">
                Name <span className="text-danger">*</span>
              </h4>
              <input
                type="text"
                className="po-edit-input  "
                placeholder="Enter manager name"
                // value={managerName}
                {...register("vipManagerName", {
                  required: "This is required field",
                  pattern: {
                    value: /^(?!\s*$).+/,
                    message: "Only alpha-numeric characters are allowed.",
                  },
                })}
              // onChange={(e) => setManagerName(e.target.value)}
              />
              <p className="text-danger font-body text-sm">
                {errors.vipManagerName?.message}
              </p>
            </Col>
            {/* Club Manager Name */}

            {/* Email     */}
            <Col xl={6}>
              <h4 className="text-md font-semibold color-gray mb-2">
                Email <span className="text-danger"> *</span>
              </h4>
              <input
                type="text"
                className="po-edit-input"
                placeholder="Enter email ID"
                // value={email}
                {...register("vipEmailId", {
                  required: "This is required field",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "This is not a valid email",
                  },
                })}
              // onChange={(e) => setEmail(e.target.value)}
              />
              <p className=" text-danger font-body text-sm">
                {errors.vipEmailId?.message}
              </p>
            </Col>
            {/* Email     */}

            {/* Club Manager Phone number */}
            <Col xl={6}>
              <h4 className="text-md font-semibold color-gray mb-2">
                Phone number <span className="text-danger">*</span>
              </h4>
              <Controller
                name="vipManagerPhone"
                control={control}
                rules={{
                  validate: (value) => {
                    if (!value) {
                      return "This is required field";
                    }
                    if (!isValidPhoneNumber(value)) {
                      return "Invalid phone number";
                    }
                    return true;
                  },
                }}
                render={({ field }) => {
                  return (
                    <PhoneInput
                      {...field}
                      defaultCountry="SG"
                      placeholder="Enter phone number"
                      value={field.value}
                      // onChange={(e => setManagerPhoneNumber(fields.value))}
                      onChange={(value) => {
                        if (value !== undefined) {
                          field.onChange(value);
                          setValue("vipManagerPhone", value, {
                            shouldValidate: true,
                          });
                        }
                      }}
                    />
                  );
                }}
              />
              {errors.vipManagerPhone && (
                <p className="text-danger font-body text-sm mb-0">
                  {errors.vipManagerPhone.message}
                </p>
              )}
            </Col>
            {/* Club Manager Phone number */}

            {/* Club Manager Whatsapp Phone number */}
            <Col xl={6}>
              <h4 className="text-md font-semibold color-gray mb-2">
                Whatsapp number <span className="text-danger">*</span>
              </h4>
              <Controller
                name="vipManagerWhatsapp"
                control={control}
                rules={{
                  validate: (value) => {
                    if (!value) {
                      return "This is required field";
                    }
                    if (!isValidPhoneNumber(value)) {
                      return "Invalid phone number";
                    }
                    return true;
                  },
                }}
                render={({ field }) => {
                  return (
                    <PhoneInput
                      {...field}
                      defaultCountry="SG"
                      placeholder="Enter whatsapp number"
                      value={field.value}
                      onChange={(value) => {
                        if (value !== undefined) {
                          field.onChange(value);
                          setValue("vipManagerWhatsapp", value, {
                            shouldValidate: true,
                          });
                        }
                      }}
                    />
                  );
                }}
              />

              {errors.vipManagerWhatsapp && (
                <p className="text-danger font-body text-sm mb-0">
                  {errors.vipManagerWhatsapp.message}
                </p>
              )}
            </Col>
            {/* Club Manager Whatsapp Phone number */}
          </Row>
        </ContentWrapper>

        {/* Club VIP Managers : End */}

        {/* Club DOOR Manager : Start */}
        <ContentWrapper additionalClass="p-5 mb-4">
          <Row className="g-4">
            <Col xs={12}>
              <h5 className="text-2xl font-body font-bold">
                CLUB DOOR MANAGER{" "}
              </h5>
            </Col>

            {/* Manager Name */}

            <Col xl={6}>
              <h4 className="text-md font-semibold color-gray mb-2">
                Name <span className="text-danger">*</span>
              </h4>
              <input
                type="text"
                className="po-edit-input  "
                placeholder="Enter club door manager name"
                // // value={managerName}
                {...register("doorManagerName", {
                  required: "This is required field",
                  pattern: {
                    value: /^(?!\s*$).+/,
                    message: "Only alpha-numeric characters are allowed.",
                  },
                })}
              // onChange={(e) => setManagerName(e.target.value)}
              />
              <p className="text-danger font-body text-sm">
                {errors.doorManagerName?.message}
              </p>
            </Col>

            {/* Manager Email     */}
            <Col xl={6}>
              <h4 className="text-md font-semibold color-gray mb-2">
                Email <span className="text-danger"> *</span>
              </h4>
              <input
                type="text"
                className="po-edit-input"
                placeholder="Enter email ID"
                {...register("doorManagerEmail", {
                  required: "This is required field",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "This is not a valid email",
                  },
                })}
              />
              <p className=" text-danger font-body text-sm">
                {errors.doorManagerEmail?.message}
              </p>
            </Col>

            {/* Manager Phone number */}
            <Col xl={6}>
              <h4 className="text-md font-semibold color-gray mb-2">
                Phone number <span className="text-danger">*</span>
              </h4>
              <Controller
                name="doorManagerPhone"
                control={control}
                rules={{
                  validate: (value) => {
                    if (!value) {
                      return "This is required field";
                    }
                    if (!isValidPhoneNumber(value)) {
                      return "Invalid phone number";
                    }
                    return true;
                  },
                }}
                render={({ field }) => {
                  return (
                    <PhoneInput
                      {...field}
                      defaultCountry="SG"
                      placeholder="Enter phone number"
                      value={field.value}
                      onChange={(value) => {
                        field.onChange(value);
                        setValue("doorManagerPhone", value, {
                          shouldValidate: true,
                        });
                      }}
                    />
                  );
                }}
              />

              {errors.doorManagerPhone && (
                <p className=" text-danger font-body text-sm">
                  {errors.doorManagerPhone.message}
                </p>
              )}
            </Col>

            {/* Club Manager Whatsapp Phone number */}
            <Col xl={6}>
              <h4 className="text-md font-semibold color-gray mb-2">
                Whatsapp Number <span className="text-danger">*</span>
              </h4>
              <Controller
                name="doorManagerWhatsapp"
                control={control}
                rules={{
                  validate: (value) => {
                    if (!value) {
                      return "This is required field";
                    }
                    if (!isValidPhoneNumber(value)) {
                      return "Invalid phone number";
                    }
                    return true;
                  },
                }}
                render={({ field }) => {
                  return (
                    <PhoneInput
                      {...field}
                      defaultCountry="SG"
                      placeholder="Enter whatsapp number"
                      value={field.value}
                      onChange={(value) => {
                        field.onChange(value);
                        setValue("doorManagerWhatsapp", value, {
                          shouldValidate: true,
                        });
                      }}
                    />
                  );
                }}
              />

              {errors.doorManagerWhatsapp && (
                <p className=" text-danger font-body text-sm">
                  {errors.doorManagerWhatsapp.message}
                </p>
              )}
            </Col>
          </Row>
        </ContentWrapper>
        {/* Club DOOR Manager : End */}

        {/* CLUB COMMUNICATION DETAILS : Start */}

        <ContentWrapper additionalClass="p-5 mb-4">
          <Row className="g-4">
            <Col xs={12}>
              <h5 className="text-2xl font-body font-bold">
                CLUB COMMUNICATION DETAILS
              </h5>
            </Col>

            {/* Club Email ID */}

            <Col xl={6}>
              <h4 className="text-md font-semibold color-gray mb-2">
                Club Email ID <span className="text-danger">*</span>
              </h4>
              <input
                type="text"
                className="po-edit-input  "
                placeholder="Enter email Id"
                {...register("communicationEmail", {
                  required: "This is required field",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "This is not a valid email",
                  },
                })}
              />
              <p className="text-danger font-body text-sm">
                {errors.communicationEmail?.message}
              </p>
            </Col>

            {/* Website Link */}

            <Col xl={6}>
              <h4 className="text-md font-semibold color-gray mb-2">
                Website Link <span className="text-danger">*</span>
              </h4>
              <input
                type="text"
                className="po-edit-input"
                placeholder="Enter link"
                {...register("communicationLink", {
                  required: "This is a required field",
                  validate: (value) => isURLValid(value) || "Enter a valid URL",
                })}
              />
              <p className="text-danger font-body text-sm">
                {errors.communicationLink?.message}
              </p>
            </Col>

            {/* Club Description */}

            <Col xl={6}>
              <h4 className="text-md font-semibold color-gray mb-2">
                Club Description <span className="text-danger">*</span>
              </h4>
              <textarea
                className="po-edit-input  "
                placeholder="Enter description"
                {...register("clubDescription", {
                  required: "This is required field",
                  pattern: {
                    value: /^(?!\s*$).+/,
                    message: "Only alpha-numeric characters are allowed.",
                  },
                })}
              />
              <p className="text-danger font-body text-sm">
                {errors.clubDescription?.message}
              </p>
            </Col>
          </Row>
        </ContentWrapper>

        {/* CLUB COMMUNICATION DETAILS : End */}

        {/* CLUB ACCOUNT DETAILS : Start */}

        <ContentWrapper additionalClass="p-5 mb-4">
          <Row className="g-4">
            <Col xs={12}>
              <h5 className="text-2xl font-body font-bold">
                CLUB ACCOUNT DETAILS{" "}
              </h5>
            </Col>

            {/*  Finance Coordinator name */}

            <Col xl={6}>
              <h4 className="text-md font-semibold color-gray mb-2">
                Finance Coordinator Name <span className="text-danger">*</span>
              </h4>
              <input
                type="text"
                className="po-edit-input  "
                placeholder="Finance Coordinator Name"
                {...register("financeCoordinatorName", {
                  required: "This is required field",
                  pattern: {
                    value: /^(?!\s*$).+/,
                    message: "Only alpha-numeric characters are allowed.",
                  },
                })}
              />
              <p className="text-danger font-body text-sm">
                {errors.financeCoordinatorName?.message}
              </p>
            </Col>

            {/* Coordinator Email */}

            <Col xl={6}>
              <h4 className="text-md font-semibold color-gray mb-2">
                Email <span className="text-danger">*</span>
              </h4>
              <input
                type="text"
                className="po-edit-input  "
                placeholder="Enter email Id"
                {...register("financeCoordinatorEmail", {
                  required: "This is required field",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "This is not a valid email",
                  },
                })}
              />
              <p className="text-danger font-body text-sm">
                {errors.financeCoordinatorEmail?.message}
              </p>
            </Col>

            {/* Coordinator Mobile Number */}

            <Col xl={6}>
              <h4 className="text-md font-semibold color-gray mb-2">
                Mobile <span className="text-danger">*</span>
              </h4>
              <Controller
                name="accountPhone"
                control={control}
                rules={{
                  validate: (value) => {
                    if (!value) {
                      return "This is required field";
                    }
                    if (!isValidPhoneNumber(value)) {
                      return "Invalid phone number";
                    }
                    return true;
                  },
                }}
                render={({ field }) => {
                  return (
                    <PhoneInput
                      {...field}
                      defaultCountry="SG"
                      placeholder="Enter phone number"
                      value={field.value}
                      onChange={(value) => {
                        if (value !== undefined) {
                          field.onChange(value);
                          setValue("accountPhone", value, {
                            shouldValidate: true,
                          });
                        }
                      }}
                    />
                  );
                }}
              />

              {errors.accountPhone && (
                <p className=" text-danger font-body text-sm">
                  {errors.accountPhone.message}
                </p>
              )}
            </Col>

            {/* Coordinator Whatsapp */}

            <Col xl={6}>
              <h4 className="text-md font-semibold color-gray mb-2">
                WhatsApp <span className="text-danger">*</span>
              </h4>
              <Controller
                name="accountWhatsapp"
                control={control}
                rules={{
                  validate: (value) => {
                    if (!value) {
                      return "This is required field";
                    }
                    if (!isValidPhoneNumber(value)) {
                      return "Invalid phone number";
                    }
                    return true;
                  },
                }}
                render={({ field }) => {
                  return (
                    <PhoneInput
                      {...field}
                      defaultCountry="SG"
                      placeholder="Enter whatsapp number"
                      value={field.value}
                      onChange={(value) => {
                        if (value !== undefined) {
                          field.onChange(value);
                          setValue("accountWhatsapp", value, {
                            shouldValidate: true,
                          });
                        }
                      }}
                    />
                  );
                }}
              />

              {errors.accountWhatsapp && (
                <p className=" text-danger font-body text-sm">
                  {errors.accountWhatsapp.message}
                </p>
              )}
            </Col>

            {/* Payment Ac Details */}

            <Col xl={12}>
              <h4 className="text-md font-semibold color-gray mb-2">
                Bank AC Details for payment coordination.{" "}
                <span className="text-danger">*</span>
              </h4>
              <Controller
                name="financeCoordinatorDetails"
                control={control}
                rules={{
                  required: "This is required field",
                }}
                render={({ field }) => {
                  return (
                    <SimpleMdeReact
                      placeholder="Enter account details"
                      {...field}
                      onChange={(value) => {
                        if (value === "") {
                          setError("financeCoordinatorDetails", {
                            custom: true,
                            message: "This is required field",
                          });
                        } else if (!/^(?!\s*$).+/.test(value)) {
                          setError("financeCoordinatorDetails", {
                            custom: true,
                            message: "Only alpha-numeric characters are allowed.",
                          });
                        } else {
                          setError("financeCoordinatorDetails", {
                            custom: true,
                            message: "",
                          });
                        }
                        setValue("financeCoordinatorDetails", value);
                      }}
                    />
                  );
                }}
              />
              <p className="text-danger font-body text-sm">
                {errors.financeCoordinatorDetails?.message}
              </p>
            </Col>
          </Row>
        </ContentWrapper>

        {/* CLUB ACCOUNT DETAILS : End */}

        {/* Dress Code : Start */}

        <ContentWrapper additionalClass="p-5 mb-4">
          <Row className="g-4">
            <Col xs={12}>
              <h5 className="text-2xl font-body font-bold">DRESS CODE</h5>
            </Col>

            {/* Dress  */}

            <Col xl={12}>
              <h4 className="text-md font-semibold color-gray mb-2">
                Attire guidelines <span className="text-danger">*</span>
              </h4>
              <Controller
                name="dress"
                defaultValue="Smart casual and closed footwear"
                control={control}
                rules={{ required: "This is a required field" }}
                render={({ field }) => {
                  return (
                    <SimpleMdeReact
                      {...field}
                      onChange={(value) => {
                        if (value === "") {
                          setError("dress", {
                            type: "manual",
                            message: "This is required field",
                          });
                        } else if (!/^(?!\s*$).+/.test(value)) {
                          setError("dress", {
                            custom: true,
                            message: "Only alpha-numeric characters are allowed.",
                          });
                        } else {
                          setError("dress", {
                            custom: true,
                            message: null,
                          });
                          setValue("dress", value);
                        }
                      }}
                    />
                  );
                }}
              />
              <p className="text-danger font-body text-sm">
                {errors.dress?.message}
              </p>
            </Col>
            <Col xs={12}>
              <i className="bi bi-info-circle-fill text-md color-yellow1 me-2">
                <span className=" text-sm ml-1 text-dark">
                  Smart casual and closed footwear
                </span>
              </i>
            </Col>
          </Row>
        </ContentWrapper>

        {/* Dress Code : End */}

        {/* CLUB LOGO  : Start */}

        <ContentWrapper additionalClass="p-5 mb-4">
          <Row className="g-4">
            <Col xs={12}>
              <h5 className="text-2xl font-body font-bold">CLUB LOGO</h5>
            </Col>
            <Col xl={6} className="font-body">
              <label className="text-lg  font-medium color-gray mb-2">
                Club Logo <span className="text-danger">*</span>
              </label>

              <div
                style={{
                  backgroundImage: `url(${previewImage ? previewImage : null})`,
                  border: `${previewImage ? "0px" : "1px"} dashed #D2D6DA`,
                }}
                className="po-updateEvent__inputPhoto d-flex align-items-center justify-content-center background-position-center-top background-repeat-norepeat background-size-cover rounded overflow-hidden position-relative"
                {...register("logo", {
                  required: "This is required field",
                })}
              >
                {previewImage ? (
                  <div className="position-absolute end-0 top-0">
                    <ActionIcon
                      onClick={() => setLogoDeleteModal(true)}
                      additionalClass="bi bi-x-circle-fill text-3xl color-red me-2"
                    />
                  </div>
                ) : null}
                {previewImage ? null : (
                  <div>
                    <input
                      type="file"
                      id="image"
                      accept="image/*"
                      onChange={handleLogo}
                      value={logo ? logo : ""}
                      className="opacity-0 position-absolute start-0 end-0 top-0 bottom-0"
                    />
                    <div className="text-center">
                      <i className="bi bi-plus-circle-fill text-5xl color-lightgreen d-block"></i>
                      <p className="text-xs font-semibold font-body text-center color-gray2 ">
                        Upload image here
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <p className="text-danger font-body text-sm">
                {errors.logo?.message}
              </p>
              {/* <i className="bi bi-info-circle-fill  color-yellow1 me-2 cursor-pointer" >
                <span className="text-xs text-danger mb-0 mt-3 semi-bold">
                  Upload logo of size 200x200 pixels in one of the valid formats (JPEG, PNG, GIF, or BMP)
                </span>
              </i> */}
              {/* <div className="d-flex align-items-center">
                <i
                  className="bi bi-info-circle-fill"
                  style={{ color: "#E1AF26" }}
                ></i>
                <p className="ms-2 text-danger text-sm mb-0">
                  Upload logo of size 200x200 pixels in one of the valid formats (JPEG, PNG, GIF, or BMP)
                </p>
              </div> */}
              <i className="bi bi-info-circle-fill text-md color-yellow1 me-2">
                <span className=" text-sm ml-1 text-dark">
                  Upload logo of size 200x200 pixels in one of the valid formats (JPEG, PNG, GIF, or BMP)
                </span>
              </i>
            </Col>
          </Row>
        </ContentWrapper>

        {/* CLUB LOGO  : End */}

        {/* CLUB Cover  : Start */}

        <ContentWrapper additionalClass="p-5 mb-4">
          <Row className="g-4">
            <Col xs={12}>
              <h5 className="text-2xl font-body font-bold">
                CLUB COVER IMAGES{" "}
              </h5>
            </Col>
            <Col xl={6} className="font-body">
              <label className="text-lg  font-medium color-gray mb-2">
                Club Cover Image <span className="text-danger">*</span>
              </label>

              <div
                style={{
                  backgroundImage: `url(${coverPreviewImage ? coverPreviewImage : null
                    })`,
                  border: `${coverPreviewImage ? "0px" : "1px"} dashed #D2D6DA`,
                }}
                className="po-updateEvent__inputPhoto d-flex align-items-center justify-content-center background-position-center-top background-repeat-norepeat background-size-cover rounded overflow-hidden position-relative"
                {...register("coverImage", {
                  required: "This is required field",
                })}
              >
                {coverPreviewImage ? (
                  <div className="position-absolute end-0 top-0">
                    <ActionIcon
                      onClick={() => {
                        setCoverImageDeleteModal(true);
                      }}
                      additionalClass="bi bi-x-circle-fill text-3xl color-red me-2"
                    />
                  </div>
                ) : null}
                {coverPreviewImage ? null : (
                  <div>
                    <input
                      type="file"
                      id="image"
                      accept="image/*"
                      // value={state.display_image}
                      // {...register("display_image", { required: "Image is required" })}
                      onChange={handleCoverImage}
                      value={coverImage ? coverImage : ""}
                      className="opacity-0 position-absolute start-0 end-0 top-0 bottom-0"
                    />
                    <div className="text-center">
                      <i className="bi bi-plus-circle-fill text-5xl color-lightgreen d-block"></i>
                      <p className="text-xs font-semibold font-body text-center color-gray2 ">
                        Upload image here
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <p className="text-danger font-body text-sm mt-1">
                {errors.coverImage?.message}
              </p>
              <i className="bi bi-info-circle-fill text-md color-yellow1 me-2">
                <span className=" text-sm ml-1 text-dark">
                  Upload image of size 600x552 pixels in one of the valid formats (JPEG, PNG, GIF, or BMP)
                </span>
              </i>
            </Col>
          </Row>
        </ContentWrapper>

        {/* CLUB Cover  : End */}

        {/* Club Gallery photos : Start*/}

        <ContentWrapper additionalClass="p-5 mb-4">
          <Row className="g-4">
            <Col xs={12}>
              <h5 className="text-2xl font-body font-bold">
                CLUB Gallery photos{" "}
              </h5>
            </Col>
            <Col xl={6} className="font-body">
              <div
                className="po-updateEvent__inputPhoto d-flex align-items-center justify-content-center rounded position-relative"
                style={{
                  border: "1px dashed rgb(210, 214, 218)",
                }}
              >
                <input
                  disabled={clubPhotos.concat(clubPhotosUrl).length + 1 > 6 ? true : false}
                  type="file"
                  onChange={handleClubPhotos}
                  multiple
                  className="position-absolute opacity-0 start-0 bottom-0 top-0 end-0"

                />
                <div className="text-center">
                  <i className="bi bi-plus-circle-fill text-5xl color-lightgreen d-block"></i>
                  <p className="text-xs font-semibold font-body text-center color-gray2 ">
                    Upload image here
                  </p>
                </div>
              </div>

              {/* <h4 className="text-lg font-medium color-gray mb-2">
                Club Photos <span style={{ color: "red" }}>*</span>
              </h4> */}
              <p
                {...register("clubPhotos")}
              ></p>

              {clubPhotos.concat(clubPhotosUrl).length + 1 > 6 && (
                <p className="text-danger  m-2">
                  {" "}
                  You can select only up to six photos
                </p>
              )}
              {clubPhotos && clubPhotos.length > 0 && (
                <>
                  <h6 className="mt-5">Newly Added</h6>
                  <ul className="d-flex flex-wrap">
                    {clubPhotos &&
                      clubPhotos.length &&
                      clubPhotos.map((item, index) => (
                        <li
                          key={index}
                          className="text-xxs po-updateEvent__inputList-item my-1 d-flex justify-content-between"
                        >
                          <div
                            key={index}
                            style={{
                              height: "75px",

                              width: "75px",

                              backgroundImage: `url(${URL.createObjectURL(
                                item
                              )})`,
                            }}
                            className="position-relative mt-2 text-end me-2 background-position-center-center background-repeat-norepeat background-size-cover rounded overflow-hidden p-1"
                          >
                            <div
                              onClick={() => {
                                setPhotoDeleteModal(true);
                                setPhotoIndex({
                                  ...photoIndex,
                                  indexNumber: index,
                                  type: "newlyAdded",
                                });
                              }}
                              className="cursor-pointer d-inline-block rounded-circle d-flex align-items-center justify-content-center position-absolute top-0 end-0 z-2"
                              style={{
                                height: "20px",
                                width: "20px",
                              }}
                            >
                              <i className="bi d-inline-block cursor-pointer bi bi-x-circle-fill color-red me-1 mt-1 text-lg"></i>{" "}
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                </>
              )}
              {clubPhotosUrl && clubPhotosUrl && clubPhotosUrl.length > 0 && (
                <>
                  <h6 className="mt-6">The existing ones</h6>
                  <ul className="d-flex flex-wrap mt-2">
                    {clubPhotosUrl.map((item, index) => (
                      <li
                        key={index}
                        className="text-xxs po-updateEvent__inputList-item my-1 d-flex justify-content-between"
                      >
                        <div
                          key={index}
                          style={{
                            height: "75px",
                            width: "75px",
                            backgroundImage: `url(${item.url})`,
                          }}
                          className="position-relative mt-2 text-end me-2 background-position-center-center background-repeat-norepeat background-size-cover rounded overflow-hidden p-1"
                        >
                          <div
                            // onUpdateImagesList(index)
                            onClick={() => {
                              setPhotoDeleteModal(true);
                              setPhotoIndex({
                                ...photoIndex,
                                indexNumber: index,
                                type: "notNewlyAdded",
                              });
                            }}
                            className="cursor-pointer d-inline-block rounded-circle  d-flex align-items-center justify-content-center position-absolute top-0 end-0 z-2"
                            style={{
                              height: "20px",
                              width: "20px",
                            }}
                          >
                            <i className="bi d-inline-block cursor-pointer bi bi-x-circle-fill  color-red me-1 mt-1 text-lg"></i>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </>
              )}
              <p className="text-danger font-body text-sm">
                {maximumPhotoSize}
              </p>

              {photoError && (
                <div>
                  <p className="mb-7 text-danger font-body text-sm">
                    {photoError}
                  </p>
                </div>
              )}
              <p className="text-danger font-body text-sm mt-0">
                {errors.clubPhotos?.message}
              </p>
              <i className="bi bi-info-circle-fill text-md color-yellow1 me-2">
                <span className=" text-sm ml-1 text-dark">
                  Upload image of size 760x352 pixels in one of the valid formats (JPEG, PNG, GIF, or BMP)
                </span>
              </i>
            </Col>
          </Row>
        </ContentWrapper>

        {/* Club Gallery photos : End */}

        {/* Club Active state */}
        {console.log(status, "ssssstatus")}
        <ContentWrapper additionalClass="p-5 mb-4">
          <Col xl={6}>
            <div className="d-flex align-items-center">
              <label className="text-md font-bold" htmlFor="isActiveCheckbox">
                Is Active
              </label>
              <input
                id="isActiveCheckbox"
                value={status}
                checked={status}
                type="checkbox"
                className="ml-4"
                onChange={() => {
                  if (club_id !== undefined) {
                    setStatus(!status);
                  }
                }}
              />
            </div>
          </Col>

          {/* Featured Club  */}
          <Col xl={6}>
            <div className="d-flex align-items-center">
              <label className="text-md font-bold" htmlFor="isFeatureCheckbox">
                Is Featured
              </label>
              <input
                id="isFeatureCheckbox"
                type="checkbox"
                placeholder="isFeatured"
                {...register("isFeatured", {})}
                className="ml-4"
              />
            </div>
          </Col>
        </ContentWrapper>

        {/* Club Active state */}

        {/* Club working hours : Start */}

        {console.log(ladiesNights, "ladiesNights")}

        <ContentWrapper additionalClass="p-5 mb-4">
          <Row className="g-4">
            <Col xl={12}>
              <h5 className="text-2xl font-body font-bold">
                CLUB WORKING HOURS
              </h5>
            </Col>
            <Col xs={6} className="font-body">
              <div className="mb-3">
                <Row className="g-4">
                  <Col xs={12}>
                    <p className="mb-1 text-xl">
                      Monday <span className="text-danger">*</span>
                    </p>
                    <label style={{ fontWeight: "bold" }}>Party Theme : </label>
                    <input
                      type="text"
                      className="po-edit-input"
                      placeholder="Please enter title..."
                      {...register("mondayTitle", {
                        pattern: {
                          value: /^(?!\s*$).+/,
                          message: "Only alpha-numeric characters are allowed.",
                        },
                      })}
                    />
                    <div className="p-2 mt-1 user-select-none">
                      <label className="d-inline-flex align-items-center">
                        <input
                          style={{
                            height: "25px",
                            width: "25px",
                          }}
                          checked={ladiesNights.find(night => night === "Monday")}
                          onChange={(e) => handleLadiesNight("Monday", e)}
                          className="me-2 cursor-pointer "
                          type="checkbox"
                        />
                        <span className="bold user-select-none text-danger mt-1">Ladies night</span>
                      </label>
                    </div>
                    <p className="text-danger font-body text-sm mt-0">
                      {errors.mondayTitle?.message}
                    </p>
                  </Col>

                  <Col xs={6}>
                    <label style={{ fontWeight: "bold" }}>Start time : </label>
                    <input
                      type="time"
                      className="po-edit-input"
                      id="appt"
                      name="appt"
                      {...register("monday_start_timing", {
                        required: "This is required field",
                      })}
                    />
                    <p className="  text-danger font-body text-sm">
                      {errors.monday_start_timing?.message}
                    </p>
                  </Col>
                  <Col xs={6}>
                    <label style={{ fontWeight: "bold" }}>End time :</label>
                    <input
                      type="time"
                      className="po-edit-input"
                      id="appt"
                      name="appt"
                      {...register("monday_end_timing", {
                        required: "This is required field",
                      })}
                    />
                    <p className="  text-danger font-body text-sm">
                      {errors.monday_end_timing?.message}
                    </p>
                  </Col>
                </Row>

                <p className="  text-danger font-body text-sm">
                  {errors.mondayTimings?.message}
                </p>
                <div></div>
              </div>

              <div className="mb-3">
                <Row className="g-4">
                  <Col xs={12}>
                    <p className="mb-1 text-xl">
                      Tuesday <span className="text-danger">*</span>
                    </p>
                    <label style={{ fontWeight: "bold" }}>Party Theme : </label>
                    <input
                      type="text"
                      className="po-edit-input"
                      placeholder="Please enter title..."
                      {...register("tuesdayTitle", {
                        pattern: {
                          value: /^(?!\s*$).+/,
                          message: "Only alpha-numeric characters are allowed.",
                        },
                      })}
                    />
                    {/* Checkbox */}
                    <div className="d-inline-flex align-items-center">
                      <label className="p-2 mt-1 cursor-pointer d-flex align-items-center">
                        <input
                          checked={ladiesNights.find(night => night === "Tuesday")}
                          onChange={(e) => handleLadiesNight("Tuesday", e)}
                          style={{
                            height: "25px",
                            width: "25px",
                          }}
                          className="me-2 cursor-pointer "
                          type="checkbox"
                        />
                        <span className="bold user-select-none text-danger mt-1">Ladies night</span>
                      </label>
                    </div>
                    {/* Checkbox */}
                    <p className="text-danger font-body text-sm mt-0">
                      {errors.tuesdayTitle?.message}
                    </p>
                  </Col>
                  <Col xs={6}>
                    <label style={{ fontWeight: "bold" }}>Start time :</label>
                    <input
                      type="time"
                      className="po-edit-input"
                      id="appt"
                      name="appt"
                      {...register("tuesday_start_timing", {
                        required: "This is required field",
                      })}
                    />
                    <p className="  text-danger font-body text-sm">
                      {errors.tuesday_start_timing?.message}
                    </p>
                  </Col>
                  <Col xs={6}>
                    <label style={{ fontWeight: "bold" }}>End time :</label>
                    <input
                      type="time"
                      className="po-edit-input"
                      id="appt"
                      name="appt"
                      {...register("tuesday_end_timing", {
                        required: "This is required field",
                      })}
                    />
                    <p className="  text-danger font-body text-sm">
                      {errors.tuesday_end_timing?.message}
                    </p>
                  </Col>
                </Row>
              </div>

              <div className="mb-3">
                <Row className="g-4">
                  <Col xs={12}>
                    <p className="mb-1 text-xl">
                      Wednesday <span className="text-danger">*</span>
                    </p>
                    <label style={{ fontWeight: "bold" }}>Party Theme : </label>
                    <input
                      type="text"
                      className="po-edit-input"
                      placeholder="Please enter title..."
                      {...register("wednesdayTitle", {
                        pattern: {
                          value: /^(?!\s*$).+/,
                          message: "Only alpha-numeric characters are allowed.",
                        },
                      })}
                    />
                    {/* Checkbox */}
                    <div className="d-inline-flex align-items-center">
                      <label className="p-2 mt-1 cursor-pointer d-flex align-items-center">
                        <input
                          style={{
                            height: "25px",
                            width: "25px",
                          }}
                          className="me-2 cursor-pointer "
                          type="checkbox"
                          checked={ladiesNights.find(night => night === "Wednesday")}
                          onChange={(e) => handleLadiesNight("Wednesday", e)}
                        />
                        <span className="bold user-select-none text-danger mt-1">Ladies night</span>
                      </label>
                    </div>
                    {/* Checkbox */}
                    <p className="text-danger font-body text-sm mt-0">
                      {errors.wednesdayTitle?.message}
                    </p>
                  </Col>
                  <Col xs={6}>
                    <label style={{ fontWeight: "bold" }}>Start time :</label>
                    <input
                      type="time"
                      className="po-edit-input"
                      id="appt"
                      name="appt"
                      {...register("wednesday_start_timing", {
                        required: "This is required field",
                      })}
                    />
                    <p className="  text-danger font-body text-sm">
                      {errors.wednesday_start_timing?.message}
                    </p>
                  </Col>
                  <Col xs={6}>
                    <label style={{ fontWeight: "bold" }}>End time :</label>
                    <input
                      type="time"
                      id="appt"
                      className="po-edit-input"
                      name="appt"
                      {...register("wednesday_end_timing", {
                        required: "This is required field",
                      })}
                    />
                    <p className="  text-danger font-body text-sm">
                      {errors.wednesday_end_timing?.message}
                    </p>
                  </Col>
                </Row>
              </div>

              <div className="mb-3">
                <Row className="g-4">
                  <Col xs={12}>
                    <p className="mb-1 text-xl">
                      Thursday <span className="text-danger">*</span>
                    </p>
                    <label style={{ fontWeight: "bold" }}>Party Theme : </label>
                    <input
                      type="text"
                      className="po-edit-input"
                      placeholder="Please enter title..."
                      {...register("thursdayTitle", {
                        pattern: {
                          value: /^(?!\s*$).+/,
                          message: "Only alpha-numeric characters are allowed.",
                        },
                      })}
                    />
                    {/* Checkbox */}
                    <div className="d-inline-flex align-items-center">
                      <label className="p-2 mt-1 cursor-pointer d-flex align-items-center">
                        <input
                          style={{
                            height: "25px",
                            width: "25px",
                          }}
                          className="me-2 cursor-pointer "
                          type="checkbox"
                          checked={ladiesNights.find(night => night === "Thursday")}
                          onChange={(e) => handleLadiesNight("Thursday", e)}
                        />
                        <span className="bold user-select-none text-danger mt-1">Ladies night</span>
                      </label>
                    </div>
                    {/* Checkbox */}
                    <p className="text-danger font-body text-sm mt-0">
                      {errors.thursdayTitle?.message}
                    </p>
                  </Col>
                  <Col xs={6}>
                    <label style={{ fontWeight: "bold" }}>Start time :</label>
                    <input
                      type="time"
                      className="po-edit-input"
                      id="appt"
                      name="appt"
                      {...register("thursday_start_timing", {
                        required: "This is required field",
                      })}
                    />
                    <p className="  text-danger font-body text-sm">
                      {errors.thursday_start_timing?.message}
                    </p>
                  </Col>
                  <Col xs={6}>
                    <label style={{ fontWeight: "bold" }}>End time :</label>
                    <input
                      type="time"
                      className="po-edit-input"
                      id="appt"
                      name="appt"
                      {...register("thursday_end_timing", {
                        required: "This is required field",
                      })}
                    />
                    <p className="  text-danger font-body text-sm">
                      {errors.thursday_end_timing?.message}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={6} className="font-body">
              <div className="mb-3">
                <Row className="g-4">
                  <Col xs={12}>
                    <p className="mb-1 text-xl">
                      Friday <span className="text-danger">*</span>
                    </p>
                    <label style={{ fontWeight: "bold" }}>Party Theme : </label>
                    <input
                      type="text"

                      className="po-edit-input"
                      placeholder="Please enter title..."
                      {...register("fridayTitle", {
                        pattern: {
                          value: /^(?!\s*$).+/,
                          message: "Only alpha-numeric characters are allowed.",
                        },
                      })}
                    />
                    {/* Checkbox */}
                    <div className="d-inline-flex align-items-center">
                      <label className="p-2 mt-1 cursor-pointer d-flex align-items-center">
                        <input
                          style={{
                            height: "25px",
                            width: "25px",
                          }}
                          className="me-2 cursor-pointer "
                          type="checkbox"
                          checked={ladiesNights.find(night => night === "Friday")}
                          onChange={(e) => handleLadiesNight("Friday", e)}
                        />
                        <span className="bold user-select-none text-danger mt-1">Ladies night</span>
                      </label>
                    </div>
                    {/* Checkbox */}
                    <p className="  text-danger font-body text-sm">
                      {errors.fridayTitle?.message}
                    </p>
                  </Col>
                  <Col xs={6}>
                    <label style={{ fontWeight: "bold" }}>Start time :</label>
                    <input
                      type="time"
                      className="po-edit-input"
                      id="appt"
                      name="appt"
                      {...register("friday_start_timing", {
                        required: "This is required field",
                      })}
                    />
                    <p className="  text-danger font-body text-sm">
                      {errors.friday_start_timing?.message}
                    </p>
                  </Col>
                  <Col xs={6}>
                    <label style={{ fontWeight: "bold" }}>End time :</label>
                    <input
                      type="time"
                      id="appt"
                      className="po-edit-input"
                      name="appt"
                      {...register("friday_end_timing", {
                        required: "This is required field",
                      })}
                    />
                    <p className="  text-danger font-body text-sm">
                      {errors.friday_end_timing?.message}
                    </p>
                  </Col>
                </Row>
              </div>
              <div className="mb-3">
                <Row className="g-4">
                  <Col xs={12}>
                    <p className="mb-1 text-xl">
                      Saturday <span className="text-danger">*</span>
                    </p>
                    <label style={{ fontWeight: "bold" }}>Party Theme : </label>
                    <input
                      type="text"
                      className="po-edit-input"
                      placeholder="Please enter title..."
                      {...register("saturdayTitle", {
                        pattern: {
                          value: /^(?!\s*$).+/,
                          message: "Only alpha-numeric characters are allowed.",
                        },
                      })}
                    />
                    {/* Checkbox */}
                    <div className="d-inline-flex align-items-center">
                      <label className="p-2 mt-1 cursor-pointer d-flex align-items-center">
                        <input
                          style={{
                            height: "25px",
                            width: "25px",
                          }}
                          className="me-2 cursor-pointer "
                          type="checkbox"
                          checked={ladiesNights.find(night => night === "Saturday")}
                          onChange={(e) => handleLadiesNight("Saturday", e)}
                        />
                        <span className="bold user-select-none text-danger mt-1">Ladies night</span>
                      </label>
                    </div>
                    {/* Checkbox */}
                    <p className="  text-danger font-body text-sm">
                      {errors.saturdayTitle?.message}
                    </p>
                  </Col>
                  <Col xs={6}>
                    <label style={{ fontWeight: "bold" }}>Start time :</label>
                    <input
                      type="time"
                      id="appt"
                      className="po-edit-input"
                      name="appt"
                      {...register("saturday_start_timing", {
                        required: "This is required field",
                      })}
                    />
                    <p className="  text-danger font-body text-sm">
                      {errors.saturday_start_timing?.message}
                    </p>
                  </Col>
                  <Col xs={6}>
                    <label style={{ fontWeight: "bold" }}>End time :</label>
                    <input
                      type="time"
                      id="appt"
                      name="appt"
                      className="po-edit-input"
                      {...register("saturday_end_timing", {
                        required: "This is required field",
                      })}
                    />
                    <p className="  text-danger font-body text-sm">
                      {errors.saturday_end_timing?.message}
                    </p>
                  </Col>
                </Row>
              </div>

              <div className="mb-3">
                <Row className="g-4">
                  <Col xs={12}>
                    <p className="mb-1 text-xl">
                      Sunday <span className="text-danger">*</span>
                    </p>
                    <label style={{ fontWeight: "bold" }}>Party Theme : </label>
                    <input
                      type="text"
                      className="po-edit-input"
                      placeholder="Please enter title..."
                      {...register("sundayTitle", {
                        pattern: {
                          value: /^(?!\s*$).+/,
                          message: "Only alpha-numeric characters are allowed.",
                        },
                      })}
                    />
                    {/* Checkbox */}
                    <div className="d-inline-flex align-items-center">
                      <label className="p-2 mt-1 cursor-pointer d-flex align-items-center">
                        <input
                          style={{
                            height: "25px",
                            width: "25px",
                          }}
                          className="me-2 cursor-pointer "
                          type="checkbox"
                          checked={ladiesNights.find(night => night === "Sunday")}
                          onChange={(e) => handleLadiesNight("Sunday", e)}
                        />
                        <span className="bold user-select-none text-danger mt-1">Ladies night</span>
                      </label>
                    </div>
                    {/* Checkbox */}
                    <p className="  text-danger font-body text-sm">
                      {errors.sundayTitle?.message}
                    </p>
                  </Col>
                  <Col xs={6}>
                    <label style={{ fontWeight: "bold" }}>Start time :</label>
                    <input
                      type="time"
                      id="appt"
                      name="appt"
                      className="po-edit-input"
                      {...register("sunday_start_timing", {
                        required: "This is required field",
                      })}
                    />
                    <p className="  text-danger font-body text-sm">
                      {errors.sunday_start_timing?.message}
                    </p>
                  </Col>
                  <Col xs={6}>
                    <label style={{ fontWeight: "bold" }}>End time :</label>
                    <input
                      type="time"
                      id="appt"
                      name="appt"
                      className="po-edit-input"
                      {...register("sunday_end_timing", {
                        required: "This is required field",
                      })}
                    />
                    <p className="  text-danger font-body text-sm">
                      {errors.sunday_end_timing?.message}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </ContentWrapper>

        {/* Club working hours : End */}

        {/* Location : Start */}
        <ContentWrapper additionalClass="p-5 mb-4">
          <Row className="g-4">
            <Col xl={12}>
              <h5 className="text-2xl font-body font-bold">Club location</h5>
            </Col>

            <Col xs={6} className="font-body">
              <div>
                <h4 className="text-lg  font-medium color-gray mb-2">
                  Country
                </h4>
                <select
                  name="state"
                  id="state"
                  className="po-edit-input  "
                  placeholder="Enter Manager Name"
                  onChange={handleCountry}
                  value={countryId}
                >
                  {countries &&
                    countries.map((country, index) => {
                      return (
                        <option value={country.data().id} key={index}>
                          {country.data().name}
                        </option>
                      );
                    })}
                </select>
                <p className="  text-danger font-body text-sm">
                  {errors.selectState?.message}
                </p>
              </div>
              <div>
                <h4 className="text-lg  font-medium color-gray mb-2">State</h4>
                <select
                  name="state"
                  id="state"
                  className="po-edit-input "
                  placeholder="Enter Manager Name"
                  onChange={handleState}
                  value={stateId}
                >
                  {states &&
                    states.map((element, index) => {
                      return (
                        <option value={element.data().id} key={index}>
                          {element.data().name}
                        </option>
                      );
                    })}
                </select>
                <p className=" text-danger font-body text-sm">
                  {errors.selectZip?.message}
                </p>
              </div>
            </Col>
            <Col xs={6} className="font-body">
              <div>
                <h4 className="text-lg  font-medium color-gray mb-2">City</h4>
                <select
                  name="state"
                  id="state"
                  className="po-edit-input "
                  placeholder="Enter Manager Name"
                  value={cityId}
                  onChange={(e) => {
                    let temTableSlots = tableEntitiesData;
                    e.target.options &&
                      setCity(e.target.options[e.target.selectedIndex].text);
                    setCityId(e.target.value);
                    for (let index = 0; index < cities.length; index++) {
                      const element = cities[index];
                      if (element.data().id === e.target.value) {
                        setTimeZone(element.data().country_timezone);
                        // timezone = element.data().country_timezone;
                      }
                    }
                    temTableSlots &&
                      temTableSlots.map((ele) => {
                        ele["zone"] = timezone;
                      });
                    setTableEntitiesData(temTableSlots);
                  }}
                >
                  {cities &&
                    cities.map((ele, index) => {
                      return (
                        <option value={ele.data().id} key={index}>
                          {ele.data().name}
                        </option>
                      );
                    })}
                </select>
                <p className="  text-danger font-body text-sm">
                  {errors.selectCity?.message}
                </p>
              </div>
              <div>
                <h4 className="text-lg font-medium color-gray mb-2">
                  Full address <span className="text-danger">*</span>
                </h4>
                <input
                  type="text"
                  className="po-edit-input"
                  placeholder="Enter full address"
                  {...register("enterFullAddress", {
                    required: "This is required field",
                    pattern: {
                      value: /^.*$/,
                      message: "Only alpha-numeric characters are allowed.",
                    },
                  })}
                />
                <p className="  text-danger font-body text-sm">
                  {errors.enterFullAddress?.message}
                </p>
                {wrongAddress && (
                  <p className=" text-danger font-body text-sm">
                    Please enter valid address
                  </p>
                )}
              </div>
            </Col>
          </Row>
        </ContentWrapper>
        {/* Location : End */}

        {/* Slot timings : Start */}
        <ContentWrapper additionalClass="p-5 mb-4">
          <Row className="g-4">
            <Col xl={12}>
              <h5 className="text-2xl font-body font-bold">
                SLOT TIMINGS <span className="text-danger">*</span>
              </h5>
            </Col>
            <Col xl={6}>
              {tableDetailError.tableList && (
                <p className="text-danger">Atleast one slot required</p>
              )}
              <label>Slot Time :</label>
              <input
                type="time"
                className="po-edit-input"
                id="appt"
                name="appt"
                value={tableEntities.startTime}
                onChange={(e) => {
                  setTableEntities({
                    ...tableEntities,
                    startTime: e.target.value,
                  });
                  setTableEntitiesError({
                    ...tableEntitiesError,
                    startTime: false,
                  });
                }}
              />
              <div>
                <p className="text-danger">
                  {tableEntitiesError.startTime && "this is required field"}
                </p>
              </div>

              <Button
                className="color-black1 text-md font-body font-semibold cursor-pointer po-border po-border-yellow1 py-3 px-3 radius-105 d-inline-block bg-transparent text-dark"
                onClick={() => {
                  handleTableEntities();
                }}
                name="Add entity"
              >
                Add
              </Button>
            </Col>
            <Col xl={6}>
              <h4 className="text-lg  font-medium color-gray">Slots Details</h4>
              {tableEntitiesData &&
                tableEntitiesData.length > 0 &&
                tableEntitiesData.map((ele, index) => {
                  return (
                    <div
                      className="shadow-sm p-3 rounded font-body mb-4"
                      key={index}
                    >
                      <div className="text-end">
                        <ActionIcon
                          additionalClass="text-danger text-xl bi-x-circle-fill"
                          onClick={() => {
                            let temp = tableEntitiesData;
                            temp.splice(index, 1);
                            setTableEntitiesData([...temp]);
                          }}
                        />
                      </div>
                      <ul>
                        <li>
                          <span className="text-md font-semibold">
                            Slot time :
                          </span>{" "}
                          {`${ele.slot_time} ${ele.zone}`}
                        </li>
                      </ul>
                    </div>
                  );
                })}
            </Col>
          </Row>
        </ContentWrapper>
        {/* Slot timings : End */}

        {/* Terms and conditions */}
        <ContentWrapper additionalClass="p-5 mb-4">
          <Row className="g-4">
            <Col xs={12}>
              <h5 className="text-2xl font-body font-bold">
                TERMS AND CONDITIONS{" "}
              </h5>
            </Col>
            <Col xl={12}>
              <h4 className="text-md font-semibold color-gray mb-2">
                Terms and conditions <span className="text-danger">*</span>
              </h4>
              <Controller
                name="termsAndConditions"
                control={control}
                rules={{ required: "This is required field" }}
                render={({ field }) => {
                  return (
                    <SimpleMdeReact
                      placeholder="Enter terms & conditions"
                      {...field}
                      onChange={(value) => {
                        if (value === "") {
                          setError("termsAndConditions", {
                            custom: true,
                            message: "This is required field",
                          });
                        } else if (!/^(?!\s*$).+/.test(value)) {
                          setError("termsAndConditions", {
                            custom: true,
                            message: "Only alpha-numeric characters are allowed.",
                          });
                        } else {
                          setValue("termsAndConditions", value);
                          setError("termsAndConditions", {
                            custom: true,
                            message: null,
                          });
                        }
                      }}
                    />
                  );
                }}
              />
              <p className="text-danger font-body text-sm">
                {errors.termsAndConditions?.message}
              </p>
            </Col>
          </Row>
        </ContentWrapper>

        <Row>
          <Col xs={12} className="d-flex justify-content-end mb-6">
            <OutlinedButton
              link="/clubs"
              name="Cancel"
              additionalClass="me-3 px-5"
            />
            <SuccessButton
              type="submit"
              name={club_id ? "Update" : "Create"}
              icon="bi-check-circle"
              additionalClass={`px-5 ${showToaster ? "opacity-06 cursor-default" : null}`}
              disabled={showToaster ? true : false}
            />
          </Col>
        </Row>
      </form>
      {/* ------------ New Form : End --------- */}

      {/* Modals */}

      {/* Logo delete modal */}

      <ClubModal
        showModal={logoDeleteModal}
        setShowModal={setLogoDeleteModal}
        warningText="Are you sure?"
        bodyText="Do you want to delete this logo? "
        bodyParaClass="text-light font-weight-bold"
        successButtonClass="text-light mt-3 me-4 px-5"
        cancelButtonClass="text-light mt-3 me-4 px-5"
        onClick={logoModalOnclick}
        onClose={closeLogoModal}
      />

      {/* Cover delete modal */}

      <ClubModal
        showModal={coverImageDeleteModal}
        setShowModal={coverImageModalOnClick}
        warningText="Are you sure?"
        bodyText="Do you want to delete this cover image?"
        bodyParaClass="text-light font-weight-bold"
        successButtonClass="text-light mt-3 me-4 px-5"
        cancelButtonClass="text-light mt-3 me-4 px-5"
        onClick={coverImageModalOnClick}
        onClose={coverImageModalOnClose}
      />

      {/* Club photos delete modal */}

      <ClubModal
        showModal={photoDeleteModal}
        setShowModal={coverImageModalOnClick}
        warningText="Are you sure?"
        bodyText="Do you want to delete this photo?"
        bodyParaClass="text-light font-weight-bold"
        successButtonClass="text-light mt-3 me-4 px-5"
        cancelButtonClass="text-light mt-3 me-4 px-5"
        onClick={photosModalOnClick}
        onClose={photosModalOnClose}
      />
      <ClubModal
        icon={true}
        showModal={ladiesNightWarningModal}
        setShowModal={() => setLadiesNightWarningModal(true)}
        warningText="Warning"
        bodyText="Please note that you can select a maximum of three 'Ladies Night' checkboxes at a time."
        bodyParaClass="text-light font-weight-bold"
        successButtonClass="text-light mt-3 me-4 px-5"
        cancelButtonClass="text-light mt-3 me-4 px-5"
      />

      {/* Modals */}

      <Toaster
        showToaster={showToaster}
        setShowToaster={setShowToaster}
        bg={toasterDetails.bg}
        title={toasterDetails.title}
        bodyText={toasterDetails.bodyText}
        statusIcon={toasterDetails.statusIcon}
      />
    </Layout>
  );
}
