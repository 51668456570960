import { doc, setDoc } from "firebase/firestore";
import UpdateEvent from "../../Components/Events/UpdateEvent";
import Layout from "../../Layout/Layout";
import db from "../../firebase";
const CreateEvent = () => {

  const newClubData = async (clubData) => {
    console.log(clubData.id, 'newClubData', clubData);
    try {
      await setDoc(doc(db, "events", clubData.id), clubData);
      return true;
    }
    catch (err) {
      console.log(err);
      return false
    }
  }

  return (
    <Layout path="/events/create-event">
      <UpdateEvent newClubData={newClubData} />
    </Layout>
  );
};
export default CreateEvent;
