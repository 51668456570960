import ContentWrapper from "../../Components/Global/ContentWrapper";
import Layout from "../../Layout/Layout";
import { useForm } from "react-hook-form";
import { Row, Col, Button, Modal, ModalBody } from "react-bootstrap";
import {
  collection,
  doc,
  getDocs,
  setDoc,
  orderBy,
  where,
  query,
} from "@firebase/firestore";
import { v4 as uuid } from "uuid";
import { useEffect } from "react";
import db from "../../firebase";
import { useState } from "react";
import ActionIcon from "../../Components/Global/Buttons/ActionIcons";
import axios from "axios";
import { useNavigate } from "react-router";
// import Success from "../../Components/Global/States/Success";
// import Loading from "../../Components/Global/States/Loading";
// import Error from "../../Components/Global/States/Error";
import SuccessButton from "../../Components/Global/Buttons/SuccessButton";
import OutlinedButton from "../../Components/Global/Buttons/OutlinedButton";
import Toaster from "../../Components/Global/Toast";
// import { Link } from "react-router-dom";
import SecondaryButton from "../../Components/Global/Buttons/SecondaryButton";
const countries = [
  { value: "USA", label: "USA" },
  { value: "Canada", label: "Canada" },
  { value: "Mexico", label: "Mexico" },
];

const states = [
  { value: "CA", label: "California" },
  { value: "NY", label: "New York" },
  { value: "TX", label: "Texas" },
];
const CreateState = () => {
  const [showToaster, setShowToaster] = useState(false);
  const [toasterDetails, setToasterDetails] = useState({
    title: "",
    bodyText: "",
    bg: "",
    statusIcon: "",
  });
  const [show, setShow] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [image, setImage] = useState({});
  const [country, setCountry] = useState("");
  const [countryId, setCountryId] = useState("");
  const [state, setState] = useState("");
  const [stateId, setStateId] = useState("");
  const [previewImage, setPreviewImage] = useState(null);
  const [stateImageDeleteModal, setStateImageDeleteModal] = useState(null);
  const [imageError, setImageError] = useState("")
  // const [isLoading, setIsLoading] = useState(false);
  // const [isSuccess, setIsSuccess] = useState(false);
  // const [isError, setIsError] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //Duplicates Validation
  const checkingForDuplicates = (value) => {
    let flag = false;
    states.forEach((ele) => {
      if (
        ele.data().name === value.trim() &&
        ele.data().country_id === countryId
      ) {
        flag = true;
      }
    });
    return flag;
  };

  // onSubmit function
  const onSubmit = async (data) => {
    const duplicatesResult = checkingForDuplicates(data.stateName);
    if (duplicatesResult) {
      setShow(true);
      return;
    }
    setShowToaster(true);
    setToasterDetails((prev) => ({
      ...prev,
      title: "Creating...",
      bodyText: "Creating state",
      bg: "warning",
      statusIcon: (
        <div className="spinner-border text-warning me-2" role="status"></div>
      ),
    }));
    // setIsLoading(true);
    console.log(image, "image");
    const formData = new FormData();
    formData.append("images", image);
    const images = async () => {
      const imageResponse = await axios.post(
        `${process.env.REACT_APP_MULTIPLEIMAGES_POSTING_URL}/uploads/uploadImages`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            method: "POST",
          },
        }
      );
      console.log(imageResponse.data.url[0], "imageResponse");
      return imageResponse && imageResponse.data && imageResponse.data.url[0];
    };
    console.log(data, "data");
    const id = uuid();
    const payloadImage = await images();
    const payload = {
      id: id && id,
      name: data && data.stateName,
      state_image: payloadImage ? payloadImage : null,
      country_name: country && country,
      country_id: countryId && countryId,
      latitude: data && Number(data.latitude),
      longitude: data && Number(data.longitude),
      createdAt: new Date(),
      updatedAt: new Date(),
    };
    try {
      const createCityResponse = await setDoc(doc(db, "states", id), payload);
      // setIsLoading(false);
      // setIsSuccess(true);
      // navigate("/states");
      setToasterDetails((prev) => ({
        ...prev,
        title: "Success",
        bodyText: "Created state successfully",
        bg: "success",
        statusIcon: <i className="bi bi-check text-success text-3xl me-2"></i>,
      }));
      setTimeout(() => {
        navigate("/states");
      }, 2000);
    } catch (err) {
      // setIsLoading(false);
      // setIsError(true);
      setToasterDetails((prev) => ({
        ...prev,
        title: "Error",
        bodyText: "Something went wrong",
        bg: "danger",
        statusIcon: (
          <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
        ),
      }));
      console.error("Error adding document: ", err);
    }
  };

  // Handle country
  const handleCountry = (e) => {
    console.log(e.target.options[e.target.selectedIndex].text, "country");
    e.target.options &&
      setCountry(e.target.options[e.target.selectedIndex].text);
    setCountryId(e.target.value);
    setError("countryName", { type: "custom", message: null });
  };

  //Handle Image
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = () => {
          setPreviewImage(reader.result);
        };
        reader.readAsDataURL(file);
        console.log(file, "its coming");
        setImage(file);
        setImageError("")
      }
      else {
        setImageError("Please ensure your selection is an image file.");
        return;
      }

    }
  };

  //Get all states
  const getAllStates = async () => {
    const statesResponse = await getDocs(collection(db, "states"));
    setStates(statesResponse.docs);
  };
  const getAllCountriesAndStates = async () => {
    const countriesResponse = await getDocs(collection(db, "countries"));
    let sortedCountries = countriesResponse.docs.sort((a, b) => a.data().name.localeCompare(b.data().name))
    setCountries(sortedCountries);
  };

  useEffect(() => {
    getAllCountriesAndStates();
    getAllStates();
  }, []);

  return (
    <Layout path="/states/create-state">
      <ContentWrapper additionalClass="p-5 font-body">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row className="g-3">
            <Col xs={12}>
              <label htmlFor="cityImage">State image</label>
              <div
                style={{
                  height: "200px",
                  width: "350px",
                  backgroundImage: `url(${previewImage ? previewImage : null})`,
                  border: `${previewImage ? "0px" : "1px"} dashed #D2D6DA`,
                }}
                className="d-flex align-items-center justify-content-center background-position-center-top background-repeat-norepeat background-size-cover rounded overflow-hidden position-relative"
              >
                {previewImage ? (
                  <div className="position-absolute end-0 top-0">
                    <ActionIcon
                      onClick={() => {
                        setStateImageDeleteModal(true)
                      }}
                      additionalClass="bi bi-x-circle-fill text-3xl color-red me-2"
                    />
                  </div>
                ) : null}

                {previewImage ? null : (
                  <div>
                    <input
                      accept="image/*"
                      type="file"
                      id="display_image"
                      onChange={handleImageChange}
                      className="opacity-0 position-absolute start-0 end-0 top-0 bottom-0"
                    />

                    <div className="text-center">
                      <i className="bi bi-plus-circle-fill text-5xl color-lightgreen d-block"></i>
                      <p className="text-md font-body text-center  ">
                        Upload your Image here.
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </Col>
            {imageError && <p className="text-danger">{imageError}</p>

            }
            <Col xs={6}>
              <label htmlFor="cityName">
                State name
                <span style={{ color: "red", fontSize: "20px" }}>*</span>
              </label>
              <input
                className="po-edit-input"
                type="text"
                id="stateName"
                {...register("stateName", {
                  required: true,
                  pattern: {
                    value: /^(?!\s*$).+/,
                    message: "Only alphabetic characters are allowed.",
                  },
                })}
              />

              <p className="text-sm text-danger">
                {errors.stateName && errors.stateName.type === "required" && (
                  <span>This field is required</span>
                )}

                {errors.stateName && errors.stateName.type === "pattern" && (
                  <span>Only alphabetic characters are allowed.</span>
                )}
              </p>
            </Col>
            <Col xs={6}>
              <label htmlFor="countryName">
                Country name{" "}
                <span style={{ color: "red", fontSize: "20px" }}>*</span>
              </label>
              <select
                id="countryName"
                {...register("countryName", {
                  required: "This field is required",
                })}
                className="po-edit-input"
                onChange={handleCountry}
              >
                <option value="">Select a country</option>
                {countries &&
                  countries.map((country, index) => (
                    <option
                      key={index}
                      value={country.data() && country.data().id}
                    >
                      {country.data() && country.data().name}
                    </option>
                  ))}
              </select>
              <p className="text-sm text-danger">
                {errors.countryName?.message}
              </p>
            </Col>
            <Col xs={6}>
              <label htmlFor="latitude">
                Latitude
                <span style={{ color: "red", fontSize: "20px" }}>*</span>
              </label>
              <input
                type="text"
                id="latitude"
                className="po-edit-input"
                {...register("latitude", {
                  required: true,
                  pattern: /^\d+(\.\d+)?$/,
                })}
              />

              <p className="text-sm text-danger">
                {errors.latitude && errors.latitude.type === "required" && (
                  <span>This field is required</span>
                )}
                {errors.latitude && errors.latitude.type === "pattern" && (
                  <span>Invalid format (must be a number)</span>
                )}
              </p>
            </Col>
            <Col xs={6}>
              <label htmlFor="longitude">
                Longitude
                <span style={{ color: "red", fontSize: "20px" }}>*</span>
              </label>
              <input
                type="text"
                id="longitude"
                className="po-edit-input"
                {...register("longitude", {
                  required: true,
                  pattern: /^\d+(\.\d+)?$/,
                })}
              />

              <p className="text-sm text-danger">
                {errors.longitude && errors.longitude.type === "required" && (
                  <span>This field is required</span>
                )}
                {errors.longitude && errors.longitude.type === "pattern" && (
                  <span>Invalid format (must be a number)</span>
                )}
              </p>
            </Col>

          </Row>

          <div className="d-flex justify-content-end mt-4">
            <OutlinedButton link="/states" name="Cancel" />
            <SuccessButton
              type="submit"
              name="Create State"
              icon="bi-check-circle"
              additionalClass="ms-3"
              disabled={showToaster ? true : false}
            />
          </div>
        </form>
      </ContentWrapper>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <div className="background-appColor p-8 rounded font-body">
          <h2 className="text-2xl  color-ternary font-semibold mb-4">Error</h2>
          <p className="text-md text-light text-wrap mb-1">
            {" "}
            State is already Exists
          </p>
          <div className="text-end">
            <SecondaryButton
              onClick={handleClose}
              name="Close"
              additionalClass="text-light"
            />
          </div>
        </div>
      </Modal>
      {/* State Image Modal */}
      <Modal size="xs" show={stateImageDeleteModal} onHide={() => setStateImageDeleteModal(false)}  >

        <div className="background-appColor p-5 rounded font-body">
          <h5 className="text-2xl  color-ternary  mb-3">
            Are you sure?
          </h5>
          <ModalBody>
            <p className="text-light font-weight-bold">Do you want to delete state image?</p>
          </ModalBody>
          <div className="d-flex justify-content-end">
            <SecondaryButton
              onClick={() => setStateImageDeleteModal(false)}
              name="No"
              additionalClass="text-light mt-3 me-4 px-5"
            />
            <SecondaryButton
              onClick={() => {

                // setSelectedImage(null)
                setPreviewImage(null);
                setImage(null);
                setStateImageDeleteModal(false)
              }}
              name="Yes"
              additionalClass="text-light mt-3 me-4 px-5"
            />

          </div>
        </div>
      </Modal >

      <Toaster
        showToaster={showToaster}
        setShowToaster={setShowToaster}
        bg={toasterDetails.bg}
        title={toasterDetails.title}
        bodyText={toasterDetails.bodyText}
        statusIcon={toasterDetails.statusIcon}
      />
    </Layout>
  );
};
export default CreateState;
