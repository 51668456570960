import React, { useState, useEffect } from 'react';
import ReactApexChart from "react-apexcharts";
import ContentWrapper from "../../ContentWrapper";
import DateRange from "../../DateRange";
const StackedColumn = ({ setSelectedDateRange, active_booking, activeBookingStartDate, activeBookingEndDate }) => {
  const [state, setState] = useState({
    eventBookings: [],
    tableBookings: [],
    entryBookings: [],
    months: [],
    total: 0,
  })

  useEffect(() => {
    let eventBookings = [];
    let tableBookings = [];
    let entryBookings = [];
    let months = [];
    let total = 0;

    active_booking && active_booking.length > 0 && active_booking.forEach(data => {
      eventBookings.push(data.event_booking);
      tableBookings.push(data.table_booking);
      entryBookings.push(data.entry_booking);
      months.push(data.month_name);
      total = total + data.event_booking + data.table_booking + data.entry_booking
    })
    setState((prevProps) => ({
      ...prevProps,
      eventBookings: eventBookings,
      tableBookings: tableBookings,
      entryBookings: entryBookings,
      months: months,
      total: total
    }))
  }, [active_booking]);

  const chartData = {
    series: [
      {
        name: "Event Bookings",
        data: state.eventBookings,
      },
      {
        name: "Table Bookings",
        data: state.tableBookings,
      },
      {
        name: "Entry Bookings",
        data: state.entryBookings,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        fontFamily: 'Montserrat, sans-serif',
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "70%",
        },
      },
      legend: {
        position: "bottom",
        horizontalAlign: 'left',
        offsetY: 0,
        // labels: {
        //   colors: ['#bfbfbf'],
        //   useSeriesColors: false,
        // },
        // markers: {
        //   fillColors: ['#ffffff'],
        // },
      },
      xaxis: {
        categories: state.months,
        labels: {
          // style: {
          //   colors: ['#bfbfbf'],
          // },
        },
      },
      yaxis: {
        title: {
          // text: 'Total',
          // style: {
          //   color: '#bfbfbf',
          // },
        },
        // labels: {
        //   style: {
        //     colors: ['#bfbfbf'],
        //   },
        // },
      },
      fill: {
        opacity: 1,
      },
      colors: [
        "#003f5c",
        "#444e86",
        "#dd5182",
        "#955196",

        "#ff6e54",
        "#ffa600"],
    },
  };
  const getDateRange = (start, end) => {
    setSelectedDateRange(start, end, "active_booking");
  }
  return (
    <ContentWrapper additionalClass="p-5 rounded font-body h-100">
      <div className="d-flex justify-content-between font-body">
        <div>
          <h2 className="text-md text-xl-lg font-bold color-blue font-body mb-0">
            Active Bookings
          </h2>
          <p className="text-xs text-xl-sm">Total bookings: {state.total}</p>
        </div>
        <div>
          <DateRange
            getDateRange={getDateRange}
            startDate={activeBookingStartDate}
            endDate={activeBookingEndDate}
          />
        </div>
      </div>
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        height={450}
      />
    </ContentWrapper>
  );
};
export default StackedColumn;
