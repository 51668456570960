import React, { useEffect, useState } from "react";
import ConfirmModal from "../Popup/ConfirmModal";
import { Table } from "react-bootstrap";
import moment from "moment";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import SecondaryButton from "../Buttons/SecondaryButton";
import Ripple from "../Buttons/Ripple";

const contents = {
  title: "Delete Event",
  permission: "Are you sure you want to delete this event permanently?",
};

const EventEntryBooking = ({ tableHeading, tableData, loading }) => {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { event_id } = useParams();

  useEffect(() => {
    setData(tableData);
  }, [tableData]);
  return (
    <div className="bg-white py-6 rounded-3 mt-6 mt-xl-9">
      <h2 className="text-lg font-semibold ps-4 py-3 color-lightblue">
        Showing Entry Bookings
      </h2>
      <Table striped hover responsive>
        <thead>
          <tr>
            <th className="text-nowrap font-body text-uppercase ps-4">Id</th>
            <th className="text-nowrap font-body">
              <span className="d-flex align-items-center text-uppercase font-body gap-1">
                {tableHeading.heading1}
              </span>
            </th>
            <th className="text-nowrap text-center font-body text-uppercase">
              {tableHeading.heading2}
            </th>
            <th className="text-nowrap text-center font-body text-uppercase">
              {tableHeading.heading3}
            </th>
            <th className="text-nowrap text-center gap-1 font-body text-uppercase">
              {tableHeading.heading4}
            </th>
            <th
              className="text-nowrap"
            // onClick={() =>sortingHandler('booking_status')}
            >
              <span className="d-flex align-items-center gap-1 font-body text-uppercase">
                {tableHeading.heading5}
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          {!loading ? (
            data.length ? (
              data.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="align-middle ps-4 text-md mb-0 text-nowrap color-lightblue font-semibold">
                      {index + 1}.
                    </td>
                    <td className="align-middle d-flex align-items-center gap-3">
                      <div className="po-table__eventWrapper d-flex justify-content-center align-items-center border rounded flex-shrink-0">
                        {item.user.profile_image ? (
                          <img
                            src={item.user.profile_image}
                            alt={item.user.name}
                            className="po-table__eventImg rounded"
                          />
                        ) : (
                          <i className="bi bi-image text-5xl"></i>
                        )}
                      </div>
                      <div className="d-flex flex-column po-event__name text-nowrap">
                        <span className=" po-word-break text-sm font-body">
                          {item && item.user.name}
                        </span>
                        <span className="text-xxs po-word-break font-body">
                          booking id: {item.booking_id}{" "}
                        </span>
                      </div>
                    </td>
                    <td className="align-middle text-center font-body">
                      {item.attendee_count}
                    </td>
                    <td className="align-middle text-center font-body">
                      {item.amount}
                    </td>
                    <td className="align-middle text-center font-body">
                      {moment(item.booking_date.toDate()).format("DD/MM/YYYY")}
                    </td>
                    <td className="align-middle ">
                      <div className="d-flex gap-4 gap-xl-5 align-items-center justify-content-between">
                        {/* <Badge
                        // className={`po-table__status text-xxs font-semibold px-2 py-1 rounded-pill text-center ${item.booking_status === "Pending" ? "color-green background-lightgreen1" : "color-blue1 background-lightblue1"}`}
                        pill bg={"secondary"}
                        >
                        {item.status}
                      </Badge> */}
                        <div className="d-flex align-items-center justify-content-start">
                          {item.status === "Waiting for approval" ? (
                            <Ripple color="#FFAE1F" />
                          ) : (
                            <div
                              style={{
                                height: "10px",
                                width: "10px",
                                backgroundColor: `${item.status === "Approved"
                                  ? "#13DEB9"
                                  : item.status === "Waiting for approval"
                                    ? "#FFAE1F"
                                    : item.status === "Rejected"
                                      ? "#FA896B"
                                      : null
                                  }`,
                              }}
                              className="rounded-circle"
                            ></div>
                          )}

                          <div className="ms-2 color-lightblue font-semibold">
                            {item.status === "Waiting for approval"
                              ? "Pending"
                              : item.status}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="align-middle ">
                      <SecondaryButton
                        link={`/manager/event-details/${item.id}`}
                        name="View"
                      />
                    </td>
                  </tr>
                );
              })
            ) : null
          ) : (
            <tr>
              <td colSpan={8}>
                <Skeleton inline={true} count={8} height={70} />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {(loading || tableData.length === 0) && (
        <p className="text-center">
          <strong className="py-3 text-4xl">
            {tableData.length === 0 && !loading ? "No Bookings" : "Loading..."}
          </strong>
        </p>
      )}
      <ConfirmModal
        showModal={showModal}
        setShowModal={setShowModal}
        contents={contents}
      />
    </div>
  );
};

export default EventEntryBooking;
