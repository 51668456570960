import React from 'react';
import {Row, Col} from 'react-bootstrap';
import ProductTestimonials from '../Components/Global/swiper/ProductTestimonials';
import LoginModal from '../Components/Global/Buttons/LoginModal';
import Accordion from 'react-bootstrap/Accordion';
import { useForm } from 'react-hook-form';
import DropdownComponent from '../Components/DropdownComponent';
import {Helmet} from "react-helmet";


const BecomeAPartner = () =>{
    const { register, handleSubmit, formState: {errors} } = useForm();  
    const data = ["Delhi", "Bengaluru", "Hyderabad", "Ananthapuramu", "Thiruvananthapuram", "Chennai", "Amaravati"]
    return(
        <div className="po-business">
            <Helmet>
                <title>Register Your Pubs and Clubs with Party.One - The Ultimate Party Finder App</title>
                <meta name="description" content="Join the party with Party.One! Register your pub or club and get listed on the ultimate party finder app to get discovered by party goers around the world. Get more customers and take your business to the next level. Register now!" />
                <meta name="keywords" content="Party.One, party finder app, register your pub, register your club, nightlife, party-goers, registration page, partner with us." />
                
                <meta property='og:title' content="Register Your Pubs and Clubs with Party.One - The Ultimate Party Finder App" />
                <meta property='og:description' content="Join the party with Party.One! Register your pub or club and get listed on the ultimate party finder app to get discovered by party goers around the world. Get more customers and take your business to the next level. Register now!" />
                <meta property="og:url" content="https://party.one/club-registration" />

                <link href="https://party.one/club-registration" rel="canonical" />

                <meta data-n-head="ssr" name="twitter:title" content="Register Your Pubs and Clubs with Party.One - The Ultimate Party Finder App" />
                <meta data-n-head="ssr" name="twitter:description" content="Join the party with Party.One! Register your pub or club and get listed on the ultimate party finder app to get discovered by party goers around the world. Get more customers and take your business to the next level. Register now!" />
                <meta data-n-head="ssr" name="twitter:url" content="https://party.one/club-registration" />
            </Helmet>
            <div className="po-business__banner d-flex flex-column justify-content-center">
                <div className="po-business__banner-wrapper">
                    <h1 className="font-body text-white font-medium text-3xl text-lg-5xl mb-5">More customers, more profits - join Party.One !</h1>
                    <LoginModal buttonName="Register your club" />
                </div>
            </div>
            <div className="po-business__card-wrapper pt-10 pb-10">
                <div className="po-business__card m-auto d-flex flex-column justify-content-center align-items-center p-5 pt-10">
                    <h2 className="font-body text-lg-3xl text-2xl text-center color-app pb-4">Boost your revenue with strategic partnership !</h2>
                    <h3 className="font-body text-lg color-fadedblack text-center">Ready, set, signup! Have your required legal documents prepared.</h3>
                    {/* <Row className='d-flex justify-content-between m-2'>
                        <Col xs={12} lg={6}>
                            <ul className="font-body text-lg color-app mr-10 mb-0">
                                <li className="po-business__card-listItems p-3"><i className="bi bi-check-circle-fill color-ternary mr-3"></i>FSSAI license copy</li>
                                <li className="po-business__card-listItems p-3"><i className="bi bi-check-circle-fill color-ternary mr-3"></i>PAN card copy</li>
                                <li className="po-business__card-listItems p-3 pb-0"><i className="bi bi-check-circle-fill color-ternary mr-3"></i>Regular GSTIN (if applicable)</li>
                            </ul>
                        </Col>
                        <Col xs={12} lg={6}>
                            <ul className="font-body text-lg color-app">
                                <li className="po-business__card-listItems p-3"><i className="bi bi-check-circle-fill color-ternary mr-3"></i>Bank account details</li>
                                <li className="po-business__card-listItems p-3"><i className="bi bi-check-circle-fill color-ternary mr-3"></i>Your restaurant menu</li>
                                <li className="po-business__card-listItems p-3 pb-0"><i className="bi bi-check-circle-fill color-ternary mr-3"></i>Dish images for top 5 items</li>
                            </ul>
                        </Col>
                    </Row> */}
                </div>
            </div>
            {/* <div className="po-business__banner-wrapper pt-20 pb-10 font-body d-flex flex-column justify-content-center align-items-center">
                <h1 className="font-medium text-xl text-md-5xl text-center pb-4">Why partner with us?</h1>
                <p className="text-lg color-fadedblack text-center pb-10">With Party.One, you can gain valuable insights to improve your business, resulting in a 60% boost in revenue, 10 times more new customers, and improved brand visibility.</p>
                <div className="d-flex flex-wrap">
                    <div className="po-business__whyPartner-cards p-5 d-flex align-items-center mr-5 mb-10">
                        <img src="./assets/icons/thousand-plus-cities-in-india.svg" alt="Globe"/>
                        <div className="ml-5 d-flex flex-column justify-content-center">
                            <h1 className="text-primary font-light text-3xl mb-0">1000+ cities</h1>
                            <p className="text-lg color-fadedblack mb-0">in India</p>
                        </div>
                    </div>
                    <div className="po-business__whyPartner-cards p-5 d-flex align-items-center mr-5 mb-10">
                        <img src="./assets/icons/restaurant-listings.svg" alt="Shop"/>
                        <div className="ml-5 d-flex flex-column justify-content-center">
                            <h1 className="text-primary font-light text-3xl mb-0">3 lakh+</h1>
                            <p className="text-lg color-fadedblack mb-0">restaurant listings</p>
                        </div>
                    </div>
                    <div className="po-business__whyPartner-cards p-5 d-flex align-items-center mb-10">
                        <img src="./assets/icons/monthly-orders.svg" alt="Calendar"/>
                        <div className="ml-5 d-flex flex-column justify-content-center">
                            <h1 className="text-primary font-light text-3xl mb-0">5.0 crore+</h1>
                            <p className="text-lg color-fadedblack mb-0">monthly orders</p>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="po-business__steps pt-20">
                <div className="po-business__banner-wrapper">
                    <h4 className="font-body font-medium text-md-5xl text-center pb-10 text-white">How it works?</h4>
                    <div className="d-flex flex-wrap justify-content-center">
                        <div className="po-business__steps-cards d-flex flex-column justify-content-center mr-sm-5 mb-10 p-3">
                            <div className="d-flex justify-content-center mb-5 po-business__steps-cardImage">
                                <img src="./assets/icons/step-one.png" alt="Register Your Bar or Club on Party.One - The Ultimate Party Finder App"/>
                            </div>
                            <div className="font-body po-business__steps-cardDescription">
                                <p className="text-center font-medium text-xl color-app">Step 1</p>
                                <h4 className="text-center text-md font-medium color-app">Get Listed on the Ultimate Party Hub</h4>
                                <p className="text-center text-sm color-fadedblack mb-0">List your place on our exclusive nightlife app and attract more customers !</p>
                            </div>
                        </div>
                        <div className="po-business__steps-cards d-flex flex-column justify-content-center mr-md-5 mb-10 p-3">
                            <div className="d-flex justify-content-center mb-5 po-business__steps-cardImage">
                                <img src="./assets/icons/step-two.png" alt="Register Your Bar and Club on Party.One - The Best Party Finder App"/>
                            </div>
                            <div className="font-body po-business__steps-cardDescription">
                                <p className="text-center font-medium text-xl color-app">Step 2</p>
                                <h4 className="text-center text-md font-medium color-app">Join the Global Club Scene</h4>
                                <p className="text-center text-sm color-fadedblack mb-0">Host millions of customers from around the world and make your club the ultimate hotspot !</p>
                            </div>
                        </div>
                        <div className="po-business__steps-cards d-flex flex-column justify-content-center mb-10 p-3">
                            <div className="d-flex justify-content-center mb-5 po-business__steps-cardImage">
                                <img src="./assets/icons/step-three.png" alt="Register Your Club on Party.One - The Most Popular Party Finder App"/>
                            </div>
                            <div className="font-body po-business__steps-cardDescription">
                                <p className="text-center font-medium text-xl color-app">Step 3</p>
                                <h4 className="text-center text-md font-medium color-app">Get the party rolling</h4>
                                <p className="text-center text-sm color-fadedblack mb-0">Manage your bookings and guests online with our all-in-one hunch console.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="po-business__card-wrapper pt-20 pb-20">
                <div className="po-business__banner-wrapper font-body d-flex flex-column justify-content-center">
                    <h1 className="font-medium text-xl text-md-5xl text-center">Already have your restaurant listed?</h1>
                    <p className="text-center text-lg color-fadedblack mb-0 pt-5">Search here and claim the ownership of your restaurant</p>
                    <div className="d-flex pt-10">
                        <i className="bi bi-geo-alt po-business__location-icon po-business__location-icon--new"></i>
                        <input list="data" className="po-business__location w-25" type="search" placeholder='Delhi NCR'/> <i className="bi bi-caret-down-fill po-business__location-icon"></i>
                        <input list="data" className="po-business__location po-business__location--searchRestaurant w-75" type="search" placeholder="Search for your restaurant, eg. Greenland Cafe" /> <i className="bi bi-caret-down-fill po-business__location-icon"></i>
                    </div>
                </div>
                </div>*/}
            {/* <div className="po-business__banner-wrapper pt-20 pb-20">
                <h1 className="font-body font-medium text-xl text-md-5xl text-center pb-10">Our products</h1>
                {/* <ProductSlider/> */}
            {/* </div>  */}
            {/* <div className="po-business__card-wrapper pt-20 pb-20">
                <div className="po-business__banner-wrapper">
                    <h1 className="font-body font-medium text-xl text-md-5xl text-center pb-10">Our happy partners</h1>
                    <ProductTestimonials />
                </div>
            </div> */}
            <div className="po-business__banner-wrapper mt-20 mb-20">
                <div className="po-business__start p-10">
                    <h2 className="font-body font-medium text-md-4xl text-white mb-5">Join the Ultimate Party Squad</h2>
                    <h3 className="text-white text-xl mb-5 font-body">Become a partner club with Party.one today !</h3>
                    <LoginModal buttonName="Start Now" />
                </div>
            </div>
            {/* <div className="po-business__banner-wrapper mt-20 mb-20">
                <h1 className="font-body font-medium text-xl text-md-5xl text-center pb-10">Frequently asked questions</h1>
                <Accordion className="font-body">
                    <Accordion.Item eventKey="0" className="po-business__faq-accordion mb-5">
                        <Accordion.Header>What will Party.One charge me for creating a page on its platform?</Accordion.Header>
                        <Accordion.Body className="pt-0">
                        <hr className="color-fadedblack w-100 mt-0"/>
                        Creating a restaurant page on Party.One is free of cost. You can maintain your page by replying to reviews and do a lot more without any charges.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1" className="po-business__faq-accordion mb-5">
                        <Accordion.Header>What all documents are required for registering on online ordering?</Accordion.Header>
                        <Accordion.Body  className="pt-0">
                        <hr className="color-fadedblack w-100 mt-0"/>
                        Registration for online ordering requires:
                        <ul>
                            <li>FSSAI certificate (application no. if FSSAI is not present)</li>
                            <li>PAN Card</li>
                            <li>GST certificate (if applicable)</li>
                        </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2" className="po-business__faq-accordion mb-5">
                        <Accordion.Header>I have a large fleet of delivery boys, why should I use Party.One delivery service?</Accordion.Header>
                        <Accordion.Body className="pt-0">
                        <hr className="color-fadedblack w-100 mt-0"/>
                         You can use your and Party.One delivery fleet simultaneously to increase the network of your delivery radius. Also, our delivery fleet delivers orders in minimum possible time, a key factor leading to increased customer satisfaction.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3" className="po-business__faq-accordion mb-5">
                        <Accordion.Header>What happens if the average order value of Party.One orders is very low</Accordion.Header>
                        <Accordion.Body  className="pt-0">
                        <hr className="color-fadedblack w-100 mt-0"/>
                        Average order value from our platform is generally more than Rs 250. However, in some cases, users want to try out your place by ordering for lesser amount. But we have observed that they eventually come back with higher value orders if they like your food.
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div> */}
            {/* <div className="po-business__addRestaurant">
                <Row className="po-business__banner-wrapper d-flex align-items-center pt-20 pb-20">
                    <Col xs={6}>
                        <h1 className="font-body font-medium text-xl text-md-4xl text-lg-5xl text-white mb-5">Cannot find your favourite restaurant on Party.One?</h1>
                        <p className="text-lg color-gray2 mb-0 font-body">Submit the details and our team will get the restaurant onboard</p>
                    </Col>
                    <Col xs={6}>
                        <form onSubmit={handleSubmit((data) =>{
                            console.log(data);
                        })}>
                            <div className="bg-white rounded p-10">
                                <input className="po-edit-input w-100" type="text" placeholder='Restaurant name*' {...register('restaurantName', {required:'This field is required'})}/>
                                <p className="text-danger text-sm mb-4 font-body">{errors.restaurantName?.message}</p>
                                <div className="d-flex align-items-center">
                                    <input list="data" className="po-edit-input w-100" type="text" placeholder='Restaurant Location*' {...register('restaurantLocation', {required:'This field is required'})}/>
                                    <i className="bi bi-search po-business__location-icon po-business__location-icon--search"></i>
                                </div>
                                <datalist id="data" className="w-100">
                                    {data.map((op,index)=><option key={index} className="w-100">{op}</option>)}
                                </datalist>
                                <p className="text-danger text-sm mb-4 font-body">{errors.restaurantLocation?.message}</p>
                                <input className="po-edit-input w-100" type="text" placeholder='Restaurant contact number' {...register('restaurantContact', {required:'This field is required'})}/>
                                <p className="text-danger text-sm mb-4 font-body">{errors.restaurantContact?.message}</p>
                                <textarea className="po-edit-input w-100 font-body" type="text" placeholder='What do you like about the restaurant?' {...register('restaurantReview', {required:'This field is required'})}></textarea>
                                <p className="text-danger text-sm mb-4 font-body">{errors.restaurantReview?.message}</p>
                                <button type="submit" className="po-login__button py-3 text-lg font-body w-100">Submit</button>
                            </div>
                        </form>
                    </Col>
                </Row>
            </div> */}
        </div>
    );
}

export default BecomeAPartner