import React, { useCallback, useRef } from 'react'
import ContentWrapper from '../../Components/Global/ContentWrapper';
import PageDescription from '../../Components/Global/PageDescription';
import Layout from '../../Layout/Layout'
import { useEffect } from 'react';
import { collection, getDocs, doc, deleteDoc, orderBy, query, getCountFromServer, limit, getDoc, startAfter, startAt, endAt, endBefore, limitToLast, where, and } from "firebase/firestore";
import db from '../../firebase';
import { useState } from 'react';
import { Modal, Table } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
// import ReactPaginate from "react-paginate";
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import PrimaryButton from '../../Components/Global/Buttons/PrimaryButton';
import SecondaryButton from '../../Components/Global/Buttons/SecondaryButton';
import FilterModalKyc from '../../Components/Global/Popup/FilterModalKyc';

const Kyc = () => {
  const [show, setShow] = useState({ show: false, data: null });  
  const [loader, setLoader] = useState(true);
  const [kycList, setKycList] = useState([]);
  const [totalKyc, setTotalKyc] = useState(null);
  const [pageCount, setPageCount] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterKeys, setFilterKeys] = useState({
    selectedLocations: [],
    selectedStatus: []
  })

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const kycPerPage = useRef(20);
  const count = useRef(0);
  const changeOrder = useRef(0);
  const nextPage = useRef(1);
  const currentPageId = useRef("");
  const sortBy = useRef({
    orderByKey: "createdAt",
    order: "desc"
  })
  const filterKeysRef = useRef({
    selectedLocations: [],
    selectedStatus: []
  });
  const localIdForSortIcon = useRef("");
  const targetElementForSort = useRef("");
  const searchQueryInput = useRef("");
  const filterIcon = useRef(null);
  
  const kycPageNo = searchParams.get('kycPageNo');
  const filterComponent = React.createRef();

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setShow({ show: true, data: kycList[id] });
  };
  const handleClickOutside = useCallback((event) => {
      if (filterComponent.current && !filterComponent.current.contains(event.target) && event.target!==filterIcon.current) {
        setShowModal(false);
      }
  }, [filterComponent]);

// useEffect goes here ----------------------------------------------------------------------------------------

  useEffect(() => { // this useEffect is used when user will click other area than filter popup
      document.addEventListener('click', handleClickOutside, true);
      return () => {
          document.removeEventListener('click', handleClickOutside, true);
      };
  }, [handleClickOutside]);

  useEffect(()=> { //on the very first render of component and on page refresh
    if(Boolean(searchParams.get('sortBy'))) {
      sortBy.current = JSON.parse(searchParams.get('sortBy'));
      changeOrder.current = +searchParams.get('changeOrder');
      targetElementForSort.current = JSON.parse(searchParams.get('sortBy')).orderByKey;
      localIdForSortIcon.current=targetElementForSort.current;
    }
    if(Boolean(searchParams.get('itemsPerPage'))) kycPerPage.current = +searchParams.get('itemsPerPage'); 
    if(Boolean(searchParams.get('searchQuery')) || Boolean(searchParams.get('filterQuery'))) {
      console.log("searchQuery or filterQuery");
      if(Boolean(searchParams.get('searchQuery'))) {
        searchQueryInput.current = searchParams.get('searchQuery');
        setSearchQuery(searchQueryInput.current);
        // fetchSearchedKyc(searchQueryInput.current);
      } 
      // else handleFilterKyc(JSON.parse(searchParams.get('filterQuery')));
    }
    else {
      fetchKycCount();
      nextPage.current = +searchParams.get("n"); // for tracking which button is clicked next or prev. it will help when page reload to identify which query should be used in order to reload the page(startAfter or lastToEnd)
      count.current = Boolean(kycPageNo) ? kycPageNo - 1 : count.current;
      currentPageId.current = searchParams.get('currentPageId');
        if(!Boolean(kycPageNo)) {
          fetchData();
        }
      // else pageReloadFunc();
    }
  }, [])
  // useEffect ends here***********************************************************

  const fetchKycCount = async () => { // It is giving me total no. of clubs and no. of pages
    const coll = collection(db, "user_kyc");
    const clubsCount = await getCountFromServer(coll);
    setTotalKyc(clubsCount.data().count);
    setPageCount(Math.ceil(clubsCount.data().count/kycPerPage.current));
  }

  // fetching entries
  // const fetchData = async () => {
  //   const q = query(collection(db, 'user_kyc'), orderBy('createdAt', 'desc'));
  //   const querySnapshot = await getDocs(q);
  //   const newData = querySnapshot.docs.map((doc) => ({
  //     id: doc.id,
  //     ...doc.data(),
  //   }));
  //   setKycList(newData);
  //   setIsLoading(false);
  // };
  const fetchData = () => { // this function will execute on first render only when count value will be zero or clubPageNo will be 1 and if items per page will change
    setLoader(true);
    setFilterKeys({
      selectedLocations: [],
      selectedStatus: []
    })
    filterKeysRef.current = {
      selectedLocations: [],
      selectedStatus: []
    }
    if(Boolean(searchParams.get('filterQuery'))) {
      kycPerPage.current = 20;
      // localIdBasedSortIcon.current="";
      localIdForSortIcon.current = "";
    }
    getDocs(query(collection(db, "user_kyc"),orderBy(sortBy.current.orderByKey, sortBy.current.order), limit(kycPerPage.current)))
      .then((docs) => {
        setKycList([]);
        docs.forEach((doc) => {
          setKycList((prev) => [...prev, doc.data()]);
        });
        setSearchParams({itemsPerPage: kycPerPage.current, sortBy: JSON.stringify(sortBy.current), changeOrder: changeOrder.current});
        setLoader(false);
      })
      .catch((err) => {
        console.log(err, "err");
        setLoader(false);
      });
  }
  const hadnleDelete = async (data) => {
    try {
      await deleteDoc(doc(db, "user_kyc", data.id));
      setKycList()
      handleClose();
    } catch (err) {
      console.log(err);
    }
  };
  // useEffect(() => {
  //   fetchData();
  //   setKycList();
  // }, []);

  
  const pageReloadFunc = useCallback(async() => {
    kycPerPage.current = +searchParams.get('itemsPerPage');
    console.log(kycPerPage.current, 'kycPerPage.current');
    setLoader(true);
    if(nextPage.current===1) {
      const docSnap = count.current && await getDoc(doc(collection(db, "user_kyc"), currentPageId.current));
      getDocs(query(collection(db, "user_kyc"),orderBy(sortBy.current.orderByKey, sortBy.current.order), startAfter(docSnap), limit(kycPerPage.current)))
        .then((docs) => {
          setKycList([]);
          docs.forEach((doc) => {
            setKycList((prev) => [...prev, doc.data()]);
          });
          setLoader(false);
        })
        .catch((err) => {
          console.log(err, "err");
          setLoader(false);
        });
        
    } else {
      const docSnap = count.current && await getDoc(doc(collection(db, "user_kyc"), currentPageId.current));
      getDocs(query(collection(db, "user_kyc"),orderBy(sortBy.current.orderByKey, sortBy.current.order), endBefore(docSnap), limitToLast(+kycPerPage.current)))
      .then((docs) => {
        setKycList([]);
        docs.forEach((doc) => {
          setKycList((prev) => [...prev, doc.data()]);
        });        
        setLoader(false);
      })
      .catch((err) => {
        console.log(err, "err");
        setLoader(false);
      });

    }

  }, [nextPage])

  // const handleDelete = async (data) => {
  //   try {
  //     await deleteDoc(doc(db, "user_kyc", data.id));
      
  //     if(Boolean(searchParams.get("searchQuery"))) {
  //       localIdBasedSortIcon.current="";
  //       fetchSearchedClubs(searchParams.get("searchQuery"));
  //     }
  //     else if(Boolean(searchParams.get("filterQuery"))) {
  //       localIdBasedSortIcon.current="";
  //       handleFilterClubs(JSON.parse(searchParams.get("filterQuery")))
  //     } 
  //     else {
  //       count.current = 0;
  //       fetchData();
  //       fetchClubsCount();
  //     }
  //     handleClose();
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  // const onClickHandler = (id, type) => {
  //   if (id === undefined && type === "Add") {
  //     navigate("/add-club")
  //     return;
  //   }
  //   if (type === "update" && id != null) {
  //     navigate(`/clubs/edit-club/${id}`);
  //   }
  //   if (type === "delete" && id != null) {
  //     async function callDeleteClub() {
  //       deleteClub(id);
  //     }
  //     callDeleteClub();
  //   }
  // }

  // Items Per Page Change **************************************************

  const handlerItemsPerPage = (e) => { // I am recalling the fetchData func when items per page whenever change but not calling fetchClubsCount because it won't change if items per page will change
    setLoader(true);
    kycPerPage.current = +e.target.value;
    const currentParams = Object.fromEntries([...searchParams]);
    setSearchParams({...currentParams, itemsPerPage: kycPerPage.current});
    count.current = 0;
    if(!Boolean(searchParams.get('searchQuery')) && !Boolean(searchParams.get('filterQuery'))) {
      fetchKycCount(); // this function is called because on items per page change no of page will change
      fetchData();
    }
    else if(Boolean(searchParams.get("searchQuery"))) fetchSearchedClubs(searchParams.get("searchQuery"));
    else if(Boolean(searchParams.get("filterQuery"))) handleFilterClubs(JSON.parse(searchParams.get("filterQuery"))) 
    else console.log(searchParams.get("searchQuery"), 'endingg');
  }

  // Sorting Goes Here ***************************************************************************

  const handleSorting = (e, localIdParam) => {
    if(count.current) { // coming back to first page when you will be on other page
      count.current=0;
      changeOrder.current = 0;
    }
    if(targetElementForSort.current !== localIdParam) {
      changeOrder.current = 0;
      targetElementForSort.current = localIdParam;
    }
    if(searchParams.get('searchQuery') || searchParams.get('filterQuery')) {
      if(Boolean(searchParams.get('searchQuery'))) {
        if(!changeOrder.current) {
          localIdForSortIcon.current = localIdParam;
          changeOrder.current = changeOrder.current + 1;
          setKycList([...kycList].sort((a, b) => a[targetElementForSort.current] > b[targetElementForSort.current] ? 1 : a[targetElementForSort.current] < b[targetElementForSort.current] ? -1 : 0 ).splice(0,kycPerPage.current));
        }
        else if(changeOrder.current===1) {
          changeOrder.current = changeOrder.current + 1;
          setKycList([...kycList].sort((a, b) => a[targetElementForSort.current] < b[targetElementForSort.current] ? 1 : a[targetElementForSort.current] > b[targetElementForSort.current] ? -1 : 0 ).splice(0,kycPerPage.current));
        }
        else {
          changeOrder.current = 0;
          setLoader(true);
          fetchSearchedClubs(searchParams.get('searchQuery'));
        }
      }
      else {
        if(!changeOrder.current) {
          localIdForSortIcon.current = localIdParam;
          changeOrder.current = changeOrder.current + 1;
          setKycList([...kycList].sort((a, b) => a[targetElementForSort.current] > b[targetElementForSort.current] ? 1 : a[targetElementForSort.current] < b[targetElementForSort.current] ? -1 : 0 ).splice(0,kycPerPage.current));
        }
        else if(changeOrder.current===1) {
          changeOrder.current = changeOrder.current + 1;
          setKycList([...kycList].sort((a, b) => a[targetElementForSort.current] < b[targetElementForSort.current] ? 1 : a[targetElementForSort.current] > b[targetElementForSort.current] ? -1 : 0 ).splice(0,kycPerPage.current));
        }
        else {
          changeOrder.current = 0;
          setLoader(true);
          handleFilterClubs(JSON.parse(searchParams.get('filterQuery')));
        }
      }
    }
    else {
      if(!changeOrder.current) {
        changeOrder.current = changeOrder.current + 1;
        localIdForSortIcon.current = localIdParam;
        sortBy.current = {orderByKey: targetElementForSort.current, order: "asc"} 
        fetchData();
      }
      else if(changeOrder.current===1) {
        changeOrder.current = changeOrder.current + 1;
        sortBy.current = {orderByKey: targetElementForSort.current, order: "desc"} 
        fetchData();
      }
      else {
        changeOrder.current = 0;
        sortBy.current = {orderByKey: "createdAt", order: "desc"} 
        fetchData();
      }
      const currentParams = Object.fromEntries([...searchParams]);
      setSearchParams({...currentParams, sortBy: JSON.stringify(sortBy.current), changeOrder: changeOrder.current});
    }
    
  }

  // Sorting Finished Here -----------------------------------------------------------------------

// Searching ****************************************************************************!

const handleSearchQuery = (e) => {
  localIdForSortIcon.current="";
  setSearchQuery(e.target.value);
  if(Boolean(searchParams.get('filterQuery'))) kycPerPage.current = +searchParams.get('itemsPerPage')
  setFilterKeys({
    selectedLocations: [],
    selectedStatus: []
  })
  
  filterKeysRef.current = {
    selectedLocations: [],
    selectedStatus: []
  }
  searchQueryInput.current = e.target.value;
  if(e.target.value) {
    fetchSearchedClubs(e.target.value);
  }
  else {
    // kycPerPage.current = 20;
    setLoader(true);
    sortBy.current = {
      orderByKey: "createdAt",
      order: "desc"
    }
    changeOrder.current = 0;
    setSearchParams({itemsPerPage: kycPerPage.current, sortBy: JSON.stringify(sortBy.current), changeOrder: changeOrder.current});
    setTimeout(()=> {
      setKycList([]);
      fetchKycCount()
      fetchData();
    },1000)
  }
  }
const fetchSearchedClubs = useCallback(
  debounce((val) => {
    count.current=0;
    // setKycList([]);
    console.log(val, 'val', searchQueryInput.current);
    if(searchQueryInput.current) {
      setLoader(true);
      const searchedUserKycByName = query(collection(db, "user_kyc"),orderBy("name_lower_case"), startAt(val.replace(/\s+/g, ' ').trim().toLowerCase()),endAt(`${val.toLowerCase()}\uf8ff`));
      const searchedUserKycByNationality = query(collection(db, "user_kyc"),orderBy("nationalty_lower_case"), startAt(val.replace(/\s+/g, ' ').trim().toLowerCase()),endAt(`${val.toLowerCase()}\uf8ff`));
      // const searchedClubByLocation = query(collection(db, "user_kyc"),orderBy("city_name_lower_case"), startAt(val.toLowerCase()),endAt(`${val.toLowerCase()}\uf8ff`));
      let localSearchedArray = [];
      getDocs(searchedUserKycByName).then((docSnapshots) => {
        docSnapshots.forEach((docs) => {
          localSearchedArray = [...localSearchedArray, docs.data()]
        });
      });
      // getDocs(searchedUserKycByNationality).then((docSnapshots) => {
      //   docSnapshots.forEach((docs) => {
      //     localSearchedArray = [...localSearchedArray, docs.data()]
      //   });
      // });
      getDocs(searchedUserKycByNationality).then((docSnapshots) => {

        setSearchParams({clubPageNo: count.current + 1, itemsPerPage: kycPerPage.current, searchQuery: val });  
        docSnapshots.forEach((docs) => {
          localSearchedArray = [...localSearchedArray, docs.data()]
        });
        uniqueSearchedClubs(localSearchedArray);
        setLoader(false);
      });
    }
  }, 1000),
  []);
  const uniqueSearchedClubs = (localSearchedArray) => {
    setKycList([...new Map(localSearchedArray.map(item =>
      [item['id'], item])).values()].splice(0, kycPerPage.current));
    setTotalKyc([...new Map(localSearchedArray.map(item =>
      [item['id'], item])).values()].length)
      
  }

function debounce(fn, delay) {
  let timer;
  return function () {
    let context = this,
      args = arguments;
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
}

// Searching Finished Here ---------------------------------------------------------------------------------

  const previousPageDataFunc = async () => { // this function will be called when previous button clicked
    setLoader(true);
    count.current = count.current-1;
    if(!count.current) {
      fetchData();
      setSearchParams({});
      return;
    }
    const docSnap = count.current && await getDoc(doc(collection(db, "user_kyc"), kycList[0].id));
    getDocs(query(collection(db, "user_kyc"),orderBy(sortBy.current.orderByKey, sortBy.current.order), endBefore(docSnap), limitToLast(+kycPerPage.current)))
      .then((docs) => {
        setKycList([]);
        docs.forEach((doc) => {
          setKycList((prev) => [...prev, doc.data()]);
        });
        setSearchParams({clubPageNo: count.current+1, prevNext: JSON.stringify([docs.docs[0].id, docs.docs[docs.docs.length-1].id]), currentPageId: docSnap.id, itemsPerPage: kycPerPage.current , n: 0, sortBy: JSON.stringify(sortBy.current), changeOrder: changeOrder.current })
        
        // if count value will be greater than 0 then will set data in the url otherwise nothing needs to set in the url
        setLoader(false);
      })
      .catch((err) => {
        console.log(err, "err");
        setLoader(false);
      });
  }

  const nextPageDataFunc = async () => { // this will called when next button will called
    setLoader(true);
    count.current = count.current+1;
    const docSnap = count.current && await getDoc(doc(collection(db, "user_kyc"), kycList[kycList.length-1].id));
    getDocs(query(collection(db, "user_kyc"),orderBy(sortBy.current.orderByKey, sortBy.current.order), startAfter(docSnap), limit(kycPerPage.current)))
      .then((docs) => {
        setKycList([]);
        docs.forEach((doc) => {
          setKycList((prev) => [...prev, doc.data()]);
        });
        setSearchParams({clubPageNo: count.current+1, prevNext: JSON.stringify([docs.docs[0].id, docs.docs[docs.docs.length-1].id]), currentPageId: docSnap.id, itemsPerPage: kycPerPage.current , n: 1, sortBy: JSON.stringify(sortBy.current), changeOrder: changeOrder.current})
        /* setSearchParams => clubPageno will help to track the page no, prevNext is storing id of first and last
          item which will help to go prev and next page.
          currentPageId will help me to be on the page same page when page reload
        */
        setLoader(false);
      })
      .catch((err) => {
        console.log(err, "err");
        setLoader(false);
      });
  }

  const handleShowAll = () => {
    setLoader(true);
    let tempItemsPerPage = kycPerPage.current;
    kycPerPage.current = +totalKyc;
    if(Boolean(searchParams.get('searchQuery'))) fetchSearchedClubs(searchQuery)
    else handleFilterClubs(JSON.parse(searchParams.get('filterQuery')))
    const currentParams = Object.fromEntries([...searchParams]);
      setSearchParams({...currentParams, itemsPerPage: tempItemsPerPage});
  }

  // Filter **************************************************************************************************!

  const handleFilterClubs = async (filterState) => {
    localIdForSortIcon.current="";
    setLoader(true);
    let arr=[];
    setFilterKeys(filterState);
    filterKeysRef.current = filterState;
    // reseting search input field in order to remove search and apply filter  
    searchQueryInput.current="";
    setSearchQuery(searchQueryInput.current);
    // ----------------------------------------
    let filteredLocations = filterKeysRef.current.selectedLocations.length;
    let filteredStatus = filterKeysRef.current.selectedStatus.length;
    if(filteredLocations && filteredStatus) {
      arr = [where("nationalty_lower_case", "in", filterKeysRef.current.selectedLocations), where("status", "in", filterKeysRef.current.selectedStatus)]
    } else if(filteredLocations) {
      arr = [where("nationalty_lower_case", "in", filterKeysRef.current.selectedLocations)]
    } else if(filteredStatus) {
      arr = [where("status", "in", filterKeysRef.current.selectedStatus)]
    } else console.log("not applicable");

    let events_data = (filteredLocations || filteredStatus) && await getDocs(query(collection(db, "user_kyc"), and(...arr), orderBy(sortBy.current.orderByKey, sortBy.current.order)))
    if(events_data) {
      let localFilteredClubs = []
      events_data.size && events_data.forEach(event => {
        localFilteredClubs = [...localFilteredClubs, event.data()];
        // setKycList(prev => [...prev, event.data()])
      })
      setKycList([...localFilteredClubs].splice(0, kycPerPage.current));
      setTotalKyc(events_data.size);
      setLoader(false);
    }
  }

  const handleModifyFilter = (e,item,filterType) => {
    console.log(kycPerPage.current, 'current', item);
    localIdForSortIcon.current=""
    if(filterType==="location") {
      setFilterKeys({
        ...filterKeys,
        selectedLocations: filterKeys.selectedLocations.filter(location => location!==item)
      })

      filterKeysRef.current= {
        ...filterKeysRef.current,
        selectedLocations: filterKeysRef.current.selectedLocations.filter(location => location!==item)
      }
    }
    if(filterType==="status") {
      setFilterKeys({
        ...filterKeys,
        selectedStatus: filterKeys.selectedStatus.filter(status => status!==item)
      })
      filterKeysRef.current = {
        ...filterKeysRef.current,
        selectedStatus: filterKeysRef.current.selectedStatus.filter(status => status!==item)  
      }
    }
    const currentParams = Object.fromEntries([...searchParams]);
    setSearchParams({...currentParams, filterQuery: JSON.stringify(filterKeysRef.current)});
    if(filterKeysRef.current.selectedLocations.length || filterKeysRef.current.selectedStatus.length) {
      handleFilterClubs(filterKeysRef.current);
    } else {
      sortBy.current = {
        orderByKey: "createdAt",
        order: "desc"
      }
      changeOrder.current = 0;
      kycPerPage.current=20;
      setSearchParams({itemsPerPage: kycPerPage.current, sortBy: JSON.stringify(sortBy.current), changeOrder: changeOrder.current});  
      fetchData()
      fetchKycCount();
    }
  }


  const KycEntries = ({ kycList }) => {
    return (
      <div className="table-responsive po-table tableFixHead">
        <Table hover>
          <thead>
            <tr className="background-gray10">
              <th className="text-nowrap"><p className="text-nowrap text-xs text-xl-sm text-uppercase ps-3 color-black2 mb-0 py-2">s. no</p></th>
              <th className="text-nowrap"><p className="text-nowrap text-xs text-xl-sm text-uppercase  color-black2 mb-0 py-2">Name</p></th>
              <th className="text-nowrap"><p className="text-nowrap text-xs text-xl-sm text-uppercase  color-black2 mb-0 py-2">Nationality</p></th>
              <th className="text-nowrap"><p className="text-nowrap text-xs text-xl-sm text-uppercase  color-black2 mb-0 py-2">Requested On</p></th>
              <th className="text-nowrap"><p className="text-nowrap text-xs text-xl-sm text-uppercase  color-black2 mb-0 py-2">Status</p></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {!loader ?
              (
                <>
                  {kycList.length ? kycList.map((kyc, index) => {
                    return (
                      <tr key={index}>
                        <td className="align-middle"><p className='ps-3 text-sm mb-0 text-nowrap color-lightblue'>{index + 1}.</p></td>
                        <td className="align-middle"><p className='text-sm mb-0 text-nowrap color-lightblue'>{kyc.name}</p></td>
                        <td className="align-middle"><p className='text-sm mb-0 text-nowrap color-lightblue'>{kyc.nationalty}</p></td>
                        <td className="align-middle"><p className='text-sm mb-0 text-nowrap color-lightblue'>{kyc.createdAt.seconds && moment(new Date(kyc.createdAt.seconds * 1000).toISOString()).format("DD MMM YYYY")}</p></td>
                        <td className="align-middle"><p className='text-sm mb-0 text-nowrap color-lightblue'>{kyc.status}</p></td>
                        <td className="align-middle">

                          <div className='d-flex justify-content-center align-items-center'>
                            <SecondaryButton name="View" onClick={(e) => navigate(`/kyc-view/${kyc.id}`)}/>
                          </div>

                        </td>
                      </tr>
                    );
                  }) : <tr><td colSpan={8} className="text-center text-2xl font-bold">No Data Found</td></tr>
                  }
                </>
              ) : (
                <tr>
                  <td colSpan={5}>
                    <Skeleton inline={true} count={5} height={80} style={{ marginBottom: "1rem" }} baseColor='#dedede' highlightColor='#cccccc' duration={2} />
                  </td>
                </tr>
              )
            }
          </tbody>
        </Table>
      </div>
    )
  }
  console.log(kycList, 'kycList');
  return (
    <Layout path="/kyc" additionalclass="no-scrollbar d-flex flex-column justify-content-between">
      <div className="d-flex justify-content-between align-items-center gap-3 gap-lg-5 ">
        <PageDescription title="All kyc" caption="" />
        {/* <PrimaryButton name="Add City" link="/city/create-city" icon="bi-plus-circle" /> */}
      </div>
      <div className='row d-flex justify-content-between g-3'>
        <div className='col-5 col-xxl-4'>
          <input
            id="search"
            type="search"
            className="po-edit-input mb-1"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchQuery}
          />
        </div>
        <div className='col-6 col-xxl-4'>
          <div className='text-end position-relative'>
            <button className='border-0 bg-transparent' type='button' onClick={()=>{setShowModal(prev=>!prev)}}><i className={`bi bi-funnel-fill text-xl ${showModal ? "color-yellow1" : ""}`} ref={filterIcon}></i></button>
            <FilterModalKyc
              ref={filterComponent} 
              showModal={showModal} 
              setShowModal={setShowModal} 
              collectionRecall={fetchData}
              handleFilterClubs={handleFilterClubs}
              // fetchClubsCount={fetchClubsCount}
              // clubsPerPage={clubsPerPage.current}
            />
          </div>
        </div>
      </div>
      <div className={`mt-3 ${!filterKeys.selectedLocations.length && !filterKeys.selectedStatus.length ? "d-none" : ""}`}>
        {filterKeys && filterKeys.selectedLocations && filterKeys.selectedLocations.length ? <div className='d-flex align-items-start my-3 gap-3 text-sm font-medium'>
          <span className='text-nowrap'>Selected Location</span> : 
          <div className='d-flex align-items-start flex-wrap gap-3'>
            {filterKeys && filterKeys.selectedLocations && filterKeys.selectedLocations.map((item,index)=> <div 
                className='text-xxs font-medium pl-3 pe-0 py-1 border-1 background-gradient rounded-pill text-capitalize d-flex align-items-center justify-content-between' 
                key={index}
              >
                {item}
                <button className='bg-transparent border-0 d-flex align-items-center' onClick={(e)=>handleModifyFilter(e,item,'location')}>
                  <i className="bi bi-x-circle-fill text-md lineheight-0"></i>
                </button>
              </div>
            )}
          </div>
        </div>:""}
        {filterKeys && filterKeys.selectedStatus && filterKeys.selectedStatus.length ? <div className='d-flex align-items-start my-3 gap-3 text-sm font-medium'>
          <span className='text-nowrap'>Selected Status</span> : 
          <div className='d-flex align-items-start flex-wrap gap-3'>
            {filterKeys && filterKeys.selectedStatus && filterKeys.selectedStatus.map((item,index)=> <div 
                className='text-xxs font-medium pl-3 pe-0 py-1 border-1 background-gradient rounded-pill text-capitalize d-flex align-items-center justify-content-between' 
                key={index}
              >
                {item}
                <button className='bg-transparent border-0 d-flex align-items-center' onClick={(e)=>handleModifyFilter(e,item,'status')}>
                  <i className="bi bi-x-circle-fill text-md lineheight-0"></i>
                </button>
              </div>
            )}
          </div>
        </div>:""}
      </div>
      <ContentWrapper additionalClass="pb-5 font-body ">
      <KycEntries kycList={kycList} />
      {kycList.length ? <div className="d-flex gap-2 justify-content-end align-items-center py-5 mt-6 me-4" style={{height: 66}}>
          {(Boolean(searchParams.get('searchQuery')) || Boolean(searchParams.get('filterQuery'))) && !loader ? <div className={kycList.length !== totalKyc ?  "mx-12" : ""} >No. of clubs displaying: <span className="font-medium ms-3">{totalKyc}</span></div>: ""}
          {(kycList.length !== totalKyc) ? <div>
            Rows per page: 
            <div className="d-inline-block ms-1 ms-xl-3">
              <select name="item-per-page" id="item-per-page" value={kycPerPage.current} className="border-0 shadow-none font-medium" onChange={handlerItemsPerPage}>
                <option value={10}>10</option>
                <option value={15}>15</option>
                <option value={20}>20</option>
                <option value={25}>25</option>
                <option value={30}>30</option>
              </select>
            </div>
          </div> : ""}
          {(Boolean(searchParams.get('searchQuery')) || Boolean(searchParams.get('filterQuery'))) && !loader && totalKyc > kycList.length ? 
            <button 
              className="bg-transparent border-1 rounded-3 ml-12" 
              type="button"
              onClick={handleShowAll}
            >Show All</button>: ""}
            {!Boolean(searchParams.get('searchQuery')) && !Boolean(searchParams.get('filterQuery')) ? <div className="d-flex align-items-center gap-3">
            {!loader && kycList.length ? <div className="mx-6 mx-xl-12 font-medium">
              {count.current*kycPerPage.current+1}-{(count.current+1)*kycPerPage.current < totalKyc ? (count.current+1)*kycPerPage.current: totalKyc} of {totalKyc}
            </div>: <div className="mx-6 mx-xl-12 font-medium">loading...</div>}
            <button className={` text-md font-body font-semibold py-3 px-6 radius-105 d-inline-block bg-transparent ${(!count.current || loader) ? "po-border border-secondary" : "color-black1 po-border po-border-yellow1"}`} type="button" 
              onClick={previousPageDataFunc} 
              disabled={!count.current || loader}
            >Prev</button>
            <button className={` text-md font-body font-semibold py-3 px-6 radius-105 d-inline-block bg-transparent ${(pageCount - 1 === count.current) || loader ? "po-border border-secondary" : "color-black1 po-border po-border-yellow1"}`} type="button" 
              onClick={nextPageDataFunc} 
              disabled={(pageCount - 1 === count.current) || loader}>Next</button>
          </div>: <div className="px-2"></div>}
        </div>: ""}
      </ContentWrapper>
      <Modal show={show.show} onHide={handleClose}>
        <div className="background-appColor p-8 rounded font-body">
          <h5 className="text-2xl  color-ternary font-semibold ">
            Are you sure?
          </h5>
          <p className="background-appColor text-light border-bottom-0 py-1 ">
            You want to delete this City
          </p>
          <div className="d-flex justify-content-end mt-12">
            <SecondaryButton name="Cancel" onClick={handleClose} additionalClass="px-6 text-white me-4" />
            <PrimaryButton name="Delete" onClick={() => hadnleDelete(show.data)} />
          </div>
        </div>
      </Modal>
    </Layout>
  )
}

export default Kyc;