import { useNavigate, useParams } from "react-router-dom";
import ContentWrapper from "../../Components/Global/ContentWrapper";
import Layout from "../../Layout/Layout";
import { Row, Col, Button, Table, Modal, ModalBody } from "react-bootstrap";
import { useState } from "react";
import { doc, getDoc, serverTimestamp, updateDoc } from "firebase/firestore";
import { useEffect } from "react";
import db from "../../firebase";
import ActionIcon from "../../Components/Global/Buttons/ActionIcons";
import { useForm } from "react-hook-form";
import OutlinedButton from "../../Components/Global/Buttons/OutlinedButton";
import SuccessButton from "../../Components/Global/Buttons/SuccessButton";
import axios from "axios";
import Toaster from "../../Components/Global/Toast";
import SecondaryButton from "../../Components/Global/Buttons/SecondaryButton";

const EditPlans = () => {
  const [photoDeleteModal, setPhotoDeleteModal] = useState("")
  const [editBenifit, setEditBenifit] = useState({
    title: "",
    description: "",
  });
  const [flatDeleteModal, setFlatDeleteModal] = useState(null)
  const [taglines, setTaglines] = useState([]);
  const [tagline, setTagline] = useState("");
  const [previewImage, setPreviewImage] = useState(null);
  const [flatPreviewImage, setFlatPreviewImage] = useState("");
  const [flatImage, setFlatImage] = useState({
    fromBackend: true,
    ImageUrl: "",
  });
  const [gurantedEntry, setGurantedEntry] = useState(false);
  const [taglineErrors, setTaglineErrors] = useState({
    error: "",
  });
  const [benifitErrors, setBenifitErrors] = useState({
    title: "",
    description: "",
  });
  const [benifits, setBenifits] = useState([]);
  const [planDetails, setPlanDetails] = useState(null);
  const [planImage, setPlanImage] = useState({
    fromBackend: true,
    ImageUrl: "",
  });
  const [benifit, setBenifit] = useState({
    title: "",
    description: "",
  });
  const [showToaster, setShowToaster] = useState(false);
  const [toasterDetails, setToasterDetails] = useState({
    title: "",
    bodyText: "",
    bg: "",
    statusIcon: "",
  });
  const [editState, setEditState] = useState(false);
  const navigate = useNavigate();
  const { plan_id } = useParams();
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
  } = useForm();

  // Uploading Image
  const uploadImage = async (image) => {
    let form = new FormData();
    form.append("images", image);
    let responseImage = await axios
      .post(
        `${process.env.REACT_APP_MULTIPLEIMAGES_POSTING_URL}/uploads/uploadImages`,
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((resImage) => {
        return resImage && resImage.data.url[0];
      })
      .catch((error) => console.log(error, "err"));
    return responseImage;
  };

  //Handle Flat image
  const handleFlatImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setFlatPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
      setFlatImage({
        ...flatImage,
        ImageUrl: file,
        fromBackend: false,
      });
    }
    setError("flat_image", null);
  };

  const validation = () => {
    let flag = false;
    if (taglines.length <= 0 && benifits.length <= 0) {
      setTaglineErrors({
        ...errors,
        error: "Please add atleast one tagline",
      });
      setBenifitErrors({
        ...benifitErrors,
        error: "Please add atleast one benifit",
      });
      flag = true;
    } else {
      if (taglines.length <= 0) {
        setTaglineErrors({
          ...errors,
          error: "Please add atleast one tagline",
        });
        flag = true;
      } else if (benifits.length <= 0) {
        setBenifitErrors({
          ...benifitErrors,
          error: "Please add atleast one benifit",
        });
        flag = true;
      }
    }
    return flag;
  };

  // Form Submit
  const onSubmit = async (data) => {
    console.log(data, "data");
    let validationResult = validation();
    if (validationResult) {
      return;
    }
    setShowToaster(true);
    setToasterDetails((prev) => ({
      ...prev,
      title: "Updating...",
      bodyText: "Updating Plan",
      bg: "warning",
      statusIcon: (
        <div className="spinner-border text-warning me-2" role="status"></div>
      ),
    }));

    let plans = plan_id === "91e5aae1-6edd-4394-a648-5908084db3e7" ? [
      {
        price: data.price ? Number(data.price) : 0,
        duration: data.duration_month ? Number(data.duration_month) : 0,
        plan_unique_id: `${data.name.toLowerCase()}-one-year`,
      },
      {
        price: data.price ? Number(data.secondprice) : 0,
        duration: data.duration_month ? Number(data.second_duration_month) : 0,
        plan_unique_id: `${data.name.toLowerCase()}-three-months`,
      }
    ] : [
      {
        price: data.price ? Number(data.price) : 0,
        duration: data.duration_month ? Number(data.duration_month) : 0,
        plan_unique_id: `${data.name.toLowerCase()}-one-year`,
      }
    ]
    const payload = {
      benifits: benifits,
      name: data.name,
      display_image:
        typeof planImage.ImageUrl === "object"
          ? await uploadImage(planImage.ImageUrl)
          : planImage.ImageUrl,
      flat_image:
        typeof flatImage.ImageUrl === "object"
          ? await uploadImage(flatImage.ImageUrl)
          : flatImage.ImageUrl,
      membership_calculations: {
        cancelation_available: Number(data.cancelation_hour),
        guaranteed_entry: gurantedEntry,
        invitation_to_premium_events: Number(data.invitation_to_premium),
        table_booking_up_to_hours: Number(data.table_booking_hours),
      },
      level: Number(data.level),
      plans: plans,
      tagline: {
        tagline_title: data.tagline_title,
        taglines: taglines,
      },
      updatedAt: serverTimestamp(),
    };
    await updateDoc(doc(db, "memberships", plan_id), payload)
      .then(() => {
        setShowToaster(true);
        setToasterDetails((prev) => ({
          ...prev,
          title: "Success",
          bodyText: "Plan updated successfully",
          bg: "success",
          statusIcon: (
            <i className="bi bi-check text-success text-3xl me-2"></i>
          ),
        }));
        setTimeout(() => {
          navigate("/plans");
        }, 2000);
      })
      .catch((err) => {
        alert(err);
      });
  };

  //Function to get vip data
  const getVipPlan = async () => {
    await getDoc(doc(db, "memberships", plan_id))
      .then((res) => {
        const {
          name,
          membership_calculations,
          plans,
          display_image,
          benifits,
          flat_image,
          tagline,
          level,
        } = res.data();
        setPlanDetails(res.data());
        name && setValue("name", name);
        membership_calculations.cancelation_available &&
          setValue(
            "cancelation_hour",
            membership_calculations.cancelation_available
          );
        setPreviewImage(display_image);
        setValue("guranted_entry", membership_calculations.guaranteed_entry);
        setValue(
          "invitation_to_premium",
          membership_calculations.invitation_to_premium_events
        );
        setValue(
          "table_booking_hours",
          membership_calculations.table_booking_up_to_hours
        );
        setValue("level", level);
        res.data() && res.data().plans
          ? plans && plans[0] && setValue("price", plans[0].price)
          : setValue("price", 0);
        res.data().plans
          ? setValue("duration_month", plans[0].duration)
          : setValue("duration_month", 0);
        setValue("tagline_title", tagline.tagline_title);
        tagline && setTaglines(tagline.taglines);
        setPlanImage({
          ...planImage,
          ImageUrl: display_image,
          fromBackend: true,
        });


        // For Amethyst only
        res.data() && res.data().plans
          ? plans && plans[1] && setValue("secondprice", plans[1].price)
          : setValue("price", 0);
        res.data().plans
          ? plans && plans[1] && setValue("second_duration_month", plans[1].duration)
          : setValue("second_duration_month", 0);

        setFlatPreviewImage(flat_image);
        setFlatImage({
          ...flatImage,
          ImageUrl: flat_image,
          fromBackend: true,
        });
        benifits &&
          Array.isArray(benifits) &&
          benifits.length > 0 &&
          setBenifits(benifits);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getVipPlan();
  }, []);

  ///Handle Image
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
      setPlanImage({
        ...planImage,
        ImageUrl: file,
        fromBackend: false,
      });
    }
    setError("display_image", null);
  };

  // Handling Benifit title input
  const handleBenifitTitle = (e) => {
    const { value } = e.target;
    if (value === "") {
      setBenifitErrors({
        ...benifitErrors,
        title: "Field should not be empty",
      });
    } else if (value.trim().length === 0) {
      setBenifitErrors({
        ...benifitErrors,
        title: "White Space should not allowed",
      });
    } else {
      setBenifitErrors((prevErrors) => ({ ...prevErrors, title: "" }));
    }
    setBenifit({ ...benifit, title: e.target.value });
  };

  // Handling Benifit description input
  const handleBenifitDescription = (e) => {
    const { value } = e.target;
    if (value === "") {
      setBenifitErrors({
        ...benifitErrors,
        description: "Field should not be empty",
      });
    } else if (value.trim().length === 0) {
      setBenifitErrors({
        ...benifitErrors,
        description: "White Space should not allowed",
      });
    } else {
      setBenifitErrors((prevErrors) => ({ ...prevErrors, description: "" }));
    }
    setBenifit({ ...benifit, description: e.target.value });
  };
  // Tagline Handler
  const handleTagline = (e) => {
    let { value } = e.target;
    let tempTagline = taglines;
    const regex = /^\s*$/;
    if (e.target.value.match(regex) || e.target.value === "") {
      if (e.target.value.match(regex)) {
        setTaglineErrors({
          ...taglineErrors,
          error: "Please enter valid input",
        });
      }
      if (e.target.value === "") {
        setTaglineErrors({
          ...taglineErrors,
          error: "Field should not be empty",
        });
        setTagline("");
      }
      return;
    }
    setTaglineErrors({ ...taglineErrors, error: "" });
    tempTagline[0] = value;
    setTaglines(tempTagline);
  };

  // Handle Save Button
  const handleSaveButton = (index) => {
    let { title, description } = editBenifit;
    let prevTitleAndDescription = benifits[index];
    let prevBenifits = benifits.map((ele) => ({
      title: ele.title,
      description: ele.description,
    }));

    prevBenifits[index] = {
      title: title ? title : prevTitleAndDescription.title,
      description: description
        ? description
        : prevTitleAndDescription.description,
    };
    setBenifits([...prevBenifits]);
    setEditState(false);
  };

  return (
    <Layout path="/plans/edit-plans">
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Plan Image upload*/}
        <ContentWrapper additionalClass="font-body p-5">
          <Row className="g-4">
            <h4 className="text-lg font-bold mb-">Edit Plans</h4>
            <Col lg={6}>
              <label htmlFor="planImage">
                Plan Image
                <span style={{ color: "red", fontSize: "20px" }}>*</span>
              </label>
              <div
                style={{
                  height: "200px",
                  width: "350px",
                  backgroundImage: `url(${previewImage ? previewImage : null})`,
                  border: `${previewImage ? "0px" : "1px"} dashed #D2D6DA`,
                }}
                className={
                  `${previewImage ? null : null
                  } d-flex align-items-center justify-content-center background-position-center-top background-repeat-norepeat background-size-contain rounded overflow-hidden position-relative`}
              >
                {previewImage ? (
                  <div className="position-absolute end-0 top-0">
                    <ActionIcon
                      onClick={() => {
                        setPhotoDeleteModal(true)
                        // setPreviewImage(null);
                        // setPlanImage({ ...planImage, ImageUrl: "" });
                      }}
                      additionalClass="bi bi-x-circle-fill text-3xl color-red me-2"
                    />
                  </div>
                ) : null}

                {previewImage ? null : (
                  <div>
                    <input
                      accept="image/*"
                      type="file"
                      id="display_image"
                      {...register("display_image", {
                        required: "Image is required",
                      })}
                      onChange={handleImageChange}
                      className="opacity-0 position-absolute start-0 end-0 top-0 bottom-0"
                    />

                    <div className="text-center">
                      <i className="bi bi-plus-circle-fill text-5xl color-lightgreen d-block"></i>
                      <p className="text-md font-body text-center  ">
                        Upload your Image here.
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <p className="text-danger font-body text-sm">
                {errors.display_image?.message}
              </p>
            </Col>

            {/* Flat Image Upload */}
            <Col lg={6}>
              <label htmlFor="planImage">
                Flat Image
                <span style={{ color: "red", fontSize: "20px" }}>*</span>
              </label>
              <div
                style={{
                  height: "200px",
                  width: "350px",
                  backgroundImage: `url(${flatPreviewImage ? flatPreviewImage : null
                    })`,
                  border: `${flatPreviewImage ? "0px" : "1px"} dashed #D2D6DA`,
                }}
                className="d-flex align-items-center justify-content-center background-position-center-top background-repeat-norepeat background-size-cover rounded overflow-hidden position-relative"
              >
                {flatPreviewImage ? (
                  <div className="position-absolute end-0 top-0">
                    <ActionIcon
                      onClick={() => {
                        // setFlatPreviewImage(null);
                        // setFlatImage({ ...flatImage, ImageUrl: "" });
                        setFlatDeleteModal(true)
                      }}
                      additionalClass="bi bi-x-circle-fill text-3xl color-red me-2"
                    />
                  </div>
                ) : null}

                {flatPreviewImage ? null : (
                  <div>
                    <input
                      accept="image/*"
                      type="file"
                      id="flat_image"
                      {...register("flat_image", {
                        required: "Image is required",
                      })}
                      onChange={handleFlatImageChange}
                      className="opacity-0 position-absolute start-0 end-0 top-0 bottom-0"
                    />

                    <div className="text-center">
                      <i className="bi bi-plus-circle-fill text-5xl color-lightgreen d-block"></i>
                      <p className="text-md font-body text-center  ">
                        Upload your Image here.
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <p className="text-danger font-body text-sm">
                {errors.flat_image?.message}
              </p>
            </Col>

            {/* Plan name */}
            <Col lg={6}>
              <label htmlFor="latitude">
                Name<span style={{ color: "red", fontSize: "20px" }}>*</span>
              </label>
              <input
                placeholder="Enter name..."
                type="text"
                id="latitude"
                className="po-edit-input"
                {...register("name", {
                  required: "This is required field",
                  pattern: {
                    value: /^(?!\s*$).+/,
                    message: "Only alphabetic characters are allowed.",
                  },
                })}
              />
              <p className="text-sm text-danger">
                {errors.name && <span>{errors.name.message}</span>}
              </p>
            </Col>

            {/* Level */}
            <Col lg={6}>
              <label>
                Level<span style={{ color: "red", fontSize: "20px" }}>*</span>
              </label>
              <div>
                <input
                  type="number"
                  className="po-edit-input"
                  {...register("level", {
                    required: "Level is required",
                    pattern: {
                      value: /^(?!\s*$).+/,
                      message: "Only alphabetic characters are allowed.",
                    },
                  })}
                />
              </div>
              <p className="text-sm text-danger">
                {errors.level && <span>{errors.level.message}</span>}
              </p>
            </Col>
          </Row>
        </ContentWrapper>

        <ContentWrapper additionalClass="font-body p-5 mt-5">
          <Row>
            {/*  Membership Benifits */}
            <Col lg={12} className="mb-0">
              <h4 className="text-md font-bold">Membership Benefits</h4>
            </Col>

            {/* Cancellation hours */}
            <Col lg={6} className="mt-2 ">
              <div>
                <label>
                  Cancellation hours
                  <span style={{ color: "red", fontSize: "20px" }}>*</span>
                </label>
                <input
                  type="text"
                  className="po-edit-input"
                  placeholder="Please enter cancelation hours"
                  {...register("cancelation_hour", {
                    required: "Cancellation hours is required",
                    pattern: {
                      value: /^(?!\s*$).+/,
                      message: "Only alphabetic characters are allowed.",
                    },
                  })}
                />
                {errors.cancelation_hour && (
                  <span className="text-danger">
                    {errors.cancelation_hour.message}
                  </span>
                )}
              </div>
            </Col>

            {/* Guranted entry */}
            <Col lg={6}>
              <label>Guranted Entry</label>
              <input
                value={gurantedEntry}
                type="checkbox"
                className="m-4"
                onChange={() => setGurantedEntry(!gurantedEntry)}
              />
            </Col>
            <Col lg={6}>
              <div>
                <label>
                  Invitation to premium events
                  <span style={{ color: "red", fontSize: "20px" }}>*</span>
                </label>
                <input
                  type="text"
                  className="po-edit-input"
                  placeholder="Please enter premium event value"
                  {...register("invitation_to_premium", {
                    required: "Please enter a premium event value",
                    pattern: {
                      value: /^(?!\s*$).+/,
                      message: "Only alphabetic characters are allowed.",
                    },
                  })}
                />
                {errors.invitation_to_premium && (
                  <span className="text-danger">
                    {errors.invitation_to_premium.message}
                  </span>
                )}
              </div>
            </Col>

            {/* Booking Hours */}
            <Col lg={6}>
              <div>
                <label>
                  Table booking hours
                  <span style={{ color: "red", fontSize: "20px" }}>*</span>
                </label>
                <input
                  type="text"
                  className="po-edit-input"
                  placeholder="Please enter hours"
                  {...register("table_booking_hours", {
                    required: "Please enter a premium event value",
                    pattern: {
                      value: /^(?!\s*$).+/,
                      message: "Only alphabetic characters are allowed.",
                    },
                  })}
                />
                {errors.table_booking_hours && (
                  <span className="text-danger">
                    {errors.table_booking_hours.message}
                  </span>
                )}
              </div>
            </Col>
            {/* end */}
          </Row>
        </ContentWrapper>

        <ContentWrapper additionalClass="font-body p-5 mt-5">
          <Row>
            {/* Plans */}
            <Col lg={12}>
              <div>
                <h4 className="text-md font-bold mt-4">Plans</h4>
                <div>
                  <div>
                    <label>
                      Plan price
                      <span style={{ color: "red", fontSize: "20px" }}>*</span>
                    </label>
                    <input
                      type="Number"
                      className="po-edit-input"
                      placeholder="Eg : 455"
                      {...register("price", {
                        required: "Please enter a price",
                        pattern: {
                          value: /^(?!\s*$).+/,
                          message: "Only alphabetic characters are allowed.",
                        },
                        min: {
                          value: 0,
                          message: "Price must be greater than or equal to 0",
                        },
                      })}
                    />
                    {errors.price && (
                      <span className="text-danger">{errors.price.message}</span>
                    )}
                  </div>
                  <div>
                    <label>
                      Plan duration in months
                      <span style={{ color: "red", fontSize: "20px" }}>*</span>
                    </label>
                    <input
                      type="Number"
                      className="po-edit-input"
                      placeholder="Eg : 455"
                      {...register("duration_month", {
                        required: "Please enter a price",
                        pattern: {
                          value: /^(?!\s*$).+/,
                          message: "Only alphabetic characters are allowed.",
                        },
                        pattern: {
                          value: /^-?\d+$/,
                          message: "Only whole numbers are allowed.",
                        },
                        min: {
                          value: 1,
                          message: "Price must be greater than or equal to 1",
                        },
                      })}
                    />
                    {errors.duration && (
                      <span className="text-danger">
                        {errors.duration.message}
                      </span>
                    )}
                  </div>
                </div>


                {plan_id === "91e5aae1-6edd-4394-a648-5908084db3e7" &&
                  <div>
                    <div>
                      <label>
                        Plan price
                        <span style={{ color: "red", fontSize: "20px" }}>*</span>
                      </label>
                      <input
                        type="Number"
                        className="po-edit-input"
                        placeholder="Eg : 455"
                        {...register("secondprice", {
                          required: "Please enter a price",
                          pattern: {
                            value: /^(?!\s*$).+/,
                            message: "Only alphabetic characters are allowed.",
                          },
                          min: {
                            value: 0,
                            message: "Price must be greater than or equal to 0",
                          },
                        })}
                      />
                      {errors.price && (
                        <span className="text-danger">{errors.price.message}</span>
                      )}
                    </div>
                    <div>
                      <label>
                        Plan duration in months
                        <span style={{ color: "red", fontSize: "20px" }}>*</span>
                      </label>
                      <input
                        type="Number"
                        className="po-edit-input"
                        placeholder="Eg : 455"
                        {...register("second_duration_month", {
                          required: "Please enter a price",
                          pattern: {
                            value: /^(?!\s*$).+/,
                            message: "Only alphabetic characters are allowed.",
                          },
                          pattern: {
                            value: /^-?\d+$/,
                            message: "Only whole numbers are allowed.",
                          },
                          min: {
                            value: 1,
                            message: "Price must be greater than or equal to 1",
                          },
                        })}
                      />
                      {errors.duration && (
                        <span className="text-danger">
                          {errors.duration.message}
                        </span>
                      )}
                    </div>
                  </div>}
              </div>
            </Col>
          </Row>
        </ContentWrapper>

        <ContentWrapper additionalClass="font-body p-5 mt-5">
          <Row>
            {/* Taglines */}
            <Col lg={12}>
              <h4 className="text-md font-bold">Taglines</h4>
              <label>
                Title<span style={{ color: "red", fontSize: "20px" }}>*</span>
              </label>
              <input
                type="text"
                className="po-edit-input"
                name="title"
                {...register("tagline_title", {
                  required: "Please enter a tagline title",
                  pattern: {
                    value: /^(?!\s*$).+/,
                    message: "Only alphabetic characters are allowed.",
                  },
                })}
              />
              {errors.tagline_title && (
                <span className="text-danger">
                  {errors.tagline_title.message}
                </span>
              )}
            </Col>

            {/* Taglines lists */}
            <Col xs={12}>
              <label htmlFor="latitude">
                Tagline
                <span style={{ color: "red", fontSize: "20px" }}>*</span>
              </label>
              <div className="d-flex align-items-center">
                <input
                  value={taglines[0]}
                  placeholder="Enter Benefit..."
                  type="text"
                  id="latitude"
                  className="po-edit-input w-100"
                  onChange={handleTagline}
                />
                {/* <Button
                  variant="outline-success"
                  className=" radius-105 text-nowrap ms-3"
                  type="button"
                  onClick={() => {
                    if (tagline === "") {
                      setTaglineErrors({
                        ...taglineErrors,
                        error: "Please enter some text...",
                      });
                      return;
                    }
                    let prev = taglines;
                    prev.push(tagline);
                    setTaglines([...prev]);
                    setTagline("");
                  }}
                >
                  Add tagline
                </Button> */}
              </div>
              <p className="text-danger">
                {taglineErrors.error && taglineErrors.error}
              </p>
            </Col>
          </Row>
        </ContentWrapper>

        {/* <ContentWrapper additionalClass="font-body p-5 mt-5">
          <Row>
            <Col md={12}>
              {taglines && taglines.length > 0 && (
                <label className="bold text-lg mb-2">List of Brief</label>
              )}

              <ul className="list-group">
                {taglines &&
                  taglines.map((tagline, index) => {
                    return (
                      <li
                        key={index}
                        className="d-flex justify-content-between align-items-center list-group-item"
                      >
                        <div className="my-1">{tagline}</div>
                        <Button
                          variant="outline-danger "
                          className=" radius-105"
                          type="button"
                          onClick={(e) => {
                            let itemsToRemove = taglines;
                            itemsToRemove.splice(index, 1);
                            setTaglines([...itemsToRemove]);
                          }}
                        >
                          Delete
                        </Button>
                      </li>
                    );
                  })}
              </ul>
            </Col>
            {/*  Tagline */}


        {/* </Row>
        </ContentWrapper>  */}


        <ContentWrapper additionalClass="p-5 mt-5">
          <Row>
            {/*  Benifits */}
            <Col lg={12}>
              <label htmlFor="latitude" className="bold">
                Add Benefit
                <span style={{ color: "red", fontSize: "20px" }}> *</span>
              </label>
              <div className="">
                <div>
                  <input
                    value={benifit.title}
                    placeholder="Enter title..."
                    type="text"
                    id="latitude"
                    className="po-edit-input w-100 mt-3"
                    onChange={handleBenifitTitle}
                  />
                  {benifitErrors.title && (
                    <p className="text-danger">{benifitErrors.title}</p>
                  )}
                </div>
                <div>
                  <textarea
                    value={benifit.description}
                    placeholder="Enter Description..."
                    type="text"
                    id="latitude"
                    className="po-edit-input w-100 mt-3"
                    onChange={handleBenifitDescription}
                  ></textarea>
                  {benifitErrors.description && (
                    <p className="text-danger">{benifitErrors.description}</p>
                  )}
                </div>

                <Button
                  variant="outline-success"
                  className=" radius-105 text-nowrap  mt-3"
                  type="button"
                  onClick={(e) => {
                    if (
                      benifitErrors.title === "" &&
                      benifitErrors.description === ""
                    ) {
                      if (benifit.title === "") {
                        setBenifitErrors((prevErrors) => ({
                          ...prevErrors,
                          title: "Please enter a title",
                        }));
                      }
                      if (benifit.description === "") {
                        setBenifitErrors((prevErrors) => ({
                          ...prevErrors,
                          description: "Please enter a description",
                        }));
                      }
                      if (benifit.title !== "" && benifit.description !== "") {
                        setBenifits((prevBenifits) => [
                          ...prevBenifits,
                          benifit,
                        ]);
                        setBenifit({
                          title: "",
                          description: "",
                        });
                        setBenifitErrors({
                          title: "",
                          description: "",
                        });
                      }
                    }
                  }}
                >
                  Add Benefit
                </Button>
              </div>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col lg={12}>
              {benifits.length > 0 && (
                <label className="bold my-2 text-lg">List of Benifits</label>
              )}

              {/* List of Benifts */}
              <Table bordered>
                <thead>
                  <tr>
                    <th>S.no</th>
                    <th>Title</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {benifits &&
                    benifits.length > 0 &&
                    benifits.map((ele, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          {/* Table */}
                          {ele.contentEditable ? (
                            <td
                              className="text-sm"
                              style={{
                                backgroundColor: ele.contentEditable ? "#e0dcdc" : null,
                                padding: "10px",
                                minHeight: "100px",
                                outline: "none"
                              }}
                              // User input setting in the state

                              onInput={(e) => {
                                setEditBenifit({
                                  title: e.target.innerHTML,
                                  description: ele.description,
                                });
                              }}
                              // Pressing Enter key the content will add to the benifits array

                              onKeyDown={(e) => {
                                // if (e.keyCode === 13) {
                                // }
                              }}
                              contentEditable
                            >
                              {ele.title}
                            </td>
                          ) : (
                            <td
                              className="text-sm"
                              style={{
                                backgroundColor: ele.contentEditable ? "#e0dcdc" : null,
                                padding: "10px",
                                minHeight: "100px",
                                outline: "none"
                              }}
                              // User input setting in the state

                              onInput={(e) => {
                                setEditBenifit({
                                  title: e.target.innerHTML,
                                  description: ele.description,
                                });
                              }}
                              // Pressing Enter key the content will add to the benifits array

                              onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                  let prevBenifits = benifits.map((ele) => ({
                                    title: ele.title,
                                    description: ele.description,
                                  }));
                                  prevBenifits[index] = {
                                    title: editBenifit.title,
                                    description: editBenifit.description,
                                  };
                                  setBenifits([...prevBenifits]);
                                  setEditState(false);
                                }
                              }}
                            >
                              {ele.title}
                            </td>
                          )}

                          {/* Description */}

                          {ele.contentEditable ? (
                            <td

                              className="text-sm"
                              // contentEditable={ele.contentEditable}

                              style={{
                                backgroundColor: ele.contentEditable ? "#e0dcdc" : null,
                                padding: "10px",
                                minHeight: "100px",
                                outline: "none"
                              }}
                              // User input setting in the state

                              onInput={(e) => {
                                setEditBenifit({
                                  title: ele.title,
                                  description: e.target.innerHTML,
                                });
                              }}
                              // Pressing Enter key the content will add to the benifits array

                              onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                  let prevBenifits = benifits.map((ele) => ({
                                    title: ele.title,
                                    description: ele.description,
                                  }));
                                  prevBenifits[index] = {
                                    title: editBenifit.title,
                                    description: editBenifit.description,
                                  };
                                  setBenifits([...prevBenifits]);
                                  setEditState(false);
                                }
                              }}
                              colSpan={2}
                              contentEditable
                            >
                              {ele.description}
                            </td>
                          ) : (
                            <td
                              className="text-sm"
                              // contentEditable={ele.contentEditable}

                              style={{
                                backgroundColor: ele.contentEditable ? "#e0dcdc" : null,
                                padding: "10px",
                                minHeight: "100px",
                                outline: "none"
                              }}
                              // User input setting in the state

                              onInput={(e) => {
                                setEditBenifit({
                                  title: ele.title,
                                  description: e.target.innerHTML,
                                });
                              }}
                              // Pressing Enter key the content will add to the benifits array

                              onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                  let prevBenifits = benifits.map((ele) => ({
                                    title: ele.title,
                                    description: ele.description,
                                  }));
                                  prevBenifits[index] = {
                                    title: editBenifit.title,
                                    description: editBenifit.description,
                                  };
                                  setBenifits([...prevBenifits]);
                                  setEditState(false);
                                }
                              }}
                              colSpan={2}
                            >
                              {ele.description}
                            </td>
                          )}

                          {ele.contentEditable ? (
                            <td>
                              <Button
                                variant="success"
                                onClick={(e) => handleSaveButton(index)}
                              >
                                Save
                              </Button>
                            </td>
                          ) : (
                            <td>
                              <Button
                                variant="outline-secondary"
                                onClick={(e) => {
                                  let prevBenifits = benifits.map((ele) => ({
                                    title: ele.title,
                                    description: ele.description,
                                  }));
                                  prevBenifits[index]["contentEditable"] = true;
                                  prevBenifits[index]["blinking"] = true;
                                  setEditState(true);
                                  setBenifits([...prevBenifits]);
                                }}

                              >
                                Edit
                              </Button>
                            </td>
                          )}
                          <td className="d-flex justify-content-center">
                            <Button
                              variant="outline-danger "
                              className=" radius-105"
                              type="button"
                              onClick={(e) => {
                                let itemsToRemove = benifits;
                                itemsToRemove.splice(index, 1);
                                setBenifits([...itemsToRemove]);
                              }}
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Col>

            {/*  Benifits */}
            <Col md={12}>
              <div className="d-flex justify-content-end mt-4">
                <OutlinedButton link="/plans" name="Cancel" />

                <SuccessButton
                  type="submit"
                  name="Update Plan"
                  icon="bi-check-circle"
                  additionalClass={`ms-4 ${showToaster ? "opacity-06 cursor-default" : null
                    }  `}
                  disabled={editState ? true : showToaster}
                />
              </div>
            </Col>
          </Row>
        </ContentWrapper>
      </form>
      {/* Plan Image */}
      <Modal size="xs" show={photoDeleteModal} onHide={() => setPhotoDeleteModal(false)}  >
        <div className="background-appColor p-5 rounded font-body">
          <h5 className="text-2xl  color-ternary  mb-3">
            Are you sure?
          </h5>
          <ModalBody>
            <p className="text-light font-weight-bold">Do you want to delete this plan image?</p>
          </ModalBody>
          <div className="d-flex justify-content-end">
            <SecondaryButton
              onClick={() => setPhotoDeleteModal(false)}
              name="No"
              additionalClass="text-light mt-3 me-4 mx-5 px-5"
            />
            <SecondaryButton
              onClick={() => {
                setPreviewImage(null);
                setPlanImage({ ...planImage, ImageUrl: "" });
                setPhotoDeleteModal(false)
              }}
              name="Yes"
              additionalClass="text-light mt-3 me-4 px-5"
            />

          </div>
        </div>
      </Modal >
      {/* Plan Flat Image */}
      <Modal size="xs" show={flatDeleteModal} onHide={() => setFlatDeleteModal(false)}  >
        <div className="background-appColor p-5 rounded font-body">
          <h5 className="text-2xl  color-ternary  mb-3">
            Are you sure?
          </h5>
          <ModalBody>
            <p className="text-light font-weight-bold">Do you want to delete flat image?</p>
          </ModalBody>
          <div className="d-flex justify-content-end">
            <SecondaryButton
              onClick={() => setFlatDeleteModal(false)}
              name="No"
              additionalClass="text-light mt-3 me-4 mx-5 px-5"
            />
            <SecondaryButton
              onClick={() => {
                setFlatPreviewImage(null);
                setFlatImage({ ...flatImage, ImageUrl: "" });
                setFlatDeleteModal(false);
              }}
              name="Yes"
              additionalClass="text-light mt-3 me-4 px-5"
            />

          </div>
        </div>
      </Modal >
      <Toaster
        showToaster={showToaster}
        setShowToaster={setShowToaster}
        bg={toasterDetails.bg}
        title={toasterDetails.title}
        bodyText={toasterDetails.bodyText}
        statusIcon={toasterDetails.statusIcon}
      />
    </Layout>
  );
};
export default EditPlans;
