import React, { useState, useEffect } from 'react';
import { Button, Table, Modal } from "react-bootstrap";
import { collection, deleteDoc, doc, getDocs, query, orderBy } from "firebase/firestore";

import ContentWrapper from "../../../Components/Global/ContentWrapper";
import Layout from "../../../Layout/LayoutManager/Layout";
import ActionIcon from "../../../Components/Global/Buttons/ActionIcons";
import PageDescription from "../../../Components/Global/PageDescription";
import { Link } from "react-router-dom";
import db from "../../../firebase";
import Dropdown from "react-bootstrap/Dropdown";
import PrimaryButton from '../../../Components/Global/Buttons/PrimaryButton';
import SecondaryButton from '../../../Components/Global/Buttons/SecondaryButton';
import Skeleton from 'react-loading-skeleton';

const SmokeManagement = () => {
  const clubId = localStorage.getItem('club_id');
  const [show, setShow] = useState(false);
  const [deletingId, setDeletingId] = useState("");
  const [isLoading, setIsLoading] = useState([]);
  const handleClose = () => {
    setDeletingId("");
    setShow(false);
  }
  const confirmDelete = (deleteId) => {
    setShow(true);
    setDeletingId(deleteId);
  }

  const [smokes, setSmokes] = useState([]);

  const getClubData = async () => {
    // console.log("coming inside the get all smoke");
    const subColRef = query(collection(db, "clubs", clubId, "smoke_category"), orderBy('updatedAt', 'desc'));
    const qSnap = await getDocs(subColRef);
    let smokeArray = [];
    qSnap.forEach(data => {
      smokeArray.push(data.data());
    });
    setSmokes(smokeArray);
    setIsLoading(false);
  }
  useEffect(() => {
    getClubData();
  }, []);
  const deleteCategory = async (e, id) => {
    const detetion = await deleteDoc(doc(db, "clubs", clubId, "smoke_category", id))
      .then(() => {
        // alert("Successfully Deleted");
        getClubData();
        setShow(false);
      })
      .catch((err) => {
        console.log(err);
        // alert("Not Deleted");
        setShow(false);
      });
  }
  function getAllBrands(data) {
    let rtn = "";
    data.forEach((d, ind) => {
      if (ind + 1 < data.length) {
        rtn += d.name + ", "
      } else if (ind + 1 == data.length) {
        rtn += d.name
      }
    })
    return rtn;
  }
  return (
    <Layout path="/manager/smokes-management">
      <div className="d-flex justify-content-between align-items-center gap-3 gap-lg-5 font-body">
        <PageDescription title="Smoke Categories" caption="" />
        <PrimaryButton link="/manager/smokes-management/create-category" name="Add Category" />

      </div>
      <ContentWrapper additionalClass="pb-5 rounded font-body">
        <div className="table-responsive po-table tableFixHead">
          <Table hover>
            <thead>
              <tr className="background-gray10">
                <th><p className="text-nowrap text-xs text-xl-sm text-uppercase ps-3 color-black2 mb-0 py-2">S. NO</p></th>
                <th>
                  <p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2"> Smoke Types </p>
                </th>
                <th className="text-nowrap text-sm text-uppercase">
                  <p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2"> Brands/Flavors </p>
                </th>
                <th className="text-nowrap text-sm text-uppercase"></th>
              </tr>
            </thead>
            <tbody>
              {!isLoading ?
                (
                  <>
                    {(smokes && smokes.length) ? smokes.map((smoke, index) => {
                      return (
                        <tr key={index}>
                          <td className="align-middle">
                            <p className="text-sm mb-0 text-nowrap color-lightblue ps-3">{index + 1}.</p>
                          </td>
                          <td className="align-middle">
                            <p className="text-sm mb-0 color-lightblue">{smoke.name}</p>
                          </td>

                          <td className="align-middle">
                            {smoke.brands && smoke.brands.length > 0 && smoke.brands.map((brand, inde) => {
                              if (inde < 3) {
                                return <span className="mr-3 text-sm">{brand.name}</span>
                              }
                              if (inde == 3) {
                                return <span title={getAllBrands(smoke.brands)} className="text-sm font-semibold link " >...More</span>
                              }
                            })}
                          </td>
                          <td className="align-middle">

                            <Dropdown>
                              <Dropdown.Toggle variant="link" id="dropdown-basic" className="text-black">
                                <i className="bi bi-three-dots-vertical text-4xl d-bloxk"></i>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="border-0 shadow px-2">
                                <Link className="text-black border-bottom font-bold text-sm py-2 d-block ps-1 " to={`/manager/smokes-management/update-category/${smoke.id}`}>Edit</Link>
                                <div className="text-black font-bold text-sm py-2 d-block ps-1 cursor-pointer" onClick={(e) => confirmDelete(smoke.id)}>Delete</div>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      )
                    }) : ""
                    }
                  </>
                ) : (
                  <tr>
                    <td colSpan={4}> <Skeleton inline={true} count={5} height={80} style={{ marginBottom: "1rem" }} baseColor='#dedede' highlightColor='#cccccc' duration={2} />
                    </td>
                  </tr>
                )
              }
            </tbody>
          </Table>
        </div>
      </ContentWrapper>
      <Modal show={show} onHide={handleClose}>
        <div className="background-appColor p-8 rounded font-body">
          <h5 className="text-2xl  color-ternary font-semibold ">
            Are you sure?
          </h5>
          <p className="background-appColor text-light border-bottom-0 py-1 ">
            You want to delete this Smoke category?
          </p>
          <div className="d-flex justify-content-end mt-12">
            {/* <Button variant="secondary" className="me-2 px-6" onClick={(e) => deleteCategory(e, deletingId)}>Yes</Button>
            <Button variant="light" className=" px-6" onClick={handleClose}>No keep it</Button> */}
            <SecondaryButton onClick={(e) => deleteCategory(e, deletingId)} additionalClass="me-2 px-6 text-white" name="Delete" />
            <PrimaryButton onClick={handleClose} name="Cancel" additionalClass="px-6" />
          </div>
        </div>
      </Modal>
    </Layout>
  );
};
export default SmokeManagement;
