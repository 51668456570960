import ContentWrapper from "../../../Components/Global/ContentWrapper";
import Layout from "../../../Layout/LayoutManager/Layout";
import { Row, Col, Button, Table } from "react-bootstrap";
import { useEffect } from "react";
import { useState } from "react";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import db from "../../../firebase";
import OutlinedButton from "../../../Components/Global/Buttons/OutlinedButton";
import SuccessButton from "../../../Components/Global/Buttons/SuccessButton";
import ActionIcon from "../../../Components/Global/Buttons/ActionIcons";
import moment from "moment";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Toaster from "../../../Components/Global/Toast";

const CreateEventBooking = () => {
  const [events, setEvents] = useState([]);
  const [users, setUsers] = useState([]);
  const [tableDetails, setTableDetails] = useState([]) // fetched from clubs
  const token = localStorage.getItem('authenticate');
  const [timeSlots, setTimeSlots] = useState([]);
  const [userId, setUserId] = useState("");
  const [showToaster, setShowToaster] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [toasterDetails, setToasterDetails] = useState({
    title: "",
    bodyText: "",
    bg: "",
    statusIcon: "",
  });
  const [guestErrors, setGuestErrors] = useState({
    guestName: false,
    gender: false,
    age: false,
    ageInputValidation: false,
  })
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState({
    name: false,
    username: false,
    booking_date: false,
    amount: false
  })
  const [guestDetails, setGuestDetails] = useState({
    guest_name: "",
    gender: "",
    age: ""
  })
  const [eventName, setEventName] = useState("");
  const [eventId, setEventId] = useState("")
  const [state, setState] = useState({
    event_name: "",   // will use for submit form
    amount: "",
    people: [],
    booking_date: "",
    errors: {
      event_name: "",
      amount: "",
      people: "",
      booking_date: "",
    }
  })
  // fetching clubs details ***********************************
  const fetchClubs = () => {
    getDocs(query(
      collection(db, "events"),
      orderBy("name"))).then((docSnapshots) => {
        console.log(docSnapshots, "docSnapshots");
        setEvents([]);
        docSnapshots.forEach((docs) => {
          setEvents((prev) => [...prev, docs.data()]);
          // console.log(docs.data());
        });
      });
  }

  // fetching users details *******************************************
  const fetchUsers = () => {
    getDocs(query(
      collection(db, "users"),
      orderBy("name"))).then((docSnapshots) => {
        setUsers([]);
        docSnapshots.forEach((docs) => {
          setUsers((prev) => [...prev, docs.data()]);
        });
      });
  }
  useEffect(() => {
    fetchClubs();
    fetchUsers();

  }, []);

  const handleFormSubmit = (e) => {
    setLoadingSubmit(true);
    e.preventDefault();
    if (eventId === "" || userId === "" || state.booking_date === "" || state.amount === "") {
      let validation = formErrors;
      if (eventId === "") {
        validation.name = true;
      }
      if (userId === "") {
        validation.username = true;
      }
      if (state.booking_date === "") {
        validation.booking_date = true;
      }
      if (state.amount === "") {
        validation.amount = true;
      }
      setFormErrors({ ...validation })
      return;
    }
    setShowToaster(true);
    setToasterDetails((prev) => ({
      ...prev,
      title: "Creating...",
      bodyText: "Creating Event Booking",
      bg: "warning",
      statusIcon: (
        <div className="spinner-border text-warning me-2" role="status"></div>
      ),
    }));
    setGuestErrors({
      guestName: false,
      gender: false,
      age: false,
      ageInputValidation: false,
    })
    const payload = {
      booking_type: "event_entry_booking",
      user_id: userId,
      event_id: eventId,
      event_name: eventName,
      booking_date: state.booking_date,
      attendee_count: state.people.length,
      amount: Number(state.amount),
      people: state.people
    }

    const headers = {
      headers: {
        developement: true,
        token: token,
      },
    };
    axios.post(`${process.env.REACT_APP_API_URL}/booking/v2/eventBooking`,
      payload, headers).then(function (response) {
        setToasterDetails((prev) => ({
          ...prev,
          title: "Success",
          bodyText: "Event Booking Details are added successfully",
          bg: "success",
          statusIcon: (
            <i className="bi bi-check text-success text-3xl me-2"></i>
          ),
        }));
        setUserId("");
        setEventId("");
        setEventName("");
        setState(prev => ({
          ...prev,
          people: []
        }))
        setLoadingSubmit(false);
        setState({ ...state, amount: "" })
        setTimeout(() => {
          navigate(`/manager/booking-management?tab=events`, { replace: true });
        }, 2000);

      }).catch(function (error) {
        // alert(error.message);
        setToasterDetails((prev) => ({
          ...prev,
          title: "Error",
          bodyText: "Something went wrong, Please try again",
          bg: "danger",
          statusIcon: (
            <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
          ),
        }));
        setLoadingSubmit(false);
        setTimeout(() => {
          setShowToaster(false);
        }, 2000);
      })

  };

  const onChangeHandler = (e) => {
    // const { name, value } = e.target;
    setFormErrors({
      ...formErrors, name: false
    })
    setEventName(e.target.options[e.target.selectedIndex].text)
    setEventId(e.target.value);

  }

  const handleGuestDetailsChange = (event) => {
    const { name, value } = event.target;
    if (name === "event_name") {
      if (value === "")
        setGuestErrors({ guestName: true })
    }
    setGuestDetails(prev => ({
      ...prev,
      [name]: value
    }))

  };
  const handleGenderChange = (event) => {
    setGuestDetails(prev => ({
      ...prev,
      gender: event.target.value
    }))
  };
  const addingGuestDetails = () => {
    console.log(guestDetails, "guestDetails");
    const numberRegex = /^\d+(\.\d+)?$/;
    if ([guestDetails.guest_name, guestDetails.gender, guestDetails.age].includes("")) {
      setGuestErrors({
        guestName: true,
        gender: true,
        age: true
      });
      return;
    }
    if (guestDetails.guest_name === "") {
      setGuestErrors({ guestName: true });
      return;
    }
    if (guestDetails.gender === "") {
      setGuestErrors({ gender: true });
      return;
    }
    if (guestDetails.age === "") {
      setGuestErrors({ age: true });
      return;
    }
    if (!numberRegex.test(guestDetails.age)) {
      setGuestErrors({ ageInputValidation: true });
      return;
    }
    if (![guestDetails.guest_name, guestDetails.gender, guestDetails.age].includes("")) {
      setGuestErrors({
        guestName: false,
        gender: false,
        age: false
      });

    }
    let guest = {
      name: guestDetails.guest_name,
      gender: guestDetails.gender,
      age: guestDetails.age
    }
    setState(prev => ({
      ...prev,
      people: [...state.people, guest]
    }))
    setGuestDetails({
      guest_name: "",
      gender: "",
      age: ""
    })
  }
  const handleTableDetailsChange = (event, selectedTableDetails) => {
    const isChecked = event.target.checked;
    // Update the selectedOptions array based on the checkbox change
    let tablePayload = {
      table_type: selectedTableDetails.name,
      count: selectedTableDetails.seater_count,
      minimum_spend: selectedTableDetails.minimum_spent
    }
    if (isChecked) {
      setState(prev => ({
        ...prev,
        tables: [...state.tables, tablePayload]
      }))
    } else {
      const filteredOptions = state.tables.filter((option) => option.table_type !== tablePayload.table_type);
      setState(prev => ({
        ...prev,
        tables: filteredOptions
      }))
    }
  };

  const handleRemoveGuest = (index) => {
    const guestList = [...state.people];
    const remainGuest = guestList.splice(index, 1);
    setState(prev => ({
      ...prev,
      people: [...guestList]
    }))
  }
  const OnChangeUserHandler = (e) => {
    setFormErrors({
      ...formErrors, username: false
    })
    setUserId(e.target.value)
  }

  const onChangeAmount = (event) => {
    setState(prev => ({
      ...prev,
      amount: event.target.value
    }))
    setFormErrors({
      ...formErrors, amount: false
    })
  }
  const onChangeBookingDate = (e) => {
    setState(prev => ({
      ...prev,
      booking_date: e.target.value
    }))
    setFormErrors({
      ...formErrors, booking_date: false
    })
  }

  return (
    <Layout path="/manager/booking-management/create-event-booking">
      <ContentWrapper additionalClass="p-5">
        <form className="mt-6" onSubmit={handleFormSubmit}>
          <h5 className="text-2xl font-body font-bold mb-3">Event booking details</h5>
          <Row className="g-4">
            <Col xs={6} className="font-body">
              <h4 className="text-md font-semibold color-gray mb-2">Event name<span className="text-danger">*</span></h4>
              <select
                name="event_name"
                id="club_name"
                className="po-edit-input "
                // placeholder="Enter Manager Name"
                onChange={onChangeHandler}
                value={eventId}

              >
                <option key="club_name" value="" >Select event name</option>
                {events &&
                  events.map((event) => {
                    return <option value={event.id} id={event.id} key={event.id} title={event.club && event.club.address} >{event.name}</option>;
                  })}
              </select>
              {formErrors.name && <p className="  text-danger font-body text-sm">
                {/* {errors.selectState?.message} */}
                Please choose event
              </p>}
            </Col>
            {console.log(users, "users")}
            <Col xs={6} className="font-body">
              <h4 className="text-md font-semibold color-gray mb-2">User name<span className="text-danger">*</span></h4>
              <select
                name="user_name"
                id="user_name"
                className="po-edit-input "
                // placeholder="Enter Manager Name"
                onChange={OnChangeUserHandler}
                value={userId}
              >
                <option key="user_name" value="">Select user name</option>
                {users &&
                  users.map((user, index) => {
                    return <option value={user.id} id={user.id} key={index} title={user.email}>{user.name}</option>;
                  })}
              </select>
              {formErrors.username && <p className="text-danger font-body text-sm">
                {/* {errors.selectState?.message} */}
                Please choose user
              </p>}
            </Col>
          </Row>

          {/* Table Details goes here ***************************************************** */}
          {tableDetails.length ? <Row className="g-4 mt-2">
            <Col xs={6}>
              <h4 className="text-2xl  font-bold text-black">
                Add table details
              </h4>
              <div>
                {tableDetails.map((tableDetail) => (
                  <label key={tableDetail.name} className="d-flex align-items-center gap-2 text-md font-medium">
                    <input
                      type="checkbox"
                      value={tableDetail.name}
                      className="po-checkbox"
                      // checked={tableDetails.includes(state.)}
                      onChange={(e) => { handleTableDetailsChange(e, tableDetail) }}
                    />
                    {tableDetail.name}
                  </label>
                ))}
              </div>
            </Col>
            {state.tables.length ? <Col xs={6}>
              <h4 className="text-2xl  font-bold text-black">
                Table details list
              </h4>
              <Table responsive hover>
                <thead>
                  <tr>
                    <th className="text-md">S. No</th>
                    <th className="text-md">Table Type</th>
                    <th className="text-md">No of people</th>
                    <th className="text-md">Minimum Spent</th>
                  </tr>
                </thead>
                <tbody>
                  {state.tables.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.table_type}</td>
                        <td>{item.count}</td>
                        <td>{item.minimum_spend}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </Col> : ""}
          </Row> : ""}

          {/* guest details goes here **************************************** */}
          <Row className="g-4 mt-2">
            <Col xs={6}>
              <h4 className="text-2xl  font-bold text-black">
                Add guest details
              </h4>
              {state.errors.tables && (
                <div>
                  <p className="  text-danger font-body text-sm">
                    Add atleast one table
                  </p>
                </div>
              )}
              <label className="font-body text-md mt-3">Name<span className="text-danger">*</span></label>
              <input
                name="guest_name"
                id="guest_name"
                className="po-edit-input"
                placeholder="Please enter the name of guest"
                value={guestDetails.guest_name.charAt(0).toUpperCase() + guestDetails.guest_name.slice(1)}
                onChange={handleGuestDetailsChange}
              />
              {guestErrors.guestName && <p className="text-danger">Please enter name</p>}
              <div>
                <label className="font-body text-md d-block mt-3">Gender<span className="text-danger">*</span></label>
                <label className="me-2 font-medium text-md d-inline-flex align-items-center">
                  <input
                    type="radio"
                    value="male"
                    className="me-1"
                    checked={guestDetails.gender === 'male'}
                    onChange={handleGenderChange}
                  />
                  Male
                </label>
                <label className="me-2 font-medium text-md d-inline-flex align-items-center">
                  <input
                    type="radio"
                    className="me-1"
                    value="female"
                    checked={guestDetails.gender === 'female'}
                    onChange={handleGenderChange}
                  />
                  Female
                </label>
                <label className="me-2 font-medium text-md d-inline-flex align-items-center">
                  <input
                    type="radio"
                    className="me-1"
                    value="other"
                    checked={guestDetails.gender === 'other'}
                    onChange={handleGenderChange}
                  />
                  Other
                </label>
              </div>
              {guestErrors.guestName && <p className="text-danger">Please select gender</p>}
              <label className="font-body text-md mt-3 ">Age<span className="text-danger">*</span></label>
              <input
                className="po-edit-input"
                name="age"
                type="number"
                value={guestDetails.age ? +guestDetails.age : ""}
                min={0}
                onChange={handleGuestDetailsChange}
              />
              {guestErrors.age && <p className="text-danger">Please enter age</p>}
              {guestErrors.ageInputValidation && <p className="text-danger">Please enter a valid age</p>}
              <div className="text-end mt-5 font-body">
                <Button className="color-black1 text-md font-body font-semibold cursor-pointer po-border po-border-yellow1 py-3 px-3 radius-105 d-inline-block bg-transparent text-dark"
                  onClick={addingGuestDetails} name="Add Table Data">
                  Add Guest Details
                </Button>
              </div>
            </Col>
            <Col xs={6}>
              {state.people.length ?
                <div>
                  <h4 className="text-2xl  font-bold text-black">Guest Details List</h4>
                  <Table responsive hover>
                    <thead>
                      <tr>
                        <th className="text-md">S. No</th>
                        <th className="text-md">Name</th>
                        <th className="text-md">Gender</th>
                        <th className="text-md">Age</th>
                      </tr>
                    </thead>
                    <tbody>
                      {console.log(state.people, 'state.people state.people')}
                      {state.people.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.name}</td>
                            <td>{item.gender}</td>
                            <td>
                              <span className="d-flex align-items-center gap-2">
                                <span>{item.age}</span>
                                <span className="">
                                  <ActionIcon
                                    onClick={() => { handleRemoveGuest(index) }}
                                    additionalClass="bi bi-x-circle-fill text-3xl color-red me-2"
                                  />
                                </span>
                              </span>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div> : ""
              }
            </Col>
          </Row>
          <Row className="g-4 mt-2">
            <Col xs={6}>
              <label htmlFor="booking_date" className="text-lg font-medium color-gray mb-2">
                Booking date<span className="text-danger">*</span>
              </label>
              <input
                id="booking_date"
                name="booking_date"
                type="date"
                min={new Date().toISOString().split('T')[0]}
                max={moment().add(10, 'y').format("YYYY-MM-DD")}
                value={state.booking_date}
                className="po-edit-input mb-1"
                onChange={onChangeBookingDate}
              />
              {formErrors.booking_date && <p className="text-danger">Please select date</p>}
            </Col>


            <Col xs={6}>
              {/* <PrimaryInput
                type="number"
                id="amount"
                label="Amount Paid*"
                value={state.amount}
                min="0"
                placeholder="500"
                onChange={onChangeHandler}
              /> */}
              <label htmlFor="amount" className="text-lg font-medium color-gray mb-2">
                Amount paid<span className="text-danger">*</span>
              </label>
              <input
                id="amount"
                name="amount"
                type="number"
                value={state.amount}
                placeholder="500"
                className="po-edit-input mb-1"
                onChange={onChangeAmount}
              />
              {formErrors.amount && <p className="text-danger">Please enter amount</p>}
            </Col>

            {/* <Col xs={6}>
              <label htmlFor="status" className="text-lg font-medium color-gray mb-2">
                Status*
              </label>
              <input
                id="status"
                name="status"
                type="text"
                value={state.status}
                className="po-edit-input mb-1"
                onChange={onChangeHandler}
              />
            </Col> */}
            <Col
              xs={12}
              className="d-flex justify-content-end align-items-center mb-6"
            >
              {showToaster && (
                <img
                  src="/assets/icons/loading_icon.gif"
                  alt="processing"
                  width={100}
                />
              )}
              <OutlinedButton link="/manager/booking-management" name="Cancel" />
              <SuccessButton
                type="submit"
                name={loadingSubmit ? "Saving..." : "Save"}
                icon="bi-check-circle"
                additionalClass="ms-4"
                disabled={loadingSubmit}
              />
            </Col>
          </Row>
        </form>
      </ContentWrapper>
      <Toaster
        showToaster={showToaster}
        setShowToaster={setShowToaster}
        bg={toasterDetails.bg}
        title={toasterDetails.title}
        bodyText={toasterDetails.bodyText}
        statusIcon={toasterDetails.statusIcon}
      />
    </Layout>
  );
};

export default CreateEventBooking;