import { Row, Col } from "react-bootstrap";
import ContentWrapper from "../../Components/Global/ContentWrapper";
// import axios from "axios";
import moment from "moment";
import { useCallback, useState } from "react";
import ActionIcon from "../Global/Buttons/ActionIcons";
import { useForm } from "react-hook-form";
import PrimaryInput from "../UI/PrimaryInput";
import {
  Timestamp,
  collection,
  doc,
  endAt,
  getDoc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  startAt,
  updateDoc,
} from "firebase/firestore";
import db from "../../firebase";
import { v4 as uuidv4 } from "uuid";
import Venue from "./Venue";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Loading from "../Global/States/Loading";
import Success from "../Global/States/Success";
import Error from "../Global/States/Error";
import SuccessButton from "../Global/Buttons/SuccessButton";
import OutlinedButton from "../Global/Buttons/OutlinedButton";
import Toaster from "../Global/Toast";
import useCallCollection from "../../hooks/useCallCollection";

const UpdateEvent = ({ newClubData, eventDetails }) => {

  const clubId = localStorage.getItem('club_id');

  if (eventDetails && eventDetails.date_and_time) {
  }
  const navigate = useNavigate();

  const [eventTypes] = useCallCollection('picklist', "event_type")
  const [previewImage, setPreviewImage] = useState(null);
  const [displayImage, setDisplayImage] = useState();
  const [eventPhotosUrl, setEventPhotosUrl] = useState([]);
  // const [eventPhotosForUpload, setEventPhotosForUpload] = useState(null);
  const [eventPhotos, setEventPhotos] = useState([]);
  // const [eventTypes, setEventTypes] = useState([]);
  const { handleSubmit, reset } = useForm({
    // resolver: yupResolver(schema)
  });
  // const [isLoading, setIsLoading] = useState(false);
  // const [isSuccess, setIsSuccess] = useState(false);
  // const [isError, setIsError] = useState(false);
  // const [loaderState, setLoaderstate] = useState({
  //   loading: "",
  //   success: "",
  //   loaderState: "",
  //   error: "",
  // });
  const [showToaster, setShowToaster] = useState(false);
  const [toasterDetails, setToasterDetails] = useState({
    title: "",
    bodyText: "",
    bg: "",
    statusIcon: "",
  });
  const [state, setState] = useState({
    id: "",
    event_name: "",
    description: "",
    event_type: "",
    event_type_show: false,
    event_date: "",
    event_timing: "",
    entry_per_head: "",
    display_image: "",
    event_photos: [],
    // club: {
    //   club_name: "",
    //   club_id: "",
    //   club_address: "",
    //   city_name: "",
    //   city_id: "",
    //   longitude: null,
    //   latitude: null,
    // },
    error: {
      event_name: "",
      description: "",
      event_type: "",
      club_name: "",
      event_date: "",
      event_timing: "",
      entry_per_head: "",
    },
    // table_reservation: {
    //   table_1: 0,
    //   table_2: 0,
    //   table_3: 0,
    //   table_4: 0,
    //   table_5: 0,
    //   table_6: 0,
    //   party_table: 0,
    // },
    showList: true,
  });
  // filling the inputs for update event********************************
  useEffect(() => {
    if (eventDetails && JSON.stringify(eventDetails) !== "{}") {
      setState((prev) => ({
        ...prev,
        event_name: eventDetails && eventDetails.name,
        description: eventDetails && eventDetails.description,
        event_type: eventDetails && eventDetails.event_type,
        // event_type_show: false,
        event_date: moment(eventDetails.date_and_time.toDate()).format(
          "YYYY-MM-DD"
        ),
        event_timing: moment(eventDetails.date_and_time.toDate()).format(
          "HH:mm:ss"
        ),
        entry_per_head: eventDetails.entry_per_head,
      }));
      setPreviewImage(eventDetails && eventDetails.display_image);
      // setEventPhotos(eventDetails.photos);
      setEventPhotosUrl(eventDetails.photos);
    }
  }, [eventDetails]);
  // postImages ****************************************************
  const postImages = async (files) => {
    const formdata = new FormData();
    for (const file of files) {
      formdata.append("images", file);
    }
    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    const resultPhotos = await fetch(
      `${process.env.REACT_APP_MULTIPLEIMAGES_POSTING_URL}/uploads/uploadImages`,
      requestOptions
    );
    const response = await resultPhotos.json();
    if (resultPhotos.status === 200) {
      return response.url;
    } else {
      return "Something went wrong!";
    }
  };
  // onChangeDisplayImage
  const handleDisplayImage = (e) => {
    const file = e.target.files[0];
    setDisplayImage(e.target.files);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  // handleChangePhotos ****************************
  const handleChangePhotos = (e) => {
    // setEventPhotos([]);
    for (const file of e.target.files) {
      setEventPhotos((prev) => [...prev, file]);
    }
  };

  const formValidate = () => {
    let isValid = true;
    let errors = state.error;
    if (state.event_name.trim() === "") {
      errors.event_name = "Please enter Event name...";
      isValid = false;
    }
    if (state.description.trim() === "") {
      errors.description = "Please enter description...";
      isValid = false;
    }
    if (state.event_type === "") {
      errors.event_type = "Please Select an event type...";
      isValid = false;
    }
    if (state.event_date === "") {
      errors.event_date = "Please Select an event date...";
      isValid = false;
    }
    if (state.event_timing === "") {
      errors.event_timing = "Please Select an event timing...";
      isValid = false;
    } else {
      if (
        state.event_date === moment().format("YYYY-MM-DD") &&
        state.event_timing < moment(new Date()).format("HH:mm")
      ) {
        errors.event_timing = "You entered invalid time...";
        isValid = false;
      }
    }
    if (state.entry_per_head === "") {
      errors.entry_per_head = "Please enter the amount...";
      isValid = false;
    }
    setState((prev) => ({
      ...prev,
      error: errors,
    }));
    return isValid;
  };

  const onSubmit = async (data) => {
    // let validation = formValidate();
    // setIsLoading(true);
    console.log(clubId, 'clubId');
    let validation = formValidate();
    let clubDetails={};
    if(validation) {
      const docRef = doc(db, "clubs", clubId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        clubDetails={...docSnap.data()}
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    } else return;
    if (JSON.stringify(clubDetails)!=="{}") {
      console.log(clubDetails, 'clubDetails', clubId);
      setShowToaster(true)
      if (newClubData) {
        setToasterDetails((prev) => ({
          ...prev,
          title: "Creating...",
          bodyText: "Creating new events",
          bg: "warning",
          statusIcon: (
            <div className="spinner-border text-warning me-2" role="status"></div>
          ),
        }));
      } else {
        setToasterDetails((prev) => ({
          ...prev,
          title: "Updating...",
          bodyText: "Updating event details",
          bg: "warning",
          statusIcon: (
            <div className="spinner-border text-warning me-2" role="status"></div>
          ),
        }));
      }
      let display_image_url = displayImage
        ? await postImages(displayImage)
        : null;
      let event_photos_url = eventPhotos.length
        ? await postImages(eventPhotos)
        : [];
      if (eventPhotosUrl && eventPhotosUrl.length) {
        event_photos_url =
          eventPhotosUrl.length > 0 && event_photos_url
            ? event_photos_url.concat(eventPhotosUrl)
            : eventPhotosUrl;
      }

      let obj = {
        createdAt: new Date(),
        id: uuidv4(),
        name: state.event_name.trim(),
        description: state.description.trim(),
        event_type: state.event_type,
        date_and_time: Timestamp.fromDate(
          moment(
            `${state.event_date} ${state.event_timing}`,
            moment.defaultFormat
          ).toDate(),
          "date_and_time"
        ),
        entry_per_head: +state.entry_per_head,
        no_of_bookings:0,
        display_image:
          display_image_url &&
          display_image_url.length > 0 &&
          display_image_url[0],
        photos: event_photos_url,
        active: false,
        status: "Inactive",
        club: {
          name: clubDetails.name,
          id: clubId,
          address: clubDetails.address,
          city_name: clubDetails.city_name,
          city_id: clubDetails.city_id,
          longitude: clubDetails.longitude ?? null,
          latitude: clubDetails.latitude ?? null,
        },
        name_lowercase: state.event_name.toLowerCase().trim(),
        updateAt: serverTimestamp(),
      };
      // create event ***********************************************
      if (newClubData) {
        let success = await newClubData(obj);
        if (success) {
          reset();
          setPreviewImage(null);
          setToasterDetails((prev) => ({
            ...prev,
            title: "Success",
            bodyText: "Event create successfully",
            bg: "success",
            statusIcon: (
              <i className="bi bi-check text-success text-3xl me-2"></i>
            ),
          }));
          setTimeout(() => {
            setShowToaster(false);
            navigate("/manager/events-management", { replace: true });
          }, 2000);
        } else {
          setToasterDetails((prev) => ({
            ...prev,
            title: "Error",
            bodyText: "Something went wrong, Try again",
            bg: "danger",
            statusIcon: (
              <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
            ),
          }));
          setTimeout(() => {
            setShowToaster(false);
          }, 2000);
        }
      } else {
        // updating the event ****************************
        const updateEvent = doc(db, "events", eventDetails.id);
        try {
          await updateDoc(updateEvent, {
            name: state.event_name.trim(),
            name_lowercase: state.event_name.toLowerCase().trim(),
            city_name_lower_case: clubDetails.city_name_lower_case,
            club_name_lower_case: clubDetails.name.toLowerCase(),
            description: state.description.trim(),
            event_type: state.event_type,
            active: false,
            status: "Inactive",
            date_and_time: Timestamp.fromDate(
              moment(
                `${state.event_date} ${state.event_timing}`,
                moment.defaultFormat
              ).toDate(),
              "date_and_time"
            ),
            entry_per_head: +state.entry_per_head,
            no_of_bookings: eventDetails.no_of_bookings ?? 0,
            display_image: display_image_url
              ? display_image_url.length > 0 && display_image_url[0]
              : previewImage,
            photos: event_photos_url ?? eventPhotosUrl,
            // club: {
            //   name: state.club.club_name,
            //   id: state.club.club_id,
            //   address: state.club.club_address,
            //   city_name: state.club.city_name,
            //   city_id: state.club.city_id,
            //   longitude: state.club.longitude,
            //   latitude: state.club.latitude,
            // },
            updateAt: serverTimestamp(),
          });
          setToasterDetails((prev) => ({
            ...prev,
            title: "Success",
            bodyText: "Event details are updated successfully",
            bg: "success",
            statusIcon: (
              <i className="bi bi-check text-success text-3xl me-2"></i>
            ),
          }));
          setTimeout(() => {
            setShowToaster(false)
            navigate("/manager/events-management", { replace: true });
          }, 2000);
          reset();
        } catch (err) {
          console.log(err, "err");
          setToasterDetails((prev) => ({
            ...prev,
            title: "Error",
            bodyText: err,
            bg: "danger",
            statusIcon: (
              <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
            ),
          }));
          setTimeout(() => {
            setShowToaster(false);
          }, 2000);
        }
      }
    } else {
      setShowToaster(false);
      if (!state.event_name.trim()) {
        document.getElementById("event_name").focus();
      } else if (!state.description.trim()) {
        document.getElementById("description").focus();
      } else if (!state.event_type) {
        document.getElementById("event_type").focus();
      } else if (!state.event_date) {
        document.getElementById("event_date").focus();
      } else if (!state.event_timing || state.error.event_timing) {
        document.getElementById("event_timing").focus();
      } else if (!state.entry_per_head) {
        document.getElementById("entry_per_head").focus();
      } console.log('console');
    }
  };

  const onChangeHandler = (event) => {
    const { value, name } = event.target;
    let errors = state.error;
    if (name === "event_date") {
      errors.event_timing = "";
    }
    setState((prevProps) => ({
      ...prevProps,
      // errors,
      [name]: value,
      error: {
        ...errors,
        [name]: "",
      },
    }));
  };

  // const fetchEventsTypes = async () => {
  //   setState((prev) => ({
  //     ...prev,
  //     event_type_show: true,
  //   }));
  //   if (eventTypes.length < 1) {
  //     const docRef = doc(db, "picklist", "event_type");
  //     const docSnap = await getDoc(docRef);
  //     if (docSnap.exists()) {
  //       setEventTypes(Object.values(docSnap.data()));
  //     } else {
  //       console.log("No such document!");
  //     }
  //   }
  // };
  // deleting image from the list of multiple images selected
  const onDeleteImagesList = (item) => {
    let newArrayEventPhotos = [...eventPhotos];
    newArrayEventPhotos.splice(item, 1);
    setEventPhotos(newArrayEventPhotos);
  };
  const onUpdateImagesList = (imageIndex) => {
    let remainImages = eventPhotosUrl.filter(
      (item, index) => index !== imageIndex
    );
    setEventPhotosUrl(remainImages);
  };


  // image upload
  // const [images, setImages] = useState([]);
  // const [uploadedImageUrls, setUploadedImageUrls] = useState([]);
  // const handleImageUpload = (event) => {
  //   const selectedImages = Array.from(event.target.files);
  //   setImages((prevImages) => [...prevImages, ...selectedImages]);
  // };

  // const handleImageDelete = (index) => {
  //   setImages((prevImages) => {
  //     const updatedImages = [...prevImages];
  //     updatedImages.splice(index, 1);
  //     return updatedImages;
  //   });
  // };
  // const uploadImages = async () => {
  //   try {
  //     const formData = new FormData();
  //     images.forEach((image) => formData.append('images', image));

  //     const response = await axios.post(`${process.env.REACT_APP_MULTIPLEIMAGES_POSTING_URL}/uploads/uploadImages`, formData);
  //     const  url  = response.data;
  //     setUploadedImageUrls(url);
  //   } catch (error) {
  //   }
  // };
  return (
    <ContentWrapper additionalClass="p-5">
      <h5 className="text-2xl font-body font-medium">Event details</h5>
      <form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
        <Row className="g-4">
          <Col xs={6} className="font-body mb-7">
            <PrimaryInput
              id="event_name"
              label="Event name"
              mandatory={true}
              placeholder="Enter event name"
              onChange={onChangeHandler}
              value={state.event_name}
            />
            {state.error.event_name && <span className="text-danger font-medium">{state.error.event_name}</span>}
          </Col>
          <Col xs={6} className="font-body mb-7">
            <PrimaryInput
              id="description"
              label="Description"
              mandatory={true}
              placeholder="Enter description"
              onChange={onChangeHandler}
              value={state.description}
            />
            {state.error.description && <span className="text-danger font-medium">{state.error.description}</span>}
          </Col>
          <Col xs={6} className="font-body mb-7">
            <label
              htmlFor="event_type"
              className="text-lg font-medium color-gray mb-3"
            >
              Event type<sup className="text-danger">*</sup>
            </label>
            <select
              id="event_type"
              name="event_type"
              type="text"
              value={state.event_type}
              className="po-edit-input position-relative zIndex__1 bg-transparent text-black mb-1"
              style={{ caretColor: "transparent" }}
              placeholder="Select Event Type"
              onChange={(e)=> {
                setState((prev) => ({
                  ...prev,
                  event_type: e.target.options[e.target.selectedIndex].value,
                  error: {
                    ...state.error,
                    event_type: e.target.options[e.target.selectedIndex].value.trim() ? "" : "Please select an event type"
                  }
                }));
              }}
            >
              <option key="event_type" value="">Select event type</option>
              {eventTypes && eventTypes.length > 0 &&
                eventTypes.map((eventType, index) => {
                  return <option value={eventType} key={index}  className="text-md">{eventType}</option>;
                })}
            </select>
            {state.error.event_type && <span className="text-danger font-medium">{state.error.event_type}</span>}
          </Col>
          <Col xs={6} className="font-body mb-7">
            <label htmlFor="event_date" className="text-lg font-medium color-gray mb-3">
              Event date<sup className="text-danger">*</sup>
            </label>
            <input
              id="event_date"
              name="event_date"
              type="date"
              min={new Date().toISOString().split('T')[0]}
              max={moment().add(10, 'y').format("YYYY-MM-DD")}
              value={state.event_date}
              className="po-edit-input mb-1"
              onChange={onChangeHandler}
            />
            {state.error.event_date && <span className="text-danger font-medium">{state.error.event_date}</span>}
          </Col>
          <Col xs={6} className="font-body mb-7">
            <label htmlFor="event_timing" className="text-lg  font-medium color-gray mb-3">
              Event timings<sup className="text-danger">*</sup>
            </label>
            <input
              name="event_timing"
              id="event_timing"
              value={state.event_timing}
              type="time"
              className="po-edit-input mb-1"
              step="2"
              onChange={onChangeHandler}
            />
            {(state.error.event_timing || state.error.event_timing) && <span className="text-danger font-medium">{state.error.event_timing}</span>}
          </Col>
          <Col xs={6} className="font-body">
            <PrimaryInput
              type="number"
              id="entry_per_head"
              label="Entry per head"
              mandatory={true}
              value={state.entry_per_head}
              min="0"
              placeholder="500"
              onChange={onChangeHandler}
            />
            {state.error.entry_per_head && <span className="text-danger font-medium">{state.error.entry_per_head}</span>}
          </Col>
          <Col xs={6} className="font-body">
            <label className="text-lg  font-medium color-gray mb-2">
              Event banner
            </label>
            <div
              style={{
                backgroundImage: `url(${previewImage ? previewImage : null})`,
                border: `${previewImage ? "0px" : "1px"} dashed #D2D6DA`,
              }}
              className="po-updateEvent__inputPhoto d-flex align-items-center justify-content-center background-position-center-top background-repeat-norepeat background-size-cover rounded overflow-hidden position-relative"
            >
              {previewImage ? (
                <div className="position-absolute end-0 top-0">
                  <ActionIcon
                    onClick={() => {
                      setPreviewImage(null);
                      setDisplayImage(null);
                    }}
                    additionalClass="bi bi-x-circle-fill text-3xl color-red me-2"
                  />
                </div>
              ) : null}
              {previewImage ? null : (
                <div>
                  <input
                    type="file"
                    id="image"
                    accept="image/*"
                    // value={state.display_image}
                    // {...register("display_image", { required: "Image is required" })}
                    onChange={handleDisplayImage}
                    className="opacity-0 position-absolute start-0 end-0 top-0 bottom-0"
                  />
                  <div className="text-center">
                    <i className="bi bi-plus-circle-fill text-5xl color-lightgreen d-block"></i>
                    <p className="text-xs font-semibold font-body text-center color-gray2 ">
                      Upload Image here
                    </p>
                  </div>
                </div>
              )}
            </div>
          </Col>
          <Col xs={6} className="font-body mb-7">
            <label
              htmlFor="event_photos"
              className="text-lg font-medium color-gray mb-2"
            >
              Event photos
            </label>
            <div
              className=" rounded position-relative po-updateEvent__inputPhoto d-flex align-items-center justify-content-center"
              style={{ border: "1px dashed #D2D6DA" }}
            >
              <input
                type="file"
                accept="image/*"
                multiple
                onChange={handleChangePhotos}
                className=" position-absolute top-0 bottom-0 start-0 end-0 opacity-0"
              />
              <div className="text-center">
                <i className="bi bi-plus-circle-fill text-5xl color-lightgreen d-block"></i>
                <p className="text-xs font-semibold font-body text-center color-gray2 ">
                  Upload Images here
                </p>
              </div>
            </div>
            {/* start */}
            {eventPhotos && eventPhotos.length > 0 && (
              <ul className="d-flex flex-wrap mt-2">

                {eventPhotos.map((item, index) => (
                  <li
                    key={index}
                    className="text-xxs po-updateEvent__inputList-item my-1 d-flex justify-content-between"
                  >
                    {" "}
                    <div
                      key={index}
                      style={{
                        height: "75px",

                        width: "75px",

                        backgroundImage: `url(${URL.createObjectURL(item)})`,
                      }}
                      className="position-relative mt-2 text-end me-2 background-position-center-center background-repeat-norepeat background-size-cover rounded overflow-hidden p-1"
                    >

                      <div
                        onClick={() => onDeleteImagesList(index)}
                        className="cursor-pointer d-inline-block rounded-circle d-flex align-items-center justify-content-center position-absolute top-0 end-0 z-2"
                        style={{
                          height: "20px",

                          width: "20px",
                        }}
                      >

                        <i className="bi d-inline-block cursor-pointer bi bi-x-circle-fill color-red me-1 mt-1 text-lg"></i>

                      </div>

                    </div>



                  </li>
                ))}

              </ul>
            )}
            {/* end */}

            {/* {eventPhotosUrl && eventPhotosUrl.length > 0 && (

            )} */}
            {
              eventPhotosUrl && <ul className="d-flex flex-wrap mt-2">
                {eventPhotosUrl.map((item, index) => (
                  <li
                    key={index}
                    className="text-xxs po-updateEvent__inputList-item my-1 d-flex justify-content-between"
                  >
                    {/* <img src={item} alt="xddd"/> */}
                    <div

                      style={{
                        height: "75px",
                        width: "75px",


                      }}
                      className="position-relative mt-2 text-end me-2 background-position-center-center background-repeat-norepeat background-size-cover rounded overflow-hidden"
                    >
                      <img src={item} alt="xddd" className="rounded" style={{ height: "100%", width: "100%", objectFit: "cover", objectPosition: "center" }} />
                      <div
                        onClick={() => onUpdateImagesList(index)}
                        className="cursor-pointer d-inline-block rounded-circle  d-flex align-items-center justify-content-center position-absolute top-0 end-0 z-2"
                        style={{
                          height: "20px",
                          width: "20px",
                        }}
                      >
                        <i className="bi d-inline-block cursor-pointer bi bi-x-circle-fill  color-red me-1 mt-1 text-lg"></i>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            }
          </Col>
          <Col
            xs={12}
            className="d-flex justify-content-end align-items-center mb-6"
          >
            {showToaster && (
              <img
                src="/assets/icons/loading_icon.gif"
                alt="processing"
                width={100}
              />
            )}

            <OutlinedButton link="/manager/events-management" name="Cancel" />
            <SuccessButton
              type="submit"
              name={newClubData ? "Create": "Update"}
              icon="bi-check-circle"
              additionalClass="ms-4"
            />
          </Col>
        </Row>
      </form>
      <Toaster
        showToaster={showToaster}
        setShowToaster={setShowToaster}
        bg={toasterDetails.bg}
        title={toasterDetails.title}
        bodyText={toasterDetails.bodyText}
        statusIcon={toasterDetails.statusIcon}
      />
    </ContentWrapper>

  );
};

export default UpdateEvent;
