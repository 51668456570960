import React, { useState, useEffect } from 'react';
import { Row, Col } from "react-bootstrap";
import ContentWrapper from "../ContentWrapper";
import DateRange from "../DateRange";
import PieChart from "./PieChart";
import Chart from "react-apexcharts";
import PolarChart from "./PolarChart";


const GridItem = ({ icon, title, count, backgroundColor, color }) => {
  return (
    <article
      className="h-100 d-flex font-body rounded p-2 flex-column align-items-center justify-content-center"
      style={{ backgroundColor: `${backgroundColor}` }}
    >
      <div>
        <div style={{ height: "50px", width: "50px" }}>
          <img src={icon} alt={title} className="img-fluid" />
        </div>
      </div>
      <div className="text-center mt-2">
        <h2 className="text-sm font-bold my-2" style={{ color: `${color}` }}>
          {title}
        </h2>
        <h4 className="text-xl font-bold" style={{ color: `${color}` }}>
          {count}
        </h4>

        <></>
      </div>
    </article>
  );
};
const Bookings = ({ setSelectedDateRange, booking_analytycs, bookingsStartDate, bookingsEndDate, monthlyBooking }) => {
  const [state, setState] = useState({
    totalBookings: [],
    entry: [],
    table: [],
    event: [],
    months: [],
  })
  useEffect(() => {
    let totalBookings = [];
    if (booking_analytycs && booking_analytycs.event_booking) {
      totalBookings = booking_analytycs.event_booking + booking_analytycs.table_booking + booking_analytycs.club_entry_booking
    }
    setState((prevProps) => ({
      ...prevProps,
      totalBookings: totalBookings,
    }))
  }, [booking_analytycs]);
  useEffect(() => {
    let entry = [];
    let table = [];
    let event = [];
    let months = []

    monthlyBooking.length > 0 && monthlyBooking.forEach(data => {
      entry.push(data.club_entry_booking);
      table.push(data.table_booking);
      event.push(data.event_entry_booking);
      months.push(data.month_name);

    })
    setState((prevProps) => ({
      ...prevProps,
      entry: entry,
      table: table,
      event: event,
      months: months
    }))
  }, [monthlyBooking]);

  const data = [
    {
      icon: "/assets/analytics-icon/entry-booking.svg",
      title: "Entry Bookings",
      count: "100",
    },
    {
      icon: "/assets/analytics-icon/event-booking.svg",
      title: "Event Bookings",
      count: "300",
    },
    {
      icon: "/assets/analytics-icon/table-booking.svg",
      title: "Table Bookings",
      count: "400",
    },
    // {
    //   icon: "https://modernize-nextjs.adminmart.com/_next/static/media/icon-favorites.62841e4a.svg",
    //   title: "Loyalty points generated",
    //   count: "656565656",

    // },
    // {
    //   icon: "https://modernize-nextjs.adminmart.com/_next/static/media/icon-speech-bubble.51601a3f.svg",
    //   title: "Loyalty points redeemed",
    //   count: "565656",
    // },
  ];
  const chartOptions = {
    chart: {
      id: "line-chart",
    },
    xaxis: {
      categories: state.months,
    },
    yaxis: {
      // title: {
      //   text: 'Value',
      // },
    },
    colors: ["#dd5182", "#444e86", "#ff6e54"],
    fill: {
      opacity: 1.0,
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: 'left',
    },
    dataLabels: {
      enabled: false,
    },
  };
  // 'Bookings', 'Successful bookings', 'Pending booking requests'
  const chartSeries = [
    {
      name: "Table Bookings",
      data: state.table,
    },
    {
      name: "Club Entry Bookings",
      data: state.entry,
    },
    {
      name: "Event Entry Bookings",
      data: state.event,
    },
  ];
  const getDateRange = (start, end) => {
    setSelectedDateRange(start, end, "booking")
  }

  return (
    <ContentWrapper additionalClass="p-5 rounded font-body">
      <div className="d-flex justify-content-between  mb-3">
        <div>
          <h2 className="text-md text-xl-lg font-bold color-blue font-body mb-0">
            Bookings
          </h2>
          <p className="text-xs text-xl-sm">Total Bookings: {state.totalBookings}</p>
        </div>
        <div>
          <DateRange
            getDateRange={getDateRange}
            startDate={bookingsStartDate}
            endDate={bookingsEndDate}
          />
        </div>
      </div>

      <Row className="g-12">
        <Col>
          <Row className="g-3">
            {/* {data.map((item, index) => {
              return ( */}
            <Col xs={4} md={4} lg={4}>
              <GridItem
                icon="/assets/analytics-icon/entry-booking.svg"
                count={booking_analytycs && booking_analytycs.club_entry_booking && booking_analytycs.club_entry_booking}
                title="Entry Bookings"
                color="#000"
                backgroundColor="#ECF2FF"
              />
            </Col>
            <Col xs={4} md={4} lg={4}>
              <GridItem
                icon="/assets/analytics-icon/event-booking.svg"
                count={booking_analytycs && booking_analytycs.event_booking && booking_analytycs.event_booking}
                title="Event Bookings"
                color="#000"
                backgroundColor="#FDEDE8"
              />
            </Col>
            <Col xs={4} md={4} lg={4}>
              <GridItem
                icon="/assets/analytics-icon/table-booking.svg"
                count={booking_analytycs && booking_analytycs.table_booking && booking_analytycs.table_booking}
                title="Table Bookings"
                color="#000"
                backgroundColor="#E6FFFA"
              />
            </Col>
            {/* );
            })} */}
          </Row>
        </Col>

      </Row>
      <Row className="mt-10">
        <Col lg={8}>
          {/* <PieChart/> */}
          <Chart
            options={chartOptions}
            series={chartSeries}
            type="line"
            height={500}
          />
        </Col>
        <Col lg={4}>
          {/* <PieChart/> */}
          <PolarChart
            booking_analytycs={booking_analytycs}
          />
        </Col>
      </Row>
    </ContentWrapper>
  );
};
export default Bookings;
