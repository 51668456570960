import React, { useState, useEffect } from 'react';
import ContentWrapper from "../../../Components/Global/ContentWrapper";
import Layout from "../../../Layout/LayoutManager/Layout";

import { useParams } from "react-router-dom";
import { Row, Col, Button, Form } from "react-bootstrap";
import CustomFileUploader from "../../../Components/Global/CustomFileUploader";
// import InputCounter from "../../Components/Global/InputCounter";
// import SuccessButtonCheck from "../Components/Global/Buttons/SuccessButtonCheck";
// import DeleteButton from "../../Components/Global/Buttons/DeleteButton";
import db from "../../../firebase";
import ActionIcon from "../../../Components/Global/Buttons/ActionIcons";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  // setDoc,
  // updateDoc,
  where,
} from "firebase/firestore";
// import { v4 as uuid } from "uuid";
import axios from "axios";
import { useForm } from "react-hook-form";
// import { runTransaction } from "firebase/firestore";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// import Clock from "react-clock";
import moment from "moment";
import PhoneInput from 'react-phone-number-input'
import { isValidPhoneNumber } from 'react-phone-number-input'
import Loading from "../../../Components/Global/States/Loading";
import Success from "../../../Components/Global/States/Success";
import Error from "../../../Components/Global/States/Error";
import PageDescription from "../../../Components/Global/PageDescription";

import OutlinedButton from "../../../Components/Global/Buttons/OutlinedButton";
import PrimaryButton from "../../../Components/Global/Buttons/PrimaryButton";
import SuccessButton from "../../../Components/Global/Buttons/SuccessButton";
import SecondaryButton from "../../../Components/Global/Buttons/SecondaryButton";
import Toaster from '../../../Components/Global/Toast';
const EditClub = () => {
  const { club_id } = useParams();
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [logoPreviewImage, setLogoPreviewImage] = useState("")
  const location = useLocation();
  let {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [country, setCountry] = useState("India");
  const [countryId, setCountryId] = useState("")
  const [states, setStates] = useState([]);
  const [state, setState] = useState("");
  const [stateId, setStateId] = useState("");
  const [cityId, setCityId] = useState("");
  const [city, setCity] = useState("");
  const [logo, setLogo] = useState(null);
  const [clubPhotos, setClubPhotos] = useState([]);
  const [fileSucess, setFileSucess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [imageError, setImageError] = useState(false)
  const [photoError, setPhotoError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [dressCode, setDressCode] = useState("");
  const [logoInBackend, setLogoInBackend] = useState(false);
  // const [toggleError, setToggleError] = useState(false);
  const [photosInBackend, setPhotosInBackend] = useState(false);
  const [choosenPhotos, setChoosenPhotos] = useState([]);
  const [wrongAddress, setWrongAddress] = useState(false);
  const [timezone, setTimeZone] = useState("");
  const [managerPhoneNumber, setManagerPhoneNumber] = useState("");
  const [managerPhoneError, setManagerPhoneError] = useState(false);
  const [timezoneCountryId, setTimeZoneCountryId] = useState("")
  const [tableEntities, setTableEntities] = useState({
    startTime: ""
  });
  const [showToaster, setShowToaster] = useState(false);
  const [toasterDetails, setToasterDetails] = useState({
    title: "",
    bodyText: "",
    bg: "",
    statusIcon: "",
  });
  const [tableDetail, setTableDetails] = useState({
    seater_count: 0,
    count: 0,
    minimum_spent: 0,
    name: ""
  })
  const [tableError, setTableError] = useState(false);
  const [tableDetailError, setTableDetailError] = useState({
    seater_count: false,
    count: false,
    minimum_spent: false,
    name: false,
    seater_count_validation: false,
    count_validation: false,
    minimum_spent_validation: false
  })
  const [tableEntitiesData, setTableEntitiesData] = useState([])
  const [tableEntitiesError, setTableEntitiesError] = useState({
    startTime: false,
    tableList: false
  });
  const [tableDetailsArray, setTableDetailsArray] = useState([]);
  const [schedules, setSchedules] = useState({
    Monday: {
      from_time: "",
      to_time: "",
    },
    Tuesday: {
      from_time: "",
      to_time: "",
    },
    Wednesday: {
      from_time: "",
      to_time: "",
    },
    Thursday: {
      from_time: "",
      to_time: "",
    },
    Friday: {
      from_time: "",
      to_time: "",
    },
    Saturday: {
      from_time: "",
      to_time: "",
    },
    Sunday: {
      from_time: "",
      to_time: "",
    },
  });
  const baseUrl = process.env.REACT_APP_BASEURL;
  const navigate = useNavigate();
  // console.log(register, "res");


  const handleTableEntities = () => {
    if (tableEntities.startTime == "") {
      setTableEntitiesError({
        ...tableEntitiesError,
        startTime: true,
      });
      return;
    }
    else {
      setTableEntitiesData([...tableEntitiesData,
      {
        slot_time: moment(tableEntities.startTime, ["HH:mm"]).format("hh:mm a"),
        zone: timezone
      }
      ])
      setTableEntities({ ...tableEntities, startTime: "" })
    }

  }

  const handleLogo = (e) => {
    // setLogo(url[0]);
    // setLogoPreviewImage(url[0])
    // setLogoInBackend(false);
    const file = e.target.files[0];
    setLogo(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setLogoPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
    setLogoInBackend(false);
  };
  const handleClubPhotos = (e) => {
    console.log("CLubphors", clubPhotos);
    for (const file of e.target.files) {
      setClubPhotos((prev) => [...prev, file]);
    }
    console.log(clubPhotos, "Afterrr");
    setPhotoError(false);
    // setClubPhotos(images);
    setPhotosInBackend(false);
  };
  const handleState = async (e) => {
    // console.log(e.target.value, "tstatte handle");
    e.target.options &&
      setStateId(e.target.value);
    setState(e.target.options[e.target.selectedIndex].text);
    await settingCities(e.target.value)
  };
  const settingStatesAndCities = async (countryId) => {
    let stateQuery = query(
      collection(db, "states"),
      where("country_id", "==", countryId)
    )
    let statesResponse = await getDocs(stateQuery);
    let stateData = statesResponse && statesResponse.docs && statesResponse.docs[0].data();
    setStates(statesResponse.docs);
    setStateId(stateData.id);
    setState(stateData.name);
    let citiesQuery = query(collection(db, "cities"), where("state_id", "==", stateData.id))
    const citiesResponse = await getDocs(citiesQuery);
    const cityData = citiesResponse && citiesResponse.docs.length > 0 && citiesResponse.docs[0].data();
    citiesResponse.docs && setCities(citiesResponse.docs);
    cityData && setCityId(cityData.id);
    cityData && setCity(cityData.name);
  }
  const settingCities = async (stateId) => {
    let q = query(collection(db, "cities"), where("state_id", "==", stateId))
    const response = stateId && await getDocs(q)
    setCities(response.docs);
    setCity(response.docs[0].data().name);
    setCityId(response.docs[0].data().id)
  }
  const handleCountry = async (e) => {
    let timezone = ""
    let temTableSlots = tableEntitiesData;
    e.target.options &&
      setCountry(e.target.options[e.target.selectedIndex].text);
    setCountryId(e.target.value);
    await settingStatesAndCities(e.target.value);
    for (let index = 0; index < countries.length; index++) {
      const element = countries[index];
      if (element.data().id === e.target.value) {
        console.log("true", element.data());
        setTimeZone(element.data().country_timezone);
        timezone = element.data().country_timezone;
      }
    }
    temTableSlots && temTableSlots.length > 0 && temTableSlots.map((ele) => {
      ele['zone'] = timezone
    })
  };
  async function addressValidation(address) {
    let flag = false;
    // console.log(process.env.REACT_APP_GOOGLEMAP_KEY, "process.env.REACT_GOOGLE_MAP_KEY");
    let myAPIKey = process.env.REACT_APP_GOOGLEMAP_KEY;
    let url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${myAPIKey}`;
    const result = await axios.get(url).then((res) => {
      // console.log(res, "addresss");
      if (res.data.status == "ZERO_RESULTS") {
        return {
          status: false
        };
      }
      else {
        return {
          status: true,
          results: res.data.results
        }
      }
    }).catch((err) => {
      console.log(err);
      return {
        status: false
      }
    })
    return result;
  }
  const handleFormSubmit = async (data) => {
    if (managerPhoneNumber === "" || managerPhoneNumber === undefined || !isValidPhoneNumber(managerPhoneNumber)) {
      setManagerPhoneError(true)
      setPhoneNumberError(true);
      return;
    }
    if (managerPhoneNumber === "" || managerPhoneNumber === undefined || !isValidPhoneNumber(managerPhoneNumber)) {
      setManagerPhoneError(true)
      return;
    }
    if (phoneNumber === "" || phoneNumber === undefined) {
      setPhoneNumberError(true);
      return;
    }

    if (clubPhotos.concat(clubPhotosUrl).length === 0) {
      setPhotoError(true);
      return;
    }
    const addressFlag = false;
    let latitude = 0;
    let longitude = 0;
    let logoImage = null;
    let photos = null;

    if (logo == null) {
      setImageError(true);
      return;
    }
    if (phoneNumber === null || !isValidPhoneNumber(phoneNumber)) {
      setPhoneNumberError(true);
      return;
    }

    if (tableDetailsArray.length === 0) {
      setTableError(true);
      return;
    }
    const addressResult = await addressValidation(data.enterFullAddress);
    if (addressResult.status === true) {
      setWrongAddress(false);
      latitude = addressResult.results[0].geometry.location.lat;
      longitude = addressResult.results[0].geometry.location.lng;
    } else {
      setWrongAddress(true);
      return;
    }
    setShowToaster(true);
    setToasterDetails((prev) => ({
      ...prev,
      title: "Updating...",
      bodyText: "Updating Club",
      bg: "warning",
      statusIcon: (
        <div className="spinner-border text-warning me-2" role="status"></div>
      ),
    }));
    if (logoInBackend) {
      logoImage = logo;
    } else {
      logoImage = await insertingImage(logo);
    }
    if (clubPhotos.length > 0) {
      let PhotosComeFromBackend = clubPhotosUrl;
      let temp = await insertingImages(clubPhotos);
      for (let index = 0; index < temp.length; index++) {
        const element = temp[index];
        PhotosComeFromBackend.push({
          url: element,
          featured: true,
          status: "Active",
        });
      }
      photos = PhotosComeFromBackend;
    } else {
      photos = clubPhotosUrl;
    }
    if (photos.length === 0) {
      setPhotoError(true);
      return;
    }
    const payload = {
      name: data.enterName,
      manager_contacts: [
        {
          name: data.enterManagerName,
          email: data.enterEmailId,
          phone: phoneNumber,
        },
        {
          name: data.enterManagerName,
          email: data.enterEmailId,
          phone: managerPhoneNumber,
        }
      ],
      dress: data.dresscode,
      website_url: data.websiteName,
      display_image: logoImage ? logoImage : null,
      photos: photos,
      schedule: {
        Monday: {
          from_time: moment
            .duration({
              hour: data.monday_start_timing.slice(0, 2),
              minutes: data.monday_start_timing.slice(3, 5),
            })
            .asMinutes(),
          to_time: moment
            .duration({
              hour: data.monday_end_timing.slice(0, 2),
              minutes: data.monday_end_timing.slice(3, 5),
            })
            .asMinutes(),
        },
        Tuesday: {
          from_time: moment
            .duration({
              hour: data.tuesday_start_timing.slice(0, 2),
              minutes: data.tuesday_start_timing.slice(3, 5),
            })
            .asMinutes(),
          to_time: moment
            .duration({
              hour: data.tuesday_end_timing.slice(0, 2),
              minutes: data.tuesday_end_timing.slice(3, 5),
            })
            .asMinutes(),
        },
        Wednesday: {
          from_time: moment
            .duration({
              hour: data.wednesday_start_timing.slice(0, 2),
              minutes: data.wednesday_start_timing.slice(3, 5),
            })
            .asMinutes(),
          to_time: moment
            .duration({
              hour: data.wednesday_end_timing.slice(0, 2),
              minutes: data.wednesday_end_timing.slice(3, 5),
            })
            .asMinutes(),
        },
        Thursday: {
          from_time: moment
            .duration({
              hour: data.thursday_start_timing.slice(0, 2),
              minutes: data.thursday_start_timing.slice(3, 5),
            })
            .asMinutes(),
          to_time: moment
            .duration({
              hour: data.thursday_end_timing.slice(0, 2),
              minutes: data.thursday_end_timing.slice(3, 5),
            })
            .asMinutes(),
        },
        Friday: {
          from_time: moment
            .duration({
              hour: data.friday_start_timing.slice(0, 2),
              minutes: data.friday_start_timing.slice(3, 5),
            })
            .asMinutes(),
          to_time: moment
            .duration({
              hour: data.friday_end_timing.slice(0, 2),
              minutes: data.friday_end_timing.slice(3, 5),
            })
            .asMinutes(),
        },
        Saturday: {
          from_time: moment
            .duration({
              hour: data.saturday_start_timing.slice(0, 2),
              minutes: data.saturday_start_timing.slice(3, 5),
            })
            .asMinutes(),
          to_time: moment
            .duration({
              hour: data.saturday_end_timing.slice(0, 2),
              minutes: data.saturday_end_timing.slice(3, 5),
            })
            .asMinutes(),
        },
        Sunday: {
          from_time: moment
            .duration({
              hour: data.sunday_start_timing.slice(0, 2),
              minutes: data.sunday_start_timing.slice(3, 5),
            })
            .asMinutes(),
          to_time: moment
            .duration({
              hour: data.sunday_end_timing.slice(0, 2),
              minutes: data.sunday_end_timing.slice(3, 5),
            })
            .asMinutes(),
        },
      },
      longitude: longitude,
      latitude: latitude,
      country_name: country ? country : "",
      country_id: countryId ? countryId : "",
      state_name: state ? state : "",
      state_id: stateId ? stateId : "",
      city_id: cityId ? cityId : "",
      city_name: city ? city : "",
      address: data && data.enterFullAddress,
      sitting_tables: tableDetailsArray.length > 0 && tableDetailsArray,
      updatedAt: new Date(),
      slots: tableEntitiesData,
      status: data.status === true ? "Active" : "Inactive",
      club_timezone: timezone,
    };
    try {
      const clubResponse = await updateDoc(doc(db, "clubs", club_id), payload).then(
        () => {
          reset();
          setLogo(null);
          setFileSucess(true);
          setSchedules({
            Monday: {
              from_time: "",
              to_time: "",
            },
            Tuesday: {
              from_time: "",
              to_time: "",
            },
            Wednesday: {
              from_time: "",
              to_time: "",
            },
            Thursday: {
              from_time: "",
              to_time: "",
            },
            Friday: {
              from_time: "",
              to_time: "",
            },
            Saturday: {
              from_time: "",
              to_time: "",
            },
            Sunday: {
              from_time: "",
              to_time: "",
            },
          });
          setIsLoading(false);
          setIsSuccess(true);
          getClubData(club_id)
          setToasterDetails((prev) => ({
            ...prev,
            title: "Success",
            bodyText: "Club Updated successfully",
            bg: "success",
            statusIcon: <i className="bi bi-check text-success text-3xl me-2"></i>,
          }));
          setTimeout(() => {
            navigate("/manager");
          }, 2000)
        }
      );
      // setTableDetailsArray([]);
      // setIsFeatured(false)
      // setPhoneNumber(null)
      // setDressCode("")
      // getAllCountries();


    } catch (err) {
      setToasterDetails((prev) => ({
        ...prev,
        title: "Error",
        bodyText: "Something went wrong",
        bg: "danger",
        statusIcon: (
          <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
        ),
      }));
      reset();
      setCountry("");
      setLogo(null);
      setFileSucess(true);
      setSchedules({
        Monday: {
          from_time: "",
          to_time: "",
        },
        Tuesday: {
          from_time: "",
          to_time: "",
        },
        Wednesday: {
          from_time: "",
          to_time: "",
        },
        Thursday: {
          from_time: "",
          to_time: "",
        },
        Friday: {
          from_time: "",
          to_time: "",
        },
        Saturday: {
          from_time: "",
          to_time: "",
        },
        Sunday: {
          from_time: "",
          to_time: "",
        },
      });
      setIsLoading(false);
      setIsError(true);
      setTableDetailsArray([]);
      setPhoneNumber(null);
      setDressCode("");
      getAllCountries();
    }
    console.log(payload, "statussss");
  };

  const getAllCountries = async (countryId) => {
    try {
      const responseArray = [];
      let q = query(collection(db, "countries"))
      let response = await getDocs(q)
        .then((responseData) => {
          // console.log(responseData.docs, "responseData");
          let timezone = "";
          for (let index = 0; index < responseData.docs.length; index++) {
            const element = responseData.docs[index];
            // console.log(element, "daaaa");
            if (element.data().id === countryId) {
              // console.log(element.data().country_timezone, "true12222");
              // setTimeZone(element.data().country_timezone);
              timezone = element.data().country_timezone
              break;
            }
          }
          // console.log(timezone, "timezone");

          // console.log(response.docs[0].data().country_timezone, "");
          setCountries(responseData.docs);
          setTimeZone(timezone)
          return responseData;
        })
        .catch((err) => console.log(err))
      // console.log(response.docs, "response.docs");


    }
    catch (err) {
      console.log(err);
    }
  }
  const getAllStates = async (countryId) => {
    try {
      let responseArray = [];
      let stateNames = [];
      let q = query(
        collection(db, "states"),
        where("country_id", "==", countryId)
      );
      let response = await getDocs(q)
        .then((responseData) => {
          return responseData;
        })
        .catch((err) => console.log(err));
      if (response.docs.length > 0) {
        response && setStates(response.docs);

      }
      else {
        setStateId("");
        setState("")
        setStates([]);
        getCities(null);
      }
    }
    catch (err) {
      // console.log(err, "state error");
      setStateId("");
      setState("");
      setStates([]);
      getCities(null)
    }
  };
  const getCities = async (stateId) => {
    if (stateId === null) {
      setCities([]);
      setCityId(null);
      setCity("");
      // console.log("null");
    }
    else {
      let responseArray = [];
      let CitiesNames = [];
      // console.log(stateId, "stateId");
      let q = query(collection(db, "cities"), where("state_id", "==", stateId))
      const response = stateId && await getDocs(q)
      response && response.docs && setCities(response.docs);

    }
  }
  const settingTimeZone = (countryId) => {
    let timezone = "";
    for (let index = 0; index < countries.length; index++) {
      const element = countries[index];
      // console.log(element.data(), "daaaa");
      if (element.data().id === countryId) {
        // console.log(element.data().country_timezone, "true12222");
        timezone = element.data().country_timezone;
      }

    }
    setTimeZone(timezone)

  }
  const getClubData = async (clubId) => {
    let clubQuery = doc(db, "clubs", clubId);
    // Club response
    const clubResponse = await getDoc(clubQuery);
    const responseData = clubResponse.data();
    let photos = responseData.photos;
    let logo = responseData.display_image;
    if (responseData) {
      try {
        reset((register) => ({
          ...register,
          enterName: responseData.name,
          isFeatured: responseData.featured,
          status: responseData.status == "Active" ? true : false,
          // responseData && responseData.manager_contacts[0].phone
          // dressCodes:    responseData.dress &&    responseData.dress,
          enterManagerName:
            responseData && responseData.manager_contacts[0].name,
          dresscode: responseData.dress,
          enterEmailId: responseData && responseData.manager_contacts[0].email,
          websiteName: responseData && responseData.website_url,
          enterFullAddress: responseData && responseData.address,
          monday_start_timing:
            responseData &&
            responseData.schedule.Monday &&
            moment
              .utc()
              .startOf("day")
              .add(responseData.schedule.Monday.from_time, "minutes")
              .format("HH:mm"),
          monday_end_timing:
            responseData &&
            responseData.schedule.Monday &&
            moment
              .utc()
              .startOf("day")
              .add(responseData.schedule.Monday.to_time, "minutes")
              .format("HH:mm"),
          tuesday_start_timing:
            responseData &&
            responseData.schedule.Tuesday &&
            moment
              .utc()
              .startOf("day")
              .add(responseData.schedule.Tuesday.from_time, "minutes")
              .format("HH:mm"),
          tuesday_end_timing:
            responseData &&
            responseData.schedule.Tuesday &&
            moment
              .utc()
              .startOf("day")
              .add(responseData.schedule.Tuesday.to_time, "minutes")
              .format("HH:mm"),
          wednesday_start_timing:
            responseData &&
            responseData.schedule.Wednesday &&
            moment
              .utc()
              .startOf("day")
              .add(responseData.schedule.Wednesday.from_time, "minutes")
              .format("HH:mm"),
          wednesday_end_timing:
            responseData &&
            responseData.schedule.Wednesday &&
            moment
              .utc()
              .startOf("day")
              .add(responseData.schedule.Wednesday.to_time, "minutes")
              .format("HH:mm"),
          thursday_start_timing:
            responseData &&
            responseData.schedule.Thursday &&
            moment
              .utc()
              .startOf("day")
              .add(responseData.schedule.Thursday.from_time, "minutes")
              .format("HH:mm"),
          thursday_end_timing:
            responseData &&
            responseData.schedule.Thursday &&
            moment
              .utc()
              .startOf("day")
              .add(responseData.schedule.Thursday.end_time, "minutes")
              .format("HH:mm"),
          friday_start_timing:
            responseData &&
            responseData.schedule.Friday &&
            moment
              .utc()
              .startOf("day")
              .add(responseData.schedule.Friday.from_time, "minutes")
              .format("HH:mm"),
          friday_end_timing:
            responseData &&
            responseData.schedule.Friday &&
            moment
              .utc()
              .startOf("day")
              .add(responseData.schedule.Friday.to_time, "minutes")
              .format("HH:mm"),
          saturday_start_timing:
            responseData &&
            responseData.schedule.Saturday &&
            moment
              .utc()
              .startOf("day")
              .add(responseData.schedule.Saturday.from_time, "minutes")
              .format("HH:mm"),
          saturday_end_timing:
            responseData &&
            responseData.schedule.Saturday &&
            moment
              .utc()
              .startOf("day")
              .add(responseData.schedule.Saturday.to_time, "minutes")
              .format("HH:mm"),
          sunday_start_timing:
            responseData &&
            responseData.schedule.Sunday &&
            moment
              .utc()
              .startOf("day")
              .add(responseData.schedule.Sunday.from_time, "minutes")
              .format("HH:mm"),
          sunday_end_timing:
            responseData &&
            responseData.schedule.Sunday &&
            moment
              .utc()
              .startOf("day")
              .add(responseData.schedule.Sunday.to_time, "minutes")
              .format("HH:mm"),
        }));
        let sittingTables = [];
        let photos = [];
        responseData.photos &&
          responseData.photos.forEach((ele) => {
            photos.push(ele);
          });
        responseData.sitting_tables.forEach((ele) => {
          sittingTables.push({
            seater_count: ele.seater_count,
            count: ele.count,
            minimum_spent: ele.minimum_spent,
            name: "",
          });
        });
        responseData.dress && setDressCode(responseData.dress);
        responseData &&
          responseData.manager_contacts &&
          setPhoneNumber(responseData.manager_contacts[0].phone);
        responseData.manager_contacts &&
          responseData.manager_contacts && responseData.manager_contacts[1] && setManagerPhoneNumber(responseData.manager_contacts[1].phone);
        setTimeZoneCountryId(responseData.country_id);
        getAllCountries(responseData.country_id);
        responseData && setCountryId(responseData.country_id);
        // settingTimeZone(responseData.country_id)
        getAllStates(responseData.country_id)
        responseData && setStateId(responseData.state_id);
        responseData && setState(responseData.state_name);
        responseData && getCities(responseData.state_id);
        responseData && setCity(responseData.city_name);
        responseData && setCityId(responseData.city_id);
        photos && setClubPhotosUrl(photos);
        photos && setPhotosInBackend(true);
        logo && setLogo(responseData.display_image);
        logo && setLogoPreviewImage(responseData.display_image);
        responseData.slots && setTableEntitiesData(responseData.slots);
        logo && setLogoInBackend(true);
        responseData &&
          responseData.sitting_tables.length > 0 &&
          setTableDetailsArray(responseData.sitting_tables);
        // responseData && setIsFeatured(responseData.featured)

      } catch (error) {
        console.log(error, "gettingdoc");
      }
    }
  };
  const insertingImage = async (imageData) => {
    let form = new FormData();
    form.append("images", imageData);
    let responseImage = await axios
      .post(
        `${process.env.REACT_APP_MULTIPLEIMAGES_POSTING_URL}`,
        form,
        { headers: { "Content-Type": "multipart/form-data" } }
      )
      .then((resImage) => {
        return resImage && resImage.data.url[0];
      })
      .catch((error) => console.log(error, "err"));
    return responseImage;
  };
  const insertingImages = async (images) => {
    // console.log(images, "Images456");
    const form = new FormData();
    let resultantArray = [];
    for (let index = 0; index < images.length; index++) {
      form.append("images", images[index]);
    }
    let responseImages = await axios
      .post(
        `${process.env.REACT_APP_MULTIPLEIMAGES_POSTING_URL}`,
        form,
        { headers: { "Content-Type": "multipart/form-data" } }
      )
      .then((resImage) => {
        // console.log(resImage, "imagggjhjhes");
        return resImage && resImage.data.url;
      })
      .catch((error) => console.log(error, "err"));
    responseImages &&
      responseImages.forEach(async (image) => {
        let insertingData = {};

        await resultantArray.push(image);
      });
    return resultantArray;
  };

  useEffect(() => {
    if (club_id) {
      getClubData(club_id);
    }
  }, [club_id])
  const handleTableDetails = (e) => {
    if (tableDetail.name == "") {
      setTableDetailError({ ...tableDetailError, name: true })
      return;
    }
    else if (tableDetail.seater_count == 0) {
      setTableDetailError({ ...tableDetailError, seater_count: true })
      return;
    }
    else if (tableDetail.count == 0) {
      setTableDetailError({ ...tableDetailError, count: true });
      return;
    }
    else if (tableDetail.minimum_spent == 0) {
      setTableDetailError({ ...tableDetailError, minimum_spent: true })
      return;
    }
    setTableError(false);
    const copyingTableDetailArray = tableDetailsArray;
    copyingTableDetailArray.push(tableDetail);
    setTableDetailsArray(copyingTableDetailArray);
    setTableDetails({ seater_count: 0, minimum_spent: 0, count: 0, name: "" });
  }
  //show images related ****************************
  const [clubPhotosUrl, setClubPhotosUrl] = useState([]);
  const [eventPhotos, setEventPhotos] = useState([]);
  const [displayImage, setDisplayImage] = useState(null);

  // handleChangePhotos ****************************
  const handleChangePhotos = (e) => {
    for (const file of e.target.files) {
      setChoosenPhotos(prev => [...prev, file]);
    }
    // console.log(choosenPhotos, "e.target.files");
    setPhotosInBackend(false)
  }
  const onUpdateImagesList = imageIndex => {
    let remailImages = clubPhotosUrl.filter((item, index) => index !== imageIndex);
    setClubPhotosUrl(remailImages);
  }
  const onDeleteImagesList = (item) => {
    let newArrayEventPhotos = [...clubPhotos];
    newArrayEventPhotos.splice(item, 1);
    setClubPhotos(newArrayEventPhotos);
  }
  return (
    <Layout path={`/manager/edit-club-details/${localStorage.getItem('club_id')}`}>
      <form className="" onSubmit={handleSubmit(handleFormSubmit)}>
        <ContentWrapper additionalClass="p-5 mb-4">
          <Row className="g-4">
            <Col xl={12}>
              <h5 className="text-2xl font-body font-bold">Club details</h5>
            </Col>
            <Col xl={6} className="font-body">
              <h4 className="text-md font-semibold color-gray mb-2">
                Club name <span style={{ color: "red" }}>*</span>
              </h4>
              <input
                {...register("enterName", {
                  required: "This is required field",
                  pattern:
                  {
                    value: /^(?!\s*$).+/,
                    message: 'Only alphabetic characters are allowed.',
                  },
                })}
                type="text"
                className="po-edit-input mb-7"
                placeholder="Enter name"
                name="club-name"
                {...register("enterName", {
                  required: "This is required field",
                  pattern:
                  {
                    value: /^(?!\s*$).+/,
                    message: 'Only alphabetic characters are allowed.',
                  },
                })}
              />
              <p className="mb-7 text-danger font-body text-sm">
                {errors.enterName?.message}
              </p>
            </Col>

            <Col xs={6} className="font-body">
              <h4 className="text-md font-semibold color-gray mb-2">
                Phone number <span style={{ color: "red" }}>*</span>
              </h4>
              <PhoneInput
                placeholder="Enter phone number"
                defaultCountry="IN"
                className="w-100"
                name="phoneInput"
                value={phoneNumber}
                onChange={(e) => { setPhoneNumber(e); setPhoneNumberError("") }}
              />
              <p className="mb-7 text-danger font-body text-sm">
                {phoneNumberError && <p className="mb-7 text-danger font-body text-sm">Enter a valid phone number</p>}
              </p>
            </Col>
            <Col xl={6} className="font-body">
              <h4 className="text-md font-semibold color-gray mb-2">
                Club manager name <span style={{ color: "red" }}>*</span>
              </h4>
              <input
                type="text"
                className="po-edit-input mb-7"
                placeholder="Enter manager name"
                {...register("enterManagerName", {
                  required: "This is required field",
                  pattern:
                  {
                    value: /^(?!\s*$).+/,
                    message: 'Only alphabetic characters are allowed.',
                  },
                })}
              />
              <p className="v">{errors.enterManagerName?.message}</p>
            </Col>
            <Col xl={6} className="font-body">
              <h4 className="text-md font-semibold color-gray mb-2">
                Manager phone number <span style={{ color: "red" }}>*</span>
              </h4>
              <PhoneInput
                placeholder="Please enter a manager number"

                value={managerPhoneNumber}
                onChange={(e) => { setManagerPhoneNumber(e); setManagerPhoneError("") }}
              />
              {managerPhoneError &&
                <p className="mb-7 text-danger font-body text-sm">
                  Enter a valid phone number
                </p>}
            </Col>
            <Col xl={6} className="font-body">
              <h4 className="text-md font-semibold color-gray mb-2">Email <span style={{ color: "red" }}>*</span></h4>
              <input
                type="text"
                className="po-edit-input mb-7"
                placeholder="Enter email ID"
                {...register("enterEmailId", {
                  required: "This is required field",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "This is not a valid email",
                  },
                })}
              />
              <p className="mb-7 text-danger font-body text-sm">
                {errors.enterEmailId?.message}
              </p>
              <p className=" text-danger font-body text-sm">
                {errors.emailWhiteSpace?.message}
              </p>

            </Col>
            <Col xl={6} className="font-body">
              <h4 className="text-md font-semibold color-gray mb-2">
                Website Link <span style={{ color: "red" }}>*</span>
              </h4>
              <input
                {...register("websiteName", {
                  required: "This is required field",
                  pattern: {
                    value: /^(?!\s*$).+/,
                    message: 'Only alphabetic characters are allowed.',
                  }
                })}
                type="text"
                className="po-edit-input "
                placeholder="Website name"
              />
              <p className="mb-7 text-danger font-body text-sm">
                {errors.websiteName?.message}
              </p>

            </Col>
            <Col xs={6}>
              <h4 className="text-md font-semibold color-gray mb-2">
                Dress code <span style={{ color: "red" }}>*</span>
              </h4>
              <input
                {...register("dresscode", {
                  required: "This is required field",
                  pattern: {
                    value: /^(?!\s*$).+/,
                    message: 'Only alphabetic characters are allowed.',
                  }

                })}
                // value={dressCode}
                type="text"
                className="po-edit-input"
                placeholder="Dress codes"
              // onChange={(e) => {
              //   setDressCode(e.target.value);
              // }}
              /><p className="text-danger font-body text-sm">
                {errors.dresscode?.message}
              </p>
            </Col>
            <Col xl={6} className="font-body">
            </Col>
            <Col xs={6} className="font-body fill-gray">
              {/* <h4 className="text-md font-semibold color-gray mb-2">Club logo</h4> */}
              {/* <CustomFileUploader handleImage={handleLogo} file={fileSucess} /> */}
              <Col xs={6} className="font-body">
                <label className="text-md font-semibold color-gray mb-2">
                  Club logo<sup className="text-danger">*</sup>
                </label>
                <div
                  style={{
                    backgroundImage: `url(${logoPreviewImage ? logoPreviewImage : null})`,
                    border: `${logoPreviewImage ? "0px" : "1px"} dashed #D2D6DA`,
                  }}
                  className="po-updateEvent__inputPhoto d-flex align-items-center justify-content-center background-position-center-top background-repeat-norepeat background-size-cover rounded overflow-hidden position-relative"

                >
                  {logoPreviewImage ? (
                    <div className="position-absolute end-0 top-0">
                      <ActionIcon
                        onClick={() => {
                          setLogoPreviewImage(null);
                          setLogo(null);
                        }}
                        additionalClass="bi bi-x-circle-fill text-3xl color-red me-2"
                      />
                    </div>
                  ) : null}
                  {logoPreviewImage ? null : (
                    <div>
                      <input
                        type="file"
                        id="image"
                        // value={state.display_image}
                        // {...register("display_image", { required: "Image is required" })}
                        onChange={handleLogo}
                        className="opacity-0 position-absolute start-0 end-0 top-0 bottom-0"
                      />
                      <div className="text-center">
                        <i className="bi bi-plus-circle-fill text-5xl color-lightgreen d-block"></i>
                        <p className="text-xs font-semibold font-body text-center color-gray2 ">
                          Upload Image here
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </Col>
              {/* <p className="text-danger font-body text-sm">
                {errors.logo?.message}
              </p> */}
              {imageError && (
                <div>
                  <p className="text-danger font-body text-sm">
                    Please choose the logo
                  </p>
                </div>
              )}
            </Col>
            <Col xs={6} className="font-body">
              <h4 className="text-md font-semibold color-gray mb-2">
                Club photo<sup className="text-danger">*</sup>
              </h4>

              <div
                style={{
                  backgroundImage: `url(${previewImage ? previewImage : null})`,
                  border: `${previewImage ? "0px" : "1px"} dashed #D2D6DA`,
                }}
                className="po-updateEvent__inputPhoto d-flex align-items-center justify-content-center background-position-center-top background-repeat-norepeat background-size-cover rounded overflow-hidden position-relative"
              >

                {previewImage ? (
                  <div className="position-absolute end-0 top-0">
                    <ActionIcon
                      onClick={() => {
                        setPreviewImage(null);
                        setClubPhotos([]);
                      }}
                      additionalClass="bi bi-x-circle-fill text-3xl color-red me-2"
                    />
                  </div>
                ) : null}
                {previewImage ? null : (
                  <div>
                    <input
                      type="file"
                      id="image"
                      onChange={handleClubPhotos}
                      className="opacity-0 position-absolute start-0 end-0 top-0 bottom-0"
                    />
                    <div className="text-center">
                      <i className="bi bi-plus-circle-fill text-5xl color-lightgreen d-block"></i>
                      <p className="text-xs font-semibold font-body text-center color-gray2 ">
                        Upload Image here
                      </p>
                    </div>
                  </div>
                )}
              </div>
              {clubPhotos && clubPhotos.length > 0 && (
                <ul className="d-flex flex-wrap mt-2">
                  {" "}
                  {clubPhotos && clubPhotos.length && clubPhotos.map((item, index) => (
                    <li
                      key={index}
                      className="text-xxs po-updateEvent__inputList-item my-1 d-flex justify-content-between"
                    >
                      {" "}
                      <div
                        key={index}
                        style={{
                          height: "75px",

                          width: "75px",

                          backgroundImage: `url(${URL.createObjectURL(item)})`,
                        }}
                        className="position-relative mt-2 text-end me-2 background-position-center-center background-repeat-norepeat background-size-cover rounded overflow-hidden p-1"
                      >
                        {" "}
                        <div
                          onClick={() => onDeleteImagesList(index)}
                          className="cursor-pointer d-inline-block rounded-circle  d-flex align-items-center justify-content-center position-absolute top-0 end-0 z-2"
                          style={{
                            height: "20px",

                            width: "20px",
                          }}
                        >
                          {" "}
                          <i className="bi d-inline-block cursor-pointer bi bi-x-circle-fill  color-red me-1 mt-1 text-lg"></i>
                          {" "}
                        </div>
                        {" "}
                      </div>
                      {" "}

                      {" "}
                    </li>
                  ))}
                  {" "}
                </ul>
              )}
              {clubPhotosUrl && clubPhotosUrl && clubPhotosUrl.length > 0 && (
                <ul className="d-flex flex-wrap mt-2">
                  {clubPhotosUrl.map((item, index) => (
                    <li
                      key={index}
                      className="text-xxs po-updateEvent__inputList-item my-1 d-flex justify-content-between"
                    >
                      <div
                        key={index}
                        style={{
                          height: "75px",
                          width: "75px",
                          backgroundImage: `url(${item.url})`,
                        }}
                        className="position-relative mt-2 text-end me-2 background-position-center-center background-repeat-norepeat background-size-cover rounded overflow-hidden p-1"
                      >
                        <div
                          onClick={() => onUpdateImagesList(index)}
                          className="cursor-pointer d-inline-block rounded-circle  d-flex align-items-center justify-content-center position-absolute top-0 end-0 z-2"
                          style={{
                            height: "20px",
                            width: "20px",
                          }}
                        >
                          <i className="bi d-inline-block cursor-pointer bi bi-x-circle-fill  color-red me-1 mt-1 text-lg"></i>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              )}

              {photoError && <div><p className="mb-7 text-danger font-body text-sm">Please select club photos </p></div>}
            </Col>
          </Row>
        </ContentWrapper>
        <ContentWrapper additionalClass="p-5 mb-4">
          <Row>
            <Col xs={12} className="font-body">
              <h4 className="text-2xl font-body font-bold">Schedule</h4>
            </Col>
            <Col xs={6} className="font-body">
              <div className="mb-3">
                <Row>
                  <Col xs={12}>
                    <p className="mb-1 text-xl">Monday</p>
                  </Col>
                  <Col xs={6}>
                    <label style={{ fontWeight: "bold" }}>Start time :<sup className="text-danger">*</sup></label>
                    <input
                      type="time"
                      className="po-edit-input"
                      id="appt"
                      name="appt"
                      {...register("monday_start_timing", {
                        required: "This is required field"
                      })}

                    />
                    <p className="mb-7 text-danger font-body text-sm">
                      {errors.monday_start_timing?.message}
                    </p>
                  </Col>
                  <Col xs={6}>
                    <label style={{ fontWeight: "bold" }}>End time :<sup className="text-danger">*</sup></label>
                    <input
                      type="time"
                      className="po-edit-input"
                      id="appt"
                      name="appt"
                      {...register("monday_end_timing", {
                        required: "This is required field"
                      })}
                    />
                    <p className="mb-7 text-danger font-body text-sm">
                      {errors.monday_end_timing?.message}
                    </p>
                  </Col>
                </Row>
                {/* 
                <p className="mb-7 text-danger font-body text-sm">
                  {errors.mondayTimings?.message}
                </p>
                <div></div> */}
              </div>
              <div className="mb-3">
                <Row>
                  <Col xs={12}>
                    <p className="mb-1 text-xl">Tuesday</p>
                  </Col>
                  <Col xs={6}>
                    <label style={{ fontWeight: "bold" }}>Start time :<sup className="text-danger">*</sup></label>
                    <input
                      type="time"
                      className="po-edit-input"
                      id="appt"
                      name="appt"
                      {...register("tuesday_start_timing", {
                        required: "This is required field"
                      })}
                    />
                    <p className="mb-7 text-danger font-body text-sm">
                      {errors.tuesday_start_timing?.message}
                    </p>
                  </Col>
                  <Col xs={6}>
                    <label style={{ fontWeight: "bold" }}>End time :<sup className="text-danger">*</sup></label>
                    <input
                      type="time"
                      className="po-edit-input"
                      id="appt"
                      name="appt"
                      {...register("tuesday_end_timing", {
                        required: "This is required field"
                      })}
                    />
                    <p className="mb-7 text-danger font-body text-sm">
                      {errors.tuesday_end_timing?.message}
                    </p>
                  </Col>
                </Row>
              </div>

              <div className="mb-3">
                <Row>
                  <Col xs={12}>
                    <p className="mb-1 text-xl">Wednesday</p>
                  </Col>
                  <Col xs={6}>
                    <label style={{ fontWeight: "bold" }}>Start time :<sup className="text-danger">*</sup></label>
                    <input
                      type="time"
                      className="po-edit-input"
                      id="appt"
                      name="appt"
                      {...register("wednesday_start_timing", {
                        required: "This is required field"
                      })}
                    />
                    <p className="mb-7 text-danger font-body text-sm">
                      {errors.wednesday_start_timing?.message}
                    </p>
                  </Col>
                  <Col xs={6}>
                    <label style={{ fontWeight: "bold" }}>End time :<sup className="text-danger">*</sup></label>
                    <input
                      type="time"
                      id="appt"
                      className="po-edit-input"
                      name="appt"
                      {...register("wednesday_end_timing", {
                        required: "This is required field"
                      })}
                    />
                    <p className="mb-7 text-danger font-body text-sm">
                      {errors.wednesday_end_timing?.message}
                    </p>
                  </Col>
                </Row>
              </div>

              <div className="mb-3">
                <Row>
                  <Col xs={12}>
                    <p className="mb-1 text-xl">Thursday</p>
                  </Col>
                  <Col xs={6}>
                    <label style={{ fontWeight: "bold" }}>Start time :<sup className="text-danger">*</sup></label>
                    <input
                      type="time"
                      className="po-edit-input"
                      id="appt"
                      name="appt"
                      {...register("thursday_start_timing", {
                        required: "This is required field"
                      })}
                    />
                    <p className="mb-7 text-danger font-body text-sm">
                      {errors.thursday_start_timing?.message}
                    </p>
                  </Col>
                  <Col xs={6}>
                    <label style={{ fontWeight: "bold" }}>End time :<sup className="text-danger">*</sup></label>
                    <input
                      type="time"
                      className="po-edit-input"
                      id="appt"
                      name="appt"
                      {...register("thursday_end_timing", {
                        required: "This is required field"
                      })}
                    />
                    <p className="mb-7 text-danger font-body text-sm">
                      {errors.thursday_end_timing?.message}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={6} className="font-body">
              <div className="mb-3">
                <Row>
                  <Col xs={12}>
                    <p className="mb-1 text-xl">Friday</p>
                  </Col>
                  <Col xs={6}>
                    <label style={{ fontWeight: "bold" }}>Start time :<sup className="text-danger">*</sup></label>
                    <input
                      type="time"
                      className="po-edit-input"
                      id="appt"
                      name="appt"

                      {...register("friday_start_timing", {
                        required: "This is required field"
                      })}
                    />
                    <p className="mb-7 text-danger font-body text-sm">
                      {errors.friday_start_timing?.message}
                    </p>
                  </Col>
                  <Col xs={6}>
                    <label style={{ fontWeight: "bold" }}>End time :<sup className="text-danger">*</sup></label>
                    <input
                      type="time"
                      id="appt"
                      className="po-edit-input"
                      name="appt"

                      {...register("friday_end_timing", {
                        required: "This is required field"
                      })}
                    />
                    <p className="mb-7 text-danger font-body text-sm">
                      {errors.friday_end_timing?.message}
                    </p>
                  </Col>
                </Row>
              </div>
              <div className="mb-3">
                <Row>
                  <Col xs={12}>
                    <p className="mb-1 text-xl">Saturday</p>
                  </Col>
                  <Col xs={6}>
                    <label style={{ fontWeight: "bold" }}>Start time :<sup className="text-danger">*</sup></label>
                    <input
                      type="time"
                      id="appt"
                      className="po-edit-input"
                      name="appt"

                      {...register("saturday_start_timing", {
                        required: "This is required field"
                      })}
                    />
                    <p className="mb-7 text-danger font-body text-sm">
                      {errors.saturday_start_timing?.message}
                    </p>
                  </Col>
                  <Col xs={6}>
                    <label style={{ fontWeight: "bold" }}>End time :<sup className="text-danger">*</sup></label>
                    <input
                      type="time"
                      id="appt"
                      name="appt"
                      className="po-edit-input"


                      {...register("saturday_end_timing", {
                        required: "This is required field"
                      })}
                    />
                    <p className="mb-7 text-danger font-body text-sm">
                      {errors.saturday_end_timing?.message}
                    </p>
                  </Col>
                </Row>
              </div>

              <div className="mb-3">
                <Row>
                  <Col xs={12}>
                    <p className="mb-1 text-xl">Sunday</p>
                  </Col>
                  <Col xs={6}>
                    <label style={{ fontWeight: "bold" }}>Start time :<sup className="text-danger">*</sup></label>
                    <input
                      type="time"
                      id="appt"
                      name="appt"
                      className="po-edit-input"

                      {...register("sunday_start_timing", {
                        required: "This is required field"
                      })}
                    />
                    <p className="mb-7 text-danger font-body text-sm">
                      {errors.sunday_start_timing?.message}
                    </p>
                  </Col>
                  <Col xs={6}>
                    <label style={{ fontWeight: "bold" }}>End time :<sup className="text-danger">*</sup></label>
                    <input
                      type="time"
                      id="appt"
                      name="appt"
                      className="po-edit-input"

                      {...register("sunday_end_timing", {
                        required: "This is required field"
                      })}
                    />
                    <p className="mb-7 text-danger font-body text-sm">
                      {errors.sunday_end_timing?.message}
                    </p>
                  </Col>
                </Row>

              </div>
            </Col>
          </Row>
        </ContentWrapper>
        <ContentWrapper additionalClass="p-5 mb-4">
          <Row>
              <h4 className="text-2xl font-bold text-black">Club location</h4>
            <Col xs={6} className="font-body">
              <div>
                <h4 className="text-lg  font-medium color-gray mb-2">
                  Country
                  <sup className="text-danger">*</sup>
                </h4>
                <select
                  value={countryId}
                  name="state"
                  id="state"
                  className="po-edit-input"
                  placeholder="Enter Manager Name"
                  onChange={handleCountry}

                >
                  {countries &&
                    countries.map((country, index) => {
                      return <option value={country.data().id} key={index}>{country.data().name}</option>;
                    })}
                </select>
                <p className="mb-7 text-danger font-body text-sm">
                  {errors.selectState?.message}
                </p>
              </div>
              <div>
                <h4 className="text-lg  font-medium color-gray mb-2">
                  State
                  <sup className="text-danger">*</sup>
                </h4>
                <select
                  name="state"
                  id="state"
                  className="po-edit-input"
                  placeholder="Enter Manager Name"
                  value={stateId}
                  onChange={handleState}
                >
                  {states &&
                    states.map((element, index) => {

                      return (<option value={element.data().id} key={index}>{element.data().name}</option>);
                    })}
                </select>
                <p className="mb-7 text-danger font-body text-sm">
                  {errors.selectZip?.message}
                </p>
              </div>
            </Col>
            <Col xs={6} className="font-body">
              <div>
                <h4 className="text-lg  font-medium color-gray mb-2">
                  City
                  <sup className="text-danger">*</sup>
                </h4>
                <select
                  name="state"
                  id="state"
                  className="po-edit-input mb-7"
                  placeholder="Enter Manager Name"
                  value={cityId}
                  onChange={(e) => {
                    e.target.options &&
                      setCity(e.target.options[e.target.selectedIndex].text);
                    setCityId(e.target.value);
                  }}
                >
                  {cities &&
                    cities.map((ele, index) => {
                      return <option value={ele.data().id} key={index}>{ele.data().name}</option>;
                    })}
                </select>
              </div>
              <div>
                <h4 className="text-lg font-medium color-gray mb-2">
                  Full address
                  <sup className="text-danger">*</sup>
                </h4>
                <input
                  type="text"
                  className="po-edit-input"
                  placeholder="Enter full address"
                  {...register("enterFullAddress", {
                    required: "This is required field",
                  })}

                />
                <p className="mb-7 text-danger font-body text-sm">
                  {errors.enterFullAddress?.message}
                </p>
              </div>
            </Col>
          </Row>
        </ContentWrapper>
        <ContentWrapper additionalClass="p-5 mb-4">
          <Row>
            <Col xs={6}>
              <h4 className="text-2xl  font-bold text-black">Add table details</h4>
              {tableError && <div><p className="mb-7 text-danger font-body text-sm">Add atleast one table</p></div>}
              <label className="font-body text-md">Table name<sup className="text-danger">*</sup></label>
              <input className="po-edit-input" value={tableDetail.name} onChange={(e) => {
                setTableDetailError({ ...tableDetailError, name: false })
                setTableDetails({ ...tableDetail, name: e.target.value })
              }} />
              <div><p className="mb-7 text-danger font-body text-sm">{tableDetailError.name && "This is required field"}</p></div>
              <label className="font-body text-md mt-3">Seater count <sup className="text-danger">*</sup></label>
              <input className="po-edit-input " value={tableDetail.seater_count}
                onChange={(e) => {

                  let numberRegex = /^-?\d+(\.\d+)?$/;
                  if (!numberRegex.test(e.target.value)) {
                    setTableDetailError({
                      ...tableDetailError,
                      seater_count_validation: true,
                    });
                    setTableDetails({ ...tableDetail, seater_count: "" });
                  }
                  else {
                    setTableDetails({
                      ...tableDetail,
                      seater_count: Number(e.target.value),
                    });
                    setTableDetailError({
                      ...tableDetailError,
                      seater_count: false,
                    });
                    setTableDetailError({
                      ...tableDetailError,
                      seater_count_validation: false,
                    });
                  }

                }} />
              {tableDetailError.seater_count &&
                <div><p className="mb-7 text-danger font-body text-sm">{tableDetailError.seater_count && "This is required field"}</p></div>
              }
              <div>
                <p className="  text-danger font-body text-sm">
                  {tableDetailError.seater_count_validation && "Please enter a number"}
                </p>
              </div>
              <label className="font-body text-md mt-3">No of table<sup className="text-danger">*</sup></label>
              <input className="po-edit-input" value={tableDetail.count}
                onChange={(e) => {
                  let numberRegex = /^-?\d+(\.\d+)?$/;
                  if (!numberRegex.test(e.target.value)) {
                    setTableDetailError({
                      ...tableDetailError,
                      count_validation: true,
                    });
                    setTableDetails({ ...tableDetail, count: "" });
                  }
                  else {
                    setTableDetails({
                      ...tableDetail,
                      count: Number(e.target.value),
                    });
                    setTableDetailError({ ...tableDetailError, count: false });
                    setTableDetailError({
                      ...tableDetailError,
                      count_validation: false,
                    });
                  }
                }} />
              {tableDetailError.count &&
                <div><p style={{ color: "red" }}>{tableDetailError.count && "This is required field"}</p></div>
              }
              <div>
                <p className="  text-danger font-body text-sm">
                  {tableDetailError.count_validation && "Please enter a number"}
                </p>
              </div>
              <label className="font-body text-md mt-3">Minimum spent<sup className="text-danger">*</sup></label>
              <input className="po-edit-input" value={tableDetail.minimum_spent}
                onChange={(e) => {
                  let numberRegex = /^-?\d+(\.\d+)?$/;
                  if (!numberRegex.test(e.target.value)) {
                    setTableDetailError({
                      ...tableDetailError,
                      minimum_spent_validation: true,
                    });
                    setTableDetails({ ...tableDetail, minimum_spent: "" });
                  }
                  else {
                    setTableDetails({
                      ...tableDetail,
                      minimum_spent: Number(e.target.value),
                    });
                    setTableDetailError({
                      ...tableDetailError,
                      minimum_spent: false,
                    });
                    setTableDetailError({
                      ...tableDetailError,
                      minimum_spent_validation: false,
                    });
                  }
                }
                } />
              {tableDetailError.minimum_spent &&
                <div><p style={{ color: "red" }}>{tableDetailError.minimum_spent && "This is required field"}</p></div>
              }
              <div>
                <p className="  text-danger font-body text-sm">
                  {tableDetailError.minimum_spent_validation && "Please enter a number"}
                </p>
              </div>
              <div className="text-end mt-5 font-body">
                <Button className="color-black1 text-md font-body font-semibold cursor-pointer po-border po-border-yellow1 py-3 px-3 radius-105 d-inline-block bg-transparent text-dark" onClick={() => {
                  handleTableDetails();
                }} name="Add Table Data">
                  Add table data
                </Button>
                {/* <SecondaryButton onClick={() => {
                  handleTableDetails();
                }} name="Add Table Data">

                </SecondaryButton> */}

              </div>
            </Col>

            <Col>
              <h4 className="text-2xl  font-bold text-black">Table data<sup className="text-danger">*</sup></h4>
              {tableDetailsArray && tableDetailsArray.length > 0 && tableDetailsArray.map((ele, index) => {

                return (
                  <div className="shadow-sm p-3 rounded font-body mb-4" key={index}>
                    <div className="text-end">
                      <ActionIcon additionalClass="text-danger text-xl bi-x-circle-fill" onClick={() => {
                        let temp = tableDetailsArray;
                        temp.splice(index, 1)
                        setTableDetailsArray([...temp])
                      }} />
                    </div>
                    <ul >
                      <li><span className="text-md font-semibold">Name :</span> {ele && ele.name}</li>
                      <li><span className="text-md font-semibold">Seater count :</span> {ele && ele.seater_count}</li>
                      <li><span className="text-md font-semibold">Table count :</span> {ele && ele.count}</li>
                      <li><span className="text-md font-semibold">Minimum spent :</span> {ele && ele.minimum_spent}</li>
                    </ul>
                  </div>

                )
              })

              }
            </Col>
          </Row>
        </ContentWrapper>
        <ContentWrapper additionalClass="p-5 mb-4">
            <Row>
              <Col xs={6}>
                <h5 className="text-2xl font-body font-bold mt-6 mb-3">Table slots</h5>
                {tableDetailError.tableList && <p style={{ color: "red" }}>Atleast one slot required</p>}
                <Col xs={6}>
                  <label style={{ fontWeight: "bold" }}>Start time :</label>
                  <input
                    type="time"
                    className="po-edit-input"
                    id="appt"
                    name="appt"
                    value={tableEntities.startTime}
                    onChange={(e) => {
                      setTableEntities({ ...tableEntities, startTime: e.target.value });
                      setTableEntitiesError({
                        ...tableEntitiesError,
                        startTime: false,
                      });
                    }}

                  // onChange={(e) => {
                  //   setSchedules({
                  //     ...schedules,
                  //     Monday: {
                  //       ...schedules.Monday,
                  //       to_time: e.target.value,
                  //     },
                  //   });
                  // }}
                  // value={schedules.Monday.to_time}

                  />
                  <div>
                    <p style={{ color: "red" }}>
                      {tableEntitiesError.startTime && "This is required field"}
                    </p>
                  </div>

                </Col>

                <Button className="color-black1 text-md font-body font-semibold cursor-pointer po-border po-border-yellow1 py-3 px-3 radius-105 d-inline-block bg-transparent text-dark" onClick={() => {
                  handleTableEntities();
                }} name="Add entity">
                  Add Table Entity
                </Button>
              </Col>
              <Col xs={6}>
                <h5 className="text-2xl font-body font-bold mt-6 mb-3">Table slots details <sup className="text-danger">*</sup></h5>
                {tableEntitiesData &&
                  tableEntitiesData.length > 0 &&
                  tableEntitiesData.map((ele, index) => {
                    return (
                      <div
                        className="shadow-sm p-3 rounded font-body mb-4"
                        key={index}
                      >
                        <div className="text-end">
                          <ActionIcon
                            additionalClass="text-danger text-xl bi-x-circle-fill"
                            onClick={() => {
                              let temp = tableEntitiesData;
                              temp.splice(index, 1);
                              setTableEntitiesData([...temp]);
                            }}
                          />
                        </div>
                        <ul>
                          <li>
                            <span className="text-md font-semibold">Slot time :</span>{" "}
                            {`${ele.slot_time} ${ele.zone}`}
                          </li>
                        </ul>
                      </div>
                    );
                  })}
              </Col>
            </Row>
            <Row>
                {/* <div>
                  <label className="text-md font-bold">is Featured</label>
                  <input
                    type='checkbox'
                    placeholder='isFeatured'
                    {...register('isFeatured', {})}
                    className='ml-4'
                  />
                </div> */}
                {/* onClick={() => {
                    navigate("/clubs") */}
                <Col xs={12} className="d-flex justify-content-end mb-6">
                  <OutlinedButton
                    link="/manager"
                    name="Cancel" additionalClass="me-3" />
                  <SuccessButton name="Update Club" type="submit" icon="bi-check-circle" />

                </Col>
                {/* <Col>
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                    label="isFeatured"
                    className="font-bold mb-6"
                    // value={isFeatured}
                    checked={isFeatured}
                    onClick={(e) => {
                      // console.log(e.target.value, "form.chek");
                      setIsFeatured(e.target.value)
                    }}
                    style={{ height: "100px", width: "50px" }}
                  />
                </Col> */}
            </Row>
          </ContentWrapper>
        </form>
      <Toaster
        showToaster={showToaster}
        setShowToaster={setShowToaster}
        bg={toasterDetails.bg}
        title={toasterDetails.title}
        bodyText={toasterDetails.bodyText}
        statusIcon={toasterDetails.statusIcon}
      />
    </Layout>
  );
};

function validateFile(file) {
  const allowedTypes = ["image/png", "image/jpeg"];
  if (!allowedTypes.includes(file[0].type)) {
    return "Invalid file format";
  }
  return true;
}

export default EditClub;
