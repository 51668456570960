import moment from 'moment';
import { useEffect, useState } from 'react';
import ContentWrapper from '../Components/Global/ContentWrapper';
import PageDescription from '../Components/Global/PageDescription';
import Layout from '../Layout/Layout';

const Payments = () => {
  const [showSubList, setShowSubList] = useState(false);
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    fetch('http://localhost:3001/payments')
      .then(res => res.json())
      .then(result => {
        setTableData(result)
      })
      .catch(err => console.log(err));
  }, [])

  const filterPayment = (key) => {
    
    fetch('http://localhost:3001/payments')
      .then(res => res.json())
      .then(result => {
        const filteredArray = key && result.filter(item => item.status === key);
        key && setTableData(filteredArray);
        !key && setTableData(result);
      })
      .catch(err => console.log(err));
  }

  return (
    <Layout>
      <PageDescription
        title="Showing All Payment Requests" 
        caption="Never miss a beat! Keep track of all payment requests for your party destinations with just a tap!"/>
      <ContentWrapper additionalClass="mt-2 py-6 overflow-hidden">
        <div className='d-flex justify-content-between align-items-center'>
          <h4 
            className='po-table__title px-8 font-body text-md font-semibold'>
            Showing All Payment Requests
          </h4>
          <div className='d-flex justify-content-center align-items-center px-12'>
            <span className='po-table__title font-body text-sm font-semibold flex-shrink-0'>
                Sort by :
            </span>
            <div className='po-sort ml-3 position-relative cursor-pointer flex-shrink-0' 
              onClick={() => setShowSubList(prev => !prev)}>
              <span className='color-app text-sm font-semibold'>
                All Requests
                <img 
                  src='/assets/icons/caret-down-dark.png' 
                  alt='caret down' 
                  width={12} 
                  className="ml-3"
                />
              </span>
              <ul 
                className={`po-sort__lists m-0 position-absolute zIndex__1 text-xxs p-2 bg-white font-body font-semibold ${showSubList ? "list-visible": "" }`}>
                <li className='po-sort__list py-2' onClick={() => filterPayment()}>All Requests</li>
                <li className='po-sort__list py-2' onClick={() => filterPayment("Pending")}>Pending</li>
                <li className='po-sort__list py-2' onClick={() => filterPayment("Paid")}>Paid</li>
                <li className='po-sort__list py-2' onClick={() => filterPayment("Link Send")}>Link Send</li>
              </ul>
            </div>
          </div>
        </div>
        <div className='po-table__container-outer'>
          <div className='po-table__container mt-5'>
            <div className='po-table__headingPayment d-grid px-8 py-2 align-items-center font-body gap-3'>
              <button 
                className='po-table__heading-name text-xs font-semibold text-left d-flex align-items-center gap-1 bg-transparent border-0 px-0'
              //   onClick={() =>sortingHandler('clubName')}
              >
                USER
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" fill="currentColor" className="bi bi-chevron-down d-block po-rotate--180" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" fill="currentColor" className="bi bi-chevron-down d-block" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </span>
              </button>
              <button 
                className='po-table__heading-name text-xs font-semibold text-left d-flex align-items-center gap-1 bg-transparent border-0 px-0'
              //   onClick={() =>sortingHandler('owner')}
              >
                BOOKING ID
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" fill="currentColor" className="bi bi-chevron-down d-block po-rotate--180" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" fill="currentColor" className="bi bi-chevron-down d-block" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </span>  
              </button>
              <button 
                className='po-table__heading-name text-xs font-semibold text-left d-flex align-items-center gap-1 bg-transparent border-0 px-0'
              //   onClick={() =>sortingHandler('location')}
              >
                CLUB NAME
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" fill="currentColor" className="bi bi-chevron-down d-block po-rotate--180" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" fill="currentColor" className="bi bi-chevron-down d-block" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </span>
              </button>
              <button 
                className='po-table__heading-name text-xs font-semibold text-left d-flex align-items-center gap-1 bg-transparent border-0 px-0'
              //   onClick={() =>sortingHandler('createdOn')}
              >
                LOCATION
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" fill="currentColor" className="bi bi-chevron-down d-block po-rotate--180" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" fill="currentColor" className="bi bi-chevron-down d-block" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </span>
              </button>
              <button 
                className='po-table__heading-name text-xs font-semibold text-left d-flex align-items-center gap-1 bg-transparent border-0 px-0'
              //   onClick={() =>sortingHandler('createdOn')}
              >
                REQUESTED ON
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" fill="currentColor" className="bi bi-chevron-down d-block po-rotate--180" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" fill="currentColor" className="bi bi-chevron-down d-block" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </span>
              </button>
              <button 
                className='po-table__heading-name text-xs font-semibold text-left d-flex align-items-center gap-1 bg-transparent border-0 px-0'
              //   onClick={() =>sortingHandler('createdOn')}
              >
                STATUS
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" fill="currentColor" className="bi bi-chevron-down d-block po-rotate--180" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" fill="currentColor" className="bi bi-chevron-down d-block" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </span>
              </button>
              <span></span>
            </div>
            <div className={`po-table__body ${tableData ? "list-visible": ''}`}>
              {tableData &&
                tableData.map((item)=> {
                  return (
                    <div className={`po-table__body-itemPayment px-8 py-4 d-grid border-1 border-bottom gap-3`} key={item.id}>
                      <div className='text-sm font-semibold text-left d-flex align-items-center gap-2'>
                          <img src={item.thumbnail} alt={item.title} width="40" className='rounded' />
                          <div className='d-flex flex-column'>
                              <span className='text-sm font-body'>{item.title}</span>
                              <span className='text-xxs po-word-break font-body'>{item.email}</span>
                          </div>
                      </div>
                      <span className='text-sm font-semibold text-left d-flex align-items-center font-body'>
                        {item.booking_id}
                      </span>
                      <span className='text-sm font-semibold text-left d-flex align-items-center font-body'>
                        {item.club_name}
                      </span>
                      <span className='text-sm font-semibold text-left d-flex align-items-center font-body'>
                        {item.location}
                      </span>
                      <span className='text-sm font-semibold text-left d-flex align-items-center font-body'>
                          {moment(item.requested_on).format("DD/MM/YYYY")}
                      </span>
                      <div className='d-flex justify-content-between align-items-center gap-4 '>
                          {item.status && <span 
                            className={`po-table__status text-xxs font-semibold px-2 py-1 rounded-pill text-center font-body ${item.status==="Pending" ? "color-golden background-lightpink" : item.status==="Link Send" ? "color-golden background-lightyellow" : "color-green background-lightgreen1" }`}>
                            {item.status}
                          </span>}
                          <button 
                            type='button' 
                            className='bg-transparent py-2 px-4 rounded-2 font-body'>
                            View
                          </button>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </ContentWrapper>
    </Layout>
  )
}

export default Payments;