import React, { useState, useEffect } from 'react';
import ContentWrapper from "../ContentWrapper";
import ReactApexChart from "react-apexcharts";
import DateRange from "../DateRange";

const GuestEntries = ({ setSelectedDateRange, guestStartDate, guestEndDate, guest }) => {
  const [state, setState] = useState({
    male: [],
    female: [],
    others: [],
    months: [],
  })
  useEffect(() => {
    let male = [];
    let female = [];
    let others = [];
    let months = []
    guest.length > 0 && guest.forEach(data => {
      male.push(data.Male);
      female.push(data.Female);
      others.push(data.Other);
      months.push(data.month_name);
    })
    setState((prevProps) => ({
      ...prevProps,
      male: male,
      female: female,
      others: others,
      months: months,
    }))
  }, [guest]);

  const chartData = {
    options: {
      colors: ["#444e86", "#955196", "#dd5182"],
      chart: {
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          columnWidth: "70%",
        },
      },
      dataLabels: {
        total: {
          enabled: true,
          style: {
            fontSize: '13px',
            fontWeight: 900
          }
        }
      },
      xaxis: {
        categories: state.months
      },
    },
    series: [
      {
        name: "Others",
        data: state.others
      },
      {
        name: "Males",
        data: state.male
      },
      {
        name: "Females",
        data: state.female
      },
    ],
  };
  const getDateRange = (start, end) => {
    setSelectedDateRange(start, end, "guest");
  }
  return (
    <ContentWrapper additionalClass="p-5">
      <div className="d-flex justify-content-between font-body">
        <div>
          <h2 className="text-md text-xl-lg font-bold color-blue font-body mb-0">
            Guest Entries
          </h2>
          {/* <p className="text-xs text-xl-sm"> Menu Preferences: 200</p> */}
        </div>

        <div>
          <DateRange
            getDateRange={getDateRange}
            startDate={guestStartDate}
            endDate={guestEndDate}
          />
        </div>
      </div>
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        height={350}
      />
    </ContentWrapper>
  );
};
export default GuestEntries;
