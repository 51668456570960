import { useState, useEffect } from "react";
import { Col, Modal, ModalBody, ModalHeader } from "react-bootstrap";
import Layout from "../../Layout/Layout";
import ContentWrapper from "../../Components/Global/ContentWrapper";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import {
  collection,
  getDocs,
  serverTimestamp,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import db from "../../firebase";
import OutlinedButton from "../../Components/Global/Buttons/OutlinedButton";
import SuccessButton from "../../Components/Global/Buttons/SuccessButton";
import Toaster from "../../Components/Global/Toast";
import ActionIcon from "../../Components/Global/Buttons/ActionIcons";
import axios from "axios";
import SecondaryButton from "../../Components/Global/Buttons/SecondaryButton";
const EditNotifications = () => {
  // Form states
  const [previewImage, setPreviewImage] = useState(null);
  const [imageDeleteModal, setimageDeleteModal] = useState(false);
  const [image, setImage] = useState({});
  const [clubData, setClubData] = useState([]);
  const [eventsData, setEventsData] = useState([]);
  const [categoryValues, setCategoryValues] = useState([]);
  const [categoryValue, setCategoryValue] = useState("");
  const [isInternal, setIsInternal] = useState(null);
  const [searchItem, setSearchItem] = useState("");
  const [notificationId, setNotificationId] = useState();
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [selectedCities, setSelectedCities] = useState([])
  const [selectedCategoryType, setSelectedCategoryType] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    setValue,
  } = useForm();
  const [showToaster, setShowToaster] = useState(false);
  const [toasterDetails, setToasterDetails] = useState({
    title: "",
    bodyText: "",
    bg: "",
    statusIcon: "",
  });
  // const location = useLocation();
  const { notification_id } = useParams();
  const navigate = useNavigate();
  const id = notification_id;
  // let timer = null;
  useEffect(() => {
    getDoc(doc(db, "notifications_campaign", notification_id)).then(
      async (res) => {
        await getAllData(res.data().notification_type);
        setPreviewImage(res.data().display_image);
        setImage(res.data().display_image)
        setNotificationId(res.data().id);
        setSelectedCategoryType(res.data().notification_type);
        setIsInternal(res.data().internal_redirect);
        setSelectedCities(res.data().cities);
        setCategoryValue(res.data().notification_category_id)
        reset((register) => ({
          ...register,
          body: res.data().body,
          title: res.data().title,
          url: res.data().internal_redirect == false ? res.data().url : "",
          internalRedirect:
            res.data().internal_redirect === true ? "YES" : "NO",
          category: res.data().notification_type === "Club" ? "Club" : "Event",
          category_value: res.data().notification_category_id,
        }));
      }
    );
  }, [id, setValue, notification_id]);
  const getAllData = async (type) => {
    try {
      const clubsResponse = await getDocs(collection(db, "clubs"));
      clubsResponse && setClubData(clubsResponse.docs);
      const eventResponse = await getDocs(collection(db, "events"));
      eventResponse && setEventsData(eventResponse.docs);
      if (type === "Club") {
        setCategoryValues(clubsResponse.docs);
      } else if (type === "Event") {
        setCategoryValues(eventResponse.docs);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Cities

  const citiesOnChange = async (e) => {
    const inputValue = e.target.value;
    setSearchItem(inputValue);
    setError("cities", {
      type: "manual",
      message: ""
    })
  };

  //Changing First letter Capital
  function capitalizeFirstLetter(inputString) {
    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
  }


  const citiesOnClick = (value) => {
    let cityDetails = citiesOptions.find(c => c.id === value);
    let duplicateValidation = selectedCities.find(citee => capitalizeFirstLetter(citee.city) === cityDetails.name);
    if (duplicateValidation) {
      setSearchItem("");
      setCitiesOptions([]);
      setError("cities", {
        type: "manual",
        message: "This city already exists"
      })
      return;
    }
    let previousCities = selectedCities;
    previousCities.push({
      city: cityDetails.name.toLowerCase(),
      country: cityDetails.country_name.toLowerCase()
    });
    setError("cities", null)
    setSelectedCities(previousCities);
    setSearchItem("")
    setCitiesOptions([]);
  }
  // Cities
  useEffect(() => {
    // Fetch suggestions from the API when 'inputValue' changes
    let timer = setTimeout(() => {
      if (searchItem !== "") {
        fetch(`${process.env.REACT_APP_API_URL}/location/v1/allCities?name=${searchItem}`)
          .then((response) => response.json())
          .then((data) => {
            setCitiesOptions(data.data); // Assuming the API response is an array of suggestions
          })
          .catch((error) => {
            console.error('Error fetching suggestions:', error);
          });
      } else {
        setCitiesOptions([]); // Clear suggestions when the input is empty
      }
    }, 300
    )
    return () => clearTimeout(timer);
  }, [searchItem]);

  const onSubmit = async (data) => {
    setShowToaster(true);
    setToasterDetails((prev) => ({
      ...prev,
      title: "Updating...",
      bodyText: "Updating push notification",
      bg: "warning",
      statusIcon: (
        <div className="spinner-border text-warning me-2" role="status"></div>
      ),
    }));
    let imageUrl = typeof image === "object" ? await insertingImage(image) : image;
    let url =
      selectedCategoryType === "Club"
        ? `clubs/${categoryValue}`
        : selectedCategoryType === "Event"
          ? `events/${categoryValue}`
          : data.url;
    let payload = null;
    if (isInternal) {
      payload = {
        // id: uuidv4(),
        title: data.title,
        body: data.body,
        updatedAt: serverTimestamp(),
        internal_redirect: isInternal,
        // read_status:data.readStatus === "YES" ? true : false,
        url:
          selectedCategoryType === "Club"
            ? `clubs/${categoryValue}`
            : selectedCategoryType === "Event"
              ? `events/${categoryValue}`
              : null,
        notification_category_id: categoryValue,
        notification_type: selectedCategoryType,
        cities: selectedCities,
        display_image: imageUrl
      };
    } else {
      payload = {
        // id: uuidv4(),
        title: data.title,
        body: data.body,
        updatedAt: serverTimestamp(),
        internal_redirect: isInternal,
        // read_status:data.readStatus === "YES" ? true : false,
        url: data.url,
        notification_category_id: null,
        notification_type: null,
        cities: selectedCities,
        display_image: imageUrl
      };

    }

    try {
      const jwtToken = localStorage.getItem("authenticate");

      const notificationResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/notification/v2/notifications`,
        {
          body: data.body,
          title: data.title,
          url: url,
          internal_redirect: data.internalRedirect === "YES",
          cities: selectedCities
        },
        {
          headers: {
            developement: true,
            token: jwtToken,
          },
        }
      );

      const docRef = await updateDoc(
        doc(db, "notifications_campaign", id),
        payload
      )

      setToasterDetails((prev) => ({
        ...prev,
        title: "Success",
        bodyText: "Push notification updated successfully",
        bg: "success",
        statusIcon: <i className="bi bi-check text-success text-3xl me-2"></i>,
      }));

      setTimeout(() => {
        navigate(`/notifications`, { replace: true });
      }, 2000);
    } catch (error) {
      console.error("Error:", error);

      setToasterDetails((prev) => ({
        ...prev,
        title: "Error",
        bodyText: error && error.response && error.response.data && error.response.data.message,
        bg: "danger",
        statusIcon: (
          <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
        ),
      }));
      setTimeout(() => {
        setShowToaster(false);
      }, 2000)
      if (error.response) {
        console.log(error.response.data.message, "Error Notification");
      }
    }

    // reset();
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
      console.log(file, "its coming");
      setImage(file);
    }
  };
  //Uploading image
  const insertingImage = async (imageData) => {
    let form = new FormData();
    form.append("images", imageData);
    let responseImage = await axios
      .post(
        `${process.env.REACT_APP_MULTIPLEIMAGES_POSTING_URL}/uploads/uploadImages`,
        form,
        { headers: { "Content-Type": "multipart/form-data" } }
      )
      .then((resImage) => {
        return resImage && resImage.data.url[0];
      })
      .catch((error) => console.log(error, "err"));
    return responseImage;
  };
  return (
    <Layout path="/notifications/edit-notifications">
      {/* <Link to="/notifications">
            <div  className="d-flex align-items-center mb-5">
               <div>
                <img
                  src="/assets/icons/sidebar/dark/campaigns-icon-dark.png"
                  alt="dashboard-icon"
                  width="15"
                  height="15"
                  className="d-block"
                />
              </div>

            <div className="ms-2 text-sm text-lg-md font-body font-semibold text-black">
            Notifications
              </div>
               <div className="ms-2 text-xs text-lg-sm font-body font-regular text-black">
               / Edit
              </div>
            </div>
            </Link> */}
      <ContentWrapper additionalClass="p-5 font-body">
        <form className="row g-4" onSubmit={handleSubmit(onSubmit)}>

          <Col xs={12}>
            <label htmlFor="cityImage">Campaign Image</label>
            <div
              style={{
                height: "200px",
                width: "350px",
                backgroundImage: `url(${previewImage ? previewImage : null})`,
                border: `${previewImage ? "0px" : "1px"} dashed #D2D6DA`,
              }}
              className="d-flex align-items-center justify-content-center background-position-center-top background-repeat-norepeat background-size-cover rounded overflow-hidden position-relative"
            >
              {previewImage ? (
                <div className="position-absolute end-0 top-0">
                  <ActionIcon
                    onClick={() => setimageDeleteModal(true)}
                    additionalClass="bi bi-x-circle-fill text-3xl color-red me-2"
                  />
                </div>
              ) : null}

              {previewImage ? null : (
                <div>
                  <input
                    {
                    ...register("display_image", {
                      required: "Campaign image is required"
                    })
                    }
                    accept="image/*"
                    type="file"
                    id="display_image"
                    onChange={handleImageChange}
                    className="opacity-0 position-absolute start-0 end-0 top-0 bottom-0"
                  />

                  <div className="text-center">
                    <i className="bi bi-plus-circle-fill text-5xl color-lightgreen d-block"></i>
                    <p className="text-md font-body text-center  ">
                      Upload your Image here.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </Col>
          {errors.display_image &&
            <p className="text-sm text-danger mt-1">{errors.display_image.message}</p>
          }

          {/* Title */}
          <Col md={6}>
            <label className="text-md mb-1" htmlFor="title">
              Title <span className="text-danger">*</span>
            </label>
            <input
              className="po-edit-input"
              {...register("title", {
                required: "Title is required",
                pattern: {
                  value: /^(?!\s*$).+/,
                  message: "Only alphabetic characters are allowed.",
                },
              })}
            />

            {errors.title && (
              <p className="text-sm text-danger mt-1 font-body">
                {errors.title.message}
              </p>
            )}
          </Col>

          {/* Body */}
          <Col md={6}>
            <label className="text-md mb-1" htmlFor="body">
              Body <span className="text-danger">*</span>
            </label>
            <textarea
              className="po-edit-input"
              {...register("body", {
                required: "Body is required",
                pattern: {
                  value: /^(?!\s*$).+/,
                  message: "Only alphabetic characters are allowed.",
                },
              })}
            />
            {errors.body && (
              <p className="text-sm text-danger mt-1 font-body">
                {errors.body.message}
              </p>
            )}
          </Col>

          {/* Internal redirect */}
          <Col md={6}>
            <label className="text-md mb-1">
              Internal redirect <span className="text-danger">*</span>
            </label>

            <select
              className="po-edit-input"
              {...register("internalRedirect", {
                required: "Internal Redirect is required",
              })}
              onChange={(e) => {
                setIsInternal(e.target.value === "YES" ? true : false);
                setError("internalRedirect", {
                  type: "manual",
                  message: "",
                });
              }}
            >
              <option value="">Internal Redirect</option>
              <option value="YES">Yes</option>
              <option value="NO">No</option>
            </select>
            {errors.internalRedirect && (
              <p className="text-sm text-danger mt-1 font-body">
                {errors.internalRedirect.message}
              </p>
            )}
          </Col>
          {isInternal === false && (
            <Col md={6}>
              <label className="text-md mb-1" htmlFor="url">
                URL:
              </label>
              <input
                className="po-edit-input"
                {...register("url", {
                  required: "URL is required",
                  pattern: {
                    value: /^(?!\s*$).+/,
                    message: "Only alphabetic characters are allowed.",
                  },
                })}
              />
              {errors.url && (
                <p className="text-sm text-danger mt-1 font-body">
                  {errors.url.message}
                </p>
              )}
            </Col>
          )}
          {isInternal && (
            <>
              <Col md={6}>
                <label className="text-md mb-1" htmlFor="internalRedirect">
                  Select category
                </label>
                <select
                  className="po-edit-input"
                  {...register("category", {
                    required: "Category is required",
                  })}
                  onChange={(e) => {
                    console.log(e.target.value, "value");
                    setSelectedCategoryType(e.target.value);
                    switch (e.target.value) {
                      case "Club":
                        setCategoryValues(clubData);
                        break;
                      case "Event":
                        setCategoryValues(eventsData);
                        break;
                      case "":
                        setCategoryValues([]);
                        break;
                      default:
                        break;
                    }
                  }}
                >
                  {selectedCategoryType === "" && (
                    <option value="" disabled>
                      Select Category
                    </option>
                  )}
                  <option value="Club">Club</option>
                  <option value="Event">Event</option>
                </select>
                {errors.category && (
                  <p className="text-sm text-danger mt-1 font-body">
                    {errors.category.message}
                  </p>
                )}
              </Col>
              <Col md={6}>
                {selectedCategoryType === "Club" ? (
                  <label className="text-md mb-1" htmlFor="internalRedirect">
                    {`Select ${selectedCategoryType}`}
                  </label>
                ) : selectedCategoryType === "Event" ? (
                  <label className="text-md mb-1" htmlFor="internalRedirect">
                    {`Select ${selectedCategoryType}`}
                  </label>
                ) : selectedCategoryType === "" ? (
                  <label className="text-md mb-1" htmlFor="internalRedirect">
                    Select Value
                  </label>
                ) : null}

                <select
                  className="po-edit-input"
                  {...register("category_value", {
                    required: "Value is required",
                  })}
                  onChange={(e) => {
                    setCategoryValue(e.target.value);
                  }}
                >
                  <option value="">Select Value</option>
                  {categoryValues &&
                    categoryValues.length > 0 &&
                    categoryValues.map((club, index) => {
                      return (
                        <option
                          value={club.data().id}
                          key={index}
                          title={club && club.data().address}
                        >
                          {club && club.data().name}
                        </option>
                      );
                    })}
                </select>
                {errors.category_value && (
                  <p className="text-sm text-danger mt-1 font-body">
                    {errors.category_value.message}
                  </p>
                )}
              </Col>
            </>
          )}

          {/* Cities Section */}

          <Col md={6}>
            <label className="text-md">
              Cities
            </label>
            <input
              {...register("cities")}
              type="search"
              className="po-edit-input"
              placeholder="Please enter some text..."
              value={searchItem}
              onChange={citiesOnChange}
            />
            {errors.cities && (
              <p className="mt-2 text-danger">{errors.cities.message}</p>
            )}

            <div className={`card mt-2 p-2 ${citiesOptions.length === 0 ? "d-none" : ""}`}>
              {citiesOptions &&
                citiesOptions.map((ele, index) => (
                  <p
                    key={index}
                    className="on-hover pt-1 ps-2 m-0"
                    onClick={() => {
                      if (selectedCities.some(selectedCity => selectedCity.city === ele.name)) {
                        // setExisitingCityError("Selected city already exists");
                        setError("cities", {
                          type: "manual",
                          message: "City already exists"
                        })
                      } else {
                        citiesOnClick(ele.id);
                        // setValue("cities", ele.name)
                      }

                    }}
                  >
                    {ele.name}
                  </p>
                ))}
            </div>

            <div className="d-flex ms-0 flex-wrap">
              {selectedCities &&
                selectedCities.length > 0 &&
                selectedCities.map((ele, index) => (
                  <div key={index} className="card d-flex flex-row justify-content-between m-1">
                    <p className="p-2 semi-bold text-nowrap">{ele.city}</p>
                    <ActionIcon
                      additionalClass="text-danger text-xl bi-x-circle-fill p-2"
                      onClick={() => {
                        // Create a new array without the selected city
                        const updatedCities = selectedCities.filter((city, i) => i !== index);
                        setSelectedCities(updatedCities);
                      }}
                    />
                  </div>
                ))}
            </div>
          </Col>

          {/* Cities Section */}

          {/* Buttons */}
          <div className="text-end">
            <OutlinedButton
              link="/notifications"
              name="Cancel"
              additionalClass="me-3"
            />
            <SuccessButton
              type="submit"
              name="Update notification"
              icon="bi-check-circle"
              additionalClass={`ms-4 ${showToaster ? "opacity-06 cursor-default" : null
                }  `}
              disabled={showToaster ? true : false}
            />
          </div>
        </form>
      </ContentWrapper>
      <Modal size="xs" show={imageDeleteModal} onHide={() => setimageDeleteModal(false)}>
        <div className="background-appColor p-5 rounded font-body">
          <ModalHeader className="border-0 pb-0">
            <h5 className="text-2xl  color-ternary">
              Are you sure?
            </h5>
          </ModalHeader>
          <ModalBody>
            <p className="text-light font-weight-bold">You want to delete this image?</p>
          </ModalBody>
          <div className="d-flex justify-content-end">
            <SecondaryButton
              onClick={() => setimageDeleteModal(false)}
              name="No"
              additionalClass="text-light mt-3 me-4 px-5"
            />
            <SecondaryButton
              onClick={() => {
                setPreviewImage(null);
                // setDisplayImage(null);
                setimageDeleteModal(false)
              }}
              name="Yes"
              additionalClass="text-light mt-3 me-4 px-5"
            />
          </div>
        </div>
      </Modal>
      <Toaster
        showToaster={showToaster}
        setShowToaster={setShowToaster}
        bg={toasterDetails.bg}
        title={toasterDetails.title}
        bodyText={toasterDetails.bodyText}
        statusIcon={toasterDetails.statusIcon}
      />
    </Layout>
  );
};
export default EditNotifications;
