import React, { useState } from 'react';
import { doc, setDoc } from "firebase/firestore";
import { v4 as uuid } from 'uuid';
import ContentWrapper from "../../../Components/Global/ContentWrapper"
import Layout from "../../../Layout/LayoutManager/Layout";
import { useForm } from "react-hook-form";
import { Button, Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
// import { async } from "q";
import db from "../../../firebase";
import PageDescription from "../../../Components/Global/PageDescription";
import OutlinedButton from '../../../Components/Global/Buttons/OutlinedButton';
import SuccessButton from '../../../Components/Global/Buttons/SuccessButton';
import Toaster from '../../../Components/Global/Toast';
const CreateCategory = () => {
  const { register, formState: { errors } } = useForm();
  const clubId = localStorage.getItem('club_id');
  const initialValues = { username: "" };
  // const [formValues, setFormValues] = useState(initialValues);
  const [showToaster, setShowToaster] = useState(false);
  const [toasterDetails, setToasterDetails] = useState({
    title: "",
    bodyText: "",
    bg: "",
    statusIcon: "",
  });
  const navigate = useNavigate();

  const [state, setState] = useState({
    name: "",
    brands: [],
    errors: {
      name: "",
      brands: "",
    },
    updated_at: new Date(),
  })

  const validate = (errors) => {
    let valid = true;
    Object.values(errors).forEach(val => val.length > 0 && (val = false));
    if (state.name.replace(/\s+/g, '') == "" || state.brands.length == 0)
      valid = false;
    state.brands.forEach(vals => {
      // console.log(vals, "KKKKKKKKKKKK");
      if (vals.name.replace(/\s+/g, '') == "") {
        valid = false;
      }
    })
    return valid;
  }

  const handleSubmit = async (event) => {
    console.log(state, "the state coming inside ");
    function removeSpace(d) {
      let newData = [];
      d.forEach(d => {
        newData.push({ name: d.name.trimStart().replace(/\s+/g, ' ').trimEnd() })
      })
      return newData;
    }
    let id = uuid();
    event.preventDefault();
    // console.log();
    // console.log();
    if (validate(state.errors)) {
      setShowToaster(true);
      setToasterDetails((prev) => ({
        ...prev,
        title: "Creating...",
        bodyText: "Adding Drink Category Details",
        bg: "warning",
        statusIcon: (
          <div className="spinner-border text-warning me-2" role="status"></div>
        ),
      }));
      const updateData = {
        id: id,
        name: state.name.trimStart().replace(/\s+/g, ' ').trimEnd(),
        brands: removeSpace(state.brands),
        createdAt: new Date(),
        updatedAt: new Date()
      };

      // console.log(updateData, "update data");
      const clubResponse = await setDoc(doc(db, "clubs", clubId, "drink_category", id), updateData)
        .then(() => {
          // alert("Data successfully added");
          setToasterDetails((prev) => ({
            ...prev,
            title: "Success",
            bodyText: "Drink Category Details are added successfully",
            bg: "success",
            statusIcon: (
              <i className="bi bi-check text-success text-3xl me-2"></i>
            ),
          }));
          setTimeout(()=> {
            navigate(`/manager/drinks-management`, { replace: true });
          }, 2000)
          // window.location.href = `/manager/drinks-management`
        })
        .catch((err) => {
          setToasterDetails((prev) => ({
            ...prev,
            title: "Error",
            bodyText: "Something went wrong, Please try again",
            bg: "danger",
            statusIcon: (
              <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
            ),
          }));
          setTimeout(() => {
            setShowToaster(false);
          }, 2000);
        });
    } else {
      let errors = state.errors;
      if (state.name.replace(/\s+/g, '') == "")
        errors.name = "Enter the category name"
      if (state.brands.length == 0)
        errors.brands = "Add atleast one brand to the category"
      state.brands.forEach(vals => {
        if (vals.name.replace(/\s+/g, '') == "") {
          errors.brands = "Add brand names for all"
        }
      })
      setState((prevProps) => ({
        ...prevProps,
        errors: errors
      }));
    }
  };

  const nameChangeHandler = (event) => {
    const { value } = event.target;
    let errors = state.errors;
    errors.name = ""
    setState(prev => ({
      ...prev,
      name: value.replace(/\s+/g, ' '),
      errors: errors
    }))
  }

  const handlechangeBrandName = (event, ind) => {
    const upd_obj = state.brands
    upd_obj[ind].name = event.target.value.replace(/\s+/g, ' ');
    let errors = state.errors;
    errors.brands = ""
    setState(prev => ({
      ...prev,
      brands: upd_obj,
      errors: errors,
    }))
  }

  const handleUppendBrandName = (event, ind) => {
    const upd_obj = [...state.brands, { name: "" }]
    setState(prev => ({
      ...prev,
      brands: upd_obj,
    }))
  }
  const handleRemoveBrandFromList = (ind) => {
    console.log(ind);
    const upd_obj = state.brands;
    upd_obj.splice(ind, 1)
    setState(prev => ({
      ...prev,
      brands: upd_obj,
    }))
  }

  return (
    <Layout path="/manager/drinks-management/create-category">
      <PageDescription title="Add Drink Category" caption="Here's where you can add, delete, or manage drink options" />
      <ContentWrapper additionalClass="p-5 font-body">
        <Row>
          <Col >
            <form onSubmit={(e) => handleSubmit(e)}>
              <label className="text-lg mb-2">Category:</label>
              <input
                className="po-edit-input"
                type="text"
                name="name"
                value={state.name}
                onChange={(e) => nameChangeHandler(e)}
              />
              {state.errors.name && (
                <p className="text-sm text-danger">{state.errors.name}</p>
              )}
              {/* {errors.name && <span className="d-block text-sm text-danger">Category is required</span>} */}

              <label className="text-lg mb-2 mt-3">Brands:</label>
              {state.brands && state.brands.length > 0 && state.brands.map((field, index) => (
                <div key={index} className="d-flex">

                  <input
                    type="text"
                    className="po-edit-input me-3 mb-3"
                    name={`brands[${index}]`}
                    value={field.name}
                    onChange={(e) => handlechangeBrandName(e, index)}
                  />

                  <Button variant="outline-danger" className="mb-3 radius-105" onClick={() => handleRemoveBrandFromList(index)}  >Delete</Button>
                  {/* {errors.(`brands.${index}.name`) && <span>this field is required</span>} */}
                </div>
              ))}
              {state.errors.brands && (
                <p className="text-sm text-danger">{state.errors.brands}</p>
              )}
              <div>
                {/* <Button type="button" variant="success" onClick={() => handleUppendBrandName()} className="mt-5">+ Add Brand</Button> */}
                <SuccessButton onClick={() => handleUppendBrandName()} name="Add Brand" icon="bi-plus-circle" additionalClass="mt-4" />
              </div>
              <div className="mt-5 d-flex justify-content-end align-items-center">
              {showToaster && (
                <img
                  src="/assets/icons/loading_icon.gif"
                  alt="processing"
                  width={100}
                />
              )}
                {/* <Button variant="success" type="submit" className="me-3">Submit</Button>
            <Link to="/manager/drinks-management/"> <Button variant="danger">Cancel</Button></Link> */}
                <OutlinedButton link="/manager/drinks-management/" name="Cancel" />
                <SuccessButton type="submit" name="Submit" icon="bi-check-circle" additionalClass="ms-3" />

              </div>

            </form>
          </Col>
        </Row>
      </ContentWrapper>
      <Toaster
        showToaster={showToaster}
        setShowToaster={setShowToaster}
        bg={toasterDetails.bg}
        title={toasterDetails.title}
        bodyText={toasterDetails.bodyText}
        statusIcon={toasterDetails.statusIcon}
      />
    </Layout>
  )
}
export default CreateCategory