import React from "react";
import { useParams } from "react-router";
import { Row, Col, Modal, Button, Table } from "react-bootstrap";
import ContentWrapper from "../../Components/Global/ContentWrapper";
import Layout from "../../Layout/Layout";
import { useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import db from "../../firebase";
import { useState } from "react";
import moment from "moment";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { useTranslation } from "react-i18next";
import Markdown from "react-markdown";
const ClubDetails = () => {
  const [isLoading, setIsLoading] = useState({});
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const params = useParams();
  const [clubDetails, setClubDetails] = useState({});
  const [index, setIndex] = useState(0);

  const fetchClubDetail = async () => {
    try {
      const clubId = params.club_id;

      // Fetch club details from Firestore using async/await
      const clubResponse = await getDoc(doc(db, "clubs", clubId));

      if (clubResponse.exists()) {
        // Club exists in the database, extract data
        const clubData = clubResponse.data();
        setClubDetails(clubData);
        console.log("Club details fetched successfully:", clubData);
      } else {
        console.log("Club not found in the database.");
      }
    } catch (error) {
      console.error("Error fetching club details:", error);
    }
  };

  useEffect(() => {
    fetchClubDetail();
  }, []);

  const convertMinutesToHours = (minutes) => {
    const duration = moment.duration(minutes, "minutes");
    const hours = duration.hours();
    const minutesRemaining = duration.minutes();
    const formattedTime = moment({
      hours: hours,
      minute: minutesRemaining,
    }).format("h:mm A");
    return formattedTime;
  };

  const clubDetailsList = [
    {
      query: "Club Email",
      clubResponse:
        clubDetails &&
        clubDetails.manager_contacts &&
        clubDetails.manager_contacts[0] &&
        clubDetails.manager_contacts[0].email,
    },
    {
      query: "Address",
      clubResponse: clubDetails && clubDetails.address,
    },
    {
      query: "Club Description",
      clubResponse: clubDetails && clubDetails.description,
    },
    {
      query: "Website",
      clubResponse: clubDetails && clubDetails.website_url,
    },
    {
      query: "Club Phone Number",
      clubResponse:
        clubDetails &&
        clubDetails.manager_contacts &&
        clubDetails.manager_contacts[0] &&
        clubDetails.manager_contacts[0].phone,
    },
    {
      query: "Club Manager Name",
      clubResponse:
        clubDetails &&
        clubDetails.manager_contacts &&
        clubDetails.manager_contacts[0] &&
        clubDetails.manager_contacts[0].name,
    },
    {
      query: "Club Manager Email",
      clubResponse:
        clubDetails &&
        clubDetails.manager_contacts &&
        clubDetails.manager_contacts[0] &&
        clubDetails.manager_contacts[0].email,
    },
    {
      query: "Whatsapp No",
      clubResponse:
        clubDetails &&
          clubDetails.manager_contacts &&
          clubDetails.manager_contacts[1]
          ? clubDetails.manager_contacts[1].phone
          : clubDetails.manager_contacts &&
          clubDetails.manager_contacts[0].phone,
    },

    {
      query: "Country",
      clubResponse: clubDetails && clubDetails.country_name,
    },
    {
      query: "State",
      clubResponse: clubDetails && clubDetails.state_name,
    },
    {
      query: "City",
      clubResponse: clubDetails && clubDetails.city_name,
    },
    {
      query: "Created Date",
      clubResponse:
        clubDetails &&
        clubDetails.createdAt &&
        moment(clubDetails.createdAt.seconds * 1000).format("DD-MM-YYYY"),
    },

    {
      query: "Dress Code",
      clubResponse: clubDetails && clubDetails.dress,
    },
    {
      query: "Featured",
      clubResponse: clubDetails && clubDetails.featured === true ? "Yes" : "No",
    },
    {
      query: "Latitude",
      clubResponse: clubDetails && clubDetails.latitude,
    },
    {
      query: "Longitude",
      clubResponse: clubDetails && clubDetails.longitude,
    },
    {
      query: "status",
      clubResponse: clubDetails && clubDetails.status,
    },
  ];

  const scheduleData = clubDetails.schedule;

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Layout path="/clubs/club-details">
      {/* Club name  :start */}
      <ContentWrapper additionalClass="p-5 font-body mb-4">
        <Row>
          <Col xs={12}>
            <h2 className="color-heading font-body font-semibold text-lg text-lg-xl mb-2">
              Club Name
            </h2>
            {clubDetails && (
              <h2 className="text-md font-body"> {clubDetails.name}</h2>
            )}
          </Col>
        </Row>
      </ContentWrapper>
      {/* CLub name : End */}
      {/* VIP Manger : Start*/}
      <ContentWrapper additionalClass="p-5 font-body mb-4">
        <Row>
          <Col xs={12}>
            <Row className="g-3">
              <Col xs={12}>
                {" "}
                <h2 className="color-heading font-body font-semibold text-lg text-lg-xl">
                  Club VIP Manager
                </h2>
              </Col>
              <Col xs={6}>
                <article>
                  <h6 className="text-md font-semibold color-gray mb-2">
                    Name
                  </h6>
                  {clubDetails && clubDetails.club_vip_manager && (
                    <p className="text-capitalize text-md mb-0">
                      {clubDetails.club_vip_manager.name}
                    </p>
                  )}
                </article>
              </Col>
              <Col xs={6}>
                <article>
                  <h6 className="text-md font-semibold color-gray mb-2">
                    Email
                  </h6>
                  {clubDetails && clubDetails.club_vip_manager && (
                    <a
                      href={`mailto:${clubDetails.club_vip_manager.email}`}
                      className="text-capitalize text-md text-dark"
                    >
                      {clubDetails.club_vip_manager.email}
                    </a>
                  )}
                </article>
              </Col>
              <Col xs={6}>
                <article>
                  <h6 className="text-md font-semibold color-gray mb-2">
                    Phone
                  </h6>
                  {clubDetails && clubDetails.club_vip_manager && (
                    <a
                      href={`tel:${clubDetails.club_vip_manager.contact_no}`}
                      className="text-capitalize text-md text-dark"
                    >
                      {clubDetails.club_vip_manager.contact_no}
                    </a>
                  )}
                </article>
              </Col>
              <Col xs={6}>
                <article>
                  <h6 className="text-md font-semibold color-gray mb-2">
                    WhatsApp
                  </h6>
                  {clubDetails && clubDetails.club_vip_manager && (
                    <a
                      aria-label="Chat on WhatsApp"
                      target="_blank"
                      href={`https://wa.me/:${clubDetails.club_vip_manager.whatsapp_no}`}
                      className="text-capitalize text-md text-dark"
                      rel="noreferrer"
                    >
                      {clubDetails.club_vip_manager.whatsapp_no}
                    </a>
                  )}
                </article>
              </Col>
            </Row>
          </Col>
        </Row>
      </ContentWrapper>
      {/* VIP Manager  : End*/}
      {/* Door Manager : Start */}
      <ContentWrapper additionalClass="p-5 font-body mb-4">
        <Row>
          <Col xs={12}>
            <Row className="g-3">
              <Col xs={12}>
                {" "}
                <h2 className="color-heading font-body font-semibold text-lg text-lg-xl">
                  CLUB DOOR MANAGER
                </h2>
              </Col>
              <Col xs={6}>
                <article>
                  <h6 className="text-md font-semibold color-gray mb-2">
                    Name
                  </h6>
                  {clubDetails && clubDetails.club_door_manager && (
                    <p className="text-capitalize text-md mb-0">
                      {clubDetails.club_door_manager.name}
                    </p>
                  )}
                </article>
              </Col>
              <Col xs={6}>
                <article>
                  <h6 className="text-md font-semibold color-gray mb-2">
                    Email
                  </h6>
                  {clubDetails && clubDetails.club_door_manager && (
                    <a
                      href={`mailto:${clubDetails.club_door_manager.email}`}
                      className="text-capitalize text-md text-dark"
                    >
                      {clubDetails.club_door_manager.email}
                    </a>
                  )}
                </article>
              </Col>
              <Col xs={6}>
                <article>
                  <h6 className="text-md font-semibold color-gray mb-2">
                    Phone
                  </h6>
                  {clubDetails && clubDetails.club_door_manager && (
                    <a
                      href={`tel:${clubDetails.club_door_manager.contact_no}`}
                      className="text-capitalize text-md text-dark"
                    >
                      {clubDetails.club_door_manager.contact_no}
                    </a>
                  )}
                </article>
              </Col>
              <Col xs={6}>
                <article>
                  <h6 className="text-md font-semibold color-gray mb-2">
                    WhatsApp
                  </h6>
                  {clubDetails && clubDetails.club_door_manager && (
                    <a
                      aria-label="Chat on WhatsApp"
                      target="_blank"
                      href={`https://wa.me/:${clubDetails.club_door_manager.whatsapp_no}`}
                      className="text-capitalize text-md text-dark"
                      rel="noreferrer"
                    >
                      {clubDetails.club_door_manager.whatsapp_no}
                    </a>
                  )}
                </article>
              </Col>
            </Row>
          </Col>
        </Row>
      </ContentWrapper>
      {/* Door Manager : End */}
      {/* CLUB COMMUNICATION DETAILS :Start */}
      <ContentWrapper additionalClass="p-5 font-body mb-4">
        <Row className="g-3">
          <Col xs={12}>
            <h2 className="color-heading font-body font-semibold text-lg text-lg-xl">
              CLUB COMMUNICATION DETAILS
            </h2>
          </Col>
          <Col xs={6}>
            <article>
              <h6 className="text-md font-semibold color-gray">Club Email ID</h6>
              {clubDetails && (
                <p className="text-capitalize text-md mb-0">
                  {clubDetails.club_email_id}
                </p>
              )}
            </article>
          </Col>
          <Col xs={6}>
            <article>
              <h6 className="text-md font-semibold color-gray ">
                Website Link
              </h6>
              {clubDetails && (
                <p className="text-capitalize text-md mb-0">
                  {clubDetails.website_url}
                </p>
              )}
            </article>
          </Col>
          <Col xs={6}>
            <article>
              <h6 className="text-md font-semibold color-gray">
                Club Description
              </h6>
              {clubDetails && (
                <p className="text-capitalize text-md mb-0">
                  {clubDetails.description}
                </p>
              )}
            </article>
          </Col>
          <Col xs={6}>
            <article>
              <h6 className="text-md font-semibold color-gray">
                Club Full Address
              </h6>

              {clubDetails && (
                <p className="text-capitalize text-md mb-0">
                  {clubDetails.address}
                </p>
              )}
            </article>
          </Col>
          <Col xs={6}>
            <article className="mb-2">
              <h6 className="text-md font-semibold color-gray">
                Club latitude
              </h6>
              {clubDetails && (
                <p className="text-capitalize text-md mb-0">
                  {clubDetails.latitude}
                </p>
              )}
            </article>
            <article>
              <h6 className="text-md font-semibold color-gray">
                Club longitude
              </h6>
              {clubDetails && (
                <p className="text-capitalize text-md mb-0">
                  {clubDetails.longitude}
                </p>
              )}
            </article>
          </Col>
          <Col xs={6}>
            <article>
              <h6 className="text-md font-semibold color-gray">Dress Code</h6>
              {clubDetails && clubDetails.dress && (
                <p className="text-capitalize text-md mb-0">
                  {clubDetails.dress}
                </p>
              )}
            </article>
          </Col>
        </Row>
      </ContentWrapper>
      {/* CLUB COMMUNICATION DETAILS :End */}
      {/* CLUB ACCOUNT DETAILS : Start */}
      <ContentWrapper additionalClass="p-5 font-body mb-4">
        <Row className="g-3">
          <Col xs={12}>
            <h2 className="color-heading font-body font-semibold text-lg text-lg-xl">
              CLUB ACCOUNT DETAILS
            </h2>
          </Col>
          <Col xs={6}>
            <article>
              <h6 className="text-md font-semibold color-gray">
                Finance Coordinator Name *
              </h6>
              {clubDetails && clubDetails.club_account_details && (
                <p className="text-capitalize text-md mb-0">
                  {clubDetails.club_account_details.finance_coordinator_name}
                </p>
              )}
            </article>
          </Col>
          <Col xs={6}>
            <article>
              <h6 className="text-md font-semibold color-gray ">Email </h6>
              {clubDetails && clubDetails.club_account_details && (
                <a
                  href={`mailto:${clubDetails.club_account_details.email}`}
                  className="text-capitalize text-md text-dark"
                >
                  {clubDetails.club_account_details.email}
                </a>
              )}
            </article>
          </Col>
          <Col xs={6}>
            <article>
              <h6 className="text-md font-semibold color-gray">Mobile</h6>
              {clubDetails && clubDetails.club_account_details && (
                <a
                  className="text-capitalize text-md text-dark"
                  href={`tel:${clubDetails.club_account_details.mobile}`}
                >
                  {clubDetails.club_account_details.mobile}
                </a>
              )}
            </article>
          </Col>
          <Col xs={6}>
            <article>
              <h6 className="text-md font-semibold color-gray">WhatsApp </h6>
              {clubDetails && clubDetails.club_account_details && (
                <a
                  aria-label="Chat on WhatsApp"
                  target="_blank"
                  href={`https://wa.me/:${clubDetails.club_account_details.whatsapp_no}`}
                  className="text-capitalize text-md text-dark"
                  rel="noreferrer"
                >
                  {clubDetails.club_account_details.whatsapp_no}
                </a>
              )}
            </article>
          </Col>
          <Col xs={12}>
            <article>
              <h6 className="text-md font-semibold color-gray">
                Bank Account Details for payment coordination.
              </h6>
              {clubDetails && clubDetails.club_account_details && (
                <p className="text-capitalize text-md mb-0">
                  <Markdown>{clubDetails.club_account_details.account_details}</Markdown>
                </p>
              )}
            </article>
          </Col>
        </Row>
      </ContentWrapper>
      {/* CLUB ACCOUNT DETAILS : End */}
      {/* DRESS CODE : Start */}
      <ContentWrapper additionalClass="p-5 font-body mb-4">
        <Row className="g-3">
          <Col xs={12}>
            <h2 className="color-heading font-body font-semibold text-lg text-lg-xl">
              DRESS CODE
            </h2>
          </Col>
          <Col xs={12}>
            <article>
              {/* <h6 className="text-md font-semibold color-gray">DRESS CODE</h6> */}
              {clubDetails && (
                <p className="text-capitalize text-md mb-0">
                  <Markdown>{clubDetails.dress}</Markdown>
                </p>
              )}
            </article>
          </Col>
        </Row>
      </ContentWrapper>
      {/* DRESS CODE : End */}
      {/* CLUB LOGO : Start */}
      <ContentWrapper additionalClass="p-5 font-body mb-4">
        <Row className="g-3">
          <Col xs={12}>
            <h2 className="color-heading font-body font-semibold text-lg text-lg-xl">
              CLUB LOGO
            </h2>
          </Col>
          <Col xs={12}>
            <article
              className="po-updateEvent__inputPhoto  background-position-center-top background-repeat-norepeat background-size-cover rounded overflow-hidden"
            >
              <PhotoProvider>
                <PhotoView src={clubDetails && clubDetails.logo}>
                  <img src={clubDetails && clubDetails && clubDetails.logo} className="w-100 h-100" style={{ objectFit: 'cover', objectPosition: 'center' }} alt=" CLUB COVER IMAGE" />
                </PhotoView>
              </PhotoProvider>
            </article>
          </Col>
        </Row>
      </ContentWrapper>
      {/* CLUB LOGO  : End */}
      {/* CLUB COVER IMAGE : Start */}
      <ContentWrapper additionalClass="p-5 font-body mb-4">
        <Row className="g-3">
          <Col xs={12}>
            <h2 className="color-heading font-body font-semibold text-lg text-lg-xl">
              CLUB COVER IMAGE
            </h2>
          </Col>
          <Col xs={12}>
            <article
              className="po-updateEvent__inputPhoto  background-position-center-top background-repeat-norepeat background-size-cover rounded overflow-hidden"
            >
              <PhotoProvider>
                <PhotoView src={clubDetails && clubDetails.display_image}>
                  <img src={clubDetails && clubDetails.display_image} className="w-100 h-100" style={{ objectFit: 'cover', objectPosition: 'center' }} alt=" CLUB COVER IMAGE" />
                </PhotoView>
              </PhotoProvider>
            </article>
          </Col>
        </Row>
      </ContentWrapper>
      {/* CLUB COVER IMAGE  : End */}
      {/* CLUB Gallery photos : Start */}
      <ContentWrapper additionalClass="p-5 font-body mb-4">
        <Row className="g-3">
          <Col xs={12}>
            <h2 className="color-heading font-body font-semibold text-lg text-lg-xl">
              CLUB Gallery photos
            </h2>
          </Col>
          <Col xs={12}>
            <h4 className="color-lightestblue font-normal text-xs mb-2 font-body">
              Club Slider Images
            </h4>
            <PhotoProvider>
              {clubDetails.photos &&
                clubDetails.photos.length > 0 &&
                clubDetails.photos.map((photo) => {
                  return (
                    <PhotoView src={photo.url}>
                      <img
                        src={photo.url}
                        alt="Not an image"
                        style={{ height: "100px", width: "100px" }}
                        className="rounded me-2 mb-2 cursor-pointer "
                      />
                    </PhotoView>
                  );
                })}
            </PhotoProvider>
          </Col>
        </Row>
      </ContentWrapper>
      {/* CLUB Gallery photos  : End */}
      {/* Status : Start */}
      <ContentWrapper additionalClass="p-5 font-body mb-4">
        <Row className="g-3">
          <Col xs={12}>
            <h2 className="color-heading font-body font-semibold text-lg text-lg-xl">
              Status
            </h2>
          </Col>
          <Col xs={6}>
            <h4 className="text-md font-semibold color-gray">Status</h4>
            <p>{clubDetails && clubDetails.status}</p>
          </Col>
          <Col xs={6}>
            <h4 className="text-md font-semibold color-gray">Is featured ?</h4>
            <p>{clubDetails && clubDetails.featured === true ? "Yes" : "No"}</p>
          </Col>
        </Row>
      </ContentWrapper>
      {/* Status : ENd */}
      {/* Working Days : start */}
      <ContentWrapper additionalClass="p-5 font-body mb-4">
        <div>
          <h2 className="color-heading font-body font-semibold text-lg text-lg-2xl pb-6 mb-0">
            Club Working Days
          </h2>
          <Table bordered rounded hover>
            {/* Table Headingss */}
            <thead>
              <tr>
                <th>S.No</th>
                <th className="p-3"> Days</th>
                <th className="p-3">Party theme</th>
                <th className="p-3">Timings</th>
              </tr>
            </thead>
            {/* Schedules */}
            <tbody>
              <tr>
                <td>1</td>
                <td className="p-3">Sunday</td>
                <td className="p-3">
                  {scheduleData &&
                    scheduleData.Sunday &&
                    scheduleData.Sunday.title}
                </td>
                <td className="p-3">
                  {scheduleData &&
                    scheduleData.Sunday &&
                    convertMinutesToHours(scheduleData.Sunday.from_time)}{" "}
                  to{" "}
                  {scheduleData &&
                    scheduleData.Sunday &&
                    convertMinutesToHours(scheduleData.Sunday.to_time)}
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td className="p-3">Monday</td>
                <td className="p-3">
                  {scheduleData &&
                    scheduleData.Monday &&
                    scheduleData.Monday.title}
                </td>
                <td className="p-3">
                  {scheduleData &&
                    scheduleData.Monday &&
                    convertMinutesToHours(scheduleData.Monday.from_time)}{" "}
                  to{" "}
                  {scheduleData &&
                    scheduleData.Monday &&
                    convertMinutesToHours(scheduleData.Monday.to_time)}
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td className="p-3">Tuesday</td>
                <td className="p-3">
                  {scheduleData &&
                    scheduleData.Tuesday &&
                    scheduleData.Tuesday.title}
                </td>
                <td className="p-3">
                  {scheduleData &&
                    scheduleData.Tuesday &&
                    convertMinutesToHours(scheduleData.Tuesday.from_time)}{" "}
                  to{" "}
                  {scheduleData &&
                    scheduleData.Tuesday &&
                    convertMinutesToHours(scheduleData.Tuesday.to_time)}
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td className="p-3">Wednesday</td>
                <td className="p-3">
                  {scheduleData &&
                    scheduleData.Wednesday &&
                    scheduleData.Wednesday.title}
                </td>
                <td className="p-3">
                  {scheduleData &&
                    scheduleData.Wednesday &&
                    convertMinutesToHours(
                      scheduleData.Wednesday.from_time
                    )}{" "}
                  to{" "}
                  {scheduleData &&
                    scheduleData.Wednesday &&
                    convertMinutesToHours(scheduleData.Wednesday.to_time)}
                </td>
              </tr>
              <tr>
                <td>5</td>
                <td className="p-3">Thursday</td>
                <td className="p-3">
                  {scheduleData &&
                    scheduleData.Thursday &&
                    scheduleData.Thursday.title}
                </td>
                <td className="p-3">
                  {scheduleData &&
                    scheduleData.Thursday &&
                    convertMinutesToHours(scheduleData.Thursday.from_time)}{" "}
                  to{" "}
                  {scheduleData &&
                    scheduleData.Thursday &&
                    convertMinutesToHours(scheduleData.Thursday.to_time)}
                </td>
              </tr>
              <tr>
                <td>6</td>
                <td className="p-3">Friday</td>
                <td className="p-3">
                  {scheduleData &&
                    scheduleData.Friday &&
                    scheduleData.Friday.title}
                </td>
                <td className="p-3">
                  {scheduleData &&
                    scheduleData.Friday &&
                    convertMinutesToHours(scheduleData.Friday.from_time)}{" "}
                  to{" "}
                  {scheduleData &&
                    scheduleData.Friday &&
                    convertMinutesToHours(scheduleData.Friday.to_time)}
                </td>
              </tr>
              <tr>
                <td>7</td>
                <td className="p-3">Saturday</td>
                <td className="p-3">
                  {scheduleData &&
                    scheduleData.Saturday &&
                    scheduleData.Saturday.title}
                </td>
                <td className="p-3">
                  {scheduleData &&
                    scheduleData.Saturday &&
                    convertMinutesToHours(scheduleData.Saturday.from_time)}{" "}
                  to{" "}
                  {scheduleData &&
                    scheduleData.Saturday &&
                    convertMinutesToHours(scheduleData.Saturday.to_time)}
                </td>
              </tr>
            </tbody>

            {/* Schedules */}
          </Table>
        </div>
      </ContentWrapper>
      {/* Working Days : end */}
      {/* Location : Start */}
      <ContentWrapper additionalClass="p-5 font-body mb-4">
        <Row className="g-3">
          <Col xs={12}>
            <h4 className="color-heading font-body font-semibold text-lg text-lg-2xl pb-6 mb-0">
              Location
            </h4>
          </Col>
          <Col xs={6}>
            <h4 className="text-md font-semibold color-gray">Country</h4>
            <p>{clubDetails && clubDetails.country_name}</p>
          </Col>
          <Col xs={6}>
            <h4 className="text-md font-semibold color-gray">State</h4>
            <p>{clubDetails && clubDetails.state_name}</p>
          </Col>
          <Col xs={6}>
            <h4 className="text-md font-semibold color-gray">City</h4>
            <p>{clubDetails && clubDetails.city_name}</p>
          </Col>

          <Col xs={6}>
            <h4 className="text-md font-semibold color-gray">Full Address</h4>
            <p>{clubDetails && clubDetails.address}</p>
          </Col>
        </Row>
      </ContentWrapper>
      {/* Location : ENd */}
      {/* Slot timings : Start */}
      <ContentWrapper additionalClass="p-5 font-body mb-4">
        <div className="mt-3">
          <h2 className="color-heading font-body font-semibold text-lg text-lg-2xl pb-6 mb-0">
            Slot Timings
          </h2>
          {clubDetails &&
            clubDetails.slots &&
            clubDetails.slots.length ?
            <Table bordered hover>
              <thead>
                <tr>
                  <th className="p-3">S.no</th>
                  <th className="p-3">Time</th>
                  <th className="p-3">Zone</th>
                </tr>
              </thead>
              <tbody>
                {clubDetails &&
                  clubDetails.slots &&
                  clubDetails.slots.length > 0 &&
                  clubDetails.slots.map((slot, index) => {
                    return (
                      <tr key={index}>
                        <td className="p-3">{index + 1}</td>
                        <td className="p-3">{slot.slot_time}</td>
                        <td className="p-3">{slot.zone}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table> : <p><span className="font-semibold">Note:</span> <span>The time slots are scheduled on the days when the club is operational, with each slot having a duration of 30 minutes.</span></p>}
        </div>
      </ContentWrapper>
      {/* Slot timings : ENd */}
      {/* Terms and Conditions : Start */}
      <ContentWrapper additionalClass="p-5 font-body mb-4">
        <div className="mt-3">
          <h2 className="color-heading font-body font-semibold text-lg text-lg-2xl pb-6 mb-0">
            Terms and Conditions
          </h2>
          <div>
            <Markdown>{clubDetails && clubDetails.terms_and_conditions}</Markdown>
          </div>
        </div>
      </ContentWrapper>
      {/* Terms and Conditions : ENd  */}
    </Layout>
  );
};

export default ClubDetails;
