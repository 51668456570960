import React from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  Image,
} from "react-bootstrap";
import ContentWrapper from "../../Components/Global/ContentWrapper";
import Layout from "../../Layout/Layout";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import db from "../../firebase";
import { useState } from "react";
import OutlinedButton from "../../Components/Global/Buttons/OutlinedButton";
import SuccessButton from "../../Components/Global/Buttons/SuccessButton";
import SecondaryButton from "../../Components/Global/Buttons/SecondaryButton";
import Toaster from "../../Components/Global/Toast";
import moment from "moment";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { useForm } from "react-hook-form";
import PrimaryButton from "../../Components/Global/Buttons/PrimaryButton";
const ImagePreview = () => {
  return (
    <div
      style={{
        height: "100px",
        width: "100px",
        backgroundColor: "#DEDEDE",
      }}
      className="bg-gray rounded-circle overflow-hidden d-flex align-items-center justify-content-center"
    >
      <i className="bi bi-person-fill text-4xl"></i>
    </div>
  );
};
const MembershipRequestDetails = () => {
  const [showRejectionModal, setshowRejectionModal] = useState(false);
  const [reason, setReason] = useState({
    reason: "",
    error: "",
  });
  const [showToaster, setShowToaster] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [kycDetails, setKycDetails] = useState({});
  const [acceptModal, setAcceptModal] = useState(false);
  const { reset, handleSubmit, register, clearErrors, formState: { errors } } = useForm();
  const [paymentLink, setPaymentLink] = useState({
    payment: "",
    paymentError: ""
  })
  const [toasterDetails, setToasterDetails] = useState({
    title: "",
    bodyText: "",
    bg: "",
    statusIcon: "",
  });
  const { membership_id } = useParams();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  let active = queryParams.get("active")

  const navigate = useNavigate();
  const [userPreviousMembership, setUserPreviousMembership] = useState(null)
  // let membershipUpdation = membershipAndUserData.membershipUpdate;
  // const [status, setStatus] = useState("Pending");
  const [userDetails, setUserDetails] = useState({});
  const [userDetailsFromApi, setUserDetailsFromApi] = useState({})
  const [membershipDetails, setMembershipDetails] = useState({});
  const [show, setShow] = useState(false);
  const [ameystModal, setAmeystModal] = useState(false);
  const handleClose = () => setShow(false);
  const [appliedMembershipDetails, setAppliedMembershipDetails] = useState({});
  // const [membershipCardBackground, setMembershipCardBackground] = useState("");
  const handleShow = () => {
    setShow(true);
  };

  const rejectButtonModalSubmit = async () => {
    if (reason.error === "") {
      if (reason.reason === "") {
        setReason({ ...reason, error: "Please enter a reason" });
        return;
      }
    }
    setshowRejectionModal(false);
    handleAcceptButton("Cancelled");
  };

  let startDate = new Date()

  let endDate = userDetails &&
    userDetails.mebership_ends_at &&
    new Date(userDetails.mebership_ends_at.seconds * 1000)

  let startMoment = startDate && moment([startDate.getFullYear(), startDate.getMonth(), startDate.getDate()])
  let endMoment = endDate && moment([endDate.getFullYear(), endDate.getMonth(), endDate.getDate()])
  const userDetailsList = [
    {
      query: `Name`,
      userResponse: userDetails.name,
    },
    {
      query: `Email`,
      userResponse: userDetails.email,
    },
    {
      query: `Phone`,
      userResponse:
        userDetails &&
        userDetails.contacts &&
        userDetails.contacts[0] &&
        userDetails.contacts[0].contact_no &&
        userDetails.contacts[0].contact_no,
      icon: "./assets/icons/america-flag-icon.svg",
    },
    {
      query: `Whatsapp`,
      userResponse:
        userDetails &&
        userDetails.contacts &&
        userDetails.contacts[1] &&
        userDetails.contacts[1].contact_no &&
        userDetails.contacts[1].contact_no,
      icon: "./assets/icons/america-flag-icon.svg",
    },
    {
      query: "Date of Birth",
      userResponse: userDetails.dob,
    },
    {
      query: "Gender",
      userResponse: userDetails.gender,
    },
    {
      query: "Nationality",
      userResponse: userDetails.home_country_name,
    },
    {
      query: "Country",
      userResponse: userDetails.home_country_name,
    },
    {
      query: "State",
      userResponse: userDetails.home_state_name,
    },
    {
      query: "City",
      userResponse: userDetails.home_city_name,
    },
    {
      query: "Address",
      userResponse: kycDetails.permanent_address,
    },
    {
      query: "Pincode",
      userResponse: kycDetails && kycDetails.zipcode,
    }

  ];

  const amethystMembershipFeatures = membershipDetails.benifits;
  const getUserDetails = async () => {
    try {
      const membershipCardDetails = {};
      const membershipResponse = await getDoc(doc(db, "membership_requests", membership_id));
      const membershipData = membershipResponse.data();
      setMembershipDetails(membershipData);
      const userResponse = await getDoc(doc(db, "users", membershipData.user.id));
      const userData = userResponse.data();
      setUserDetails(userData);
      try {
        let responseUsers = await axios.get(
          `${process.env.REACT_APP_API_URL}/user/v1/user?uid=${membershipData.user.id}`
        );
        responseUsers && responseUsers.data && responseUsers.data.data && setUserDetailsFromApi(responseUsers.data.data);
      } catch (error) {
        console.log(error, "Error")
      }

      const membershipsResponse = userData && userData.active_membership_id !== null ? await getDoc(
        doc(db, "memberships", userData.active_membership_id)
      ) : null;
      membershipsResponse && membershipsResponse.data() && setUserPreviousMembership(membershipsResponse.data())

      const appliedMembershipDetails = await getDoc(
        doc(db, "memberships", membershipData.membership_id));
      appliedMembershipDetails && appliedMembershipDetails.data() && setAppliedMembershipDetails(appliedMembershipDetails.data())
      const kycResponse = userData.id && await getDocs(query(collection(db, "user_kyc"), where("user_id", "==", userData.id)));
      // const kycResponse = userData.id && await getDocs(query(collection(db, "user_kyc"), where("user_id", "==", userData.id), where("status", "in", ["Approved", "Pending"])));
      if (kycResponse.docs.length > 0) {
        setKycDetails(kycResponse.docs[0].data());
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleStatus = (e) => {
    // setSelectedStatus(e.target.value);
  };
  const handleAcceptButton = async (type) => {
    if (type === "Cancelled") {
      if (reason.error === "") {
        if (reason.reason === "") {
          setReason({ ...reason, error: "Please enter a reason" });
          return;
        }
      }
    }
    setShowToaster(true);
    setToasterDetails((prev) => ({
      ...prev,
      title: type === "Approved" ? "Approving..." : "Cancelling...",
      bodyText: type === "Approved" ? "Approving membership" : "Cancelling membership",
      bg: "warning",
      statusIcon: (
        <div className="spinner-border text-warning me-2" role="status"></div>
      ),
    }));
    try {
      const jwtToken = localStorage.getItem("authenticate");
      await axios.post(
        `${process.env.REACT_APP_API_URL}/membership/v1/updateMembershipRequestStatus`,
        {
          status: type,
          id: membership_id,
          rejection_remark: type === "Cancelled" ? reason.reason : null,
        },
        {
          headers: {
            developement: true,
            token: jwtToken,
          },
        }
      );

      setToasterDetails((prev) => ({
        ...prev,
        title: "Success",
        bodyText: type === "Approved" ? "Approved membership successfully" : "Cancelled membership successfully",
        bg: "success",
        statusIcon: <i className="bi bi-check text-success text-3xl me-2"></i>,
      }));
      setTimeout(() => {
        navigate("/memberships");
      }, 2000);
      // navigate("/memberships");
    } catch (error) {
      setToasterDetails((prev) => ({
        ...prev,
        title: "Error",
        bodyText: "Something went wrong",
        bg: "danger",
        statusIcon: (
          <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
        ),
      }));
    }
  };
  const handleCabinCrewPhotos = () => {
    return (
      <div
        className="modal show"
        style={{ display: "block", position: "initial" }}
      ></div>
    );
  };
  useEffect(() => {
    if (membership_id) {
      getUserDetails();
    }

  }, []);

  const splitString = (input) => {
    let words = ""
    const threeLetters = input.substring(0, 3); // Get the first three letters
    const remainingLetters = input.substring(3); // Get the remaining letters
    [threeLetters, ...remainingLetters.match(/.{1,4}/g)].map((ele) => {
      words += ele + " "
    }) // Split the remaining letters into groups of four
    return words
  };




  // membership details pop up
  const [showMember, setShowMember] = useState(false);
  const memberHandleClose = () => setShowMember(false);
  const memberHandleShow = () => setShowMember(true);


  const formValidation = () => {
    const urlPattern = new RegExp(
      // urlValidationRegex
      "^" +
      "(?:(?:https?|ftp)://)" +
      "(?:\\S+(?::\\S*)?@)?" +
      "(?:" +
      "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
      "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
      "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
      "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
      "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
      "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
      "|" +
      "(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)" +
      "(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*" +
      "(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))" +
      ")" +
      "(?::\\d{2,5})?" +
      "(?:/\\S*)?" +
      "$",
      "i"
    );
    let error = null
    if (paymentLink.payment === "") {
      error = "Please enter a payment link"
    }
    if (!urlPattern.test(paymentLink.payment)) {
      error = "Please enter a valid link"
    }
    setPaymentLink({
      ...paymentLink,
      paymentError: error
    })
    return error;
  }

  const handleMembershipAcceptForm = async (e) => {
    e.preventDefault();
    if (paymentLink.paymentError === "") {
      let acceptFormValidation = formValidation();
      if (!acceptFormValidation) {
        setAcceptModal(false)
        setShowToaster(true);
        setToasterDetails((prev) => ({
          ...prev,
          title: "Updating...",
          bodyText: "Updating payment status...",
          bg: "warning",
          statusIcon: (
            <div className="spinner-border text-warning me-2" role="status"></div>
          ),
        }));
        try {
          const jwtToken = localStorage.getItem("authenticate");
          await axios.post(
            `${process.env.REACT_APP_API_URL}/membership/v1/sendPaymentLink`,
            {
              id: membership_id,
              payment_link: paymentLink.payment,
              amount: membershipDetails.amount
            },

            {
              headers: {
                developement: true,
                token: jwtToken,
              },
            }
          );
          setToasterDetails((prev) => ({
            ...prev,
            title: "Success",
            bodyText: "Successfully updated",
            bg: "success",
            statusIcon: <i className="bi bi-check text-success text-3xl me-2"></i>,
          }));
          setTimeout(() => {
            navigate("/memberships");
          }, 2000);
          // navigate("/memberships");
        } catch (error) {
          console.log(error, "errorerror")
          setToasterDetails((prev) => ({
            ...prev,
            title: "Error",
            bodyText: "Something went wrong",
            bg: "danger",
            statusIcon: (
              <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
            ),
          }));
        }
      }
    }


  }
  console.log(kycDetails, "kycResponse")
  // card details
  // const previewImage = "./assets/membership-cards/cards/platnium-background.jpg";
  return (
    <Layout path={typeof active === "string" ? "/memberships/active-request" : "/memberships/membership-request"}>
      <h2 className="color-heading font-body font-semibold text-lg text-lg-2xl pb-6 mb-0">
        Membership Request Details
      </h2>
      <ContentWrapper additionalClass="p-5 font-body rounded">
        <Row className="mb-4">
          <Col xs={12}>
            <h2 className="color-heading font-body font-semibold text-lg text-lg-2xl pb-6 mb-0">
              User Profile Details
            </h2>
            <a href={`/users/user-details/${userDetails.id}`} target="_blank">
              <figure className="d-flex align-items-center">
                <div >
                  {userDetails.profile_image ? (
                    <div
                      style={{
                        height: "100px",
                        width: "100px",
                        backgroundImage: `url(${userDetails.profile_image})`,
                      }}
                      className="background-position-center-center background-repeat-norepeat background-size-cover rounded-circle overflow-hidden "
                    ></div>
                  ) : (
                    <i className="bi bi-person-fill text-6xl m-0"></i>
                  )}
                </div>

                <figcaption className="ps-3 font-body color-lightblue font-semibold text-md text-lg-2xl mb-0">
                  {userDetails.name}
                </figcaption>
              </figure>
            </a>
          </Col>
        </Row>

        <Row className="g-4 ">
          {userDetailsList &&
            userDetailsList.map((item, index) => {
              if (item.userResponse) {
                return (
                  <Col xs={6} lg={3} className="font-body" key={index}>
                    <article>
                      <h4 className="color-lightestblue text-sm font-semibold mb-0 font-body mb-1">
                        {item.query}
                        {userDetailsFromApi &&
                          userDetailsFromApi.emailVerified !== undefined &&
                          item &&
                          item.query &&
                          item.query.toLowerCase() === "email" ? (
                          <span
                            className={` text-white ms-2 px-2 py-1 rounded ${Boolean(userDetailsFromApi.emailVerified)
                              ? "bg-success"
                              : "bg-secondary"
                              }`}
                          >
                            {Boolean(userDetailsFromApi.emailVerified)
                              ? "Verified"
                              : "Not Verified"}
                          </span>
                        ) : ""}
                      </h4>

                      {item.query === "Status" ?

                        (item.userResponse === "Approved" ?
                          <p className="text-success bold">
                            {item.userResponse}
                          </p> :
                          item.userResponse === "Pending" ?
                            <p className="text-warning bold">
                              {item.userResponse}
                            </p> :
                            item.userResponse === "Cancelled" ?
                              <p className="text-danger bold">
                                {item.userResponse}
                              </p> : null)
                        :
                        <p className="color-lightblue font-semibold text-md text-lg-lg mb-0 font-body text-wrap text-break">
                          {item.userResponse}
                        </p>
                      }
                    </article>
                  </Col>
                );
              } else return "";
            })}

        </Row>


        {/* Membership card */}

        <Row className="g-4 border-top mt-5">
          <Col xs={12} lg={6} className="pt-4">
            <article>
              <Modal show={showMember} onHide={memberHandleClose} size="lg" >
                <Modal.Header className="background-appColor p-8 font-body">
                  <Modal.Title className="d-flex justify-content-between align-items-center w-100"><h2 className="font-body mb-0 ps-2 pt-2 pb-1  text-2xl  color-ternary font-semibold ">
                    {appliedMembershipDetails && appliedMembershipDetails.name} Membership
                  </h2>
                    <i className="bi bi-x-circle-fill text-2xl color-ternary cursor-pointer" onClick={memberHandleClose}></i>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="background-appColor p-8 font-body">
                  <ul className="font-body mb-0 ps-2 pl-5  pb-2">
                    {appliedMembershipDetails.benifits && appliedMembershipDetails.benifits.map((item, index) => {
                      return <li className="text-sm font-normal mb-0 mt-2 gold-bullets text-light" style={{ opacity: "0.8" }} key={index}>{item.description}</li>;
                    })}
                  </ul>
                </Modal.Body>
                <Modal.Footer className="background-appColor p-8 font-body">
                </Modal.Footer>
              </Modal>
              <div className="d-flex align-items-center mb-2">
                <i className="bi bi-info-circle-fill text-xl color-yellow1 me-2 cursor-pointer" onClick={memberHandleShow}></i>

                <p className="color-lightblue font-semibold text-md text-lg-lg mb-0 font-body">{`From ${membershipDetails && membershipDetails.old_membership_name === null ? "Non member" : `${membershipDetails.old_membership_name}`
                  } to ${membershipDetails.membership_name}`}</p>
              </div>


              {
                (membershipDetails.status == "Pending") ? null :
                  <>
                    {(membershipDetails.status == "Approved") &&
                      kycDetails && kycDetails.name ? <article className={`membership-card background-position-center-center background-repeat-norepeat background-size-cover overflow-hidden  py-4 px-5 d-flex flex-column justify-content-between ${membershipDetails.membership_name === "Gold" ? "text-dark" : membershipDetails.membership_name === "Silver" ? "text-dark" : membershipDetails.membership_name === "Amethyst" ? "color-ternary" : "text-light"}`}
                        style={{
                          backgroundImage: `url(${appliedMembershipDetails && appliedMembershipDetails.display_image && appliedMembershipDetails.display_image})`, height: '280px', width: '450px', borderRadius: "30px"
                        }}>

                      <h2 className="text-2xl mt-5"> {userDetails && userDetails.name}</h2>
                      <div>
                        <h4 className="text-md">{membershipDetails && splitString(membershipDetails.card_no)}</h4>
                        <p className="text-light mb-0 mt-3 text-2xl">{membershipDetails && membershipDetails.membership_name}</p>
                        {
                          membershipDetails && <p className="mb-1 text-xxs">Member since {membershipDetails && membershipDetails.createdAt ? moment(membershipDetails.mebership_starts_at && membershipDetails.createdAt.seconds * 1000).format("DD MMM YYYY") : ""}</p>
                        }
                        {
                          membershipDetails.ends_at && <p className="mb-1 text-xxs">Valid till {membershipDetails && membershipDetails.ends_at ? moment(membershipDetails.ends_at && membershipDetails.ends_at.seconds * 1000).format("DD MMM YYYY") : ""}</p>
                        }

                      </div>
                    </article> : null
                    }
                  </>
              }
            </article>
          </Col>
        </Row>






        <Modal
          size="xl"
          show={show}
          onHide={() => setShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Body>
            <div className="col-12">
              <div className="d-flex justify-content-end mb-3">
                <div style={{ height: "40px", width: "40px", backgroundColor: "#090F17" }} className="cursor-pointer rounded-circle d-flex align-items-center justify-content-center" onClick={handleClose} name="Close">
                  <i className="bi bi-x-lg text-white"></i>
                </div>
              </div>
            </div>
            <div className="row g-4">
              <div className="col-4 d-flex align-items-center justify-content-center">
                {userDetails.profile_image ? <div
                  style={{
                    height: "150px",
                    width: "150px",
                    backgroundImage: `url(${userDetails.profile_image})`,
                  }}
                  className="mb-4 background-position-center-center background-repeat-norepeat background-size-cover rounded-circle overflow-hidden "
                ></div> : <p className="text-lg mb-5">No User image</p>
                }
              </div>
            </div>
            {/* end */}
          </Modal.Body>
        </Modal>
      </ContentWrapper>


      {/* KYC  */}

      <ContentWrapper additionalClass="p-5 mt-5">
        <h2 className="color-heading font-body font-semibold text-lg text-lg-2xl pb-6 mb-0">
          ID Verification
        </h2>
        <Row>
          <Col xs={4} xl={3}>
            <div>
              <p className="color-lightestblue font-normal text-sm mb-0 font-body">
                Government ID Number
              </p>
              <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                {kycDetails && kycDetails.government_id_number}
              </p>
            </div>
          </Col>
          <Col xs={4} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              Government ID
            </p>
            <div className="d-flex align-items-center">
              <div className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                <Button
                  variant="warning"
                  className="text-warning bold bg-white border-0 ps-0"
                  onClick={(e) => setShowModal(true)}
                >
                  Click Here
                </Button>
              </div>
            </div>
          </Col>
          {kycDetails && kycDetails.user_image && <Col xs={4} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              User Image
            </p>
            <Zoom>
              <img
                className="rounded po-objectFit--cover po-objectPosition"
                src={kycDetails && kycDetails.user_image}
                width={80}
                height={60}
                alt="user profile"
              />
            </Zoom>
          </Col>}
        </Row>
      </ContentWrapper>


      {membershipDetails && membershipDetails.rejection_remark &&
        <ContentWrapper additionalClass="p-5 mt-5">
          <h2 className="color-heading font-body font-semibold text-lg text-lg-2xl pb-6 mb-0">
            Rejection Reason
          </h2>
          <div>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              Membership Status
            </p>
            <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
              Cancelled
            </p>
          </div>
          <div className="mt-2">
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              Reason
            </p>
            <p className="text-danger bold">{membershipDetails.rejection_remark}</p>
          </div>
        </ContentWrapper>}

      {
        Object.keys(kycDetails).length === 0 ? (
          <ContentWrapper additionalClass="p-5 mt-5">
            <p className="text-center bold">No KYC Details</p>
          </ContentWrapper>
        ) : (
          <ContentWrapper additionalClass="p-5 mt-5">
            <h5>Active membership form details</h5>
            <Row className="g-4">

              {/* name */}

              <Col xs={6} xl={3}>
                <p className="color-lightestblue font-normal text-sm mb-0 font-body">
                  User photo
                </p>
                <div className="d-flex align-items-center">
                  <Zoom>
                    <img
                      className="rounded po-objectFit--cover po-objectPosition"
                      src={kycDetails && kycDetails.user_image}
                      width={80}
                      height={60}
                      alt="user profile"
                    />
                  </Zoom>
                </div>
              </Col>
              <Col xs={6} xl={3}>
                <p className="color-lightestblue font-normal text-sm mb-0 font-body">
                  Name
                </p>
                <div className="d-flex align-items-center">
                  <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                    {kycDetails && kycDetails.name}
                  </p>
                </div>
              </Col>

              {/* nationality */}

              <Col xs={6} xl={3}>
                <p className="color-lightestblue font-normal text-sm mb-0 font-body">
                  Nationality
                </p>
                <div className="d-flex align-items-center">
                  <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                    {kycDetails && kycDetails.nationalty}
                  </p>
                </div>
              </Col>

              {/* frequency clubing */}

              <Col xs={6} xl={3}>
                <p className="color-lightestblue font-normal text-sm mb-0 font-body">
                  Frequency Clubbing
                </p>
                <div className="d-flex align-items-center">
                  <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                    {kycDetails && kycDetails.frequency_of_clubbing}
                  </p>
                </div>
              </Col>

              {/* country */}

              <Col xs={6} xl={3}>
                <p className="color-lightestblue font-normal text-sm mb-0 font-body">
                  Home Country
                </p>
                <div className="d-flex align-items-center">
                  <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                    {kycDetails && kycDetails.home_country_name}
                  </p>
                </div>
              </Col>

              {/* state */}

              <Col xs={6} xl={3}>
                <p className="color-lightestblue font-normal text-sm mb-0 font-body">
                  Home State
                </p>
                <div className="d-flex align-items-center">
                  <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                    {kycDetails && kycDetails.home_state_name}
                  </p>
                </div>
              </Col>

              {/* city */}

              <Col xs={6} xl={3}>
                <p className="color-lightestblue font-normal text-sm mb-0 font-body">
                  Home City
                </p>
                <div className="d-flex align-items-center">
                  <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                    {kycDetails && kycDetails.home_city_name}
                  </p>
                </div>
              </Col>

              {/* status */}

              <Col xs={6} xl={3}>
                <p className="color-lightestblue font-normal text-sm mb-0 font-body">
                  Mailing Address
                </p>
                <div className="d-flex align-items-center">
                  <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                    {kycDetails && kycDetails.permanent_address}
                  </p>
                </div>
              </Col>

              <Col xs={6} xl={3}>
                <p className="color-lightestblue font-normal text-sm mb-0 font-body">
                  Pincode
                </p>
                <div className="d-flex align-items-center">
                  <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                    {kycDetails && kycDetails.zipcode}
                  </p>
                </div>
              </Col>

              {/* KYC Applied on */}

              <Col xs={6} xl={3}>
                <p className="color-lightestblue font-normal text-sm mb-0 font-body">
                  Membership Applied On
                </p>
                <div className="d-flex align-items-center">
                  <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                    {kycDetails &&
                      kycDetails.createdAt &&
                      moment(kycDetails.createdAt.seconds * 1000).format(
                        "DD MMM YYYY"
                      )}
                  </p>
                </div>
              </Col>

              {/* KYC Status */}

              <Col xs={6} xl={3}>
                <p className="color-lightestblue font-normal text-sm mb-0 font-body">
                  Membership Status
                </p>
                <div className="d-flex align-items-center">
                  <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                    {kycDetails && kycDetails.status}
                  </p>
                </div>
              </Col>
              {userDetails.mebership_starts_at &&
                < Col xs={6} xl={3}>
                  <p className="color-lightestblue font-normal text-sm mb-0 font-body">
                    Current Membership Start Date
                  </p>
                  <div className="d-flex align-items-center">
                    <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                      {userDetails &&
                        userDetails.mebership_starts_at &&
                        moment(
                          new Date(userDetails.mebership_starts_at.seconds * 1000).toISOString()
                        ).format("DD MMM YYYY")}
                    </p>
                  </div>
                </Col>
              }

              {/* Current membership end date */}

              {userDetails.mebership_ends_at &&
                <Col xs={6} xl={3}>
                  <p className="color-lightestblue font-normal text-sm mb-0 font-body">
                    Current Membership End Date
                  </p>
                  <div className="d-flex align-items-center">
                    <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                      {userDetails &&
                        userDetails.mebership_ends_at &&
                        moment(
                          new Date(userDetails.mebership_ends_at.seconds * 1000).toISOString()
                        ).format("DD MMM YYYY")}
                    </p>
                  </div>
                </Col>}

              {/* Current membership */}

              <Col xs={6} xl={3}>
                <p className="color-lightestblue font-normal text-sm mb-0 font-body">
                  Current Membership Request Status
                </p>
                <div className="d-flex align-items-center">
                  <div className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                    {membershipDetails.status ?

                      (membershipDetails.status === "Approved" ?
                        <p className="text-success bold">
                          {membershipDetails.status}
                        </p> :
                        membershipDetails.status === "Pending" ?
                          <p className="text-warning bold">
                            {membershipDetails.status}
                          </p> :
                          membershipDetails.status === "Cancelled" ?
                            <p className="text-danger bold">
                              {membershipDetails.status}
                            </p> :
                            membershipDetails.status === "waiting for payment confirmation" ?
                              <p className="text-warning bold">
                                {membershipDetails.status}
                              </p> :
                              null)
                      :
                      <p className="color-lightblue font-semibold text-md text-lg-lg mb-0 font-body text-wrap text-break">
                        {membershipDetails.status}
                      </p>
                    }
                  </div>
                </div>
              </Col>

              {/* Cancellation Reason */}
              {membershipDetails && membershipDetails.rejection_remark &&

                <Col xs={6} xl={3}>
                  <p className="color-lightestblue font-normal text-sm mb-0 font-body">
                    Cancellation reason
                  </p>
                  <div className="d-flex align-items-center">
                    <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                      {membershipDetails && membershipDetails.rejection_remark}
                    </p>
                  </div>
                </Col>}


              {/* Cabin Crew */}


              <Col xs={6} xl={3}>
                <p className="color-lightestblue font-normal text-sm mb-0 font-body">
                  Cabin crew member
                </p>
                <div className="d-flex align-items-center">
                  <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                    {userDetails.active_membership_name === "Amethyst" ? "Yes" : "No"}
                  </p>
                </div>
              </Col>

              {/*Cabin crew Images  */}

              <Col xs={6} xl={3}>
                <p className="color-lightestblue font-normal text-sm mb-0 font-body">
                  Membership Requested date
                </p>
                <div className="d-flex align-items-center">
                  <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                    {membershipDetails && moment(membershipDetails.createdAt.seconds * 1000).format("DD MMM YYYY")}
                  </p>
                </div>
              </Col>
              {membershipDetails && ((membershipDetails.cabin_crew_front_image_id) || (membershipDetails.cabin_crew_back_image_id)) &&
                < Col xs={6} xl={3}>
                  <p className="color-lightestblue font-normal text-sm mb-0 font-body">
                    Cabin crew ID
                  </p>
                  <div className="d-flex align-items-center">
                    <div className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                      <Button
                        variant="warning"
                        className="text-warning bold bg-white border-0 ps-0"
                        onClick={(e) => setAmeystModal(true)}
                      >
                        Click Here
                      </Button>
                    </div>
                  </div>
                </Col>
              }


              {/* Ameytst Plan */}

              {membershipDetails.cabin_crew &&
                <Col xs={6} xl={3}>
                  <p className="color-lightestblue font-normal text-sm mb-0 font-body">
                    Ameythest Plan
                  </p>
                  <p className="color-lightblue font-semibold text-md text-lg-lg mb-0 font-body text-wrap text-break">
                    {membershipDetails.membership_plan_id === "amethyst-one-year" ? "12 Months" : "3 Months"}
                  </p>
                </Col>}

              {(membershipDetails.status === "Pending") && (
                <Row>
                  {((membershipDetails.status === "Pending") && (membershipDetails.membership_name !== "Silver")) &&
                    <div className="d-flex justify-content-lg-end mt-12">
                      <OutlinedButton
                        onClick={(e) => setshowRejectionModal(true)}
                        name="Cancel"
                        additionalClass="me-3"
                      />

                      <SuccessButton
                        onClick={(e) => {
                          setAcceptModal(true)
                        }}
                        name="Accept"
                        icon="bi-check-circle"
                        additionalClass="px-5"
                      />
                    </div>
                  }
                </Row>
              )}
              {((membershipDetails.status == "waiting for payment confirmation" || (membershipDetails.status === "Pending" && membershipDetails.membership_name === "Silver"))) && (
                <Row>
                  {/* <div className={`d-flex ${(membershipDetails.cabinCrew) ? "justify-content-between" : "justify-content-end"}`}> */}

                  {
                    <div className="d-flex justify-content-lg-end mt-12">
                      <OutlinedButton
                        onClick={(e) => setshowRejectionModal(true)}
                        name="Cancel"
                        additionalClass="me-3"
                      />

                      <SuccessButton
                        onClick={(e) => handleAcceptButton("Approved")}
                        name="Approve"
                        icon="bi-check-circle"
                        additionalClass="px-5"
                      />
                    </div>
                  }
                </Row>
              )}
            </Row>
          </ContentWrapper>
        )
      }

      <Modal show={showModal} onHide={handleClose} size="lg"
        aria-labelledby="contained-modal-title-vcenter">
        <div className=" p-5 rounded font-body">

          <ModalBody>

            <div className="row g-4">
              <div className="col-12 d-flex justify-content-between">
                <h5 className="text-2xl  color-ternary mb-0">
                  Government ID Photos
                </h5>
                <div style={{ height: "40px", width: "40px", backgroundColor: "#090F17" }} className="cursor-pointer rounded-circle d-flex align-items-center justify-content-center" onClick={() => setShowModal(false)} name="Close">
                  <i className="bi bi-x-lg text-white"></i>
                </div>
              </div>
              {kycDetails && kycDetails.govt_id_front &&
                <div className="col-6">
                  <p className="text-xl mb-1">Front side of ID</p>
                  <Zoom>
                    <img
                      className=" rounded img-fluid"
                      src={kycDetails ? kycDetails.govt_id_front : ""}
                      alt="sdfsfd"
                      height="250px"
                      width="200px"
                    />
                  </Zoom>
                </div>}

              {kycDetails && kycDetails.govt_id_back &&
                <div className="col-6">
                  <p className="text-xl  mb-1">Back side of ID </p>
                  <Zoom>
                    <img
                      className=" rounded img-fluid"
                      src={kycDetails ? kycDetails.govt_id_back : ""}
                      alt="sdfsfd"
                      height="250px"
                      width="200px"
                    />
                  </Zoom>
                </div>}
            </div>
            <div className="col-12 d-flex justify-content-end">
              {/* <SecondaryButton
                onClick={() => setShowModal(false)}
                name="Close"
                additionalClass=" mt-5 "
              /> */}
            </div>
          </ModalBody>
        </div>
      </Modal>

      {/* Rejection modal */}

      <Modal
        show={showRejectionModal}
        onHide={() => {
          setshowRejectionModal(false);
        }}
      >
        <div className="background-appColor p-5 rounded font-body">
          <h5 className="text-2xl  color-ternary  mb-3">
            Reason For Cancellation
          </h5>
          <div></div>
          <div>
            <textarea
              className="po-edit-input"
              placeholder="Please enter reason..."
              onChange={(e) => {
                const str = e.target.value;
                if (str === "") {
                  setReason({
                    reason: "",
                    error: "Field should not be empty...",
                  });
                } else if (str.trim() === "") {
                  setReason({
                    reason: "",
                    error: "Whitespace should not allowed...",
                  });
                } else {
                  setReason({ reason: str, error: "" });
                }
              }}
            ></textarea>
            <p className="text-danger">{reason.error && reason.error}</p>
          </div>
          <div className="mt-6 text-end">
            <SecondaryButton
              onClick={() => {
                setshowRejectionModal(false);
                setReason({ reason: "", error: "" });
              }}
              name="Close"
              additionalClass="text-light me-3 px-5"
            />
            <SecondaryButton
              onClick={rejectButtonModalSubmit}
              name="Submit"
              additionalClass="text-light me-3 px-5"
            />
          </div>
        </div>
      </Modal>

      {/* Rejection modal */}
      {console.log(membershipDetails, "membershipDetailsmembershipDetails")}

      {/* Cover Charge  */}
      <Modal show={acceptModal} onHide={() => setAcceptModal(false)} >
        <div className="background-appColor p-5 rounded font-body">
          <h3 className="text-2xl color-ternary">
            Do you want to accept membership?
          </h3>
          <div className="mb-3">
            <label className="d-block mb-1 text-md text-light">
              Amount (You cannot change amount)
            </label>
            <input
              min={0}
              contentEditable="false"
              type="text"
              className="po-edit-input bg-white"
              value={"$ " + membershipDetails.amount}
              placeholder="Enter amount"
              disabled
              readOnly
            />
          </div>
          <div className="mb-3">
            <label className="d-block mb-1 text-md text-light">
              Payment Link
            </label>
            <input
              min={0}
              type="text"
              className="po-edit-input"
              onChange={(e) => {
                if (e.target.value === "") {
                  setPaymentLink({
                    ...paymentLink,
                    payment: e.target.value,
                    paymentError: "Field should not be empty"
                  })
                }
                else if (/^\s*$/.test(e.target.value)) {
                  setPaymentLink({
                    ...paymentLink,
                    payment: e.target.value,
                    paymentError: "Only whitespace not allowed"
                  })
                }
                else {
                  setPaymentLink({
                    ...paymentLink,
                    payment: e.target.value,
                    paymentError: ""
                  })
                }


              }}
              placeholder="Enter payment link"
            />
            {paymentLink.paymentError && <p className="text-danger mt-2">{paymentLink.paymentError}</p>

            }
          </div>

          <div className="d-flex justify-content-end">

            <>
              <SecondaryButton
                onClick={() => setAcceptModal(false)}
                name="Close"
                additionalClass="text-light me-3 px-5"
              />
              <PrimaryButton
                onClick={handleMembershipAcceptForm}
                name="Submit"
                type="submit"
              />
            </>

          </div>
        </div>
      </Modal>

      {/* Cover Charge  */}

      {/* Amethest  */}
      <Modal show={ameystModal} onHide={() => setAmeystModal(false)} size="lg"
        aria-labelledby="contained-modal-title-vcenter">
        <div className=" p-5 rounded font-body">

          <ModalBody>

            <div className="row g-4">
              <div className="col-12 d-flex justify-content-between">
                <h5 className="text-2xl  color-ternary mb-0">
                  Cabin Crew ID Photos
                </h5>
                <div style={{ height: "40px", width: "40px", backgroundColor: "#090F17" }} className="cursor-pointer rounded-circle d-flex align-items-center justify-content-center" onClick={() => setAmeystModal(false)} name="Close">
                  <i className="bi bi-x-lg text-white"></i>
                </div>
              </div>
              {membershipDetails && membershipDetails.cabin_crew_front_image_id ?
                <div className="col-6">
                  <p className="text-xl mb-1">Front side of ID</p>
                  <Zoom>
                    <img
                      className=" rounded img-fluid"
                      src={membershipDetails ? membershipDetails.cabin_crew_front_image_id : ""}
                      alt="sdfsfd"
                      height="250px"
                      width="200px"
                    />
                  </Zoom>
                </div> : <div><p>No Front ID Image</p></div>}

              {membershipDetails && membershipDetails.cabin_crew_back_image_id ?
                <div className="col-6">
                  <p className="text-xl  mb-1">Back side of ID </p>
                  <Zoom>
                    <img
                      className=" rounded img-fluid"
                      src={membershipDetails ? membershipDetails.cabin_crew_back_image_id : ""}
                      alt="sdfsfd"
                      height="250px"
                      width="200px"
                    />
                  </Zoom>
                </div> : <div><p>No Back ID Image</p></div>}
            </div>
            <div className="col-12 d-flex justify-content-end">
              {/* <SecondaryButton
                onClick={() => setShowModal(false)}
                name="Close"
                additionalClass=" mt-5 "
              /> */}
            </div>
          </ModalBody>
        </div>
      </Modal>

      <Toaster
        showToaster={showToaster}
        setShowToaster={setShowToaster}
        bg={toasterDetails.bg}
        title={toasterDetails.title}
        bodyText={toasterDetails.bodyText}
        statusIcon={toasterDetails.statusIcon}
      />
    </Layout >
  );
};

export default MembershipRequestDetails;
