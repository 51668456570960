import ContentWrapper from "../../Components/Global/ContentWrapper";
import Layout from "../../Layout/Layout";
import React, { useEffect, useRef, useState, useCallback } from "react";
import axios from "axios";
import PageDescription from "../../Components/Global/PageDescription";
import ConfirmModal from "../../Components/Global/Popup/ConfirmModal";
import PrimaryButton from "../../Components/Global/Buttons/PrimaryButton";
import Users from "../../Components/Global/Pagination/UserPagination";
import { useSearchParams } from "react-router-dom";
import FilterModalUser from "../../Components/Global/Popup/FilterModalUser";
import { and, collection, doc, endBefore, getCountFromServer, getDoc, getDocs, limit, limitToLast, or, orderBy, query, startAfter, where } from "firebase/firestore";
import db from "../../firebase";

const contents = {
  title: "Delete User",
  permission: "Are you sure you want to delete this user permanently?",
}
const UsersList = () => {
  const filterComponent = React.createRef();
  const [searchQuery, setSearchQuery] = useState("");
  const [usersData, setUsersData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [loader, setLoader] = useState(true);
  const [filterQuery, setFilterQuery] = useState({
    selectedCountries: [],
    selectedCities: []
  });
  const offset = useRef(0);
  const count = useRef(0);
  const filterIcon = useRef(null);
  const usersPerPage = useRef(20);
  const totalUsers = useRef(null);
  const totalFilteredUsers = useRef(undefined);
  const searchQueryRef = useRef("");
  const filterKeysRef = useRef({
    selectedCountries: [],
    selectedCities: []
  })
  const sortBy = useRef({
    orderByKey: "createdAt",
    order: "desc"
  })

  const [searchParams, setSearchParams] = useSearchParams();
  const fetchUsers = async (sortByParams, clear) => {
    totalFilteredUsers.current = undefined;
    if (clear) usersPerPage.current = 20;
    filterKeysRef.current = {
      selectedCountries: [],
      selectedCities: []
    }
    setFilterQuery(filterKeysRef.current);
    setLoader(true);
    if (sortByParams) {
      sortBy.current = sortByParams;
      count.current = 0;
      offset.current = count.current * usersPerPage.current;
    }
    sortByParams = sortByParams || sortBy.current;
    let responseUsers = await axios.get(`${process.env.REACT_APP_API_URL}/user/v3/all?limit=${usersPerPage.current}&sort_by=${sortByParams.orderByKey}-${sortByParams.order}&offset=${offset.current}`)
    totalFilteredUsers.current = undefined;
    totalUsers.current = responseUsers.data.count;

    if (responseUsers.data) {
      setUsersData(responseUsers.data.result);
      setLoader(false);
    }
  }


  const handleClickOutside = useCallback((event) => {
    if (filterComponent.current && !filterComponent.current.contains(event.target) && event.target !== filterIcon.current) {
      setShowFilterModal(false);
    }
  }, [filterComponent]);
  const handleShowAll = () => {
    setLoader(true);
    setSearchParams({ ...Object.fromEntries([...searchParams]), showAll: true })
    usersPerPage.current = totalUsers.current;
    if (Boolean(searchParams.get('filterQuery'))) {
      handleFilterUsers(filterKeysRef.current);
    } else if (Boolean(searchParams.get('searchQuery'))) {
      performSearch(searchQueryRef.current);
    }
  }

  // useEffect goes here ----------------------------------------------------------------------------------------

  useEffect(() => { // this useEffect is used when user will click other area than filter popup
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    if (Boolean(searchParams.get('usersPerPage'))) usersPerPage.current = +searchParams.get('usersPerPage');
    if (Boolean(searchParams.get('sortBy'))) {
      sortBy.current = JSON.parse(searchParams.get('sortBy'));
    }
    if (Boolean(searchParams.get('pageNo'))) {
      count.current = searchParams.get('pageNo') - 1;
      offset.current = count.current * usersPerPage.current;
    }
    if (Boolean(searchParams.get('searchQuery')) || Boolean(searchParams.get('filterQuery'))) {
      if (Boolean(searchParams.get('searchQuery'))) {
        searchQueryRef.current = searchParams.get('searchQuery');
        setSearchQuery(searchQueryRef.current);
        performSearch(searchQueryRef.current);
      } else {
        filterKeysRef.current = { ...JSON.parse(searchParams.get('filterQuery')) };
        setFilterQuery({ ...filterKeysRef.current });
        if (+searchParams.get('n') === 1) {
          setUsersData([]);
          onClickNextPage(searchParams.get('currentPageId'));
        } else if (searchParams.get('n') && +searchParams.get('n') === 0) {
          previousPageDataFunc(searchParams.get('currentPageId'));
        } else handleFilterUsers(filterKeysRef.current);
      }
      return;
    }
    fetchUsers();
  }, [])

  const handleSearch = (e) => {
    let inputValue = (e.target.value).trim();
    if (inputValue.trim().length > 0) {
      setSearchQuery(e.target.value);
      if (Boolean(searchParams.get('filterQuery'))) {
        searchParams.delete('filterQuery');
        filterKeysRef.current = {
          selectedCountries: [],
          selectedCities: []
        }
        setFilterQuery({ ...filterKeysRef.current })
      }
      if (Boolean(searchParams.get('pageNo'))) searchParams.delete('pageNo');
      searchQueryRef.current = e.target.value;
      if (Boolean(searchParams.get('showAll'))) {
        searchParams.delete('showAll');
        usersPerPage.current = 20;
        setSearchParams({ ...Object.fromEntries([...searchParams]), usersPerPage: usersPerPage.current, searchQuery: e.target.value });
      }
      if (e.target.value.length > 2) {
        performSearch(e.target.value);
        setSearchParams({ ...Object.fromEntries([...searchParams]), searchQuery: e.target.value });
      }
      if (Boolean(searchParams.get('searchQuery')) && e.target.value.length < 3) {
        searchParams.delete('searchQuery');
        setSearchParams(searchParams);
        setTimeout(() => {
          setUsersData([]);
          fetchUsers();
        }, 0)
      }
    }
    else {
      setSearchQuery("");
      console.log(searchParams, "searchParams")
      searchParams.delete('prevNext');
      searchParams.delete('currentPageId');
      searchParams.delete('filterQuery');
      searchParams.delete('n');
      searchParams.delete('searchQuery');
      setSearchParams(searchParams);
      setTimeout(() => {
        setUsersData([]);
        fetchUsers();
      }, 0)
      searchQueryRef.current = "";
      setSearchQuery(searchQueryRef.current);
    }
  }

  const performSearch = useCallback(
    debounce(async (val) => {
      if (searchQueryRef.current.length < 3) return;
      setUsersData([]);
      setLoader(true);
      if (count.current) {
        count.current = 0;
        offset.current = count.current * usersPerPage.current;
      }
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/v2/search?search_string=${encodeURIComponent(val)}&limit=${20}&offset=${0}`)
        totalFilteredUsers.current = undefined;
        totalUsers.current = response.data.result.length;
        setUsersData(response.data.result.splice(0, usersPerPage.current));
        setLoader(false);
      } catch (err) {
        console.log(err, 'err');
      }
    }, 1000),
    []);

  function debounce(fn, delay) {
    let timer;
    return function () {
      let context = this,
        args = arguments;
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(context, args);
      }, delay);
    };
  }

  // *************************** Filter Goes Here **************************************************************************!

  const handleFilterUsers = async (filterState, showAll, sortByParams) => {
    if (sortByParams) {
      sortBy.current = sortByParams;
      count.current = 0;
    }
    setLoader(true);
    if (showAll) usersPerPage.current = 20;
    setFilterQuery({ ...filterState });
    filterKeysRef.current = { ...filterState };
    setSearchQuery("");
    searchQueryRef.current = "";
    setUsersData([]);
    let arr = [];
    let countriesObj = {};
    filterKeysRef.current.selectedCities.forEach(item => {
      if (countriesObj[item.country]) {
        countriesObj[item.country] += 1;
      } else countriesObj[item.country] = 1
    })
    let cities = filterKeysRef.current.selectedCities.map(({ city }) => city);
    let countries = filterKeysRef.current.selectedCountries.map(item => item.toLowerCase()).filter(item => !countriesObj.hasOwnProperty(item));
    let countriesLength = countries.length;
    let citiesLength = cities.length;
    if (countriesLength && citiesLength) {
      arr = [where("home_country_name", "in", filterKeysRef.current.selectedCities.map(({ country }) => country)), where("home_city_name", "in", cities)]
    } else if (countriesLength) {
      arr = [where("home_country_name", "in", countries)]
    } else if (citiesLength) {
      arr = [where("home_country_name", "in", filterKeysRef.current.selectedCities.map(({ country }) => country)), where("home_city_name", "in", cities)]
    }
    else console.log("not applicable");
    const q = (countriesLength && citiesLength) ? query(collection(db, "users"), or(and(...arr), where("home_country_name", "in", countries))) : (countriesLength || citiesLength) && query(collection(db, "users"), and(...arr))
    const snapshot = await getCountFromServer(q);
    totalFilteredUsers.current = snapshot.data().count;
    let users_data = (countriesLength && citiesLength) ? await getDocs(query(collection(db, "users"), or(and(...arr), where("home_country_name", "in", countries)), limit(usersPerPage.current), orderBy(sortBy.current.orderByKey, sortBy.current.order))) : (countriesLength || citiesLength) && await getDocs(query(collection(db, "users"), and(...arr), limit(usersPerPage.current), orderBy(sortBy.current.orderByKey, sortBy.current.order)))
    if (users_data) {
      let localFilteredUsers = []
      users_data.size && users_data.forEach(user => {
        localFilteredUsers = [...localFilteredUsers, user.data()];
      })
      setUsersData([...localFilteredUsers].splice(0, usersPerPage.current));
      setLoader(false);
    }
  }
  const handleModifyFilter = (e, item, filterType) => {
    count.current = 0;
    searchParams.delete('n');
    searchParams.delete('currentPageId');
    searchParams.delete('pageNo');
    if (filterType === "country") {
      setFilterQuery({
        ...filterQuery,
        selectedCountries: filterQuery.selectedCountries.filter(country => country !== item)
      })

      filterKeysRef.current = {
        ...filterKeysRef.current,
        selectedCountries: filterKeysRef.current.selectedCountries.filter(country => country !== item)
      }
    }
    if (filterType === "city") {
      setFilterQuery({
        ...filterQuery,
        selectedCities: filterQuery.selectedCities.filter(city => city !== item)
      })
      filterKeysRef.current = {
        ...filterKeysRef.current,
        selectedCities: filterKeysRef.current.selectedCities.filter(city => city !== item)
      }
    }
    const currentParams = Object.fromEntries([...searchParams]);
    setSearchParams({ ...currentParams, filterQuery: JSON.stringify(filterKeysRef.current) });
    if (filterKeysRef.current.selectedCountries.length || filterKeysRef.current.selectedCities.length) {
      handleFilterUsers(filterKeysRef.current);
    } else {
      sortBy.current = {
        orderByKey: "createdAt",
        order: "desc",
      }
      usersPerPage.current = 20;
      count.current = 0;
      setSearchParams({ usersPerPage: usersPerPage.current });
      offset.current = 0;
      fetchUsers()
    }
  }
  // -------------------------------------------- Filter Finished -----------------------------------------

  const handleDeleteButton = async (id) => {
    setLoader(true);
    const myHeadersPayload = new Headers();
    myHeadersPayload.append("developement", "true");
    const requestOptions = {
      method: 'DELETE',
      headers: myHeadersPayload,
      redirect: 'follow'
    };
    fetch(`${process.env.REACT_APP_API_URL}/user/v1/deleteUser?uid=${id}`, requestOptions)
      .then(response => response.text())
      .then(result => {
        count.current = 0;
        offset.current = count.current * usersPerPage.current;
        searchParams.delete('searchQuery');
        setSearchParams(searchParams);
        setTimeout(() => {
          setUsersData([]);
          fetchUsers();
        }, 0)
        setSearchQuery("")
      })
      .catch(error => console.log('error', error));
  }

  const onDeleteHandler = (id) => {
    setShowModal(true);
    setDeleteId(id);
  }

  const onClickNextPage = async (currentDocId) => {
    setLoader(true);
    if (!currentDocId) count.current = count.current + 1;
    setUsersData([]);
    if (Boolean(searchParams.get('filterQuery'))) {
      try {
        const docSnap = !currentDocId ? await getDoc(doc(collection(db, "users"), usersData[usersData.length - 1].id)) : await getDoc(doc(collection(db, "users"), currentDocId));
        if (!currentDocId) setSearchParams({ ...Object.fromEntries([...searchParams]), pageNo: count.current + 1, currentPageId: docSnap.id, n: 1 });
        let arr = [];
        let countriesObj = {};
        filterKeysRef.current.selectedCities.forEach(item => {
          if (countriesObj[item.country]) {
            countriesObj[item.country] += 1;
          } else countriesObj[item.country] = 1
        })
        let cities = filterKeysRef.current.selectedCities.map(({ city }) => city);
        let countries = filterKeysRef.current.selectedCountries.map(item => item.toLowerCase()).filter(item => !countriesObj.hasOwnProperty(item));
        let countriesLength = countries.length;
        let citiesLength = cities.length;
        if (countriesLength && citiesLength) {
          arr = [where("home_country_name", "in", filterKeysRef.current.selectedCities.map(({ country }) => country)), where("home_city_name", "in", cities)]
        } else if (countriesLength) {
          arr = [where("home_country_name", "in", countries)]
        } else if (citiesLength) {
          arr = [where("home_country_name", "in", filterKeysRef.current.selectedCities.map(({ country }) => country)), where("home_city_name", "in", cities)]
        }
        else console.log("not applicable");
        const q = (countriesLength && citiesLength) ? query(collection(db, "users"), or(and(...arr), where("home_country_name", "in", countries))) : (countriesLength || citiesLength) && query(collection(db, "users"), and(...arr))
        const snapshot = await getCountFromServer(q);
        totalFilteredUsers.current = snapshot.data().count;
        let responseUsers = (countriesLength && citiesLength) ? await getDocs(query(collection(db, "users"), or(and(...arr), where("home_country_name", "in", countries)), limit(usersPerPage.current), orderBy(sortBy.current.orderByKey, sortBy.current.order), startAfter(docSnap))) : await getDocs(query(collection(db, "users"), and(...arr), limit(usersPerPage.current), orderBy(sortBy.current.orderByKey, sortBy.current.order), startAfter(docSnap)))
        responseUsers.forEach((userDetails) => {
          setUsersData(prev => [...prev, userDetails.data()])
        })
        setLoader(false);
      } catch (error) {
        console.log(error, 'error');
      }
    } else {
      offset.current = count.current * usersPerPage.current;
      searchParams.delete('searchQuery');
      setSearchParams({ ...Object.fromEntries([...searchParams]), pageNo: count.current + 1 });
      let responseUsers = await axios.get(`${process.env.REACT_APP_API_URL}/user/v3/all?limit=${usersPerPage.current}&sort_by=${sortBy.current.orderByKey}-${sortBy.current.order}&offset=${offset.current}`)
      if (responseUsers.data) {
        setUsersData(responseUsers.data.result);
        setLoader(false);
      }
    }
  }
  const previousPageDataFunc = async (currentDocId) => {
    setLoader(true);
    setUsersData([]);
    if (!currentDocId) count.current = count.current - 1;
    if (Boolean(searchParams.get('filterQuery'))) {
      if (!count.current) {
        handleFilterUsers(filterKeysRef.current);
        searchParams.delete('pageNo');
        searchParams.delete('currentPageId');
        searchParams.delete('n');
        setSearchParams(searchParams);
        return
      }
      try {
        const docSnap = !currentDocId ? await getDoc(doc(collection(db, "users"), usersData[0].id)) : await getDoc(doc(collection(db, "users"), currentDocId));
        if (!currentDocId) setSearchParams({ ...Object.fromEntries([...searchParams]), pageNo: count.current + 1, currentPageId: docSnap.id, n: 0 });
        let arr = [];
        let countriesObj = {};
        filterKeysRef.current.selectedCities.forEach(item => {
          if (countriesObj[item.country]) {
            countriesObj[item.country] += 1;
          } else countriesObj[item.country] = 1
        })
        let cities = filterKeysRef.current.selectedCities.map(({ city }) => city);
        let countries = filterKeysRef.current.selectedCountries.map(item => item.toLowerCase()).filter(item => !countriesObj.hasOwnProperty(item));
        let countriesLength = countries.length;
        let citiesLength = cities.length;
        if (countriesLength && citiesLength) {
          arr = [where("home_country_name", "in", filterKeysRef.current.selectedCities.map(({ country }) => country)), where("home_city_name", "in", cities)]
        } else if (countriesLength) {
          arr = [where("home_country_name", "in", countries)]
        } else if (citiesLength) {
          arr = [where("home_country_name", "in", filterKeysRef.current.selectedCities.map(({ country }) => country)), where("home_city_name", "in", cities)]
        }
        else console.log("not applicable");

        const q = (countriesLength && citiesLength) ? query(collection(db, "users"), or(and(...arr), where("home_country_name", "in", countries))) : (countriesLength || citiesLength) && query(collection(db, "users"), and(...arr))
        const snapshot = await getCountFromServer(q);
        totalFilteredUsers.current = snapshot.data().count;
        let responseUsers = (countriesLength && citiesLength) ? await getDocs(query(collection(db, "users"), or(and(...arr), where("home_country_name", "in", countries)), orderBy(sortBy.current.orderByKey, sortBy.current.order), endBefore(docSnap), limitToLast(+usersPerPage.current),)) : await getDocs(query(collection(db, "users"), and(...arr), orderBy(sortBy.current.orderByKey, sortBy.current.order), endBefore(docSnap), limitToLast(+usersPerPage.current)))
        responseUsers.forEach((userDetails) => {
          setUsersData(prev => [...prev, userDetails.data()])
        })
        setLoader(false);
        return;
      }
      catch (error) {
        console.log(error, 'error');
      }
    }
    offset.current = count.current * usersPerPage.current;
    searchParams.delete('searchQuery');
    if (!count.current) {
      fetchUsers();
      searchParams.delete('offset');
      setSearchParams({ ...Object.fromEntries([...searchParams]), pageNo: count.current + 1 });
      return;
    } else setSearchParams({ ...Object.fromEntries([...searchParams]), pageNo: count.current + 1 });
    let responseUsers = await axios.get(`${process.env.REACT_APP_API_URL}/user/v3/all?limit=${usersPerPage.current}&sort_by=${sortBy.current.orderByKey}-${sortBy.current.order}&offset=${offset.current}`);
    if (responseUsers.data) {
      setUsersData(responseUsers.data.result);
      setLoader(false);
    }
  }

  const handlerItemsPerPage = (e) => {
    usersPerPage.current = +e.target.value;
    count.current = 0;
    offset.current = count.current * usersPerPage.current;
    setSearchParams({ ...Object.fromEntries([...searchParams]), usersPerPage: usersPerPage.current })
    if (Boolean(searchParams.get('filterQuery'))) {
      handleFilterUsers(filterKeysRef.current);
    } else if (Boolean(searchParams.get('searchQuery'))) {
      performSearch(searchQueryRef.current);
    } else fetchUsers();
  }
  return (
    <Layout path="/users" additionalclass="d-flex flex-column">
      <div className="d-flex justify-content-between align-items-center gap-3 gap-lg-5 font-body">
        <PageDescription title="All Users" caption="" />
        <PrimaryButton link="/users/create-user" name="Add User" icon="bi-plus-circle" />
      </div>
      <div className='row d-flex justify-content-between g-3'>
        <div className='col-5 col-xxl-4'>
          <input type="search" className="po-edit-input mb-1" placeholder="Search..." value={searchQuery} onChange={handleSearch} />
          {searchQuery.length > 2 ? "" :
            <i className="bi bi-info-circle-fill d-inline-block text-md color-yellow1 me-2 mb-3">
              <span className=" text-sm ml-1 text-dark">
                Atleast three characters are required for search.
              </span>
            </i>}
          {Boolean(searchParams.get('searchQuery')) || Boolean(searchParams.get('filterQuery')) ? <div className={"mb-2 text-md ps-2"} >No. of users displaying: <span className="font-bold ms-3">{!loader ? totalFilteredUsers.current ?? totalUsers.current : "....."}</span></div> : ""}
        </div>
        <div className='col-6 col-xxl-5'>
          <div className='text-end position-relative'>
            <button className='border-0 bg-transparent' type='button' onClick={() => { setShowFilterModal(prev => !prev) }}><i className={`bi bi-funnel-fill text-xl ${showFilterModal ? "color-yellow1" : ""}`} ref={filterIcon}></i></button>
            <FilterModalUser
              ref={filterComponent}
              showModal={showFilterModal}
              setShowModal={setShowFilterModal}
              collectionRecall={fetchUsers}
              handleFilterUsers={handleFilterUsers}
            />
          </div>
        </div>
      </div>
      <div className={`mt-3 ${!filterQuery.selectedCountries.length && !filterQuery.selectedCities.length ? "d-none" : ""}`}>
        {filterQuery && filterQuery.selectedCountries && filterQuery.selectedCountries.length ? <div className='d-flex align-items-start my-3 gap-3 text-sm font-medium'>
          <span className='text-nowrap'>Selected Countries</span> :
          <div className='d-flex align-items-start flex-wrap gap-3'>
            {filterQuery && filterQuery.selectedCountries && filterQuery.selectedCountries.map((item, index) => <div
              className='text-xxs font-medium pl-3 pe-0 py-1 border-1 background-gradient rounded-pill text-capitalize d-flex align-items-center justify-content-between'
              key={index}
            >
              {item}
              <button className='bg-transparent border-0 d-flex align-items-center' onClick={(e) => handleModifyFilter(e, item, 'country')}>
                <i className="bi bi-x-circle-fill text-md lineheight-0"></i>
              </button>
            </div>
            )}
          </div>
        </div> : ""}
        {filterQuery && filterQuery.selectedCities && filterQuery.selectedCities.length ? <div className='d-flex align-items-start my-3 gap-3 text-sm font-medium'>
          <span className='text-nowrap'>Selected Cities</span> :
          <div className='d-flex align-items-start flex-wrap gap-3'>
            {filterQuery && filterQuery.selectedCities && filterQuery.selectedCities.map((item, index) => <div
              className='text-xxs font-medium pl-3 pe-0 py-1 border-1 background-gradient rounded-pill text-capitalize d-flex align-items-center justify-content-between'
              key={index}
            >
              {item.city}
              <button className='bg-transparent border-0 d-flex align-items-center' onClick={(e) => handleModifyFilter(e, item, 'city')}>
                <i className="bi bi-x-circle-fill text-md lineheight-0"></i>
              </button>
            </div>
            )}
          </div>
        </div> : ""}
      </div>
      <ContentWrapper additionalClass="font-body">
        <Users
          currentItems={usersData}
          loader={loader}
          count={count.current}
          usersPerPage={usersPerPage.current}
          handleFilterUsers={handleFilterUsers}
          fetchUsers={fetchUsers}
          onDeleteHandler={onDeleteHandler}
        />
        <div className="d-flex justify-content-end align-items-center py-4 me-4 text-xs">
          {((usersData.length !== totalUsers.current) || (usersData.length !== totalFilteredUsers.current)) && !Boolean(searchParams.get('showAll')) ? <div className="text-xs">
            Rows per page:
            <div className="d-inline-block ms-1 ms-xl-3">
              <select name="item-per-page" id="item-per-page" value={usersPerPage.current} className="border-0 shadow-none font-medium text-xs" onChange={handlerItemsPerPage}>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
          </div> : ""}
          {Boolean(searchParams.get('searchQuery')) && totalUsers.current > usersData.length ?
            <button
              className="bg-transparent border-1 rounded-3 ml-12"
              type="button"
              onClick={handleShowAll}
            >Show All</button> : ""}
          {!Boolean(searchParams.get('searchQuery')) ? <div className="d-flex gap-2 justify-content-end align-items-center py-4 me-4 text-xs" style={{ height: 66 }}>
            {!loader ?
              !Boolean(searchParams.get('filterQuery')) ?
                <div className="mx-12 font-medium">
                  {count.current * usersPerPage.current + 1}-{(count.current + 1) * usersPerPage.current < totalUsers.current ? (count.current + 1) * usersPerPage.current : totalUsers.current} of {totalUsers.current}
                </div>
                :
                <div className="mx-12 font-medium">
                  {usersPerPage.current < totalFilteredUsers.current ? count.current * usersPerPage.current + 1 : 1}-{(count.current + 1) * usersPerPage.current < totalFilteredUsers.current ? (count.current + 1) * usersPerPage.current : totalFilteredUsers.current} of {totalFilteredUsers.current}
                </div>
              : <div className="mx-12 font-medium">loading...</div>}
            <button className={`font-body font-bold rounded-circle po-paginationBtn d-inline-block bg-transparent ${(!count.current || loader) ? "po-border border-secondary" : "color-black1 po-border po-border-yellow1"}`} type="button"
              onClick={() => previousPageDataFunc()}
              disabled={!count.current || loader}
            ><i className="bi bi-chevron-left text-black font-black text-lg"></i></button>
            {!Boolean(searchParams.get('filterQuery')) ?
              <button
                className={`font-body font-bold rounded-circle po-paginationBtn po-paginationBtn--next d-inline-block bg-transparent ${loader || Math.ceil(totalUsers.current / usersPerPage.current) === count.current + 1 ? "po-border border-secondary" : "color-black1 po-border po-border-yellow1"}`} type="button"
                onClick={() => onClickNextPage()}
                disabled={loader || Math.ceil(totalUsers.current / usersPerPage.current) === count.current + 1}><i className="bi bi-chevron-right text-black font-black text-lg"></i>
              </button> :
              <button
                className={`font-body font-bold rounded-circle po-paginationBtn po-paginationBtn--next d-inline-block bg-transparent ${loader || Math.ceil(totalFilteredUsers.current / usersPerPage.current) === count.current + 1 ? "po-border border-secondary" : "color-black1 po-border po-border-yellow1"}`} type="button"
                onClick={() => onClickNextPage()}
                disabled={loader || Math.ceil(totalFilteredUsers.current / usersPerPage.current) === count.current + 1}><i className="bi bi-chevron-right text-black font-black text-lg"></i>
              </button>
            }
          </div> : ""}
        </div>
        <ConfirmModal
          showModal={showModal}
          setShowModal={setShowModal}
          contents={contents}
          Deleteid={deleteId}
          handleDeleteButton={handleDeleteButton}
        />
      </ContentWrapper>
    </Layout>
  )
}
export default UsersList;