import ContentWrapper from "../../Components/Global/ContentWrapper";
import PageDescription from "../../Components/Global/PageDescription";
import Layout from "../../Layout/Layout";
import { Table, Modal } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import React, { useCallback, useEffect, useState } from 'react'
import db from "../../firebase";
import moment from 'moment';
import { doc, deleteDoc, getDoc, collection, getDocs, query, orderBy, startAfter, limit, getCountFromServer, endBefore, limitToLast, startAt, endAt, where, and } from "firebase/firestore";
import deleteClub from '..//../helpers/deleteClub';
import PrimaryButton from "../../Components/Global/Buttons/PrimaryButton";
import Dropdown from "react-bootstrap/Dropdown";
import SecondaryButton from "../../Components/Global/Buttons/SecondaryButton";
import Skeleton from "react-loading-skeleton";
import { useRef } from "react";
import FilterModalClub from "../../Components/Global/Popup/FilterModalClub";
import axios from "axios";

const sortIcon = "/assets/icons/icon-sort.svg";
const sortIconAsc = "/assets/icons/icon-sort-asc.svg";
const sortIconDesc = "/assets/icons/icon-sort-desc.svg";

const Clubs = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const clubsPerPage = useRef(20);
  const count = useRef(0);
  const changeOrder = useRef(0);
  const nextPage = useRef(1);
  const currentPageId = useRef("");
  const sortBy = useRef({
    orderByKey: "createdAt",
    order: "desc"
  })
  const filterKeysRef = useRef({
    selectedLocations: [],
    selectedStatus: []
  });
  const localIdBasedSortIcon = useRef("");
  const targetElementForSort = useRef("");
  const searchQueryInputRef = useRef("");
  const filterIcon = useRef(null);
  const [show, setShow] = useState({ show: false, data: null });
  const [loader, setLoader] = useState(true);
  const [clubs, setClubs] = useState([]);
  const [totalClubs, setTotalClubs] = useState(null);
  const [pageCount, setPageCount] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterKeys, setFilterKeys] = useState({
    selectedLocations: [],
    selectedStatus: []
  })
  const clubPageNo = searchParams.get('clubPageNo');
  const filterComponent = React.createRef();

  // Table Height : Start
  const [firstSectionHeight, setFirstSectionHeight] = useState(0);
  const secondSectionHeight = `calc(60vh - ${firstSectionHeight}px)`;
  // Calculate the height of the first section on mount and window resize
  useEffect(() => {
    const calculateHeight = () => {
      const firstSection = document.getElementById('first-section');
      if (firstSection) {
        setFirstSectionHeight(firstSection.clientHeight);
      }
    };

    calculateHeight();
    window.addEventListener('resize', calculateHeight);

    return () => {
      window.removeEventListener('resize', calculateHeight);
    };
  }, []);
  // Table Height : End


  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setShow({ show: true, data: clubs[id] });
  };

  const handleClickOutside = useCallback((event) => {
    if (filterComponent.current && !filterComponent.current.contains(event.target) && event.target !== filterIcon.current) {
      setShowModal(false);
    }
  }, [filterComponent]);


  // removing cache
  const handleCache = async () => {
    const token = localStorage.getItem("authenticate");
    const headers = {
      headers: {
        developement: true,
        token: token,
      },
    };
    await axios
      .post(`${process.env.REACT_APP_API_URL}/dashboard/v1/clearCache`, {}, headers)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };


  // useEffect goes here ----------------------------------------------------------------------------------------

  useEffect(() => { // this useEffect is used when user will click other area than filter popup
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handleClickOutside]);

  useEffect(() => { //on the very first render of component and on page refresh
    if (Boolean(searchParams.get('sortBy'))) {
      sortBy.current = JSON.parse(searchParams.get('sortBy'));
      changeOrder.current = +searchParams.get('changeOrder');
      targetElementForSort.current = JSON.parse(searchParams.get('sortBy')).orderByKey;
      localIdBasedSortIcon.current = targetElementForSort.current;
    }
    if (Boolean(searchParams.get('itemsPerPage'))) clubsPerPage.current = +searchParams.get('itemsPerPage');
    if (Boolean(searchParams.get('searchQuery')) || Boolean(searchParams.get('filterQuery'))) {
      if (Boolean(searchParams.get('searchQuery'))) {
        searchQueryInputRef.current = searchParams.get('searchQuery');
        setSearchQuery(searchQueryInputRef.current);
        fetchSearchedClubs(searchQueryInputRef.current);
      } else handleFilterClubs(JSON.parse(searchParams.get('filterQuery')));
    }
    else {
      fetchClubsCount();
      nextPage.current = +searchParams.get("n"); // for tracking which button is clicked next or prev. it will help when page reload to identify which query should be used in order to reload the page(startAfter or lastToEnd)
      count.current = Boolean(clubPageNo) ? clubPageNo - 1 : count.current;
      currentPageId.current = searchParams.get('currentPageId');
      if (!Boolean(clubPageNo)) {
        fetchData();
      }
      else pageReloadFunc();
    }
  }, [])
  // useEffect ends here***********************************************************

  const fetchClubsCount = async () => { // It is giving me total no. of clubs and no. of pazges
    const clubsCount = await getCountFromServer(query(collection(db, "clubs"), orderBy(sortBy.current.orderByKey, sortBy.current.order)));
    setTotalClubs(clubsCount.data().count);
    setPageCount(Math.ceil(clubsCount.data().count / clubsPerPage.current));
  }
  const fetchData = () => { // this function will execute on first render only when count value will be zero or clubPageNo will be 1 and if items per page will change
    setLoader(true);
    setFilterKeys({
      selectedLocations: [],
      selectedStatus: []
    })
    filterKeysRef.current = {
      selectedLocations: [],
      selectedStatus: []
    }
    if (Boolean(searchParams.get('filterQuery'))) {
      clubsPerPage.current = 20;
      localIdBasedSortIcon.current = "";
    }
    getDocs(query(collection(db, "clubs"), orderBy(sortBy.current.orderByKey, sortBy.current.order), limit(clubsPerPage.current)))
      .then((docs) => {
        setClubs([]);
        docs.forEach((doc) => {
          setClubs((prev) => [...prev, doc.data()]);
        });
        setSearchParams({ itemsPerPage: clubsPerPage.current, sortBy: JSON.stringify(sortBy.current), changeOrder: changeOrder.current });
        setLoader(false);
      })
      .catch((err) => {
        console.log(err, "err");
        setLoader(false);
      });
  }
  const pageReloadFunc = useCallback(async () => {
    clubsPerPage.current = +searchParams.get('itemsPerPage');
    setLoader(true);
    if (nextPage.current === 1) {
      const docSnap = count.current && await getDoc(doc(collection(db, "clubs"), currentPageId.current));
      getDocs(query(collection(db, "clubs"), orderBy(sortBy.current.orderByKey, sortBy.current.order), startAfter(docSnap), limit(clubsPerPage.current)))
        .then((docs) => {
          setClubs([]);
          docs.forEach((doc) => {
            setClubs((prev) => [...prev, doc.data()]);
          });
          setLoader(false);
        })
        .catch((err) => {
          console.log(err, "err");
          setLoader(false);
        });

    } else {
      const docSnap = count.current && await getDoc(doc(collection(db, "clubs"), currentPageId.current));
      getDocs(query(collection(db, "clubs"), orderBy(sortBy.current.orderByKey, sortBy.current.order), endBefore(docSnap), limitToLast(+clubsPerPage.current)))
        .then((docs) => {
          setClubs([]);
          docs.forEach((doc) => {
            setClubs((prev) => [...prev, doc.data()]);
          });
          setLoader(false);
        })
        .catch((err) => {
          console.log(err, "err");
          setLoader(false);
        });

    }

  }, [nextPage])

  const handleDelete = async (data) => {
    try {
      await deleteDoc(doc(db, "clubs", data.id)).then(() => {
        handleCache();
        searchParams.delete("searchQuery");
        setSearchQuery("");
        setClubs([]);
        fetchClubsCount()
        fetchData();
      }).catch((err) => {
        console.error(err)
      })

      if (Boolean(searchParams.get("searchQuery"))) {
        // localIdBasedSortIcon.current="";
        fetchSearchedClubs(searchParams.get("searchQuery"));
      }
      else if (Boolean(searchParams.get("filterQuery"))) {
        // localIdBasedSortIcon.current="";
        handleFilterClubs(JSON.parse(searchParams.get("filterQuery")))
      }
      else {
        count.current = 0;
        fetchData();
        fetchClubsCount();
      }
      handleClose();
    } catch (err) {
      console.log(err);
    }
  };
  const onClickHandler = (id, type) => {
    if (id === undefined && type === "Add") {
      navigate("/add-club")
      return;
    }
    if (type === "update" && id != null) {
      navigate(`/clubs/edit-club/${id}`);
    }
    if (type === "delete" && id != null) {
      async function callDeleteClub() {
        deleteClub(id);
      }
      callDeleteClub();
    }
  }

  // Items Per Page Change **************************************************

  const handlerItemsPerPage = (e) => { // I am recalling the fetchData func when items per page whenever change but not calling fetchClubsCount because it won't change if items per page will change
    setLoader(true);
    clubsPerPage.current = +e.target.value;
    const currentParams = Object.fromEntries([...searchParams]);
    setSearchParams({ ...currentParams, itemsPerPage: clubsPerPage.current });
    count.current = 0;
    if (!Boolean(searchParams.get('searchQuery')) && !Boolean(searchParams.get('filterQuery'))) {
      fetchClubsCount(); // this function is called because on items per page change no of page will change
      fetchData();
    }
    else if (Boolean(searchParams.get("searchQuery"))) fetchSearchedClubs(searchParams.get("searchQuery"));
    else if (Boolean(searchParams.get("filterQuery"))) handleFilterClubs(JSON.parse(searchParams.get("filterQuery")))
    else console.log(searchParams.get("searchQuery"), 'endingg');
  }

  // Sorting Goes Here ***************************************************************************

  const handleSorting = (e, localIdParam) => {
    if (count.current) { // coming back to first page when you will be on other page
      count.current = 0;
      changeOrder.current = 0;
    }
    if (targetElementForSort.current !== localIdParam) {
      changeOrder.current = 0;
      targetElementForSort.current = localIdParam;
    }
    if (searchParams.get('searchQuery') || searchParams.get('filterQuery')) {
      if (Boolean(searchParams.get('searchQuery'))) {
        if (!changeOrder.current) {
          localIdBasedSortIcon.current = localIdParam;
          changeOrder.current = changeOrder.current + 1;
          sortBy.current = { orderByKey: targetElementForSort.current, order: "asc" }
          setClubs([...clubs].sort((a, b) => a[targetElementForSort.current] > b[targetElementForSort.current] ? 1 : a[targetElementForSort.current] < b[targetElementForSort.current] ? -1 : 0).splice(0, clubsPerPage.current));
        }
        else if (changeOrder.current === 1) {
          changeOrder.current = changeOrder.current + 1;
          sortBy.current = { orderByKey: targetElementForSort.current, order: "desc" }
          setClubs([...clubs].sort((a, b) => a[targetElementForSort.current] < b[targetElementForSort.current] ? 1 : a[targetElementForSort.current] > b[targetElementForSort.current] ? -1 : 0).splice(0, clubsPerPage.current));
        }
        else {
          changeOrder.current = 0;
          setLoader(true);
          sortBy.current = { orderByKey: "createdAt", order: "desc" }
          fetchSearchedClubs(searchParams.get('searchQuery'));
        }
      }
      else {
        if (!changeOrder.current) {
          localIdBasedSortIcon.current = localIdParam;
          changeOrder.current = changeOrder.current + 1;
          sortBy.current = { orderByKey: targetElementForSort.current, order: "asc" }
          setClubs([...clubs].sort((a, b) => a[targetElementForSort.current] > b[targetElementForSort.current] ? 1 : a[targetElementForSort.current] < b[targetElementForSort.current] ? -1 : 0).splice(0, clubsPerPage.current));
        }
        else if (changeOrder.current === 1) {
          changeOrder.current = changeOrder.current + 1;
          sortBy.current = { orderByKey: targetElementForSort.current, order: "desc" }
          setClubs([...clubs].sort((a, b) => a[targetElementForSort.current] < b[targetElementForSort.current] ? 1 : a[targetElementForSort.current] > b[targetElementForSort.current] ? -1 : 0).splice(0, clubsPerPage.current));
        }
        else {
          changeOrder.current = 0;
          setLoader(true);
          sortBy.current = { orderByKey: "createdAt", order: "desc" }
          handleFilterClubs(JSON.parse(searchParams.get('filterQuery')));
        }
      }
    }
    else {
      if (!changeOrder.current) {
        changeOrder.current = changeOrder.current + 1;
        localIdBasedSortIcon.current = localIdParam;
        sortBy.current = { orderByKey: targetElementForSort.current, order: "asc" }
        fetchData();
      }
      else if (changeOrder.current === 1) {
        changeOrder.current = changeOrder.current + 1;
        sortBy.current = { orderByKey: targetElementForSort.current, order: "desc" }
        fetchData();
      }
      else {
        changeOrder.current = 0;
        sortBy.current = { orderByKey: "createdAt", order: "desc" }
        fetchData();
      }
      // const currentParams = Object.fromEntries([...searchParams]);
      // setSearchParams({...currentParams, sortBy: JSON.stringify(sortBy.current), changeOrder: changeOrder.current});
    }
    const currentParams = Object.fromEntries([...searchParams]);
    setSearchParams({ ...currentParams, sortBy: JSON.stringify(sortBy.current), changeOrder: changeOrder.current });
  }

  // Sorting Finished Here -----------------------------------------------------------------------

  // Searching ****************************************************************************!

  const handleSearchQuery = (e) => {
    let inputValue = (e.target.value).trim();
    if (inputValue.trim().length > 0) {
      setLoader(true);
      // localIdBasedSortIcon.current="";
      setSearchQuery(e.target.value);
      if (Boolean(searchParams.get('filterQuery'))) clubsPerPage.current = +searchParams.get('itemsPerPage')
      setFilterKeys({
        selectedLocations: [],
        selectedStatus: []
      })

      filterKeysRef.current = {
        selectedLocations: [],
        selectedStatus: []
      }
      searchQueryInputRef.current = e.target.value;
      if (e.target.value) {
        console.log(e.target.value, "aaaaaaa")
        fetchSearchedClubs(e.target.value);
      }
      else {
        setSearchParams({ itemsPerPage: clubsPerPage.current, sortBy: JSON.stringify(sortBy.current), changeOrder: changeOrder.current });
        setTimeout(() => {
          setClubs([]);
          fetchClubsCount()
          fetchData();
        }, 1000)

      }
    }
    else {
      setSearchQuery("");
      console.log(searchParams, "searchParams")
      searchParams.delete('prevNext');
      searchParams.delete('currentPageId');
      searchParams.delete('filterQuery');
      searchParams.delete('n');
      searchParams.delete('searchQuery');
      setSearchParams(searchParams);
      setTimeout(() => {
        setClubs([]);
        fetchClubsCount()
        fetchData();
      }, 1000)
      searchQueryInputRef.current = "";
      setSearchQuery(searchQueryInputRef.current);
    }
  }
  const fetchSearchedClubs = useCallback(
    debounce((val) => {
      count.current = 0;
      // setClubs([]);
      if (searchQueryInputRef.current) {
        setLoader(true);
        const searchedClubByName = query(collection(db, "clubs"), orderBy("name_lower_case"), startAt(val.replace(/\s+/g, ' ').trim().toLowerCase()), endAt(`${val.toLowerCase()}\uf8ff`));
        const searchedClubByManagerName = query(collection(db, "clubs"), orderBy("manager_name_lower_case"), startAt(val.replace(/\s+/g, ' ').trim().toLowerCase()), endAt(`${val.toLowerCase()}\uf8ff`));
        const searchedClubByLocation = query(collection(db, "clubs"), orderBy("city_name_lower_case"), startAt(val.replace(/\s+/g, ' ').trim().toLowerCase()), endAt(`${val.toLowerCase()}\uf8ff`));
        let localSearchedArray = [];
        getDocs(searchedClubByName).then((docSnapshots) => {
          docSnapshots.forEach((docs) => {
            localSearchedArray = [...localSearchedArray, docs.data()]
          });
        });
        getDocs(searchedClubByManagerName).then((docSnapshots) => {
          docSnapshots.forEach((docs) => {
            localSearchedArray = [...localSearchedArray, docs.data()]
          });
        });
        getDocs(searchedClubByLocation).then((docSnapshots) => {
          const currentParams = Object.fromEntries([...searchParams]);
          setSearchParams({ ...currentParams, clubPageNo: count.current + 1, itemsPerPage: clubsPerPage.current, searchQuery: val, sortBy: JSON.stringify(sortBy.current), changeOrder: changeOrder.current });
          // setSearchParams({...currentParams, searchQuery: val});
          docSnapshots.forEach((docs) => {
            localSearchedArray = [...localSearchedArray, docs.data()]
          });
          uniqueSearchedClubs(localSearchedArray);
          setLoader(false);
        });
      }
    }, 1000),
    []);
  const uniqueSearchedClubs = (localSearchedArray) => {
    if (!changeOrder.current) {
      setClubs([...new Map(localSearchedArray.map(item =>
        [item['id'], item])).values()].splice(0, clubsPerPage.current));
    }
    else if (changeOrder.current === 1) {
      setClubs([...new Map(localSearchedArray.map(item =>
        [item['id'], item])).values()].sort((a, b) => a[targetElementForSort.current] > b[targetElementForSort.current] ? 1 : a[targetElementForSort.current] < b[targetElementForSort.current] ? -1 : 0).splice(0, clubsPerPage.current));
      // setSortedEvents([...tableData].sort((a, b) => a[targetElementId.current] > b[targetElementId.current] ? 1 : a[targetElementId.current] < b[targetElementId.current] ? -1 : 0 ).splice(0,items));
    }
    else {

      setClubs([...new Map(localSearchedArray.map(item =>
        [item['id'], item])).values()].sort((a, b) => a[targetElementForSort.current] < b[targetElementForSort.current] ? 1 : a[targetElementForSort.current] > b[targetElementForSort.current] ? -1 : 0).splice(0, clubsPerPage.current));
      // setSortedEvents([...tableData].sort((a, b) => a[targetElementId.current] < b[targetElementId.current] ? 1 : a[targetElementId.current] > b[targetElementId.current] ? -1 : 0 ).splice(0,items));
    }
    setTotalClubs([...new Map(localSearchedArray.map(item =>
      [item['id'], item])).values()].length)

  }

  function debounce(fn, delay) {
    let timer;
    return function () {
      let context = this,
        args = arguments;
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(context, args);
      }, delay);
    };
  }

  // Searching Finished Here ---------------------------------------------------------------------------------

  const previousPageDataFunc = async () => { // this function will be called when previous button clicked
    setLoader(true);
    count.current = count.current - 1;
    if (!count.current) {
      fetchData();
      setSearchParams({});
      return;
    }
    const docSnap = count.current && await getDoc(doc(collection(db, "clubs"), clubs[0].id));
    getDocs(query(collection(db, "clubs"), orderBy(sortBy.current.orderByKey, sortBy.current.order), endBefore(docSnap), limitToLast(+clubsPerPage.current)))
      .then((docs) => {
        setClubs([]);
        docs.forEach((doc) => {
          setClubs((prev) => [...prev, doc.data()]);
        });
        setSearchParams({ clubPageNo: count.current + 1, prevNext: JSON.stringify([docs.docs[0].id, docs.docs[docs.docs.length - 1].id]), currentPageId: docSnap.id, itemsPerPage: clubsPerPage.current, n: 0, sortBy: JSON.stringify(sortBy.current), changeOrder: changeOrder.current })

        // if count value will be greater than 0 then will set data in the url otherwise nothing needs to set in the url
        setLoader(false);
      })
      .catch((err) => {
        console.log(err, "err");
        setLoader(false);
      });
  }

  const nextPageDataFunc = async () => { // this will called when next button will called
    setLoader(true);
    count.current = count.current + 1;
    const docSnap = count.current && await getDoc(doc(collection(db, "clubs"), clubs[clubs.length - 1].id));
    getDocs(query(collection(db, "clubs"), orderBy(sortBy.current.orderByKey, sortBy.current.order), startAfter(docSnap), limit(clubsPerPage.current)))
      .then((docs) => {
        setClubs([]);
        docs.forEach((doc) => {
          setClubs((prev) => [...prev, doc.data()]);
        });
        setSearchParams({ clubPageNo: count.current + 1, prevNext: JSON.stringify([docs.docs[0].id, docs.docs[docs.docs.length - 1].id]), currentPageId: docSnap.id, itemsPerPage: clubsPerPage.current, n: 1, sortBy: JSON.stringify(sortBy.current), changeOrder: changeOrder.current })
        /* setSearchParams => clubPageno will help to track the page no, prevNext is storing id of first and last
          item which will help to go prev and next page.
          currentPageId will help me to be on the page same page when page reload
        */
        setLoader(false);
      })
      .catch((err) => {
        console.log(err, "err");
        setLoader(false);
      });
  }

  const handleShowAll = () => {
    setLoader(true);
    let tempItemsPerPage = clubsPerPage.current;
    clubsPerPage.current = +totalClubs;
    if (Boolean(searchParams.get('searchQuery'))) fetchSearchedClubs(searchQuery)
    else handleFilterClubs(JSON.parse(searchParams.get('filterQuery')))
    const currentParams = Object.fromEntries([...searchParams]);
    setSearchParams({ ...currentParams, itemsPerPage: tempItemsPerPage });
  }

  // Filter **************************************************************************************************!

  const handleFilterClubs = async (filterState) => {
    setLoader(true);
    count.current = 0;
    let arr = [];
    setFilterKeys(filterState);
    filterKeysRef.current = filterState;
    // reseting search input field in order to remove search and apply filter
    searchQueryInputRef.current = "";
    setSearchQuery(searchQueryInputRef.current);
    // ----------------------------------------
    let filteredLocations = filterKeysRef.current.selectedLocations.length;
    let filteredStatus = filterKeysRef.current.selectedStatus.length;
    if (filteredLocations && filteredStatus) {
      arr = [where("city_name_lower_case", "in", filterKeysRef.current.selectedLocations), where("status", "in", filterKeysRef.current.selectedStatus)]
    } else if (filteredLocations) {
      arr = [where("city_name_lower_case", "in", filterKeysRef.current.selectedLocations)]
    } else if (filteredStatus) {
      arr = [where("status", "in", filterKeysRef.current.selectedStatus)]
    } else console.log("not applicable");

    let events_data = (filteredLocations || filteredStatus) && await getDocs(query(collection(db, "clubs"), and(...arr), orderBy(sortBy.current.orderByKey, sortBy.current.order)))
    if (events_data) {
      let localFilteredClubs = []
      events_data.size && events_data.forEach(event => {
        localFilteredClubs = [...localFilteredClubs, event.data()];
        // setClubs(prev => [...prev, event.data()])
      })
      setClubs([...localFilteredClubs].splice(0, clubsPerPage.current));
      setTotalClubs(events_data.size);
      setLoader(false);
    }
  }

  const handleModifyFilter = (e, item, filterType) => {
    // localIdBasedSortIcon.current=""
    if (filterType === "location") {
      setFilterKeys({
        ...filterKeys,
        selectedLocations: filterKeys.selectedLocations.filter(location => location !== item)
      })

      filterKeysRef.current = {
        ...filterKeysRef.current,
        selectedLocations: filterKeysRef.current.selectedLocations.filter(location => location !== item)
      }
    }
    if (filterType === "status") {
      setFilterKeys({
        ...filterKeys,
        selectedStatus: filterKeys.selectedStatus.filter(status => status !== item)
      })
      filterKeysRef.current = {
        ...filterKeysRef.current,
        selectedStatus: filterKeysRef.current.selectedStatus.filter(status => status !== item)
      }
    }
    const currentParams = Object.fromEntries([...searchParams]);
    setSearchParams({ ...currentParams, filterQuery: JSON.stringify(filterKeysRef.current) });
    if (filterKeysRef.current.selectedLocations.length || filterKeysRef.current.selectedStatus.length) {
      handleFilterClubs(filterKeysRef.current);
    } else {
      sortBy.current = {
        orderByKey: "createdAt",
        order: "desc"
      }
      changeOrder.current = 0;
      clubsPerPage.current = 20;
      setSearchParams({ itemsPerPage: clubsPerPage.current, sortBy: JSON.stringify(sortBy.current), changeOrder: changeOrder.current });
      fetchData()
      fetchClubsCount();
    }
  }
  const ClubEntries = ({ clubData }) => {
    return (
      <div className="tableFixHead pb-5 font-body rounded bg-white" >
        <Table hover>
          <thead>
            <tr className="background-gray10">
              <th><p className="text-nowrap text-xs text-xl-sm text-uppercase ps-3 color-black2 mb-0 py-2">s. no</p></th>
              <th>
                <span
                  onClick={(e) => handleSorting(e, "name_lower_case")}
                  className={`text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2 d-inline-flex align-items-center gap-1 cursor-pointer ${(changeOrder.current !== 0 && localIdBasedSortIcon.current === "name_lower_case") ? "color-yellow1" : ""}`}>
                  CLUB NAME
                  <img src={localIdBasedSortIcon.current !== "name_lower_case" ? sortIcon : !changeOrder.current ? sortIcon : changeOrder.current === 1 ? sortIconAsc : sortIconDesc} alt="sort-icon" height={12} />
                </span>
              </th>
              <th><p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2"> CLUB IMAGE</p></th>
              <th><p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">manager</p></th>
              <th><p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">manager phone</p></th>
              <th>
                <span
                  onClick={(e) => handleSorting(e, "city_name_lower_case")}
                  className={`text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2 d-inline-flex align-items-center gap-1 cursor-pointer ${changeOrder.current !== 0 && localIdBasedSortIcon.current === "city_name_lower_case" ? "color-yellow1" : ""}`}>
                  LOCATION
                  <img src={localIdBasedSortIcon.current !== "city_name_lower_case" ? sortIcon : !changeOrder.current ? sortIcon : changeOrder.current === 1 ? sortIconAsc : sortIconDesc} alt="sort-icon" height={12} />
                </span>
              </th>
              <th><p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">Booking Count</p></th>
              <th>
                <span
                  onClick={(e) => handleSorting(e, "createdAt")}
                  className={`text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2 d-inline-flex align-items-center gap-1 cursor-pointer ${changeOrder.current !== 0 && localIdBasedSortIcon.current === "createdAt" ? "color-yellow1" : ""}`}>
                  CREATED ON
                  <img src={localIdBasedSortIcon.current !== "createdAt" ? sortIcon : !changeOrder.current ? sortIcon : changeOrder.current === 1 ? sortIconAsc : sortIconDesc} alt="sort-icon" height={12} />
                </span>
              </th>
              <th><p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">STATUS</p></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {!loader ?
              (
                <>
                  {(clubData && clubData.length) ? clubData.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className="align-middle"> <p className="text-sm mb-0 text-nowrap text-black ps-3">{count.current * clubsPerPage.current + index + 1}.</p></td>
                        <td className="align-middle">
                          <Link to={`/clubs/club-details/${item.id}`} target="_blank" className="text-dark text-sm mb-0 text-nowrap">{item.name}</Link>
                        </td>
                        <td>
                          <img src={item.display_image} width={"70px"} height={"70px"} alt="" className="rounded" />
                        </td>
                        <td className="align-middle">
                          <p className="text-sm mb-0 text-nowrap color-black">
                            {/* {item.manager_contacts && item.manager_contacts[0] && item.manager_contacts[0].name} */}
                            {
                              item.club_vip_manager && item.club_vip_manager.name
                            }
                          </p>
                        </td>
                        <td className="align-middle">
                          <p className="text-sm mb-0 text-nowrap color-black">
                            {/* {item.manager_contacts && item.manager_contacts[0] && item.manager_contacts[0].phone} */}
                            {
                              item.club_vip_manager && item.club_vip_manager.contact_no
                            }
                          </p>
                        </td>
                        <td className="align-middle">
                          <p className="text-sm mb-0 text-nowrap color-black">
                            {item.city_name}
                          </p>
                        </td>
                        <td className="align-middle"><p className="text-sm mb-0 text-nowrap text-dark text-center">{item.no_of_bookings ? (<a href={`/clubs-bookings/${item.id}?club_name=${item.name}`} >{item.no_of_bookings}</a>) : 0}</p></td>
                        <td className="align-middle">
                          <p className="text-sm mb-0 text-nowrap color-black">
                            {moment(item.createdAt.toDate()).format("DD MMM YYYY")}
                          </p>
                        </td>
                        <td className="align-middle"><p className="text-sm mb-0 text-nowrap color-black">{item.status}</p></td>
                        <td className="align-middle">
                          <div className="d-flex">
                            <Dropdown>
                              <Dropdown.Toggle variant="link" id="dropdown-basic" className="text-black">
                                <i className="bi bi-three-dots-vertical text-4xl d-bloxk"></i>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="border-0 shadow px-2">
                                <Dropdown.Item className="border-bottom font-bold text-sm py-2 text-black" onClick={(e) => onClickHandler(item.id, "update")}>
                                  Edit
                                </Dropdown.Item>
                                <Dropdown.Item className="border-bottom font-bold text-sm py-2 text-black" onClick={() => { handleShow(index) }}>
                                  Delete
                                </Dropdown.Item>
                                {/* <Dropdown.Item className="border-bottom font-bold text-sm py-2 text-black" onClick={() => { navigate(`/clubs/drink-management/${item.id}?name=${item.name}`) }}>
                                  Manage Drink
                                </Dropdown.Item>
                                <Dropdown.Item className="border-bottom font-bold text-sm py-2 text-black" onClick={() => { navigate(`/clubs/smoke-management/${item.id}?name=${item.name}`) }}>
                                  Manage Smoke
                                </Dropdown.Item> */}
                                {/* <Dropdown.Item className="border-bottom font-bold text-sm py-2 text-black" onClick={() => { navigate(`/ladies-night/${item.id}?name=${item.name}`) }}>
                                  Ladies Night
                                </Dropdown.Item> */}
                                <Dropdown.Item className="font-bold text-sm py-2 text-black" onClick={() => { navigate(`/clubs/close-booking/${item.id}?name=${item.name}`) }}>
                                  Non-Operational Days
                                </Dropdown.Item>
                                <Dropdown.Item className="font-bold text-sm py-2 text-black" onClick={() => { navigate(`/conceigers/${item.id}`, { state: item.name }) }}>
                                  Concierge List
                                </Dropdown.Item>
                                {/* <Dropdown.Item className="font-bold text-sm py-2 text-black" onClick={() => { navigate(`/managers/${item.id}`, { state: item.name }) }}>
                                  Managers List
                                </Dropdown.Item> */}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </td>
                      </tr>
                    );
                  }) : <tr><td colSpan={10} className="text-center text-2xl font-bold">No Data Found</td></tr>
                  }
                </>
              ) : (
                <tr>
                  <td colSpan={10}>
                    <Skeleton inline={true} count={5} height={80} style={{ marginBottom: "1rem" }} baseColor='#dedede' highlightColor='#cccccc' duration={2} />
                  </td>
                </tr>
              )
            }
          </tbody>
        </Table>
      </div>
    )
  }

  return (
    <Layout path="/clubs" additionalclass="d-flex flex-column">
      <div>
        <div className="d-flex justify-content-between align-items-center gap-3 gap-lg-5 font-body">
          <PageDescription title="All Clubs" caption="" />
          <PrimaryButton link="/clubs/create-club" name="Add Club" icon="bi-plus-circle" />
        </div>
        <div className='row d-flex justify-content-between align-items-center'>
          <div className='col-5 col-xxl-4'>
            <input
              id="search"
              type="search"
              className="po-edit-input mb-1"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchQuery}
            />
            {(Boolean(searchParams.get('searchQuery')) || Boolean(searchParams.get('filterQuery'))) && !loader ? <div className={"mt-2 text-md ps-2"} >No. of clubs displaying: <span className="font-bold ms-3">{totalClubs}</span></div> : ""}
          </div>
          <div className='col-6 col-xxl-4'>
            <div className='text-end position-relative'>
              <button className='border-0 bg-transparent' type='button' onClick={() => { setShowModal(prev => !prev) }}><i className={`bi bi-funnel-fill text-xl ${showModal ? "color-yellow1" : ""}`} ref={filterIcon}></i></button>
              <FilterModalClub
                ref={filterComponent}
                showModal={showModal}
                setShowModal={setShowModal}
                collectionRecall={fetchData}
                handleFilterClubs={handleFilterClubs}
                fetchClubsCount={fetchClubsCount}
                clubsPerPage={clubsPerPage.current}
              />
            </div>
          </div>
        </div>
        <div className={`mt-3 ${!filterKeys.selectedLocations.length && !filterKeys.selectedStatus.length ? "d-none" : ""}`}>
          {filterKeys && filterKeys.selectedLocations && filterKeys.selectedLocations.length ? <div className='d-flex align-items-start my-3 gap-3 text-sm font-medium'>
            <span className='text-nowrap'>Selected Location</span> :
            <div className='d-flex align-items-start flex-wrap gap-3'>
              {filterKeys && filterKeys.selectedLocations && filterKeys.selectedLocations.map((item, index) => <div
                className='text-xxs font-medium pl-3 pe-0 py-1 border-1 background-gradient rounded-pill text-capitalize d-flex align-items-center justify-content-between'
                key={index}
              >
                {item}
                <button className='bg-transparent border-0 d-flex align-items-center' onClick={(e) => handleModifyFilter(e, item, 'location')}>
                  <i className="bi bi-x-circle-fill text-md lineheight-0"></i>
                </button>
              </div>
              )}
            </div>
          </div> : ""}
          {filterKeys && filterKeys.selectedStatus && filterKeys.selectedStatus.length ? <div className='d-flex align-items-start my-3 gap-3 text-sm font-medium'>
            <span className='text-nowrap'>Selected Status</span> :
            <div className='d-flex align-items-start flex-wrap gap-3'>
              {filterKeys && filterKeys.selectedStatus && filterKeys.selectedStatus.map((item, index) => <div
                className='text-xxs font-medium pl-3 pe-0 py-1 border-1 background-gradient rounded-pill text-capitalize d-flex align-items-center justify-content-between'
                key={index}
              >
                {item}
                <button className='bg-transparent border-0 d-flex align-items-center' onClick={(e) => handleModifyFilter(e, item, 'status')}>
                  <i className="bi bi-x-circle-fill text-md lineheight-0"></i>
                </button>
              </div>
              )}
            </div>
          </div> : ""}
        </div>
      </div>

      <ContentWrapper additionalClass="font-body">
        <ClubEntries clubData={clubs} />
        {clubs.length ? <div className="d-flex gap-2 justify-content-end align-items-center py-4 me-4">
          {(clubs.length !== totalClubs) ? <div className="text-xs">
            Rows per page:
            <div className="d-inline-block ms-1 ms-xl-3">
              <select name="item-per-page" id="item-per-page" value={clubsPerPage.current} className="border-0 shadow-none font-medium text-xs" onChange={handlerItemsPerPage}>
                <option value={10}>10</option>
                <option value={15}>15</option>
                <option value={20}>20</option>
                <option value={25}>25</option>
                <option value={30}>30</option>
              </select>
            </div>
          </div> : ""}
          {(Boolean(searchParams.get('searchQuery')) || Boolean(searchParams.get('filterQuery'))) && !loader && totalClubs > clubs.length ?
            <button
              className="bg-transparent border-1 rounded-3 ml-12"
              type="button"
              onClick={handleShowAll}
            >Show All</button> : ""}
          {!Boolean(searchParams.get('searchQuery')) && !Boolean(searchParams.get('filterQuery')) ? <div className="d-flex align-items-center gap-3">
            {!loader && clubs.length ? <div className="mx-6 mx-xl-12 font-medium text-xs">
              {count.current * clubsPerPage.current + 1}-{(count.current + 1) * clubsPerPage.current < totalClubs ? (count.current + 1) * clubsPerPage.current : totalClubs} of {totalClubs}
            </div> : <div className="mx-6 mx-xl-12 font-medium">loading...</div>}
            <button className={`font-body font-bold rounded-circle po-paginationBtn d-inline-block bg-transparent ${(!count.current || loader) ? "po-border border-secondary" : "color-black1 po-border po-border-yellow1"}`} type="button"
              onClick={previousPageDataFunc}
              disabled={!count.current || loader}
            ><i className="bi bi-chevron-left text-black font-black text-lg"></i></button>
            <button className={`font-body font-bold rounded-circle po-paginationBtn po-paginationBtn--next d-inline-block bg-transparent ${(pageCount - 1 === count.current) || loader ? "po-border border-secondary" : "color-black1 po-border po-border-yellow1"}`} type="button"
              onClick={nextPageDataFunc}
              disabled={(pageCount - 1 === count.current) || loader}><i className="bi bi-chevron-right text-black font-black text-lg"></i></button>
          </div> : <div className="px-2"></div>}
        </div> : ""}
      </ContentWrapper>
      <Modal
        show={show.show}
        onHide={handleClose}
      >
        <div className="background-appColor p-8 rounded font-body">
          <h5 className="text-2xl  color-ternary font-semibold ">
            Are you sure?
          </h5>
          <p className="background-appColor text-light border-bottom-0 py-1 ">
            You want to delete this club
          </p>
          <div className="d-flex justify-content-end mt-12">
            <SecondaryButton onClick={() => handleDelete(show.data)} name="Delete" additionalClass="text-white me-3" />
            <PrimaryButton onClick={handleClose} name="Cancel" />
          </div>
        </div>
      </Modal>
    </Layout>
  )
}
export default Clubs;