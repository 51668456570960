import { useParams } from "react-router-dom";
import UpdateEvent from "../../Components/Events/UpdateEvent";
import Layout from "../../Layout/Layout";
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import db from "../../firebase";
const EditEvent = () => {

  const {event_id} = useParams();
  const [eventDetails, setEventDetails] = useState({});

  useEffect(() => {
    const docRef = doc(db, "events", event_id);
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        setEventDetails(docSnap.data());
      } else {
        // docSnap.data() will be undefined in this case
        // console.log("No such document!");
      }
    })
  }, [event_id])
  return (
    <Layout path="/events/edit-event">
      <UpdateEvent eventDetails={eventDetails} />
    </Layout>
  );
};
export default EditEvent;
