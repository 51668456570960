import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
// import { useForm, useFieldArray } from "react-hook-form";
import { Button } from "react-bootstrap";
// import { Link } from "react-router-dom";
import PageDescription from "../../../Components/Global/PageDescription";
import ContentWrapper from "../../../Components/Global/ContentWrapper"
import Layout from "../../../Layout/LayoutManager/Layout";
import db from "../../../firebase";
import OutlinedButton from '../../../Components/Global/Buttons/OutlinedButton';
import SuccessButton from '../../../Components/Global/Buttons/SuccessButton';
import Toaster from '../../../Components/Global/Toast';

const UpdateCategory = () => {
  const clubId = localStorage.getItem('club_id');
  const { drink_id } = useParams();
  const navigate = useNavigate();
  const [showToaster, setShowToaster] = useState(false);
  const [toasterDetails, setToasterDetails] = useState({
    title: "",
    bodyText: "",
    bg: "",
    statusIcon: "",
  });
  const [state, setState] = useState({
    name: "",
    brands: [],
    errors: {
      name: "",
      brands: "",
    }
  })

  const validate = (errors) => {
    let valid = true;
    Object.values(errors).forEach(val => val.length > 0 && (val = false));
    if (state.name.replace(/\s+/g, '') == "" || state.brands.length == 0)
      valid = false;
    state.brands.forEach(vals => {
      console.log(vals, "KKKKKKKKKKKK");
      if (vals.name.replace(/\s+/g, '') == "") {
        valid = false;
      }
    })
    return valid;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    function removeSpace(d) {
      let newData = [];
      d.forEach(d => {
        newData.push({ name: d.name.replace(/\s+/g, ' ') })
      })
      return newData;
    }
    // console.log();
    if (validate(state.errors)) {
      setShowToaster(true);
      setToasterDetails((prev) => ({
        ...prev,
        title: "Updating...",
        bodyText: "Updating Drink Category Details",
        bg: "warning",
        statusIcon: (
          <div className="spinner-border text-warning me-2" role="status"></div>
        ),
      }));
      const updateData = {
        name: state.name.replace(" ", '').replace(/\s+/g, ' ').trimEnd(),
        brands: removeSpace(state.brands),
        updatedAt: new Date()
      };
      const clubResponse = await updateDoc(doc(db, "clubs", clubId, "drink_category", drink_id), updateData)
        .then(() => {
          setToasterDetails((prev) => ({
            ...prev,
            title: "Success",
            bodyText: "Drink Category Details are updated successfully",
            bg: "success",
            statusIcon: (
              <i className="bi bi-check text-success text-3xl me-2"></i>
            ),
          }));
          setTimeout(() => {
            navigate(`/manager/drinks-management`, { replace: true });
          }, 2000);
          // alert("Data successfully Updated");
          // window.location.href = `/manager/drinks-management`
        })
        .catch((err) => {
          setToasterDetails((prev) => ({
            ...prev,
            title: "Error",
            bodyText: "Something went wrong, Please try again",
            bg: "danger",
            statusIcon: (
              <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
            ),
          }));
          setTimeout(() => {
            setShowToaster(false);
          }, 2000);
        });
    } else {
      let errors = state.errors;
      if (state.name.replace(/\s+/g, '') == "")
        errors.name = "Enter the category name"
      if (state.brands.length == 0)
        errors.brands = "Add atleast one brand to the category"
      state.brands.forEach(vals => {
        if (vals.name.replace(/\s+/g, '') == "") {
          errors.brands = "Add brand names for all"
        }
      })
      setState((prevProps) => ({
        ...prevProps,
        errors: errors
      }));
    }
  };

  const getDrinkData = async () => {
    const subColRef = (await getDoc(doc(db, "clubs", clubId, "drink_category", drink_id))).data();
    setState(prev => ({
      ...prev,
      name: subColRef && subColRef.name && subColRef.name,
      brands: subColRef && subColRef.brands && subColRef.brands,
    }))
  };
  useEffect(() => {
    getDrinkData();
  }, []);
  const nameChangeHandler = (event) => {
    const { value } = event.target;
    let errors = state.errors;
    errors.name = ""
    setState(prev => ({
      ...prev,
      name: value.replace(/\s+/g, ' '),
      errors: errors
    }))
  }
  const handlechangeBrandName = (event, ind) => {
    const upd_obj = state.brands
    upd_obj[ind].name = event.target.value.replace(/\s+/g, ' ');
    let errors = state.errors;
    errors.brands = ""
    setState(prev => ({
      ...prev,
      brands: upd_obj,
      errors: errors,
    }))
  }
  const handleUppendBrandName = (event, ind) => {
    const upd_obj = [...state.brands, { name: "" }]
    setState(prev => ({
      ...prev,
      brands: upd_obj,
    }))
  }
  const handleRemoveBrandFromList = (ind) => {
    console.log(ind);
    const upd_obj = state.brands;
    upd_obj.splice(ind, 1)
    setState(prev => ({
      ...prev,
      brands: upd_obj,
    }))
  }
  console.log(state, "the state cominghg");
  return (
    <Layout path="/manager/drinks-management/update-category">
      <PageDescription title="Edit Drink Category" caption="" />
      <ContentWrapper additionalClass="p-5 font-body">
        <form onSubmit={(e) => handleSubmit(e)} className="col-12">
          <label className="text-lg mb-2">Category:</label>
          <input
            className="po-edit-input"
            type="text"
            name="name"
            value={state.name}
            onChange={(e) => nameChangeHandler(e)}
          />
          {/* {errors.name && <span className="d-block text-sm text-danger ">Category is required</span>} */}
          {state.errors.name && (
            <p className="text-sm text-danger">{state.errors.name}</p>
          )}
          <label className="text-lg mb-2 mt-3">Brands:</label>
          {state.brands && state.brands.length > 0 && state.brands.map((field, index) => (
            <div key={index} className="d-flex">

              <input
                type="text"
                className="po-edit-input me-3 mb-3"
                name={`brands[${index}]`}
                value={field.name}
                onChange={(e) => handlechangeBrandName(e, index)}
              />
              {/* <OutlinedButton onClick={() => handleRemoveBrandFromList(index)} name="Delete" additionalClass="py-0 px-8"/> */}
              <Button variant="outline-danger" className="mb-3 radius-105" onClick={() => handleRemoveBrandFromList(index)}  >Delete</Button>
              {/* {errors.(`brands.${index}.name`) && <span>this field is required</span>} */}
            </div>
          ))}
          {state.errors.brands && (
            <p className="text-sm text-danger">{state.errors.brands}</p>
          )}
          <div>
            <SuccessButton onClick={() => handleUppendBrandName()} name="Add Brand" icon="bi-plus-circle" additionalClass="mt-4" />
          </div>
          <div className="mt-5 d-flex justify-content-end align-items-center">
            {/* <Button variant="success" type="submit" className="me-3">Submit</Button> */}
            {showToaster && (
              <img
                src="/assets/icons/loading_icon.gif"
                alt="processing"
                width={100}
              />
            )}
            <OutlinedButton link="/manager/drinks-management/" name="Cancel" additionalClass="me-3" />
            <SuccessButton type="submit" name="Update" icon="bi-check-circle" />
          </div>

        </form>
      </ContentWrapper>
      <Toaster
        showToaster={showToaster}
        setShowToaster={setShowToaster}
        bg={toasterDetails.bg}
        title={toasterDetails.title}
        bodyText={toasterDetails.bodyText}
        statusIcon={toasterDetails.statusIcon}
      />
    </Layout>
  )
}
export default UpdateCategory;