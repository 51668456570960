import React from 'react'
import { Modal, ModalBody } from 'react-bootstrap';
import SecondaryButton from '../../Components/Global/Buttons/SecondaryButton';

function ClubModal({
  icon,
  showModal,
  setShowModal,
  warningText,
  bodyText,
  bodyParaClass,
  successButtonClass,
  cancelButtonClass,
  onClick,
  onClose
}) {
  return (
    <Modal
      size="xs"
      show={showModal}
      onHide={() => setShowModal(false)}
    >
      <div className="background-appColor p-5 rounded font-body">
        <h5 className="text-2xl  color-ternary  mb-3">{warningText}</h5>
        <ModalBody>
          <div className='d-flex'>
            {icon && <i className="bi bi-info-circle-fill text-md color-yellow1 me-2">
            </i>}
            <p className={bodyParaClass}>
              {bodyText}
            </p>
          </div>
        </ModalBody>
        <div className="d-flex justify-content-end">
          <SecondaryButton
            onClick={onClose}
            name="No"
            additionalClass={cancelButtonClass}
          />
          <SecondaryButton
            onClick={onClick}
            name="Yes"
            additionalClass={successButtonClass}
          />
        </div>
      </div>
    </Modal>
  )
}

export default ClubModal;