import React from 'react';
import { ToastContainer } from 'react-bootstrap';
// import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Toast from 'react-bootstrap/Toast';

function Toaster({showToaster, setShowToaster, bg, title, bodyText, statusIcon}) {

  return (
    <Row>
      <Col xs={6}>
        <ToastContainer position="top-end" className="p-3" >
            <Toast onClose={() => setShowToaster(false)} show={showToaster} bg={bg}>
            <Toast.Header>
                {statusIcon}
                <strong className="me-auto">{title}</strong>
            </Toast.Header>
            <Toast.Body>{bodyText}</Toast.Body>
            </Toast>
        </ToastContainer>
      </Col>
      {/* <Col xs={6}>
        <Button onClick={() => setShow(true)}>Show Toast</Button>
      </Col> */}
    </Row>
  );
}

export default Toaster;