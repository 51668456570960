import { Modal } from "react-bootstrap";
import { signOut } from "firebase/auth";
import { auth } from "../../../firebase";
import SecondaryButton from "../Buttons/SecondaryButton";
import PrimaryButton from "../Buttons/PrimaryButton";

const ConfirmModal = ({
  showModal,
  setShowModal,
  handleDeleteButton,
  logout,
  Deleteid,
  contents = {
    title: "Delete Event",
    permission: "Are you sure you want to delete this event permanently?",
  },
}) => {
  const handleClose = () => setShowModal(false);
  const signOutHandler = () => {
    localStorage.setItem('logout', "yes");
    signOut(auth)
      .then(() => {
        const logoutHandler = (isLoggedIn) => {
          localStorage.setItem("authenticate", isLoggedIn);
          // setAuthentication(localStorage.getItem('authenticate'));
        };
        localStorage.removeItem("authenticate");
        localStorage.removeItem('user_id');
        localStorage.removeItem('roles');
        localStorage.removeItem('client-token');
        localStorage.removeItem('logout', "yes");
        localStorage.clear();
        logoutHandler(false);
        window.location.replace("/");
      })
      .catch((error) => {
        console.log(error, 'error');
      });
  };
  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={true}
    >
      <div className="background-appColor p-8 rounded font-body">
        <h5 className="text-2xl  color-ternary font-semibold ">
          {contents.title}
        </h5>
        <p className="background-appColor text-light border-bottom-0 py-1 ">
          {contents.permission}
        </p>
        <div className="d-flex justify-content-end mt-12">
          <SecondaryButton
            onClick={handleClose}
            name="Cancel"
            additionalClass="me-3 text-white"
          />
          {logout ? (
            <PrimaryButton onClick={signOutHandler} name="Yes" />
          ) : (
            <PrimaryButton
              onClick={() => {
                handleDeleteButton(Deleteid);
                setShowModal(false);
              }}
              name="Delete"
            />
          )}
        </div>
      </div>
    </Modal>
  );
};
export default ConfirmModal;
