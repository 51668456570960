import React, { useRef, useCallback, useState, useEffect } from 'react'
import Layout from '../Layout/Layout'
import PageDescription from '../Components/Global/PageDescription'
import Skeleton from 'react-loading-skeleton';
import { Table } from 'react-bootstrap';
import { collection, doc, endBefore, getCountFromServer, getDoc, getDocs, limit, limitToLast, onSnapshot, orderBy, query, startAfter, updateDoc, where } from 'firebase/firestore';
import db from '../firebase';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import FilterPushNotificationModal from '../Components/Global/Popup/FilterPushNotificationModal';
import ContentWrapper from '../Components/Global/ContentWrapper';

const membershipsLight = "/assets/icons/sidebar/light/new/vip-plan.svg";
const clubLight = "/assets/icons/sidebar/light/new/clubs.svg";
const eventLight = "/assets/icons/sidebar/light/new/events.svg";
const userLight = "/assets/icons/sidebar/light/new/users.svg";

const PushNotificationPage = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const filterComponent = React.createRef();
  const notificationPerPage = useRef(20);
  const count = useRef(0);
  // const nextPage = useRef(1);
  // const currentPageId = useRef("");
  const filterKeysRef = useRef({
    selectedNotificationTitle: [],
  });
  const filterIcon = useRef(null);
  const [totalNotification, setTotalNotification] = useState(null);
  const [pageCount, setPageCount] = useState(null);
  const [loader, setLoader] = useState();
  const [showModal, setShowModal] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [filterKeys, setFilterKeys] = useState({
    selectedNotificationTitle: [],
  })

  const handleNotificationCount = () => {
    getCountFromServer(query(collection(db, "notifications"), where('user',"==","admin")))
    .then(res=> {
      setTotalNotification(res.data().count);
      setPageCount(Math.ceil(res.data().count / notificationPerPage.current));  
    })
  }
  // hide filter modal when clicked outside the area of filter modal
  const handleClickOutside = useCallback((event) => {
    if (filterComponent.current && !filterComponent.current.contains(event.target) && event.target !== filterIcon.current) {
      setShowModal(false);
    }
  }, [filterComponent]);
  const countFilteredNotificationsFunc = (filterKeysArray) => {
    const coll = query(collection(db, "notifications"), where('user',"==","admin"), where("title", "in", filterKeysArray));
    getCountFromServer(coll)
    .then(res=> {
    setTotalNotification(res.data().count);
    setPageCount(Math.ceil(res.data().count / notificationPerPage.current));    
    })
  }

  // useEffect goes here ********************************************************************

  useEffect(() => { // this useEffect is used when user will click other area than filter popup
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    setLoader(true);
    if(Boolean(searchParams.get('itemsPerPage'))) notificationPerPage.current= +searchParams.get('itemsPerPage');
    if(Boolean(searchParams.get('filterQuery'))) {
      filterKeysRef.current.selectedNotificationTitle= [...JSON.parse(searchParams.get('filterQuery')).selectedNotificationTitle];
      setFilterKeys({
        selectedNotificationTitle: [...JSON.parse(searchParams.get('filterQuery')).selectedNotificationTitle]
      })
    }
    if(Boolean(searchParams.get('prevNext'))) {
      count.current = searchParams.get('pageNo')-1;
      getDoc(doc(collection(db, "notifications"), searchParams.get('currentPageId')))
        .then(docSnap=> {
          if(+searchParams.get('n')===1) {
            if(Boolean(searchParams.get('filterQuery'))) {
              countFilteredNotificationsFunc(filterKeysRef.current.selectedNotificationTitle);
              getDocs(query(collection(db, "notifications"), orderBy("createdAt", "desc"), where('user',"==","admin"), where("title", "in", filterKeysRef.current.selectedNotificationTitle), startAfter(docSnap), limit(notificationPerPage.current)))
              .then((docs) => {
                setNotifications([]);
                docs.forEach((doc) => {
                  setNotifications(prev => [...prev, doc.data()]);
                });
                // handleNotificationCount();
                setSearchParams({ ...Object.fromEntries([...searchParams]), pageNo: count.current + 1, prevNext: JSON.stringify([docs.docs[0].id, docs.docs[docs.docs.length - 1].id]), currentPageId: docSnap.id, n: 1 })
                setLoader(false);
              })
              .catch((err) => {
                setLoader(false);
              });
            } else {
              getDocs(query(collection(db, "notifications"), orderBy("createdAt", "desc"), where('user',"==","admin"), startAfter(docSnap), limit(notificationPerPage.current)))
              .then((docs) => {
                setNotifications([]);
                docs.forEach((doc) => {
                  setNotifications(prev => [...prev, doc.data()]);
                });
                handleNotificationCount();
                setSearchParams({ ...Object.fromEntries([...searchParams]), pageNo: count.current + 1, prevNext: JSON.stringify([docs.docs[0].id, docs.docs[docs.docs.length - 1].id]), currentPageId: docSnap.id, n: 1 })
                setLoader(false);
              })
              .catch((err) => {
                setLoader(false);
              });
            }
          } else {
            if(Boolean(searchParams.get('filterQuery'))) {
              countFilteredNotificationsFunc(filterKeysRef.current.selectedNotificationTitle)
              getDocs(query(collection(db, "notifications"), orderBy("createdAt", "desc"), where('user',"==","admin"), where("title", "in", filterKeysRef.current.selectedNotificationTitle), endBefore(docSnap), limitToLast(+notificationPerPage.current)))
              .then((docs) => {
                setNotifications([]);
                docs.forEach((doc) => {
                  setNotifications(prev => [...prev, doc.data()]);
                });
                setSearchParams({ ...Object.fromEntries([...searchParams]), pageNo: count.current + 1, prevNext: JSON.stringify([docs.docs[0].id, docs.docs[docs.docs.length - 1].id]), currentPageId: docSnap.id, itemsPerPage: notificationPerPage.current, n: 0 })
                setLoader(false);
              })
              .catch((err) => {
                setLoader(false);
              });
            } 
            else {
              getDocs(query(collection(db, "notifications"), orderBy("createdAt", "desc"), where('user',"==","admin"), endBefore(docSnap), limitToLast(+notificationPerPage.current)))
                .then((docs) => {
                  setNotifications([]);
                  docs.forEach((doc) => {
                    setNotifications(prev => [...prev, doc.data()]);
                  });
                  handleNotificationCount();
                  setSearchParams({ ...Object.fromEntries([...searchParams]), pageNo: count.current + 1, prevNext: JSON.stringify([docs.docs[0].id, docs.docs[docs.docs.length - 1].id]), currentPageId: docSnap.id, itemsPerPage: notificationPerPage.current, n: 0 })
                  setLoader(false);
                })
                .catch((err) => {
                  setLoader(false);
                });
            }
          }
        })
        .catch(err=>console.log(err, 'err'))
      return;
    }
    fetchNotifications();
  },[]);

  const fetchNotifications = (clearFilter) => {
    if(clearFilter) {
      count.current=0;
      filterKeysRef.current.selectedNotificationTitle= [];
      setFilterKeys({
        selectedNotificationTitle: [],
      })
    }
    if(filterKeysRef.current.selectedNotificationTitle.length && !clearFilter) {
      handleFilterPushNotifications([...JSON.parse(searchParams.get('filterQuery')).selectedNotificationTitle])
      return;
    }
    setLoader(true);
    setNotifications([]);
    handleNotificationCount();
    onSnapshot(query(collection(db, "notifications"), orderBy("createdAt", "desc"), where('user',"==","admin"), limit(notificationPerPage.current)), (snapshot) => {
      setNotifications(snapshot.docs.map((doc)=>({...doc.data()})));
      setLoader(false);
    })
  }

  const nextPageDataFunc = async () => { // this will called when next button will called
    setLoader(true);
    count.current = count.current + 1;
    const docSnap = count.current && await getDoc(doc(collection(db, "notifications"), notifications[notifications.length - 1].id));
    if(Boolean(searchParams.get('filterQuery'))) {
      getDocs(query(collection(db, "notifications"), orderBy("createdAt", "desc"), where('user',"==","admin"), where("title", "in", filterKeys.selectedNotificationTitle), startAfter(docSnap), limit(notificationPerPage.current)))
      .then((docs) => {
        setNotifications([]);
        docs.forEach((doc) => {
          setNotifications(prev => [...prev, doc.data()]);
        });
        setSearchParams({ ...Object.fromEntries([...searchParams]), pageNo: count.current + 1, prevNext: JSON.stringify([docs.docs[0].id, docs.docs[docs.docs.length - 1].id]), currentPageId: docSnap.id, n: 1 })
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
    } else {
      getDocs(query(collection(db, "notifications"), orderBy("createdAt", "desc"), where('user',"==","admin"), startAfter(docSnap), limit(notificationPerPage.current)))
      .then((docs) => {
        setNotifications([]);
        docs.forEach((doc) => {
          setNotifications(prev => [...prev, doc.data()]);
        });
        setSearchParams({ ...Object.fromEntries([...searchParams]), pageNo: count.current + 1, prevNext: JSON.stringify([docs.docs[0].id, docs.docs[docs.docs.length - 1].id]), currentPageId: docSnap.id, n: 1 })
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
    }
  }

  const previousPageDataFunc = async () => { // this function will be called when previous button clicked
    setLoader(true);
    count.current = count.current - 1;
    if (!count.current) {
      fetchNotifications();
      setSearchParams({});
      return;
    }
    const docSnap = count.current && await getDoc(doc(collection(db, "notifications"), notifications[0].id));
    if(Boolean(searchParams.get('filterQuery'))) {
      getDocs(query(collection(db, "notifications"), orderBy("createdAt", "desc"), where('user',"==","admin"), where("title", "in", filterKeys.selectedNotificationTitle), endBefore(docSnap), limitToLast(+notificationPerPage.current)))
      .then((docs) => {
        setNotifications([]);
        docs.forEach((doc) => {
          setNotifications(prev => [...prev, doc.data()]);
        });
        setSearchParams({ ...Object.fromEntries([...searchParams]), pageNo: count.current + 1, prevNext: JSON.stringify([docs.docs[0].id, docs.docs[docs.docs.length - 1].id]), currentPageId: docSnap.id, itemsPerPage: notificationPerPage.current, n: 0 })
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
    } else {
      getDocs(query(collection(db, "notifications"), orderBy("createdAt", "desc"), where('user',"==","admin"), endBefore(docSnap), limitToLast(+notificationPerPage.current)))
      .then((docs) => {
        setNotifications([]);
        docs.forEach((doc) => {
          setNotifications(prev => [...prev, doc.data()]);
        });
        setSearchParams({ ...Object.fromEntries([...searchParams]), pageNo: count.current + 1, prevNext: JSON.stringify([docs.docs[0].id, docs.docs[docs.docs.length - 1].id]), currentPageId: docSnap.id, itemsPerPage: notificationPerPage.current, n: 0 })
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
    }
  }

  const handlerItemsPerPage = (e) => { // I am recalling the fetchData func when items per page whenever change but not calling fetchMembershipsRequestCount because it won't change if items per page will change
    setLoader(true);
    notificationPerPage.current = +e.target.value;
    searchParams.delete('pageNo');
    searchParams.delete('prevNext');
    searchParams.delete('currentPageId');
    searchParams.delete('n');
    const currentParams = Object.fromEntries([...searchParams]);
    setSearchParams({ ...currentParams, itemsPerPage: notificationPerPage.current });
    count.current = 0;
    setNotifications([]);
    if(Boolean(searchParams.get('filterQuery'))) {
      onSnapshot(query(collection(db, "notifications"), orderBy("createdAt", "desc"), where('user',"==","admin"), where("title", "in", filterKeys.selectedNotificationTitle), limit(notificationPerPage.current)), (snapshot) => {
        setNotifications(snapshot.docs.map((doc)=>({...doc.data()})));
        setLoader(false);
      })
    } else {
      handleNotificationCount();
      onSnapshot(query(collection(db, "notifications"), orderBy("createdAt", "desc"), where('user',"==","admin"), limit(notificationPerPage.current)), (snapshot) => {
        setNotifications(snapshot.docs.map((doc)=>({...doc.data()})));
        setLoader(false);
      })
    }
  }
  // Filter goes here *****************************************************************
  const handleFilterPushNotifications = (filterKeysArray) => {
    filterKeysRef.current.selectedNotificationTitle = [...filterKeysArray];
    setFilterKeys({
      selectedNotificationTitle: [...filterKeysArray]
    })
    setLoader(true);
    count.current = 0;
    setNotifications([]);
    countFilteredNotificationsFunc(filterKeysArray)
    onSnapshot(query(collection(db, "notifications"), orderBy("createdAt", "desc"), where('user',"==","admin"), where("title", "in", filterKeysArray), limit(notificationPerPage.current)), (snapshot) => {
      setNotifications(snapshot.docs.map((doc)=>({...doc.data()})));
      setLoader(false);
    })
  }

  const handleModifyFilter = (item, filterType) => {
    setFilterKeys({
      ...filterKeys,
      selectedNotificationTitle: filterKeys.selectedNotificationTitle.filter(notification => notification !== item)
    })
    filterKeysRef.current = {
      ...filterKeysRef.current,
      selectedNotificationTitle: filterKeysRef.current.selectedNotificationTitle.filter(notification => notification !== item)
    }
    const currentParams = Object.fromEntries([...searchParams]);
    setSearchParams({ ...currentParams, filterQuery: JSON.stringify(filterKeysRef.current) });
    if (filterKeysRef.current.selectedNotificationTitle.length) {
      handleFilterPushNotifications(filterKeysRef.current.selectedNotificationTitle);
    } else {
      notificationPerPage.current = 20;
      count.current=0;
      setSearchParams({ itemsPerPage: notificationPerPage.current });
      fetchNotifications();
    }
  }

  const handleNotificationRedirection = async (url, id) => {
    await updateDoc(doc(db, "notifications", id), {
      read_status: true
    });
    if(url) {
      if(url.includes('table_booking')) window.open('/bookings/'+url.replace('table_booking', "table-details"), '_blank');
      if(url.includes('club_entry_booking')) window.open('/bookings/'+url.replace('club_entry_booking', "entry-details", '_blank'));
      if(url.includes('event_entry_booking')) window.open('/bookings/'+url.replace('event_entry_booking', "event-details", '_blank'));
      if(url.includes('membership_requests')) window.open('/memberships/'+url.replace('membership_requests', "membership-request", '_blank'));
      if(url.includes('user-profile')) window.open(url.replace('user-profile/', 'users/user-details/'), '_blank');
      if(url.includes('users')) window.open(url.replace('users/', 'users/user-details/'), '_blank');
    }
  }

  const NotificationEntries = ({ currentItems }) => {
    return (
      <div className="table-responsive po-table tableFixHead">
        <Table striped hover responsive>
          <thead>
            <tr className="background-gray10">
              <th>
                <p className="text-nowrap text-xs text-xl-sm text-uppercase ps-3 color-black2 mb-0 py-2">
                  S. No
                </p>
              </th>
              <th>
                {" "}
                <p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">
                  Title
                </p>
              </th>
              <th>
                {" "}
                <p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">
                  Description
                </p>
              </th>
              <th>
                {" "}
                <p className="text-nowrap text-xs text-xl-sm text-uppercase color-black2 mb-0 py-2">
                  Date and Time
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            {!loader ? (
              <>
                {currentItems && currentItems.length
                  ? currentItems.map((data, index) => {
                    const createdDate= moment(data.createdAt.seconds * 1000).format("YYYY-MM-DD");
                    const presentDate = moment(new Date());
                    return (
                      <tr key={index} onClick={()=>handleNotificationRedirection(data.url, data.id)} className='cursor-pointer'>
                        <td className="align-middle ps-4">
                          <p className={`text-sm mb-0 text-nowrap color-black ps-3 ${!Boolean(data.read_status) ? "font-semibold":""}`}>
                            {count.current * notificationPerPage.current + index + 1}.
                          </p>
                        </td>
                        <td className="align-middle">
                          <div
                            className={`text-sm mb-0 color-black d-flex align-items-center gap-3 ${!Boolean(data.read_status) ? "font-semibold":""}`}
                            style={{ maxWidth: "300px" }}
                          >
                            
                            <span className='po-notification__icon-bg flex-shrink-0 d-inline-block rounded-circle d-flex justify-content-center align-items-center'>
                              {data.type!=="default" ? <img src={data.type && data.type==="membership" ? membershipsLight
                                      : data.type==="event_booking" ? eventLight
                                      : data.type==="club_booking" ? clubLight 
                                      : data.type==="profile" ? userLight:""  } 
                                alt="Notification Icon"
                                width={15}
                                height={15}
                              /> : <i className="bi bi-person-add text-md" style={{color:"#D3BB8A"}}></i>}
                            </span>
                            {data.title && data.title}
                          </div>
                        </td>
                        <td className="align-middle">
                          <div style={{ maxWidth: "400px" }}>
                            <p className={`text-sm mb-0 color-black ${!Boolean(data.read_status) ? "font-semibold":""}`}>
                              {data.body && data.body}
                            </p>
                          </div>
                        </td>
                        <td className={`align-middle ${!Boolean(data.read_status) ? "font-semibold":""}`}>
                          {!presentDate.diff(createdDate, 'days') ? moment(new Date(data.createdAt.seconds * 1000).toISOString()).format("hh:mm A") : moment(new Date(data.createdAt.seconds * 1000).toISOString()).format("DD MMM YYYY")}
                        </td>
                      </tr>
                    );
                  })
                  : ""}
              </>
            ) : (
              <tr>
                <td colSpan={5}>
                  {" "}
                  <Skeleton
                    inline={true}
                    count={5}
                    height={80}
                    style={{ marginBottom: "1rem" }}
                    baseColor="#dedede"
                    highlightColor="#cccccc"
                    duration={2}
                  />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    );
  };
  return (

    <Layout
      path="/push-notification"
      additionalclass="d-flex flex-column"
    >
      <PageDescription title="All Notifications" caption="" />
        <div className='text-end position-relative'>
          <button className='border-0 bg-transparent mb-5' type='button' onClick={() => { setShowModal(prev => !prev) }}><i className={`bi bi-funnel-fill text-xl ${showModal ? "color-yellow1" : ""}`} ref={filterIcon}></i></button>
          <FilterPushNotificationModal
            ref={filterComponent}
            showModal={showModal}
            setShowModal={setShowModal}
            collectionRecall={fetchNotifications}
            handleFilterPushNotifications={handleFilterPushNotifications}
          />
        </div>
        <div className={`mt-3 ${!filterKeys.selectedNotificationTitle.length ? "d-none" : ""}`}>
          {filterKeys.selectedNotificationTitle.length ? <div className='d-flex align-items-start my-3 gap-3 text-sm font-medium'>
            <span className='text-nowrap'>Selected Notification</span> :
            <div className='d-flex align-items-start flex-wrap gap-3'>
              {filterKeys.selectedNotificationTitle.map((item, index) => <div
                className='text-xxs font-medium pl-3 pe-0 py-1 border-1 background-gradient rounded-pill text-capitalize d-flex align-items-center justify-content-between'
                key={index}
              >
                {item}
                <button className='bg-transparent border-0 d-flex align-items-center' onClick={(e) => handleModifyFilter(item, 'pushNotification')}>
                  <i className="bi bi-x-circle-fill text-md lineheight-0"></i>
                </button>
              </div>
              )}
            </div>
          </div> : ""}
        </div>
        {Boolean(searchParams.get('filterQuery')) && !loader ? <div className={"mt-2 mb-5 text-sm"} >No. of notifications displaying: <span className="font-bold text-sm ms-3">{totalNotification}</span></div> : ""}
      <ContentWrapper additionalClass="font-body">
        <NotificationEntries currentItems={notifications} />
        {notifications.length ? <div className={`d-flex gap-2 justify-content-end align-items-center py-4 text-xs me-4`}>
          {(notifications.length !== totalNotification) ? <div>
            Rows per page:
            <div className="d-inline-block ms-1 ms-xl-3">
              <select name="item-per-page" id="item-per-page" value={notificationPerPage.current} className="border-0 shadow-none bg-transparent font-medium" onChange={handlerItemsPerPage}>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
          </div> : ""}
          <div className="d-flex align-items-center gap-3">
            {!loader && notifications.length && totalNotification ? <div className="mx-6 mx-xl-12 font-medium">
              {count.current * notificationPerPage.current + 1}-{(count.current + 1) * notificationPerPage.current < totalNotification ? (count.current + 1) * notificationPerPage.current : totalNotification} of {totalNotification}
            </div> : <div className="mx-6 mx-xl-12 font-medium">loading...</div>}
            <button className={`font-body font-bold rounded-circle po-paginationBtn d-inline-block bg-transparent ${(!count.current || loader) ? "po-border border-secondary" : "color-black1 po-border po-border-yellow1"}`} type="button"
              onClick={previousPageDataFunc}
              disabled={!count.current || loader}
            ><i className="bi bi-chevron-left text-black font-black text-lg"></i></button>
            <button className={`font-body font-bold rounded-circle po-paginationBtn po-paginationBtn--next d-inline-block bg-transparent ${(pageCount - 1 === count.current) || loader ? "po-border border-secondary" : "color-black1 po-border po-border-yellow1"}`} type="button"
              onClick={nextPageDataFunc}
              disabled={(pageCount - 1 === count.current) || loader}><i className="bi bi-chevron-right text-black font-black text-lg"></i></button>
          </div>
        </div> : ""}
      </ContentWrapper>
    </Layout>
    
  )
}

export default PushNotificationPage
