import ContentWrapper from "../../Components/Global/ContentWrapper";
import Layout from "../../Layout/Layout";
import { useForm } from "react-hook-form";
import { Row, Col, Modal, ModalBody } from "react-bootstrap";
import { collection, doc, getDocs, setDoc, where, query } from "@firebase/firestore";
import { v4 as uuid } from "uuid";
import { useEffect } from "react";
import db from "../../firebase";
import { useState } from "react";
import ActionIcon from "../../Components/Global/Buttons/ActionIcons";
import axios from "axios";
import { useNavigate } from "react-router";
// import Success from "../../Components/Global/States/Success";
// import Loading from "../../Components/Global/States/Loading";
// import Error from "../../Components/Global/States/Error";
import { Link } from "react-router-dom";
import SuccessButton from "../../Components/Global/Buttons/SuccessButton";
import OutlinedButton from "../../Components/Global/Buttons/OutlinedButton";
import Toaster from "../../Components/Global/Toast";
import SecondaryButton from "../../Components/Global/Buttons/SecondaryButton";

const CreateCity = () => {
  const [showToaster, setShowToaster] = useState(false);
  const [show, setShow] = useState(false);
  const [cityImageDeleteModal, setCityImageDeleteModal] = useState(null)
  const [toasterDetails, setToasterDetails] = useState({
    title: "",
    bodyText: "",
    bg: "",
    statusIcon: "",
  });
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [cities, setCities] = useState(null);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [image, setImage] = useState({});
  const [country, setCountry] = useState("");
  const [countryId, setCountryId] = useState("");
  const [state, setState] = useState("");
  const [stateId, setStateId] = useState("");
  const [previewImage, setPreviewImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [imageError, setImageError] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //Duplicates Validation
  const checkingForDuplicates = (value) => {
    let flag = false;
    cities.forEach((ele) => {
      if (ele.data().name === value.trim() && ele.data().country_id === countryId && ele.data().state_id === stateId) {
        flag = true;
      }
    })
    return flag;
  }

  const getAllCities = async () => {
    await getDocs(collection(db, "cities")).then((res) => {
      setCities(res.docs)
    }).catch((err) => {
      console.log(err, "error");
    })
  }
  useEffect(() => {
    getAllCities();
  }, [])
  const onSubmit = async (data) => {
    const duplicatesResult = checkingForDuplicates(data.cityName)
    if (duplicatesResult) {
      setShow(true)
      return;
    }
    // setIsLoading(true);
    setShowToaster(true);
    setToasterDetails((prev) => ({
      ...prev,
      title: "Creating...",
      bodyText: "Creating city",
      bg: "warning",
      statusIcon: (
        <div className="spinner-border text-warning me-2" role="status"></div>
      ),
    }));
    const formData = new FormData();
    formData.append("images", image);
    const images = async () => {
      const imageResponse = await axios.post(
        `${process.env.REACT_APP_MULTIPLEIMAGES_POSTING_URL}/uploads/uploadImages`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            method: "POST",
          },
        }
      );
      return imageResponse && imageResponse.data && imageResponse.data.url[0];
    };
    const id = uuid();
    const payloadImage = await images();
    const payload = {
      id: id && id,
      name_lower_case: data.cityName.toLowerCase(),
      name: data && data.cityName,
      city_image: payloadImage ? payloadImage : null,
      country_name: country && country,
      country_id: countryId && countryId,
      latitude: data && Number(data.latitude),
      longitude: data && Number(data.longitude),
      party_location: data && data.partyLocation,
      state_name: state && state,
      state_id: stateId && stateId,
      country_timezone: data && data.countrytimezone,
      createdAt: new Date(),
      updatedAt: new Date()
    };
    console.log(payload, "payload");
    try {
      const createCityResponse = await setDoc(doc(db, "cities", id), payload);
      // setIsLoading(false);
      // setIsSuccess(true);
      // navigate("/city");
      setToasterDetails((prev) => ({
        ...prev,
        title: "Success",
        bodyText: "Created city successfully",
        bg: "success",
        statusIcon: <i className="bi bi-check text-success text-3xl me-2"></i>,
      }));
      setTimeout(() => {
        navigate("/city");
      }, 2000)
    } catch (err) {
      // setIsLoading(false);
      // setIsError(true);
      setToasterDetails((prev) => ({
        ...prev,
        title: "Error",
        bodyText: "Something went wrong",
        bg: "danger",
        statusIcon: (
          <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
        ),
      }));
      console.error("Error adding document: ", err);
    }
  };
  const handleState = (e) => {
    console.log(e.target.options[e.target.selectedIndex].text, "state");
    e.target.options && setState(e.target.options[e.target.selectedIndex].text);
    setStateId(e.target.value);
    setError("stateName", null)
  };
  const handleCountry = (e) => {
    console.log(e.target.options[e.target.selectedIndex].text, "country");
    e.target.options &&
      setCountry(e.target.options[e.target.selectedIndex].text);
    setCountryId(e.target.value);
    getAllStates(e.target.value);
    setError('countryName', { type: 'custom', message: null });
  };

  const getAllStates = async (id) => {
    const statesResponse = await getDocs(query(collection(db, "states"), where("country_id", "==", id)));
    let sortedStates = statesResponse.docs.sort((a, b) => a.data().name.localeCompare(b.data().name))
    setStates(sortedStates);
  }
  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (!/^image\//.test(file.type)) {
        setError("display_image", {
          type: "manual",
          message: "Please select an image file",
        });
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          setPreviewImage(reader.result);
        };
        reader.readAsDataURL(file);
        setImage(file);
        setError("display_image", null);
      }
    }
  };


  const getAllCountriesAndStates = async () => {
    const countriesResponse = await getDocs(collection(db, "countries"));
    let sortedCountries = countriesResponse.docs.sort((a, b) => a.data().name.localeCompare(b.data().name))
    setCountries(sortedCountries);
  };

  useEffect(() => {
    getAllCountriesAndStates();
  }, []);

  console.log(country, "country");
  return (
    <Layout path="/city/create-city">
      {/* <Link to="/city">
            <div  className="d-flex align-items-center mb-5">
               <div>
                <img
                  src="/assets/icons/sidebar/dark/location-icon-dark.svg"
                  alt="dashboard-icon"
                  width="15"
                  height="15"
                  className="d-block"
                />
              </div>

            <div className="ms-2 text-sm text-lg-md font-body font-semibold text-black">
            City
              </div>
               <div className="ms-2 text-xs text-lg-sm font-body font-regular text-black">
               / create
              </div>
            </div>
            </Link> */}
      <ContentWrapper additionalClass="p-5 font-body">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row className="g-3">
            <Col xs={12}>
              <label htmlFor="cityImage">City image<span style={{ color: "red", fontSize: "20px" }}>*</span></label>
              <div
                style={{
                  height: "200px",
                  width: "350px",
                  backgroundImage: `url(${previewImage ? previewImage : null})`,
                  border: `${previewImage ? "0px" : "1px"} dashed #D2D6DA`,
                }}
                className="d-flex align-items-center justify-content-center background-position-center-top background-repeat-norepeat background-size-cover rounded overflow-hidden position-relative"
              >
                {previewImage ? (
                  <div className="position-absolute end-0 top-0">
                    <ActionIcon
                      onClick={() => {
                        // setPreviewImage(null);
                        setCityImageDeleteModal(true)
                      }}
                      additionalClass="bi bi-x-circle-fill text-3xl color-red me-2"
                    />
                  </div>
                ) : null}

                {previewImage ? null : (
                  <div>
                    <input
                      accept="image/*"
                      type="file"
                      id="display_image"
                      {...register("display_image", {
                        required: "Image is required",
                      })}
                      onChange={handleImageChange}
                      className="opacity-0 position-absolute start-0 end-0 top-0 bottom-0"
                    />

                    <div className="text-center">
                      <i className="bi bi-plus-circle-fill text-5xl color-lightgreen d-block"></i>
                      <p className="text-md font-body text-center  ">
                        Upload your Image here.
                      </p>
                    </div>
                  </div>
                )}
              </div>
              {
                <p className="mt-1">
                  {errors.display_image && <span className="text-danger">{errors.display_image.message}</span>}
                </p>
              }

            </Col>

            <Col xs={6}>
              <label htmlFor="countryName">Country name<span style={{ color: "red", fontSize: "20px" }}>*</span></label>
              <select
                id="countryName"
                {...register("countryName", { required: "This field is required" })}
                className="po-edit-input"
                onChange={handleCountry}
              >
                <option value="">Select a country</option>
                {countries &&
                  countries.map((country, index) => (
                    <option
                      key={index}
                      value={country.data() && country.data().id}
                    >
                      {country.data() && country.data().name}
                    </option>
                  ))}
              </select>
              <p className="text-sm text-danger">
                {errors.countryName?.message}
              </p>
            </Col>
            <Col xs={6}>
              <label htmlFor="stateName">State name<span style={{ color: "red", fontSize: "20px" }}>*</span></label>
              <select
                className="po-edit-input"
                id="stateName"
                {...register("stateName", { required: "This field is required" })}
                onChange={handleState}
              >
                <option value="" >Select a state</option>
                {states &&
                  states.map((state, index) => (
                    <option key={index} value={state.data().id}>
                      {state.data().name}
                    </option>
                  ))}
              </select>

              <p className="text-sm text-danger">
                {" "}
                {errors.stateName?.message}
              </p>
            </Col>
            <Col xs={12}>
              <label htmlFor="cityName">City name<span style={{ color: "red", fontSize: "20px" }}>*</span></label>
              <input
                className="po-edit-input"
                type="text"
                id="cityName"
                {...register("cityName", {
                  required: true,
                  pattern: {
                    value: /^(?!\s*$).+/,
                    message: 'Only alphabetic characters are allowed.',
                  }
                })}
              />
              <p className="text-sm text-danger">
                {errors.cityName && errors.cityName.type === "required" && (
                  <span>This field is required</span>
                )}

                {errors.cityName && errors.cityName.type === "pattern" && (
                  <span>Only alphabetic characters are allowed.</span>
                )}
              </p>
            </Col>
            <Col xs={6}>
              <label htmlFor="latitude">Latitude<span style={{ color: "red", fontSize: "20px" }}>*</span></label>
              <input
                type="text"
                id="latitude"
                className="po-edit-input"
                {...register("latitude", {
                  required: true,
                  pattern: /^\d+(\.\d+)?$/,
                })}
              />

              <p className="text-sm text-danger">
                {errors.latitude && errors.latitude.type === "required" && (
                  <span>This field is required</span>
                )}
                {errors.latitude && errors.latitude.type === "pattern" && (
                  <span>Invalid format (must be a number)</span>
                )}
              </p>
            </Col>
            <Col xs={6}>
              <label htmlFor="longitude">Longitude<span style={{ color: "red", fontSize: "20px" }}>*</span></label>
              <input
                type="text"
                id="longitude"
                className="po-edit-input"
                {...register("longitude", {
                  required: true,
                  pattern: /^\d+(\.\d+)?$/,
                })}
              />

              <p className="text-sm text-danger">
                {errors.longitude && errors.longitude.type === "required" && (
                  <span>This field is required</span>
                )}
                {errors.longitude && errors.longitude.type === "pattern" && (
                  <span>Invalid format (must be a number)</span>
                )}
              </p>
            </Col>

            {/* <Col xs={6}>
              <input
                type="checkbox"
                id="popularHometown"
                {...register("popularHometown")}
              />
              <label htmlFor="popularHometown">Popular hometown</label>
            </Col> */}


            {/* Country Timezone */}
            <Col xs={6}>
              <label >Country timezone</label>
              <input type="text"
                {...register("countrytimezone", {
                  required: "This field is required"
                })}
                className="po-edit-input"
              // placeholder="Please enter country code"
              />
              {errors.countrytimezone && <p className="text-danger text-sm">{errors.countrytimezone.message}</p>}
            </Col>
            <Col xs={12} className="d-flex align-items-center">
              <input
                type="checkbox"
                id="partyLocation"
                {...register("partyLocation")}
              />
              <label htmlFor="partyLocation" className="ms-2">Status</label>
            </Col>
          </Row>

          <div className="d-flex justify-content-end">
            <OutlinedButton link="/city" name="Cancel" />
            <SuccessButton type="submit" name="Create city" icon="bi-check-circle" additionalClass={`ms-4 ${showToaster ? "opacity-06 cursor-default" : null}  `} disabled={showToaster ? true : false} />

          </div>
        </form>
      </ContentWrapper>
      {/* City Delete Image Modal */}
      <Modal size="xs" show={cityImageDeleteModal} onHide={() => setCityImageDeleteModal(false)}  >

        <div className="background-appColor p-5 rounded font-body">
          <h5 className="text-2xl  color-ternary  mb-3">
            Are you sure?
          </h5>
          <ModalBody>
            <p className="text-light font-weight-bold">Do you want to delete city image?</p>
          </ModalBody>
          <div className="d-flex justify-content-end">
            <SecondaryButton
              onClick={() => setCityImageDeleteModal(false)}
              name="No"
              additionalClass="text-light mt-3 me-4 px-5"
            />
            <SecondaryButton
              onClick={() => {

                // setSelectedImage(null)
                setPreviewImage(null);
                // setImage(null);
                setCityImageDeleteModal(false)
              }}
              name="Yes"
              additionalClass="text-light mt-3 me-4 px-5"
            />

          </div>
        </div>
      </Modal >


      <Toaster
        showToaster={showToaster}
        setShowToaster={setShowToaster}
        bg={toasterDetails.bg}
        title={toasterDetails.title}
        bodyText={toasterDetails.bodyText}
        statusIcon={toasterDetails.statusIcon}
      />
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <div className="background-appColor p-8 rounded font-body">
          <h2 className="text-2xl  color-ternary font-semibold mb-4">Error</h2>
          <p className="text-md text-light text-wrap mb-1"> City is already Exists</p>
          <div className="text-end">
            <SecondaryButton onClick={handleClose} name="Close" additionalClass="text-light" />
          </div>
        </div>

      </Modal>
    </Layout>
  );
};
export default CreateCity;
