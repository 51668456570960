import Layout from "../../Layout/Layout";
import ContentWrapper from "../../Components/Global/ContentWrapper";
// import Layout from "../../Layout/Layout";
import { useForm } from "react-hook-form";
import { Row, Col, Modal, ModalBody } from "react-bootstrap";
import {
  doc,
  getDoc,
  updateDoc,
  query,
  collection,
  getDocs,
} from "@firebase/firestore";
// import { v4 as uuid } from "uuid";
import { useEffect } from "react";
import db from "../../firebase";
import { useState } from "react";
import ActionIcon from "../../Components/Global/Buttons/ActionIcons";
import axios from "axios";
import { useParams } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import Success from "../../Components/Global/States/Success";
import Loading from "../../Components/Global/States/Loading";
import Error from "../../Components/Global/States/Error";
import SuccessButton from "../../Components/Global/Buttons/SuccessButton";
import OutlinedButton from "../../Components/Global/Buttons/OutlinedButton";
import Toaster from "../../Components/Global/Toast";
import SecondaryButton from "../../Components/Global/Buttons/SecondaryButton";
const EditCountry = () => {
  const [showToaster, setShowToaster] = useState(false);
  const [toasterDetails, setToasterDetails] = useState({
    title: "",
    bodyText: "",
    bg: "",
    statusIcon: "",
  });
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  // const location = useLocation()
  // const id = location.state.id;
  const [show, setShow] = useState(false);
  const [countryImageDeleteModal, setCountryImageDeleteModal] = useState(null)
  const { country_id } = useParams();
  const [image, setImage] = useState({});
  const [previewImage, setPreviewImage] = useState(null);
  const [countries, setCountries] = useState([]);
  const [updateData, setUpdateData] = useState({});
  const [imageFromBackend, setImageFromBackend] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [imageError, setImageError] = useState("")
  const navigate = useNavigate();
  const insertingImage = async (imageData) => {
    let form = new FormData();
    form.append("images", imageData);
    let responseImage = await axios
      .post(
        `${process.env.REACT_APP_MULTIPLEIMAGES_POSTING_URL}/uploads/uploadImages`,
        form,
        { headers: { "Content-Type": "multipart/form-data" } }
      )
      .then((resImage) => {
        return resImage && resImage.data.url[0];
      })
      .catch((error) => console.log(error, "err"));
    return responseImage;
  };
  //Modal Handlers
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // fetching entries
  const fetchCountries = async () => {
    const q = query(collection(db, "countries"));
    const querySnapshot = await getDocs(q);
    const newData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setCountries(newData);
  };
  useEffect(() => {
    fetchCountries();
  }, []);

  //Duplicates Validation
  const checkingForDuplicates = (value) => {
    let flag = false;
    countries.forEach((ele) => {
      if (ele.id !== country_id) {
        let oldCountryName = ele.name.trim();
        let newCountryName = value.trim();
        if (oldCountryName === newCountryName) {
          flag = true;
        }
      }
    });
    return flag;
  };

  // Submit function
  const onSubmit = async (data) => {
    // setIsLoading(true);
    const duplicateValidation = checkingForDuplicates(data.countryName);
    if (duplicateValidation) {
      setShow(true);
      return;
    }
    setShowToaster(true);
    setToasterDetails((prev) => ({
      ...prev,
      title: "Updating...",
      bodyText: "Updating country",
      bg: "warning",
      statusIcon: (
        <div className="spinner-border text-warning me-2" role="status"></div>
      ),
    }));
    let payloadImage = "";
    if (imageFromBackend) {
      payloadImage = previewImage;
    } else if (imageFromBackend == false) {
      payloadImage = await insertingImage(image);
    }

    const payload = {
      name: data && data.countryName,
      country_image: payloadImage,
      latitude: data && Number(data.latitude),
      longitude: data && Number(data.longitude),
      updatedAt: new Date(),
      country_code: data && data.countryCode
    };
    console.log(payload, "payload");
    try {
      const clubResponse = await updateDoc(
        doc(db, "countries", country_id),
        payload
      ).then(() => {
        // setIsLoading(false);
        // setIsSuccess(true);
        settingData();
        setPreviewImage(null);
        setToasterDetails((prev) => ({
          ...prev,
          title: "Success",
          bodyText: "Updated Country successfully",
          bg: "success",
          statusIcon: (
            <i className="bi bi-check text-success text-3xl me-2"></i>
          ),
        }));
        setTimeout(() => {
          navigate("/country");
        }, 2000);
      });
    } catch (err) {
      setToasterDetails((prev) => ({
        ...prev,
        title: "Error",
        bodyText: "Something went wrong",
        bg: "danger",
        statusIcon: (
          <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
        ),
      }));
      console.log(err, "err");
      // setIsLoading(false);
      // setIsError(true);
    }
  };

  //Image Handler
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = () => {
          setPreviewImage(reader.result);
        };
        reader.readAsDataURL(file);
        console.log(file, "its coming");
        setImage(file);
        setImageError("")
      }
      else {
        setImageError("Please ensure your selection is an image file.");
        return;
      }

    }
  };

  const settingData = async () => {
    const cityResponse = await getDoc(doc(db, "countries", country_id));
    let ResponseData = cityResponse.data();
    cityResponse && (await setUpdateData(ResponseData));
    console.log(ResponseData, "ResponseData");
    // setState(ResponseData.state_name)
    reset((register) => ({
      ...register,
      // countryName: updateData && updateData.country_name,
      countryName: ResponseData.name,
      countryCode: ResponseData.country_code,
      // stateName: updateData.state_name,
      latitude: ResponseData.latitude,
      longitude: ResponseData.longitude
      // display_image: updateData.city_image
    }));
    setPreviewImage(ResponseData.country_image);
    setImageFromBackend(true);
  };
  useEffect(() => {
    if (country_id) {
      settingData();
    }
  }, [country_id]);

  return (
    <Layout path="/country/edit-country">
      <ContentWrapper additionalClass="p-5 font-body">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row className="g-3">
            {/* Country Image */}
            <Col xs={12}>
              <label htmlFor="cityImage">Country Image</label>
              <div
                style={{
                  height: "200px",
                  width: "350px",
                  backgroundImage: `url(${previewImage ? previewImage : null})`,
                  border: `${previewImage ? "0px" : "1px"} dashed #D2D6DA`,
                }}
                className="d-flex align-items-center justify-content-center background-position-center-top background-repeat-norepeat background-size-cover rounded overflow-hidden position-relative"
              >
                {previewImage ? (
                  <div className="position-absolute end-0 top-0">
                    <ActionIcon
                      onClick={() => {
                        setCountryImageDeleteModal(true)
                      }}
                      additionalClass="bi bi-x-circle-fill text-3xl color-red me-2"
                    />
                  </div>
                ) : null}

                {previewImage ? null : (
                  <div>
                    <input
                      accept="image/*"
                      type="file"
                      id="display_image"
                      onChange={handleImageChange}
                      className="opacity-0 position-absolute start-0 end-0 top-0 bottom-0"
                    />

                    <div className="text-center">
                      <i className="bi bi-plus-circle-fill text-5xl color-lightgreen d-block"></i>
                      <p className="text-md font-body text-center  ">
                        Upload your Image here.
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </Col>
            {imageError && <p className="text-danger">{imageError}</p>

            }

            {/* Country name */}
            <Col xs={12}>
              <label htmlFor="cityName">
                Country name
                <span style={{ color: "red", fontSize: "20px" }}>*</span>
              </label>
              <input
                className="po-edit-input"
                type="text"
                id="cityName"
                {...register("countryName", {
                  required: true,
                  pattern: {
                    value: /^(?!\s*$).+/,
                    message: "Only alphabetic characters are allowed.",
                  },
                })}
              />

              <p className="text-sm text-danger">
                {errors.countryName &&
                  errors.countryName.type === "required" && (
                    <span>This field is required</span>
                  )}

                {errors.countryName &&
                  errors.countryName.type === "pattern" && (
                    <span>Only alphabetic characters are allowed.</span>
                  )}
              </p>
            </Col>

            {/* Langitude */}
            <Col xs={6}>
              <label htmlFor="latitude">
                Latitude
                <span style={{ color: "red", fontSize: "20px" }}>*</span>
              </label>
              <input
                type="text"
                id="latitude"
                className="po-edit-input"
                {...register("latitude", {
                  required: true,
                  pattern: /^\d+(\.\d+)?$/,
                })}
              />

              <p className="text-sm text-danger">
                {errors.latitude && errors.latitude.type === "required" && (
                  <span>This field is required</span>
                )}
                {errors.latitude && errors.latitude.type === "pattern" && (
                  <span>Invalid format (must be a number)</span>
                )}
              </p>
            </Col>

            {/* Longitude */}
            <Col xs={6}>
              <label htmlFor="longitude">
                Longitude
                <span style={{ color: "red", fontSize: "20px" }}>*</span>
              </label>
              <input
                type="text"
                id="longitude"
                className="po-edit-input"
                {...register("longitude", {
                  required: true,
                  pattern: /^\d+(\.\d+)?$/,
                })}
              />

              <p className="text-sm text-danger">
                {errors.longitude && errors.longitude.type === "required" && (
                  <span>This field is required</span>
                )}
                {errors.longitude && errors.longitude.type === "pattern" && (
                  <span>Invalid format (must be a number)</span>
                )}
              </p>
            </Col>


            {/* Country code */}
            <Col xs={12}>
              <label htmlFor="cityCode">
                Country code
                <span style={{ color: "red", fontSize: "20px" }}>*</span>
              </label>
              <input
                className="po-edit-input"
                type="text"
                id="cityName"
                {...register("countryCode", {
                  required: true,
                  pattern: {
                    value: /^(?!\s*$).+/,
                    message: "Only alphabetic characters are allowed.",
                  },
                })}
              />

              <p className="text-sm text-danger">
                {errors.countryCode &&
                  errors.countryCode.type === "required" && (
                    <span>This field is required</span>
                  )}

                {errors.countryCode &&
                  errors.countryCode.type === "pattern" && (
                    <span>Only alphabetic characters are allowed.</span>
                  )}
              </p>
            </Col>

            {/* Country timezone
            <Col xs={6}>
              <label htmlFor="cityCode">
                Country time zone
                <span style={{ color: "red", fontSize: "20px" }}>*</span>
              </label>
              <input
                className="po-edit-input"
                type="text"
                id="cityName"
                {...register("countryZone", {
                  required: true,
                  pattern: {
                    value: /^(?!\s*$).+/,
                    message: "Only alphabetic characters are allowed.",
                  },
                })}
              />

              <p className="text-sm text-danger">
                {errors.countryZone &&
                  errors.countryZone.type === "required" && (
                    <span>This field is required</span>
                  )}

                {errors.countryZone &&
                  errors.countryZone.type === "pattern" && (
                    <span>Only alphabetic characters are allowed.</span>
                  )}
              </p>
            </Col> */}
          </Row>

          {/* Buttons */}
          <div className="d-flex justify-content-end mt-4">
            <OutlinedButton link="/country" name="Cancel" />
            <SuccessButton
              type="submit"
              name="Update Country"
              icon="bi-check-circle"
              additionalClass={`ms-4 ${showToaster ? "opacity-06 cursor-default" : null
                }  `}
              disabled={showToaster ? true : false}
            />
          </div>
        </form>
      </ContentWrapper>
      {isLoading && <Loading />}

      {isSuccess && <Success />}

      {isError && <Error />}

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <div className="background-appColor p-8 rounded font-body">
          <h2 className="text-2xl  color-ternary font-semibold mb-4">Error</h2>
          <p className="text-md text-light text-wrap mb-1">
            {" "}
            Country is already Exists
          </p>
          <div className="text-end">
            <SecondaryButton
              onClick={handleClose}
              name="Close"
              additionalClass="text-light"
            />
          </div>
        </div>
      </Modal>
      {/* Country Image Modal */}
      <Modal size="xs" show={countryImageDeleteModal} onHide={() => setCountryImageDeleteModal(false)}  >

        <div className="background-appColor p-5 rounded font-body">
          <h5 className="text-2xl  color-ternary  mb-3">
            Are you sure?
          </h5>
          <ModalBody>
            <p className="text-light font-weight-bold">Do you want to delete country image?</p>
          </ModalBody>
          <div className="d-flex justify-content-end">
            <SecondaryButton
              onClick={() => setCountryImageDeleteModal(false)}
              name="No"
              additionalClass="text-light mt-3 me-4 px-5"
            />
            <SecondaryButton
              onClick={() => {

                // setSelectedImage(null)
                setPreviewImage(null);
                setImage(null);
                setCountryImageDeleteModal(false)
              }}
              name="Yes"
              additionalClass="text-light mt-3 me-4 px-5"
            />

          </div>
        </div>
      </Modal >
      <Toaster
        showToaster={showToaster}
        setShowToaster={setShowToaster}
        bg={toasterDetails.bg}
        title={toasterDetails.title}
        bodyText={toasterDetails.bodyText}
        statusIcon={toasterDetails.statusIcon}
      />
    </Layout>
  );
};
export default EditCountry;
