import React, { useState, useEffect, useRef, useCallback } from "react";
import { Tab, Nav } from "react-bootstrap";
import Layout from "../../Layout/Layout";
import ContentWrapper from "../../Components/Global/ContentWrapper";
import { collection, getCountFromServer, query, where } from "firebase/firestore";
import db from "../../firebase";
import TableBookings from "../../Components/Booking/TableBookings";
import EventBookings from "../../Components/Booking/EventBookings";
import EntryBookings from "../../Components/Booking/EntryBookings";
import { useNavigate, useSearchParams } from "react-router-dom";
import PrimaryButton from "../../Components/Global/Buttons/PrimaryButton";
import PageDescription from "../../Components/Global/PageDescription";
import Skeleton from "react-loading-skeleton";
import FilterModalBookings from "../../Components/Global/Popup/FilterModalBookings";
import FilterModalEventBookings from "../../Components/Global/Popup/FilterModalEventBookings";

const Bookings = () => {

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab") ? searchParams.get("tab") : "table";
  const [selectedTab, setSelectedTab] = useState(tab);
  const [bookingsCount, setBookingsCount] = useState({
    tableBookings: null,
    clubEntryBookings: null,
    eventEntryBookings: null
  })
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterKeys, setFilterKeys] = useState({
    selectedLocations: [],
    selectedClubs: [],
    selectedEvents: [],
    selectedStatus: []
  })
  const filterIcon = useRef(null);
  const filterKeysRef = useRef({
    selectedLocations: [],
    selectedClubs: [],
    selectedEvents: [],
    selectedStatus: []
  });
  const bookingRef = useRef(null);
  const fetchPendingBookingsCount = async () => {
    const tableBookingsCount = await getCountFromServer(query(collection(db, "club_entry_bookings"), where("booking_type", "==", "table_booking"), where('status', '==', 'Pending')));
    const ClubEntryBookingsCount = await getCountFromServer(query(collection(db, "club_entry_bookings"), where("booking_type", "==", "club_entry_booking"), where('status', '==', 'Pending')));
    const EventEntryBookingsCount = await getCountFromServer(query(collection(db, "event_entry_bookings"), where('status', '==', 'Pending')));
    setBookingsCount({
      tableBookings: tableBookingsCount.data().count,
      clubEntryBookings: ClubEntryBookingsCount.data().count,
      eventEntryBookings: EventEntryBookingsCount.data().count
    })
  }

  const filterComponent = React.createRef();
  const handleClickOutside = useCallback((event) => {
    if (filterComponent.current && !filterComponent.current.contains(event.target) && event.target !== filterIcon.current) {
      setShowModal(false);
    }
  }, [filterComponent]);

  // Searching ****************************************************************************!

  const handleSearchQuery = (e) => {
    let inputValue = (e.target.value).trim();
    if (inputValue.trim().length > 0) {
      setFilterKeys({
        selectedLocations: [],
        selectedClubs: [],
        selectedEvents: [],
        selectedStatus: []
      })
      filterKeysRef.current = {
        selectedLocations: [],
        selectedClubs: [],
        selectedEvents: [],
        selectedStatus: []
      }
      if (e.target.value) {
        searchParams.delete('filterQuery');
        searchParams.delete('pageNo');
        searchParams.delete('prevNext');
        searchParams.delete('currentPageId');
        searchParams.delete('n');
        if (Boolean(searchParams.get("showAll"))) {
          searchParams.delete('showAll');
          setSearchParams({ ...Object.fromEntries([...searchParams]), searchQuery: e.target.value, bookingsPerPage: 20 })
        } else if (Boolean(searchParams.get("showAllEntries")) && (searchParams.get("tab") === "clubs")) {
          searchParams.delete('showAllEntries');
          setSearchParams({ ...Object.fromEntries([...searchParams]), searchQuery: e.target.value, bookingsPerPage: 20 })
        } else if (Boolean(searchParams.get("showAllEventbookings")) && searchParams.get("tab") === "events") {
          searchParams.delete('showAllEventbookings');
          setSearchParams({ ...Object.fromEntries([...searchParams]), searchQuery: e.target.value, bookingsPerPage: 20 })
        } else {
          setSearchParams({ ...Object.fromEntries([...searchParams]), searchQuery: e.target.value })
        }
        setSearchQuery(e.target.value);
      }
      else {
        setSearchQuery("");
        if (searchParams.get('sortBy')) {
          navigate(`/bookings?tab=${tab}&sortBy=${searchParams.get('sortBy')}&changeOrder=${searchParams.get("changeOrder")}`)
        }
        else {
          navigate(`/bookings?tab=${tab}`);
        }
      }
    }
    else {
      setSearchQuery("");
      if (searchParams.get('sortBy')) {
        navigate(`/bookings?tab=${tab}&sortBy=${searchParams.get('sortBy')}&changeOrder=${searchParams.get("changeOrder")}`)
      }
      else {
        navigate(`/bookings?tab=${tab}`);
      }

    }
  }

  // Filter **************************************************************************************************!

  const handleModifyFilter = (e, item, filterType) => {
    console.log(filterKeysRef, "filterKeysReffilterKeysRef");
    if (filterType === "location") {
      setFilterKeys({
        ...filterKeys,
        selectedLocations: filterKeys.selectedLocations.filter(location => location !== item)
      })
      filterKeysRef.current = {
        ...filterKeysRef.current,
        selectedLocations: filterKeysRef.current.selectedLocations.filter(location => location !== item)
      }
    }
    if (filterType === "club") {
      setFilterKeys({
        ...filterKeys,
        selectedClubs: filterKeys.selectedClubs.filter(club => club.id !== item)
      })

      filterKeysRef.current = {
        ...filterKeysRef.current,
        selectedClubs: filterKeysRef.current.selectedClubs.filter(club => club.id !== item)
      }
    }
    if (filterType === "event") {
      setFilterKeys({
        ...filterKeys,
        selectedEvents: filterKeys.selectedEvents.filter(event => event.id !== item)
      })

      filterKeysRef.current = {
        ...filterKeysRef.current,
        selectedEvents: filterKeysRef.current.selectedEvents.filter(event => event.id !== item)
      }
    }
    if (filterType === "status") {
      setFilterKeys({
        ...filterKeys,
        selectedStatus: filterKeys.selectedStatus.filter(status => status !== item)
      })
      filterKeysRef.current = {
        ...filterKeysRef.current,
        selectedStatus: filterKeysRef.current.selectedStatus.filter(status => status !== item)
      }
    }
    if (filterKeysRef.current.selectedLocations.length + filterKeysRef.current.selectedEvents.length + filterKeysRef.current.selectedClubs.length + filterKeysRef.current.selectedStatus.length === 0) {
      searchParams.delete('filterQuery');
      searchParams.delete('pageNo');
      setSearchParams({ ...Object.fromEntries([...searchParams]), bookingsPerPage: 20 });
    } else {
      setSearchParams({ ...Object.fromEntries([...searchParams]), filterQuery: JSON.stringify(filterKeysRef.current) });
    }
  }

  // filter goes here -----------------------------------------------------------------------------------

  // useEffect goes here **********************************************************************************

  useEffect(() => {
    fetchPendingBookingsCount();
    if (Boolean(searchParams.get('searchQuery'))) {
      setSearchQuery(searchParams.get('searchQuery'))
    }
    if (Boolean(searchParams.get('filterQuery'))) {
      setSearchQuery("");
      setFilterKeys(prev => ({
        ...prev,
        ...JSON.parse(searchParams.get('filterQuery'))
      }))
      filterKeysRef.current = {
        ...filterKeysRef.current,
        ...JSON.parse(searchParams.get('filterQuery'))
      }
    }
    if (!Boolean(searchParams.get('filterQuery'))) {
      setFilterKeys({
        selectedLocations: [],
        selectedClubs: [],
        selectedEvents: [],
        selectedStatus: []
      })
      filterKeysRef.current = {
        selectedLocations: [],
        selectedClubs: [],
        selectedEvents: [],
        selectedStatus: []
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (!Boolean(searchParams.get("searchQuery"))) setSearchQuery("");
  }, [tab])

  useEffect(() => {
    // this useEffect is used when user will click other area than filter popup
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handleClickOutside]);

  const RENDERCREATEBTN = () => {
    switch (selectedTab) {
      case "table":
        return (
          <PrimaryButton
            link="/bookings/create-table-booking"
            name="Create Table Booking"
            icon="bi-plus-circle"
          />
        );
      case "clubs":
        return (
          <PrimaryButton
            link="/bookings/create-entry-booking"
            name="Create Entry Booking"
            icon="bi-plus-circle"
          />
        );
      case "events":
        return (
          <PrimaryButton
            link="/bookings/create-event-booking"
            name="Create Event Booking"
            icon="bi-plus-circle"
          />
        );
      default:
        break;
    }
  };
  return (
    <Layout path="/bookings">
      <div className='po-table-content'>
        <div>
          <PageDescription title="All Bookings" caption="" additionalClass="py-2 pb-5" />
          <div className='row d-flex justify-content-between g-3'>
            <div className='col-5 col-xxl-4'>
              <input
                type="search"
                className="po-edit-input mb-1"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => handleSearchQuery(e)}
              />
            </div>
            <div className='col-6 col-xxl-4'>
              <div className='text-end position-relative'>
                <button className='border-0 bg-transparent' type='button' onClick={() => { setShowModal(prev => !prev) }}>
                  <i
                    className={`bi bi-funnel-fill text-xl ${showModal ? "color-yellow1" : ""}`}
                  ></i></button>
                {selectedTab !== "events" ? <FilterModalBookings
                  ref={filterComponent}
                  showModal={showModal}
                  setShowModal={setShowModal}
                /> : <FilterModalEventBookings
                  ref={filterComponent}
                  showModal={showModal}
                  setShowModal={setShowModal}
                />}
              </div>
            </div>
          </div>
          <div className={`mt-3 ${(!filterKeys.selectedLocations.length && !filterKeys.selectedClubs.length && !filterKeys.selectedEvents.length && !filterKeys.selectedStatus.length) ? "d-none" : ""}`}>
            {filterKeys && filterKeys.selectedLocations && filterKeys.selectedLocations.length ? <div className='d-flex align-items-start my-3 gap-3 text-sm font-medium'>
              <span className='text-nowrap'>Selected Locations</span> :
              <div className='d-flex align-items-start flex-wrap gap-3'>
                {filterKeys.selectedLocations.map((item, index) => <div
                  className='text-xxs font-medium pl-3 pe-0 py-1 border-1 background-gradient rounded-pill text-capitalize d-flex align-items-center justify-content-between'
                  key={index}
                >
                  {item}
                  <button
                    className='bg-transparent border-0 d-flex align-items-center'
                    onClick={(e) => handleModifyFilter(e, item, 'location')}
                  >
                    <i className="bi bi-x-circle-fill text-md lineheight-0"></i>
                  </button>
                </div>
                )}
              </div>
            </div> : ""}
            {filterKeys && filterKeys.selectedClubs && filterKeys.selectedClubs.length ? <div className='d-flex align-items-start my-3 gap-3 text-sm font-medium'>
              <span className='text-nowrap'>Selected Clubs</span> :
              <div className='d-flex align-items-start flex-wrap gap-3'>
                {filterKeys.selectedClubs.map((item, index) => <div
                  className='text-xxs font-medium pl-3 pe-0 py-1 border-1 background-gradient rounded-pill text-capitalize d-flex align-items-center justify-content-between'
                  key={index}
                >
                  {item.name}
                  <button
                    className='bg-transparent border-0 d-flex align-items-center'
                    onClick={(e) => handleModifyFilter(e, item.id, 'club')}
                  >
                    <i className="bi bi-x-circle-fill text-md lineheight-0"></i>
                  </button>
                </div>
                )}
              </div>
            </div> : ""}
            {filterKeys && filterKeys.selectedEvents && filterKeys.selectedEvents.length ? <div className='d-flex align-items-start my-3 gap-3 text-sm font-medium'>
              <span className='text-nowrap'>Selected Events</span> :
              <div className='d-flex align-items-start flex-wrap gap-3'>
                {filterKeys.selectedEvents.map((item, index) => <div
                  className='text-xxs font-medium pl-3 pe-0 py-1 border-1 background-gradient rounded-pill text-capitalize d-flex align-items-center justify-content-between'
                  key={index}
                >
                  {item.name}
                  <button
                    className='bg-transparent border-0 d-flex align-items-center'
                    onClick={(e) => handleModifyFilter(e, item.id, 'event')}
                  >
                    <i className="bi bi-x-circle-fill text-md lineheight-0"></i>
                  </button>
                </div>
                )}
              </div>
            </div> : ""}
            {filterKeys && filterKeys.selectedStatus && filterKeys.selectedStatus.length ? <div className='d-flex align-items-start my-3 gap-3 text-sm font-medium'>
              <span className='text-nowrap'>Selected Status</span> :
              <div className='d-flex align-items-start flex-wrap gap-3'>
                {filterKeys.selectedStatus.map((item, index) => <div
                  className='text-xxs font-medium pl-3 pe-0 py-1 border-1 background-gradient rounded-pill text-capitalize d-flex align-items-center justify-content-between'
                  key={index}
                >
                  {item}
                  <button
                    className='bg-transparent border-0 d-flex align-items-center'
                    onClick={(e) => handleModifyFilter(e, item, 'status')}
                  >
                    <i className="bi bi-x-circle-fill text-md lineheight-0"></i>
                  </button>
                </div>
                )}
              </div>
            </div> : ""}
          </div>
        </div>
        <ContentWrapper additionalClass="pt-5" >
          <div className="po-custom-tabs">
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey={selectedTab}
              onSelect={(k) => { setSelectedTab(k); navigate(`/bookings?tab=${k}`) }}
            >
              <div>
                <div className="border-bottom po-custom-tabs d-flex align-items-center justify-content-between px-5">
                  <Nav variant="pills" className="flex-row pb-4">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="table"
                        className="d-flex justify-content-center align-items-center"
                      >
                        <div>Table Bookings</div>{" "}
                        {bookingsCount.tableBookings !== null ? (
                          <div
                            className="d-flex justify-content-center align-items-center ms-2 rounded-circle text-center text-white"
                            style={{
                              height: "30px",
                              width: "30px",
                              background:
                                "transparent linear-gradient(290deg, #DBA611 0%, #F7CE73 100%) 0% 0% no-repeat padding-box",
                            }}
                          >
                            <div className="text-xs" title="Pending">
                              {bookingsCount.tableBookings}
                            </div>
                          </div>
                        ) : <Skeleton
                          inline={true} count={1}
                          height={30} width={30}
                          baseColor="#dedede" highlightColor="#cccccc"
                          duration={2} className="ms-2" circle
                        />
                        }
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="clubs"
                        className="d-flex justify-content-center align-items-center"
                      >
                        <div>Entry Bookings</div>{" "}
                        {bookingsCount.clubEntryBookings !== null ? (
                          <div
                            className="d-flex justify-content-center align-items-center ms-2 rounded-circle text-center text-white"
                            style={{
                              height: "30px",
                              width: "30px",
                              background:
                                "transparent linear-gradient(290deg, #DBA611 0%, #F7CE73 100%) 0% 0% no-repeat padding-box",
                            }}
                            title="Pending"
                          >
                            <div className="text-xs">
                              {bookingsCount.clubEntryBookings}
                            </div>
                          </div>
                        ) : <Skeleton
                          inline={true} count={1}
                          height={30} width={30}
                          baseColor="#dedede" highlightColor="#cccccc"
                          duration={2} className="ms-2" circle
                        />
                        }
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="events"
                        className="d-flex justify-content-center align-items-center"
                      >
                        <div>Event Booking</div>{" "}
                        {bookingsCount.eventEntryBookings !== null ? (
                          <div
                            className="d-flex justify-content-center align-items-center ms-2 rounded-circle text-center text-white"
                            style={{
                              height: "30px",
                              width: "30px",
                              background:
                                "transparent linear-gradient(290deg, #DBA611 0%, #F7CE73 100%) 0% 0% no-repeat padding-box",
                            }}
                            title="Pending"
                          >
                            <div className="text-xs">
                              {bookingsCount.eventEntryBookings}
                            </div>
                          </div>
                        ) : <Skeleton
                          inline={true} count={1}
                          height={30} width={30}
                          baseColor="#dedede" highlightColor="#cccccc"
                          duration={2} className="ms-2" circle
                        />}
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <div className="mb-5">
                    <RENDERCREATEBTN />
                  </div>
                </div>
                <div>
                  <Tab.Content>
                    <Tab.Pane eventKey="table">
                      {selectedTab === "table" && <TableBookings
                        bookingRef={bookingRef}
                      />}
                    </Tab.Pane>
                    <Tab.Pane eventKey="clubs">
                      {selectedTab === "clubs" && <EntryBookings
                        bookingRef={bookingRef}
                      />}
                    </Tab.Pane>
                    <Tab.Pane eventKey="events">
                      {selectedTab === "events" && <EventBookings
                        bookingRef={bookingRef}
                      />}
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          </div>
        </ContentWrapper>
      </div>
    </Layout>
  );
};
export default Bookings;
