import { doc, deleteDoc } from "firebase/firestore";
import db from "../firebase";

const deleteClub = async (id) => {
  await deleteDoc(doc(db, "clubs", id)).then(() => {
    console.log("Deleted");
    alert("Data Deleted");
    window.location.reload();
  }).catch((err) => {
    console.log(err);
  })
}

export default deleteClub;