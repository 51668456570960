import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button, Col, Row } from "react-bootstrap";
import ContentWrapper from "../../Components/Global/ContentWrapper";
import Layout from "../../Layout/Layout";
import ActionIcon from "../../Components/Global/Buttons/ActionIcons";
import {
  collection,
  getDocs,
  orderBy,
  query,
  addDoc,
  serverTimestamp,
  setDoc,
  doc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import db from "../../firebase";
import SuccessButton from "../../Components/Global/Buttons/SuccessButton";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import Toaster from "../../Components/Global/Toast";
import SecondaryButton from "../../Components/Global/Buttons/SecondaryButton";
import OutlinedButton from "../../Components/Global/Buttons/OutlinedButton";
const CreateJourneyToBeOne = () => {
  const [showToaster, setShowToaster] = useState(false);
  const [toasterDetails, setToasterDetails] = useState({
    title: "",
    bodyText: "",
    bg: "",
    statusIcon: "",
  });
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
    reset,
  } = useForm();
  const [selectedImage, setSelectedImage] = useState(null);
  const [ufile, setUfile] = useState({});

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(URL.createObjectURL(file));
    setUfile(file);
    setError("image", null);
  };
  const handleDeselectImage = () => {
    setSelectedImage(null);
    const inputElement = document.getElementById("imageInput");
    if (inputElement) {
      inputElement.value = null;
    }
  };

  // upload images
  const uploadImage = async () => {
    const formData = new FormData();
    formData.append("images", ufile);
    // console.log(ufile, "hhhhh");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_MULTIPLEIMAGES_POSTING_URL}/uploads/uploadImages`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            method: "POST",
          },
        }
      );

      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (data) => {
    setShowToaster(true);
    setToasterDetails((prev) => ({
      ...prev,
      title: "Creating...",
      bodyText: "Creating record",
      bg: "warning",
      statusIcon: (
        <div className="spinner-border text-warning me-2" role="status"></div>
      ),
    }));
    const payloadId = uuidv4();
    const resultantImage = await uploadImage(ufile);
    const payload = {
      id: payloadId,
      title: data.title,
      image: resultantImage.data.url[0],
      redirection_url: data.redirection,
      description: data.decription,
      createdAt: serverTimestamp(),
    };
    try {
      const docRef = await setDoc(
        doc(db, "journey_to_be_one", payloadId),
        payload
      );
      reset();
      setSelectedImage(null);
      setToasterDetails((prev) => ({
        ...prev,
        title: "Success",
        bodyText: "Created record successfully",
        bg: "success",
        statusIcon: <i className="bi bi-check text-success text-3xl me-2"></i>,
      }));
      setTimeout(() => {
        navigate("/journey");
      }, 2000);
    } catch (e) {
      console.error("Error adding document: ", e);
      setToasterDetails((prev) => ({
        ...prev,
        title: "Error",
        bodyText: "Something went wrong",
        bg: "danger",
        statusIcon: (
          <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
        ),
      }));
    }
  };
  return (
    <Layout>
      <ContentWrapper additionalClass="p-5">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row className="g-4">

            {/* Image */}
            <Col xs={6}>
              <label className="text-md mb-1">Image</label>
              <div
                id="displayImage"
                style={{
                  height: "240px",
                  width: "100%",
                  backgroundImage: `url(${selectedImage ? selectedImage : null
                    })`,
                  border: `${selectedImage ? "0px" : "1px"} dashed #D2D6DA`,
                }}
                className="d-flex align-items-center justify-content-center background-position-center-top background-repeat-norepeat background-size-cover rounded overflow-hidden position-relative"
              >
                {selectedImage ? (
                  <div className="position-absolute end-0 top-0">
                    <ActionIcon
                      onClick={() => {
                        setSelectedImage(null);
                      }}
                      additionalClass="bi bi-x-circle-fill text-3xl color-red me-2"
                    />
                  </div>
                ) : null}

                {selectedImage ? null : (
                  <div>
                    <input
                      type="file"
                      id="imageInput"
                      {...register("image", { required: "Image is required" })}
                      onChange={handleImageChange}
                      accept="image/*"
                      className="opacity-0 position-absolute start-0 end-0 top-0 bottom-0 w-100 h-100"
                    />

                    <div className="text-center">
                      <i className="bi bi-plus-circle-fill text-5xl color-lightgreen d-block"></i>
                      <p className="text-md font-body text-center  ">
                        Upload your Image here.
                      </p>
                    </div>
                  </div>
                )}
              </div>
              {errors.image && (
                <p className="text-sm text-danger mt-1 font-body">
                  {errors.image.message}
                </p>
              )}
            </Col>

            {/* Title */}
            <Col xs={6}>
              <label className="text-md mb-1">Title</label>

              <input
                className="po-edit-input"
                placeholder="Please enter the title..."
                {...register("title", {
                  required: "Title is required",
                  pattern: {
                    value: /^(?!\s*$).+/,
                    message: "Only alphabetic characters are allowed.",
                  },
                })}
              />
              {errors.title && (
                <p className="text-sm text-danger mt-1 font-body">
                  {errors.title.message}
                </p>
              )}
            </Col>

            {/* Redirection */}
            <Col xs={6}>
              <label className="text-md mb-1">Redirection</label>
              <input
                className="po-edit-input"
                type="text"
                placeholder="Please enter redirecting link..."
                {...register("redirection", {
                  required: "Redirection link is required",
                  pattern: {
                    value: /^(?!\s*$).+/,
                    message: "Only alphabetic characters are allowed.",
                  },
                })}
              />
              {errors.redirection && (
                <p className="text-sm text-danger mt-1 font-body">
                  {errors.redirection.message}
                </p>
              )}
            </Col>

            {/* Description */}
            <Col xs={6}>
              <label className="text-md mb-1">Description</label>
              <input
                type="text"
                className="po-edit-input h-75"
                placeholder="Description...."
                {...register("decription", {
                  required: "Description is required",
                  pattern: {
                    value: /^(?!\s*$).+/,
                    message: "Only alphabetic characters are allowed.",
                  },
                })}
              />
              {errors.decription && (
                <p className="text-sm text-danger mt-1 font-body">
                  {errors.decription.message}
                </p>
              )}
            </Col>

            {/* Buttons */}
            <Col className="text-end">
              <OutlinedButton
                link="/journey"
                name="Cancel"
                additionalClass="me-3"
              />
              <SuccessButton
                type="submit"
                disabled={showToaster}
                name="Save"
                icon="bi-check-circle"
              />
            </Col>
          </Row>
        </form>
      </ContentWrapper>
      <Toaster
        showToaster={showToaster}
        setShowToaster={setShowToaster}
        bg={toasterDetails.bg}
        title={toasterDetails.title}
        bodyText={toasterDetails.bodyText}
        statusIcon={toasterDetails.statusIcon}
      />
    </Layout>
  );
};
export default CreateJourneyToBeOne;
