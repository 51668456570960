import React, { useState, useEffect } from 'react';
import moment from "moment";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
const DateRange = ({ getDateRange, startDate, endDate }) => {
  // const handleEvent = (event) => {
  //   console.log(event, "the event coming inside the handleEvent");
  // }
  const handleDateRangeChange = (event, picker) => {

    getDateRange(moment(picker.startDate).format("YYYY-MM-DD"), moment(picker.endDate).format("YYYY-MM-DD"))
  }
  return (
    <DateRangePicker
      initialSettings={{ startDate: moment(startDate).format("DD/MM/YYYY"), endDate: moment(endDate).format("DD/MM/YYYY") }}
      // initialSettings={{ startDate: "2023-04-01", endDate: "2023-05-01" }}
      onApply={handleDateRangeChange}
    // onEvent={() => handleEvent()}
    // onCallback={() => handleCallback()}
    >
      <div className='cursor-pointer shadow-sm bg-light py-2 px-3 d-inline-block rounded font-body text-xs text-xl-sm'>{startDate} To {endDate}<i className="bi bi-calendar-range-fill ms-2"></i>
      </div>
    </DateRangePicker>
  )
}
export default DateRange;