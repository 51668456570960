import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import db from "../../firebase";
import { useEffect, useRef, useState } from "react";
import PrimaryButton from "../../Components/Global/Buttons/PrimaryButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import Layout from "../../Layout/Layout";
import { Controller, useForm } from "react-hook-form";
import ContentWrapper from "../../Components/Global/ContentWrapper";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Toaster from "../../Components/Global/Toast";
import axios from "axios";
import ActionIcon from "../../Components/Global/Buttons/ActionIcons";

const CreateMembership = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const optionsRef = useRef();
  const token = localStorage.getItem("authenticate");
  const [showToaster, setShowToaster] = useState(false);
  const [users, setUsers] = useState([]);
  const [kycDetails, setKycDetails] = useState({});
  // const [options, setOptions] = useState([]);
  const [membershipsOptions, setMembershipOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedUser, setSelectedUser] = useState({});
  const [suggestions, setSuggestions] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [amethystPlan, setAmethystPlan] = useState("");
  const [toasterDetails, setToasterDetails] = useState({
    title: "",
    bodyText: "",
    bg: "",
    statusIcon: "",
  });
  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const [cabinCrew, setCabinCrew] = useState({
    frontId: null,
    backId: null,
  });
  const user_id = searchParams.get('user_id');
  // Apply membership modal
  const [cshow, csetShow] = useState(false);
  const [kycLocations, setKycLocations] = useState({
    countries: [],
    states: [],
    cities: [],
    nationalty: [],
  });
  const [images, setImages] = useState({
    frontId: null,
    backId: null,
    userPhoto: null,
  });
  const [locations, setLocations] = useState({
    country: {
      countryName: "",
      countryId: "",
    },
    state: {
      stateName: "",
      stateId: "",
    },
    city: {
      cityName: "",
      cityId: "",
    },
  });
  const [previewImage, setPreviewImage] = useState("");
  const [backIdPreviewImage, setBackIdPreviewImage] = useState("");
  const [userPhotoPreviewImage, setUserPhotoPreviewImage] = useState("");
  const optionsc = [
    { value: "Mostly daily", label: "Regularly" },
    { value: "Weekly", label: "Weekends Only" },
    { value: "Occasionally", label: "Occasionally" },
  ];

  const ChandleClose = () => {
    reset();
    csetShow(false);
  };
  const ChandleShow = () => csetShow(true);

  // APPLYING FOR KYC


  const applyForMembershipRequest = async () => {
    let isAmeyst = selectedOption === "91e5aae1-6edd-4394-a648-5908084db3e7";
    const headers = {
      headers: {
        developement: true,
        token: token,
      },
    };
    let selectedMembershipDetail = optionsRef.current.find(
      (membership) => membership.id === selectedOption
    );
    let userDetails = !user_id ? users.find((user) => user.id === selectedUser.id) : selectedUser
    let { id, name } = selectedMembershipDetail;
    let { price, plan_unique_id } = amethystPlan === "amethyst-three-months" ? selectedMembershipDetail.plans[1] : selectedMembershipDetail.plans[0];
    let payload = {
      membership_id: id,
      membership_name: name,
      membership_plan_id: isAmeyst ? amethystPlan : plan_unique_id,
      referral_code: null,
      amount: price,
      old_membership_id: userDetails.active_membership_id
        ? userDetails.active_membership_id
        : null,
      old_membership_name: userDetails.active_membership_name
        ? userDetails.active_membership_name
        : null,
      user_id: selectedUser.id,
      cabin_crew: isAmeyst ? true : false,
      govt_front_image_id: kycDetails.govt_id_front,
      govt_back_image_id: kycDetails.govt_id_back,
      cabin_crew_front_image_id: isAmeyst
        ? await insertingImage(cabinCrew.frontId)
        : null,
      cabin_crew_back_image_id: isAmeyst
        ? await insertingImage(cabinCrew.backId)
        : null,
    };


    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/membership/v3/membershipRequest`,
        payload,
        headers
      );
      setShowModal(false);
      setToasterDetails((prev) => ({
        ...prev,
        title: "Success",
        bodyText: "Successfully Requested for membership",
        bg: "success",
        statusIcon: <i className="bi bi-check text-success text-3xl me-2"></i>,
      }));
      setSelectedOption("");
      setSelectedUser({});
      setSearchItem("")
      setTimeout(() => {
        setShowToaster(false);
        navigate("/memberships");
      }, 2000);
    } catch (e) {
      setToasterDetails((prev) => ({
        ...prev,
        title: "Error",
        bodyText: e && e.response && e.response.data && e.response.data.message ? e.response.data.message : "Something went wrong",
        bg: "danger",
        statusIcon: (
          <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
        ),
      }));
      setSelectedOption("");
      setSelectedUser({})
      setSearchItem("")
      setTimeout(() => {
        setShowToaster(false);
      }, 2000);
    }
  }


  const verifyingUserKyc = async () => {
    try {
      const response = await getDocs(
        query(
          collection(db, "user_kyc"),
          where("user_id", "==", selectedUser.id),
          where("status", "in", ["Approved", "Pending"])
        )
      );
      response && response.docs && response.docs[0] && setKycDetails(response.docs[0].data())
      if (response.docs.length > 0) {
        return response;
      }
    } catch (err) {
      console.error(err);
    }
  };

  // Membership request  -- start
  useEffect(() => {
    fetchAllMemberships();
    if (user_id) {
      (async () => {
        const userDetails = await getDoc(query(doc(db, "users", user_id)))
        if (userDetails.exists()) {
          setSelectedUser(userDetails.data());
          setSearchItem(userDetails.data().name);
          filterUserMembershipsOptions(userDetails.data().active_membership_name)
        } else {
          console.log("No such document!");
        }
      })()
    } else {
      fetchAllCountriesForKyc();
      fetchAllUsers();
    }
  }, []);

  // Locations and nationalities     ---Start

  //Uploading Ameyst image

  const insertingImage = async (imageData) => {
    let form = new FormData();
    form.append("images", imageData);
    let responseImage = await axios
      .post(
        `${process.env.REACT_APP_MULTIPLEIMAGES_POSTING_URL}/uploads/uploadImages`,
        form,
        { headers: { "Content-Type": "multipart/form-data" } }
      )
      .then((resImage) => {
        return resImage && resImage.data.url[0];
      })
      .catch((error) => console.log(error, "err"));
    return responseImage;
  };

  const fetchAllCountriesForKyc = async () => {
    try {
      const { data } = await (
        await axios.get(`${process.env.REACT_APP_API_URL}/location/v2/countries`)
      ).data;
      setKycLocations({
        ...kycLocations,
        countries: data,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const fetchAllStates = async (countryId) => {
    try {
      const { data } = (
        await axios.get(
          `${process.env.REACT_APP_API_URL}/location/v1/userState?country=${countryId}`
        )
      ).data;
      setKycLocations({
        ...kycLocations,
        states: data,
      });
    } catch (error) {
      console.error(error);
    }
  };
  const fetchAllCities = async (stateId, countryId) => {
    try {
      const { data } = (
        await axios.get(
          `${process.env.REACT_APP_API_URL}/location/v3/userCities?country=${countryId}&state=${stateId}`
        )
      ).data;
      setKycLocations({
        ...kycLocations,
        cities: data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  // Locations and nationalities     ---End
  const handleFrontId = (e) => {
    const file = e.target.files[0];
    if (file) {
      const allowedFormats = ["image/jpeg", "image/png", "image/gif"]; // Add more formats as needed
      if (allowedFormats.includes(file.type)) {
        setPreviewImage(file);
        setImages({
          ...images,
          frontId: file,
        });
        setValue("frontId", e.target.value)
        clearErrors("frontId");


        const reader = new FileReader();
        reader.onload = () => {
          setPreviewImage(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        setError("cabinCrewFrontId", {
          type: "manual",
          message: "Invalid image format",
        });
        setTimeout(() => {
          clearErrors("cabinCrewFrontId");
        }, 1000);
        console.error("Invalid image format. Please select a valid image.");
      }
    }
  };


  const handleBackId = (e) => {
    const file = e.target.files[0];
    setBackIdPreviewImage(file);
    setImages({
      ...images,
      backId: file,
    });
    clearErrors("backId");
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setBackIdPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleUserPhoto = (e) => {
    const file = e.target.files[0];
    setUserPhotoPreviewImage(file);
    setImages({
      ...images,
      userPhoto: file,
    });
    setValue("userImage", file);
    clearErrors("userImage");
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setUserPhotoPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleKycForm = async (data) => {
    console.log(data, "datadatadata")
    ChandleClose();
    let {
      name,
      nationality,
      mailing_address,
      pincode,
      government_number,
      frequency_of_clubbing,
    } = data;
    setShowToaster(true);
    setToasterDetails((prev) => ({
      ...prev,
      title: "Applying...",
      bodyText: "Applying for Kyc",
      bg: "warning",
      statusIcon: (
        <div className="spinner-border text-warning me-2" role="status"></div>
      ),
    }));
    // const images = await insertingImages();
    // if (Array.isArray(images)) {
    const headers = {
      headers: {
        developement: true,
        token: token,
      },
    };
    let payload = {
      user_id: selectedUser.id,
      name: name,
      nationalty: nationality,
      residency: "residency",
      home_country_id: locations.country.countryId,
      home_country_name: locations.country.countryName,
      permanent_address: `${mailing_address},${pincode}`,
      home_state_id: locations.state.stateId,
      home_state_name: locations.state.stateName,
      home_city_id: locations.city.cityId,
      home_city_name: locations.city.cityName,
      government_id_number: government_number,
      frequency_of_clubbing: frequency_of_clubbing,
      govt_id_front: await insertingImage(images.frontId) || null,
      govt_id_back: await insertingImage(images.backId) || null,
      user_image: images && await insertingImage(images.userPhoto) || null,
      zipcode: data.pincode
    };
    console.log(payload)
    try {
      await axios
        .post(`${process.env.REACT_APP_API_URL}/kyc/v2/kyc`, payload, headers)
        .then((res) => {
          setShowModal(false);
          setToasterDetails((prev) => ({
            ...prev,
            title: "Success",
            bodyText: "Applied Kyc successfully",
            bg: "success",
            statusIcon: (
              <i className="bi bi-check text-success text-3xl me-2"></i>
            ),
          }));
          applyForMembershipRequest()
        })
        .catch((err) => {
          setToasterDetails((prev) => ({
            ...prev,
            title: "Error",
            bodyText: "Something went wrong",
            bg: "danger",
            statusIcon: (
              <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
            ),
          }));
          setTimeout(() => {
            setShowToaster(false);
          }, 2000);
        });
    } catch (error) {
      console.error(error);
    }
    // }
  };

  // Membership request and Kyc   --End


  const ameystFormValidation = () => {
    let error = false;
    if (cabinCrew.frontId === null) {
      setError("cabinCrewFrontId", {
        type: "manual",
        message: "Please select a file",
      });
      setTimeout(() => {
        clearErrors("cabinCrewFrontId");
      }, 1000);
      error = true;
    }
    if (cabinCrew.backId === null) {
      setError("cabinCrewBackId", {
        type: "manual",
        message: "Please select a file",
      });
      setTimeout(() => {
        clearErrors("cabinCrewBackId");
      }, 1000);
      error = true;
    }
    if (selectedUser === "") {
      setError("user", {
        type: "manual",
        message: "Please select a user",
      });
      setTimeout(() => {
        clearErrors("user");
      }, 1000);
      error = true;
    }
    if (amethystPlan === "") {
      setError("plan", {
        type: "manual",
        message: "Please select a plan",
      });
      setTimeout(() => {
        clearErrors("plan");
      }, 1000);
      error = true;
    }
    return error;
  };

  const formValidation = () => {
    let isError = {};
    if (selectedOption === "") {
      setError("option", {
        type: "manual",
        message: "Please select a option",
      });
      setTimeout(() => {
        clearErrors("option");
      }, 1000);
      isError.option = true;
    }
    if (selectedUser === "") {
      setError("user", {
        type: "manual",
        message: "Please select a user",
      });
      setTimeout(() => {
        clearErrors("user");
      }, 1000);
      isError.user = true;
    }

    return Object.keys(isError).length > 0;
  };

  const handleApplyButton = async (e) => {
    e.preventDefault();
    let isAmeyst = selectedOption === "91e5aae1-6edd-4394-a648-5908084db3e7";
    const headers = {
      headers: {
        developement: true,
        token: token,
      },
    };
    if (!isAmeyst) {
      let formValidationResult = formValidation();
      if (formValidationResult) {
        return formValidationResult;
      }
    }
    // Amethyst
    else if (isAmeyst) {
      if (ameystFormValidation()) {
        return;
      }
    }
    setShowToaster(true);
    setToasterDetails((prev) => ({
      ...prev,
      title: "Verifying...",
      bodyText: "Memebership Form",
      bg: "warning",
      statusIcon: (
        <div className="spinner-border text-warning me-2" role="status"></div>
      ),
    }));
    let kycVerification = await verifyingUserKyc();
    if (kycVerification) {
      setTimeout(() => {
        setToasterDetails((prev) => ({
          ...prev,
          title: "Requesting...",
          bodyText: "Requesting for membership...",
          bg: "warning",
          statusIcon: (
            <div className="spinner-border text-warning me-2" role="status"></div>
          ),
        }));
        applyForMembershipRequest();
      }, 3000)
    } else {
      setTimeout(() => {
        setShowToaster(false);
        csetShow(true);
        // applyForMembershipRequest()
      }, 2000)
    }
  };

  const filterUserMembershipsOptions = (active_membership_name) => {
    switch (active_membership_name) {
      case 'Silver':
        {
          let filteringMemberships = ["Gold", "Platinum", "Amethyst", "Solitaire"];
          let membershipOptions = optionsRef.current.filter(ele => (filteringMemberships.includes(ele.name)));
          setMembershipOptions(membershipOptions);
          break;
        }
      case 'Gold':
        {
          let filteringMemberships = ["Platinum", "Amethyst", "Solitaire"];
          let membershipOptions = optionsRef.current.filter(ele => (filteringMemberships.includes(ele.name)));
          setMembershipOptions(membershipOptions);
          break;
        }
      case 'Solitaire':
        {
          let filteringMemberships = ["Amethyst"];
          let membershipOptions = optionsRef.current.filter(ele => (filteringMemberships.includes(ele.name)));
          setMembershipOptions(membershipOptions);
          break;
        }
      case 'Platinum':
        {
          let filteringMemberships = ["Amethyst", "Solitaire"];;
          let membershipOptions = optionsRef.current.filter(ele => (filteringMemberships.includes(ele.name)));
          setMembershipOptions(membershipOptions);
          break;
        }
      case 'Amethyst':
        {
          let filteringMemberships = ["Gold", "Platinum", "Solitaire",];
          let membershipOptions = optionsRef.current.filter(ele => (filteringMemberships.includes(ele.name)));
          setMembershipOptions(membershipOptions);
          break;
        }
      default:
        setMembershipOptions(optionsRef.current);
        break;
    }
  }



  // Fetch all memberships

  const fetchAllMemberships = async () => {
    try {
      const response = await getDocs(collection(db, "memberships"));
      let options = response.docs.map((doc) => doc.data());
      optionsRef.current = options;
      setMembershipOptions(options)
    } catch (error) {
      console.log(error);
    }
  };

  // Fetch all users

  const fetchAllUsers = async () => {
    try {
      let users = await getDocs(query(collection(db, "users"), where("status", "==", "Active")));
      setUsers(users.docs.map((ele) => ele.data()));
      // setUsers(users);
    } catch (error) {
      console.log(error);
    }
  };

  // Suggestion box handler

  const handleSearchBox = (e) => {
    const { value } = e.target;
    setSearchItem(value); // Update searchTerm state immediately

    if (value === "") {
      setSuggestions([]);
      setSelectedUser("");
      setSelectedOption("")

    } else {
      const results = getSearchResults(value);
      setSuggestions(results);
    }
  };
  const getSearchResults = (input) => {
    if (typeof input !== "string") {
      return [];
    }

    return users.filter(
      (user) =>
        user &&
        user.name &&
        user.name.toLowerCase().includes(input.toLowerCase())
    );
  };

  // User suggesstion on click

  const handleUserOnClick = (e) => {
    let userIndex = e.target.getAttribute("value");
    let userDetails = users.find((ele) => ele.id === userIndex);
    setSuggestions([]);
    setSelectedUser(userDetails);
    setSearchItem(userDetails.name);
    filterUserMembershipsOptions(userDetails.active_membership_name)
  };

  // APPLYING FOR KYC

  return (
    <Layout path="/create-membership">
      {/* Applying for membership */}
      <ContentWrapper additionalClass="p-5">
        <form>
          <Row>
            <Col xs={12}>
              <h2 className="text-2xl text-lg-4xl font-bold mb-3">Create Membership Request</h2>
            </Col>
            <Col xs={6}>
              <label className="bold">Users</label>
              <div>
                <input
                  placeholder="Please enter user..."
                  value={searchItem}
                  className="po-edit-input"
                  type="search"
                  onChange={handleSearchBox}
                />
                <ul className={`mt-2 ${suggestions.length > 0 && `card`} py-2`}>
                  {suggestions &&
                    suggestions.map((suggestion, index) => (
                      <li
                        key={index}
                        value={suggestion.id}
                        className="on-hover px-3"
                        onClick={handleUserOnClick}
                      >
                        {suggestion.name} - {suggestion.email}
                      </li>
                    ))}
                </ul>
              </div>
              {errors.user && (
                <p className="mb-0 mt-1 text-sm text-danger">
                  {errors.user.message}
                </p>
              )}
            </Col>
            {selectedUser &&
              <Col xs={6}>
                <div>
                  <p className="text-lg mb-1 font-semibold">Choose memberships</p>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'start'
                  }}>
                    {membershipsOptions &&
                      membershipsOptions.length > 0 &&
                      membershipsOptions.map((option, index) => (
                        <label key={index} className="radio-container" >
                          <input
                            type="radio"
                            checked={selectedOption === option.id}
                            value={option.name}
                            onChange={(e) => {
                              setSelectedOption(option.id);
                            }}
                          />
                          <span className="text-md"> {option.name}</span>
                        </label>
                      ))}
                  </div>
                  {errors.option && (
                    <p className="mb-0 text-sm text-danger">
                      {errors.option.message}
                    </p>
                  )}

                  {/*  If Ameyst it will display */}

                  {selectedOption === "91e5aae1-6edd-4394-a648-5908084db3e7" && (
                    <div className="d-flex flex-column">
                      <div className="mb-3">
                        <p className="mb-0 bold">Front Id</p>
                        <input
                          type="file"
                          className="form-control"
                          accept="image/*"
                          {...register("cabinCrewFrontId", {
                            required: "Please choose a front id",
                          })}
                          onChange={(e) => {
                            const file = e.target.files[0];

                            // Check if a file was selected
                            if (file) {
                              // Define allowed image formats (MIME types) for images
                              const allowedFormats = ["image/jpeg", "image/png", "image/gif", "image/svg+xml"]; // Add more formats as needed

                              // Check if the selected file's MIME type is in the list of allowed formats
                              if (allowedFormats.includes(file.type)) {
                                // The selected file is an image with an allowed format
                                setCabinCrew({ ...cabinCrew, frontId: file });
                              } else {
                                // The selected file is not an allowed image format
                                // Handle the error, e.g., display an error message to the user
                                setError("cabinCrewFrontId", {
                                  type: "manual",
                                  message: "Invalid file format"
                                })
                                setTimeout(() => {
                                  clearErrors("cabinCrewFrontId")
                                }, 1000);
                                e.target.value = ""
                                console.error("Invalid image format. Please select a valid image.");
                              }
                            }
                          }}

                        />
                        {errors.cabinCrewFrontId && (
                          <p className="mb-0 text-sm text-danger bold">
                            {errors.cabinCrewFrontId.message}
                          </p>
                        )}
                        <p className="text-warning mt-2">Alllowed formats JPEG,PNG</p>

                      </div>
                      <div>
                        <p className="mb-0 bold">Back Id</p>
                        <input
                          accept="image/*"
                          type="file"
                          className="form-control mb-1"
                          {...register("cabinCrewBackId", {
                            required: "Please choose a back id",
                          })}
                          onChange={(e) => {
                            let file = e.target.files[0];
                            if (file) {
                              // Define allowed image formats (MIME types) for images
                              const allowedFormats = ["image/jpeg", "image/png", "image/gif", "image/svg+xml"]; // Add more formats as needed

                              // Check if the selected file's MIME type is in the list of allowed formats
                              if (allowedFormats.includes(file.type)) {
                                // The selected file is an image with an allowed format
                                setCabinCrew({ ...cabinCrew, backId: file });
                              } else {
                                // The selected file is not an allowed image format
                                // Handle the error,
                                setError("cabinCrewBackId", {
                                  type: "manual",
                                  message: "Invalid file format"
                                })
                                setTimeout(() => {
                                  clearErrors("cabinCrewBackId")
                                }, 1000);
                                e.target.value = ""
                                console.error("Invalid image format. Please select a valid image.");
                              }
                            }
                          }}
                        />
                        {errors.cabinCrewBackId && (
                          <p className="mb-0 text-sm text-danger bold">
                            {errors.cabinCrewBackId.message}
                          </p>
                        )}
                        <p className="text-warning mt-0">Alllowed formats JPEG,PNG</p>

                      </div>
                      <div className="mb-3">
                        <p className="mb-0 bold">Choose plan</p>
                        <select  {...register("plan", {
                          required: "Please select a plan"
                        })} className="po-edit-input mt-3" onChange={(e) => {
                          console.log(e.target.value, "e.target.valuee.target.valuee.target.value")
                          setAmethystPlan(e.target.value)
                        }}>
                          <option>Select plan</option>
                          <option value="amethyst-one-year">75$ / Year</option>
                          <option value="amethyst-three-months">25$ / 3 Months</option>
                        </select>

                        {errors.plan &&
                          <p className="mb-0 text-sm text-danger bold">{errors.plan.message}</p>

                        }

                      </div>
                    </div>
                  )}
                  {/* <button type="submit">Submit</button> */}
                </div>
              </Col>
            }
            <div className="text-end">
              <PrimaryButton
                disabled={showToaster}
                name="Request"
                onClick={handleApplyButton}
              />
            </div>
          </Row>
        </form>
      </ContentWrapper>

      {/* Applying for membership */}

      {/* KYC request modal : Start */}
      <Modal show={cshow} onHide={ChandleClose} size="xl" centered>
        <Modal.Header className="d-flex justify-content-between align-items-center">

          <Modal.Title>Membership Form</Modal.Title>


          <i
            className="bi bi-x-circle-fill text-4xl color-ternary cursor-pointer"
            onClick={ChandleClose}
          ></i>
        </Modal.Header>

        <Modal.Body>
          <p className="text-danger text-lg mb-3">Update Membership Form details</p>
          <form onSubmit={handleSubmit(handleKycForm)}>
            {/* Basic details : Start */}
            <h4 className="text-xl font-semibold">Basic Details</h4>
            <Row className="g-4 mb-5 pb-5 border-bottom">
              {/* Name */}
              <Col lg={6}>
                <label className="color-lightestblue font-normal text-sm mb-0 font-body mb-1">
                  Your name on the card
                </label>
                <input
                  className="po-edit-input"
                  type="text"
                  {...register("name", {
                    required: "This is required field",
                    pattern: {
                      value: /^(?!\s*$).+/,
                      message: "Only alphabetic characters are allowed.",
                    },
                  })}
                />

                {errors.name && (
                  <p className="text-danger mt-1">{errors.name.message}</p>
                )}
              </Col>

              {/* Nationality */}
              <Col lg={6}>
                <label className="color-lightestblue font-normal text-sm mb-0 font-body mb-1">
                  Nationality
                </label>
                <Controller
                  name="nationality"
                  rules={{ required: "This is required field" }}
                  control={control}
                  defaultValue=""
                  render={({ fields }) => {
                    return (
                      <select
                        {...fields}
                        className="po-edit-input"
                        onChange={(e) => {
                          clearErrors("nationality");
                          setValue("nationality", e.target.value);
                        }}
                      >
                        <option>Select the country</option>
                        {kycLocations.countries &&
                          kycLocations.countries.map((country, index) => (
                            <option key={index} value={country.name}>
                              {country.name}
                            </option>
                          ))}
                      </select>
                    );
                  }}
                />
                {errors.nationality && (
                  <p className="text-danger mt-1">
                    {errors.nationality.message}
                  </p>
                )}
              </Col>

              {/* Mailing Address */}
              <Col lg={6}>
                <label className="color-lightestblue font-normal text-sm mb-0 font-body mb-1">
                  Mailing Address
                </label>
                <textarea
                  className="po-edit-input"
                  {...register("mailing_address", {
                    required: "This is required field",
                    pattern: {
                      value: /^(?!\s*$).+/,
                      message: "Only alphabetic characters are allowed.",
                    },
                  })}
                />
                {errors.mailing_address && (
                  <p className="text-danger mt-1">
                    {errors.mailing_address.message}
                  </p>
                )}
              </Col>

              {/* Country */}
              <Col lg={6}>
                <label className="color-lightestblue font-normal text-sm mb-0 font-body mb-1">
                  Country
                </label>
                <Controller
                  name="country"
                  control={control}
                  rules={{ required: "This is required field" }}
                  render={({ fields }) => {
                    return (
                      <select
                        className="po-edit-input"
                        {...fields}
                        onChange={(e) => {
                          let countryName =
                            e.target.options[e.target.selectedIndex].text;
                          clearErrors("country");
                          setValue("country", e.target.value);
                          setLocations({
                            ...locations,
                            country: {
                              ...locations.country,
                              countryName: countryName,
                              countryId: Number(e.target.value),
                            },
                          });
                          fetchAllStates(e.target.value);
                        }}
                      >
                        <option>Select the country</option>
                        {kycLocations.countries &&
                          kycLocations.countries.map((country, index) => (
                            <option key={index} value={country.id}>
                              {country.name}
                            </option>
                          ))}
                      </select>
                    );
                  }}
                />
                {errors.country && (
                  <p className="mt-1 text-danger">{errors.country.message}</p>
                )}
              </Col>

              {/* State */}
              <Col lg={6}>
                <label className="color-lightestblue font-normal text-sm mb-0 font-body mb-1">
                  State
                </label>
                <Controller
                  name="home_state"
                  control={control}
                  rules={{ required: "This is required field" }}
                  render={({ fields }) => {
                    return (
                      <select
                        className="po-edit-input"
                        {...fields}
                        onChange={(e) => {
                          let stateName =
                            e.target.options[e.target.selectedIndex].text;
                          clearErrors("home_state");
                          setValue("home_state", e.target.value);
                          setLocations({
                            ...locations,
                            state: {
                              ...locations.state,
                              stateName: stateName,
                              stateId: Number(e.target.value),
                            },
                          });
                          fetchAllCities(
                            e.target.value,
                            locations.country.countryId
                          );
                        }}
                      >
                        <option>Select the state</option>
                        {kycLocations.states &&
                          kycLocations.states.map((state, index) => (
                            <option key={index} value={state.id}>
                              {state.name}
                            </option>
                          ))}
                      </select>
                    );
                  }}
                />
                {errors.home_state && (
                  <p className="text-danger mt-1">
                    {errors.home_state.message}
                  </p>
                )}
              </Col>

              {/* Home City */}
              <Col lg={6}>
                <label className="color-lightestblue font-normal text-sm mb-0 font-body mb-1">
                  City
                </label>
                <Controller
                  control={control}
                  name="city"
                  rules={{ required: "This is required field" }}
                  render={({ fields }) => {
                    return (
                      <select
                        {...fields}
                        className="po-edit-input"
                        onChange={(e) => {
                          let cityName =
                            e.target.options[e.target.selectedIndex].text;
                          clearErrors("city");
                          setLocations({
                            ...locations,
                            city: {
                              ...locations.city,
                              cityName: cityName,
                              cityId: Number(e.target.value),
                            },
                          });
                          setValue("city", e.target.value);
                        }}
                      >
                        <option>Select the city</option>
                        {kycLocations.cities &&
                          kycLocations.cities.map((city, index) => (
                            <option key={index} value={city.id}>
                              {city.name}
                            </option>
                          ))}
                      </select>
                    );
                  }}
                />
                {errors.city && (
                  <p className="mt-1 text-danger">{errors.city.message}</p>
                )}
              </Col>

              {/* Address */}
              {/* <Col lg={6}>
                <label className="color-lightestblue font-normal text-sm mb-0 font-body mb-1">
                  Permanent Address
                </label>
                <textarea
                  className="po-edit-input"
                  {...register("permanent_address", {
                    required: "This is required field",
                    pattern: {
                      value: /^(?!\s*$).+/,
                      message: "Only alphabetic characters are allowed.",
                    },
                  })}
                />
                {errors.permanent_address && (

                  <p className="text-danger mt-1">
                    {errors.permanent_address.message}
                  </p>
                )}
              </Col> */}

              {/* Pincode */}
              <Col lg={6}>
                <label className="color-lightestblue font-normal text-sm mb-0 font-body mb-1">
                  Pincode
                </label>
                <input
                  {...register("pincode", {
                    required: "This is required field",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Please enter a valid numeric Pincode",
                    },
                  })}
                  className="po-edit-input"
                  placeholder="586214"
                  type="text"
                />
                {errors.pincode && (
                  <p className="text-danger mt-1">{errors.pincode.message}</p>
                )}
              </Col>

              {/* Government Id Number */}
              <Col lg={6}>
                <label className="color-lightestblue font-normal text-sm mb-0 font-body mb-1">
                  Government ID number
                </label>
                <input
                  {...register("government_number", {
                    required: "This is required field",
                    pattern: {
                      value: /^(?!\s*$).+/,
                      message: "Only alphabetic characters are allowed.",
                    },
                  })}
                  className="po-edit-input"
                  placeholder="00000000"
                  type="text"
                />
                {errors.government_number && (
                  <p className="text-danger mt-1">
                    {errors.government_number.message}
                  </p>
                )}
              </Col>

              {/* Club Frequeny */}
              <Col lg={6}>
                <label className="color-lightestblue font-normal text-sm mb-0 font-body mb-1">
                  Frequency of Clubbing
                </label>
                <div>
                  {optionsc.map((option, index) => (
                    <label key={index} className="radio-container">
                      <input
                        type="radio"
                        value={option.label}
                        {...register("frequency_of_clubbing", {
                          required: "This is required field",
                        })}
                      />
                      <span className="text-md"> {option.label}</span>
                    </label>
                  ))}
                </div>
                {errors.frequency_of_clubbing && (
                  <p className="text-danger mt-1">
                    {errors.frequency_of_clubbing.message}
                  </p>
                )}
              </Col>
            </Row>

            {/* Basic details : End */}

            {/* ID Varification : Start */}
            <h4 className="text-xl font-semibold mt-5">ID Verification</h4>
            <Row className="g-4 pb-5">
              {/* Front Id */}

              <Col xl={6} className="font-body">
                <label className="text-lg  font-medium color-gray mb-2">
                  Front side <span className="text-danger">*</span>
                </label>

                <div
                  style={{
                    backgroundImage: `url(${previewImage ? previewImage : null
                      })`,
                    border: `${previewImage ? "0px" : "1px"} dashed #D2D6DA`,
                  }}
                  className="po-updateEvent__inputPhoto d-flex align-items-center justify-content-center background-position-center-top background-repeat-norepeat background-size-cover rounded overflow-hidden position-relative"
                  {...register("frontId", {
                    required: "Please choose a image",
                  })}
                >
                  {previewImage ? (
                    <div className="position-absolute end-0 top-0">
                      <ActionIcon
                        onClick={() => {
                          // setLogoDeleteModal(true)
                          setPreviewImage(false);
                          setError("frontId", {
                            type: "manual",
                            message: "Please choose an Image",
                          });
                          setValue("frontId", null);
                        }}
                        additionalClass="bi bi-x-circle-fill text-3xl color-red me-2"
                      />
                    </div>
                  ) : null}
                  {previewImage ? null : (
                    <div>
                      <input
                        type="file"
                        id="image"
                        accept="image/*"
                        // value={state.display_image}
                        // {...register("display_image", { required: "Image is required" })}
                        onChange={handleFrontId}
                        className="opacity-0 position-absolute start-0 end-0 top-0 bottom-0"
                      />
                      <div className="text-center">
                        <i className="bi bi-plus-circle-fill text-5xl color-lightgreen d-block"></i>
                        <p className="text-xs font-semibold font-body text-center color-gray2 ">
                          Upload image here
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <p className="text-danger  mt-1">{errors.frontId?.message}</p>
              </Col>

              {/* Back Id */}

              <Col xl={6} className="font-body">
                <label className="text-lg  font-medium color-gray mb-2">
                  Back side
                </label>
                <div
                  style={{
                    backgroundImage: `url(${backIdPreviewImage ? backIdPreviewImage : null
                      })`,
                    border: `${backIdPreviewImage ? "0px" : "1px"
                      } dashed #D2D6DA`,
                  }}
                  className="po-updateEvent__inputPhoto d-flex align-items-center justify-content-center background-position-center-top background-repeat-norepeat background-size-cover rounded overflow-hidden position-relative"
                  {...register("backId")}
                >
                  {backIdPreviewImage ? (
                    <div className="position-absolute end-0 top-0">
                      <ActionIcon
                        onClick={() => {

                          setBackIdPreviewImage(false);

                        }}
                        additionalClass="bi bi-x-circle-fill text-3xl color-red me-2"
                      />
                    </div>
                  ) : null}
                  {backIdPreviewImage ? null : (
                    <div>
                      <input
                        type="file"
                        id="image"
                        accept="image/*"

                        onChange={handleBackId}
                        className="opacity-0 position-absolute start-0 end-0 top-0 bottom-0"
                      />
                      <div className="text-center">
                        <i className="bi bi-plus-circle-fill text-5xl color-lightgreen d-block"></i>
                        <p className="text-xs font-semibold font-body text-center color-gray2 ">
                          Upload image here
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <p className="text-danger  mt-1">{errors.backId?.message}</p>
              </Col>




              {/* User photo */}

              <Col xl={6} className="font-body">
                <label className="text-lg  font-medium color-gray mb-2">
                  User photo <span className="text-danger">*</span>
                </label>
                <div
                  style={{
                    backgroundImage: `url(${userPhotoPreviewImage ? userPhotoPreviewImage : null
                      })`,
                    border: `${userPhotoPreviewImage ? "0px" : "1px"
                      } dashed #D2D6DA`,
                  }}
                  className="po-updateEvent__inputPhoto d-flex align-items-center justify-content-center background-position-center-top background-repeat-norepeat background-size-cover rounded overflow-hidden position-relative"
                  {...register("userImage", {
                    required: "Please choose a image",
                  })}
                >
                  {userPhotoPreviewImage ? (
                    <div className="position-absolute end-0 top-0">
                      <ActionIcon
                        onClick={() => {
                          // setLogoDeleteModal(true)
                          setUserPhotoPreviewImage(false);
                          setError("userImage", {
                            type: "manual",
                            message: "Please choose an Image",
                          });
                          setValue("userImage", null);
                        }}
                        additionalClass="bi bi-x-circle-fill text-3xl color-red me-2"
                      />
                    </div>
                  ) : null}
                  {userPhotoPreviewImage ? null : (
                    <div>
                      <input
                        type="file"
                        id="image"
                        accept="image/*"
                        // value={state.display_image}
                        // {...register("display_image", { required: "Image is required" })}
                        onChange={handleUserPhoto}
                        className="opacity-0 position-absolute start-0 end-0 top-0 bottom-0"
                      />
                      <div className="text-center">
                        <i className="bi bi-plus-circle-fill text-5xl color-lightgreen d-block"></i>
                        <p className="text-xs font-semibold font-body text-center color-gray2 ">
                          Upload image here
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <p className="text-danger  mt-1">{errors.userImage?.message}</p>
              </Col>
            </Row>
            {/* ID Varification : End */}
            <div className="p-5 d-flex justify-content-end">
              <Button className="text-black text-md font-body font-semibold cursor-pointer po-border po-border-black py-3 px-6 radius-105  d-inline-block bg-transparent" onClick={ChandleClose}>Close</Button>
              <PrimaryButton
                disabled={showToaster}
                additionalClass="ms-2"
                name="Apply"
                type="submit"
              />
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {/* KYC request modal : End */}

      <Toaster
        showToaster={showToaster}
        setShowToaster={setShowToaster}
        bg={toasterDetails.bg}
        title={toasterDetails.title}
        bodyText={toasterDetails.bodyText}
        statusIcon={toasterDetails.statusIcon}
      />
    </Layout >
  );
};
export default CreateMembership;
