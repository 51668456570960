import React, { useRef, useEffect, useState } from "react";
import { Row, Col, Table, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import ContentWrapper from "../../Components/Global/ContentWrapper";
import Layout from "../../Layout/Layout";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import db from "../../firebase";
import { useForm } from "react-hook-form";
import moment from "moment";
import axios from "axios";
import SecondaryButton from "../Global/Buttons/SecondaryButton";
import OutlinedButton from "../Global/Buttons/OutlinedButton";
import SuccessButton from "../Global/Buttons/SuccessButton";
import Toaster from "../Global/Toast";
import { v4 as uuid } from "uuid";
import PrimaryButton from "../Global/Buttons/PrimaryButton";
import ActionIcon from "../Global/Buttons/ActionIcons";
import { PhotoProvider, PhotoView } from "react-photo-view";
import Zoom from 'react-medium-image-zoom'
import Skeleton from "react-loading-skeleton";
import Markdown from "react-markdown";

const EventBookingDetails = () => {
  const navigate = useNavigate();
  const [selectButtonError, setSelectButtonError] = useState(false);
  const [reason, setReason] = useState({
    reason: "",
    error: "",
  });

  const textAreaRef = useRef(null);
  const [paymentLinkCopied, setPaymentLinkCopied] = useState(false);
  // Checking url if it only for one club we want to redirect to the particular page
  const isClubBooking = window.location.search;
  const [bookingCompletionModal, setBookingCompletionModal] = useState(false);
  const [approveModal, setApprovalModal] = useState(false);
  const [showRejectionModal, setshowRejectionModal] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [currencySymbols, setCurrencySymbols] = useState([])
  const [clubDetails, setClubDetails] = useState({});
  const [currenciesRateInUSD, setCurrenciesRateInUSD] = useState({});
  const [countryCurrencyInfo, setCountryCurrencyInfo] = useState([]);
  const [transactionDetails, setTransactionDetails] = useState({
    data: {
      transactionId: "",
      transactionDate: moment(new Date()).format("YYYY-MM-DD"),
    },
    errors: {
      id: "",
      date: "",
    },
  });
  const [favoriteClubs, setFavouriteClubs] = useState({});
  const [multipleEmails, setMultipleEmails] = useState({
    email: "",
    error: "",
    emails: [],
  });
  const [invoice, setInvoice] = useState({
    invoiceFile: "",
    amount: 0,
    loyaltyPoint: 0,
    error: {
      file: "",
      amount: "",
      loyalityPoint: ""
    }
  });
  const [showCancelBookingModal, setCancelBookingModal] = useState(false);
  const [bookingCancellationDetails, setBookingCancellationDetails] = useState({
    reason: "",
    trasactionId: "",
    trasactionDate: moment(new Date()).format('YYYY-MM-DD'),
    transactionAmount: "",
    cancellationCharge: ""
  });
  const [bookingCharge, setBookingCharge] = useState({
    coverChargeAmount: 0,
    paymentLink: "",
    cardFillingPaymentLink: "",
    errors: {
      coverCharger: "",
      paymentLink: "",
      cardFillingPaymentLink: "",
      cancellationCharge: ""
    },
  });
  const [cancelBookingErrors, setCancelBookingErrors] = useState({
    reason: "",
    // trasactionId: "",
    // transactionAmount: "",
    transactionDate: "",
  });
  const [status, setStatus] = useState("");
  const [eventDetail, setEventDetail] = useState({});

  // membership details pop up
  const [showMember, setShowMember] = useState(false);
  const memberHandleClose = () => setShowMember(false);
  const memberHandleShow = () => setShowMember(true);
  const [membershipDetails, setMembershipDetails] = useState({});
  const [bookingUserDetails, setBookingUserDetails] = useState({})
  const [kycDetails, setKycDetails] = useState({});

  const user_id = localStorage.getItem("user_id");
  const [showModal, setShowModal] = useState(false);
  const [selection, setSelection] = useState("");
  // const user_name = localStorage.getItem("user_name");
  const user_email = localStorage.getItem("email");
  const [allComments, setAllComments] = useState([]);
  const [guests, setGuests] = useState([]);
  const [searchParams] = useSearchParams();
  const [showToaster, setShowToaster] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [commentLoader, setCommentLoader] = useState(false);
  const [toasterDetails, setToasterDetails] = useState({
    title: "",
    bodyText: "",
    bg: "",
    statusIcon: "",
  });
  const handleCopyPaymentLink = () => {
    if (textAreaRef.current) {
      // Create a range to select the text
      const range = document.createRange();
      range.selectNode(textAreaRef.current);

      // Select the text
      window.getSelection().removeAllRanges(); // Clear any previous selections
      window.getSelection().addRange(range);

      try {
        // Copy the selected text to the clipboard
        document.execCommand('copy');
        setPaymentLinkCopied(true);
        setTimeout(() => {
          setPaymentLinkCopied(false);
        }, 2000)
      } catch (err) {
        alert('Unable to copy text to clipboard');
      } finally {
        // Deselect the text
        window.getSelection().removeAllRanges();
      }
    }
  };

  const splitString = (input) => {
    let words = ""
    const threeLetters = input.substring(0, 3); // Get the first three letters
    const remainingLetters = input.substring(3); // Get the remaining letters
    [threeLetters, ...remainingLetters.match(/.{1,4}/g)].map((ele) => words += ele + " ") // Split the remaining letters into groups of four
    return words
  };

  //Modal handler
  const handleClose = () => {
    setApprovalModal(false);
    setSelectButtonError(false);
    setBookingCharge({
      ...bookingCharge,
      coverChargeAmount: 0,
      paymentLink: "",
      cardFillingPaymentLink: "",
      errors: {
        ...errors,
        coverCharger: "",
        paymentLink: "",
        cardFillingPaymentLink: "",
      },
    });
    setSelection("");
  };
  const fetchClubDetail = async (club_id) => {
    try {
      const clubResponse = await getDoc(doc(db, "clubs", club_id));
      if (clubResponse.exists()) {
        const clubData = clubResponse.data();
        setClubDetails(clubData);
      } else {
      }
    } catch (error) {
    }
  };
  const convertMinutesToHours = (minutes) => {
    const duration = moment.duration(minutes, "minutes");
    const hours = duration.hours();
    const minutesRemaining = duration.minutes();
    const formattedTime = moment({
      hours: hours,
      minute: minutesRemaining,
    }).format("h:mm A");
    return formattedTime;
  };
  // Handle booking model
  const handleBookingCompletionModal = () => {
    setBookingCompletionModal(true);
  };
  // //Handling Transaction details
  const handleTransaction = (e) => {
    e.preventDefault();
    // Validation
    let { transactionId, transactionDate } = transactionDetails.data;
    if (transactionId === "" || transactionDate === "") {
      let transactionTempForError = transactionDetails;
      if (transactionId === "") {
        transactionTempForError.errors.id = "Please enter a transaction ID";
      }
      if (transactionDate === "") {
        transactionDetails.errors.date = "Transaction date should not be empty";
      }
      setTransactionDetails({ ...transactionTempForError });
      return;
    }
    if (
      [transactionDetails.errors.date, transactionDetails.errors.id].includes(
        ""
      )
    ) {
      actionSubmit("Approved");
    }
  };
  // comment section
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { entry_id } = useParams();
  // const page = searchParams.get("page");
  // const event_id = searchParams.get("event_id");

  const getEventDetailWithId = async () => {
    const eventResponse = await getDoc(doc(db, "event_entry_bookings", entry_id));
    if (eventResponse.data()) {
      fetchClubDetail(eventResponse.data().club_id);
      // fetching booking user details
      const bookingUserResponse = await getDoc(doc(db, "users", eventResponse.data().user_id));
      bookingUserResponse &&
        bookingUserResponse.data() &&
        bookingUserResponse.data().favorite_clubs &&
        setFavouriteClubs(
          bookingUserResponse.data().favorite_clubs.filter((item) => item !== "")
        );
      setUserDetails(bookingUserResponse.data());
      eventResponse.data().comments &&
        setAllComments(eventResponse.data().comments);
      setGuests(eventResponse.data().people);
      setStatus(eventResponse.data().status);
      setBookingUserDetails(bookingUserResponse.data());
      const membershipId = eventResponse.data().user.mebership_id;
      // fetching membership details
      const membershipResponse = await getDoc(doc(db, "memberships", membershipId));
      setMembershipDetails(membershipResponse.data());
      setEventDetail(eventResponse.data());
      // fetching kyc details of the user who booked table
      const kycRef = collection(db, "user_kyc");
      const kycQuery = query(kycRef, where("user_id", "==", eventResponse.data().user_id));
      getDocs(kycQuery)
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            data && setKycDetails(data);
          });
        })
        .catch((error) => {
          // Handle any errors
        });
    }
  };

  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  // This function is for transaction modal, We are disabling dates which are after booking date , Because transaction should not happen after booking date
  const maxTransactionDate = () => {
    if (
      !eventDetail ||
      !eventDetail.booking_date ||
      !eventDetail.booking_date.seconds
    ) {
      return null;
    }
    let bookingDate = new Date(eventDetail && eventDetail.booking_date && eventDetail.booking_date.seconds * 1000);
    const year = bookingDate.getFullYear();
    const month = bookingDate.getMonth() + 1;
    const day = bookingDate.getDate();
    return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
  };

  // This function is for transaction modal, We are disabling dates which are after booking date , Because transaction should not happen after booking date
  const minTransactionDate = () => {
    if (
      !eventDetail ||
      !eventDetail.booking_date ||
      !eventDetail.booking_date.seconds
    ) {
      return null;
    }
    let bookingDate = new Date(eventDetail.createdAt.seconds * 1000);
    const year = bookingDate.getFullYear();
    const month = bookingDate.getMonth() + 1;
    const day = bookingDate.getDate();
    return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
  };
  // multiple email submit button
  const multipleEmailOnSubmit = async () => {
    setShowToaster(true);
    setToasterDetails((prev) => ({
      ...prev,
      title: "Sending...",
      bodyText: "Sending Booking Details...",
      bg: "warning",
      statusIcon: (
        <div className="spinner-border text-warning me-2" role="status"></div>
      ),
    }));
    try {
      let token = localStorage.getItem("authenticate");
      let payload = {
        email_arr: multipleEmails.emails,
        id: eventDetail.id,
        booking_type: eventDetail.booking_type,
      };
      await axios
        .post(`${process.env.REACT_APP_API_URL}/booking/v1/sendemail`, payload, {
          headers: {
            developement: true,
            token: token,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setShowModal(false);
          setToasterDetails((prev) => ({
            ...prev,
            title: "Success",
            bodyText: "Successfully sent",
            bg: "success",
            statusIcon: (
              <i className="bi bi-check text-success text-3xl me-2"></i>
            ),
          }));
          setMultipleEmails({
            ...multipleEmails,
            email: "",
            emails: [],
            error: "",
          });
          setTimeout(() => {
            setShowToaster(false);
          }, 2000);
        })
        .catch((err) => console.error(err));
    } catch (err) {
      setToasterDetails((prev) => ({
        ...prev,
        title: "Error",
        bodyText: "Something went wrong",
        bg: "danger",
        statusIcon: (
          <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
        ),
      }));
      setTimeout(() => {
        setShowToaster(false);
      }, 2000);
    }
  };

  //email validation
  const emailValidation = (email) => {
    let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email === "") {
      setMultipleEmails({
        ...multipleEmails,
        error: "Please enter an email...",
      });
      return false;
    } else if (!emailRegex.test(email)) {
      setMultipleEmails({ ...multipleEmails, error: "Invalid email..." });
      return false;
    }
    // checking for existing email
    else if (multipleEmails.emails.includes(email)) {
      setMultipleEmails({
        ...multipleEmails,
        error: "This email already added...",
      });
      return false;
    }
    return true;
  };

  //Multiple email handler
  const multipleEmailHandler = (e) => {
    e.preventDefault();
    let emailValidationResult = emailValidation(multipleEmails.email);
    if (emailValidationResult) {
      if (multipleEmails.email.trim() !== "") {
        setMultipleEmails((prevEmail) => ({
          ...prevEmail,
          emails: [...prevEmail.emails, prevEmail.email],
          error: "",
          email: "", // Clear the email input after adding it to the emails array
        }));
      }
    }
  };


  const fetchCurrencySymbols = async () => {
    let temp = [];
    axios.post(`${process.env.REACT_APP_API_URL}/booking/v1/getCurrencyRateInUSD`, {}, {
      headers: {
        'developement': true,
        'token': localStorage.getItem("authenticate"),
        'uid': user_id,
        "Content-Type": "application/json",
      }
    })
      .then((response) => {
        setCurrenciesRateInUSD(response.data.data);
        Object.keys(response.data.data).forEach(ele => temp.push(ele));
        setCurrencySymbols(temp);
        setCurrencySymbol(temp.find(item => item === "SGD"));
      })
      .catch((error) => {
        console.log(error);
      });
    try {
      let currenySymbolsResponse = await getDoc(doc(db, "picklist", "country_currency_info"));
      setCountryCurrencyInfo(Object.values(currenySymbolsResponse.data()))
    } catch (error) {
      console.error(error);
    }
  }

  //Loyality point

  // const calculateLoyaltyPoint = () => {
  //   let { amount, loyalityPoint } = invoice.error;
  //   if (amount === "" && loyalityPoint === "") {
  //     let errors = {
  //       amount: "",
  //       loyalityPoint: "",
  //       file: ""
  //     }
  //     if (invoice.amount === 0) {
  //       errors.amount = "Please enter a amount"
  //     }
  //     if (currencySymbol === "") {
  //       errors.loyalityPoint = "Please select a currency"
  //     }
  //     if (eventDetail.invoice_url === undefined) {
  //       if (invoice.invoiceFile === "") {
  //         errors.file = "Please select a file"
  //       }
  //     }
  //     setInvoice({
  //       ...invoice, error: {
  //         ...invoice.error, amount: errors.amount, loyalityPoint: errors.loyalityPoint, file: errors.file
  //       }
  //     })

  //   }
  //   if ((invoice.amount > 0) && (currencySymbol !== "") && (eventDetail.invoice_url || invoice.invoiceFile)) {
  //     const token = localStorage.getItem("authenticate");
  //     const headers = {
  //       headers: {
  //         developement: true,
  //         token: token,
  //       }
  //     };
  //     let response = axios.post(`${process.env.REACT_APP_API_URL}/booking/v1/calculateLoyalityPoint`, {
  //       "amount": invoice.amount,
  //       "currency_type": currencySymbol
  //     }, headers).then((res) => {
  //       setInvoice({
  //         ...invoice, loyaltyPoint: (res.data.usd).toFixed(2)
  //       })
  //     }).catch((err) => console.log(err))
  //   }
  // };

  //Invoice handler
  const invoiceHandler = (e) => {
    const file = e.target.files[0];
    var fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2);
    if (file.type !== "application/pdf" && file.type !== "image/jpeg" && file.type !== "image/png") {
      setInvoice({ ...invoice, error: { ...invoice.error, file: "Invalid File Type" } });
      e.target.value = "";
    }
    else if (parseFloat(fileSizeInMB) > 2) {
      setInvoice({
        ...invoice, error: {
          ...invoice.error, file: "File size exceeds the maximum limit. Please select a file less than 2MB"
        }
      });
      e.target.value = "";
    }
    else {
      setInvoice({
        ...invoice, invoiceFile: file, error: {
          ...invoice.error, file: ""
        }
      });
    }

  };

  //Invoice submission
  const invoiceSubmit = async () => {
    if (!eventDetail.invoice_url) {
      //checking if already error is there
      const { amount, file } = invoice.error;
      if (amount === "" && file === "") {
        const token = localStorage.getItem("authenticate");
        const headers = {
          headers: {
            developement: true,
            token: token,
            "Content-Type": "multipart/form-data",
          },
        };
        if (invoice.invoiceFile === "" || invoice.invoiceFile === undefined || invoice.amount === 0) {
          setInvoice({
            ...invoice, error: {
              ...invoice.error,
              file: (invoice.invoiceFile === "" || invoice.invoiceFile === undefined) ? "Please choose a invoice" : "",
              amount: invoice.amount === 0 ? "Please enter the amount" : ""
            },
          });
          return;
        }
        //Invoice upload
        setShowToaster(true);
        setToasterDetails((prev) => ({
          ...prev,
          title: "Updating...",
          bodyText: "Uploading Invoice...",
          bg: "warning",
          statusIcon: (
            <div
              className="spinner-border text-warning me-2"
              role="status"
            ></div>
          ),
        }));
        try {
          await axios
            .post(
              `${process.env.REACT_APP_API_URL}/booking/v2/uploadInvoice`,
              { id: eventDetail.id, invoice: invoice.invoiceFile, booking_type: "event_entry_booking", },
              headers
            )
            .then(async (response) => {
              let payload = {
                invoice_url: response.data.invoice_url,
                booking_type: "event_entry_booking",
                loyality_point: parseInt(invoice.loyaltyPoint),
                loyality_point_amount: invoice.amount,
                id: eventDetail.id,
                currency_type: currencySymbol
              };

              await axios.post(`${process.env.REACT_APP_API_URL}/booking/v1/creditLoyalityPoint`, payload, {
                headers: {
                  developement: true,
                  token: token,
                  "Content-Type": "application/json",
                },
              })
                .then(() => {
                  setShowModal(false);
                  setToasterDetails((prev) => ({
                    ...prev,
                    title: "Success",
                    bodyText: "Updated the status successfully",
                    bg: "success",
                    statusIcon: (
                      <i className="bi bi-check text-success text-3xl me-2"></i>
                    ),
                  }));
                  setInvoice({ ...invoice, error: "", invoiceFile: "" });
                  setTimeout(() => {
                    isClubBooking === "?club_booking=true" ? navigate(`/clubs-bookings/${eventDetail.club_id}?club_name=${eventDetail.club_name}&tab=club_booking_events`) :
                      navigate("/bookings?tab=events");
                  }, 2000);
                })
                .catch((err) => {
                  console.log(err)
                  setToasterDetails((prev) => ({
                    ...prev,
                    title: "Error",
                    bodyText: "Something went wrong",
                    bg: "danger",
                    statusIcon: (
                      <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
                    ),
                  }));
                  setTimeout(() => {
                    setShowToaster(false);
                  }, 2000);
                });
            })
            .catch((err) => {
              console.log(err)
              setToasterDetails((prev) => ({
                ...prev,
                title: "Error",
                bodyText: "Something went wrong",
                bg: "danger",
                statusIcon: (
                  <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
                ),
              }));
              setTimeout(() => {
                setShowToaster(false);
              }, 2000);
            });
        } catch (err) {
          setToasterDetails((prev) => ({
            ...prev,
            title: "Error",
            bodyText: "Something went wrong",
            bg: "danger",
            statusIcon: (
              <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
            ),
          }));
          setTimeout(() => {
            setShowToaster(false);
          }, 2000);
        }

      }
    }
    else {
      const { amount } = invoice.error;
      const token = localStorage.getItem("authenticate");
      if (amount === "") {
        if (invoice.amount === 0) {
          setInvoice({
            ...invoice, error: {
              ...invoice.error,
              amount: invoice.amount === 0 ? "Please enter the amount" : ""
            },
          });
          return;

        }
      };
      //Invoice upload
      setShowToaster(true);
      setToasterDetails((prev) => ({
        ...prev,
        title: "Updating...",
        bodyText: "Uploading Invoice...",
        bg: "warning",
        statusIcon: (
          <div
            className="spinner-border text-warning me-2"
            role="status"
          ></div>
        ),
      }));
      try {
        let payload = {
          invoice_url: eventDetail.invoice_url,
          booking_type: "event_entry_booking",
          loyality_point: invoice.loyaltyPoint,
          loyality_point_amount: invoice.amount,
          id: eventDetail.id,
          currency_type: currencySymbol
        };

        await axios.post(`${process.env.REACT_APP_API_URL}/booking/v1/creditLoyalityPoint`, payload, {
          headers: {
            developement: true,
            token: token,
            "Content-Type": "application/json",
          },
        })
          .then(() => {
            setShowModal(false);
            setToasterDetails((prev) => ({
              ...prev,
              title: "Success",
              bodyText: "Updated the status successfully",
              bg: "success",
              statusIcon: (
                <i className="bi bi-check text-success text-3xl me-2"></i>
              ),
            }));
            setInvoice({ ...invoice, error: "", invoiceFile: "" });
            setTimeout(() => {
              isClubBooking === "?club_booking=true" ? navigate(`/clubs-bookings/${eventDetail.club_id}?club_name=${eventDetail.club_name}&tab=club_booking_events`) :
                navigate("/bookings?tab=events");
            }, 2000);
          })
          .catch((err) => {
            setToasterDetails((prev) => ({
              ...prev,
              title: "Error",
              bodyText: "Something went wrong",
              bg: "danger",
              statusIcon: (
                <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
              ),
            }));
            setTimeout(() => {
              setShowToaster(false);
            }, 2000);
          });
      } catch (err) {
        setToasterDetails((prev) => ({
          ...prev,
          title: "Error",
          bodyText: "Something went wrong",
          bg: "danger",
          statusIcon: (
            <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
          ),
        }));
        setTimeout(() => {
          setShowToaster(false);
        }, 2000);
      }

    }
  }

  // Invoice submission

  // Form Validation
  const formValidation = () => {
    const regexPattern = /^\s*$/;
    const {
      reason,
      trasactionDate,
      // trasactionId,
      // transactionAmount,
      // cancellationCharge
    } = bookingCancellationDetails;
    let errors = {};
    if (reason === "") {
      errors.reason = "Please enter reason";
    }
    if (trasactionDate === "") {
      errors.transactionDate = "Please enter a transaction date";
    }
    // if (trasactionId === "") {
    //   errors.trasactionId = "Please enter transaction Id";
    // }

    // if (cancellationCharge === "") {
    //   errors.cancellationCharge = "Please enter cancellation charge";
    // }
    if (reason !== "") {
      if (regexPattern.test(reason))
        errors.reason = "Only alpha-numeric value allowed";
    }
    // if (trasactionId !== "") {
    //   if (regexPattern.test(trasactionId))
    //     errors.trasactionId = "Only alpha-numeric value allowed";
    // }

    setCancelBookingErrors(errors);
    return Object.keys(errors).length === 0;
  };
  // Future dates we are not showing to user with this function
  const getCurrentDate = () => {
    const today = new Date(eventDetail && eventDetail.booking_date && eventDetail.booking_date.seconds * 1000);
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Pad the month and day values with leading zeros if necessary
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  };

  // api call
  const formCancellationApiCall = async () => {
    const jwtToken = localStorage.getItem("authenticate");
    setCancelBookingModal(false);
    setShowToaster(true);
    setToasterDetails((prev) => ({
      ...prev,
      title: "Cancelling...",
      bodyText: "Cancelling the booking...",
      bg: "warning",
      statusIcon: (
        <div className="spinner-border text-warning me-2" role="status"></div>
      ),
    }));
    let payload;
    if (bookingCancellationDetails.trasactionId) {
      payload = {
        id: entry_id,
        booking_type: eventDetail.booking_type,
        refund_transaction_id: bookingCancellationDetails.trasactionId,
        refund_transaction_date: bookingCancellationDetails.trasactionDate,
        reason_for_cancellation: bookingCancellationDetails.reason,
        cancellation_charge: Number(bookingCancellationDetails.cancellationCharge)
      };
    }
    else {
      payload = {
        id: entry_id,
        booking_type: eventDetail.booking_type,
        refund_transaction_date: bookingCancellationDetails.trasactionDate,
        reason_for_cancellation: bookingCancellationDetails.reason,
        cancellation_charge: Number(bookingCancellationDetails.cancellationCharge)
      };
    }
    await axios
      .post(`${process.env.REACT_APP_API_URL}/booking/v1/bookingCancellation`, payload, {
        headers: {
          developement: true,
          token: jwtToken,
        },
      })
      .then((response) => {
        setShowModal(false);
        setToasterDetails((prev) => ({
          ...prev,
          title: "Success",
          bodyText: "Successfully Cancelled",
          bg: "success",
          statusIcon: (
            <i className="bi bi-check text-success text-3xl me-2"></i>
          ),
        }));
        setTimeout(() => {
          isClubBooking === "?club_booking=true" ? navigate(`/ clubs - bookings / ${eventDetail.club_id}?club_name = ${eventDetail.club_name}& tab=club_booking_events`) :
            navigate("/bookings?tab=events");
        }, 2000);
      })
      .catch((error) => {
        setToasterDetails((prev) => ({
          ...prev,
          title: "Error",
          bodyText: "Something went wrong",
          bg: "danger",
          statusIcon: (
            <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
          ),
        }));
        setTimeout(() => {
          setShowToaster(false);
        }, 2000);
        console.log(error)
      });
  };

  const handleCancelBooking = async () => {
    let validationResponse = formValidation();
    if (validationResponse) {
      setCancelBookingModal(true);
    }
  };
  const actionSubmit = async (type) => {
    // setIsLoading(true);
    let payload = {}
    if (type === "Approved") {
      const jwtToken = localStorage.getItem("authenticate");
      setBookingCompletionModal(false);
      setShowToaster(true);
      setToasterDetails((prev) => ({
        ...prev,
        title: "Updating...",
        bodyText: "Please hold while we are updating the details",
        bg: "warning",
        statusIcon: (
          <div className="spinner-border text-warning me-2" role="status"></div>
        ),
      }));
      payload = {
        id: entry_id,
        status: type,
        booking_type: eventDetail.booking_type,
        transaction_id: transactionDetails.data.transactionId,
        transaction_date: transactionDetails.data.transactionDate,
      };
      try {
        await axios
          .post(
            `${process.env.REACT_APP_API_URL}/booking/v1/updateBookingStatus`,
            payload,
            {
              headers: {
                developement: true,
                token: jwtToken,
              },
            }
          )
          .then(() => {
            setShowModal(false);
            setToasterDetails((prev) => ({
              ...prev,
              title: "Success",
              bodyText: "Updated the status successfully",
              bg: "success",
              statusIcon: (
                <i className="bi bi-check text-success text-3xl me-2"></i>
              ),
            }));

            setTimeout(() => {
              isClubBooking === "?club_booking=true" ? navigate(`/ clubs - bookings / ${eventDetail.club_id}?club_name = ${eventDetail.club_name}& tab=club_booking_events`) :
                navigate("/bookings?tab=events");
            }, 2000);
          });

      } catch (err) {
        setToasterDetails((prev) => ({
          ...prev,
          title: "Error",
          bodyText: "Something went wrong",
          bg: "danger",
          statusIcon: (
            <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
          ),
        }));
        setTimeout(() => {
          setShowToaster(false);
        }, 2000);
      }
    }
    else {
      if (reason.error === "") {
        if (reason.reason === "") {
          setReason({ ...reason, error: "Please enter a reason" });
          return;
        }
        setshowRejectionModal(false);
        setShowToaster(true);
        setToasterDetails((prev) => ({
          ...prev,
          title: "Updating...",
          bodyText: "Please hold while we are updating the details",
          bg: "warning",
          statusIcon: (
            <div className="spinner-border text-warning me-2" role="status"></div>
          ),
        }));
        try {
          let payload = {
            id: entry_id,
            status: type,
            booking_type: "event_entry_booking",
            rejection_remark: reason.reason,
          };

          const jwtToken = localStorage.getItem("authenticate");
          await axios
            .post(
              `${process.env.REACT_APP_API_URL}/booking/v1/updateBookingStatus`,
              payload,
              {
                headers: {
                  developement: true,
                  token: jwtToken,
                },
              }
            )
            .then(() => {
              setShowModal(false);
              setToasterDetails((prev) => ({
                ...prev,
                title: "Success",
                bodyText: "Updated the status successfully",
                bg: "success",
                statusIcon: (
                  <i className="bi bi-check text-success text-3xl me-2"></i>
                ),
              }));
              setTimeout(() => {
                isClubBooking === "?club_booking=true" ? navigate(`/ clubs - bookings / ${eventDetail.club_id}?club_name = ${eventDetail.club_name}& tab=club_booking_events`) :
                  navigate("/bookings?tab=events");
              }, 2000);
            });
        } catch (err) {
          setToasterDetails((prev) => ({
            ...prev,
            title: "Error",
            bodyText: "Something went wrong",
            bg: "danger",
            statusIcon: (
              <i className="bi bi-exclamation-triangle text-danger me-2 text-2xl"></i>
            ),
          }));
          setTimeout(() => {
            setShowToaster(false);
          }, 2000);
        }
      }
    }
  }
  useEffect(() => {
    getEventDetailWithId();
    fetchCurrencySymbols();
  }, []);

  useEffect(() => {
    let obj = {
      name: userDetails.name,
      gender: userDetails.gender,
      age:
        userDetails && userDetails.dob
          ? getAge(
            moment(new Date(moment(userDetails.dob, "DD/MM/YYYY").format("YYYY/MM/DD"))).format("MM/DD/YYYY")
          )
          : "",
    };
    if (userDetails.dob) {
      setGuests((prev) => [obj, ...prev]);
    }
  }, [userDetails]);

  const userDetailsList = [
    {
      query: "Event Name",
      userResponse: eventDetail && eventDetail.event_name,
    },
    // {
    //   query: "Club Name",
    //   userResponse: eventDetail && eventDetail.club_name,
    // },
    {
      query: "Club Location",
      userResponse: eventDetail && eventDetail.club_location,
    },
    {
      query: "Booked On",
      userResponse: eventDetail && eventDetail.createdAt && moment(eventDetail.createdAt.toDate()).format("DD/MM/YYYY")
    }, {
      query: "Booked For",
      userResponse:
        eventDetail &&
        eventDetail.booking_date &&
        moment(new Date(eventDetail.booking_date.seconds * 1000).toISOString()).format("DD/MM/YYYY"),
    },
    {
      query: "Guest Count",
      userResponse: eventDetail && parseInt(eventDetail.attendee_count + 1),
    },
    // {
    //   query: "Created Loyalty Points",
    //   userResponse: eventDetail && eventDetail.credit_loyality_point && eventDetail.credit_loyality_point
    // },
    {
      query: "Checked In",
      userResponse: eventDetail && eventDetail.isCheckedIn !== undefined ? eventDetail.isCheckedIn === true || eventDetail.invoice_url ? "Yes" : "No" : undefined,
    },
    {
      query: "Status",
      userResponse: eventDetail && eventDetail.status,
    },
    {
      query: "Reason for Cancellation",
      userResponse: eventDetail && eventDetail.reason_for_cancellation,
    },
  ];
  const paymentDetails = [
    {
      query: "Transaction Date",
      userResponse:
        eventDetail &&
        eventDetail.transaction_date &&
        eventDetail.transaction_date.seconds &&
        moment(
          new Date(eventDetail.transaction_date.seconds * 1000).toISOString()
        ).format("DD MMM YYYY"),
    },
    {
      query: "Transacion ID",
      userResponse: eventDetail && eventDetail.transaction_id,
    },
    {
      query: "Choosen Payment Option",
      userResponse: eventDetail.cover_charge === "required" ? "Cover Charge" : eventDetail.cover_charge === "Card filling" ? "Card filling" : undefined
    }, {
      query: "Cover charge amount",
      userResponse: eventDetail && eventDetail.cover_charge_amount && eventDetail.cover_charge_currency ? eventDetail.cover_charge_amount + " " + eventDetail.cover_charge_currency : undefined,
    },
    {
      query: "Payment Link",
      userResponse: eventDetail && eventDetail.cover_charge_payment_link
    }
  ];

  //Validation Modal
  const validationModal = (type) => {
    if (type === "cover_charge") {
      let errors = {};
      if (bookingCharge.coverChargeAmount === 0) {
        errors.coverCharger = "Please enter a charge amount";
      }
      if (bookingCharge.paymentLink === "") {
        errors.paymentLink = "Please enter a payment link";
      }
      setBookingCharge({
        ...bookingCharge,
        errors: errors,
      });
      return Object.keys(errors).length > 0;
    } else if (type === "card_filling") {
      let errors = {};
      if (bookingCharge.cardFillingPaymentLink === "") {
        errors.cardFillingPaymentLink = "Please enter a payment link";
      }
      setBookingCharge({
        ...bookingCharge,
        errors: errors,
      });
      return Object.keys(errors).length > 0;
    }
  };

  //Handling Charge details
  const handleCoverCharge = async (type) => {
    if (type === "") {
      setSelectButtonError(true);
      return;
    }
    if (!(bookingCharge.errors.coverCharger === "" && bookingCharge.errors.paymentLink === "" && bookingCharge.errors.cardFillingPaymentLink === "")) {
      return;
    }
    let validation = validationModal(type);
    if (!validation) {
      if (type === "cover_charge") {
        setApprovalModal(false);
        setShowToaster(true);
        setToasterDetails((prev) => ({
          ...prev,
          title: "Updating...",
          bodyText: "Updating the status",
          bg: "warning",
          statusIcon: (
            <div
              className="spinner-border text-warning me-2"
              role="status"
            ></div>
          ),
        }));
        let payload = {
          id: entry_id,
          booking_type: "event_entry_booking",
          payment_link: bookingCharge.paymentLink,
          cover_charge: "required",
          cover_charge_amount: bookingCharge.coverChargeAmount,
          cover_charge_currency: currencySymbol
        };
        const jwtToken = localStorage.getItem("authenticate");
        await axios
          .post(
            `${process.env.REACT_APP_API_URL}/booking/v2/updateCoverCharges`,
            payload,
            {
              headers: {
                developement: true,
                token: jwtToken,
              },
            }
          )
          .then(() => {
            setToasterDetails((prev) => ({
              ...prev,
              title: "Success",
              bodyText: "Updated the status successfully",
              bg: "success",
              statusIcon: (
                <i className="bi bi-check text-success text-3xl me-2"></i>
              ),
            }));
            setTimeout(() => {
              window.location.reload();
            }, 2000);
            setBookingCharge({
              ...bookingCharge,
              paymentLink: "",
              coverChargeAmount: 0,
              errors: {
                ...errors,
                paymentLink: "",
                coverCharger: "",
              },
            });
          })
          .catch((err) => {
            setToasterDetails((prev) => ({
              ...prev,
              title: "Not Updated",
              bodyText: "Something went wrong",
              bg: "danger",
              statusIcon: (
                <i className="bi bi-check text-success text-3xl me-2"></i>
              ),
            }));
            setTimeout(() => {
              setShowToaster(false);
            }, 2000);
          });
      } else if (type === "card_filling") {
        setApprovalModal(false);
        setShowToaster(true);
        setToasterDetails((prev) => ({
          ...prev,
          title: "Updating...",
          bodyText: "Updating the status",
          bg: "warning",
          statusIcon: (
            <div
              className="spinner-border text-warning me-2"
              role="status"
            ></div>
          ),
        }));
        const jwtToken = localStorage.getItem("authenticate");
        let payload = {
          id: entry_id,
          booking_type: "event_entry_booking",
          payment_link: bookingCharge.cardFillingPaymentLink,
          cover_charge: "card filling required",
          cover_charge_amount: null,
        };
        await axios
          .post(
            `${process.env.REACT_APP_API_URL}/booking/v2/updateCoverCharges`,
            payload,
            {
              headers: {
                developement: true,
                token: jwtToken,
              },
            }
          )
          .then(() => {
            setToasterDetails((prev) => ({
              ...prev,
              title: "Success",
              bodyText: "Updated the status successfully",
              bg: "success",
              statusIcon: (
                <i className="bi bi-check text-success text-3xl me-2"></i>
              ),
            }));
            setTimeout(() => {
              window.location.reload();
            }, 2000);
            setBookingCharge({
              ...bookingCharge,
              paymentLink: "",
              coverChargeAmount: 0,
              errors: {
                ...errors,
                paymentLink: "",
                coverCharger: "",
              },
            });
          })
          .catch((err) => {
            setApprovalModal(false);
            setToasterDetails((prev) => ({
              ...prev,
              title: "Not Updated",
              bodyText: "Something went wrong",
              bg: "danger",
              statusIcon: (
                <i className="bi bi-check text-success text-3xl me-2"></i>
              ),
            }));
            setTimeout(() => {
              setShowToaster(false);
            }, 2000)
          });
      } else {
        setApprovalModal(false);
        setShowToaster(true);
        setToasterDetails((prev) => ({
          ...prev,
          title: "Updating...",
          bodyText: "Updating the status",
          bg: "warning",
          statusIcon: (
            <div
              className="spinner-border text-warning me-2"
              role="status"
            ></div>
          ),
        }));
        const jwtToken = localStorage.getItem("authenticate");
        let payload = {
          id: entry_id,
          booking_type: "event_entry_booking",
          cover_charge: "not required",
        };
        await axios
          .post(
            `${process.env.REACT_APP_API_URL}/booking/v2/updateCoverCharges`,
            payload,
            {
              headers: {
                developement: true,
                token: jwtToken,
              },
            }
          )
          .then(() => {
            setToasterDetails((prev) => ({
              ...prev,
              title: "Success",
              bodyText: "Updated the status successfully",
              bg: "success",
              statusIcon: (
                <i className="bi bi-check text-success text-3xl me-2"></i>
              ),
            }));
            setTimeout(() => {
              window.location.reload();
            }, 2000);
            setBookingCharge({
              ...bookingCharge,
              paymentLink: "",
              coverChargeAmount: 0,
              errors: {
                ...errors,
                paymentLink: "",
                coverCharger: "",
              },
            });
          })
          .catch((err) => {
            console.log(err, 'err');
            setApprovalModal(false);
            setToasterDetails((prev) => ({
              ...prev,
              title: "Not Updated",
              bodyText: "Something went wrong",
              bg: "danger",
              statusIcon: (
                <i className="bi bi-check text-success text-3xl me-2"></i>
              ),
            }));
            setTimeout(() => {
              setShowToaster(false);
            }, 2000)
          });
      }
    }
  };
  // const amethystMembershipFeatures = [
  //   "Exclusively for Cabin Crew",
  //   "Two free drink for 365 nights a year",
  //   "No free/free walk-in to any HTP integrated Clubs",
  //   "Access to accumulated rewards after 30 days",
  //   "No access to discover ladies’ nights",
  //   "No Invitation to exclusive celebrity events",
  //   "No priority booking for clubs & events",
  // ];


  const onSubmit = async (data) => {
    setCommentLoader(true);
    let id = uuid();
    const updateData = {
      comments: [
        {
          comment: data.message,
          createdAt: new Date(),
          user_id: user_id,
          user_name: user_email,
          id: id,
        },
        ...allComments,
      ],
    };
    try {
      const eventUpdateResponse = await updateDoc(
        doc(db, "event_entry_bookings", entry_id),
        updateData
      );
      getEventDetailWithId();
      setCommentLoader(false);
      reset();
    } catch (err) {
      setCommentLoader(false);
    }
  };

  const deleteThisComment = async (id) => {
    let removedData = allComments;
    removedData = allComments.filter((data) => data.id !== id);
    const updateData = {
      comments: [...removedData],
    };
    try {
      const eventUpdateResponse = await updateDoc(
        doc(db, "event_entry_bookings", entry_id),
        updateData
      );
      getEventDetailWithId();
      reset();
    } catch (err) {
    }
  };

  const validateField = (value) => {
    if (value.trim() === "") {
      return "This field should not be empty.";
    }
    if (/^\s+$/.test(value)) {
      return "This field should not contain only whitespace characters.";
    }
    return true;
  };

  // radio selection
  const handleSelectionChange = (e) => {
    setSelection(e.target.value);
    setSelectButtonError(false)
    setBookingCharge({
      ...bookingCharge,
      coverChargeAmount: 0,
      paymentLink: "",
      cardFillingPaymentLink: "",
      errors: {
        ...errors,
        coverCharger: "",
        paymentLink: "",
        cardFillingPaymentLink: "",
      },
    });
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {paymentLinkCopied ? "Copied" : "Copy to clipboard"}
    </Tooltip>
  );

  return (
    <Layout path="/bookings/event-details">
      <ContentWrapper additionalClass="overflow-hidden p-5 font-body">
        {/* <h2 className="color-heading font-body font-semibold text-lg text-lg-xl pb-6 mb-0">
          Event Entry Details
        </h2> */}
        <Col xl={6}>
          <div className="d-flex align-items-center border-bottom pb-1">
            <div style={{ width: "50%" }}>
              <h2 className="color-heading font-body font-semibold text-lg text-lg-xl mb-0">
                Event Entry Booking Id
              </h2>
            </div>
            <div className="w-50 ps-3">
              <span className="d-inline-bolck color-burgandi text-lg-xl font-semibold">
                : {eventDetail.booking_id}
              </span>
            </div>
          </div>
          <div className="d-flex align-items-center pt-1">
            <div style={{ width: "50%" }}>
              <h2 className="color-heading font-body font-semibold text-lg text-lg-xl mb-0">
                Club Name
              </h2>
            </div>
            <div className="w-50 ps-3">
              <Link
                className="d-inline-bolck color-burgandi text-lg-xl font-semibold"
                to={`/clubs/club-details/${eventDetail.club_id}`} target="_blank" >: {eventDetail.club_name}</Link>
            </div>
          </div>
          {eventDetail && eventDetail.event_display_image ? <figure>
            <img
              className="img-fluid mt-3 rounded-circle"
              src={eventDetail.event_display_image}
              style={{ width: "200px", height: "200px", objectFit: "cover", objectPosition: "center" }}
              alt="club displayImage"
            />
          </figure> : <Skeleton
            inline={true} count={1}
            height={200} width={200}
            baseColor="#dedede" highlightColor="#cccccc"
            duration={2} circle
          />}
        </Col>
        <Row className="g-4">
          {userDetailsList &&
            userDetailsList.map((item, index) => {
              if (item.userResponse) {
                return (
                  <Col xs={6} lg={3} key={index}>
                    <article>
                      <h2 className="color-lightestblue font-normal text-sm mb-0 font-body">
                        {item.query}
                      </h2>
                      {item.query === "Status" ? (
                        <div
                          style={{
                            backgroundColor: `${item.userResponse === "Approved"
                              ? "#13DEB9"
                              : item.userResponse === "Pending"
                                ? "#FFAE1F"
                                : item.userResponse === "Rejected"
                                  ? "#FA896B"
                                  : item.userResponse === "Completed"
                                    ? "#13DEB9"
                                    : item.userResponse === "Waiting for payment verification"
                                      ? "#fc9403"
                                      : item.userResponse === "Cancelled"
                                        ? "rgb(250, 137, 107)" : null
                              } `,
                          }}
                          className="rounded d-inline-block px-3 py-2 text-white font-semibold text-xs mb-0 font-body text-wrap text-break"
                        >
                          {item.userResponse === "Waiting for approval"
                            ? "Pending"
                            : item.userResponse}
                        </div>
                      ) : item.query.toLowerCase() === "event name" ?
                        <Link to={`/ events / edit - event / ${eventDetail.event_id} `} target="_blank" className="color-lightblue font-semibold text-md text-lg-lg mb-0 font-body text-wrap text-break">{item.userResponse}</Link> :
                        <p className="color-lightblue font-semibold text-md text-lg-lg mb-0 font-body text-wrap text-break">
                          {item.userResponse}
                        </p>}
                    </article>
                  </Col>
                );
              } else return ""
            })
          }
          <Col xs={12}>
            <div className="text-end">
              {(status === "Pending" ||
                status === "Booking request pending") && (
                  <div>
                    <OutlinedButton
                      onClick={() => {
                        setshowRejectionModal(true);
                      }}
                      name="Reject"
                      additionalClass="me-4"
                    />

                    <SuccessButton
                      onClick={() => {
                        setApprovalModal(true);
                      }}
                      name="Accept"
                      icon="bi-check-circle"
                      additionalClass="px-5"
                    />
                  </div>
                )}

              {/* <div> */}
              {status === "Waiting for payment verification" && (
                <div>
                  <OutlinedButton
                    onClick={() => {
                      setshowRejectionModal(true);
                    }}
                    name="Reject Booking"
                    additionalClass="me-4"
                  />

                  <SuccessButton
                    onClick={() => {
                      setBookingCompletionModal(true);
                    }}
                    name="Approve Booking"
                    icon="bi-check-circle"
                    additionalClass="px-5"
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </ContentWrapper>

      <ContentWrapper additionalClass="p-5 mt-5">
        <h5 className="color-heading font-body font-semibold text-lg text-lg-xl">
          User Details
        </h5>
        <Row className="g-4">
          {userDetails && userDetails.name && <Col xs={6} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              Full Name
            </p>
            <div className="d-flex align-items-center">
              <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                {userDetails.name}
              </p>
            </div>
          </Col>}
          {userDetails && userDetails.email && <Col xs={6} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              Email
            </p>
            <div className="d-flex align-items-center">
              <p className="color-lightblue po-word-break font-semibold text-md text-lg-md mb-0 font-body">
                {userDetails.email}
              </p>
            </div>
          </Col>}
          {userDetails && userDetails.contacts && userDetails.contacts.length ? (
            <Col xs={6} xl={3}>
              <p className="color-lightestblue font-normal text-sm mb-0 font-body">
                Phone Number
              </p>
              <div className="d-flex align-items-center">
                <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                  {userDetails.contacts.find(item => item.mode === "phone") ? userDetails.contacts.find(item => item.mode === "phone").contact_no : "Not available"}
                </p>
              </div>
            </Col>
          ) : ""}
          {userDetails && userDetails.contacts && userDetails.contacts.length ? (
            <Col xs={6} xl={3}>
              <p className="color-lightestblue font-normal text-sm mb-0 font-body">
                WhatsApp Number
              </p>
              <div className="d-flex align-items-center">
                <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                  {userDetails.contacts.find(item => item.mode === "whatsapp") ? userDetails.contacts.find(item => item.mode === "whatsapp").contact_no : "Not available"}
                </p>
              </div>
            </Col>
          ) : ""}
          {userDetails && userDetails.dob && <Col xs={6} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              Date Of Birth
            </p>
            <div className="d-flex align-items-center">
              {/* {item.icon && <img className="po-userDetails__icon mr-2" src={item.icon} alt="User Response Icons" />} */}
              <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                {userDetails.dob}
              </p>
            </div>
          </Col>}

          {userDetails && userDetails.gender && <Col xs={6} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              Gender
            </p>
            <div className="d-flex align-items-center">
              {/* {item.icon && <img className="po-userDetails__icon mr-2" src={item.icon} alt="User Response Icons" />} */}
              <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                {userDetails.gender}
              </p>
            </div>
          </Col>}
          {userDetails && userDetails.home_country_name && <Col xs={6} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              Home Country
            </p>
            <div className="d-flex align-items-center">
              {/* {item.icon && <img className="po-userDetails__icon mr-2" src={item.icon} alt="User Response Icons" />} */}
              <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                {userDetails.home_country_name}
              </p>
            </div>
          </Col>}
          {userDetails && userDetails.home_city_name && <Col xs={6} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              Home City
            </p>
            <div className="d-flex align-items-center">
              {/* {item.icon && <img className="po-userDetails__icon mr-2" src={item.icon} alt="User Response Icons" />} */}
              <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                {userDetails.home_city_name}
              </p>
            </div>
          </Col>}
          {userDetails && userDetails.active_membership_name && <Col xs={6} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              Active Membership
            </p>
            <div className="d-flex align-items-center">
              {userDetails && (
                <div
                  style={{
                    backgroundColor: `${userDetails.active_membership_name === "Amethyst"
                      ? "#502d68"
                      : userDetails.active_membership_name === "Solitaire"
                        ? "#213d61"
                        : userDetails.active_membership_name === "Platinum"
                          ? "#4a4e57"
                          : userDetails.active_membership_name === "Gold"
                            ? "#bba15c"
                            : userDetails.active_membership_name === "Silver"
                              ? "#87888c"
                              : null
                      } `,
                  }}
                  className="rounded d-inline-block px-3 text-white"
                >
                  {userDetails.active_membership_name}
                </div>
              )}
            </div>
          </Col>}
          {favoriteClubs && favoriteClubs.length > 0 &&
            <Col xs={6} xl={3}>
              <p className="color-lightestblue font-normal text-sm mb-0 font-body">
                Favorite Clubs
              </p>
              <div className="d-flex align-items-center">
                <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                  {favoriteClubs && favoriteClubs.length > 0 && favoriteClubs.map((ele, index) => {
                    return (
                      <span className="d-block" key={index}>
                        {index + 1} . {ele}
                      </span>
                    )
                  })}
                </p>
              </div>
            </Col>
          }
          <Col xs={6} xl={3}>
            <div>
              <p className="color-lightestblue font-normal text-sm mb-0 font-body">
                User Status
              </p>
              <div className="d-flex align-items-center">
                <p className="color-lightblue font-semibold text-md text-lg-md mb-0 me-2 font-body">
                  Active
                </p>
                {userDetails.status === "Active" ? (
                  <input type="checkbox" className="po-checkbox" checked />
                ) : (
                  <input type="checkbox" className="po-checkbox" />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </ContentWrapper>
      {/* Payment Details */}
      {paymentDetails.filter(item => item.userResponse).length ? <ContentWrapper additionalClass="p-5 mt-5">
        <h2 className="color-heading font-body font-semibold text-lg text-lg-xl">Payment Details</h2>
        <Row className="g-4">
          {paymentDetails && paymentDetails.map((item, index) => {
            if (item.userResponse) {
              return (
                <Col xs={item.query === "Payment Link" ? 12 : 6} lg={item.query === "Payment Link" ? 12 : 3} key={index}>
                  <div className="color-lightestblue d-flex align-items-center gap-1 font-normal text-sm mb-0 font-body">
                    {item.query} {" "}
                    {item.query === "Payment Link" ?
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                      >
                        <button className="bg-transparent border-0 text-xs"
                          onClick={handleCopyPaymentLink}
                          type="button"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={paymentLinkCopied ? "#009B38" : "#000"} className="bi bi-copy" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V2Zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6ZM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1H2Z" />
                          </svg>
                        </button>
                      </OverlayTrigger> : ""}
                  </div>
                  {item.query === "Payment Link" ?
                    <a
                      href={item.userResponse}
                      target="_blank"
                      rel="noreferrer"
                      ref={textAreaRef}
                      className="color-lightblue font-semibold text-sm mb-0 font-body text-wrap text-break text-decoration-underline"
                    >{item.userResponse}</a>
                    :
                    <p
                      className="color-lightblue font-semibold text-sm mb-0 font-body text-wrap text-break"
                    >
                      {item.userResponse}
                    </p>
                  }
                </Col>
              )
            } else return "";
          })}
        </Row>
      </ContentWrapper> : ""}
      <ContentWrapper additionalClass="mt-5 p-5">
        <Row>
          <Col xs={12}>
            <h2 className="color-heading font-body font-semibold text-lg text-lg-xl ">
              {("Membership Details").split(" ").map(item => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()).join(" ")}
            </h2>
          </Col>
          <Col xs={12} lg={6} className="pt-2">
            <article>
              <Modal show={showMember} onHide={memberHandleClose} size="lg" >
                <Modal.Header className="background-appColor p-8 font-body">
                  <Modal.Title className="d-flex justify-content-between align-items-center w-100"><h2 className="font-body mb-0 ps-2 pt-2 pb-1  text-2xl  color-ternary font-semibold ">
                    {membershipDetails.name} Membership
                  </h2>
                    <i className="bi bi-x-circle-fill text-2xl color-ternary cursor-pointer" onClick={memberHandleClose}></i>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="background-appColor p-8 font-body">
                  <ul className="font-body mb-0 ps-2 pl-5  pb-2">
                    {membershipDetails.benifits && membershipDetails.benifits.map((item, index) => {
                      return <li className="text-sm font-normal mb-0 mt-2 gold-bullets text-light" style={{ opacity: "0.8" }} key={index}>{item.description}</li>;
                    })}
                  </ul>
                </Modal.Body>
                <Modal.Footer className="background-appColor p-8 font-body">
                </Modal.Footer>
              </Modal>

              <div className="d-flex align-items-center mb-2">
                <i className="bi bi-info-circle-fill text-xl color-yellow1 me-2 cursor-pointer" onClick={memberHandleShow}></i>
                <p className="color-lightblue font-semibold text-md text-lg-lg mb-0 font-body">{membershipDetails.name}</p>
              </div>
              {membershipDetails && membershipDetails.display_image ? <article className={`membership-card background-position-center background-repeat-norepeat background-size-cover overflow-hidden  py-4 px-5 d-flex flex-column justify-content-between ${membershipDetails.name === "Gold" ? "text-dark" : membershipDetails.name === "Silver" ? "text-dark" : membershipDetails.name === "Amethyst" ? "color-ternary" : "text-light"} `} style={{
                backgroundImage: `url(${membershipDetails.display_image})`, height: '280px', width: '450px', borderRadius: "30px"
              }}>
                <h2 className="text-2xl mt-5"> {kycDetails && kycDetails.name}</h2>
                <div>
                  <h4 className="text-md">{splitString(bookingUserDetails && bookingUserDetails.active_membership_card_no)}</h4>
                  <p className="text-lg mb-0">{membershipDetails && membershipDetails.name}</p>
                  {
                    bookingUserDetails.mebership_starts_at && <p className="mb-1 text-xxs">Member since {bookingUserDetails.mebership_starts_at ? moment(new Date(bookingUserDetails.mebership_starts_at && bookingUserDetails.mebership_starts_at.seconds * 1000).toISOString()).format("DD MMM YYYY") : ""}</p>
                  }
                  {
                    bookingUserDetails.mebership_ends_at && <p className="mb-1 text-xxs font-bold">Valid till {bookingUserDetails.mebership_ends_at ? moment(new Date(bookingUserDetails.mebership_ends_at && bookingUserDetails.mebership_ends_at.seconds * 1000).toISOString()).format("DD MMM YYYY") : ""}</p>
                  }

                </div>
              </article> : <Skeleton
                inline={true} count={1}
                height={280} width={450}
                baseColor="#dedede" highlightColor="#cccccc"
                duration={2} rectangle
              />}
            </article>
          </Col>
        </Row>
      </ContentWrapper>
      {/* Kyc Details */}
      <ContentWrapper additionalClass="p-5 mt-5">
        <h2 className="color-heading font-body font-semibold text-lg text-lg-xl">
          {("Membership Form Details").split(" ").map(item => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()).join(" ")}
        </h2>
        <Row className="g-4">
          {/* name */}
          <Col xs={6} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              User photo
            </p>
            {kycDetails && kycDetails.user_image ? <div className="d-flex align-items-center">
              <Zoom>
                <img
                  className="rounded"
                  src={kycDetails.user_image}
                  width={80}
                  height={60}
                  alt="user profile"
                />
              </Zoom>
            </div> : <Skeleton
              inline={true} count={1}
              height={60} width={80}
              baseColor="#dedede" highlightColor="#cccccc"
              duration={2} rectangle
            />}
          </Col>
          <Col xs={6} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              Name
            </p>
            <div className="d-flex align-items-center">
              <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                {kycDetails && kycDetails.name}
              </p>
            </div>
          </Col>
          {/* nationality */}
          <Col xs={6} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              Nationality
            </p>
            <div className="d-flex align-items-center">
              <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                {kycDetails && kycDetails.nationalty}
              </p>
            </div>
          </Col>
          {/* residency */}
          {/* <Col xs={6} xl={3}>
          <p className="color-lightestblue font-normal text-sm mb-0 font-body">
            Residency
          </p>
          <div className="d-flex align-items-center">
            <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
              {kycDetails && kycDetails.residency}
            </p>
          </div>
        </Col> */}
          {/* frequency clubing */}
          <Col xs={6} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              Frequency Clubbing
            </p>
            <div className="d-flex align-items-center">
              <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                {kycDetails && kycDetails.frequency_of_clubbing}
              </p>
            </div>
          </Col>
          {/* country */}
          <Col xs={6} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              Home Country
            </p>
            <div className="d-flex align-items-center">
              <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                {kycDetails && kycDetails.home_country_name}
              </p>
            </div>
          </Col>
          {/* state */}
          <Col xs={6} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              Home State
            </p>
            <div className="d-flex align-items-center">
              <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                {kycDetails && kycDetails.home_state_name}
              </p>
            </div>
          </Col>
          {/* city */}
          <Col xs={6} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              Home City
            </p>
            <div className="d-flex align-items-center">
              <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                {kycDetails && kycDetails.home_city_name}
              </p>
            </div>
          </Col>
          {/* status */}

          <Col xs={6} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              Mailing Address
            </p>
            <div className="d-flex align-items-center">
              <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                {kycDetails && kycDetails.permanent_address}
              </p>
            </div>
          </Col>
          <Col xs={6} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              Applied On
            </p>
            <div className="d-flex align-items-center">
              <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                {kycDetails &&
                  kycDetails.createdAt &&
                  moment(kycDetails.createdAt.seconds * 1000).format(
                    "DD MMM YYYY"
                  )}
              </p>
            </div>
          </Col>

          <Col xs={6} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              KYC Status
            </p>
            <div className="d-flex align-items-center">
              <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                {kycDetails && kycDetails.status}
              </p>
            </div>
          </Col>
          <Col xs={6} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              Current Membership Start Date
            </p>
            <div className="d-flex align-items-center">
              <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                {userDetails &&
                  userDetails.mebership_starts_at &&
                  moment(
                    new Date(userDetails.mebership_starts_at.seconds * 1000).toISOString()
                  ).format("DD MMM YYYY")}
              </p>
            </div>
          </Col>
          <Col xs={6} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              Current Membership End Date
            </p>
            <div className="d-flex align-items-center">
              <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                {userDetails &&
                  userDetails.mebership_ends_at &&
                  moment(
                    new Date(userDetails.mebership_ends_at.seconds * 1000).toISOString()
                  ).format("DD MMM YYYY")}
              </p>
            </div>
          </Col>
          {/* <Col xs={6} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              Current Membership Request Status
            </p>
            <div className="d-flex align-items-center">
              <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                {membershipDetails.status ?

                  (membershipDetails.status === "Approved" ?
                    <p className="text-success bold">
                      {membershipDetails.status}
                    </p> :
                    membershipDetails.status === "Pending" ?
                      <p className="text-warning bold">
                        {membershipDetails.status}
                      </p> :
                      membershipDetails.status === "Cancelled" ?
                        <p className="text-danger bold">
                          {membershipDetails.status}
                        </p> : null)
                  :
                  <p className="color-lightblue font-semibold text-md text-lg-lg mb-0 font-body text-wrap text-break">
                    {membershipDetails.status}
                  </p>
                }
              </p>
            </div>
          </Col> */}
          <Col xs={6} xl={3}>
            <p className="color-lightestblue font-normal text-sm mb-0 font-body">
              Membership Requested date
            </p>
            <div className="d-flex align-items-center">
              <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                {membershipDetails && moment(membershipDetails && membershipDetails.createdAt && membershipDetails.createdAt.seconds * 1000).format("DD MMM YYYY")}
              </p>
            </div>
          </Col>
        </Row>
      </ContentWrapper>
      {/* Cancellation reason */}
      {eventDetail && eventDetail.rejection_remark &&
        <ContentWrapper additionalClass="mt-5 p-5">
          <h6>Reason for cancellation</h6>
          <p>{eventDetail.rejection_remark}</p>
        </ContentWrapper>}
      {/* Cancellation reason */}
      <ContentWrapper additionalClass="p-5 mt-5">
        <Col xd={12}>
          {guests.length > 0 ? (
            <>
              <p className="color-heading font-body font-semibold text-lg text-lg-xl">
                Guest Lists
              </p>
              <Table bordered responsive hover>
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">Gender</th>
                    <th scope="col">Age</th>
                  </tr>
                </thead>
                <tbody>
                  {guests &&
                    guests.map((guest, index) => {
                      return (
                        <tr key={index}>
                          <td className="align-middle">{index + 1}</td>
                          <td className="align-middle">
                            {/* {guest.name} */}
                            {guest.name !== "-" ? guest.name + (index === 0 ? " (Host)" : "") : `Guest ${index} `}
                          </td>
                          <td className="align-middle">{guest.gender}</td>
                          <td className="align-middle">{guest.age}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </>
          ) : (
            <p className="color-lightblue font-semibold text-md text-lg-lg mb-0 font-body text-danger">
              No Guests Are There
            </p>
          )}
        </Col>
      </ContentWrapper>

      {/* Upload Invoice  */}
      {((eventDetail.status === "Approved") || ((eventDetail.isCheckedIn === true) && !eventDetail.invoice_url)) && (
        <ContentWrapper additionalClass="p-5 mt-4">
          <h2 className="color-heading font-body font-semibold text-lg text-lg-xl">Upload Invoice</h2>
          {/* Render content if the entry is "Approved" */}
          {((eventDetail.status === "Approved") || (eventDetail.isCheckedIn === true)) &&
            /* Check if the invoice URL exists */
            (eventDetail.invoice_url ? (
              /* If invoice URL exists, show the image */
              <div>
                <a
                  href={eventDetail.invoice_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  Click To View Invoice
                </a>
              </div>
            ) : (
              /* If invoice URL does not exist, show the file input for uploading */
              <div className="mb-3">
                <p className="color-lightestblue font-normal text-sm mb-0 font-body p-1">
                  Click on the button to upload <span className="text-xs text-danger">(Acceptable file types: PDF, JPEG and PNG)</span>
                </p>
                <div className="w-100">
                  <input
                    className="form-control shadow-none"
                    type="file"
                    accept="application/pdf, image/jpeg, image/png"
                    onChange={invoiceHandler}
                  />
                  {invoice.error.file && (
                    <p className="text-danger mt-1">{invoice.error.file}</p>
                  )}
                  <div className="d-flex align-items-center">

                  </div>
                </div>
              </div>
            ))}
          <label className="color-lightestblue font-normal text-sm mb-0 font-body p-1">
            Enter Amount
          </label>
          <div className="row g-3">
            <div className="col-3 col-xl-2">
              {/* Currency  */}
              <div >
                <select
                  className="po-edit-input text-sm"
                  style={{ height: "40px" }}
                  onChange={(e) => {
                    let symbolValue = e.target.value;
                    setCurrencySymbol(e.target.value)
                    setInvoice({
                      ...invoice,
                      loyaltyPoint: +((invoice.amount / currenciesRateInUSD[symbolValue]) * 7 / 100).toFixed(2),
                      error: {
                        ...invoice.error, loyalityPoint: symbolValue === "" ? "Please select currency" : ""
                      }
                    })
                  }}>
                  <option defaultChecked={true} value="SGD">Singapore | $</option>
                  {countryCurrencyInfo && countryCurrencyInfo.length > 0 &&
                    countryCurrencyInfo.sort((a, b) => a.Country > b.Country ? 1 : -1).map((ele, index) => {
                      if (ele.currency_code !== "SGD") {
                        return (
                          <option key={index} value={ele.currency_code}>{ele.Country} | {ele.Symbol}</option>
                        )
                      } else return ""
                    })
                  }
                </select>
                {invoice.error.loyalityPoint && (
                  <p className="text-danger">{invoice.error.loyalityPoint}</p>
                )}
              </div>
            </div>
            <div className="col-9 col-xl-10">
              {/* Invoice amount */}
              <div>
                <input
                  type="Number"
                  className="po-edit-input"
                  placeholder="Enter amount (minimum 10)"
                  style={{ height: "40px" }}
                  min="10"
                  onChange={(e) => {
                    let value = +e.target.value;
                    setInvoice({
                      ...invoice,
                      error: {
                        ...invoice.error,
                        amount: (value / currenciesRateInUSD[currencySymbol]).toFixed(2) < 10 ? "Please enter an amount more than or equal to 10 USD" : "",
                      },
                      loyaltyPoint: ((value / currenciesRateInUSD[currencySymbol]) * 7 / 100).toFixed(2),
                      amount: value,
                    });
                  }}
                />
                {invoice.error.amount && (
                  <p className="text-danger mt-1">{invoice.error.amount}</p>
                )}
              </div>
            </div>
          </div>
          <div className={`d-flex justify-content-between ${invoice.error.amount ? "" : " mt-4"} `}>
            <p className="bold mb-0">
              Amount In USD : {(invoice.amount / (currenciesRateInUSD[currencySymbol])).toFixed(2) + " $"}
            </p>
            <p className="semibold text-sm mb-0">
              1 USD : {currenciesRateInUSD[currencySymbol].toFixed(2) + " " + currencySymbol}
            </p>
          </div>
          <div className="d-flex justify-content-between mt-2">
            <p className="bold">
              Loyalty Points : {parseInt(invoice.loyaltyPoint)}
            </p>
            {invoice.loyaltyPoint !== 0 && (
              <SecondaryButton
                type="submit"
                name="Submit"
                onClick={invoiceSubmit}
                additionalClass="mt-3"
              />
            )}
          </div>
          <i className="bi bi-info-circle-fill text-md color-yellow1 me-2">
            <span className=" text-sm ml-1 text-dark">
              Upload customer invoice to calculate loyalty points
            </span>
          </i>
        </ContentWrapper>
      )}
      {/* Upload Invoice  */}

      {/* Showing Invoice */}
      {((eventDetail.status === "Completed") && (eventDetail.credit_loyality_point)) &&
        <ContentWrapper additionalClass="mt-5 p-5">
          <div className="d-flex justify-content-between">
            <div>
              <h6>Booking Invoice</h6>
              {/* <p className="text-warning">Click to view above invoice</p> */}
              <a href={eventDetail.invoice_url} target="_blank" rel="noreferrer">
                Click To View Invoice
              </a>
            </div>
            <div>
              <h6>Amount Paid</h6>
              {eventDetail && eventDetail.credit_loyality_point && (
                <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                  {eventDetail.loyality_point_amount}
                </p>
              )}
            </div>
            <div>
              <h6>Loyality Points</h6>
              {eventDetail && eventDetail.credit_loyality_point && (
                <p className="color-lightblue font-semibold text-md text-lg-md mb-0 font-body">
                  {eventDetail.credit_loyality_point}
                </p>
              )}
            </div>
          </div>
        </ContentWrapper>
      }
      {/* Showing Invoice */}

      {/* Booking Cancellation*/}
      {status === "Approved" && (
        <ContentWrapper additionalClass="p-5 mt-5">
          <div className="d-flex justify-content-between">
            <h2 className="color-heading font-body font-semibold text-lg text-lg-xl">
              Booking Cancellation
            </h2>
            {/* <Button variant="warning" className="m-3" onClick={(e) => setEditable({ ...editable, status: true, className: "po-edit-input" })}>Edit Cancellation</Button> */}
          </div>
          <Row className="g-3 mt-1 ">
            <Col xs={6}>
              <div className="">
                <label className="color-lightestblue font-normal text-sm mb-0 font-body">
                  Reason For Cancellation
                </label>
                <textarea
                  className="po-edit-input"
                  style={{ height: "90px" }}
                  placeholder="Please enter the reason..."
                  onChange={(e) => {
                    let { value } = e.target;
                    setCancelBookingErrors({
                      ...cancelBookingErrors,
                      reason: value === "" ? "Field should not be empty" : value.trim().length === 0 ? "Whitespace not allowed" : "",
                    });
                    setBookingCancellationDetails({
                      ...bookingCancellationDetails,
                      reason: value,
                    });
                  }}
                ></textarea>
                {cancelBookingErrors.reason && (
                  <p className="text-danger">{cancelBookingErrors.reason}</p>
                )}
              </div>
            </Col>
            <Col xs={6}>
              <div className="">
                <label className="color-lightestblue font-normal text-sm mb-0 font-body">
                  Transaction Id
                </label>
                <input
                  type="text"
                  className="po-edit-input"
                  placeholder="Please enter transaction id..."
                  onChange={(e) => {
                    // const regexPattern = /^\s*$/;
                    let { value } = e.target;
                    // let isValid = regexPattern.test(value);
                    // setCancelBookingErrors({
                    //   ...cancelBookingErrors,
                    //   trasactionId: value === "" ? "Field should not be empty" : value.trim().length === 0 ? "Whitespace not allowed" : "",
                    // });
                    setBookingCancellationDetails({
                      ...bookingCancellationDetails,
                      trasactionId: value,
                    });
                  }}
                />
                {/* {cancelBookingErrors.trasactionId && (
                  <p className="text-danger">
                    {cancelBookingErrors.trasactionId}
                  </p>
                )} */}
              </div>
            </Col>
            <Col xs={6}>
              <div className="">
                <label className="color-lightestblue font-normal text-sm mb-0 font-body">
                  Cancellation Date
                </label>
                <input
                  min={eventDetail &&
                    eventDetail.createdAt &&
                    moment(eventDetail.createdAt.toDate()).format("YYYY-MM-DD")}
                  value={bookingCancellationDetails.trasactionDate}
                  type="date"
                  className="po-edit-input"
                  max={eventDetail &&
                    eventDetail.booking_date &&
                    moment(eventDetail.booking_date.toDate()).subtract(1, 'days').format("YYYY-MM-DD")}
                  onChange={(e) => {
                    const regexPattern = /^\s*$/;
                    let { value } = e.target;
                    const minDate = eventDetail &&
                      eventDetail.createdAt &&
                      moment(eventDetail.createdAt.toDate()).format("YYYY-MM-DD");
                    const maxDate = eventDetail &&
                      eventDetail.booking_date &&
                      moment(eventDetail.booking_date.toDate()).subtract(1, 'days').format("YYYY-MM-DD");

                    // Validate if the entered date is within the allowed range
                    if (value < minDate) {
                      value = minDate; // Set the date to the minimum allowed value
                    } else if (value > maxDate) {
                      value = maxDate; // Set the date to the maximum allowed value
                    }

                    setCancelBookingErrors({
                      ...cancelBookingErrors,
                      transactionDate: value ? "" : "Field should not be empty",
                    });
                    setBookingCancellationDetails({
                      ...bookingCancellationDetails,
                      trasactionDate: value,
                    });
                  }}
                />
                {cancelBookingErrors.transactionDate && (
                  <p className="text-danger">
                    {cancelBookingErrors.transactionDate}
                  </p>
                )}

              </div>
            </Col>
            {/* <Col xs={6}>
              <div className="">
                <label className="color-lightestblue font-normal text-sm mb-0 font-body">
                  Transaction Amount
                </label>
                <input
                  min="0"
                  type="number"
                  className="po-edit-input"
                  placeholder="Please enter amount..."
                  onChange={(e) => {
                    const regexPattern = /^\s*$/;
                    let { value } = e.target;
                    setCancelBookingErrors({
                      ...cancelBookingErrors,
                      transactionAmount: value
                        ? ""
                        : "Field should not be empty",
                    });

                    setBookingCancellationDetails({
                      ...bookingCancellationDetails,
                      transactionAmount: value,
                    });
                  }}
                />
                {cancelBookingErrors.transactionAmount && (
                  <p className="text-danger">
                    {cancelBookingErrors.transactionAmount}
                  </p>
                )}
              </div>
            </Col> */}
            <Col xs={6}>
              <div className="">
                <label className="color-lightestblue font-normal text-sm mb-0 font-body">
                  Cancellation Charge
                </label>
                <input
                  min="0"
                  type="number"
                  className="po-edit-input"
                  placeholder="Please enter cancellation charge..."
                  onChange={(e) => {
                    // const regexPattern = /^\s*$/;
                    let { value } = e.target;
                    // setCancelBookingErrors({
                    //   ...cancelBookingErrors,
                    //   cancellationCharge: value
                    //     ? ""
                    //     : "Field should not be empty",
                    // });

                    setBookingCancellationDetails({
                      ...bookingCancellationDetails,
                      cancellationCharge: value,
                    });
                  }}
                />
                {/* {cancelBookingErrors.cancellationCharge && (
                  <p className="text-danger">
                    {cancelBookingErrors.cancellationCharge}
                  </p>
                )} */}
              </div>
            </Col>
          </Row>
          <div className="d-flex justify-content-between align-items-center">
            <div className="mt-4">
              <i className="bi bi-info-circle-fill text-md color-yellow1 me-2"><span className=" text-sm ml-1 text-dark">This section is for Booking Cancellation</span></i>
            </div>
            <div>
              <SecondaryButton
                type="submit"
                name="Cancel Booking"
                onClick={handleCancelBooking}
                additionalClass="mt-3"
              />
            </div>

          </div>
        </ContentWrapper>
      )}
      {/* Booking Cancellation*/}
      {/* Multiple Emails Sending  */}
      {status === "Approved" && (
        <ContentWrapper additionalClass="p-5 mt-5">
          <div>
            <h2 className="color-heading font-body font-semibold text-lg text-lg-xl ">
              {("Send Booking Details").split(" ").map(item => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()).join(" ")}
            </h2>
            {/* <p className="m-0 p-0 text-info text-sm" >Please press enter key to add</p> */}
            <div className="d-flex align-items-end justify-content-between gap-5">
              <div className="w-100">
                <label className="color-lightestblue font-normal text-sm mb-0 font-body">
                  Enter Email Addresses
                </label>
                <input
                  type="email"
                  className="po-edit-input"
                  placeholder="Press enter to add email..."
                  value={multipleEmails.email}
                  onChange={(e) => {
                    var regex = /^.+\s.+$/g;
                    if (regex.test(e.target.value)) {
                      setMultipleEmails((prev) => ({
                        ...prev,
                        error: "Please enter valid email address",
                      }));
                    } else {
                      setMultipleEmails((prev) => ({
                        ...prev,
                        error: "",
                      }));
                    }
                    setMultipleEmails((prev) => ({
                      ...prev,
                      email: e.target.value,
                    }));
                  }}
                // onKeyDown={multipleEmailHandler}
                />
              </div>
              <SecondaryButton
                name="Add email"
                onClick={multipleEmailHandler}
                additionalClass="mt-3 flex-shrink-0"
              />
            </div>
          </div>
          {multipleEmails.error && (
            <p className="text-danger m-0">{multipleEmails.error}</p>
          )}
          <div className="d-flex align-items-center"></div>
          <div className="d-flex flex-wrap">
            {multipleEmails.emails &&
              multipleEmails.emails.length > 0 &&
              multipleEmails.emails.map((email, index) => {
                return (
                  <div className="p-2 my-2 me-2 border border-light rounded bg-light text-md semibold" key={index}>
                    {email}{" "}
                    <ActionIcon
                      onClick={() => {
                        let prevMultipleEmails = multipleEmails.emails;
                        prevMultipleEmails.splice(index, 1);
                        setMultipleEmails({
                          ...multipleEmails,
                          emails: [...prevMultipleEmails],
                        });
                      }}
                      additionalClass="bi bi-x-circle-fill text-1xl color-red me-2"
                    />
                  </div>
                );
              })}
          </div>
          {multipleEmails.emails.length > 0 && <div className="d-flex justify-content-end">
            <SecondaryButton
              type="submit"
              name="Send email"
              onClick={multipleEmailOnSubmit}
              additionalClass="mt-3"
            />
          </div>}
          <div className="mt-1">
            <i className="bi bi-info-circle-fill text-md color-yellow1 me-2">
              <span className=" text-sm ml-1 text-dark">
                Send booking details for multiple emails
              </span>
            </i>
          </div>
        </ContentWrapper>
      )}
      {/* VIP Manger : Start*/}
      <ContentWrapper additionalClass="p-5 font-body mt-5 mb-4">
        <Row>
          <Col xs={12}>
            <Row className="g-3">
              <Col xs={12}>
                {" "}
                <h2 className="color-heading font-body font-semibold text-lg text-lg-xl">
                  Club VIP Manager
                </h2>
              </Col>
              <Col xs={6}>
                <article>
                  <h6 className="text-md font-semibold color-gray mb-2">
                    Name
                  </h6>
                  {clubDetails && clubDetails.club_vip_manager && (
                    <p className="text-capitalize text-md mb-0">
                      {clubDetails.club_vip_manager.name}
                    </p>
                  )}
                </article>
              </Col>
              <Col xs={6}>
                <article>
                  <h6 className="text-md font-semibold color-gray mb-2">
                    Email
                  </h6>
                  {clubDetails && clubDetails.club_vip_manager && (
                    <a
                      href={`mailto:${clubDetails.club_vip_manager.email} `}
                      className="text-capitalize text-md text-dark"
                    >
                      {clubDetails.club_vip_manager.email}
                    </a>
                  )}
                </article>
              </Col>
              <Col xs={6}>
                <article>
                  <h6 className="text-md font-semibold color-gray mb-2">
                    Phone
                  </h6>
                  {clubDetails && clubDetails.club_vip_manager && (
                    <a
                      href={`tel:${clubDetails.club_vip_manager.contact_no} `}
                      className="text-capitalize text-md text-dark"
                    >
                      {clubDetails.club_vip_manager.contact_no}
                    </a>
                  )}
                </article>
              </Col>
              <Col xs={6}>
                <article>
                  <h6 className="text-md font-semibold color-gray mb-2">
                    WhatsApp
                  </h6>
                  {clubDetails && clubDetails.club_vip_manager && (
                    <a
                      aria-label="Chat on WhatsApp"
                      target="_blank"
                      href={`https://wa.me/:${clubDetails.club_vip_manager.whatsapp_no}`}
                      className="text-capitalize text-md text-dark"
                      rel="noreferrer"
                    >
                      {clubDetails.club_vip_manager.whatsapp_no}
                    </a >
                  )}
                </article >
              </Col >
            </Row >
          </Col >
        </Row >
      </ContentWrapper >
      {/* VIP Manager  : End*/}
      {/* Door Manager : Start */}
      <ContentWrapper additionalClass="p-5 font-body mb-4">
        <Row>
          <Col xs={12}>
            <Row className="g-3">
              <Col xs={12}>
                {" "}
                <h2 className="color-heading font-body font-semibold text-lg text-lg-xl">
                  {("CLUB DOOR MANAGER").split(" ").map(item => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()).join(" ")}
                </h2>
              </Col>
              <Col xs={6}>
                <article>
                  <h6 className="text-md font-semibold color-gray mb-2">
                    Name
                  </h6>
                  {clubDetails && clubDetails.club_door_manager && (
                    <p className="text-capitalize text-md mb-0">
                      {clubDetails.club_door_manager.name}
                    </p>
                  )}
                </article>
              </Col>
              <Col xs={6}>
                <article>
                  <h6 className="text-md font-semibold color-gray mb-2">
                    Email
                  </h6>
                  {clubDetails && clubDetails.club_door_manager && (
                    <a
                      href={`mailto:${clubDetails.club_door_manager.email}`}
                      className="text-capitalize text-md text-dark"
                    >
                      {clubDetails.club_door_manager.email}
                    </a>
                  )}
                </article>
              </Col>
              <Col xs={6}>
                <article>
                  <h6 className="text-md font-semibold color-gray mb-2">
                    Phone
                  </h6>
                  {clubDetails && clubDetails.club_door_manager && (
                    <a
                      href={`tel:${clubDetails.club_door_manager.contact_no}`}
                      className="text-capitalize text-md text-dark"
                    >
                      {clubDetails.club_door_manager.contact_no}
                    </a>
                  )}
                </article>
              </Col>
              <Col xs={6}>
                <article>
                  <h6 className="text-md font-semibold color-gray mb-2">
                    WhatsApp
                  </h6>
                  {clubDetails && clubDetails.club_door_manager && (
                    <a
                      aria-label="Chat on WhatsApp"
                      target="_blank"
                      href={`https://wa.me/:${clubDetails.club_door_manager.whatsapp_no}`}
                      className="text-capitalize text-md text-dark"
                      rel="noreferrer"
                    >
                      {clubDetails.club_door_manager.whatsapp_no}
                    </a>
                  )}
                </article>
              </Col>
            </Row>
          </Col>
        </Row>
      </ContentWrapper>
      {/* Door Manager : End */}
      {/* CLUB COMMUNICATION DETAILS :Start */}
      <ContentWrapper additionalClass="p-5 font-body mb-4">
        <Row className="g-3">
          <Col xs={12}>
            <h2 className="color-heading font-body font-semibold text-lg text-lg-xl">
              {("CLUB COMMUNICATION DETAILS").split(" ").map(item => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()).join(" ")}
            </h2>
          </Col>
          <Col xs={6}>
            <article>
              <h6 className="text-md font-semibold color-gray">Club Email ID</h6>
              {clubDetails && (
                <p className="text-capitalize text-md mb-0">
                  {clubDetails.club_email_id}
                </p>
              )}
            </article>
          </Col>
          <Col xs={6}>
            <article>
              <h6 className="text-md font-semibold color-gray ">
                Website Link
              </h6>
              {clubDetails && (
                <p className="text-capitalize text-md mb-0">
                  {clubDetails.website_url}
                </p>
              )}
            </article>
          </Col>
          <Col xs={6}>
            <article>
              <h6 className="text-md font-semibold color-gray">
                Club Description
              </h6>
              {clubDetails && (
                <p className="text-capitalize text-md mb-0">
                  {clubDetails.description}
                </p>
              )}
            </article>
          </Col>
        </Row>
      </ContentWrapper>
      {/* CLUB COMMUNICATION DETAILS :End */}
      {/* CLUB ACCOUNT DETAILS : Start */}
      <ContentWrapper additionalClass="p-5 font-body mb-4">
        <Row className="g-3">
          <Col xs={12}>
            <h2 className="color-heading font-body font-semibold text-lg text-lg-xl">
              {("CLUB ACCOUNT DETAILS").split(" ").map(item => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()).join(" ")}
            </h2>
          </Col>
          <Col xs={6}>
            <article>
              <h6 className="text-md font-semibold color-gray">
                Finance Coordinator Name *
              </h6>
              {clubDetails && clubDetails.club_account_details && (
                <p className="text-capitalize text-md mb-0">
                  {clubDetails.club_account_details.finance_coordinator_name}
                </p>
              )}
            </article>
          </Col>
          <Col xs={6}>
            <article>
              <h6 className="text-md font-semibold color-gray ">Email </h6>
              {clubDetails && clubDetails.club_account_details && (
                <a
                  href={`mailto:${clubDetails.club_account_details.email}`}
                  className="text-capitalize text-md text-dark"
                >
                  {clubDetails.club_account_details.email}
                </a>
              )}
            </article>
          </Col>
          <Col xs={6}>
            <article>
              <h6 className="text-md font-semibold color-gray">Mobile</h6>
              {clubDetails && clubDetails.club_account_details && (
                <a
                  className="text-capitalize text-md text-dark"
                  href={`tel:${clubDetails.club_account_details.mobile}`}
                >
                  {clubDetails.club_account_details.mobile}
                </a>
              )}
            </article>
          </Col>
          <Col xs={6}>
            <article>
              <h6 className="text-md font-semibold color-gray">WhatsApp </h6>
              {clubDetails && clubDetails.club_account_details && (
                <a
                  aria-label="Chat on WhatsApp"
                  target="_blank"
                  href={`https://wa.me/:${clubDetails.club_account_details.whatsapp_no}`}
                  className="text-capitalize text-md text-dark"
                  rel="noreferrer"
                >
                  {clubDetails.club_account_details.whatsapp_no}
                </a>
              )}
            </article>
          </Col>
          <Col xs={12}>
            <article>
              <h6 className="text-md font-semibold color-gray">
                Bank AC Details for payment coordination.
              </h6>
              {clubDetails && clubDetails.club_account_details && (
                <p className="text-capitalize text-md mb-0">
                  {clubDetails.club_account_details.account_details}
                </p>
              )}
            </article>
          </Col>
        </Row>
      </ContentWrapper>
      {/* CLUB ACCOUNT DETAILS : End */}
      {/* DRESS CODE : Start */}
      <ContentWrapper additionalClass="p-5 font-body mb-4">
        <Row className="g-3">
          <Col xs={12}>
            <h2 className="color-heading font-body font-semibold text-lg text-lg-xl">
              {("CLUB DRESS CODE").split(" ").map(item => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()).join(" ")}
            </h2>
          </Col>
          <Col xs={12}>
            <article>
              {/* <h6 className="text-md font-semibold color-gray">DRESS CODE</h6> */}
              {clubDetails && (
                <p className="text-capitalize text-md mb-0">
                  {clubDetails.dress}
                </p>
              )}
            </article>
          </Col>
        </Row>
      </ContentWrapper>
      {/* DRESS CODE : End */}
      {/* CLUB LOGO : Start */}
      {/* <ContentWrapper additionalClass="p-5 font-body mb-4">
        <Row className="g-3">
          <Col xs={12}>
            <h2 className="color-heading font-body font-semibold text-lg text-lg-xl">
              CLUB LOGO
            </h2>
          </Col>
          <Col xs={12}>
          <article
              className="po-updateEvent__inputPhoto  background-position-center-top background-repeat-norepeat background-size-cover rounded overflow-hidden"
            >
               <PhotoProvider>
               <PhotoView src={clubDetails && clubDetails.logo}>
              <img src={clubDetails && clubDetails && clubDetails.logo} className="w-100 h-100" style={{objectFit:'cover',objectPosition:'center'}} alt="CLUB COVER"/>
              </PhotoView>
              </PhotoProvider>
            </article>
          </Col>
        </Row>
      </ContentWrapper> */}
      {/* CLUB LOGO  : End */}
      {/* CLUB COVER IMAGE : Start */}
      <ContentWrapper additionalClass="p-5 font-body mb-4">
        <Row className="g-3">
          <Col xs={12}>
            <h2 className="color-heading font-body font-semibold text-lg text-lg-xl">
              {("CLUB COVER IMAGE").split(" ").map(item => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()).join(" ")}
            </h2>
          </Col>
          <Col xs={12}>
            {clubDetails && clubDetails.display_image ? <article
              className="po-updateEvent__inputPhoto  background-position-center-top background-repeat-norepeat background-size-cover rounded overflow-hidden"
            >
              <PhotoProvider>
                <PhotoView src={clubDetails.display_image}>
                  <img src={clubDetails.display_image} className="w-100 h-100" style={{ objectFit: 'cover', objectPosition: 'center' }} alt="CLUB COVER" />
                </PhotoView>
              </PhotoProvider>
            </article> : <Skeleton
              inline={true} count={1}
              height={200} width={350}
              baseColor="#dedede" highlightColor="#cccccc"
              duration={2} rectangle
            />}
          </Col>
        </Row>
      </ContentWrapper>
      {/* CLUB COVER IMAGE  : End */}
      {/* CLUB Gallery photos : Start */}
      <ContentWrapper additionalClass="p-5 font-body mb-4">
        <Row className="g-3">
          <Col xs={12}>
            <h2 className="color-heading font-body font-semibold text-lg text-lg-xl">
              {("CLUB Gallery photos").split(" ").map(item => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()).join(" ")}
            </h2>
          </Col>
          <Col xs={12}>
            <h4 className="color-lightestblue font-normal text-xs mb-2 font-body">
              Club Slider Images
            </h4>
            <PhotoProvider>
              {clubDetails.photos &&
                clubDetails.photos.length > 0 ?
                clubDetails.photos.map((photo, index) => {
                  return (
                    <PhotoView src={photo.url} key={index}>
                      <img
                        src={photo.url}
                        alt="club sliders"
                        style={{ height: "100px", width: "100px" }}
                        className="rounded me-2 mb-2 cursor-pointer "
                      />
                    </PhotoView>
                  );
                }) : <Skeleton
                  inline={true} count={3}
                  height={100} width={100}
                  baseColor="#dedede" highlightColor="#cccccc"
                  duration={2} rectangle
                  className="me-2"
                />}
            </PhotoProvider>
          </Col>
        </Row>
      </ContentWrapper>
      {/* CLUB Gallery photos  : End */}
      {/* Status : Start */}
      <ContentWrapper additionalClass="p-5 font-body mb-4">
        <Row className="g-3">
          <Col xs={12}>
            <h2 className="color-heading font-body font-semibold text-lg text-lg-xl">
              {("CLUB Status").split(" ").map(item => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()).join(" ")}
            </h2>
          </Col>
          <Col xs={6}>
            <h4 className="text-md font-semibold color-gray">Status</h4>
            <p>{clubDetails && clubDetails.status}</p>
          </Col>
          <Col xs={6}>
            <h4 className="text-md font-semibold color-gray">Is featured ?</h4>
            <p>{clubDetails && clubDetails.featured === true ? "Yes" : "No"}</p>
          </Col>
        </Row>
      </ContentWrapper>
      {/* Status : ENd */}
      {/* Working Days : start */}
      <ContentWrapper additionalClass="p-5 font-body mb-4">
        <div>
          <h2 className="color-heading font-body font-semibold text-lg text-lg-xl pb-6 mb-0">
            {("Club Working Days").split(" ").map(item => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()).join(" ")}
          </h2>
          <Table bordered rounded hover>
            {/* Table Headingss */}
            <thead>
              <tr>
                <th>S.No</th>
                <th className="p-3"> Days</th>
                <th className="p-3">Party Theme</th>
                <th className="p-3">Timings</th>
              </tr>
            </thead>
            {/* Schedules */}
            <tbody>
              <tr>
                <td>1</td>
                <td className="p-3">Sunday</td>
                <td className="p-3">
                  {clubDetails.schedule &&
                    clubDetails.schedule.Sunday &&
                    clubDetails.schedule.Sunday.title}
                </td>
                <td className="p-3">
                  {clubDetails.schedule &&
                    clubDetails.schedule.Sunday &&
                    convertMinutesToHours(clubDetails.schedule.Sunday.from_time)}{" "}
                  to{" "}
                  {clubDetails.schedule &&
                    clubDetails.schedule.Sunday &&
                    convertMinutesToHours(clubDetails.schedule.Sunday.to_time)}
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td className="p-3">Monday</td>
                <td className="p-3">
                  {clubDetails.schedule &&
                    clubDetails.schedule.Monday &&
                    clubDetails.schedule.Monday.title}
                </td>
                <td className="p-3">
                  {clubDetails.schedule &&
                    clubDetails.schedule.Monday &&
                    convertMinutesToHours(clubDetails.schedule.Monday.from_time)}{" "}
                  to{" "}
                  {clubDetails.schedule &&
                    clubDetails.schedule.Monday &&
                    convertMinutesToHours(clubDetails.schedule.Monday.to_time)}
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td className="p-3">Tuesday</td>
                <td className="p-3">
                  {clubDetails.schedule &&
                    clubDetails.schedule.Tuesday &&
                    clubDetails.schedule.Tuesday.title}
                </td>
                <td className="p-3">
                  {clubDetails.schedule &&
                    clubDetails.schedule.Tuesday &&
                    convertMinutesToHours(clubDetails.schedule.Tuesday.from_time)}{" "}
                  to{" "}
                  {clubDetails.schedule &&
                    clubDetails.schedule.Tuesday &&
                    convertMinutesToHours(clubDetails.schedule.Tuesday.to_time)}
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td className="p-3">Wednesday</td>
                <td className="p-3">
                  {clubDetails.schedule &&
                    clubDetails.schedule.Wednesday &&
                    clubDetails.schedule.Wednesday.title}
                </td>
                <td className="p-3">
                  {clubDetails.schedule &&
                    clubDetails.schedule.Wednesday &&
                    convertMinutesToHours(
                      clubDetails.schedule.Wednesday.from_time
                    )}{" "}
                  to{" "}
                  {clubDetails.schedule &&
                    clubDetails.schedule.Wednesday &&
                    convertMinutesToHours(clubDetails.schedule.Wednesday.to_time)}
                </td>
              </tr>
              <tr>
                <td>5</td>
                <td className="p-3">Thursday</td>
                <td className="p-3">
                  {clubDetails.schedule &&
                    clubDetails.schedule.Thursday &&
                    clubDetails.schedule.Thursday.title}
                </td>
                <td className="p-3">
                  {clubDetails.schedule &&
                    clubDetails.schedule.Thursday &&
                    convertMinutesToHours(clubDetails.schedule.Thursday.from_time)}{" "}
                  to{" "}
                  {clubDetails.schedule &&
                    clubDetails.schedule.Thursday &&
                    convertMinutesToHours(clubDetails.schedule.Thursday.to_time)}
                </td>
              </tr>
              <tr>
                <td>6</td>
                <td className="p-3">Friday</td>
                <td className="p-3">
                  {clubDetails.schedule &&
                    clubDetails.schedule.Friday &&
                    clubDetails.schedule.Friday.title}
                </td>
                <td className="p-3">
                  {clubDetails.schedule &&
                    clubDetails.schedule.Friday &&
                    convertMinutesToHours(clubDetails.schedule.Friday.from_time)}{" "}
                  to{" "}
                  {clubDetails.schedule &&
                    clubDetails.schedule.Friday &&
                    convertMinutesToHours(clubDetails.schedule.Friday.to_time)}
                </td>
              </tr>
              <tr>
                <td>7</td>
                <td className="p-3">Saturday</td>
                <td className="p-3">
                  {clubDetails.schedule &&
                    clubDetails.schedule.Saturday &&
                    clubDetails.schedule.Saturday.title}
                </td>
                <td className="p-3">
                  {clubDetails.schedule &&
                    clubDetails.schedule.Saturday &&
                    convertMinutesToHours(clubDetails.schedule.Saturday.from_time)}{" "}
                  to{" "}
                  {clubDetails.schedule &&
                    clubDetails.schedule.Saturday &&
                    convertMinutesToHours(clubDetails.schedule.Saturday.to_time)}
                </td>
              </tr>
            </tbody>

            {/* Schedules */}
          </Table>
        </div>
      </ContentWrapper>
      {/* Working Days : end */}
      {/* Location : Start */}
      <ContentWrapper additionalClass="p-5 font-body mb-4">
        <Row className="g-3">
          <Col xs={12}>
            <h4 className="color-heading font-body font-semibold text-lg text-lg-xl pb-6 mb-0">
              {("Club Location").split(" ").map(item => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()).join(" ")}
            </h4>
          </Col>
          <Col xs={6}>
            <h4 className="text-md font-semibold color-gray">Country</h4>
            <p>{clubDetails && clubDetails.country_name}</p>
          </Col>
          <Col xs={6}>
            <h4 className="text-md font-semibold color-gray">State</h4>
            <p>{clubDetails && clubDetails.state_name}</p>
          </Col>
          <Col xs={6}>
            <h4 className="text-md font-semibold color-gray">City</h4>
            <p>{clubDetails && clubDetails.city_name}</p>
          </Col>

          <Col xs={6}>
            <h4 className="text-md font-semibold color-gray">Full Address</h4>
            <p>{clubDetails && clubDetails.address}</p>
          </Col>
          <Col xs={6}>
            <h4 className="text-md font-semibold color-gray">Club latitude</h4>
            <p>{clubDetails && clubDetails.latitude}</p>
          </Col>
          <Col xs={6}>
            <h4 className="text-md font-semibold color-gray">Club longitude</h4>
            <p>{clubDetails && clubDetails.longitude}</p>
          </Col>
        </Row>
      </ContentWrapper>
      {/* Location : ENd */}
      {/* Slot timings : Start */}
      <ContentWrapper additionalClass="p-5 font-body mb-4">
        <div className="mt-3">
          <h2 className="color-heading font-body font-semibold text-lg text-lg-xl pb-6 mb-0">
            {("Club Slot Timings").split(" ").map(item => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()).join(" ")}
          </h2>
          {clubDetails &&
            clubDetails.slots &&
            clubDetails.slots.length ?
            <Table bordered hover>
              <thead>
                <tr>
                  <th className="p-3">S.no</th>
                  <th className="p-3">Time</th>
                  <th className="p-3">Zone</th>
                </tr>
              </thead>
              <tbody>
                {clubDetails &&
                  clubDetails.slots &&
                  clubDetails.slots.length > 0 &&
                  clubDetails.slots.map((slot, index) => {
                    return (
                      <tr key={index}>
                        <td className="p-3">{index + 1}</td>
                        <td className="p-3">{slot.slot_time}</td>
                        <td className="p-3">{slot.zone}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table> : <p><span className="font-semibold">Note:</span> <span>The time slots are scheduled on the days when the club is operational, with each slot having a duration of 30 minutes.</span></p>}
        </div>
      </ContentWrapper>
      {/* Slot timings : ENd */}
      {/* Terms and Conditions : Start */}
      <ContentWrapper additionalClass="p-5 font-body mb-4">
        <div className="mt-3">
          <h2 className="color-heading font-body font-semibold text-lg text-lg-xl pb-6 mb-0">
            {("Club Terms and Conditions").split(" ").map(item => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()).join(" ")}
          </h2>
          <div>
            <Markdown>{clubDetails && clubDetails.terms_and_conditions}</Markdown>
          </div>
        </div>
      </ContentWrapper>
      {/* Terms and Conditions : ENd  */}


      {/* Multiple Emails Sending  */}

      <ContentWrapper additionalClass="p-5 mt-5">
        <Row>
          <Col xs={6}>
            <h5 className="font-body text-xl ">Make A Note.</h5>
            <ul className="mt-3">
              {eventDetail &&
                eventDetail.comments &&
                eventDetail.comments.length > 0 &&
                eventDetail.comments.map((data, index) => {
                  return (
                    <li key={index}>
                      <div className="shadow-sm p-3 mb-5 bg-white rounded font-body d-flex justify-content-between">
                        <div className="d-flex align-items-start">
                          <div
                            style={{
                              height: "60px",
                              width: "60px",
                              // backgroundImage: `url(${data.user_profile_image})`,
                              backgroundColor: "#ededed",
                            }}
                            className="d-flex align-items-center justify-content-center rounded-circle"
                          >
                            <i className="bi bi-person-circle text-4xl"></i>
                          </div>
                          <div className="ms-3">
                            <div className="mb-1 d-flex align-items-center">
                              <div className="text-sm font-bold me-3">
                                {data.user_name
                                  ? data.user_name
                                  : "Unknown user"}
                              </div>
                              <div className="text-xxs">
                                {data.createdAt &&
                                  moment(
                                    new Date(
                                      data.createdAt.seconds * 1000
                                    ).toISOString()
                                  ).format("DD/MM/YYYY")}
                              </div>
                            </div>
                            <div className="w-100">
                              <p className="text-sm mb-0 text-wrap">
                                {data.comment}
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* <i
                          className="bi bi-trash-fill"
                          onClick={() => deleteThisComment(data.id)}
                          style={{ cursor: "pointer" }}
                        ></i> */}
                      </div>
                    </li>
                  );
                })}
            </ul>

            <form onSubmit={handleSubmit(onSubmit)} className="mt-5 font-body">
              <textarea
                className="po-edit-input"
                id="message"
                {...register("message", { validate: validateField })}
              />
              {errors.message && (
                <p className="text-sm text-danger mt-1">
                  Please Enter A Comment.
                </p>
              )}

              <div className="text-end d-flex justify-content-end align-items-center mb-6">
                {commentLoader && (
                  <img
                    src="/assets/icons/loading_icon.gif"
                    alt="processing"
                    width={100}
                  />
                )}
                <SecondaryButton
                  type="submit"
                  name="Comment"
                  additionalClass="mt-3"
                />
              </div>
            </form>
          </Col>
        </Row>
      </ContentWrapper>
      {/* Cancel Booking Modal */}
      {/* Approve modal */}
      <Modal show={approveModal} onHide={handleClose} size={"lg"}>
        <div className="background-appColor p-10 rounded font-body">
          {/* <h5 className="text-2xl  color-ternary  mb-3">Transaction details</h5> */}
          <h3 className="text-2xl color-ternary">
            Does This Booking Require Cover Charge?
          </h3>

          <div>
            <div className="d-flex align-items-center mb-3">
              <label className="ms-2 text-light">
                <input
                  type="radio"
                  value="option1"
                  checked={selection === "option1"}
                  onChange={handleSelectionChange}
                />
                <span className="ml-2">Yes required</span></label>
            </div>
            <div className="d-flex align-items-center mb-3">
              <label className="ms-2 text-light">
                <input
                  type="radio"
                  value="option2"
                  checked={selection === "option2"}
                  onChange={handleSelectionChange}
                />
                <span className="ml-2">Card Filing Required</span></label>
            </div>
            <div className="d-flex align-items-center mb-3">
              <label className="ms-2 text-light">
                <input
                  type="radio"
                  value="option3"
                  checked={selection === "option3"}
                  onChange={handleSelectionChange}
                />
                <span className="ml-2">No Not Required</span></label>
            </div>

            {selection === "option1" && (
              <div>
                <div className="mb-1 mt-5">
                  <label className="d-block mb-1 text-md text-light">
                    Amount (Cover Charge)
                  </label>
                  <div className="row g-1 w-100 mx-0">
                    <div className="col-3">
                      {/* currency selector */}
                      {/* <select className="po-edit-input text-sm h-100" onChange={(e) => {
                        let symbolValue = e.target.value;
                        setCurrencySymbol(e.target.value)
                        setInvoice({
                          ...invoice,
                          error: {
                            ...invoice.error, loyalityPoint: symbolValue === "" ? "Please select currency" : ""
                          }
                        })
                      }}>
                        <option defaultChecked={true} value="SGD">SGD</option>
                        {currencySymbols && currencySymbols.length > 0 &&
                          currencySymbols.sort((a,b)=>a>b ?1:-1).map((ele, index) => {
                            if(ele!=="SGD") {
                              return (
                                <option key={index} value={ele}>{ele}</option>
                              )
                            } else return "";
                          })
                        }
                      </select> */}
                      <select
                        className="po-edit-input text-sm h-100"
                        onChange={(e) => {
                          let symbolValue = e.target.value;
                          setCurrencySymbol(e.target.value)
                          setInvoice({
                            ...invoice,
                            error: {
                              ...invoice.error, loyalityPoint: symbolValue === "" ? "Please select currency" : ""
                            }
                          })
                        }}>
                        <option defaultChecked={true} value="SGD">Singapore | $</option>
                        {countryCurrencyInfo && countryCurrencyInfo.length > 0 &&
                          countryCurrencyInfo.sort((a, b) => a.Country > b.Country ? 1 : -1).map((ele, index) => {
                            if (ele.currency_code !== "SGD") {
                              return (
                                <option key={index} value={ele.currency_code}>{ele.Country} | {ele.Symbol}</option>
                              )
                            } else return ""
                          })
                        }
                      </select>
                    </div>
                    <div className="col-9">
                      {/* input for amount */}
                      <input
                        min={0}
                        type="Number"
                        className="po-edit-input text-sm h-100"
                        value={bookingCharge.coverChargeAmount}
                        placeholder="Enter amount"
                        disabled={!currencySymbol}
                        onChange={(e) => {
                          let value = e.target.value === 0;
                          setBookingCharge({
                            ...bookingCharge,
                            errors: {
                              ...bookingCharge.errors,
                              coverCharger: value ? "Enter some value" : "",
                            },
                            coverChargeAmount: Number(e.target.value),
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                {bookingCharge.errors.coverCharger && (
                  <p className="text-danger">
                    {bookingCharge.errors.coverCharger}
                  </p>
                )}
                <div className="mb-1 mt-5">
                  <label className="d-block mb-1 text-md text-light">
                    Enter Payment Link
                  </label>
                  <input
                    type="text "
                    value={bookingCharge.paymentLink}
                    className="po-edit-input"
                    placeholder="Enter link"
                    onChange={(e) => {
                      const urlPattern = new RegExp(
                        // urlValidationRegex
                        "^" +
                        "(?:(?:https?|ftp)://)" +
                        "(?:\\S+(?::\\S*)?@)?" +
                        "(?:" +
                        "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
                        "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
                        "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
                        "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
                        "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
                        "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
                        "|" +
                        "(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)" +
                        "(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*" +
                        "(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))" +
                        ")" +
                        "(?::\\d{2,5})?" +
                        "(?:/\\S*)?" +
                        "$", "i"
                      );
                      let value = e.target.value === "";
                      setBookingCharge({
                        ...bookingCharge,
                        errors: {
                          ...bookingCharge.errors,
                          paymentLink: value
                            ? "Field should not be empty"
                            : e.target.value.trim().length === 0 ? "Whitespace not allowed" :
                              !urlPattern.test(e.target.value) ? "Url only allowed" : "",
                        },
                        paymentLink: e.target.value,
                      });
                    }}
                  />
                </div>
                {bookingCharge.errors.paymentLink && (
                  <p className="text-danger">
                    {bookingCharge.errors.paymentLink}
                  </p>
                )}
              </div>
            )}
            {selection === "option2" && (
              <div>
                <div className="mb-1 mt-5">
                  <label className="d-block mb-1 text-md text-light">
                    Enter Payment Link
                  </label>
                  <input
                    type="text "
                    value={bookingCharge.cardFillingPaymentLink}
                    className="po-edit-input"
                    placeholder="Enter link"
                    onChange={(e) => {
                      const urlPattern = new RegExp(
                        // urlValidationRegex
                        "^" +
                        "(?:(?:https?|ftp)://)" +
                        "(?:\\S+(?::\\S*)?@)?" +
                        "(?:" +
                        "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
                        "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
                        "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
                        "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
                        "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
                        "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
                        "|" +
                        "(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)" +
                        "(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*" +
                        "(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))" +
                        ")" +
                        "(?::\\d{2,5})?" +
                        "(?:/\\S*)?" +
                        "$", "i"
                      );
                      let value = e.target.value === "";
                      setBookingCharge({
                        ...bookingCharge,
                        errors: {
                          ...bookingCharge.errors,
                          cardFillingPaymentLink: value
                            ? "Field should not be empty"
                            : e.target.value.trim().length === 0 ? "Whitespace not allowed" :
                              !urlPattern.test(e.target.value) ? "Url Only allowed" : "",
                        },
                        cardFillingPaymentLink: e.target.value,
                      });
                    }}
                  />
                </div>
                {bookingCharge.errors.cardFillingPaymentLink && (
                  <p className="text-danger">
                    {bookingCharge.errors.cardFillingPaymentLink}
                  </p>
                )}
              </div>
            )}

            {selection === "option3" && <div></div>}
          </div>
          {selectButtonError && <p className="text-danger">Please select any one option</p>}

          {selection === "option1" ? (
            <div className="mt-6 text-end">
              <SecondaryButton
                onClick={handleClose}
                name="Close"
                additionalClass="text-light me-3 px-5"
              />
              <PrimaryButton
                onClick={(e) => handleCoverCharge("cover_charge")}
                name="Submit"
              />
            </div>
          ) : selection === "option2" ? (
            <div className="mt-6 text-end">
              <SecondaryButton
                onClick={handleClose}
                name="Close"
                additionalClass="text-light me-3 px-5"
              />
              <PrimaryButton
                onClick={(e) => handleCoverCharge("card_filling")}
                name="Submit"
              />
            </div>
          ) : selection === "option3" ? (
            <div className="mt-6 text-end">
              <SecondaryButton
                onClick={handleClose}
                name="Close"
                additionalClass="text-light me-3 px-5"
              />
              <PrimaryButton
                onClick={(e) => handleCoverCharge("not required")}
                name="Submit"
              />
            </div>
          ) :
            <div className="d-flex justify-content-end">
              <SecondaryButton
                onClick={handleClose}
                name="Close"
                additionalClass="text-light me-3 px-5"
              />
              <PrimaryButton
                onClick={(e) => handleCoverCharge("")}
                name="Submit"
              />
            </div>
          }
        </div>
      </Modal>
      {/* Approve modal */}
      <Modal
        show={showCancelBookingModal}
        onHide={() => {
          setCancelBookingModal(false);
        }}
      >
        <div className="background-appColor p-5 rounded font-body">
          <h5 className="text-2xl  color-ternary  mb-3">
            Confirm Cancellation
          </h5>
          <div>
            <p className="background-appColor text-light border-bottom-0 py-1 ">
              Are you sure you want to cancel booking?
            </p>
          </div>
          <div className="mt-6 text-end">
            <SecondaryButton
              onClick={() => setCancelBookingModal(false)}
              name="No"
              additionalClass="text-light me-3 px-5"
            />
            <PrimaryButton onClick={formCancellationApiCall} name="Yes" />
          </div>
        </div>
      </Modal>

      {/* Booking Completion Model */}
      <Modal
        show={bookingCompletionModal}
        onHide={handleBookingCompletionModal}
      >
        <div className="background-appColor p-5 rounded font-body">
          <h5 className="text-2xl  color-ternary  mb-3">Transaction Details</h5>
          <div>
            <div>
              <label className="text-md m-1 text-light">
                Transaction ID{" "}
                <span style={{ color: "red", width: "10px" }}>*</span>
              </label>
              <input
                type="text"
                className="po-edit-input m-1"
                placeholder="Please enter transactioin Id"
                onChange={(e) => {
                  let regex = /\s/;
                  let temp = { ...transactionDetails };
                  if (e.target.value === "") {
                    temp.errors.id = "Field should not be empty";
                  } else if (regex.test(e.target.value)) {
                    temp.errors.id = "Only alphabetic characters are allowed";
                  } else {
                    temp.errors.id = "";
                  }
                  temp.data.transactionId = e.target.value;
                  setTransactionDetails(temp);
                }}
              />
              {transactionDetails.errors.id && (
                <p className="text-danger">{transactionDetails.errors.id}</p>
              )}
            </div>
            <div className="mt-4">
              <label className="text-md m-1 text-light ">
                Transaction Date{" "}
                <span style={{ color: "red", width: "10px" }}>*</span>
              </label>
              <input
                min={minTransactionDate()}
                type="date"
                value={transactionDetails.data.transactionDate}
                className="po-edit-input  mt-1"
                max={maxTransactionDate()}
                onChange={(e) => {
                  let regex = /\s/;
                  let temp = transactionDetails;
                  if (e.target.value === "" || regex.test(e.target.value)) {
                    if (e.target.value === "")
                      temp.errors.date = "Field should not be empty";
                    else if (regex.test(e.target.value))
                      temp.errors.date =
                        "Only alphabetic characters are allowed";
                    setTransactionDetails({ ...temp });
                  }
                  temp.errors.date = "";
                  setTransactionDetails({ ...temp });
                  setTransactionDetails({
                    ...transactionDetails,
                    data: {
                      ...transactionDetails.data,
                      transactionDate: e.target.value,
                    },
                  });
                }}
                onKeyDown={(e) => e.preventDefault()}
                onKeyUp={(e) => e.preventDefault()}
              />
            </div>
            {transactionDetails.errors.date && (
              <p className="text-danger">{transactionDetails.errors.date}</p>
            )}
          </div>

          <div className="mt-6 text-end">
            <SecondaryButton
              onClick={() => {
                setBookingCompletionModal(false);
                setTransactionDetails({
                  ...transactionDetails,
                  data: {
                    transactionId: "",
                    transactionDate: moment(new Date()).format("YYYY-MM-DD"),
                  },
                  errors: {
                    ...errors,
                    id: "",
                    date: "",
                  },
                });
              }}
              name="Close"
              additionalClass="text-light me-3 px-5"
            />
            <PrimaryButton onClick={handleTransaction} name="Save Changes" />
          </div>
        </div>
      </Modal>
      {/* Booking Completion Model */}
      {/* Rejection Model */}
      <Modal
        show={showRejectionModal}
        onHide={() => {
          setshowRejectionModal(false);
        }}
      >
        <div className="background-appColor p-5 rounded font-body">
          <h5 className="text-2xl  color-ternary  mb-3">
            Reason For Rejection
          </h5>
          <div></div>
          <div>
            <textarea
              className="po-edit-input"
              placeholder="Please enter reason..."
              onChange={(e) => {
                const str = e.target.value;
                if (str === "") {
                  setReason({
                    reason: "",
                    error: "Field should not be empty...",
                  });
                } else if (str.trim() === "") {
                  setReason({
                    reason: "",
                    error: "Whitespace should allowed...",
                  });
                } else {
                  setReason({ reason: str, error: "" });
                }
              }}
            ></textarea>
            <p className="text-danger mt-1">{reason.error && reason.error}</p>
          </div>
          <div className="mt-6 text-end">
            <SecondaryButton
              onClick={() => {
                setshowRejectionModal(false);
                setReason({ reason: "", error: "" });
              }}
              name="Close"
              additionalClass="text-light me-3 px-5"
            />
            <SecondaryButton
              onClick={(e) => actionSubmit("Rejected")}
              name="Submit"
              additionalClass="text-light me-3 px-5"
            />
          </div>
        </div>
      </Modal>
      {/* Rejection Model */}
      <Toaster
        showToaster={showToaster}
        setShowToaster={setShowToaster}
        bg={toasterDetails.bg}
        title={toasterDetails.title}
        bodyText={toasterDetails.bodyText}
        statusIcon={toasterDetails.statusIcon}
      />
    </Layout >
  );
};

export default EventBookingDetails;
