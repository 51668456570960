import React, { memo } from "react";
import Layout from "../../Layout/Layout";
import PageDescription from "../../Components/Global/PageDescription";
import ContentWrapper from "../../Components/Global/ContentWrapper";
import { Button, Col, Row, Modal } from "react-bootstrap";
import { useRef } from "react";
import { useState } from "react";
import { collection, doc, getDocs, setDoc, updateDoc } from "firebase/firestore";
import db from "../../firebase";
import { useEffect } from "react";
import axios from "axios";
import Toaster from "../../Components/Global/Toast";
import PrimaryButton from "../../Components/Global/Buttons/PrimaryButton";
import SecondaryButton from "../../Components/Global/Buttons/SecondaryButton";
import Skeleton from "react-loading-skeleton";
import SuccessButton from "../../Components/Global/Buttons/SuccessButton";

//Child Component
const ChildVideoComponent = ({ items, fetchVideo }) => {
  const [show, setShow] = useState(false);
  const [toasterDetails, setToasterDetails] = useState({
    title: "",
    bodyText: "",
    bg: "",
    statusIcon: "",
  });
  const [showToaster, setShowToaster] = useState(false);
  const [fileUpload, setFileUpload] = useState(false);
  const [video, setVideo] = useState({
    videoData: "",
    previewVideoUrl: "",
    error: ""
  })

  // getting video url from api
  const uploadingVideo = async (videoData) => {
    let form = new FormData();
    form.append("images", videoData);
    let responseVideo = await axios
      .post(
        `${process.env.REACT_APP_MULTIPLEIMAGES_POSTING_URL}/uploads/uploadImages`,
        form,
        { headers: { "Content-Type": "multipart/form-data" } }
      )
      .then((resVideo) => {
        return resVideo && resVideo.data.url[0];
      })
      .catch((error) => console.log(error, "err"));
    return responseVideo;
  };



  // Upload video
  const createVideo = async () => {
    if (video.videoData === "") {
      setVideo({ ...video, error: "Please upload video..." })
      return;
    }
    setShowToaster(true);
    setToasterDetails((prev) => ({
      ...prev,
      title: "Uploading...",
      bodyText: "Uploading Video...",
      bg: "warning",
      statusIcon: (
        <div className="spinner-border text-warning me-2" role="status"></div>
      ),
    }));
    let uploadingVideoUrl = await uploadingVideo(video.videoData);
    await updateDoc(doc(db, "videos", items.id), {
      video_url: uploadingVideoUrl
    }).then((responseVideo) => {
      setToasterDetails((prev) => ({
        ...prev,
        title: "Success",
        bodyText: "Video Uploaded successfully",
        bg: "success",
        statusIcon: (
          <i className="bi bi-check text-success text-3xl me-2"></i>
        ),
      }));
      fetchVideo();
      // setFileUpload(!fileUpload)
      setTimeout(() => {
        setShowToaster(false);
      }, 2000)
      // window.location.reload();
    }).catch(error => console.log(error, "err"))

  }


  return (
    // <Layout>
    <div>
      <div className="d-flex justify-content-between align-items-center gap-3 gap-lg-5 font-body">
        <PageDescription title="Upload Dashboard Video" caption="" />
      </div>
      <ContentWrapper additionalClass="p-5 mb-4">
        <div>
          <Row>
            {
              // is video is there in backend
              items.videoUrl ? (
                <Col xs={6} hidden={!fileUpload}>
                  <div>
                    <video width="610" height="340" controls className="rounded">
                      <source
                        src={items.videoUrl}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                  <div className="w-100 d-flex flex-column ">
                    <Button
                      variant="danger text-light bold"
                      className="w-100 mt-3"
                      onClick={() => {
                        setShow(true);
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                </Col>
              ) :


                // if video is not there in backend
                (
                  <Col>
                    <div className="p-3 m-2">
                      <p className="bold text-warning text-lg">Please upload video to show</p>
                      <div>

                        <div className="d-flex justify-content-center">
                          <div style={{ height: "250px", width: "450px" }} className="cursor-pointer position-relative border rounded border-dashed mt-5 d-flex justify-content-center align-items-center">
                            <div className="text-center">
                              <img src="./assets/icons/icon-mediaplayer.svg" alt="icon" />
                              <h5 className="text-lg mt-2">Select your video</h5>
                            </div>

                            <input type="file" value={video.videoData} accept="video/*" className="position-absolute start-0 end-0 top-0 bottom-0 opacity-0 cursor-pointer" onChange={(e) => {
                              const videoUrl = URL.createObjectURL(e.target.files[0]);
                              if (videoUrl) {
                                setVideo({ ...video, previewVideoUrl: videoUrl, error: "", })
                              }

                            }} />
                          </div>
                        </div>
                        {video.error && <p className="text-danger">{video.error}</p>}
                        <div className="d-flex justify-content-end">
                          <SuccessButton type="submit"
                            onClick={createVideo}
                            name="Upload"
                            icon="bi-check-circle" additionalClass="mt-4 text-center" />
                        </div>
                      </div>
                      <video
                        src={video.previewVideoUrl}
                        style={{ height: "450px", width: "250px" }}
                        controls
                        autoPlay
                        className="rounded border"
                      >
                        Sorry, your browser doesn't support embedded videos.
                      </video>
                      <button onClick={(e) => {
                        setVideo({ ...video, previewVideoUrl: "", videoData: "" })
                      }}>
                        delete</button>
                    </div>
                  </Col>
                )

            }
          </Row>
        </div>
        {/* Modal */}
        <Modal
          show={show}
          onHide={() => {
            setShow(false);
          }}
        >
          <div className="background-appColor p-5 rounded font-body">
            <h5 className="text-2xl  color-ternary  mb-3">Confirm Delete</h5>
            <div>
              <p className="background-appColor text-light border-bottom-0 py-1 ">
                Are you sure you want to delete video?
              </p>
            </div>
            <div className="mt-6 text-end">
              <SecondaryButton
                onClick={() => setShow(false)}
                name="No"
                additionalClass="text-light me-3 px-5"
              />
              <PrimaryButton name="Yes" />
            </div>
          </div>
        </Modal>
        <Toaster
          showToaster={showToaster}
          setShowToaster={setShowToaster}
          bg={toasterDetails.bg}
          title={toasterDetails.title}
          bodyText={toasterDetails.bodyText}
          statusIcon={toasterDetails.statusIcon}
        />
      </ContentWrapper >
    </div >
    // </Layout >
  );
};


const DashboardVideo = () => {
  const [video, setVideo] = useState({
    videoUrl: "",
    id: "",
    videoData: "",
    error: "",
    updateInput: ""
  });
  const fetchVideo = async () => {
    await getDocs(collection(db, "videos")).then(response => response && response.docs && response.docs[0] && setVideo({ ...video, videoUrl: response.docs[0].data().video_url, id: response.docs[0].data().id }))
  }

  useEffect(() => {
    fetchVideo()
  }, [])

  return (
    <Layout>
      {video.id ? <ChildVideoComponent items={video} fetchVideo={fetchVideo} /> :
        <div>
          <Skeleton variant="text" animation="wave" height={50} width={303.39} className="p-5 mb-4" />
          <Skeleton variant="rectangular" width={1100} height={186} className="mt-4" />
        </div>
      }
    </Layout>
  )
}

export default DashboardVideo;